import React from "react";
import styled from "styled-components";

import { AddonType } from "../../../common/types/subscription-service";
import { CheckIcon, PlusIcon } from "../../../components/Icons/Icons";
import { useAddonPopup } from "../../../hooks/useAddonPopup";
import { Box, Flex, MoreOptions, Text, theme } from "../../../icecube-ux";
import { _ } from "../../../languages/helper";

const StyledLearnMoreBox = styled(Box)`
  display: flex;
  align-items: center;
  height: 21px;
  padding: 8px 6px;
  gap: 2px;
  border-radius: 2px;
  border: 1px solid ${theme.colors.white100};
`;

const StyledLearnMoreText = styled(Text)`
  color: ${theme.colors.primary100};
  text-decoration-line: underline;
`;

const StyledSubscriptionFlexLine = styled(Flex)<{
  addOn: AddonType | undefined;
}>`
  align-items: center;
  cursor: ${({ addOn }) => (addOn ? "pointer" : "default")};
  &:hover ${StyledLearnMoreBox} {
    border: 1px solid ${theme.colors.primary10};
    background-color: ${theme.colors.primary10};
  }
  &:hover ${StyledLearnMoreText} {
    text-decoration-line: none;
  }
`;

export const SubscriptionPlanListItemLi = ({
  text,
  addOn,
  plus,
}: {
  text: React.ReactNode;
  addOn?: AddonType;
  plus?: boolean;
}) => {
  const { setAddon } = useAddonPopup();

  return (
    <StyledSubscriptionFlexLine
      addOn={addOn}
      onClick={addOn ? () => setAddon(addOn) : undefined}
    >
      {plus ? (
        <MoreOptions
          className={"subscription-plus"}
          options={[]}
          anchorComponent={<PlusIcon />}
        />
      ) : (
        <div style={{ width: 20, height: 20 }}>
          <CheckIcon size={18} className="subscription-tick" />
        </div>
      )}
      <Flex
        style={{ margin: "4px 0px 4px 8px" }}
        justifyContent={"space-between"}
        alignItems={"center"}
        fullWidth
      >
        <Text className="subscription-plan-list-item" variant="body12Regular">
          {text}
        </Text>
        {addOn && (
          <StyledLearnMoreBox>
            <StyledLearnMoreText variant={"body9SemiBold"}>
              {_`Learn more`}
            </StyledLearnMoreText>
          </StyledLearnMoreBox>
        )}
      </Flex>
    </StyledSubscriptionFlexLine>
  );
};
