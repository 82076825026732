import React from "react";
import "react-notifications/lib/notifications.css";
import { Route } from "react-router-dom";

import { ProvideConnectorData } from "../../hooks/useConnectorData";

const Connectors = React.lazy(
  () => import("../../pages/connectors/Connectors"),
);
const Confirm = React.lazy(() => import("../../pages/shopify/Confirm"));

export const ConnectorRoutes = (
  <>
    <Route
      path="/connectors"
      element={
        <ProvideConnectorData>
          <Connectors />
        </ProvideConnectorData>
      }
    />
    <Route
      path="/connectors/:connectorKey/:tab"
      element={
        <ProvideConnectorData>
          <Connectors />
        </ProvideConnectorData>
      }
    />
    <Route
      path="/connectors/:connectorKey/:tab/:subtab"
      element={
        <ProvideConnectorData>
          <Connectors />
        </ProvideConnectorData>
      }
    />
    <Route path="/shopify/confirm" element={<Confirm />} />
  </>
);
