import styled, { css } from "styled-components";

import { theme } from "../theme";
import { typographyStylesMap } from "../Typography/typographyStylesMap";

export const StyledLabel = styled.div`
  flex-grow: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
`;

export const StyledRightComponent = styled.div``;

const tabSizeStyleMap = {
  large: (stacked: boolean) => ({
    ...typographyStylesMap.body12Regular,
    padding: stacked ? "8px 8px 8px 16px" : "8px",
  }),
  small: (_stacked: boolean) => ({
    ...typographyStylesMap.body11Regular,
    padding: "4.5px 10px",
  }),
};

export const StyledTabList = styled.div<{
  stacked: boolean;
}>`
  display: flex;
  padding: 0;
  gap: 8px;
  ${({ stacked }) => stacked && "flex-direction: column;"};
`;

export const StyledTabListTab = styled.button<{
  isCurrentTab: boolean;
  stacked: boolean;
  size: "large" | "small";
  showRightComponentOnlyOnHover: boolean;
}>`
  cursor: pointer;

  display: flex;
  flex-shrink: 0;
  margin: 0;
  gap: 8px;
  background: none;
  border: none;
  border-radius: ${theme.borderRadius.r4px};
  text-align: ${({ stacked }) => (stacked ? "left" : "center")};

  background-color: ${({ stacked }) =>
    stacked ? theme.colors.bgLight20 : theme.colors.bgLight10};
  color: ${({ stacked }) =>
    stacked ? theme.colors.text90 : theme.colors.text100};

  ${({ isCurrentTab }) =>
    isCurrentTab
      ? css`
          color: ${theme.colors.primary100};
          background-color: ${theme.colors.primary10};
        `
      : null}
  ${({ isCurrentTab, showRightComponentOnlyOnHover }) =>
    !isCurrentTab
      ? css`
          &:hover {
            color: ${theme.colors.text110};
            background-color: ${theme.colors.bgLight20};
            ${StyledLabel} {
              white-space: normal;
            }
            ${showRightComponentOnlyOnHover
              ? css`
                  ${StyledRightComponent} {
                    display: block;
                  }
                `
              : null}
          }
          ${showRightComponentOnlyOnHover
            ? css`
                ${StyledRightComponent} {
                  display: none;
                }
              `
            : null}
        `
      : css`
          ${StyledLabel} {
            white-space: normal;
          }
        `}

  &:focus-visible {
    outline: solid 3px ${theme.colors.primary10};
  }

  ${({ size, stacked }) => tabSizeStyleMap[size](stacked)}

  justify-content: space-between;
`;
