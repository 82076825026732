import styled from "styled-components";

import { useAuth } from "../../hooks/auth/auth";
import { useBootstrap } from "../../hooks/bootstrap";
import { Counter, Flex, Space, Text, theme } from "../../icecube-ux";
import { _ } from "../../languages/helper";
import { TRACKING_EVENTS, trackEvent } from "../../utils/trackingUtils";

const secondStepBgColor =
  "linear-gradient(127.01deg, #FF5C5C -0.11%, #FFA724 74.01%, #FFDC24 165.02%)";
const fourthStepBgColor =
  "linear-gradient(130.33deg, #B75CFF -20.97%, #6B62FE 42.42%, #3DBBF2 120.27%);";

interface ReferralStepCardProps {
  stepNumber: number;
  stepText: string;
  highlightText?: string;
  hightlightColor?: string;
}

const StyledReferralStepStepCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 12px 12px;
  border-radius: ${theme.borderRadius.r6px};
  background-color: ${theme.colors.bgLight10};
  align-items: center;
  gap: 16px;
  flex-grow: 1;
`;

const StyledTextWrapper = styled.div<{ background: string }>`
  background: ${({ background }) => background};
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: ${theme.borderRadius.r6px};
  padding: 10px 12px;
`;

const ReferralStepCard = ({
  stepNumber,
  stepText,
  highlightText,
  hightlightColor,
}: ReferralStepCardProps) => (
  <StyledReferralStepStepCard>
    <Counter variant="referral" value={stepNumber} />
    <Text
      textAlign="center"
      variant="body12Medium"
      color={theme.colors.text100}
      style={{ maxWidth: "150px" }}
    >
      {stepText}
    </Text>
    {highlightText && (
      <StyledTextWrapper background={hightlightColor ?? ""}>
        <Text variant="body12Regular" color={theme.colors.white100}>
          {_`You get`}
        </Text>
        <Text variant="body12Regular" color={theme.colors.white100}>
          {highlightText}
        </Text>
      </StyledTextWrapper>
    )}
  </StyledReferralStepStepCard>
);

const REFERRALS_TC_LINK =
  "https://intercom.help/polar-app/en/articles/8596531-polar-analytics-refer-a-friend-program-terms-and-conditions";

export const ReferralsStepsSection = () => {
  const { tenant } = useBootstrap();
  const auth = useAuth();
  return (
    <>
      <Flex flexDirection="column">
        <Text
          style={{ margin: "24px 0 4px" }}
          variant="body16Medium"
          color={theme.colors.text110}
        >{_`Easy as 1, 2, 3`}</Text>
        <Text
          variant="body12Regular"
          color={theme.colors.text90}
        >{_`Tell other brands how Polar could benefit them`}</Text>
      </Flex>
      <Space size={16} />
      <Flex gap={16}>
        <ReferralStepCard
          stepNumber={1}
          stepText={_`Create and start sharing your 20% discount code`}
        />
        <ReferralStepCard
          stepNumber={2}
          stepText={_`Your friend completes a demo with Polar`}
          highlightText={_`$50 Amazon gift card*`}
          hightlightColor={secondStepBgColor}
        />
        <ReferralStepCard
          stepNumber={3}
          stepText={_`Your friend starts a free trial`}
        />
        <ReferralStepCard
          stepNumber={4}
          stepText={_`The brand becomes a new Polar customer`}
          highlightText={_`$250 Polar credit*`}
          hightlightColor={fourthStepBgColor}
        />
      </Flex>
      <Space size={16} />
      <Text inline variant="body12Regular" color={theme.colors.text90}>
        {_`* See the`}{" "}
      </Text>
      <Text
        inline
        underline
        variant="body12Regular"
        color={theme.colors.text90}
        onClick={() => {
          trackEvent(TRACKING_EVENTS.REFERRALS_TEMS_AND_CONDITIONS_CLICKED, {
            userId: auth.user?.id,
            tenantId: tenant.id,
          });
        }}
      >
        <a
          style={{ color: theme.colors.text90 }}
          href={REFERRALS_TC_LINK}
          target="_blank"
          rel="noreferrer"
        >{_`referral program terms and conditions`}</a>
      </Text>
    </>
  );
};
