import classNames from "classnames";
import React from "react";

import { ClassAndStyleProps, InteractionEvents } from "../../shared";

import {
  StyledInputWrapper,
  StyledInputWrapperLabel,
  StyledInputWrapperMessage,
} from "./InputWrapper.styled";

export type InputWrapperProps = InteractionEvents<HTMLDivElement> &
  ClassAndStyleProps & {
    label?: string | React.ReactElement;
    htmlFor?: string;
    children: React.ReactNode;
    error?: string | React.ReactNode;
    helpText?: string;
    isFocused: boolean;
    disabled?: boolean;
  };

export default function InputWrapper({
  className,
  style,
  label,
  children,
  isFocused,
  disabled,
  error,
  helpText,
  htmlFor,
  ...interactionEvents
}: InputWrapperProps) {
  const messageText = error || helpText;
  return (
    <StyledInputWrapper
      {...interactionEvents}
      className={classNames(className)}
      style={style}
    >
      <StyledInputWrapperLabel
        htmlFor={htmlFor}
        {...{ error, isFocused, disabled }}
      >
        {label}
      </StyledInputWrapperLabel>
      {children}
      {messageText && (
        <StyledInputWrapperMessage type={error ? "error" : "help"}>
          {messageText}
        </StyledInputWrapperMessage>
      )}
    </StyledInputWrapper>
  );
}
