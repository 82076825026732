import { Box, Text, theme } from "../../../../icecube-ux";
import { CustomDimensionThenBlock } from "../../types";

import ThenFormulaElement from "./ThenFormulaElement";
import { DimensionAvailabilities } from "./utils";

interface FormulaThenElseBlockProps {
  editing: boolean;
  title: string;
  block: CustomDimensionThenBlock;
  onChange: (newBlock: CustomDimensionThenBlock) => void;
  availableBreakdownsForThenElse?: DimensionAvailabilities;
}

export default function FormulaThenElseBlock({
  editing,
  title,
  block,
  onChange,
  availableBreakdownsForThenElse,
}: FormulaThenElseBlockProps) {
  return (
    <Box>
      <Text variant="body13SemiBold" color={theme.colors.text110}>
        {title}
      </Text>
      <Box
        borderRadius="4px"
        border={`1px solid ${theme.colors.borderLight}`}
        padding="6px"
        marginTop="6px"
      >
        <ThenFormulaElement
          editing={editing}
          block={block}
          onChange={onChange}
          availableBreakdowns={availableBreakdownsForThenElse}
        />
      </Box>
    </Box>
  );
}
