import { useNavigate } from "react-router-dom";

import { Tooltip, Text, ConnectorIcon, Box } from "../../../../../icecube-ux";
import {
  TRACKING_EVENTS,
  trackEvent,
} from "../../../../../utils/trackingUtils";

export const PolarPixelTabIcon = () => {
  const navigate = useNavigate();
  return (
    <Tooltip
      mouseLeaveDelay={500}
      fullHeight
      style={{
        height: "22px",
        width: "22px",
      }}
      content={
        <Box maxWidth="200px" textAlign="center">
          <Text variant="body12Regular" color={"white"}>
            Your acquistion data is being
          </Text>
          <Text variant="body12Regular" color={"white"}>
            enhanced with the Polar Pixel
          </Text>
          <Text
            underline
            variant="body12Regular"
            color={"white"}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              trackEvent(TRACKING_EVENTS.PIXEL_SETUP_CTA_CLICKED, {
                buttonId: "Left Menu - See Pixel Health",
              });
              navigate("/connectors/polar-pixel/setup/shopify");
            }}
          >
            See pixel health
          </Text>
        </Box>
      }
    >
      {" "}
      <ConnectorIcon
        size={14}
        withBorder
        withBackground
        name="PolarPixel"
      ></ConnectorIcon>
    </Tooltip>
  );
};
