import { useState } from "react";

export const useCopyToClipboard = ({ timeout = 2000 } = {}) => {
  const [hasJustCopied, setHasJustCopied] = useState(false);

  const copyToClipboard = (value: string) => {
    void navigator.clipboard.writeText(value);
    setHasJustCopied(true);
    setTimeout(() => setHasJustCopied(false), timeout);
  };

  return {
    copyToClipboard,
    hasJustCopied,
  };
};
