import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// import { useBootstrap } from "../../hooks/bootstrap";
import { Icon, theme, Text, Flex, Box } from "../../icecube-ux";

const Wrapper = styled(Flex)`
  cursor: pointer;
  overflow: visible;
  position: relative;
`;

const IconContainer = styled.div`
  display: flex;
  border-radius: 8px;
  overflow: hidden;
`;

// We display try tab only to the new customer who is not on custom plan and who doesn't have the addons yet
export const AddonTab = ({ isExpanded }: { isExpanded: boolean }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const handleOpen = () => {
    navigate("/advancedFeatures");
  };

  // const { isDemoData } = useBootstrap();
  return 1 > [1, 2, 3].length ? ( // Hidden for now
    <Wrapper ref={ref}>
      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        rowGap={4}
        columnGap={12}
        onClick={handleOpen}
        style={{ padding: 5 }}
        fullWidth
      >
        <IconContainer>
          <Icon name="TryAddon" size={40}></Icon>
        </IconContainer>
        {isExpanded && (
          <Box flexGrow="1">
            <Text variant="body12Medium" color={theme.colors.text110}>
              {`Advanced Features`}
            </Text>
            <Text variant="body10Regular" color={theme.colors.text90}>
              {`Polar Pixel, CAPI and more...`}
            </Text>
          </Box>
        )}
      </Flex>
    </Wrapper>
  ) : (
    <div></div>
  );
};
