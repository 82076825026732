import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { _ } from "../../languages/helper";
import { trackEvent, TRACKING_EVENTS } from "../../utils/trackingUtils";
import { Button } from "../Button";
import { Flex } from "../Flex";
import { Icon } from "../Icon";
import { Tag } from "../Tag";
import { theme } from "../theme";
import { Text } from "../Typography";

const StyledButton = styled(Button)`
  &:hover .new-explore-metric-tag {
    background: ${theme.colors.primary30};
    color: ${theme.colors.primary110};
    border: 1px solid ${theme.colors.primary30};
  }
`;

export const ExploreMetricLink = ({
  metricKey,
  isNew,
}: {
  metricKey?: string;
  isNew?: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <StyledButton
      size="small"
      style={{ width: "100%", gridColumn: "1 / -1" }}
      color="secondary"
      onClick={() => {
        trackEvent(TRACKING_EVENTS.KEY_INDICATOR_EXPLORE_WHY_CLICKED, {
          metricKey: metricKey || "N/A",
        });
        navigate(`/metric/${metricKey}`);
      }}
    >
      <Flex gap={4}>
        <Icon color={theme.colors.primary100} name="FlowChart"></Icon>
        <Text variant="body11Regular" color={theme.colors.primary100}>
          {_`Explore metric`}
        </Text>
        {isNew && (
          <Tag className="new-explore-metric-tag" color="primary">{_`New`}</Tag>
        )}
      </Flex>
    </StyledButton>
  );
};
