import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DASHBOARD_STATES } from "../common/setupState/consts";
import {
  SUBSCRIPTION_TYPES,
  PlanPriceMapping,
  PlanName,
  PlanSelector,
  SUBSCRIPTION_TYPE_NAMES,
} from "../common/types/subscription-service";
import { PricingModels } from "../common/types/users-service";
import {
  createStripeCheckoutSession,
  createStripeCustomerPortalSession,
  getUsageBasedPrice,
  upgradeStripeSubscription,
  getGMVTierBasedPrice,
} from "../lib/subscriptionsService";
import { ConfirmationProperties } from "../shared/Plans/components/ConfirmationPopup";

import { useAuth } from "./auth/auth";
import { useBootstrap } from "./bootstrap";
import { refetchQueriesByQueryKey } from "./query/utils";
import { useAddonPopup } from "./useAddonPopup";

export const isNewCustomer = (price: PlanPriceMapping | null | undefined) => {
  if (!price) return false;
  return !!price?.USAGE_BASED_PLUS_PLAN;
};

export const useTenantPlan = () => {
  const {
    subscription,
    tenant: { states },
  } = useBootstrap();

  const isActive = subscription?.valid_until
    ? new Date(subscription.valid_until) >= new Date()
    : true;

  const result = {
    isOnUsageBasedPlan:
      isActive && subscription?.plan === SUBSCRIPTION_TYPES.USAGE_BASED_PLAN,
    isOnUsageBasedPlusPlan:
      isActive &&
      subscription?.plan === SUBSCRIPTION_TYPES.USAGE_BASED_PLUS_PLAN,
    isOnUsageBasedPlusActivatePlan:
      isActive &&
      subscription?.plan === SUBSCRIPTION_TYPES.USAGE_BASED_PLUS_ACTIVATE_PLAN,
    isOnCustomPlan: isActive && (states?.isOnCustomPlan ?? false),
  };

  return {
    ...result,
    isOnAnyUsageBasedPlan:
      result.isOnUsageBasedPlan ||
      result.isOnUsageBasedPlusPlan ||
      result.isOnUsageBasedPlusActivatePlan,
  };
};

export const useAddonInfoRedirect = () => {
  const { setAddon } = useAddonPopup();
  const navigate = useNavigate();

  const handleSeeAllPlans = useCallback(() => {
    setAddon(undefined);
    navigate("/settings/account");
  }, [navigate, setAddon]);
  const handleLearnMore = useCallback(() => {
    window.open(
      "https://intercom.help/polar-app/en/articles/6531316-how-does-pricing-work",
      "_blank",
    );
  }, []);

  return {
    handleSeeAllPlans,
    handleLearnMore,
  };
};

const USAGE_BASED_PRICE_QUERY_KEY = ["usageBasedPrice"];
export const useUsageBasedPricing = () => {
  const auth = useAuth();
  return useQuery(USAGE_BASED_PRICE_QUERY_KEY, async () => {
    const token = await auth.getToken();
    return getUsageBasedPrice(token);
  });
};

const GMV_TIER_BASED_PRICE_QUERY_KEY = ["gmvTierBasedPrice"];
export const useGMVTierBasedPricing = () => {
  const auth = useAuth();
  return useQuery(GMV_TIER_BASED_PRICE_QUERY_KEY, async () => {
    const token = await auth.getToken();
    return getGMVTierBasedPrice(token);
  });
};

export const useShowNewPricingComponents = () => {
  const {
    tenant: {
      states: {
        hasBookedCall = false,
        appActivated = false,
        dashboard = DASHBOARD_STATES.NO_DATA,
      },
    },
    getUserTenantSetting,
  } = useBootstrap();
  const { isOnCustomPlan } = useTenantPlan();
  const { isLoading, data: usageBasedPricing } = useUsageBasedPricing();
  const isOnGMVPricing =
    getUserTenantSetting("pricing_model", undefined) ===
    PricingModels.GMV_TIER_BASED;
  const [isUserEligibleToSeeNewPricing, setIsUserEligibleToSeeNewPricing] =
    useState(false);
  useEffect(() => {
    if (isLoading) return;
    if (
      (isOnGMVPricing || isNewCustomer(usageBasedPricing?.price)) &&
      !isOnCustomPlan
    ) {
      setIsUserEligibleToSeeNewPricing(
        appActivated && dashboard !== DASHBOARD_STATES.NO_DATA,
      );
    } else {
      setIsUserEligibleToSeeNewPricing(false);
    }
  }, [
    isLoading,
    isOnCustomPlan,
    usageBasedPricing,
    hasBookedCall,
    appActivated,
    dashboard,
    isOnGMVPricing,
  ]);

  return {
    isUserEligibleToSeeNewPricing,
  };
};

type UseRedirectToStripeCheckout = {
  setConfirmationProperties?: (args: ConfirmationProperties | null) => void;
};

export const useRedirectToStripeCheckout = ({
  setConfirmationProperties,
}: UseRedirectToStripeCheckout = {}) => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const { isOnAnyUsageBasedPlan } = useTenantPlan();
  const [isRedirecting, setIsRedirecting] = useState(false);

  const redirectToNewStripeCheckoutSession = useCallback(
    async (planSelector: PlanSelector) => {
      setIsRedirecting(true);
      const session = await createStripeCheckoutSession(
        await auth.getToken(),
        planSelector,
      );
      if (session) {
        window.location.href = session.url;
      } else {
        setIsRedirecting(false);
      }
    },
    [auth],
  );

  const redirectUpgradeStripeSubscription = useCallback(
    async (planSelector: PlanSelector) => {
      setIsRedirecting(true);
      const session = await upgradeStripeSubscription(
        await auth.getToken(),
        planSelector,
      );

      if (session) {
        if (!session.url) {
          // wait here to let the stripe web hook to be called to update the subscription
          await new Promise((resolve) => setTimeout(resolve, 2000));
        }
        window.location.href = session.url || "/";
        // here to invalidate the usage based pricing query cache
        await refetchQueriesByQueryKey(
          queryClient,
          USAGE_BASED_PRICE_QUERY_KEY,
        );
      }
    },
    [auth, queryClient],
  );

  const redirectStripeUsageBasedCheckout = useCallback(
    (planSelector: PlanSelector) => {
      const isFirstUsageSubscription = !isOnAnyUsageBasedPlan;
      if (isFirstUsageSubscription) {
        void redirectToNewStripeCheckoutSession(planSelector);
      } else if (setConfirmationProperties) {
        setConfirmationProperties({
          planSelector,
          onDecline: () => setConfirmationProperties(null),
          onConfirm: () => redirectUpgradeStripeSubscription(planSelector),
        });
      } else {
        void redirectUpgradeStripeSubscription(planSelector);
      }
    },
    [
      setConfirmationProperties,
      isOnAnyUsageBasedPlan,
      redirectToNewStripeCheckoutSession,
      redirectUpgradeStripeSubscription,
    ],
  );

  const redirectToStripeCustomerPortal = async () => {
    setIsRedirecting(true);
    const session = await createStripeCustomerPortalSession(
      await auth.getToken(),
    );
    if (session) {
      window.location.href = session.url;
    }
  };
  return {
    isRedirecting,
    redirectStripeUsageBasedCheckout,
    redirectToStripeCustomerPortal,
  };
};

export const useHighlightedPlan = () => {
  const {
    tenant: { settings },
  } = useBootstrap();
  const { isOnUsageBasedPlan, isOnUsageBasedPlusPlan, isOnCustomPlan } =
    useTenantPlan();

  const reportedGMV = settings.onboarding_form?.responses.find(
    (response) => response.questionKey === "reported_gmv",
  )?.selection[0];

  let highlightedPlan: PlanName | null = null;

  if (reportedGMV === "<1m" && !isOnUsageBasedPlan) {
    highlightedPlan = SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLAN;
  } else if (reportedGMV === ">20m" && !isOnCustomPlan) {
    highlightedPlan = SUBSCRIPTION_TYPE_NAMES.CUSTOM;
  } else if (
    reportedGMV &&
    reportedGMV !== "agency" &&
    !isOnUsageBasedPlusPlan
  ) {
    highlightedPlan = SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLUS_PLAN;
  }

  return { highlightedPlan };
};

export const useSubscriptionEnd = () => {
  const { subscription } = useBootstrap();

  const isSubscriptionEnding = subscription?.cancel_at_end ?? false;
  const subscriptionEndDate = subscription?.valid_until;

  return { isSubscriptionEnding, subscriptionEndDate };
};
