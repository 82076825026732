import styled from "styled-components";

import { legacyTheme } from "../legacyTheme";
import { theme } from "../theme";

export const StyledConnectorIconWrapper = styled.div<{
  wrapperSize: string;
  withBorder?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: ${legacyTheme.colors.white};
  padding: ${legacyTheme.padding.tiny};
  height: ${({ wrapperSize }) => wrapperSize}};
  width: ${({ wrapperSize }) => wrapperSize}};
  border-radius: ${legacyTheme.borderRadius.tiny};
  border: ${({ withBorder }) =>
    withBorder ? `1px solid ${theme.colors.borderLight}` : "initial"}}; 
`;
