import { useEffect, useState } from "react";

import { FiltersIcon } from "../../../components/Icons/Icons";
import { Loader } from "../../../components/Loader/Loader";
import { useAuth } from "../../../hooks/auth/auth";
import { useBootstrap } from "../../../hooks/bootstrap";
import { useConnectorObservability } from "../../../hooks/connectorObservability";
import { useCustomElementEditors } from "../../../hooks/customElementEditors";
import { useMetricList } from "../../../hooks/metricList";
import { useModals } from "../../../hooks/modals";
import { useCanSeePixel } from "../../../hooks/pixelConfiguration";
import { useAddonPopup } from "../../../hooks/useAddonPopup";
import { useTenantAddOns } from "../../../hooks/useAddonStatuses";
import { useShowNewPricingComponents } from "../../../hooks/useAddonUtils";
import {
  Icon,
  Button,
  Flex,
  Grid,
  GroupedList,
  Input,
  Tooltip,
  Space,
  theme,
} from "../../../icecube-ux";
import { getIntegrationHandler } from "../../../integrations/integration";
import { _ } from "../../../languages/helper";
import { AddonTag } from "../../../shared/Plans/analyzeEnrichPlan/AddonTag";
import {
  CustomMetricDefinition,
  IMetricsList,
} from "../../../types/synthesizer";

import NoResult from "./NoResult";
import "./metric-list.css";
import {
  MetricOption,
  getFilteredMetricList,
  getMetricBlockList,
} from "./utils";

const MAX_METRICS = 20;

interface MetricListProps {
  search: string;
  reportId?: string;
  onSearchChange: (value: string) => void;
  selectedMetrics: string[];
  onClickMetric?: (value: string) => void;
  onAddMetric?: (value: string) => void;
  onRemoveMetric?: (value: string) => void;
  onChangeMetric?: (values: string[]) => void;
  onClearMetrics?: () => void;
  loading: boolean;
  metric: CustomMetricDefinition | null;
  metricList: IMetricsList;
  disableCheckbox?: boolean;
  startsWithMode?: boolean;
  hideCustomMetric?: boolean;
  filteredSelectedIndex?: number;
  onFilteredSelectedIndexChange?: (index: number) => void;
}

export default function MetricList({
  search,
  onSearchChange,
  selectedMetrics,
  onAddMetric,
  onRemoveMetric,
  onClearMetrics = () => {},
  loading,
  metric,
  metricList,
  onClickMetric,
  disableCheckbox = false,
  startsWithMode = true,
  hideCustomMetric = false,
  filteredSelectedIndex = 0,
  onFilteredSelectedIndexChange = () => {},
}: MetricListProps) {
  const bootstrap = useBootstrap();
  const { statuses } = useConnectorObservability();
  const { tableKeyToConnectorKey } = useMetricList();
  const { canSeePixelMetrics } = useCanSeePixel();
  const auth = useAuth();
  const modal = useModals();
  const elementEditors = useCustomElementEditors();
  const { hasPermission } = bootstrap;
  const canExplore = hasPermission("data.explore");

  const [showAddonTag, setShowAddonTag] = useState({
    capi: true,
    polarPixel: true,
  });
  const { setAddon } = useAddonPopup();
  const { isUserEligibleToSeeNewPricing } = useShowNewPricingComponents();
  const { isCapiEnabled, isPolarPixelEnabled } = useTenantAddOns();

  const [openBlocks, setOpenBlocks] = useState("");
  const [filteredList, setFilteredList] = useState<MetricOption[]>([]);

  const getAddonTooltip = (addonTooltip?: "capi" | "polar-pixel") => {
    if (!addonTooltip || !isUserEligibleToSeeNewPricing) {
      return null;
    }
    const { capi: showCapi, polarPixel: showPolarPixel } = showAddonTag;
    if (!isCapiEnabled && addonTooltip === "capi" && showCapi) {
      return (
        <AddonTag
          addonName="Capi"
          tagText={_`CAPI`}
          onClickAddon={() => setAddon("capi")}
          onClose={() => setShowAddonTag({ ...showAddonTag, capi: false })}
        />
      );
    } else if (!isPolarPixelEnabled && showPolarPixel && !canSeePixelMetrics) {
      return (
        <AddonTag
          addonName="PolarPixel"
          tagText={_`Pixel`}
          onClickAddon={() => setAddon("polar_pixel")}
          onClose={() =>
            setShowAddonTag({ ...showAddonTag, polarPixel: false })
          }
        />
      );
    }
  };

  const handleToggle = (key: string) => {
    setOpenBlocks(openBlocks === key ? "" : key);
  };

  const handleClickOnMetric = (value: string) => {
    onClickMetric && onClickMetric(value);
    if (!selectedMetrics.includes(value)) {
      if (selectedMetrics.length < MAX_METRICS) {
        onAddMetric && onAddMetric(value);
      } else {
        modal.alert({
          danger: true,
          title: _`Maximum reached`,
          texts: [
            _`You cannot add more than ${[
              "max",
              String(MAX_METRICS),
            ]} metrics to a single report.`,
          ],
        });
      }
    } else {
      onRemoveMetric && onRemoveMetric(value);
    }
  };

  const handleSearchChange = (v: string) => {
    onSearchChange(v);
    onFilteredSelectedIndexChange(0);
  };

  const handleAutoComplete = (filteredList: MetricOption[]) => {
    if (onClickMetric && filteredList.length > filteredSelectedIndex) {
      onClickMetric(filteredList[filteredSelectedIndex].value);
      onSearchChange("");
    } else if (onAddMetric && filteredList.length > filteredSelectedIndex) {
      onAddMetric(filteredList[filteredSelectedIndex].value);
      onSearchChange("");
    }
  };

  const handleChangeAutoCompleteIndex = (down: boolean) => {
    const newIndex = down
      ? filteredSelectedIndex + 1
      : filteredSelectedIndex - 1;
    onFilteredSelectedIndexChange(newIndex);
  };

  const handleUseAskPolar = () => {
    modal.confirm({
      title: _`This action will overwrite any selected metrics`,
      texts: [
        _`If you use Ask Polar to build this report, it will clear your existing report.`,
        _`Are you sure you want to do this?`,
      ],
      cancelButtonLabel: _`No, stay in manual mode`,
      confirmButtonLabel: _`Yes, continue with Ask Polar`,
      onConfirm: () => {
        onClearMetrics();
      },
    });
  };

  useEffect(() => {
    const newFiltered = getFilteredMetricList(
      search,
      metricList,
      statuses,
      tableKeyToConnectorKey,
      startsWithMode,
    );
    const metrics = Object.entries(newFiltered)
      .map(([_, t]) => [...t.raw, ...t.computed])
      .flat();
    setFilteredList(metrics);
    onFilteredSelectedIndexChange(
      Math.min(filteredList.length - 1, Math.max(0, filteredSelectedIndex)),
    );
    // eslint-disable-next-line
  }, [
    search,
    metricList,
    bootstrap.tenant.settings,
    startsWithMode,
    filteredSelectedIndex,
    filteredList.length,
  ]);

  useEffect(() => {
    const validIndex = Math.min(
      filteredList.length - 1,
      Math.max(0, filteredSelectedIndex),
    );
    if (validIndex !== filteredSelectedIndex) {
      onFilteredSelectedIndexChange(validIndex);
    }
    // eslint-disable-next-line
  }, [filteredSelectedIndex, filteredList.length]);

  if (loading) {
    return (
      <div className="metric-list loading">
        <Loader color={"#E3E3FF"} size={24} />
      </div>
    );
  }

  const metricBlocks = getMetricBlockList({
    showCustomMetric: !hideCustomMetric,
    metric,
    metricList,
    startsWithMode,
    tenant: bootstrap?.tenant,
    isAdmin: auth.isAdmin(),
    search,
    createMetric: elementEditors.createMetric,
    editMetric: elementEditors.editMetric,
    duplicateMetric: elementEditors.duplicateMetric,
    deleteMetric: elementEditors.deleteMetric,
    canSeePixelMetrics,
    statuses,
    tableKeyToConnectorKey,
  });

  const metricCount = metricBlocks.reduce(
    (sum, block) => sum + Object.values(block.metrics).flat().length,
    0,
  );

  return (
    <div className="metric-list">
      <div className="searchbox">
        <Input
          block
          leftIcon="Search"
          rightIcon={search !== "" ? "CloseCircle" : undefined}
          value={search}
          onChange={(v) => handleSearchChange(v as string)}
          placeholder="Find a metric"
          className="margin-top-xlarge"
          onClickOnRightIcon={() => handleSearchChange("")}
          onKeyDown={(event) => {
            if (event.key === "Enter" || event.key === "Tab") {
              handleAutoComplete(filteredList);
            }
            if (event.key === "ArrowDown" || event.key === "ArrowUp") {
              event.preventDefault();
              event.stopPropagation();
              handleChangeAutoCompleteIndex(event.key === "ArrowDown");
              return false;
            }
          }}
        />
      </div>
      <div className="scrollbox">
        {search !== "" && filteredList.length > 0 && (
          <Grid gridTemplateColumns="1fr auto" className="margin-bottom-xlarge">
            <div className="result-count">
              {metricCount} result{filteredList.length > 1 ? "s" : ""}
            </div>
          </Grid>
        )}
        {filteredList.length === 0 && search !== "" && (
          <NoResult
            title={_`Oops!`}
            text={_`No metric found`}
            image="no-result-found.svg"
          />
        )}
        {!disableCheckbox &&
          canExplore &&
          search === "" &&
          selectedMetrics.length > 0 && (
            <Flex justifyContent="space-between" alignItems="center">
              <Button
                color="secondary"
                rightIcon="Sparkle"
                onClick={handleUseAskPolar}
              >
                {_`Use Ask Polar`}
              </Button>
              <div className="clear-filters" onClick={onClearMetrics}>
                <FiltersIcon size={16} className="mrx-8" />
                {_`Clear metrics`}
              </div>
            </Flex>
          )}
        <div
          className={
            "cb" +
            (disableCheckbox || !canExplore || selectedMetrics.length === 0
              ? " mtx-16"
              : "")
          }
        ></div>
        {metricBlocks.map(
          ({
            label,
            blockKey,
            icon,
            background,
            iconComponent,
            metrics,
            actions,
            disabled,
            headerInfo,
            addonTooltip,
          }) => {
            const connector = getIntegrationHandler(
              tableKeyToConnectorKey[blockKey]?.[0],
            );
            return (
              <GroupedList
                key={`metriclistblock-${blockKey}`}
                className="margin-bottom-xlarge"
                icon={icon}
                background={background}
                iconComponent={iconComponent}
                label={label}
                connected={true}
                opened={openBlocks === blockKey}
                direction={"vertical"}
                onToggleOpen={() => handleToggle(blockKey)}
                selected={selectedMetrics}
                groupedList={metrics}
                onClickOnElement={(v) => handleClickOnMetric(v as string)}
                allowSelection={!disableCheckbox}
                search={search}
                canUnselectWhenDisabled={true}
                addingDisabled={
                  selectedMetrics.length >= MAX_METRICS || disabled
                }
                sectionDisabled={
                  disabled ||
                  (bootstrap.isDemoData && blockKey === "Custom Metrics")
                }
                headerInfo={
                  <>
                    {connector?.customConnectorInfo && (
                      <Tooltip
                        direction="top"
                        content={
                          <>
                            {connector.label}
                            <Space type="small" />
                            <Flex asHTMLTag="li" flexDirection="column" gap={2}>
                              {connector.customConnectorInfo.map((line) => (
                                <li key={line}>{line}</li>
                              ))}
                            </Flex>
                          </>
                        }
                      >
                        <Icon
                          color={theme.colors.primary50}
                          name="InfoCircle"
                        ></Icon>
                      </Tooltip>
                    )}
                    {headerInfo}
                    {getAddonTooltip(addonTooltip)}
                  </>
                }
                onClickPlusButton={actions}
              />
            );
          },
        )}
      </div>
    </div>
  );
}
