import "./formula-error.css";
import { _ } from "../../../../languages/helper";

interface FormulaErrorProps {
  reason: string;
}

export default function FormulaError({ reason }: FormulaErrorProps) {
  return (
    <div className="formula-error">
      <div>
        {_`Oh snap!`} <img src="/img/emojis/woops.png" alt="" />
      </div>
      {_`Invalid formula. ${["reason", reason]} Check your formula.`}
    </div>
  );
}
