import classNames from "classnames";

import { ClassAndStyleProps, InteractionEvents } from "../shared";

import { StyledWireframeLoader } from "./WireframeLoader.styled";

export type WireframeLoaderProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    height: string;
    width: string;
    radius?: number;
    background?: string;
  };

export default function WireframeLoader({
  width,
  height,
  radius,
  background,
  className,
  style,
  ...interactionEvents
}: WireframeLoaderProps) {
  return (
    <StyledWireframeLoader
      {...interactionEvents}
      className={classNames(className)}
      style={{
        ...style,
        width,
        height,
        background,
        borderRadius: radius ? `${radius}px` : undefined,
      }}
    />
  );
}
