import { AddIcon } from "../../../components/Icons/Icons";
import { Button } from "../../../icecube-ux";
import { _ } from "../../../languages/helper";
import "./no-result.css";

export default function NoResult({
  title,
  text,
  image,
  onClick,
}: {
  title: string;
  text: string;
  image: string;
  onClick?: () => void;
}) {
  return (
    <div className="no-metric-found">
      <img src={`/img/${image}`} alt="" />
      <strong>{title}</strong>
      <p>{text}</p>
      {onClick && (
        <Button size="small" block color="tertiary" onClick={onClick}>
          <AddIcon size={14} className="mrx-8" />
          {_`Add metric`}
        </Button>
      )}
    </div>
  );
}
