import { randomString } from "../utils/utils";

import { getExtraHeaders, getServiceHost } from "./services";

export const createClient = (serviceName: string) => {
  return {
    new: (signal?: AbortSignal) => new ApiClient(serviceName, signal),
  };
};
interface FetchError {
  error: true;
  message?: string;
}

interface FetchResult {
  error: false;
  data: unknown;
}

type ResultOrError<T, E> = (FetchResult & T) | (FetchError & E);
// this header is used to pass the current url to the backend to distinguish the source of the request
const XComposeContextHeaderName = "x-compose-context";
const XRequestTimeHeaderName = "x-request-start-time";

class ApiClient {
  serviceName: string;
  abortSignal?: AbortSignal;
  method = "POST";
  endpoint = "/";
  token = "";
  bodyData: object | null = null;
  headersObject: Record<string, string> = {};

  private shouldTrackDuration: boolean = false;

  constructor(serviceName: string, signal?: AbortSignal) {
    this.serviceName = serviceName;
    this.abortSignal = signal;
  }

  trackDuration = () => {
    this.shouldTrackDuration = true;
    return this;
  };

  fetch = async <T = {}, E = {}>(
    logContext?: string,
  ): Promise<ResultOrError<T, E>> => {
    try {
      const response = await this.normalFetch(logContext);
      return (await response.json()) as ResultOrError<T, E>;
    } catch (e) {
      const error = e as { message?: string } | undefined;
      if (!this.abortSignal || !this.abortSignal.aborted) {
        console.error({
          error: true,
          message: error?.message || "",
          data: [],
          result: [],
        });
      }
      return { error: true, message: error?.message ?? "" };
    } finally {
      this.shouldTrackDuration = false;
    }
  };

  private async normalFetch(logContext: string | undefined) {
    const response = await fetch(
      getServiceHost(this.serviceName) + this.endpoint,
      {
        method: this.method,
        signal: this.abortSignal,
        headers: {
          ...getExtraHeaders(),
          [XComposeContextHeaderName]: window.location.href,
          ...(this.shouldTrackDuration
            ? { [XRequestTimeHeaderName]: Date.now().toString() }
            : {}),
          "Content-Type": "application/json",
          LogContext: logContext ?? randomString(12),
          ...(this.token !== "" ? { token: this.token } : {}),
          ...this.headersObject,
        },
        ...(this.bodyData !== null
          ? { body: JSON.stringify(this.bodyData) }
          : {}),
      },
    );
    return response;
  }

  stream = async (logContext?: string) => {
    try {
      const response = await this.normalFetch(logContext);
      if (response.ok && response.body) {
        return {
          error: false,
          message: "",
          reader: response?.body?.getReader(),
        };
      }
    } catch (e) {
      const error = e as { message?: string } | undefined;
      if (!this.abortSignal || !this.abortSignal.aborted) {
        console.error({
          error: true,
          message: error?.message || "",
          data: [],
          result: [],
        });
      }
      return { error: true, message: error?.message ?? "" };
    } finally {
      this.shouldTrackDuration = false;
    }
  };

  headers = (headers: Record<string, string>) => {
    this.headersObject = headers;
    return this;
  };

  body = <T extends object>(data: T) => {
    this.bodyData = data;
    return this;
  };

  auth = (token: string) => {
    this.token = token;
    return this;
  };

  post = (endpoint: string) => {
    this.method = "POST";
    this.endpoint = endpoint;
    return this;
  };

  get = (endpoint: string) => {
    this.method = "GET";
    this.endpoint = endpoint;
    return this;
  };

  patch = (endpoint: string) => {
    this.method = "PATCH";
    this.endpoint = endpoint;
    return this;
  };

  put = (endpoint: string) => {
    this.method = "PUT";
    this.endpoint = endpoint;
    return this;
  };

  delete = (endpoint: string) => {
    this.method = "DELETE";
    this.endpoint = endpoint;
    return this;
  };
}
