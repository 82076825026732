const SERVICES_HOSTS = JSON.parse(
  (process.env.REACT_APP_SERVICE_ENDPOINTS as string) || "{}",
) as Record<string, string>;

export const getServiceHost = (key: string) => {
  const host = localStorage.getItem(`${key}-host`);
  if (!host || `${host}` === "undefined") {
    localStorage.setItem(`${key}-host`, SERVICES_HOSTS[key]);
    return SERVICES_HOSTS[key];
  }
  return host;
};

export const getExtraHeaders = () => {
  const useDemoTenantV2 = localStorage.getItem(`demo-tenant-v2`) === "1";
  return {
    ...(useDemoTenantV2 ? { demoDataV2: "1" } : {}),
  } as Record<string, string>;
};
