import ReportTableHeaderColumnCell from "./ReportTableHeaderColumnCell";
import { ReportTableHeaderColumn } from "./types";

type ReportTableHeaderColumnsProps = {
  checkbox?: boolean;
  left: boolean;
  columns: ReportTableHeaderColumn[];
  lineIndex: number;
  startDragEvent: (index: number) => void;
  dragStatus: { movingIndex: number | null; toIndex: number | null };
  readOnly?: boolean;
  minimalColumnHeader?: boolean;
};

export default function ReportTableHeaderColumns({
  checkbox,
  left,
  columns,
  lineIndex,
  startDragEvent,
  dragStatus,
  readOnly,
  minimalColumnHeader,
}: ReportTableHeaderColumnsProps) {
  let columnStart = checkbox ? 2 : 1;
  let columnEnd = checkbox ? 2 : 1;
  return (
    <>
      {columns.map((column, i) => {
        columnStart = columnEnd;
        columnEnd = columnStart + (column.columnSpan || 1);
        if (column.hidden) {
          return null;
        }

        return (
          <ReportTableHeaderColumnCell
            key={`column-${column.key}`}
            left={left}
            column={column}
            columnStart={columnStart}
            columnEnd={columnEnd}
            startDragEvent={startDragEvent}
            position={{ row: lineIndex, column: i }}
            dragStatus={dragStatus}
            readOnly={readOnly}
            minimalColumnHeader={minimalColumnHeader}
          />
        );
      })}
    </>
  );
}
