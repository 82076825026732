import styled from "styled-components";

import { theme } from "../theme";
import { Text } from "../Typography";

export const StyledExpandableButtonContainer = styled.div`
  border-radius: 6px;
  border: 1px solid ${theme.colors.borderLight};
`;

export const StyledExpandableButtonHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;

  padding: 14px 16px;
  height: 50px;
  cursor: pointer;
`;

export const StyledText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledExpandableButtonDetail = styled.div`
  display: flex;
  flex-direction: column;
`;
