import { Box, NotificationMessage } from "../../../icecube-ux";

export const StyledDataSyncingBanner = () => {
  return (
    <Box paddingBottom={"12px"}>
      <NotificationMessage
        variant="primary"
        withIcon
        size={"medium"}
        title={"Your Shopify data is still syncing"}
        textContent={
          "You will be able to see your personalized pricing on this page once your data is ready."
        }
        style={{ padding: "8px 8px", maxWidth: "1440px" }}
      />
    </Box>
  );
};
