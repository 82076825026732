import styled from "styled-components";

import {
  ConnectorIcon,
  ConnectorIconNames,
  Flex,
  Space,
  Text,
  TextVariant,
  theme,
} from "../../icecube-ux";
import { ClassAndStyleProps } from "../../icecube-ux/shared";

type IncentiveCardProps = {
  title: string;
  rewardQuantity: string;
  rewardText: string;
  paragraph?: string;
  titleColor: string;
  iconName: ConnectorIconNames;
  iconBackground: string;
  margin?: string;
  titleTextVariant?: TextVariant;
  rewardQuantityTextVariant?: TextVariant;
  rewardTextVariant?: TextVariant;
  paragraphTextVariant?: TextVariant;
} & ClassAndStyleProps;

const StyledIncentiveCard = styled(Flex)`
  padding: 20px;
  flex-grow: 1;
  border-radius: 6px;
  border: 1px solid ${theme.colors.borderLight};
  background: ${theme.colors.white100};
`;

const StyledConnectorIconWrapper = styled.div<{ iconBackground: string }>`
  border-radius: 4px;
  height: 24px;
  width: 24px;
  background-color: ${({ iconBackground }) => iconBackground};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IncentiveCard = ({
  title,
  titleColor,
  rewardQuantity,
  rewardText,
  paragraph,
  iconName,
  iconBackground,
  style,
  titleTextVariant = "body14Medium",
  rewardQuantityTextVariant = "body24Medium",
  rewardTextVariant = "body14Regular",
  paragraphTextVariant = "body12Regular",
}: IncentiveCardProps) => (
  <StyledIncentiveCard flexDirection="column" style={{ ...style }}>
    <Flex alignItems="center" gap={10}>
      <StyledConnectorIconWrapper iconBackground={iconBackground}>
        <ConnectorIcon name={iconName} size={14} />
      </StyledConnectorIconWrapper>
      <Text variant={titleTextVariant} color={titleColor}>
        {title}
      </Text>
    </Flex>
    <Space size={8} />
    <Flex alignItems="baseline" gap={8}>
      <Text variant={rewardQuantityTextVariant} color={theme.colors.text110}>
        {rewardQuantity}
      </Text>
      <Text variant={rewardTextVariant} color={theme.colors.text110}>
        {rewardText}
      </Text>
    </Flex>

    {paragraph ? (
      <Text
        style={{ maxWidth: "350px" }}
        variant={paragraphTextVariant}
        color={theme.colors.text90}
      >
        {paragraph}
      </Text>
    ) : (
      <></>
    )}
  </StyledIncentiveCard>
);
