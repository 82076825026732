import { axisLeft, ScaleLinear, select } from "d3";
import { useLayoutEffect, useRef } from "react";

import { theme } from "../../theme";

interface GridProps {
  width: number;
  height: number;
  yScale: ScaleLinear<number, number>;
  tickCount: number;
  margin: [number, number, number, number];
}

export default function Grid({
  width,
  height,
  yScale,
  margin,
  tickCount,
}: GridProps) {
  const gridRef = useRef(null);

  useLayoutEffect(() => {
    select(gridRef.current).selectAll("*").remove();

    const yAxis = axisLeft(yScale).ticks(tickCount);

    const grid = select(gridRef.current)
      .append("g")
      .style("stroke-opacity", "0.25")
      .style("stroke-width", "1")
      .style("color", theme.colors.borderLight)
      .call(
        yAxis.tickSize(-width + margin[3] + margin[1]).tickFormat(() => ""),
      );
    grid.select("path").style("display", "none");
  }, [tickCount, yScale, width, margin, height]);

  return <g transform={`translate(${margin[3]}, 0)`} ref={gridRef} />;
}
