import { useAuth } from "../../hooks/auth/auth";
import { Box, Button } from "../../icecube-ux";
import { _ } from "../../languages/helper";
import { resetViewEditor } from "../CountryFilters/views/ViewProvider";

export default function FloatingLogout() {
  const { logout } = useAuth();

  return (
    <Box position="fixed" top="10px" right="10px">
      <Button
        color="tertiary"
        leftIcon="Logout"
        onClick={() => logout(resetViewEditor)}
      >{_`Logout`}</Button>
    </Box>
  );
}
