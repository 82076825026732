import { _ } from "../languages/helper";

type IntegrationCategory<TKey extends string> = {
  key: TKey;
  label: string;
};

const createIntegrationCategory = <TKey extends string>(key: TKey) => {
  return {
    [key]: {
      key,
      get label() {
        return _(key);
      },
    },
  } as { [key in TKey]: IntegrationCategory<TKey> };
};

export const INTEGRATION_CATEGORIES = {
  ...createIntegrationCategory("sales_channels"),
  ...createIntegrationCategory("analytics_and_attribution"),
  ...createIntegrationCategory("advertising"),
  ...createIntegrationCategory("marketing"),
  ...createIntegrationCategory("other_datasources"),
};

export type IntegrationCategoryKey = keyof typeof INTEGRATION_CATEGORIES;
