import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { useBootstrap } from "../../hooks/bootstrap";
import {
  Box,
  Text,
  theme,
  Icon,
  MoreOptions,
  Tag,
  OptionItem,
} from "../../icecube-ux";
import { IconNames } from "../../icecube-ux/Icon/Icon";
import { _ } from "../../languages/helper";
import { LIVE_DEMO_TENANT_ID } from "../../utils/demoConfig";
import { shouldShowReferral } from "../../utils/subscriptionsUtils";

interface AvatarBlockProps {
  onClickSettings?: () => void;
  onClickHelp?: () => void;
  onClickLogout?: () => void;
  onClickReferrals?: () => void;
  onMouseEnter?: () => void;
  logo: string;
  email: string;
  company?: string;
  username?: string;
  enableSubmenu?: boolean;
  isExpanded?: boolean;
}

const StyledWrapper = styled.div<{ isNavOpen: boolean }>`
  margin: 0;
  margin-top: auto;
  transition: width 0.1s ease;
  background-color: white;
  padding: 5px;
  width: calc(100% - 24px);
  width: 100%;

  display: flex;
  align-items: center;
  gap: 4px 12px;

  ${(p) =>
    !p.isNavOpen &&
    css`
      justify-content: center;
    `}
`;

const StyledAvatarImg = styled.img`
  border: 1px solid #eae9ff;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  z-index: 2;
  background-color: #eae9ff;
`;

const StyledCompanyName = styled(Text).attrs({
  variant: "body12Medium",
  color: theme.colors.text110,
})`
  text-overflow: ellipsis;
  max-width: 150px;
  max-height: 20px;
  white-space: nowrap;
  overflow: hidden;
`;

export function AvatarBlock({
  onClickSettings,
  onClickReferrals,
  onClickLogout,
  onClickHelp,
  onMouseEnter,
  logo,
  email,
  company,
  username,
  enableSubmenu = true,
  isExpanded,
}: AvatarBlockProps) {
  const { tenant, subscription, isDemoData } = useBootstrap();
  const showReferral = shouldShowReferral(isDemoData, subscription);
  const referralOption: OptionItem = {
    label: _`Refer and get $300`,
    icon: "Gift",
    onClick: onClickReferrals || (() => {}),
    rightComponent: (
      <Tag withBorder={false} color="primary">
        New
      </Tag>
    ),
  };
  return (
    <StyledWrapper
      isNavOpen={!!isExpanded}
      className="user-box"
      data-cy="user-box"
      onMouseEnter={onMouseEnter}
    >
      <StyledAvatarImg src={logo} alt="" />
      {isExpanded && (
        <>
          <Box flexGrow="1" data-cy="user-info">
            <StyledCompanyName>{company || email}</StyledCompanyName>
            {tenant.id === LIVE_DEMO_TENANT_ID || (company && username) ? (
              <Text variant="body11Regular" color={theme.colors.text90}>
                {username}
              </Text>
            ) : (
              <Link to="/settings/account">
                <Text variant="body11Regular" color={theme.colors.primary100}>
                  {_`Complete your profile`}
                </Text>
              </Link>
            )}
          </Box>
          <Box justifySelf="end">
            {enableSubmenu && (
              <MoreOptions
                className="avatar-more-options"
                anchorComponent={<Icon name="ArrowDown" />}
                options={[
                  {
                    label: _`Settings`,
                    icon: "Settings" as IconNames,
                    onClick: onClickSettings || (() => {}),
                  },
                  ...(showReferral ? [referralOption] : []),
                  {
                    label: _`Help`,
                    icon: "Help",
                    onClick: onClickHelp || (() => {}),
                  },
                  {
                    label: _`Log Out`,
                    icon: "Logout",
                    onClick: onClickLogout || (() => {}),
                  },
                ]}
              />
            )}
          </Box>
        </>
      )}
    </StyledWrapper>
  );
}
