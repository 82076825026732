import styled from "styled-components";

export const StyledSelector = styled.div<{ fullWidth?: boolean }>`
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  overflow: auto;
  width: ${({ fullWidth }) => (fullWidth ? "auto" : "400px")};
`;
