import classNames from "classnames";
import { ReactNode } from "react";

import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { theme } from "../theme";

import {
  StyledSelectableCard,
  StyledSelectableCardBox,
  StyledSelectableCardCheckIcon,
  StyledSelectableCardLabel,
  StyledSelectableCardRadio,
  StyledSelectableCardRadioBullet,
} from "./SelectableCard.styled";

export type SelectableCardProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    label: ReactNode;
    id: string;
    checked?: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    type?: "checkbox" | "radio";
  };

export default function SelectableCard({
  className,
  style,
  label,
  id,
  checked,
  type = "checkbox",
  onChange,
  ...interactionEvents
}: SelectableCardProps) {
  return (
    <StyledSelectableCard
      {...interactionEvents}
      className={classNames(className)}
      style={style}
    >
      <input
        name="test"
        type={type}
        hidden={true}
        id={id}
        checked={checked}
        onChange={onChange}
      />
      <StyledSelectableCardLabel htmlFor={id}>
        {type === "checkbox" ? (
          <StyledSelectableCardBox>
            <StyledSelectableCardCheckIcon
              name="Check"
              color={theme.colors.primary100}
            />
          </StyledSelectableCardBox>
        ) : (
          <StyledSelectableCardRadio>
            <StyledSelectableCardRadioBullet />
          </StyledSelectableCardRadio>
        )}

        {label}
      </StyledSelectableCardLabel>
    </StyledSelectableCard>
  );
}
