import styled from "styled-components";

import { theme } from "../theme";

export const StyledMobileLayout = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: 1fr auto;
  overflow: auto;
  background: ${theme.colors.bgBody};
  position: relative;
`;

export const StyledMobileLayoutHeader = styled.div`
  z-index: ${theme.zIndexes.layer6};
  position: fixed;
  top: 0;
  left: 0;
  padding: 8px;
  width: calc(100vw - 16px);
  overflow: hidden;
  transition: all 0.4s ease-in-out;
`;

export const StyledMobileLayoutHeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(269.22deg, #7d50fe 0%, #5a50fe 100%);
  border-radius: 6px 6px 0px 0px;
  padding: 8px 12px;
`;

export const StyledMobileLayoutHeaderContent = styled.div`
  background: ${theme.colors.white100};
  border-radius: 0px 0px 6px 6px;
  padding: 12px 12px;
  box-shadow:
    0px 2px 4px rgba(43, 150, 210, 0.1),
    0px 12px 32px rgba(43, 150, 210, 0.05);
`;

export const StyledMobileLayoutContainer = styled.div`
  overflow: auto;
  padding: 8px;
`;

export const StyledMobileLayoutFooter = styled.div`
  background: ${theme.colors.primary10};
  border-top: 1px solid ${theme.colors.bgBody};
`;

export const StyledMobileLayoutFooterMessage = styled.div`
  background: ${theme.colors.white100};
  padding: 8px 15px;
`;

export const StyledMobileLogoutButton = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${theme.colors.primary100};
  opacity: 0.8;
  cursor: pointer;
`;
