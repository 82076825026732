import styled from "styled-components";

import { theme } from "../theme";

export const StyledMultiStepFormulaInput = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 6px;
  min-height: 26px;
  line-height: 26px;
  border-radius: ${theme.borderRadius.r3px};

  & > div:last-child {
    flex-grow: 1 !important;
  }
`;
