import styled from "styled-components";

import { Box } from "../../Box";
import { theme } from "../../theme";

export const StyledExampleLayout = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  border: 1px solid ${theme.colors.borderLight};
  border-radius: 8px;
  padding: 12px;
  gap: 16px;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.bgLight10};
  }

  @media screen and (min-height: 800px) {
    padding: 16px;
  }
`;

export const StyledLeftIcon = styled(Box)<{ isHovered: boolean }>`
  background-color: ${(props) =>
    props.isHovered ? theme.colors.white100 : theme.colors.bgLight10};
  border: 1px solid
    ${(props) =>
      props.isHovered ? theme.colors.borderLight : theme.colors.bgLight10};
  padding: 8px;
  border-radius: 5px;
  width: 36px;
  height: 36px;
`;
