import { theme } from "../theme";

export type BaseInputFieldStyleOptions = {
  root?: React.CSSProperties;
  hover?: React.CSSProperties;
  focus?: React.CSSProperties;
};

export type OnBackground =
  | "whiteBackground"
  | "lightBackground"
  | "transparent";

export const baseInputFieldColorMap = {
  transparent: {
    root: {
      color: theme.colors.text90,
      backgroundColor: "transparent",
      borderColor: "transparent",
    },
    hover: {
      backgroundColor: "transparent",
      borderColor: "transparent",
    },
    focus: {
      color: theme.colors.text90,
      backgroundColor: "transparent",
      borderColor: "transparent",
      outline: "none",
    },
  },
  whiteBackground: {
    root: {
      color: theme.colors.text90,
      backgroundColor: theme.colors.bgLight10,
      borderColor: theme.colors.borderLight,
    },
    hover: {
      backgroundColor: theme.colors.bgLight20,
      borderColor: theme.colors.borderMedium,
    },
    focus: {
      color: theme.colors.text90,
      backgroundColor: theme.colors.bgLight10 + " important!",
      borderColor: theme.colors.borderMedium + " important!",
      outline: `solid 3px ${theme.colors.primary10}`,
    },
  },
  lightBackground: {
    root: {
      color: theme.colors.text90,
      backgroundColor: theme.colors.white100,
      borderColor: theme.colors.borderMedium,
    },
    hover: {
      backgroundColor: theme.colors.bgLight30,
      borderColor: theme.colors.borderDark,
    },
    focus: {
      backgroundColor: theme.colors.bgLight10,
      borderColor: theme.colors.borderMedium,
      outline: `solid 3px ${theme.colors.primary10}`,
    },
  },
} as const;
