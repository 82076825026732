import classNames from "classnames";

import { ClassAndStyleProps, InteractionEvents } from "../shared";

import { StyledRadio } from "./Radio.styled";

export type RadioProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    disabled?: boolean;
    checked?: boolean;
  };

export default function Radio({
  className,
  style,
  disabled,
  checked,
  ...interactionEvents
}: RadioProps) {
  return (
    <StyledRadio
      {...interactionEvents}
      className={classNames(className, { checked, disabled })}
      style={style}
    />
  );
}
