import { Box } from "../Box";
import { Button } from "../Button";
import { theme } from "../theme";
import { Text } from "../Typography";

type ButtonProps = {
  label: string;
  onClick: () => void;
  selected: boolean;
  disabled?: boolean;
};

const ButtonGroup = ({ buttons }: { buttons: ButtonProps[] }) => {
  return (
    <Box
      display="flex"
      borderRadius="4px"
      border={`1px solid ${theme.colors.borderLight}`}
      padding="4px 2px"
    >
      {buttons.map((button) => (
        <Button
          key={button.label}
          size={"small"}
          color={button.selected ? "secondary" : "transparent"}
          onClick={button.onClick}
        >
          <Text
            variant={"body11Regular"}
            color={
              button.selected ? theme.colors.primary100 : theme.colors.text80
            }
          >
            {button.label}
          </Text>
        </Button>
      ))}
    </Box>
  );
};

export default ButtonGroup;
