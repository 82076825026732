import { useNavigate } from "react-router-dom";

import {
  CALL_STATES,
  DASHBOARD_STATES,
  SETUP_STATES,
} from "../../common/setupState/consts";
import { SetupState } from "../../common/setupState/types";
import { useBanners } from "../../hooks/banners";
import { useBootstrap } from "../../hooks/bootstrap";
import { useCallBooking } from "../../hooks/useCallBooking";
import { Banner, Button, Flex } from "../../icecube-ux";
import { useDeviceType } from "../../icecube-ux/hooks/provideDeviceType";
import { _ } from "../../languages/helper";
import { TRACKING_EVENTS, trackEvent } from "../../utils/trackingUtils";

const getSubheading = (setupState: SetupState) => {
  switch (setupState.code) {
    case SETUP_STATES.NO_DATA_DONE_CALL:
      return _`Connect your own data to try Polar for real`;
    case SETUP_STATES.WAITING_DATA_DONE_CALL:
      return _`Your data is loading. You'll be notified when it's ready.`;
    default:
      return _`You'll see your data after we've reviewed it together on the call`;
  }
};

export default function DemoDataBanner() {
  const { isMobile } = useDeviceType();
  const { isShown } = useBanners();
  const navigate = useNavigate();
  const { setupState } = useBootstrap();
  const { openSetupCallBooking, isLoading: isCallBookingModalLoading } =
    useCallBooking();

  if (!isShown) {
    return null;
  }
  return (
    <Banner
      variant="primary"
      title={_`Demo mode: you're viewing sample data`}
      subtitle={getSubheading(setupState)}
      isMobile={isMobile}
      actionsChildren={
        <Flex columnGap={8}>
          <Button
            onClick={() => {
              navigate("/setup");
              trackEvent(TRACKING_EVENTS.CONTINUE_ONBOARDING_BUTTON_CLICKED, {
                buttonContext: "inbound-demo-data-banner",
              });
            }}
            color="secondary-on-blue-background"
            ctaSize
          >
            {_`Continue my onboarding`}
          </Button>
          {setupState.call === CALL_STATES.INITIAL && (
            <Button
              color="primary-on-blue-background"
              onClick={() => {
                void openSetupCallBooking();
                trackEvent(TRACKING_EVENTS.BOOK_A_CALL_BUTTON_CLICKED, {
                  buttonContext: "inbound-demo-data-banner",
                });
              }}
              loading={isCallBookingModalLoading}
              ctaSize
            >
              {_`Book trial setup call`}
            </Button>
          )}
          {setupState.call !== CALL_STATES.INITIAL &&
            setupState.data === DASHBOARD_STATES.NO_DATA && (
              <Button
                onClick={() => {
                  navigate("/connectors");
                  trackEvent(TRACKING_EVENTS.CONNECT_DATA_BUTTON_CLICKED, {
                    buttonContext: "inbound-data-demo-banner",
                  });
                }}
                color="primary-on-blue-background"
                ctaSize
              >
                {_`Connect data`}
              </Button>
            )}
        </Flex>
      }
    />
  );
}
