import { css } from "styled-components";

import { theme } from "../theme";

import Icon, { IconProps } from "./Icon";

const dragDropColorsByBackground = {
  white: {
    default: theme.colors.grey60,
    hover: theme.colors.grey70,
  },
  light: {
    default: theme.colors.grey70,
    hover: theme.colors.grey80,
  },
  blue: {
    default: theme.colors.primary40,
    hover: theme.colors.primary60,
  },
} as const;

type DragDropIconProps = {
  background: keyof typeof dragDropColorsByBackground;
} & Omit<IconProps, "name" | "color">;

export const StyledDragDropIcon = ({
  background,
  ...props
}: DragDropIconProps) => {
  const colors = dragDropColorsByBackground[background];
  return (
    <Icon
      {...props}
      name="Dragger"
      cssStyles={css`
        fill: ${colors?.default};

        &:hover {
          cursor: grab;
          fill: ${colors?.hover};
        }

        &:active {
          cursor: grabbing !important;
        }
      `}
    />
  );
};
