import { useAuth, withAuthentication } from "./hooks/auth/auth";
import { useBootstrap } from "./hooks/bootstrap";
import MobileKeyIndicators from "./pages/key-indicators/MobileKeyIndicators";
import { MobileOnboardingLanding } from "./pages/MobileOnboardingLanding/MobileOnboardingLanding";

export default withAuthentication(function MobileRoutes() {
  const auth = useAuth();
  const { isDemoData } = useBootstrap();

  return (
    <>
      {isDemoData && !auth.isVisitor() ? (
        <MobileOnboardingLanding />
      ) : (
        <MobileKeyIndicators />
      )}
    </>
  );
});
