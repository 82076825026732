import { useBootstrap } from "../../../hooks/bootstrap";
import useCurrencies from "../../../hooks/useCurrencies";
import { Flex, Select, Text } from "../../../icecube-ux";
import { _ } from "../../../languages/helper";
import { CustomMetricDefinition } from "../../../types/synthesizer";
import { getCurrencyFullLabel } from "../../../utils/utils";

interface CurrencyFormattingOptionsProps {
  metric: CustomMetricDefinition;
  onChange: (metric: CustomMetricDefinition) => void;
}

export default function CurrencyFormattingOptions({
  metric,
  onChange,
}: CurrencyFormattingOptionsProps) {
  const { tenant } = useBootstrap();
  const currencies = useCurrencies();

  return (
    <div>
      <Flex flexDirection="column" gap={8} className="margin-top-xlarge">
        <Text variant="BodyRegularBold">{_`Currency`}</Text>
        <Select
          withSearch
          onChange={(v) =>
            onChange({
              ...metric,
              formatting: { ...metric.formatting, currency: `${v[0]}` },
            })
          }
          selected={[
            metric.formatting.currency || tenant.settings.currency || "USD",
          ]}
          options={
            currencies === null
              ? []
              : Object.keys(currencies).map((c) => ({
                  label: getCurrencyFullLabel(c),
                  value: c,
                }))
          }
          name="currency"
        />
      </Flex>
    </div>
  );
}
