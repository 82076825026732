import { useQueryClient } from "@tanstack/react-query";
import LogRocket from "logrocket";
import {
  ComponentProps,
  ElementType,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useLocation } from "react-router";

import { SupportedPermissions } from "../../common/permissions/types";
import { SetupState } from "../../common/setupState/types";
import { Subscription } from "../../common/types/subscription-service";
import { languageState } from "../../languages/helper";
import {
  LoginResult,
  autoLogin as authServiceAutoLogin,
  changeEmail as authServiceChangeEmail,
  changePassword as authServiceChangePassword,
  login as authServiceLogin,
  resetExpiredPassword as authServiceResetExpiredPassword,
  refreshToken,
  register,
  requestPasswordReset,
  resendValidationEmail,
  resetPasswordWithCode,
  validateEmail,
  validateToken,
} from "../../lib/authService";
import {
  LoggedInUser,
  bootstrap,
  getUserAttributes,
  onboardNewUser,
  setUserSetting,
} from "../../lib/usersService";
import { Tenant } from "../../types/tenant";
import { deriveUserTimezone } from "../../utils/dateUtils";
import { LIVE_DEMO_EMAIL, OUTBOUND_DEMO_TOKENS } from "../../utils/demoConfig";
import useLocalStorage, { LOCAL_STORAGE_KEYS } from "../useLocalStorage";
import { useOutboundDemo } from "../useOutboundDemo";
import { OutboundDemoData } from "../useOutboundDemoTypes";

import { isTokenExpired } from "./utils";

interface BootstrapData {
  tenant: Tenant;
  subscription: Subscription;
  setupState: SetupState;
  permissions: SupportedPermissions;
}

export const TENANT_STATE_KEYS = {
  DASHBOARD: "dashboard",
  DASHBOARD_READY_AT: "dashboardReadyAt",
  REQUIRE_MEETING: "requireMeeting",
  REQUIRE_UPGRADE: "requireUpgrade",
  APP_ACTIVATED: "appActivated",
  IS_SUBSCRIPTION_UNPAID: "isSubscriptionUnpaid",
  NEED_MIGRATION_TO_STRIPE: "needsMigrationToStripe",
} as const;

export interface AuthContextProps {
  tokens: LoginResult | null;
  user: LoggedInUser | null;
  outboundDemoData: OutboundDemoData | null;
  isOutboundDemo: boolean;
  logIntoOutboundDemo: (email: string) => void;
  isLoggedIn: boolean;
  processing: boolean;
  refreshUser: (tokens: LoginResult | null) => Promise<LoggedInUser | null>;
  getToken: () => Promise<string>;
  isAdmin: () => boolean;
  isVisitor: () => boolean;
  isGod: () => boolean;
  isAgencyConnector: () => boolean;
  clearRequestCache: () => void;
  clearUserCache: () => void;
  login: (
    email: string,
    password: string,
    resetCallback: () => void,
  ) => Promise<{ tokens: LoginResult; user: LoggedInUser | null } | string>;
  autoLogin: (
    token: string,
    resetCallback: () => void,
  ) => Promise<{ tokens: LoginResult; user: LoggedInUser | null } | string>;
  signUp: (
    name: string,
    email: string,
    password: string,
    isMobile: boolean,
    shopUUID?: string,
    testVersion?: string,
    location?: string,
  ) => Promise<true | string>;
  confirmEmail: (email: string, code: string) => Promise<boolean>;
  changePassword: (
    previousPassword: string,
    newPassword: string,
  ) => Promise<void>;
  forgotPassword: (email: string) => Promise<boolean>;
  resetPassword: (
    email: string,
    code: string,
    password: string,
  ) => Promise<boolean>;
  resendConfirmation: (email: string) => Promise<boolean>;
  getBootstrap: () => Promise<BootstrapData | null>;
  logout: (resetCallback: () => void) => void;
  changeEmail: (email: string) => Promise<void>;
  changeName: (name: string) => Promise<void>;
  criticalFailure: boolean;
  needPasswordChange: boolean;
  completeNewPassord: (password: string) => Promise<void>;
}

export const AuthContext = createContext<AuthContextProps | null>(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw Error("Auth context not provided");
  }
  return context;
};

export const withoutAuthentication = (WrappedComponent: ElementType) => {
  const RequiresNonAuthentication = (props: ComponentProps<ElementType>) => {
    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
      if (auth.isLoggedIn && !auth.processing) {
        navigate("/");
      }
    }, [auth.isLoggedIn, auth.processing, navigate]);

    return <WrappedComponent {...props} />;
  };

  return RequiresNonAuthentication;
};

export const withForcedLogout = (
  WrappedComponent: ComponentProps<ElementType>,
) => {
  const ForceLogout = (props: {}) => {
    const auth = useAuth();
    const [done, setDone] = useState(false);
    useEffect(() => {
      if (!auth.processing) {
        // need to be done like this because
        // we have to log out properly before rendering the children components
        // otherwise data would be cleared by concurrent rendering hooks
        // we also only want to clear the login once because
        //   in some component like AcceptInvitation, it will log user back in,
        //   which shall not be logged out again here while we are still in the component with forced logout
        if (auth.isLoggedIn) {
          auth.logout(() => {
            setDone(true);
          });
        } else {
          setDone(true);
        }
      }
    }, [auth, auth.isLoggedIn, auth.processing, done]);

    return done ? <WrappedComponent {...props} /> : <div>Logging out</div>;
  };

  return ForceLogout;
};

const STOP_REDIRECT_URLS = [
  "/passwordChange",
  "/shopifyConfirmation",
  "/emailConfirmation",
];

export const withAuthentication = <TProps extends Record<string, unknown>>(
  WrappedComponent: (props: TProps) => JSX.Element | null,
) => {
  const RequiresAuthentication = (props: TProps) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [ready, setReady] = useState(false);

    useEffect(() => {
      if (!auth.isLoggedIn && !auth.processing) {
        navigate("/login");
        return;
      }

      const shouldNotRedirect = STOP_REDIRECT_URLS.some((url) =>
        location.pathname.includes(url),
      );

      if (auth.user && auth.needPasswordChange && !shouldNotRedirect) {
        navigate("/passwordChange");
        return;
      }

      if (
        auth.user &&
        auth.user.status === "shopify_unconfirmed_email" &&
        !shouldNotRedirect
      ) {
        navigate("/shopifyConfirmation");
        return;
      }

      if (
        auth.user &&
        auth.user.emailValidated === null &&
        !shouldNotRedirect
      ) {
        navigate("/emailConfirmation");
        return;
      }

      if (
        auth.user &&
        auth.user.status !== "shopify_unconfirmed_email" &&
        location.pathname.includes("/shopifyConfirmation")
      ) {
        navigate("/");
        return;
      }

      if (
        auth.user &&
        auth.user.emailValidated !== null &&
        location.pathname.includes("/emailConfirmation")
      ) {
        navigate("/");
        return;
      }
    }, [
      auth.isLoggedIn,
      auth.user,
      auth.processing,
      auth.needPasswordChange,
      navigate,
      location.pathname,
    ]);

    useEffect(() => {
      if (!auth || !auth.user || auth.processing) {
        return;
      }
      setReady(true);
    }, [auth, auth.user]);

    if (!ready) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };

  return RequiresAuthentication;
};

export function useProvideAuth() {
  const queryClient = useQueryClient();

  const {
    isOutboundDemo,
    outboundDemoData,
    clearOutboundDemoData,
    logIntoOutboundDemo: logIntoOutboundDemo_,
  } = useOutboundDemo();

  const [tokens, setTokens] = useLocalStorage<null | LoginResult>(
    LOCAL_STORAGE_KEYS.USER_TOKENS,
    null,
  );

  const [initial, setInitial] = useState(true);
  const [criticalFailure, setCriticalFailure] = useState(false);
  const [processing, setProcessing] = useState<boolean>(true);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<LoggedInUser | null>(null);
  const [needPasswordChange, setNeedPasswordChange] = useState(false);

  const clearUserCache = () => {
    Object.values(LOCAL_STORAGE_KEYS).forEach((value) => {
      localStorage.removeItem(value);
    });
  };

  const clearRequestCache = () => {
    let i = 0;
    let key = localStorage.key(i);
    const toDelete = [];
    while (key !== null) {
      if (key.startsWith("pa-dash-")) {
        toDelete.push(key);
      }
      i++;
      key = localStorage.key(i);
    }
    toDelete.forEach((k) => localStorage.removeItem(k));
  };

  const logout = useCallback(
    (resetCallback: () => void) => {
      clearOutboundDemoData();
      clearUserCache();
      clearRequestCache();
      resetCallback();

      setTokens(null);
      setUser(null);
      setIsLoggedIn(false);
      setProcessing(false);
      queryClient.clear();
    },
    [setTokens, clearOutboundDemoData, queryClient],
  );

  const isVisitor = useCallback(
    (userOverride?: LoggedInUser) => {
      return ["visitor"].includes(
        ((userOverride || user)?.role ?? "").toLowerCase(),
      );
    },
    [user],
  );

  const applyTrackers = useCallback(
    (user: LoggedInUser) => {
      if (process.env.REACT_APP_ENV === "production") {
        LogRocket.identify(
          user.id,
          isOutboundDemo
            ? undefined
            : {
                name: user.name,
                email: user.email,
              },
        );
      }

      if (isOutboundDemo || isVisitor(user) || user.email === LIVE_DEMO_EMAIL) {
        return;
      }
      analytics.identify(user.id, {
        name: user.name,
        email: user.email,
        language: languageState.current,
      });
      analytics.group(user.tenantId.toLowerCase());
    },
    [isOutboundDemo, isVisitor],
  );

  const refreshUser = useCallback(
    async (tokens: LoginResult | null): Promise<LoggedInUser | null> => {
      if (tokens === null) {
        console.error("Cannot refresh user without tokens");
        return null;
      }

      setProcessing(true);

      const tokenIsValid =
        isOutboundDemo || (await validateToken({ token: tokens?.accessToken }));
      if (!tokenIsValid) {
        try {
          const refreshedTokens = await refreshToken({
            refreshToken: tokens.refreshToken,
            userId: tokens.id,
          });
          setTokens({ ...tokens, ...refreshedTokens });
        } catch (e) {
          console.error(e);
          setProcessing(false);
          setIsLoggedIn(false);
          return null;
        }
      }

      let userAttributes = await getUserAttributes(tokens.accessToken);
      if (userAttributes === null) {
        logout(() => {});
        return null;
      }

      setUser(userAttributes);
      setIsLoggedIn(true);

      if (userAttributes.tenantId === "") {
        await onboardNewUser(tokens.accessToken, deriveUserTimezone());
        userAttributes = await getUserAttributes(tokens.accessToken);
        if (userAttributes === null) {
          logout(() => {});
          return null;
        }
      }
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.USER_ATTRIBUTES,
        JSON.stringify(userAttributes),
      );

      applyTrackers(userAttributes);

      setProcessing(false);

      return userAttributes;
    },
    [logout, setTokens, isOutboundDemo, applyTrackers],
  );

  const login = async (
    email: string,
    password: string,
    resetCallback: () => void,
  ) => {
    resetCallback();

    const tokens = await authServiceLogin({
      email,
      password,
      outboundDemoData,
    });
    if (typeof tokens === "string") {
      setProcessing(false);
      return tokens;
    }

    setTokens(tokens);

    const user = await refreshUser(tokens);
    setProcessing(false);
    setIsLoggedIn(true);
    setNeedPasswordChange(tokens.status === "password_expired");
    return { tokens, user };
  };

  const autoLogin = async (token: string, resetCallback: () => void) => {
    setProcessing(true);
    logout(resetCallback);

    const tokens = await authServiceAutoLogin({
      token,
      timezone: deriveUserTimezone(),
    });

    if (typeof tokens === "string") {
      return tokens;
    }

    setTokens(tokens);

    const user = await refreshUser(tokens);
    setProcessing(false);
    setIsLoggedIn(true);
    setNeedPasswordChange(tokens.status === "password_expired");
    return { tokens, user };
  };

  const signUp = async (
    name: string,
    email: string,
    password: string,
    isMobile: boolean,
    shopUUID?: string,
    testVersion?: string,
    location?: string,
  ) => {
    const userRegistration = await register({
      email,
      password,
      timezone: deriveUserTimezone(),
      shopUUID,
      outboundDemoData,
      isMobile,
      testVersion,
      location,
    });
    if (userRegistration !== true) {
      return userRegistration;
    }
    if (isLoggedIn) {
      logout(() => {});
    }
    clearOutboundDemoData();

    const loginResult = await login(email, password, () => {});
    if (typeof loginResult === "string") {
      return loginResult;
    }

    const { tokens } = loginResult;
    await setUserSetting(tokens.accessToken, "name", name);

    return true;
  };

  const confirmEmail = async (email: string, code: string) => {
    const result = await validateEmail({
      email,
      code,
    });
    if (result) {
      await refreshUser(tokens);
    }
    return result;
  };

  const resendConfirmation = (email: string) => {
    return resendValidationEmail({ email });
  };

  const completeNewPassord = async (password: string) => {
    const success = await authServiceResetExpiredPassword({
      token: (tokens as LoginResult).accessToken,
      password,
    });

    if (success) {
      setNeedPasswordChange(false);
    }

    setUser((u) => ({ ...(u as LoggedInUser), status: null }));
  };

  const changePassword = async (current: string, password: string) => {
    const success = await authServiceChangePassword({
      token: (tokens as LoginResult).accessToken,
      current,
      password,
    });

    if (success) {
      setNeedPasswordChange(false);
    }
  };

  const forgotPassword = async (email: string) => {
    return await requestPasswordReset({ email });
  };

  const resetPassword = async (
    email: string,
    code: string,
    password: string,
  ) => {
    const success = await resetPasswordWithCode({ email, code, password });

    if (success) {
      setNeedPasswordChange(false);
    }

    return success;
  };

  const changeEmail = async (email: string) => {
    const token = await getToken();
    await authServiceChangeEmail({ token, email });
    await refreshUser(tokens);
  };

  const changeName = async (name: string) => {
    await setUserSetting(await getToken(), "name", name);
  };

  const getToken = useCallback(async () => {
    if (isOutboundDemo) {
      return OUTBOUND_DEMO_TOKENS.accessToken;
    }

    const token = tokens?.accessToken || "";
    if (tokens && isTokenExpired(token)) {
      try {
        const refreshedTokens = await refreshToken({
          refreshToken: tokens.refreshToken,
          userId: tokens.id,
        });
        setTokens({ ...tokens, ...refreshedTokens });
        return refreshedTokens.accessToken;
      } catch (e) {
        console.error(e);
        return "";
      }
    }

    return token;
  }, [isOutboundDemo, setTokens, tokens]);

  const isAdmin = () => {
    return ["admin", "god"].includes((user?.role ?? "").toLowerCase());
  };

  const isGod = () => {
    return ["god"].includes((user?.role ?? "").toLowerCase());
  };

  const isAgencyConnector = () => {
    return ["agency-connector"].includes((user?.role ?? "").toLowerCase());
  };

  const getBootstrap = async () => {
    const token = await getToken();
    if (token !== "") {
      try {
        const data = await bootstrap(token);
        setCriticalFailure(false);
        return data;
      } catch (e) {
        console.error(e);
        setCriticalFailure(true);
      }
    }
    return null;
  };

  useEffect(() => {
    if (initial) {
      setInitial(false);
      if (isOutboundDemo) {
        void refreshUser(OUTBOUND_DEMO_TOKENS);
      } else if (tokens === null) {
        logout(() => {});
      } else {
        void refreshUser(tokens);
      }
    }
  }, [isOutboundDemo, initial, refreshUser, tokens, logout]);

  const logIntoOutboundDemo = async (email: string) => {
    setTokens(OUTBOUND_DEMO_TOKENS);
    await refreshUser(OUTBOUND_DEMO_TOKENS);
    logIntoOutboundDemo_(email);
  };

  return {
    tokens,
    user,
    outboundDemoData,
    isOutboundDemo,
    logIntoOutboundDemo,
    isLoggedIn: isOutboundDemo || isLoggedIn,
    processing,
    getToken,
    refreshUser,
    login,
    signUp,
    confirmEmail,
    changePassword,
    forgotPassword,
    resetPassword,
    logout,
    getBootstrap,
    isGod,
    isAdmin,
    isVisitor,
    isAgencyConnector,
    clearRequestCache,
    clearUserCache,
    resendConfirmation,
    criticalFailure,
    changeEmail,
    changeName,
    needPasswordChange,
    completeNewPassord,
    autoLogin,
  };
}
