import styled from "styled-components";

export const ListOptionActionsHoverWrapper = styled.div`
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s ease-in-out,
    visibility 0s linear 0.3s;
  gap: 4px;
  align-items: center;
  display: flex;
  .list-option:hover & {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }
`;
