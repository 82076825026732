import classNames from "classnames";

import { Flex } from "../Flex";
import { Icon } from "../Icon";
import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { Text } from "../Typography";

import { StyledCheckbox } from "./Checkbox.styled";

export type CheckboxProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    disabled?: boolean;
    checked?: boolean;
    label?: string | React.ReactNode;
  };

export default function Checkbox({
  className,
  style,
  disabled,
  checked,
  label,
  ...interactionEvents
}: CheckboxProps) {
  const renderCheckbox = () => (
    <StyledCheckbox
      {...interactionEvents}
      className={classNames(className, { checked, disabled })}
      style={style}
    >
      {checked && <Icon name="Check" size={12} />}
    </StyledCheckbox>
  );

  if (label) {
    return (
      <Flex alignItems="center" gap={10}>
        {renderCheckbox()}
        <Text
          pointer
          disableSelection
          variant="body12Regular"
          {...interactionEvents}
        >
          {label}
        </Text>
      </Flex>
    );
  }
  return renderCheckbox();
}
