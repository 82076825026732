import { theme } from "../theme";
import { typographyStylesMap } from "../Typography/typographyStylesMap";

export const chipSizeStylesMap = {
  large: {
    ...typographyStylesMap.body12Regular,
    borderRadius: theme.borderRadius.r4px,
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "6px",
    paddingBottom: "6px",
    height: 34,
  },
  small: {
    ...typographyStylesMap.body11Regular,
    borderRadius: theme.borderRadius.r4px,
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingTop: "4.5px",
    paddingBottom: "4.5px",
    height: 28,
  },
  xsmall: {
    ...typographyStylesMap.body10Regular,
    borderRadius: theme.borderRadius.r3px,
    paddingLeft: "6px",
    paddingRight: "6px",
    paddingTop: "3px",
    paddingBottom: "3px",
    height: 23,
  },
  xxsmall: {
    ...typographyStylesMap.body10Regular,
    borderRadius: theme.borderRadius.r2px,
    paddingLeft: "3px",
    paddingRight: "3px",
    paddingTop: "1px",
    paddingBottom: "1px",
    height: 15,
  },
};
