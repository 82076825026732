import { useQuery } from "@tanstack/react-query";
import { createContext, ReactNode, useContext } from "react";

import { CustomDimensionRecord } from "../components/CustomBreakdowns/types";
import { getCustomDimensions } from "../lib/customDimensionService";

import { useAuth } from "./auth/auth";
import useLocalStorage from "./useLocalStorage";

const useCustomDimensionsQuery = () => {
  const auth = useAuth();
  return useQuery(
    ["customDimensions"],
    async () => {
      return getCustomDimensions(await auth.getToken());
    },
    {
      enabled: !auth.processing,
      keepPreviousData: true,
    },
  );
};
export const INTERNAL_DEFAULT_CHANNEL_GROUPING_ID =
  "custom_internal-default-channel-grouping";
const getInternalDefaultChannelGrouping = (
  dimensions: CustomDimensionRecord[],
) => {
  return dimensions.find(
    (dimension) => dimension.id === INTERNAL_DEFAULT_CHANNEL_GROUPING_ID,
  );
};

interface ChannelGroupingCustomDimensionContextProps {
  isLoading: boolean;
  channelGroupingCustomDimensions: CustomDimensionRecord[];
  previousSelectedChannelGroupingDimensionId: string;
  setPreviousSelectedChannelGroupingDimensionId: (id: string) => void;
  sharedDefaultChannelGroupingDimension?: CustomDimensionRecord;
  refresh: () => Promise<void>;
}
const ChannelGroupingCustomDimensionContext =
  createContext<ChannelGroupingCustomDimensionContextProps | null>(null);

export const ProvideChannelGroupingCustomDimension = ({
  children,
}: {
  children: ReactNode;
}) => {
  const provider = useProviderChannelGroupingCustomDimensions();
  return (
    <ChannelGroupingCustomDimensionContext.Provider value={provider}>
      {children}
    </ChannelGroupingCustomDimensionContext.Provider>
  );
};
export const useChannelGroupingCustomDimensions = () => {
  const context = useContext(ChannelGroupingCustomDimensionContext);
  if (context === null) {
    throw Error("ChannelGroupingCustomDimensions context not provided");
  }
  return context;
};

const useProviderChannelGroupingCustomDimensions = () => {
  const [
    previousSelectedChannelGroupingDimensionId,
    setPreviousSelectedChannelGroupingDimensionId,
  ] = useLocalStorage<string>(
    "pa-previous-selected-channel-grouping-dimension-id",
    INTERNAL_DEFAULT_CHANNEL_GROUPING_ID,
  );
  const {
    isLoading,
    data: customDimensions,
    isFetched,
    isFetching,
    refetch,
  } = useCustomDimensionsQuery();
  const channelGroupingCustomDimensions: CustomDimensionRecord[] = isFetched
    ? customDimensions
        ?.filter((dimension) => dimension?.categories?.channelGrouping)
        ?.map((dimension) => ({
          ...dimension,
          id: "custom_" + dimension.id,
        })) ?? []
    : [];

  const refresh = async () => {
    await refetch();
  };
  return {
    isLoading: isFetching || isLoading,
    refresh,
    channelGroupingCustomDimensions,
    previousSelectedChannelGroupingDimensionId,
    setPreviousSelectedChannelGroupingDimensionId,
    sharedDefaultChannelGroupingDimension: getInternalDefaultChannelGrouping(
      channelGroupingCustomDimensions,
    ),
  };
};
