import { useMemo, useState } from "react";

import { useCustomElementEditors } from "../../../../hooks/customElementEditors";
import { useDimensionProvider } from "../../../../hooks/dimensions";
import { useMetricList } from "../../../../hooks/metricList";
import useDebouncedEffect from "../../../../hooks/useDebounceEffect";
import {
  Chip,
  ConnectorIcon,
  DimensionOrMetricSelector,
  Flex,
  Grid,
  IconButton,
  MultiStepFormulaInput,
  StepProps,
  Text,
  theme,
} from "../../../../icecube-ux";
import { getIntegrationIcon } from "../../../../integrations/integration";
import { _ } from "../../../../languages/helper";
import { DimensionTypes } from "../../../../utils/filterUtils";
import { ucFirst } from "../../../../utils/utils";
import {
  CustomDimensionWhenBlock,
  CustomDimensionWhenBlockOperators,
} from "../../types";

import {
  getAllTableDimensionList,
  getChannelGroupingDimensionList,
} from "./utils";

interface FormulaElementProps {
  onChange: (newBlock: CustomDimensionWhenBlock) => void;
  block: CustomDimensionWhenBlock;
  canDelete: boolean;
  onDelete: () => void;
  position: { row: number; column: number };
  startDragEvent: (index: number) => void;
  dragStatus: { movingIndex: number | null; toIndex: number | null };
  editing: boolean;
}

export default function WhenFormulaElement({
  onChange,
  block,
  canDelete,
  onDelete,
  position,
  startDragEvent,
  dragStatus,
  editing,
}: FormulaElementProps) {
  const { disableEdit, dimensionCategory } = useCustomElementEditors();
  const { metrics, dimensions, tableKeyToConnectorKey } = useMetricList();
  const dimensionProvider = useDimensionProvider();

  const [stepSearch, setStepSearch] = useState("");
  const [dimensionFetchSettings, setDimensionFetchSettings] = useState<{
    tables: string[];
    dimension: string;
  }>({ tables: [], dimension: "" });

  const tableDimensionList = useMemo(
    () =>
      dimensionCategory === "channelGrouping"
        ? getChannelGroupingDimensionList(metrics)
        : getAllTableDimensionList(metrics),
    [metrics, dimensionCategory],
  );

  useDebouncedEffect(
    () => {
      dimensionProvider.load(
        dimensionFetchSettings.tables,
        dimensionFetchSettings.dimension,
        stepSearch,
      );
    },
    300,
    [
      dimensionFetchSettings.tables,
      dimensionFetchSettings.dimension,
      stepSearch,
    ],
  );
  const handleChange = (stepId: number, value: string | null) => {
    const newBlock = { ...block };
    const [table, dimension] = block.dimensionKey.split(".") ?? [];
    const tables = [table];
    switch (stepId) {
      case 0:
        newBlock.dimensionKey = value ?? "";
        newBlock.value = "";
        newBlock.operator = "";
        break;
      case 1:
        newBlock.operator = (value as CustomDimensionWhenBlockOperators) ?? "";
        break;
      case 2:
        newBlock.value = value ?? "";
        break;
    }
    if (newBlock.dimensionKey !== "") {
      setDimensionFetchSettings({
        tables,
        dimension: dimension ?? table,
      });
    }
    onChange(newBlock);
  };

  const handleLineOperatorChange = (newLineOperator: "or" | "and") => {
    block.lineOperator = newLineOperator;
    onChange({ ...block });
  };

  const handleValuePasted = (id: number, value: string) => {
    if (id > 0) {
      return;
    }

    const tokens = value.split(",");
    handleChange(0, tokens.shift() ?? "");
    handleChange(1, tokens.shift() ?? "");
    handleChange(2, tokens.join(","));
  };

  const dimensionRenderer = (value: string, label: string) => {
    return (
      <Text variant="body12Regular" color={theme.colors.formulaEditor.blue}>
        <ConnectorIcon
          name={
            value.includes(".")
              ? getIntegrationIcon(
                  tableKeyToConnectorKey[value.split(".")[0]]?.[0],
                )
              : "Polar"
          }
          size={12}
          style={{ verticalAlign: "middle", marginRight: "4px" }}
        />
        {ucFirst(label)}
      </Text>
    );
  };

  const dimensionList = tableDimensionList
    .map((t) =>
      t.dimensions.map((d) => ({
        value: d.key,
        label: d.label,
        in: d.in,
      })),
    )
    .flat();

  const dimensionType =
    dimensions?.[block.dimensionKey.split(".")?.[1]]?.type ||
    DimensionTypes.string;

  const operatorRenderer = (_: string, label: string) => (
    <Text variant="body12Regular" color={theme.colors.formulaEditor.orange}>
      {ucFirst(label)}
    </Text>
  );

  const operatorList = [
    { value: "contains", label: _`contains` },
    { value: "notContains", label: _`does not contain` },
    {
      value: "containsCaseSensitive",
      label: _`contains (case sensitive)`,
    },
    {
      value: "notContainsCaseSensitive",
      label: _`does not contain (case sensitive)`,
    },
    { value: "is", label: _`is` },
    { value: "isNot", label: _`is not` },
    ...(dimensionType === DimensionTypes.integer
      ? [
          { value: "lower", label: "<" },
          { value: "greater", label: ">" },
          { value: "lowerOrEqual", label: "<=" },
          { value: "greaterOrEqual", label: ">=" },
        ]
      : []),
    { value: "startsWith", label: _`starts with` },
    { value: "endsWith", label: _`ends with` },
    ...(dimensionType === DimensionTypes.date ||
    dimensionType === DimensionTypes.datetime
      ? [
          { value: "isBefore", label: _`is before` },
          { value: "isAfter", label: _`is after` },
        ]
      : []),
    ...(dimensionType === DimensionTypes.string
      ? [
          { value: "isInList", label: _`is in list` },
          { value: "isNotInList", label: _`is not in list` },
        ]
      : []),
    { value: "isNull", label: _`is null` },
    { value: "isNotNull", label: _`is not null` },
    { value: "isEmpty", label: _`is empty` },
    { value: "isNotEmpty", label: _`is not empty` },
  ];

  const valueRenderer = (_: string, label: string) => {
    return (
      <Text
        variant="body12Regular"
        color={theme.colors.formulaEditor.purple}
        style={{
          minWidth: "12px",
          whiteSpace: "pre",
          borderBottom:
            label.trim() === ""
              ? `1px dashed ${theme.colors.borderDark}`
              : undefined,
        }}
      >
        {label}
      </Text>
    );
  };

  return (
    <Grid
      gridTemplateColumns="auto 1fr 38px 38px 26px"
      gap={6}
      style={
        dragStatus.toIndex === position.row
          ? {
              border: "2px solid " + theme.colors.borderLight + "90",
              borderRadius: "6px",
            }
          : {
              opacity: dragStatus.movingIndex === position.row ? "0.5" : 1,
            }
      }
    >
      <IconButton
        name="Dragger"
        color="transparent"
        size="large"
        onMouseDown={() => editing && startDragEvent(position.row)}
      />
      {!editing && (
        <Flex alignItems="center" gap={8}>
          {dimensionRenderer(
            block.dimensionKey,
            dimensionList.find((d) => d.value === block.dimensionKey)?.label ??
              "",
          )}
          {operatorRenderer(
            block.operator,
            operatorList.find((d) => d.value === block.operator)?.label ?? "",
          )}
          {valueRenderer(block.value, block.value)}
        </Flex>
      )}
      {editing && (
        <MultiStepFormulaInput
          disableEdit={disableEdit}
          values={[
            ...(block.dimensionKey === "" ? [] : [block.dimensionKey]),
            ...(block.operator === "" ? [] : [block.operator]),
            ...(block.value === "" ? [] : [block.value]),
          ]}
          onPaste={handleValuePasted}
          onChangeStepValue={handleChange}
          steps={[
            {
              type: "dropdown",
              placeholder: (
                <>
                  {_`select a|||dimension`}{" "}
                  <span style={{ color: theme.colors.formulaEditor.blue }}>
                    {_`dimension`}
                  </span>
                </>
              ),
              options: dimensionList,
              valueRenderer: dimensionRenderer,
              dropdownComponent: (
                <DimensionOrMetricSelector tables={tableDimensionList} />
              ),
            },
            {
              type: "select",
              placeholder: (
                <>
                  {_`select an|||operator`}{" "}
                  <span style={{ color: theme.colors.formulaEditor.orange }}>
                    {_`operator`}
                  </span>
                </>
              ),
              options: operatorList,
              valueRenderer: operatorRenderer,
            },
            ...(!["isNull", "isNotNull", "isEmpty", "isNotEmpty"].includes(
              block.operator,
            )
              ? ([
                  {
                    type: "select",
                    placeholder: (
                      <>
                        {_`type or select a|||value`}{" "}
                        <span
                          style={{ color: theme.colors.formulaEditor.purple }}
                        >
                          {_`value`}
                        </span>
                      </>
                    ),
                    loading: dimensionProvider.isLoading(
                      dimensionFetchSettings.tables,
                      dimensionFetchSettings.dimension,
                      stepSearch,
                    ),
                    initialSearch: stepSearch,
                    onSearchChange: (value) => {
                      setStepSearch(value);
                    },
                    options: dimensionProvider
                      .get(
                        dimensionFetchSettings.tables,
                        dimensionFetchSettings.dimension,
                        stepSearch,
                      )
                      .map((d) => ({ value: d, label: d })),
                    allowCustomValue: true,
                    valueRenderer: valueRenderer,
                  },
                ] as StepProps[])
              : []),
          ]}
        />
      )}
      {(disableEdit || editing) && (
        <Flex gap={6}>
          <Chip
            size="small"
            style={{ minWidth: "min-content" }}
            selected={block.lineOperator === "or"}
            onClick={
              disableEdit ? undefined : () => handleLineOperatorChange("or")
            }
          >
            {_`Or`}
          </Chip>
          <Chip
            size="small"
            style={{ minWidth: "min-content" }}
            selected={block.lineOperator === "and"}
            onClick={
              disableEdit ? undefined : () => handleLineOperatorChange("and")
            }
          >
            {_`And`}
          </Chip>
          {!disableEdit && canDelete ? (
            <IconButton
              name="Close"
              color="transparent"
              size="large"
              onClick={onDelete}
            />
          ) : (
            <div />
          )}
        </Flex>
      )}
    </Grid>
  );
}
