import { useEffect } from "react";
import { Link } from "react-router-dom";

import { SUBSCRIPTION_TYPE_NAMES } from "../../common/types/subscription-service";
import { PricingModels } from "../../common/types/users-service";
import Centering from "../../components/Helpers/Centering";
import { Loader } from "../../components/Loader/Loader";
import { useBootstrap } from "../../hooks/bootstrap";
import { useConnectorObservability } from "../../hooks/connectorObservability";
import {
  isNewCustomer,
  useHighlightedPlan,
  useRedirectToStripeCheckout,
  useSubscriptionEnd,
  useTenantPlan,
  useUsageBasedPricing,
} from "../../hooks/useAddonUtils";
import {
  Box,
  Flex,
  Grid,
  legacyTheme,
  SubscriptionPlanCard,
  SubscriptionPlanFooter,
  SubscriptionPlanHeader,
  SubscriptionPlanListDivider,
  Tag,
  Text,
} from "../../icecube-ux";
import { _ } from "../../languages/helper";
import {
  openIntercom,
  setIsIntercomLauncherHidden,
} from "../../utils/intercomUtils";
import { hasActivePaidPlan } from "../../utils/subscriptionsUtils";

import {
  BasicAccountSupport,
  BasicPlatformAccess,
  PlusAccountSupport,
  PlusPlatformAccess,
} from "./components/basicPlanContent";
import { StyledDataSyncingBanner } from "./components/shopifyDataSyncingBanner";
import { StyledInformationFilledIcon } from "./components/StyledInformationFilledIcon";
import SubscriptionPlanButton from "./components/SubscriptionPlanButton";
import { UsageTooltip } from "./UsageTooltip";

interface UsageBasedPricingPlanContainerProps {
  style?: React.CSSProperties;
}

export default function UsageBasedPricingPlanContainer({
  style,
}: UsageBasedPricingPlanContainerProps) {
  const {
    isRedirecting,
    redirectStripeUsageBasedCheckout,
    redirectToStripeCustomerPortal,
  } = useRedirectToStripeCheckout();
  const { data: usageBasedPricing } = useUsageBasedPricing();

  const displayNewPlans = isNewCustomer(usageBasedPricing?.price);
  const { highlightedPlan } = useHighlightedPlan();
  const { isOnUsageBasedPlan, isOnUsageBasedPlusPlan, isOnCustomPlan } =
    useTenantPlan();
  const { isSubscriptionEnding, subscriptionEndDate } = useSubscriptionEnd();
  const { hasShopifyHistoricalSync } = useConnectorObservability();
  const { subscription } = useBootstrap();
  const isOnPaidPlan = hasActivePaidPlan(subscription);
  const blockSelfUpgrade = isOnPaidPlan && !usageBasedPricing?.isSetUpOnStripe;

  const onHelp = () => {
    openIntercom();
  };

  useEffect(() => {
    setIsIntercomLauncherHidden(true);
    return () => {
      setIsIntercomLauncherHidden(false);
    };
  }, []);

  const onlyShowCustom =
    (highlightedPlan === "CUSTOM" && displayNewPlans) ||
    isOnCustomPlan ||
    (!!usageBasedPricing &&
      !isOnUsageBasedPlan &&
      !isOnUsageBasedPlusPlan &&
      usageBasedPricing.price.USAGE_BASED_PLAN > 2000);

  if (!usageBasedPricing)
    return (
      <Centering style={{ minHeight: 60 }}>
        <Loader />
      </Centering>
    );
  const currentPrice =
    isOnUsageBasedPlan && usageBasedPricing.orders > 0
      ? usageBasedPricing.price[SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLAN]
      : 0;

  return (
    <div style={style}>
      <div className="text-center mbx-30">
        <Text
          fontSize={legacyTheme.typography.fontSizes.x6large}
          fontWeight={legacyTheme.typography.fontWeigth.w600}
          lineHeight="36px"
          color="var(--primary3)"
          className="margin-bottom-large"
          style={{ letterSpacing: "-0.02em" }}
        >
          {_`Subscribe to Polar Analytics`}
        </Text>

        <Text
          fontSize={legacyTheme.typography.fontSizes.xlarge}
          fontWeight={legacyTheme.typography.fontWeigth.w600}
          lineHeight="24px"
          color="var(--primary3)"
          className="margin-bottom-large"
          whiteSpace="pre-wrap"
          style={{ letterSpacing: "-0.02em" }}
        >
          {_`Pricing is based on monthly tracked orders. Looking for a flat annual fee?`}{" "}
          <Link to="#" onClick={onHelp}>
            {_`Contact sales`}
          </Link>
        </Text>
      </div>

      {hasShopifyHistoricalSync && !onlyShowCustom && (
        <StyledDataSyncingBanner />
      )}

      <Flex flexDirection="row" justifyContent="center">
        {!isOnUsageBasedPlusPlan && !onlyShowCustom && (
          <SubscriptionPlanCard
            selected={
              highlightedPlan === SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLAN
            }
            current={isOnUsageBasedPlan}
          >
            <SubscriptionPlanHeader
              planName={_`Analyze`}
              current={isOnUsageBasedPlan}
              recommended={
                highlightedPlan === SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLAN
              }
              label={
                isOnUsageBasedPlan &&
                isSubscriptionEnding && (
                  <Tag
                    color="warning"
                    size="medium"
                    className="margin-left-regular"
                  >
                    {_`Cancelled`}
                  </Tag>
                )
              }
              planInfo={_`Based on tracked orders`}
              price={
                usageBasedPricing.price[
                  SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLAN
                ]
              }
              orders={usageBasedPricing.orders}
              priceInfo={
                <UsageTooltip
                  persistOnClick
                  direction="bottom"
                  orders={usageBasedPricing.orders}
                  icon={<StyledInformationFilledIcon size={12} />}
                />
              }
            />
            <SubscriptionPlanListDivider />
            <BasicPlatformAccess />
            <SubscriptionPlanListDivider />
            <BasicAccountSupport />
            <Box marginTop="auto" padding="0px 16px 16px 16px">
              <SubscriptionPlanButton
                isOnPlan={isOnUsageBasedPlan}
                isRedirecting={isRedirecting}
                subscriptionEndDate={subscriptionEndDate || undefined}
                isSubscriptionEnding={isSubscriptionEnding}
                redirectStripeUsageBasedCheckout={
                  !blockSelfUpgrade
                    ? () => {
                        redirectStripeUsageBasedCheckout({
                          pricing_model: PricingModels.USAGE_BASED,
                          plan: SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLAN,
                        });
                      }
                    : undefined
                }
                redirectToStripeCustomerPortal={
                  usageBasedPricing?.isSetUpOnStripe
                    ? redirectToStripeCustomerPortal
                    : undefined
                }
              />

              <SubscriptionPlanFooter>
                {_`Month to month. No commitment.`}
              </SubscriptionPlanFooter>
            </Box>
          </SubscriptionPlanCard>
        )}

        {displayNewPlans && !onlyShowCustom && (
          <SubscriptionPlanCard
            selected={
              highlightedPlan === SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLUS_PLAN
            }
            current={isOnUsageBasedPlusPlan}
          >
            <SubscriptionPlanHeader
              planName={_`Analyze & Enrich`}
              current={isOnUsageBasedPlusPlan}
              recommended={
                highlightedPlan ===
                SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLUS_PLAN
              }
              label={
                isOnUsageBasedPlusPlan &&
                isSubscriptionEnding && (
                  <Tag
                    color="warning"
                    size="medium"
                    className="margin-left-regular"
                  >
                    {_`Cancelled`}
                  </Tag>
                )
              }
              planInfo={_`Based on tracked orders`}
              price={
                usageBasedPricing.price[
                  SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLUS_PLAN
                ] - currentPrice
              }
              orders={usageBasedPricing.orders}
              priceInfo={
                <UsageTooltip
                  persistOnClick
                  direction="bottom"
                  orders={usageBasedPricing.orders}
                  icon={<StyledInformationFilledIcon size={12} />}
                />
              }
              plus={!!currentPrice}
            />
            <SubscriptionPlanListDivider />
            <PlusPlatformAccess
              showLearnMore={!isOnUsageBasedPlusPlan}
              showDifference={!isOnUsageBasedPlusPlan}
            />
            <SubscriptionPlanListDivider />
            <PlusAccountSupport showDifference={!isOnUsageBasedPlusPlan} />
            <Box marginTop="auto" padding="0px 16px 16px">
              <SubscriptionPlanButton
                isOnPlan={isOnUsageBasedPlusPlan}
                isRedirecting={isRedirecting}
                subscriptionEndDate={subscriptionEndDate}
                isSubscriptionEnding={isSubscriptionEnding}
                redirectStripeUsageBasedCheckout={
                  !blockSelfUpgrade
                    ? () =>
                        redirectStripeUsageBasedCheckout({
                          pricing_model: PricingModels.USAGE_BASED,
                          plan: SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLUS_PLAN,
                        })
                    : undefined
                }
                redirectToStripeCustomerPortal={
                  usageBasedPricing?.isSetUpOnStripe
                    ? redirectToStripeCustomerPortal
                    : undefined
                }
              />
              <SubscriptionPlanFooter>
                {_`Month to month. No commitment.`}
              </SubscriptionPlanFooter>
            </Box>
          </SubscriptionPlanCard>
        )}

        <SubscriptionPlanCard
          selected={highlightedPlan === SUBSCRIPTION_TYPE_NAMES.CUSTOM}
          current={isOnCustomPlan}
        >
          <SubscriptionPlanHeader
            current={isOnCustomPlan}
            planName={_`Enterprise`}
            label={
              isOnCustomPlan &&
              isSubscriptionEnding && (
                <Tag
                  color="warning"
                  size="medium"
                  className="margin-left-regular"
                >
                  {_`Cancelled`}
                </Tag>
              )
            }
            planInfo={_`For brands above $20M in yearly GMV`}
            recommended={highlightedPlan === SUBSCRIPTION_TYPE_NAMES.CUSTOM}
          />

          <SubscriptionPlanListDivider />

          <Grid gridTemplateColumns={onlyShowCustom ? "1fr auto 1fr" : "1fr"}>
            <Grid gridTemplateRows={onlyShowCustom ? "1fr 1fr" : "1fr"}>
              {onlyShowCustom && <BasicPlatformAccess />}
              <PlusPlatformAccess
                showDifference={!onlyShowCustom}
                showPlus={!isOnCustomPlan}
                isCustomPlan
              />
            </Grid>

            <SubscriptionPlanListDivider isVertical={onlyShowCustom} />

            <Grid gridTemplateRows={onlyShowCustom ? "1fr 1fr" : "1fr"}>
              {onlyShowCustom && <BasicAccountSupport />}
              <PlusAccountSupport
                showDifference={!onlyShowCustom}
                showPlus={!isOnCustomPlan}
                isCustomPlan
              />
            </Grid>
          </Grid>

          <Box marginTop="auto" padding="0px 16px 16px 16px">
            <SubscriptionPlanButton
              isOnPlan={isOnCustomPlan}
              isRedirecting={isRedirecting}
              subscriptionEndDate={subscriptionEndDate}
              isSubscriptionEnding={isSubscriptionEnding}
              redirectToStripeCustomerPortal={redirectToStripeCustomerPortal}
            />

            <SubscriptionPlanFooter>
              {_`Fixed pricing available.`}
            </SubscriptionPlanFooter>
          </Box>
        </SubscriptionPlanCard>
      </Flex>
    </div>
  );
}
