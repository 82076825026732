import { useAuth } from "../../../hooks/auth/auth";
import { Button, ConnectorIcon } from "../../../icecube-ux";
import { ButtonSize } from "../../../icecube-ux/Button/types";
import { useDeviceType } from "../../../icecube-ux/hooks/provideDeviceType";
import { _ } from "../../../languages/helper";
import { buildSSOUrl, type SSOContext } from "../../../lib/authService";

interface GoogleSSOButtonProps {
  context: SSOContext;
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
  location?: string;
  size?: "large" | "xxlarge";
}

const CALL_TO_ACTION = {
  login: "Log in with",
  signup: "Sign up with",
};

const ICON_SIZES: { [Key in ButtonSize]?: number } = {
  large: 16,
  xxlarge: 18,
};

export const GoogleSSOButton = ({
  context,
  location,
  size,
  ...buttonProps
}: GoogleSSOButtonProps) => {
  const { outboundDemoData } = useAuth();
  const { isMobile } = useDeviceType();

  return (
    <Button
      {...buttonProps}
      asLink
      label={_(`${CALL_TO_ACTION[context]} Google`)}
      href={buildSSOUrl({
        context,
        outboundDemoData,
        isMobile,
        location,
      })}
      color="ghost"
      size={size}
      leftIcon={
        size && size in ICON_SIZES ? (
          <ConnectorIcon
            name="Google"
            size={ICON_SIZES[size]}
            style={{ marginRight: 4 }}
          />
        ) : (
          "Google"
        )
      }
    />
  );
};
