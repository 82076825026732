import type { NavigateFunction } from "react-router-dom";

import { Report } from "../lib/reportsService";

import { DateRange, Granularity } from "./dateUtils";
import { CustomReportDefinition } from "./reports";

export const getReportByKey = (
  reports: Report[],
  sectionKey: string,
): { report?: CustomReportDefinition; id?: number } => {
  const data = reports.find((r) => `${r.section_key}` === `${sectionKey}`);
  const storedReport = data ? { ...data?.json_value } : undefined;
  return { id: data?.id, report: storedReport };
};

export type CustomReportConfig = {
  report?: Partial<CustomReportDefinition>;
  template?: string;
  dateRange: DateRange | { start: string; end: string };
  granularity: Granularity | "none";
  views?: string[];
};
export const openAsCustomReport = (
  navigate: NavigateFunction,
  config: CustomReportConfig,
) => {
  navigate(
    `/custom/create?initialConfig=${encodeURIComponent(
      JSON.stringify(config),
    )}`,
  );
};
