import classNames from "classnames";

import { LoaderProps, StyledLoader } from "./Loader.styled";

export default function Loader({
  className,
  style,
  size = 32,
  ...interactionEvents
}: LoaderProps) {
  return (
    <StyledLoader
      {...interactionEvents}
      className={classNames(className)}
      style={style}
      size={size}
    />
  );
}
