import styled from "styled-components";

import { StyledIcon } from "../Icon/Icon.styled";
import { theme } from "../theme";

export const StyledPercentChip = styled.div<{
  negative: boolean;
  small: boolean;
  withBackground: "light" | "dark";
}>`
  display: inline-flex;
  align-items: middle;
  border-radius: ${theme.borderRadius.r2px};
  font-size: ${theme.typography.fontSize.s10px};
  box-sizing: border-box;
  align-items: center;
  padding: ${({ small }) => (small ? "0px 4px 0px 1px" : "4px 6px 4px 4px")};
  background: ${({ withBackground, negative }) =>
    withBackground === "light"
      ? negative
        ? theme.colors.warning10
        : theme.colors.success10
      : theme.colors.white100};
  border: 1px solid
    ${({ withBackground, negative }) =>
      withBackground === "light"
        ? negative
          ? theme.colors.warning20
          : theme.colors.success20
        : theme.colors.borderLight};
  color: ${({ negative }) =>
    negative ? theme.colors.warning100 : theme.colors.success100};

  ${StyledIcon} {
    margin-top: -1px;
  }

  &.hideArrow {
    padding: 4px 6px 4px 6px;
  }
`;
