import { Helmet } from "react-helmet";
import styled from "styled-components";

import { withAuthentication } from "../../hooks/auth/auth";
import {
  PerformanceEvent,
  trackPageLoadCheckpoint,
} from "../../hooks/usePerformanceMeasurement";
import { Box, Flex, Icon, Text, theme } from "../../icecube-ux";
import { _ } from "../../languages/helper";
import MainLayout from "../../shared/Layouts/MainLayout";

import { IncentiveCard } from "./IncentiveCard";
import { ReferralsGenerateCode } from "./ReferralsGenerateCode";
import { ReferralsStepsSection } from "./ReferralsStepsSection";

trackPageLoadCheckpoint(PerformanceEvent.PAGE_LOADED);

const StyledReferralsMainSection = styled.section`
  height: 100%;
  background: ${theme.colors.white100};
  box-shadow:
    0px 2px 4px rgba(43, 150, 210, 0.1),
    0px 12px 32px rgba(43, 150, 210, 0.05);
  border-radius: ${theme.borderRadius.r8px};
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledIncentivesSection = styled.div`
  padding: 24px;
  border-radius: ${theme.borderRadius.r6px};
  background-color: ${theme.colors.bgLight10};
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

function Referrals() {
  return (
    <MainLayout>
      <Helmet>
        <title>{_`Referrals`} | Polar Analytics</title>
      </Helmet>

      <StyledReferralsMainSection title="">
        <StyledIncentivesSection>
          <Flex flexDirection="column" style={{ maxWidth: "822px" }}>
            <Text
              variant="body20Medium"
              color={theme.colors.text110}
            >{_`Refer your friends to Polar, and get rewards`}</Text>
            <Text
              variant="body16Medium"
              color={theme.colors.text100}
              style={{ margin: "12px 0" }}
            >{_`Earn up to $300 for every brand you refer*`}</Text>

            <Flex alignItems="center">
              <IncentiveCard
                title={_`Amazon voucher`}
                titleColor={theme.colors.warning110}
                iconBackground={theme.customColors.amazonBg}
                iconName="Amazon"
                rewardQuantity={_`$50`}
                rewardText={_`For you`}
                paragraph={_`When your friend attends a demo and mentions your discount code.`}
              />
              <Icon
                name="PlusOperator"
                color={theme.colors.text110}
                style={{ flexShrink: 0, margin: "0 12px" }}
                size={27}
              />
              <IncentiveCard
                title={_`Polar credit`}
                titleColor={theme.colors.primary100}
                iconBackground={theme.customColors.polarBg}
                iconName="Polar"
                rewardQuantity={_`$250`}
                rewardText={_`For your company`}
                paragraph={_`When the brand becomes a new Polar customer and uses your discount code.`}
              />
            </Flex>
          </Flex>
        </StyledIncentivesSection>
        <Box margin={"0 24px"} maxWidth={822} flexGrow={1} width="100%">
          <ReferralsGenerateCode />
          <ReferralsStepsSection />
        </Box>
      </StyledReferralsMainSection>
    </MainLayout>
  );
}

export default withAuthentication(Referrals);
