import React from "react";
import styled from "styled-components";

interface RoundedCardProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const StyledRoundedCard = styled.div`
  padding: 50px;
  background: #fff;
  border-radius: 16px;
`;

export default function RoundedCard({
  children,
  className = "",
  style,
}: RoundedCardProps) {
  return (
    <StyledRoundedCard style={style} className={className}>
      {children}
    </StyledRoundedCard>
  );
}
