import styled from "styled-components";

import { StyledButton } from "../Button/Button.styled";
import { StyledCheckbox } from "../Checkbox/Checkbox.styled";
import { SmallCounter } from "../Counter/Counter.styled";
import { StyledIcon } from "../Icon/Icon.styled";
import { legacyTheme } from "../legacyTheme";
import { StyledRadio } from "../Radio/Radio.styled";

export const StyledLabel = styled.div`
  display: inline-flex;
  padding: 4px;
  line-height: 18px;
  height: 26px;
  box-sizing: border-box;

  font-family: ${legacyTheme.typography.default.fontFamily};
  font-style: ${legacyTheme.typography.default.fontStyle};
  font-weight: ${legacyTheme.typography.default.fontWeight};
  font-size: ${legacyTheme.typography.fontSizes.large};
  color: ${legacyTheme.colors.text.regular};
  white-space: nowrap;

  justify-content: space-between;
  align-items: center;

  & span.label-content {
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;
  }

  ${SmallCounter} {
    margin-right: 6px;
  }

  &.small {
    font-size: ${legacyTheme.typography.fontSizes.small};
    line-height: 15px;
    padding: 2px;
  }

  &.block {
    display: flex;
  }

  &:hover {
    color: ${legacyTheme.colors.text.regularHover};
  }

  &.filled {
    color: ${legacyTheme.colors.primary1};
  }

  &.disabled {
    color: ${legacyTheme.colors.text.placeholder};
  }

  &.disabled:hover {
    color: ${legacyTheme.colors.text.placeholder};
  }

  &.pointerCursor {
    cursor: pointer;
  }

  &.autoHeight {
    height: auto !important;
  }

  &.error {
    color: ${legacyTheme.colors.warning1};
  }

  //
  // Icons
  //
  & .left-icon {
    margin-right: 6px;
    flex-shrink: 0;
  }
  &.small .left-icon {
    margin-right: 2px;
  }

  & .right-icon {
    margin-left: 6px;
    flex-shrink: 0;
  }
  &.small .right-icon {
    margin-left: 2px;
  }

  //
  // Toggle components
  //

  &:hover .hoverToggler {
    display: block;
  }
`;

export const StyledLeftComponent = styled.div`
  display: inline-block;
  margin-right: 8px;

  & > ${StyledCheckbox}, & > ${StyledRadio} {
    vertical-align: middle;
  }

  & > ${StyledIcon} {
    vertical-align: middle;
    margin-top: -2px;
  }

  &.small {
    margin-right: 3px;
  }
`;

export const StyledRightComponent = styled.div`
  margin-left: 8px;

  & > ${StyledButton} {
    vertical-align: middle;
  }

  & > ${StyledIcon} {
    vertical-align: middle;
    margin-top: -2px;
  }

  &.alignTop {
    align-self: flex-start;
  }

  &.hoverToggler {
    display: none;
  }

  &.small {
    margin-left: 3px;
  }
`;

export const StyledHighlightedSpan = styled.span`
  background: ${legacyTheme.colors.primary8};
  color: ${legacyTheme.colors.primary1};
  border-radius: ${legacyTheme.borderRadius.tiny};
`;
