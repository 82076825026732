import { theme } from "../theme";

export const sizeStylesMap = {
  xxl: {
    borderRadius: theme.borderRadius.r4px,
    width: "34px",
    height: "34px",
  },
  large: {
    borderRadius: theme.borderRadius.r4px,
    width: "28px",
    height: "28px",
  },
  small: {
    borderRadius: theme.borderRadius.r2px,
    width: "18px",
    height: "18px",
  },
  tiny: {
    borderRadius: theme.borderRadius.r2px,
    width: "14px",
    height: "14px",
  },
} as const;
