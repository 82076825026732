import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useAuth } from "../../../../hooks/auth/auth";
import { Button, Space, Text, theme } from "../../../../icecube-ux";
import { TRACKING_EVENTS, trackEvent } from "../../../../utils/trackingUtils";

const StyledCustomReportTemplateCard = styled.div`
  border: 1px solid #eae9ff;
  border-radius: 9px;
  width: 310px;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    border: 1px solid ${theme.colors.borderMedium};
  }

  &:hover div.zoomable {
    transform: scale(1.015);
  }

  &:hover .hoverable {
    color: ${theme.colors.text100} !important;
  }
`;

const StyledCustomReportTemplateCardContent = styled.div`
  padding: 24px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.15s ease-in-out;
`;

const StyledCustomReportTemplateCardContainer = styled.div`
  height: 130px;
  width: 100%;
  overflow: hidden;
`;

const StyledCustomReportTemplateCardImg = styled.div<{ path: string }>`
  transition: all 0.15s ease-in-out;
  width: 100%;
  height: 100%;
  background-image: url(${({ path }) => path});
  background-position: center;
  background-size: cover;
`;

interface SetupPageForOutboundVersionBProps {
  image: string;
  title: string;
  description: string;
  buttonText: string;
  productTourId?: string;
  templateId?: string;
  redirectTo?: string;
}

export function CustomReportTemplateCard({
  image,
  title,
  description,
  buttonText,
  templateId,
  productTourId,
  redirectTo,
}: SetupPageForOutboundVersionBProps) {
  const navigate = useNavigate();
  const { outboundDemoData } = useAuth();

  const handleClick = () => {
    if (redirectTo) {
      trackEvent(
        TRACKING_EVENTS.OUTBOUND_TEST_B_CALL_POPUP_EXPLORE_CARD_CLICKED,
        {
          ...outboundDemoData,
          redirectTo,
        },
      );
      navigate(redirectTo);
    } else {
      trackEvent(TRACKING_EVENTS.OUTBOUND_TEST_B_GENERATE_REPORT_CLICKED, {
        ...outboundDemoData,
        templateId,
        productTourId,
      });
      navigate(
        `/custom/create?template=${templateId}${
          productTourId ? `&productTourId=${productTourId}` : ""
        }`,
      );
    }
  };

  return (
    <StyledCustomReportTemplateCard onClick={handleClick}>
      <StyledCustomReportTemplateCardContainer>
        <StyledCustomReportTemplateCardImg path={image} className="zoomable" />
      </StyledCustomReportTemplateCardContainer>
      <StyledCustomReportTemplateCardContent>
        <Text
          variant="body15Regular"
          textAlign="center"
          color={theme.colors.text110}
        >
          {title}
        </Text>
        <Space size={4} />
        <Text
          variant="body12Regular"
          textAlign="center"
          color={theme.colors.text90}
          className="hoverable"
        >
          {description}
        </Text>
        <Space size={24} />
        <Button block onClick={() => {}}>
          {buttonText}
        </Button>
      </StyledCustomReportTemplateCardContent>
    </StyledCustomReportTemplateCard>
  );
}
