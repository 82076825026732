import React from "react";

export const useResizeObserver = (elRef: Element | null) => {
  const [rect, setRect] = React.useState({ width: 0, height: 0, top: 0 });

  const observer = React.useRef(
    new ResizeObserver((entries) => {
      const { width, height, top } = entries[0].contentRect;
      setRect({ width, height, top });
    }),
  );

  React.useEffect(() => {
    if (elRef === null) {
      return;
    }

    const obsInstance = observer.current;

    if (elRef) {
      obsInstance.observe(elRef);
    }

    return () => {
      obsInstance.unobserve(elRef);
    };
  }, [elRef, observer]);

  return rect;
};
