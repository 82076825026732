import styled from "styled-components";

import {
  PlanName,
  SUBSCRIPTION_TYPE_NAMES,
} from "../../../common/types/subscription-service";
import { PricingModels } from "../../../common/types/users-service";
import { InformationFilledIcon } from "../../../components/Icons/Icons";
import { useAuth } from "../../../hooks/auth/auth";
import { useBootstrap } from "../../../hooks/bootstrap";
import {
  useAddonInfoRedirect,
  useRedirectToStripeCheckout,
  useTenantPlan,
  useUsageBasedPricing,
} from "../../../hooks/useAddonUtils";
import { Box, Button, Flex, Tag, Text, theme } from "../../../icecube-ux";
import { _ } from "../../../languages/helper";
import { openIntercom } from "../../../utils/intercomUtils";
import { trackEvent, TRACKING_EVENTS } from "../../../utils/trackingUtils";
import Loader from "../../Loader";
import { UsageTooltip } from "../UsageTooltip";

const StyledInformationFilledIcon = styled(InformationFilledIcon)`
  color: ${theme.colors.primary80};
  vertical-align: middle;
  margin-left: 3px;
`;

const StyledPricesCard = styled(Flex)`
  padding: 8px;
  margin: 8px 0;
  background: ${theme.colors.bgLight20};
  border-radius: ${theme.borderRadius.r6px};
`;

interface PriceRowProps {
  text: string;
  price: string;
}

const PriceRow = ({ text, price }: PriceRowProps) => (
  <Flex justifyContent="space-between">
    <Text variant="body12Regular" color={theme.colors.text90}>
      {text}
    </Text>
    <Text variant="body12Regular" color={theme.colors.text110}>
      {price}
    </Text>
  </Flex>
);

const SeeAllPlansTextSection = () => {
  const { isOnUsageBasedPlan } = useTenantPlan();
  const { isLoading, data: usageBasedPricing } = useUsageBasedPricing();
  const { handleSeeAllPlans, handleLearnMore } = useAddonInfoRedirect();
  return (
    <Box>
      <Text
        variant="body11Regular"
        color={theme.colors.text100}
      >{_`Addons bundle estimated new pricing`}</Text>
      <Text inline variant="body11Regular" color={theme.colors.text80}>
        {_`based on the last 30 days MTO,`}
        {` `}
      </Text>
      <Text
        variant="body11Regular"
        color={theme.colors.text80}
        underline
        pointer
        inline
        onClick={handleSeeAllPlans}
      >
        {_`See all plans`}
      </Text>
      <Text
        variant="body11Regular"
        color={theme.colors.text80}
        underline
        pointer
        inline
        onClick={handleLearnMore}
      >
        {_`, Learn more about pricing`}
        {isOnUsageBasedPlan && !isLoading && (
          <UsageTooltip
            persistOnClick
            direction="bottom"
            orders={usageBasedPricing?.orders}
            icon={<StyledInformationFilledIcon size={12} />}
          />
        )}
      </Text>
    </Box>
  );
};

const ButtonsSection = ({
  primaryButtonText,
  plan = SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLUS_PLAN,
  tierIndex,
  tierWrapperId,
  onClickChat = openIntercom,
}: {
  primaryButtonText: string;
  plan?: PlanName;
  tierIndex?: number;
  tierWrapperId?: string;
  onClickChat?: null | (() => void);
}) => {
  const auth = useAuth();
  const { tenant } = useBootstrap();
  const { isRedirecting, redirectStripeUsageBasedCheckout } =
    useRedirectToStripeCheckout();

  return (
    <Flex flexDirection="column" gap={12}>
      <Button
        loading={isRedirecting}
        color="primary"
        rightIcon="Sparkle"
        onClick={() => {
          trackEvent(TRACKING_EVENTS.PLAN_SUBSCRIBE_VIA_POPUP, {
            tenantId: tenant?.id,
            email: auth.user?.email,
          });
          redirectStripeUsageBasedCheckout({
            pricing_model: PricingModels.GMV_TIER_BASED,
            plan,
            tierIndex,
            wrapperId: tierWrapperId,
          });
        }}
      >
        {primaryButtonText}
      </Button>
      {onClickChat && (
        <Button onClick={onClickChat} color="ghost">{_`Talk to us`}</Button>
      )}
    </Flex>
  );
};

export const PopupSubscribeSection = ({
  loading,
  price,
  plan = SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLUS_PLAN,
  tierIndex,
  tierWrapperId,
}: {
  loading: boolean;
  price: number;
  plan?: PlanName;
  tierIndex?: number;
  tierWrapperId?: string;
}) =>
  loading ? (
    <Loader />
  ) : (
    <Flex fullWidth flexDirection="column" gap={16}>
      <Flex justifyContent="space-between">
        <Text variant="body14Medium" color={theme.colors.text110}>
          {plan === SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLUS_ACTIVATE_PLAN
            ? _`Analyze, Enrich & Activate`
            : _`Analyse & Enrich`}
        </Text>
        <Tag color="primary">{_`Recommended`}</Tag>
      </Flex>
      <Text variant="body24Medium" color={theme.colors.text110}>{_`From $${[
        "price",
        price.toString(),
      ]}/month`}</Text>
      <SeeAllPlansTextSection />
      <ButtonsSection
        primaryButtonText={_`Subscribe`}
        plan={plan}
        tierIndex={tierIndex}
        tierWrapperId={tierWrapperId}
        onClickChat={null}
      />
    </Flex>
  );

export const PopupUpgradeSection = ({
  loading,
  curPrice,
  newPrice,
  plan = SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLUS_PLAN,
  tierIndex,
  tierWrapperId,
  onClickChat = openIntercom,
}: {
  loading: boolean;
  curPrice: number;
  newPrice: number;
  plan?: PlanName;
  tierIndex?: number;
  tierWrapperId?: string;
  onClickChat?: () => void;
}) =>
  loading ? (
    <Loader />
  ) : (
    <Flex fullWidth flexDirection="column" gap={8}>
      <Text
        variant="body14Medium"
        color={theme.colors.text110}
      >{_`Upgrade to unlock these features`}</Text>
      <SeeAllPlansTextSection />
      <StyledPricesCard flexDirection="column" gap={4}>
        <PriceRow
          text={_`Current price`}
          price={_`$${["curPrice", curPrice.toString()]}/Month`}
        />
        <PriceRow
          text={_`New price`}
          price={_`$${["newPrice", newPrice.toString()]}/Month`}
        />
        <PriceRow
          text={_`Difference`}
          price={_`+$${["difference", (newPrice - curPrice).toString()]}/Month`}
        />
      </StyledPricesCard>
      <ButtonsSection
        primaryButtonText={_`Upgrade now`}
        plan={plan}
        tierIndex={tierIndex}
        tierWrapperId={tierWrapperId}
        onClickChat={onClickChat}
      />
    </Flex>
  );
