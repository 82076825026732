import { FunctionComponent } from "react";

import { useMetricList } from "../../hooks/metricList";
import { ConnectorIcon, Flex, Select } from "../../icecube-ux";
import { getIntegrationIcon } from "../../integrations/integration";
import { DimensionFilter } from "../../utils/filterUtils";
import { filterIsNotNullOrUndefined } from "../../utils/utils";

interface RuleDimensionSelectorProps {
  possibleDimensions: DimensionFilter[];
  dimensionKey: string;
  onDimensionChange: (dimensionKey: string) => void;
  error?: string;
}

export const getDimensionOptions = (
  dimensions: DimensionFilter[],
  tableKeyToConnectorKey: { [key: string]: string[] },
) => {
  return dimensions
    .filter((b) => b.name !== "date" && b.name !== "tenant_id")
    .sort((a, b) =>
      b.dependencies.length === 0
        ? 1
        : a.dependencies.length === 0
        ? -1
        : b.dependencies.length - a.dependencies.length,
    )
    .map((b) => ({
      value: b.name,
      id: b.name,
      column: b.name,
      table: b.name,
      searchableLabel: b.label,
      label: (
        <Flex flexDirection="row" alignItems="center" gap={8}>
          {b.name.startsWith("custom_") && (
            <ConnectorIcon name="Polar" size={16} />
          )}
          {[
            ...new Set([
              ...b.dependencies
                .filter((t) => t !== "*")
                .filter(filterIsNotNullOrUndefined)
                .map((i) => {
                  return getIntegrationIcon(tableKeyToConnectorKey[i]?.[0]);
                }),
            ]),
          ].map((src, i) => (
            <ConnectorIcon name={src} size={16} key={src + i} />
          ))}
          <div>{b.label}</div>
        </Flex>
      ),
    }));
};

export const RuleDimensionSelector: FunctionComponent<
  RuleDimensionSelectorProps
> = ({ dimensionKey, possibleDimensions, onDimensionChange, error }) => {
  const { tableKeyToConnectorKey } = useMetricList();
  const dimensionOptions = getDimensionOptions(
    possibleDimensions,
    tableKeyToConnectorKey,
  );
  return (
    <Select
      forcedDisplayedValue={
        possibleDimensions.find((b) => b.name === dimensionKey)?.label ||
        "Select dimension..."
      }
      options={dimensionOptions}
      onChange={(v) => onDimensionChange(v[0] as string)}
      selected={[]}
      closeWhenSelectionChange
      withSearch
      block
      error={error}
    />
  );
};
