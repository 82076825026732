import TabButtons from "../../../components/TabButtons/TabButtons";
import { Grid } from "../../../icecube-ux";
import { _ } from "../../../languages/helper";
import {
  CustomMetricDefinition,
  IMetricsList,
} from "../../../types/synthesizer";
import MetricList from "../shared/MetricList";

import MetricFilterList from "./components/MetricFilterList";
import MetricFormatting from "./MetricFormatting";
import "./sidebar.css";

interface SideBarProps {
  filterOpen: number;
  onToggleOpenFilter: (index: number) => void;
  search: string;
  onSearchChange: (value: string) => void;
  onChange: (value: string) => void;
  tab: string;
  selectedMetrics: string[];
  onAddMetric: (value: string) => void;
  loading: boolean;
  metricList: IMetricsList;
  metric: CustomMetricDefinition;
  onMetricChange: (metric: CustomMetricDefinition) => void;
  filteredSelectedIndex?: number;
  onFilteredSelectedIndexChange?: (index: number) => void;
}

export default function SideBar({
  filterOpen,
  onToggleOpenFilter,
  search,
  onSearchChange,
  onChange,
  tab,
  selectedMetrics,
  onAddMetric,
  loading,
  metricList,
  metric,
  onMetricChange,
  filteredSelectedIndex,
  onFilteredSelectedIndexChange,
}: SideBarProps) {
  return (
    <div className="sidebar">
      <div className="main-toggle" style={{ padding: "12px 12px 11px" }}>
        <Grid gridTemplateColumns="1fr auto" gap={8}>
          <TabButtons
            withoutMargin={true}
            value={tab}
            onChange={(t) => onChange(t as string)}
            tabs={[
              { label: _`Metrics`, value: "metrics" },
              { label: _`Filters`, value: "filters" },
              { label: _`Formatting`, value: "formatting" },
            ]}
          />
        </Grid>
      </div>
      {tab === "metrics" && (
        <MetricList
          search={search}
          onSearchChange={onSearchChange}
          loading={loading}
          metric={metric}
          metricList={metricList}
          disableCheckbox={true}
          onClickMetric={onAddMetric}
          selectedMetrics={selectedMetrics}
          startsWithMode={false}
          filteredSelectedIndex={filteredSelectedIndex}
          onFilteredSelectedIndexChange={onFilteredSelectedIndexChange}
        />
      )}
      {tab === "filters" && (
        <MetricFilterList
          open={filterOpen}
          onToggleOpen={onToggleOpenFilter}
          metric={metric}
          metricList={metricList}
          onChange={onMetricChange}
        />
      )}
      {tab === "formatting" && (
        <MetricFormatting
          loading={loading}
          onChange={onMetricChange}
          metric={metric}
        />
      )}
    </div>
  );
}
