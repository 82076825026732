import { ReactNode } from "react";

import { Box } from "../Box";
import { ConnectorIcon } from "../ConnectorIcon";
import { Flex } from "../Flex";
import { Icon } from "../Icon";
import { ProgressBar } from "../Progress";
import { theme } from "../theme";
import { Tooltip } from "../Tooltip";
import { Text } from "../Typography";

import {
  StyledCustomerJourneyTableCell,
  StyledCustomerJourneyTableFooterCell,
  StyledCustomerJourneyTableHeaderCell,
  StyledCustomerJourneyTableHeaderCellArrow,
} from "./CustomerJourneyTable.styled";
import {
  CustomerJourneyColumnKey,
  CustomerJourneyTableOrdering,
} from "./CustomerJourneyTableOrdering";

export const CustomerJourneyTableHeaderCell = ({
  children,
  ordering,
  onChangeOrdering,
  columnKey,
  tooltipContent,
}: {
  children: ReactNode;
  ordering: CustomerJourneyTableOrdering;
  onChangeOrdering: (ordering: CustomerJourneyTableOrdering) => void;
  columnKey: CustomerJourneyColumnKey;
  tooltipContent?: ReactNode;
}) => (
  <StyledCustomerJourneyTableHeaderCell
    onClick={() => {
      let newDirection: "ASC" | "DESC" = "DESC";
      if (columnKey === ordering.columnKey) {
        newDirection = ordering.direction === "ASC" ? "DESC" : "ASC";
      }
      onChangeOrdering({
        columnKey,
        direction: newDirection,
      });
    }}
  >
    {columnKey === ordering.columnKey && (
      <StyledCustomerJourneyTableHeaderCellArrow
        name={
          ordering.direction === "ASC" ? "ArrowUpWithTail" : "ArrowDownWithTail"
        }
      />
    )}
    <Box marginLeft={columnKey === ordering.columnKey ? 0 : 6} marginRight={4}>
      <ConnectorIcon name="Polar" size={12} withBackground />
    </Box>
    <Text variant="body12Regular">{children}</Text>
    {tooltipContent && (
      <Flex
        style={{ marginLeft: "auto", marginRight: 4 }}
        alignContent="center"
        onClick={(e) => e.stopPropagation()}
      >
        <Tooltip
          content={tooltipContent}
          mouseLeaveDelay={200}
          white
          alignCenter
          isInteractive={false}
        >
          <Icon name="InfoCircle" style={{ color: theme.colors.text90 }} />
        </Tooltip>
      </Flex>
    )}
  </StyledCustomerJourneyTableHeaderCell>
);

export const CustomerJourneyTableCell = ({
  value,
  formatter,
  relativeBarMax,
}: {
  value?: string | number;
  formatter: (value: number) => string;
  relativeBarMax?: number;
}) => (
  <StyledCustomerJourneyTableCell
    style={{ overflow: "hidden", paddingLeft: 2 }}
  >
    <Text variant="body12Regular" textAlign="right">
      {value == null ? null : formatter(parseFloat(`${value}`))}
    </Text>
    {relativeBarMax != null && (
      <ProgressBar
        progress={relativeBarMax ? parseFloat(`${value}`) / relativeBarMax : 0}
        width="80px"
        size="small"
        borderRadius="large"
        style={{ marginLeft: 8 }}
      />
    )}
  </StyledCustomerJourneyTableCell>
);

export const CustomerJourneyTableFooterCell = ({
  value,
  formatter,
}: {
  value?: string | number;
  formatter: (value: number) => string;
}) => (
  <StyledCustomerJourneyTableFooterCell>
    <Text variant="body12Medium" textAlign="right">
      {value == null ? null : formatter(parseFloat(`${value}`))}
    </Text>
  </StyledCustomerJourneyTableFooterCell>
);
