import React from "react";
import styled, { css } from "styled-components";

import { Icon } from "../../Icon";
import { legacyTheme } from "../../legacyTheme";
import { theme } from "../../theme";

import { usePosition } from "./PositionProvider";

const StyledTooltip = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: visible;
  z-index: 400; // Above top bar
  pointer-events: none;
`;

const GraphTooltip = styled.div<{ theme: string }>`
  box-shadow:
    0px 8px 24px rgba(47, 41, 156, 0.08),
    0px 2px 4px rgba(47, 41, 156, 0.12);
  box-sizing: border-box;
  background: #fff;
  opacity: 1;
  position: relative;
  border-radius: 8px;
  padding: 4px;
  transform: translate(-50%, -100%);
  width: 250px;
  max-width: 250px;
  pointer-events: all;
  ${(p) =>
    p.theme === "dark" &&
    css`
      width: 90px;
      max-width: 90px;
      background: ${theme.colors.black};
      opacity: 70%;
      display: flex;
      justify-content: center;
      color: ${theme.colors.white100};
      cursor: pointer;
    `}
`;

const GraphTooltipInside = styled.div<{
  tooltipHeightVariant: "small" | "large";
  withoutContentScroll?: boolean;
}>`
  padding: 4px;
  max-height: ${(p) => (p.tooltipHeightVariant === "small" ? "138px" : "100%")};
  overflow-y: auto;

  ${(p) =>
    p.withoutContentScroll &&
    css`
      overflow: hidden;
      display: flex;
      flex-direction: column;
    `}
`;

const GraphTooltipPointingArrow = styled.div<{ theme: string }>`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 24px;
  height: 7px;
  margin-left: -12px;

  &{Icon} {
    ${(p) => {
      if (p.theme === "dark") {
        return css`
          color: ${theme.colors.black};
        `;
      } else {
        return css`
          color: ${theme.colors.white100};
        `;
      }
    }}
  }
`;

interface TooltipProps {
  children?: React.ReactNode;
  margin: number[];
  parentDimensions: DOMRect;
  isVisible: boolean;
  shownInModal?: boolean;
  tooltipHeightVariant?: "small" | "large";
  theme?: "light" | "dark";
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  withoutContentScroll?: boolean;
}

export default function Tooltip({
  children,
  margin,
  parentDimensions,
  shownInModal,
  isVisible,
  onClick,
  onMouseEnter,
  onMouseLeave,
  tooltipHeightVariant = "small",
  theme = "light",
  withoutContentScroll = false,
}: TooltipProps) {
  const position = usePosition();

  if (!isVisible) {
    return null;
  }

  return (
    <StyledTooltip
      style={{
        zIndex: shownInModal ? legacyTheme.zIndexes.layer7 : 400,
        left: `${parentDimensions.left + (position.x || 0)}px`,
        top: `${parentDimensions.top + margin[0] - 7}px`,
      }}
      onClick={onClick}
    >
      <GraphTooltip
        theme={theme}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <GraphTooltipInside
          tooltipHeightVariant={tooltipHeightVariant}
          withoutContentScroll={withoutContentScroll}
        >
          {children}
        </GraphTooltipInside>
        <GraphTooltipPointingArrow theme={theme}>
          <Icon name="PointingArrow" size={7} />
        </GraphTooltipPointingArrow>
      </GraphTooltip>
    </StyledTooltip>
  );
}
