import React, { LazyExoticComponent } from "react";

import { IconSVGProps } from "../Icon/types";

import { ConnectorIconNames } from "./types";

const ConnectorsIconSVG: Record<
  ConnectorIconNames,
  LazyExoticComponent<(props: IconSVGProps) => React.ReactElement>
> = {
  ActiveCampaign: React.lazy(() => import("./svg/ActiveCampaign")),
  AdRoll: React.lazy(() => import("./svg/AdRoll")),
  AppleAppStore: React.lazy(() => import("./svg/AppleAppStore")),
  AppleSearchAds: React.lazy(() => import("./svg/AppleSearchAds")),
  Awin: React.lazy(() => import("./svg/Awin")),
  Amazon: React.lazy(() => import("./svg/Amazon")),
  Attentive: React.lazy(() => import("./svg/Attentive")),
  Benchmarks: React.lazy(() => import("./svg/Benchmarks")),
  Bing: React.lazy(() => import("./svg/Bing")),
  CampaignMonitor: React.lazy(() => import("./svg/CampaignMonitor")),
  Criteo: React.lazy(() => import("./svg/Criteo")),
  CustomerIo: React.lazy(() => import("./svg/CustomerIo")),
  Direct: React.lazy(() => import("./svg/Direct")),
  Facebook: React.lazy(() => import("./svg/Facebook")),
  Gmail: React.lazy(() => import("./svg/Gmail")),
  GoogleAds: React.lazy(() => import("./svg/GoogleAds")),
  GoogleAdManager: React.lazy(() => import("./svg/GoogleAdManager")),
  GoogleCampaignManager360: React.lazy(
    () => import("./svg/GoogleCampaignManager360"),
  ),
  GoogleAnalytics: React.lazy(() => import("./svg/GoogleAnalytics")),
  GoogleDisplayVideo360: React.lazy(
    () => import("./svg/GoogleDisplayVideo360"),
  ),
  GoogleSearchConsole: React.lazy(() => import("./svg/GoogleSearchConsole")),
  GoogleSheet: React.lazy(() => import("./svg/GoogleSheet")),
  Google: React.lazy(() => import("./svg/Google")),
  Grin: React.lazy(() => import("./svg/Grin")),
  Gorgias: React.lazy(() => import("./svg/Gorgias")),
  Impact: React.lazy(() => import("./svg/Impact")),
  Instagram: React.lazy(() => import("./svg/Instagram")),
  InstagramBusiness: React.lazy(() => import("./svg/InstagramBusiness")),
  LinkedinAds: React.lazy(() => import("./svg/LinkedinAds")),
  LoopReturns: React.lazy(() => import("./svg/LoopReturns")),
  Klaviyo: React.lazy(() => import("./svg/Klaviyo")),
  Mailchimp: React.lazy(() => import("./svg/Mailchimp")),
  Mailjet: React.lazy(() => import("./svg/Mailjet")),
  Pinterest: React.lazy(() => import("./svg/Pinterest")),
  Polar: React.lazy(() => import("./svg/Polar")),
  PolarPixel: React.lazy(() => import("./svg/PolarPixel")),
  Prestashop: React.lazy(() => import("./svg/Prestashop")),
  RedditAds: React.lazy(() => import("./svg/RedditAds")),
  Recharge: React.lazy(() => import("./svg/Recharge")),
  Sendinblue: React.lazy(() => import("./svg/Sendinblue")),
  Shopify: React.lazy(() => import("./svg/Shopify")),
  Slack: React.lazy(() => import("./svg/Slack")),
  Taboola: React.lazy(() => import("./svg/Taboola")),
  TheTradeDesk: React.lazy(() => import("./svg/TheTradeDesk")),
  Shiphero: React.lazy(() => import("./svg/Shiphero")),
  ShipStation: React.lazy(() => import("./svg/ShipStation")),
  Snapchat: React.lazy(() => import("./svg/Snapchat")),
  SpotifyAds: React.lazy(() => import("./svg/SpotifyAds")),
  StackAdapt: React.lazy(() => import("./svg/StackAdapt")),
  Tiktok: React.lazy(() => import("./svg/Tiktok")),
  TwitterAds: React.lazy(() => import("./svg/TwitterAds")),
  UpPromote: React.lazy(() => import("./svg/UpPromote")),
  WordPress: React.lazy(() => import("./svg/WordPress")),
  YahooDSP: React.lazy(() => import("./svg/YahooDSP")),
  Yotpo: React.lazy(() => import("./svg/Yotpo")),
  YouTubeAnalytics: React.lazy(() => import("./svg/YouTubeAnalytics")),
  ZohoCRM: React.lazy(() => import("./svg/ZohoCRM")),
  ZohoCampaigns: React.lazy(() => import("./svg/ZohoCampaigns")),
  GoogleSheets: React.lazy(() => import("./svg/GoogleSheets")),
  Omnisend: React.lazy(() => import("./svg/Omnisend")),
  Outbrain: React.lazy(() => import("./svg/Outbrain")),
  Outreach: React.lazy(() => import("./svg/Outreach")),
  Paypal: React.lazy(() => import("./svg/Paypal")),
  Postscript: React.lazy(() => import("./svg/Postscript")),
  Fairing: React.lazy(() => import("./svg/Fairing")),
};

export { ConnectorsIconSVG };
