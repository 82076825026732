import styled from "styled-components";

import { Text, theme } from "../../../icecube-ux";
import { TypographyProps } from "../../../icecube-ux/Typography/Typography";

const StyledHorizontalRuleWord = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledHorizontalRuleWordLine = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: ${theme.colors.borderLight};
`;

export const HorizontalRuleWord = (props: TypographyProps) => {
  return (
    <StyledHorizontalRuleWord>
      <StyledHorizontalRuleWordLine />
      <Text variant="body14Regular" color={theme.colors.text80} {...props} />
      <StyledHorizontalRuleWordLine />
    </StyledHorizontalRuleWord>
  );
};
