import { useBootstrap } from "../../../hooks/bootstrap";
import { Button } from "../../../icecube-ux";
import { _ } from "../../../languages/helper";
import { openIntercom } from "../../../utils/intercomUtils";
import { trackEvent, TRACKING_EVENTS } from "../../../utils/trackingUtils";

import EndPlanBlock from "./endPlanBlock";

type SubscriptionPlanButtonProps = {
  isOnPlan: boolean;
  isRedirecting: boolean;
  isSubscriptionEnding: boolean;
  subscriptionEndDate?: null | string | Date;
  redirectStripeUsageBasedCheckout?: () => void;
  redirectToStripeCustomerPortal?: () => Promise<void>;
};

const SubscriptionPlanButton = ({
  isOnPlan,
  isRedirecting,
  isSubscriptionEnding,
  subscriptionEndDate,
  redirectStripeUsageBasedCheckout,
  redirectToStripeCustomerPortal,
}: SubscriptionPlanButtonProps) => {
  const { tenant } = useBootstrap();
  const bookCallButton = (
    <Button
      block={true}
      loading={isRedirecting}
      onClick={() => {
        openIntercom();
        trackEvent(TRACKING_EVENTS.PRICING_BOOK_CALL_CLICKED, {
          isOnPlan,
          tenantId: tenant.id,
        });
      }}
      color="primary"
    >
      {_`Book a call`}
    </Button>
  );
  if (!isOnPlan) {
    if (redirectStripeUsageBasedCheckout) {
      return (
        <Button
          color="primary"
          block={true}
          loading={isRedirecting}
          onClick={() => redirectStripeUsageBasedCheckout()}
        >
          {_`Upgrade Now`}
        </Button>
      );
    } else {
      return bookCallButton;
    }
  }

  return (
    <>
      {isSubscriptionEnding && subscriptionEndDate && (
        <EndPlanBlock subscriptionEndDate={subscriptionEndDate} />
      )}
      {redirectToStripeCustomerPortal && (
        <Button
          block={true}
          loading={isRedirecting}
          onClick={() => void redirectToStripeCustomerPortal()}
          color={isSubscriptionEnding ? "primary" : "ghost"}
        >
          {isSubscriptionEnding && _`Renew`}
          {!isSubscriptionEnding && _`Manage`} your subscription
        </Button>
      )}
      {!redirectToStripeCustomerPortal && bookCallButton}
    </>
  );
};

export default SubscriptionPlanButton;
