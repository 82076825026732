import classNames from "classnames";
import { useEffect } from "react";

import { ClassAndStyleProps } from "../shared";

import {
  StyledEmbeddedVideo,
  StyledEmbeddedVideoWrapper,
} from "./EmbeddedVideo.styled";

export type EmbeddedVideoProps = ClassAndStyleProps & {
  src: string;
  title: string;
  height?: string;
  width?: string;
};

export default function EmbeddedVideo({
  className,
  style,
  height = "auto",
  width = height === "auto" ? "100%" : "auto",
  src,
  title,
}: EmbeddedVideoProps) {
  useEffect(() => {
    if (style?.width || style?.height) {
      console.error("width and height should be passed as props, not in style");
    }
  }, [style?.width, style?.height]);

  return (
    <StyledEmbeddedVideoWrapper
      style={{ ...style, width, height }}
      className={classNames(className)}
    >
      <StyledEmbeddedVideo
        title={title}
        src={src}
        allowFullScreen
      ></StyledEmbeddedVideo>
    </StyledEmbeddedVideoWrapper>
  );
}
