import moment from "moment";

import { AttributionRateData } from "../../../../../lib/integrationsService";
import { getDatesByGranularity } from "../../../../../utils/dateUtils";

const LOW_ATTRIBUTION_THRESHOLD = 90;

export const isAvailableData = <T extends object>(
  attributionRate: T | "loading" | "error",
): attributionRate is T => {
  return attributionRate !== "loading" && attributionRate !== "error";
};

export const getLowAttributionByStore = (
  attributionRates: AttributionRateData[],
) => {
  const attributionRatesByStore =
    attributionRates?.reduce(
      (
        attributionRatesByStore: Record<string, AttributionRateData[]>,
        attributionRates,
      ) => {
        const { store } = attributionRates;
        attributionRatesByStore[store] = [
          ...(attributionRatesByStore[store] ?? []),
          attributionRates,
        ];
        return attributionRatesByStore;
      },
      {},
    ) || {};
  return Object.entries(attributionRatesByStore).map(
    ([store, storeAttributionRate]) => ({
      store,
      hasLowAttributionRate: getLowAttribution(storeAttributionRate),
    }),
  );
};

export const getLowAttribution = (
  dataSourceAttributionRate: AttributionRateData[],
) => {
  const {
    hasAttributionRateData,
    overallFullyAttributedPercentage: overalFullyAttributedPercentage,
  } = calculateAttributionRates(dataSourceAttributionRate);

  return (
    hasAttributionRateData &&
    parseInt(overalFullyAttributedPercentage.toFixed(0)) <
      LOW_ATTRIBUTION_THRESHOLD
  );
};

const getValuesArray = ({
  dates,
  attributionRates,
  key,
}: {
  dates: string[];
  attributionRates: AttributionRateData[];
  key: "fully_attributed" | "not_attributed" | "total";
}) => {
  return dates.map(
    (date) => attributionRates?.find((d) => d.date === date)?.[key] ?? 0,
  );
};

export const calculateAttributionRates = (
  attributionRates: AttributionRateData[],
) => {
  const datesByGranularity = getDatesByGranularity(
    "day",
    {
      start: moment().startOf("day").subtract(7, "days"),
      end: moment().startOf("day"),
    },
    "sunday",
  );
  const dates = datesByGranularity.slice(0, datesByGranularity.length - 1);
  const fullyAttributedOrders = getValuesArray({
    dates,
    attributionRates,
    key: "fully_attributed",
  });
  const unattributedOrders = getValuesArray({
    dates,
    attributionRates,
    key: "not_attributed",
  });
  const hasAttributionRateData = Boolean(
    attributionRates && attributionRates.length > 0,
  );
  const totalOrdersCounts = getValuesArray({
    dates,
    attributionRates,
    key: "total",
  });
  const totalOrdersCount = totalOrdersCounts.reduce((total, d) => total + d, 0);

  const fullAttributedOrdersCounts = getValuesArray({
    dates,
    attributionRates,
    key: "fully_attributed",
  });
  const overallFullyAttributedPercentage = totalOrdersCount
    ? (fullAttributedOrdersCounts.reduce((total, d) => total + d, 0) /
        totalOrdersCount) *
      100
    : 0;

  return {
    dates,
    fullyAttributedOrders,
    unattributedOrders,
    hasAttributionRateData,
    totalOrdersCount,
    overallFullyAttributedPercentage,
  };
};
