import { legacyTheme } from "../legacyTheme";
import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { Switch } from "../Switch";
import { Text } from "../Typography";

import {
  StyledSwitchContainer,
  StyledSwitchContainerDescription,
  StyledSwitchContainerInputSection,
  StyledSwitchContainerLabelSection,
} from "./SwitchContainer.styled";

export type SwitchContainerProps = InteractionEvents<HTMLDivElement> &
  ClassAndStyleProps & {
    id: string;
    label: string;
    description?: string;
    value: boolean;
    disabled?: boolean;
    onChange: (value: boolean) => void;
  };

export default function SwitchContainer({
  id,
  label,
  description,
  value,
  onChange,
  disabled,
  className,
  style,
  ...interactionEvents
}: SwitchContainerProps) {
  return (
    <StyledSwitchContainer
      {...interactionEvents}
      className={className}
      style={style}
    >
      <StyledSwitchContainerLabelSection>
        <Text
          fontSize={legacyTheme.typography.fontSizes.large}
          fontWeight={legacyTheme.typography.fontWeigth.w500}
          lineHeight="18px"
        >
          {label}
        </Text>
        {description && (
          <StyledSwitchContainerDescription>
            {description}
          </StyledSwitchContainerDescription>
        )}
      </StyledSwitchContainerLabelSection>
      <StyledSwitchContainerInputSection>
        <Switch id={id} value={value} onChange={onChange} disabled={disabled} />
      </StyledSwitchContainerInputSection>
    </StyledSwitchContainer>
  );
}
