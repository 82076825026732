import * as d3 from "d3";

import { serieStack } from "./chartUtils";
import { Serie, SerieFocusState } from "./Serie";
import SingleSerieAsBars from "./SingleSerieAsBars";

interface StackedBarsProps {
  width: number;
  height: number;
  margin: [number, number, number, number];
  series: Serie<number>[];
  yScales: Record<string, d3.ScaleLinear<number, number, never>>;
  barSerieIndex?: number;
  maxIndex: number;
  totalNumberOfBarSeries?: number;
  onChangeFocusState?: (serieName: string, focusState: SerieFocusState) => void;
}

export default function StackedBars({
  width,
  height,
  margin,
  series,
  yScales,
  maxIndex,
  barSerieIndex = 0,
  totalNumberOfBarSeries = 1,
  onChangeFocusState,
}: StackedBarsProps) {
  return (
    <g x={0} y={0} width={width} height={height}>
      {serieStack(series)
        .reverse()
        .map((serie) => {
          if (serie.displayType === "tooltip-only") {
            return null;
          }

          return (
            <SingleSerieAsBars
              key={serie.name}
              serie={serie}
              width={width}
              height={height}
              totalNumberOfBarSeries={totalNumberOfBarSeries}
              margin={margin}
              yScale={yScales[serie.yAxisKey]}
              barSerieIndex={barSerieIndex}
              maxIndex={maxIndex}
              onChangeFocusState={onChangeFocusState}
            />
          );
        })}
    </g>
  );
}
