import { useCallback, useEffect, useState } from "react";

type UseMouse = {
  mousePosition: {
    x: number;
    y: number;
  };
  readonly pause: () => void;
  readonly restart: () => void;
};

const useMouse = (pauseStatus = false): Readonly<UseMouse> => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [paused, setPaused] = useState(pauseStatus);

  const updateMousePosition = useCallback(
    (ev: MouseEvent) => {
      if (!paused) {
        setMousePosition({ x: ev.clientX, y: ev.clientY });
      }
    },
    [paused],
  );

  const pause = useCallback(() => setPaused(true), [setPaused]);

  const restart = useCallback(() => setPaused(false), [setPaused]);

  useEffect(() => {
    window.addEventListener("mousemove", updateMousePosition);

    return () => window.removeEventListener("mousemove", updateMousePosition);
  }, [updateMousePosition]);

  return { mousePosition, pause, restart };
};

export default useMouse;
