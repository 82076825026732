import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  CALL_STATES,
  DASHBOARD_STATES,
  SETUP_STATES,
} from "../../common/setupState/consts";
import { SetupState } from "../../common/setupState/types";
import { useBootstrap } from "../../hooks/bootstrap";
import { useCallBooking } from "../../hooks/useCallBooking";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Box, Button, Flex, Grid, Popup, Text, theme } from "../../icecube-ux";
import { ButtonColor } from "../../icecube-ux/Button/types";
import { _ } from "../../languages/helper";
import { TRACKING_EVENTS, trackEvent } from "../../utils/trackingUtils";

const DEMO_DATA_POPUP_DISPLAY_INTERVAL_MINUTES = 3;
const DEMO_DATA_POPUP_DISPLAY_INTERVAL_MS =
  DEMO_DATA_POPUP_DISPLAY_INTERVAL_MINUTES * 60 * 1000;

interface PopupButton {
  label: string;
  color?: ButtonColor | undefined;
  onClick: () => void;
  loading?: boolean;
}

const getTextContent = (state: SetupState) => {
  const defaultContent = (
    <>
      {_`You're viewing sample data.`}
      <br />
      {_`You'll see your own data after the trial setup call.`}
    </>
  );
  if (state.code === SETUP_STATES.WAITING_DATA_DONE_CALL) {
    return (
      <>
        {_`Your data is loading. You'll be notified when it's ready.`}
        <br />
        {_`In the meantime you can try Polar with sample data.`}
      </>
    );
  } else if (state.code === SETUP_STATES.NO_DATA_DONE_CALL) {
    return (
      <>
        {_`You're viewing sample data.`}
        <br />
        {_`Connect your own data to try Polar for real.`}
      </>
    );
  } else if (state.code === SETUP_STATES.NO_DATA_WAITING_CALL) {
    return (
      <>
        {defaultContent}
        <br />
        <br />
        {_`Prepare for your call by connecting some data.`}
      </>
    );
  } else {
    return defaultContent;
  }
};

export default function DemoDataPopup() {
  const navigate = useNavigate();
  const { loading, setupState } = useBootstrap();
  const [acknowledgedDemoDataAt, setAcknowledgedDemoDataAt] = useLocalStorage<
    number | null
  >("acknowledged-demo-data-at", null);
  const getHasAcknowledgedDemoDataRecently = useCallback(
    () =>
      acknowledgedDemoDataAt !== null &&
      acknowledgedDemoDataAt + DEMO_DATA_POPUP_DISPLAY_INTERVAL_MS > Date.now(),
    [acknowledgedDemoDataAt],
  );

  const [showDemoDataPopup, setShowDemoDataPopup] = useState(
    () => !getHasAcknowledgedDemoDataRecently(),
  );
  const { openSetupCallBooking, isLoading: isCallBookingModalLoading } =
    useCallBooking();

  const acknowledgeAndClose = () => {
    trackEvent(TRACKING_EVENTS.DEMO_POPUP_ACKNOWLEDGED);
    setAcknowledgedDemoDataAt(Date.now());
  };

  const keepExloringButton: PopupButton = {
    label: "Keep using sample data",
    color: "ghost",
    onClick: acknowledgeAndClose,
  };

  const gotItButton: PopupButton = {
    label: "Got it",
    onClick: acknowledgeAndClose,
  };

  const bookCallButton: PopupButton = {
    label: "Book trial setup call",
    onClick: () => {
      trackEvent(TRACKING_EVENTS.BOOK_A_CALL_BUTTON_CLICKED, {
        buttonContext: "inbound-demo-data-popup",
      });
      void openSetupCallBooking();
    },
    loading: isCallBookingModalLoading,
  };
  const connectMyDataButton: PopupButton = {
    label: "Connect data",
    onClick: () => {
      trackEvent(TRACKING_EVENTS.CONNECT_DATA_BUTTON_CLICKED, {
        buttonContext: "inbound-demo-data-popup",
      });
      navigate("/connectors");
    },
  };

  const getButtons = (state: SetupState) => {
    if (state.call === CALL_STATES.INITIAL) {
      return [bookCallButton, keepExloringButton];
    } else if (state.data === DASHBOARD_STATES.NO_DATA) {
      return [connectMyDataButton, keepExloringButton];
    } else {
      return [gotItButton];
    }
  };

  const buttons = getButtons(setupState);

  useEffect(() => {
    setShowDemoDataPopup(!getHasAcknowledgedDemoDataRecently());

    if (acknowledgedDemoDataAt && getHasAcknowledgedDemoDataRecently()) {
      const msUntilNextPopup =
        DEMO_DATA_POPUP_DISPLAY_INTERVAL_MS -
        (Date.now() - acknowledgedDemoDataAt);
      const timeout = setTimeout(() => {
        setShowDemoDataPopup(true);
      }, msUntilNextPopup);

      return () => clearTimeout(timeout);
    }
  }, [acknowledgedDemoDataAt, getHasAcknowledgedDemoDataRecently]);

  if (loading || !showDemoDataPopup) return null;

  return (
    <Popup
      onClose={() => {
        /* don't allow closing except with ackonwledgement button */
      }}
      hideCloseButton
      withPaddingTop={false}
      withPadding={false}
    >
      <Box width="550px" height="100%">
        <Flex flexDirection="column" style={{ height: "100%" }}>
          <img src="/img/desktop-app-modal.svg" alt="" />
          <Box padding="50px 34px">
            <Text
              variant="body28SemiBold"
              textAlign="center"
              color={theme.colors.text110}
            >
              Demo mode
            </Text>
            <Text
              variant="body16Regular"
              textAlign="center"
              className="margin-top-xlarge"
              color={theme.colors.text80}
            >
              {getTextContent(setupState)}
            </Text>
          </Box>
          <Box padding="24px 34px" className="border-top" marginTop="auto">
            <Grid
              gridTemplateColumns={buttons.map(() => "1fr").join(" ")}
              gap={16}
            >
              {buttons.map((button) => (
                <Button
                  size="large"
                  color={button.color}
                  onClick={button.onClick}
                  loading={button.loading}
                >
                  {button.label}
                </Button>
              ))}
            </Grid>
          </Box>
        </Flex>
      </Box>
    </Popup>
  );
}
