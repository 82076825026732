import * as d3 from "d3";

import Bar from "./Bar";
import { Serie, SerieFocusState } from "./Serie";

interface BarCollectionProps {
  width: number;
  height: number;
  margin: [number, number, number, number];
  series: Serie<number>[];
  yScales: Record<string, d3.ScaleLinear<number, number>>;
  barSerieIndex?: number;
  maxIndex: number;
  forcedBarWidth?: number;
  totalNumberOfBarSeries?: number;
  onChangeFocusState?: (serieName: string, focusState: SerieFocusState) => void;
}

export default function BarCollection({
  width,
  height,
  margin,
  series,
  yScales,
  maxIndex,
  forcedBarWidth,
  barSerieIndex = 0,
  totalNumberOfBarSeries = 1,
  onChangeFocusState,
}: BarCollectionProps) {
  const xBarScale = d3
    .scaleBand<number>()
    .domain(d3.range(maxIndex))
    .range([margin[3], width - margin[1]]);

  const barWidth =
    forcedBarWidth ??
    Math.max(0, xBarScale.bandwidth() / (totalNumberOfBarSeries + 1));

  if (!totalNumberOfBarSeries) {
    return null;
  }

  return (
    <g x={0} y={0} width={width} height={height}>
      {series.map((serie, barIndex) => {
        if (serie.displayType === "tooltip-only") {
          return null;
        }

        const yScale = yScales[serie.yAxisKey];
        const value = serie.values[0];

        const leftBlockPart = xBarScale(barIndex) ?? 0;
        const rightBlockPart = xBarScale(barIndex + 1) ?? width;
        return (
          <Bar
            key={barIndex}
            serieName={serie.name}
            barWidth={barWidth}
            barHeight={yScale(value) - yScale(0)}
            barYPosition={yScale(0)}
            barXPosition={
              (leftBlockPart + rightBlockPart) / 2 +
              barSerieIndex * (barWidth + 2) -
              (totalNumberOfBarSeries * (barWidth + 2)) / 2
            }
            value={value}
            fill={serie.color}
            focusState={serie?.focusState}
            onChangeFocusState={onChangeFocusState}
          />
        );
      })}
    </g>
  );
}
