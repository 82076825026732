import { useNavigate } from "react-router-dom";

export const toSearchParams = (obj: Record<string, string | undefined>) => {
  const definedEntries = Object.entries(obj).filter(
    (entry): entry is [string, string] => !!entry[1],
  );
  return new URLSearchParams(Object.fromEntries(definedEntries)).toString();
};

/**
 * Helper to update the search parameters at the current location.
 * Uses the returned value from the transformer if provided, otherwise
 * the mutated value passed to the transformer.
 */
export const modifySearchParams = (
  navigate: ReturnType<typeof useNavigate>,
  transform: (
    draftParams: Record<string, string>,
  ) => Record<string, string> | void,
) => {
  const draftParams = Object.fromEntries(
    new URLSearchParams(window.location.search).entries(),
  );

  const search = toSearchParams(transform(draftParams) || draftParams);

  navigate(`${window.location.pathname}${search ? `?${search}` : ""}`);
};
