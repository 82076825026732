import classNames from "classnames";

import { Button, ButtonColor } from "../Button";
import { Flex } from "../Flex";
import { Icon } from "../Icon";
import { IconNames } from "../Icon/Icon";
import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { Space } from "../Space";
import { theme } from "../theme";
import { Text } from "../Typography";

import {
  StyledEmptyStateContainer,
  StyledEmptyStateIconContainer,
} from "./EmptyState.styled";

export type EmptyStateProps = InteractionEvents<HTMLDivElement> &
  ClassAndStyleProps & {
    iconContainerStyle?: React.CSSProperties;
    iconName?: IconNames;
    iconSize?: number;
    iconColor?: string;
    heading: string;
    subheading: string;
    buttonText?: string;
    buttonColor?: ButtonColor;
    buttonLeftIcon?: IconNames;
    onButtonClick?: () => void;
  };

export function EmptyState({
  heading,
  subheading,
  buttonText,
  buttonColor = "primary",
  buttonLeftIcon,
  onButtonClick,
  className,
  style,
  iconName,
  iconSize,
  iconColor,
  iconContainerStyle,
  ...interactionEvents
}: EmptyStateProps) {
  return (
    <StyledEmptyStateContainer
      {...interactionEvents}
      style={style}
      className={classNames(className)}
    >
      <Flex flexDirection="column">
        {iconName && (
          <StyledEmptyStateIconContainer style={iconContainerStyle}>
            <Icon name={iconName} size={iconSize || 86} color={iconColor} />
          </StyledEmptyStateIconContainer>
        )}
        <Space />
        <Text
          variant="body15SemiBold"
          color={theme.colors.text100}
          textAlign="center"
        >
          {heading}
        </Text>
        <Text
          variant="body13Regular"
          color={theme.colors.primary80}
          textAlign="center"
        >
          {subheading}
        </Text>
        {buttonText ? (
          <>
            <Space />
            <Flex justifyContent="center">
              <Button
                color={buttonColor}
                onClick={onButtonClick}
                leftIcon={buttonLeftIcon}
              >
                {buttonText}
              </Button>
            </Flex>
          </>
        ) : (
          <></>
        )}
      </Flex>
    </StyledEmptyStateContainer>
  );
}
