import styled from "styled-components";

import { Button, theme } from "../../../icecube-ux";

export const StyledOnboardingFormQuestionSlide = styled.div`
  display: flex;
`;

export const StyledOnboardingFormQuestionSlideFormPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  height: 100vh;
  min-width: MIN(651px, 100%);
  position: relative;
  background-color: ${theme.colors.bgLight10};
`;

export const StyledOnboardingFormQuestionSlideIllustrationPanel = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  margin: 12px;
  width: 576px;
  height: calc(100vh - 24px);
  border-radius: 16px;
  background: ${theme.customColors.darkerLandingPageBackground};
  background-image: url("img/illustration-background-effect-2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const StyledOnboardingFormQuestionSlideOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 580px;
  max-width: 100%;
`;

export const StyledOnboardingFormQuestionSlideHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 16px;

  @media screen and (min-width: 1440px) {
    padding-top: 24px;
  }
`;

export const StyledOnboardingFormQuestionSlideHeader = styled.div`
  width: 580px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledOnboardingFormQuestionSlideBody = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0px 16px;
`;

export const StyledOnboardingFormQuestionSlideBodyContents = styled.div`
  max-width: 100%;
`;

export const StyledOnboardingFormQuestionSlideFooter = styled.div`
  background: ${theme.colors.white100};
  display: flex;
  width: 100%;
  padding: 18px 40px;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  @media screen and (max-width: 1024px) {
    padding: 18px 70px;
    justify-content: center;
    flex-direction: column-reverse;
  }
`;

export const StyledOnboardingFormQuestionSlideFooterButton = styled(Button)`
  width: 159px;

  @media screen and (max-width: 1024px) {
    width: 90%;
    max-width: 280px;
  }
`;

export const StyledOnboardingFormQuestionSlideIllustrationPanelHeader = styled.div`
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 2;
`;

export const StyledOnboardingFormQuestionSlideIllustrationPanelBody = styled.div`
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  flex-shrink: 2;
  width: 100%;
  height: 100%;
`;
export const StyledOnboardingFormQuestionSlideIllustrationPanelBodyImage = styled.img`
  position: absolute;
  right: 0;
  top: 130px;
  height: calc(100% - 220px);
`;

export const StyledOnboardingFormQuestionSlideIllustrationPanelFooter = styled.div`
  position: absolute;
  left: 0;
  bottom: 30px;
  width: 100%;
`;
