import { useEffect, useMemo, useState } from "react";

import { useCustomElementEditors } from "../../../../hooks/customElementEditors";
import { useDimensionProvider } from "../../../../hooks/dimensions";
import { useMetricList } from "../../../../hooks/metricList";
import {
  ConnectorIconWrapperList,
  Flex,
  FunctionHelperProps,
  MultiStepFormulaInput,
  OperationSelector,
  StepProps,
  Text,
  theme,
} from "../../../../icecube-ux";
import { getIntegrationIcon } from "../../../../integrations/integration";
import { _ } from "../../../../languages/helper";
import { keyToLabel, ucFirst } from "../../../../utils/utils";
import {
  CUSTOM_DIMENSIONS_FUNCTIONS,
  CustomDimensionFunctions,
  CustomDimensionThenBlock,
} from "../../types";

import { DimensionAvailabilities, getAvailableDimensionList } from "./utils";

interface ThenFormulaElementProps {
  editing: boolean;
  block: CustomDimensionThenBlock;
  onChange: (newBlock: CustomDimensionThenBlock) => void;
  availableBreakdowns?: DimensionAvailabilities;
}

export default function ThenFormulaElement({
  editing,
  block,
  onChange,
  availableBreakdowns,
}: ThenFormulaElementProps) {
  const { disableEdit } = useCustomElementEditors();
  const { metrics, tableKeyToConnectorKey } = useMetricList();
  const { get, load, isLoading } = useDimensionProvider();

  const [totalSteps, setTotalSteps] = useState(1);
  const [functionHelperInfos, setFunctionHelperInfos] = useState<
    FunctionHelperProps[]
  >([]);

  const dimensionList = useMemo(
    () => getAvailableDimensionList(metrics, availableBreakdowns),
    [metrics, availableBreakdowns],
  );

  const options = [
    ...Object.keys(dimensionList).map((d) => ({
      value: "dimension." + d,
      label: keyToLabel(d),
    })),
    ...Object.entries(CUSTOM_DIMENSIONS_FUNCTIONS).map(
      ([_, { key, label }]) => ({ value: key, label }),
    ),
  ];

  const valueRenderer = (key: string, label: string) => {
    const isOperation = key.startsWith("operation.");
    const isDimension = key.startsWith("dimension.");
    const isCustomValue = !isOperation && !isDimension;
    const formattedLabel = isCustomValue ? label : ucFirst(label);
    return (
      <Text
        variant="body12Regular"
        color={
          isOperation
            ? theme.colors.formulaEditor.orange
            : isDimension
            ? theme.colors.formulaEditor.blue
            : theme.colors.formulaEditor.purple
        }
        style={{
          minWidth: "12px",
          whiteSpace: "pre",
          borderBottom:
            formattedLabel.trim() === ""
              ? `1px dashed ${theme.colors.borderDark}`
              : undefined,
        }}
      >
        {isCustomValue && (
          <Text
            color={theme.colors.formulaEditor.purple}
            style={{ display: "inline" }}
          >
            &lsquo;&nbsp;
          </Text>
        )}
        {formattedLabel}
        {isCustomValue && (
          <Text
            color={theme.colors.formulaEditor.purple}
            style={{ display: "inline" }}
          >
            &nbsp;&rsquo;
          </Text>
        )}
      </Text>
    );
  };

  const createStep = (
    functionHelperInfo?: FunctionHelperProps,
    argumentCount?: number,
  ): StepProps => ({
    argumentCount,
    functionHelperInfo,
    allowCustomValue: true,
    type: "dropdown",
    placeholder: (
      <>
        {_`assign a|||value`}{" "}
        <span style={{ color: theme.colors.formulaEditor.purple }}>
          {_`value`}
        </span>{" "}
        {_`or a|||dimension`}{" "}
        <span style={{ color: theme.colors.formulaEditor.blue }}>
          {_`dimension`}
        </span>{" "}
        {_`or select a|||function`}{" "}
        <span style={{ color: theme.colors.formulaEditor.orange }}>
          {_`function`}
        </span>
      </>
    ),
    options,
    valueRenderer,
    dropdownComponent: (
      <OperationSelector
        dimensions={Object.entries(dimensionList).map(([key, dimension]) => ({
          key: "dimension." + key,
          label: dimension.label,
          leftComponents: [
            <ConnectorIconWrapperList
              key="icon-list"
              max={8}
              names={
                dimension.label === "Date"
                  ? ["Polar"]
                  : dimension.tables.map((t) =>
                      getIntegrationIcon(tableKeyToConnectorKey[t]?.[0]),
                    )
              }
            />,
          ],
        }))}
        operations={Object.values(CUSTOM_DIMENSIONS_FUNCTIONS)}
        dimensionValueLoading={(dim) => {
          if (dim.key.startsWith("dimension.")) {
            const dimKey = dim.key.split(".")[1];
            return isLoading(dimensionList[dimKey].tables, dimKey);
          }
          return false;
        }}
        dimensionValues={(dim) => {
          if (dim.key.startsWith("dimension.")) {
            const dimKey = dim.key.split(".")[1];
            return get(dimensionList[dimKey].tables, dimKey).map((v) => ({
              value: v,
              label: v,
            }));
          }
          return [];
        }}
        onLoadDimensionValues={(dim) => {
          if (dim.key.startsWith("dimension.")) {
            const dimKey = dim.key.split(".")[1];
            load(dimensionList[dimKey].tables, dimKey);
          }
        }}
      />
    ),
  });

  const handleChange = (stepId: number, value: string | null) => {
    while (block.length < stepId) {
      block.push(null);
    }
    block[stepId] = value;
    onChange([...computeSteps(block)]);
  };

  const handleValuePasted = (id: number, value: string) => {
    const tokenize = (valueParam: string, tokens: string[] = []): string[] => {
      const value = valueParam.trim();

      if (value.startsWith("operation.")) {
        const splittedString = value.split("(");
        tokens.push(splittedString.shift() ?? "");
        return tokenize(splittedString.join("("), tokens);
      }

      if (value.startsWith("dimension.") || value.startsWith('"')) {
        const nextClosePar = value.indexOf(")");
        const nextComa = value.indexOf(",");
        if (nextClosePar === -1 && nextComa === -1) {
          tokens.push(value);
          return tokens;
        }

        const separator =
          (nextClosePar < nextComa && nextClosePar > -1) || nextComa === -1
            ? ")"
            : ",";
        const splittedString = value.split(separator);
        tokens.push(splittedString.shift() ?? "");
        return tokenize(splittedString.join(separator), tokens);
      }

      return tokens;
    };

    const tokens = tokenize(value);
    tokens.forEach((token, tokenIndex) => {
      handleChange(
        id + tokenIndex,
        token.startsWith('"') ? token.substring(1, token.length - 1) : token,
      );
    });
  };

  const computeSteps = (values: Array<string | null>) => {
    const functionHelperInfos: FunctionHelperProps[] = [];
    let totalSteps = 0;
    let toSkip = 0;
    let canContinue = 1;
    const operationStack: Array<{
      operation: CustomDimensionFunctions;
      paramCount: number;
    }> = [];
    values.forEach((value, valueId) => {
      if (canContinue < 1) {
        return;
      }

      if (operationStack.length > 0) {
        functionHelperInfos.push({
          name: operationStack[operationStack.length - 1].operation.label,
          parameters:
            operationStack[operationStack.length - 1].operation.params,
          highlighted:
            operationStack[operationStack.length - 1].operation.params.length -
            operationStack[operationStack.length - 1].paramCount,
        });
        operationStack[operationStack.length - 1].paramCount--;
        if (operationStack[operationStack.length - 1].paramCount < 1) {
          operationStack.pop();
        }
      }

      canContinue--;
      const operation = Object.entries(CUSTOM_DIMENSIONS_FUNCTIONS).find(
        ([_, { key }]) => key === value,
      );
      if (operation) {
        operationStack.push({
          operation: operation[1],
          paramCount: operation[1].params.length,
        });
        totalSteps += operation[1].params.length + (toSkip < 1 ? 1 : 0);
        toSkip += operation[1].params.length;
        canContinue += operation[1].params.length;
      } else {
        if (toSkip > 0) {
          toSkip--;
        } else if (value !== null || valueId === 0) {
          totalSteps++;
        }
      }
    });

    while (operationStack.length > 0) {
      functionHelperInfos.push({
        name: operationStack[operationStack.length - 1].operation.label,
        parameters: operationStack[operationStack.length - 1].operation.params,
        highlighted:
          operationStack[operationStack.length - 1].operation.params.length -
          operationStack[operationStack.length - 1].paramCount,
      });
      operationStack[operationStack.length - 1].paramCount--;
      if (operationStack[operationStack.length - 1].paramCount < 1) {
        operationStack.pop();
      }
    }

    setFunctionHelperInfos(functionHelperInfos);
    setTotalSteps(totalSteps);
    return values;
  };

  useEffect(() => {
    if (block.length > 0) {
      computeSteps(block);
    }
  }, [block]);

  if (!editing) {
    return (
      <Flex alignItems="center" gap={8} style={{ margin: "4px 0" }}>
        {block.map((value) =>
          valueRenderer(
            value ?? "",
            options.find((o) => o.value === value)?.label ?? value ?? "",
          ),
        )}
        <div>&nbsp;</div>
      </Flex>
    );
  }

  return (
    <MultiStepFormulaInput
      disableEdit={disableEdit}
      steps={new Array(totalSteps)
        .fill(1)
        .map((_, id) =>
          createStep(
            id > 0 ? functionHelperInfos[id - 1] : undefined,
            Object.entries(CUSTOM_DIMENSIONS_FUNCTIONS).find(
              ([_, o]) => o.key === block[id],
            )?.[1]?.params.length ?? 0,
          ),
        )}
      values={block}
      onChangeStepValue={handleChange}
      onPaste={handleValuePasted}
    />
  );
}
