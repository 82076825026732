import { colors, customColors } from "./colors";
import { shadows } from "./shadows";
import { sizes } from "./sizes";
import { typography } from "./typography";
import { zIndexes } from "./zindexes";

export const theme = {
  colors,
  customColors,
  typography,
  shadows,
  zIndexes,
  ...sizes,
};
export type { ColorFormat } from "./colors";
