import React, {
  MouseEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";

import { Box } from "../../Box";
import useDropdown from "../../Dropdown/Dropdown.hooks";
import useCombinedRefs from "../../hooks/useCombinedRefs";
import { List, ListOptions } from "../../List";
import { theme } from "../../theme";
import { Text } from "../../Typography";
import { stopPropagation } from "../../utils";

import AutoGrowInput from "./AutoGrowInput";
import CompletionHint from "./CompletionHint";
import FunctionHelper, { FunctionHelperProps } from "./FunctionHelper";
import Parenthesis from "./Parenthesis";

interface StepProps {
  type: "dropdown" | "select";
  value: string | null;
  disabled?: boolean;
  onClick?: MouseEventHandler;
  placeholder: React.ReactNode;
  options: ListOptions[];
  autoFocus?: boolean;
  content?: React.ReactElement;
  onOpen: () => void;
  onMoveLeft?: () => void;
  onMoveRight?: () => void;
  onValueChange: (value: string | null) => void;
  onPaste?: (value: string) => void;
  onSearchChange?: (value: string) => void;
  isOpened: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  isCurrentLast?: boolean;
  valueRenderer?: (
    value: string,
    label: string,
    options: ListOptions[],
  ) => React.ReactNode;
  allowCustomValue?: boolean;
  loading?: boolean;
  argumentCount?: number;
  openParenthesis?: boolean;
  closeParenthesis?: number;
  isFunctionArgument?: boolean;
  functionHelperInfo?: FunctionHelperProps;
  initialSearch?: string;
}

const StyledDropdownContent = styled.div`
  position: fixed;
  z-index: ${theme.zIndexes.layer4};
  background: ${theme.colors.white100};
  border: 1px solid ${theme.colors.borderLight};
  box-shadow:
    0px 4px 24px rgba(47, 41, 156, 0.08),
    0px 0px 4px rgba(47, 41, 156, 0.08);
  border-radius: ${theme.borderRadius.r4px};
  overflow: auto;
`;

const Step = React.forwardRef<HTMLInputElement | HTMLDivElement, StepProps>(
  (
    {
      type,
      value,
      onClick,
      disabled,
      placeholder,
      options,
      content,
      autoFocus = false,
      onOpen,
      onValueChange,
      onPaste,
      onMoveLeft,
      onMoveRight,
      onSearchChange,
      isOpened,
      isFirst,
      isLast,
      isCurrentLast,
      valueRenderer,
      allowCustomValue = false,
      loading = false,
      openParenthesis,
      closeParenthesis,
      isFunctionArgument,
      functionHelperInfo,
      initialSearch,
    },
    ref,
  ) => {
    const getLabelFromValue = (
      value: string,
      defaultValue: React.ReactNode = "",
    ) =>
      String(
        options.find((o) => o.value === value)?.label ??
          (allowCustomValue ? value : defaultValue),
      );

    const [filteredOptions, setFilteredOptions] =
      useState<ListOptions[]>(options);
    const [selectHintId, setSelectHintId] = useState(-1);
    const [inputValue, setInputValue] = useState(
      value === null ? "" : getLabelFromValue(value),
    );

    const inputRef = useRef<HTMLInputElement>(null);
    const combinedRef = useCombinedRefs(ref, inputRef);

    const { refCallback, listPosition, open, openDropdown } = useDropdown({
      onClick,
      disabled,
      withAddButton: false,
      onClickAddButton: undefined,
      notOpenable: false,
      allowFlexibleHeight: true,
      allowFlexibleWidth: true,
    });

    const focusInput = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    const completionValueList = useMemo(() => {
      const startValue = inputValue.toLowerCase();
      return filteredOptions.filter(
        (o) =>
          `${o.value}`.toLowerCase().startsWith(startValue) ||
          String(o.searchableLabel ?? o.label)
            .toLowerCase()
            .startsWith(startValue),
      );
    }, [inputValue, filteredOptions]);

    const textCompletionBestOption = useMemo(() => {
      const startValue = inputValue.toLowerCase();
      const possibilities = completionValueList.map((item) =>
        String(item.label),
      );
      const completionValue =
        possibilities.find((item) => item.length >= startValue.length) ?? "";
      if (completionValue.length >= startValue.length) {
        return completionValue.substring(inputValue.length);
      }
      return null;
    }, [inputValue, completionValueList]);

    const renderValue = () => {
      if (value !== null && valueRenderer) {
        return (
          <div ref={combinedRef}>
            {valueRenderer(`${value}`, getLabelFromValue(`${value}`), options)}
          </div>
        );
      }
      return (
        <Text variant="body12Regular" color={theme.colors.text80}>
          <div ref={combinedRef} />
          {value !== null
            ? getLabelFromValue(`${value}`, placeholder)
            : placeholder}
        </Text>
      );
    };

    const handleDelete = () => {
      if (inputValue === "") {
        onValueChange(null);
        onMoveLeft && onMoveLeft();
        return;
      }
    };

    const handleCursorMove = (
      movedLeft: boolean,
      event: React.KeyboardEvent<HTMLInputElement>,
    ) => {
      if (movedLeft && event.currentTarget.selectionStart === 0) {
        onMoveLeft && onMoveLeft();
      } else if (
        !movedLeft &&
        event.currentTarget.selectionStart === event.currentTarget.value.length
      ) {
        onMoveRight && onMoveRight();
      }
    };

    const handleComplete = (withTabKey: boolean) => {
      if (inputValue === "") {
        onValueChange(allowCustomValue ? "" : null);
        return;
      }
      if (allowCustomValue) {
        if (
          completionValueList.length > 0 &&
          (withTabKey || completionValueList[0].value === inputValue)
        ) {
          onValueChange(`${completionValueList[0].value}`);
        } else {
          onValueChange(inputValue);
        }
      } else {
        if (completionValueList.length > 0) {
          onValueChange(`${completionValueList[0].value}`);
        }
      }
    };

    const handlePressUp = () => {
      if (selectHintId > 0) {
        setSelectHintId((s) => {
          const newValue = s - 1;
          setInputValue(String(filteredOptions?.[newValue]?.label ?? ""));
          return newValue;
        });
      } else {
        setSelectHintId(filteredOptions.length - 1);
        setInputValue(
          String(filteredOptions?.[options.length - 1]?.label ?? ""),
        );
      }
    };

    const handlePressDown = () => {
      if (filteredOptions.length - 1 > selectHintId) {
        setSelectHintId((s) => {
          const newValue = s + 1;
          setInputValue(String(filteredOptions?.[newValue]?.label ?? ""));
          return newValue;
        });
      } else {
        setSelectHintId(0);
        setInputValue(String(filteredOptions?.[0]?.label ?? ""));
      }
    };

    useEffect(() => {
      if (inputValue === "") {
        setSelectHintId(-1);
        return;
      }

      if (completionValueList.length > 0) {
        setSelectHintId(
          filteredOptions.findIndex(
            (o) => o.value === completionValueList[0].value,
          ) ?? -1,
        );
      } else {
        setSelectHintId(-1);
      }
    }, [inputValue, filteredOptions, completionValueList]);

    useEffect(() => {
      if (open) {
        onOpen();
      } else if (
        allowCustomValue &&
        inputValue !== "" &&
        options.find((o) => o.value === value && o.label === inputValue) ===
          undefined
      ) {
        onValueChange(inputValue);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
      setFilteredOptions(options);
    }, [options]);

    useEffect(() => {
      setInputValue(value === null ? "" : getLabelFromValue(value));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
      if (autoFocus) {
        combinedRef.current?.click();
      }
    }, [autoFocus, combinedRef]);

    return (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        onClick={() => {
          onOpen();
          setTimeout(focusInput, 50);
        }}
        height="26px"
        lineHeight="26px"
        flexGrow={
          (isOpened && isCurrentLast) || (isFirst && isCurrentLast) ? 1 : 0
        }
        background={
          isOpened || value === null
            ? theme.colors.bgLight20
            : theme.colors.white100
        }
        borderRadius={theme.borderRadius.r3px}
        position="relative"
        ref={refCallback}
      >
        {(isOpened || (value === null && isLast)) && functionHelperInfo && (
          <FunctionHelper {...functionHelperInfo} />
        )}
        {!isOpened && value !== null && renderValue()}
        {(isOpened || value === null) && (
          <>
            <AutoGrowInput
              ref={combinedRef}
              autoFocus={autoFocus}
              value={inputValue}
              onChange={(v) => setInputValue(v)}
              onFocus={openDropdown}
              onComplete={handleComplete}
              onPressDown={handlePressDown}
              onPressUp={handlePressUp}
              onDelete={handleDelete}
              onPaste={(e) => {
                onPaste && onPaste(e.clipboardData.getData("Text"));
              }}
              onCursorMove={handleCursorMove}
              style={{ paddingLeft: "6px" }}
            />
            <CompletionHint
              placeholder={placeholder}
              inputValue={inputValue}
              completionBestOption={textCompletionBestOption}
              allowCustomValue={allowCustomValue}
            />
          </>
        )}
        {isOpened && (
          <StyledDropdownContent
            style={listPosition}
            onClick={stopPropagation}
            onKeyDown={(event) => {
              if (
                ["ArrowDown", "ArrowUp", "Enter", "Tab"].includes(event.key)
              ) {
                if (event.key === "ArrowDown") {
                  handlePressDown();
                } else if (event.key === "ArrowUp") {
                  handlePressUp();
                } else if (event.key === "Enter" || event.key === "Tab") {
                  handleComplete(true);
                }
                event.preventDefault();
                event.stopPropagation();
                return false;
              }
            }}
          >
            {type === "dropdown" &&
              content !== undefined &&
              React.cloneElement(content, {
                options,
                onValueChange,
                onFilterOptions: (filetered: ListOptions[]) =>
                  setFilteredOptions(filetered),
                highlight: inputValue,
                preSelectionHint:
                  filteredOptions?.[selectHintId]?.value || undefined,
              })}
            {type === "select" && (
              <Box padding="12px">
                <List
                  preSelectionHint={
                    filteredOptions?.[selectHintId]?.value || undefined
                  }
                  highlighted={inputValue}
                  loading={loading}
                  initialSearch={initialSearch}
                  options={filteredOptions}
                  selected={value !== null ? [value] : []}
                  onClickOnElement={(value) => onValueChange(`${value}`)}
                  onSearchChange={onSearchChange}
                  searchNoAutoFocus={allowCustomValue}
                  withSearch={onSearchChange !== undefined}
                />
                {!loading && filteredOptions.length === 0 && (
                  <Text variant="body13Light" style={{ fontStyle: "italic" }}>
                    No value
                  </Text>
                )}
              </Box>
            )}
          </StyledDropdownContent>
        )}
        {openParenthesis && <Parenthesis value="(" />}
        {new Array(closeParenthesis).fill(1).map((_, id) => (
          <Parenthesis key={`step-close-par-${id}`} value=")" />
        ))}
        {!openParenthesis && isFunctionArgument && <Parenthesis value="," />}
      </Box>
    );
  },
);

export default Step;
