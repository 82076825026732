import styled, { css } from "styled-components";

import { theme } from "../../theme";
import { typographyStylesMap } from "../../Typography/typographyStylesMap";

export const StyledInputWrapper = styled.div``;

export const StyledInputWrapperLabel = styled.label<{
  error?: string | React.ReactNode;
  isFocused: boolean;
  disabled?: boolean;
}>`
  ${typographyStylesMap.body11Medium}
  margin-bottom: 4px;
  color: ${theme.colors.text100};

  div:hover > & {
    color: ${theme.colors.text110};
  }

  input:focus + & {
  }

  ${({ isFocused }) =>
    isFocused ? `color: ${theme.colors.primary100} !important;` : ""}
  ${({ disabled }) =>
    disabled ? `color: ${theme.colors.text80} !important;` : ""}
  ${({ error }) => (error ? `color: ${theme.colors.error100} !important;` : "")}
`;

export const StyledInputWrapperMessage = styled.div<{
  type?: "help" | "error";
}>`
  ${typographyStylesMap.body9Regular}

  color: ${theme.colors.primary100};
  background-color: ${theme.colors.primary10};

  border-radius: ${theme.borderRadius.r2px};

  margin-top: 4px;
  padding: 1px 6px;

  ${({ type }) =>
    type === "error"
      ? css`
          color: ${theme.colors.error100};
          background-color: ${theme.colors.error10};
        `
      : ""}
`;
