import { useEffect } from "react";

import { ObjectValues } from "../../../common/types";
import { TRACKING_EVENTS, trackEvent } from "../../../utils/trackingUtils";

export const FRONTEND_TEST_VERSION_KEYS = {
  ACQUISITION_AS_HOME_PAGE: "frontend-test-version-acquisition-as-home-page",
} as const;

const FRONTEND_TEST_VERSION_OPTIONS = {
  [FRONTEND_TEST_VERSION_KEYS.ACQUISITION_AS_HOME_PAGE]: [
    "acquisition",
    "setup",
  ],
} as const;

/**
 * Pre-read test versions to avoid race condition with
 * time-sensitive AB tests.
 */
const TEST_VERSIONS = Object.fromEntries(
  Object.values(FRONTEND_TEST_VERSION_KEYS).map((testKey) => {
    const storedValue = localStorage.getItem(testKey);
    if (
      storedValue &&
      (FRONTEND_TEST_VERSION_OPTIONS[testKey] as readonly string[]).includes(
        storedValue,
      )
    ) {
      return [testKey, { value: storedValue, aNewValueWasGenerated: false }];
    } else {
      const options = FRONTEND_TEST_VERSION_OPTIONS[testKey];
      return [
        testKey,
        {
          value: options[Math.floor(Math.random() * options.length)],
          aNewValueWasGenerated: true,
        },
      ];
    }
  }),
);

export const useFrontendABTestVersion = <
  TKey extends ObjectValues<typeof FRONTEND_TEST_VERSION_KEYS>,
>(
  testKey: TKey,
) => {
  const { value, aNewValueWasGenerated } = TEST_VERSIONS[testKey];

  useEffect(() => {
    if (aNewValueWasGenerated) {
      localStorage.setItem(testKey, value);
      trackEvent(TRACKING_EVENTS.FRONTEND_AB_TEST_VERSION_SET, {
        testKey,
        value,
        options: FRONTEND_TEST_VERSION_OPTIONS[testKey],
      });
      TEST_VERSIONS[testKey].aNewValueWasGenerated = false;
    }
  }, [aNewValueWasGenerated, testKey, value]);

  return value as (typeof FRONTEND_TEST_VERSION_OPTIONS)[typeof testKey][number];
};
