import styled from "styled-components";

import { Icon } from "../Icon";
import { theme } from "../theme";

export const StyledMenuFoldNavButtonIcon = styled(Icon).attrs({})`
  color: ${theme.colors.text90};
`;

export const StyledMenuFoldNavButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.white100};
  border: 1px solid ${theme.colors.borderLight};
  height: 34px;
  width: 34px;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 0;
  cursor: pointer;

  &:hover {
    border: none;
    box-shadow: ${theme.shadows.regular};
  }
  &:hover ${StyledMenuFoldNavButtonIcon} {
    color: ${theme.colors.text110};
  }
`;
