import React, { LazyExoticComponent } from "react";

import { IconSVGProps } from "../Icon/types";

const EmojisIconSVG: {
  [key: string]: LazyExoticComponent<
    (props: IconSVGProps) => React.ReactElement
  >;
} = {
  Acquisition: React.lazy(() => import("./svg/Acquisition")),
  Party: React.lazy(() => import("./svg/Party")),
  Retention: React.lazy(() => import("./svg/Retention")),
  Products: React.lazy(() => import("./svg/Products")),
  Engagement: React.lazy(() => import("./svg/Engagement")),
  Custom: React.lazy(() => import("./svg/Custom")),
  Alerts: React.lazy(() => import("./svg/Alerts")),
  Subscriptions: React.lazy(() => import("./svg/Subscriptions")),
  Earth: React.lazy(() => import("./svg/Earth")),
  HandOverMouth: React.lazy(() => import("./svg/HandOverMouth")),
  Check: React.lazy(() => import("./svg/Check")),
  Compass: React.lazy(() => import("./svg/Compass")),
  Favorite: React.lazy(() => import("./svg/Favorite")),
  Rocket: React.lazy(() => import("./svg/Rocket")),
  Sparkle: React.lazy(() => import("./svg/Sparkle")),
  Goals: React.lazy(() => import("./svg/Goals")),
  Connectors: React.lazy(() => import("./svg/Connectors")),
  Money: React.lazy(() => import("./svg/Money")),
  AskPolar: React.lazy(() => import("./svg/AskPolar")),
  Battery: React.lazy(() => import("./svg/Battery")),
  CreativeStudio: React.lazy(() => import("./svg/CreativeStudio")),
  Inventory: React.lazy(() => import("./svg/Inventory")),
  Audience: React.lazy(() => import("./svg/Audience")),
};

export { EmojisIconSVG };
