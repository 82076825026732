import styled from "styled-components";

import { legacyTheme } from "../legacyTheme";
import { ClassAndStyleProps, InteractionEvents } from "../shared";

export type LoaderProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & { size?: number };

export const StyledLoader = styled.div`
  display: inline-block;
  border-radius: 50%;
  border: ${(props: LoaderProps) => Math.floor((props.size || 32) / 6)}px solid
    ${legacyTheme.colors.borders.hover};
  animation: spin 1s linear infinite;
  width: ${(props: LoaderProps) => props.size || 32}px;
  height: ${(props: LoaderProps) => props.size || 32}px;
  border-right-color: ${legacyTheme.colors.borders.hover};
  border-top-color: ${legacyTheme.colors.borders.hover};
  border-bottom-color: ${legacyTheme.colors.borders.hover};
  border-left-color: transparent;
`;
