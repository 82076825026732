import { useState } from "react";

import { ClassAndStyleProps, InteractionEvents } from "../shared";

import {
  StyledSwitch,
  StyledSwitchInputElement,
  StyledSwitchInputHandle,
  StyledSwitchInputRail,
} from "./Switch.styled";

export type SwitchProps = InteractionEvents<HTMLDivElement> &
  ClassAndStyleProps & {
    id?: string;
    value: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
  };

let incrementingId = 0;

export default function Switch({
  id,
  value,
  onChange,
  className,
  style,
  disabled,
  ...interactionEvents
}: SwitchProps) {
  const [fallbackUniqueId] = useState(() => `checkbox-id-${incrementingId++}`);

  return (
    <StyledSwitch {...interactionEvents} className={className} style={style}>
      <StyledSwitchInputElement
        type="checkbox"
        checked={value}
        id={id || fallbackUniqueId}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
        disabled={disabled}
        key={(id || fallbackUniqueId) + value}
      />
      <StyledSwitchInputRail htmlFor={id || fallbackUniqueId}>
        <StyledSwitchInputHandle />
      </StyledSwitchInputRail>
    </StyledSwitch>
  );
}
