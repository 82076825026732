import { useEffect, useState } from "react";

import { _ } from "../../../../languages/helper";
import { ucFirst } from "../../../../utils/utils";
import { Flex } from "../../../Flex";
import { IconButton } from "../../../IconButton";
import { List, ListOptions } from "../../../List";
import { Text } from "../../../Typography";

import Selector from "./Selector";
import { StyledSelector } from "./Selector.styled";
import TableBlock, {
  DimensionOrMetricDefinition,
  OperationDefinition,
} from "./TableBlock";

type OperationSelectorProps = {
  dimensions: DimensionOrMetricDefinition[];
  operations: OperationDefinition[];
  onValueChange?: (key: string) => void;
  onFilterOptions?: (options: ListOptions[]) => void;
  highlight?: string;
  preSelectionHint?: string;
  dimensionValueLoading?: (dimension: DimensionOrMetricDefinition) => boolean;
  dimensionValues: (dimension: DimensionOrMetricDefinition) => ListOptions[];
  onLoadDimensionValues: (dimension: DimensionOrMetricDefinition) => void;
};

export default function OperationSelector({
  dimensions,
  operations,
  onValueChange,
  onFilterOptions,
  highlight,
  preSelectionHint,
  dimensionValueLoading,
  dimensionValues,
  onLoadDimensionValues,
}: OperationSelectorProps) {
  const [filters, setFilters] = useState<string[]>([]);
  const [exploreValueFor, setExploreValueFor] =
    useState<DimensionOrMetricDefinition | null>(null);

  const handleSelectFilter = (key: string) => {
    setFilters((filters) =>
      filters.includes(key)
        ? filters.filter((f) => f !== key)
        : [...filters, key],
    );
  };

  const handleSwitchToValues = (dimension: DimensionOrMetricDefinition) => {
    onLoadDimensionValues(dimension);
    setExploreValueFor(dimension);
  };

  useEffect(() => {
    onFilterOptions &&
      onFilterOptions([
        ...(filters.length === 0 || filters.includes("dimensions")
          ? dimensions.map((d) => ({ value: d.key, label: d.label }))
          : []),
        ...(filters.length === 0 || filters.includes("operations")
          ? operations.map((d) => ({ value: d.key, label: d.label }))
          : []),
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  if (exploreValueFor !== null) {
    return (
      <StyledSelector>
        <Flex flexDirection="row" alignItems="center" gap={8}>
          <IconButton
            size="large"
            name="ArrowLeft"
            onClick={() => setExploreValueFor(null)}
          />
          <Text variant="body13Medium">
            {ucFirst(exploreValueFor.label)} {_`values`}
          </Text>
        </Flex>
        <List
          loading={
            dimensionValueLoading && dimensionValueLoading(exploreValueFor)
          }
          options={dimensionValues(exploreValueFor)}
          selected={[]}
          onClickOnElement={(value) =>
            onValueChange && onValueChange(`${value}`)
          }
          withSearch
        />
      </StyledSelector>
    );
  }

  return (
    <Selector
      label={_`I'm looking for...`}
      tabs={[
        {
          key: "dimensions",
          label: _`Dimensions`,
        },
        {
          key: "operations",
          label: _`Functions`,
        },
      ]}
      blocks={[
        {
          key: "dimensions",
          element: (
            <TableBlock
              highlight={highlight}
              preSelectionHint={preSelectionHint}
              onSelectElement={onValueChange}
              label={"Dimensions"}
              dimensions={dimensions}
              onToggleToValueExplorer={handleSwitchToValues}
              withValueExplorer
            />
          ),
        },
        {
          key: "operations",
          element: (
            <TableBlock
              highlight={highlight}
              preSelectionHint={preSelectionHint}
              onSelectElement={onValueChange}
              label={"Functions"}
              operations={operations}
            />
          ),
        },
      ]}
      filters={filters}
      onFilterChange={handleSelectFilter}
      onSelectAllFilters={() => setFilters([])}
    />
  );
}
