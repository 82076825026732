import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { CustomConnectorButtonSection } from "../pages/connectors/popups/connect/customConnectors/CustomConnectorButtonSection";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  key: "awin",
  image: "Awin",
  label: "Awin",
  get description() {
    return _``;
  },
  get customConnectorWarning() {
    return _`We're only able to access your Awin data through their API if you have an Awin Advanced or Awin Accelerate plan`;
  },
  category: INTEGRATION_CATEGORIES.advertising,
  isCustomConnector: true,
  backgroundColor: theme.customColors.fallbackConnectorBg,
  borderColor: theme.customColors.fallbackConnectorBorder,
  getButtonSection: () => CustomConnectorButtonSection,
});
