import classNames from "classnames";
import React from "react";

import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { theme } from "../theme";
import { Text } from "../Typography";

import {
  StyledBanner,
  StyledBannerActions,
  StyledBannerWarningBlock,
} from "./Banner.styled";

export type BannerProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    title: React.ReactNode;
    subtitle: React.ReactNode;
    actionsChildren: React.ReactNode;
    variant: "primary" | "notification";
    isMobile?: boolean;
  };

const wrappingBlockMap = {
  notification: StyledBannerWarningBlock,
  primary: React.Fragment,
};

const colorMap = {
  primary: { title: theme.colors.white100, subtitle: theme.colors.white70 },
  notification: {
    title: theme.colors.warning110,
    subtitle: theme.customColors.textWarningNotification,
  },
} as const;

export default function Banner({
  title,
  subtitle,
  actionsChildren,
  variant,
  className,
  style,
  isMobile,
  ...interactionEvents
}: BannerProps) {
  const InnerBorderWrapper = wrappingBlockMap[variant];

  return (
    <StyledBanner
      {...interactionEvents}
      isMobile={isMobile}
      className={classNames(className)}
      style={style}
      variant={variant}
    >
      <InnerBorderWrapper>
        <div>
          <Text variant="body12SemiBold" color={colorMap[variant].title} noWrap>
            {title}
          </Text>
          <Text variant="body11Regular" color={colorMap[variant].subtitle}>
            {subtitle}
          </Text>
        </div>
        <StyledBannerActions>{actionsChildren}</StyledBannerActions>
      </InnerBorderWrapper>
    </StyledBanner>
  );
}
