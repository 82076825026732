import React, { useMemo } from "react";

import { Box, Text, theme, useSortableGrid } from "../../../../icecube-ux";
import { _ } from "../../../../languages/helper";
import { CustomDimensionWhenBlock } from "../../types";

import FormulaOperator from "./FormulaOperator";
import WhenFormulaElement from "./WhenFormulaElement";

interface FormulaWhenBlockProps {
  editing: boolean;
  blocks: CustomDimensionWhenBlock[];
  onChange: (newBlocks: CustomDimensionWhenBlock[]) => void;
}

export default function FormulaWhenBlock({
  editing,
  blocks,
  onChange,
}: FormulaWhenBlockProps) {
  const handleDeleteBlock = (position: number) => {
    if (position > 0 && blocks.length === position + 1) {
      blocks[position - 1].lineOperator = "";
    }
    onChange(blocks.filter((_, index) => index !== position));
  };

  const elementBlockOrdered = useMemo(() => {
    const orderedBlocks: Array<
      Array<{ element: CustomDimensionWhenBlock; id: number }>
    > = [];
    let previousOperator = "and";
    blocks.forEach((element, id) => {
      if (previousOperator === "and") {
        orderedBlocks.push([]);
      }
      orderedBlocks[orderedBlocks.length - 1].push({ element, id });
      previousOperator = element.lineOperator;
    });
    return orderedBlocks;
  }, [blocks]);

  const handleBlockChange = (id: number, block: CustomDimensionWhenBlock) => {
    if (id === blocks.length - 1 && block.lineOperator !== "") {
      blocks.push({
        dimensionKey: "",
        operator: "",
        value: "",
        lineOperator: "",
      });
    }
    blocks[id] = { ...block };
    onChange([...blocks]);
  };

  const handleReorder = (
    _: Array<string | number>,
    from: number,
    to: number,
  ) => {
    if (to === from) {
      return;
    }

    const temp = blocks.splice(from, 1);
    blocks.splice(to, 0, temp[0]);

    for (let i = 0; i < blocks.length - 1; i++) {
      if (blocks[i].lineOperator === "") {
        blocks[i].lineOperator = "or";
      }
    }
    blocks[blocks.length - 1].lineOperator = "";
    onChange([...blocks]);
  };

  const { x, y, ref, handleMouseDown, movingIndex, toIndex } = useSortableGrid({
    seriesLength: blocks.length,
    options: blocks.map((_, id) => ({ value: id, label: `${id}` })) || [],
    onSort: handleReorder,
    direction: "row",
  });

  return (
    <Box>
      <Text variant="body13SemiBold" color={theme.colors.text110}>
        {_`When`}
      </Text>
      <div ref={ref}>
        {elementBlockOrdered.map((block, blockIndex) => {
          return (
            <React.Fragment key={`block-${blockIndex}`}>
              <Box
                display="flex"
                flexDirection="column"
                borderRadius="4px"
                border={`1px solid ${theme.colors.borderLight}`}
                padding="6px"
                marginTop="6px"
              >
                {block.map(({ element, id }, elementIndex) => {
                  return (
                    <React.Fragment key={`when-${id}-${elementIndex}`}>
                      <WhenFormulaElement
                        editing={editing}
                        onChange={(newBlock) => handleBlockChange(id, newBlock)}
                        block={element}
                        canDelete={id > 0}
                        onDelete={() => handleDeleteBlock(id)}
                        position={{ row: id, column: 0 }}
                        startDragEvent={handleMouseDown}
                        dragStatus={{ movingIndex, toIndex }}
                      />
                      {elementIndex < block.length - 1 && (
                        <FormulaOperator operator={"or"} />
                      )}
                    </React.Fragment>
                  );
                })}
              </Box>
              {blockIndex < elementBlockOrdered.length - 1 && (
                <FormulaOperator operator="and" />
              )}
            </React.Fragment>
          );
        })}

        {movingIndex !== null && (
          <Box
            position="fixed"
            borderRadius="6px"
            height="32px"
            width="60vw"
            left={`${x}px`}
            top={`${y}px`}
            background={theme.colors.bgLight30 + "D0"}
          />
        )}
      </div>
    </Box>
  );
}
