import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

interface ScrollContextProps {
  index: number;
  reset: (() => void) | null;
}

const ScrollContext = createContext<ScrollContextProps | null>(null);

export function ProvideScroll({ children }: { children: ReactNode }) {
  const [scroll, setScroll] = useState({ index: 0, reset: () => {} });

  const update = () => {
    const sections = document.getElementsByClassName("anchor-span");
    const positions = Array.from(sections).map(
      (section) => section.getBoundingClientRect().top,
    );

    const index = positions.findIndex((position) => position > -200);

    setScroll({
      index: index === -1 ? positions.length - 1 : index,
      reset: () => {
        return setScroll((s) => ({ ...s, index: 0 }));
      },
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", update, { capture: true });
    return () => {
      window.removeEventListener("scroll", update);
    };
  }, []);

  return (
    <ScrollContext.Provider value={scroll}>{children}</ScrollContext.Provider>
  );
}

export const useScroll = () => {
  const context = useContext(ScrollContext);
  if (context === null) {
    throw Error("Scroll context not provided");
  }
  return context;
};
