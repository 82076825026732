import { useState } from "react";
import ReactDOM from "react-dom";

import { useAnnotationPopup } from "../../../hooks/useAnnotationPopup";
import { _ } from "../../../languages/helper";
import { Icon } from "../../Icon";
import { theme } from "../../theme";
import { Text } from "../../Typography";

import Tooltip from "./Tooltip";

export const AddAnnotation = ({
  x,
  y,
  dimensions,
  selectedDate,
}: {
  x: number;
  y: number;
  dimensions: DOMRect;
  selectedDate: string;
}) => {
  const [isHovered, setHovered] = useState<boolean>(false);
  const { setOpenInfo } = useAnnotationPopup();
  return (
    <g>
      <foreignObject x={x} y={y} width={14} height={13}>
        <Icon
          onClick={() => setOpenInfo({ mode: "add", selectedDate })}
          name="AddMarker"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        />
      </foreignObject>
      {ReactDOM.createPortal(
        <Tooltip
          theme="dark"
          isVisible={isHovered}
          margin={[0, 0, 0, 0]}
          parentDimensions={dimensions}
        >
          <Text
            variant="body9Regular"
            color={theme.colors.white100}
          >{_`Add annotation`}</Text>
        </Tooltip>,
        document.body,
      )}
    </g>
  );
};
