import moment from "moment";

import { ComposeDataElement } from "../types/composeData";

import {
  applyDateBoundaries,
  createUserMoment,
  DateRange,
  Granularity,
} from "./dateUtils";
import { isRuleGroup, RuleOperators, SmartFilterSelector } from "./filterUtils";

export const EMPTY_VALUE_PLACEHOLDER = "∅";

export interface ComposeRule {
  operator: RuleOperators;
  value: string[] | SmartFilterSelector[];
}
export type ComposeRuleElement = ComposeRule | ComposeRule[];

export interface ComposeRules {
  [key: string]: ComposeRuleElement[];
}
export const getFilterFirstValue = (
  filter: ComposeRuleElement[],
  operator?: RuleOperators,
) => {
  return filter?.length === 1 &&
    !Array.isArray(filter[0]) &&
    (operator ? filter[0].operator === operator : true) &&
    filter[0].value.length === 1 &&
    typeof filter[0].value[0] === "string"
    ? filter[0].value[0]
    : "";
};
export const getIsExactFilterFirstValue = (filter: ComposeRuleElement[]) => {
  return getFilterFirstValue(filter, RuleOperators.is);
};

const formatComposeDateRule = (dateRule: ComposeRule) => {
  return {
    ...dateRule,
    value: (dateRule.value as SmartFilterSelector[]).map((value) => {
      return {
        ...value,
        rangeSelection: {
          start: value.rangeSelection.start.format("YYYY-MM-DD"),
          end: value.rangeSelection.end.format("YYYY-MM-DD"),
        },
      };
    }),
  };
};

export const formatComposeRules = (rules: ComposeRules) => {
  const formatRule = (r: ComposeRule) => {
    if (r.operator === RuleOperators.date) {
      return formatComposeDateRule(r);
    }
    if (r.value.length === 1 && r.value[0] === null) {
      return {
        ...r,
        value: [EMPTY_VALUE_PLACEHOLDER],
      };
    }
    return r;
  };
  return Object.fromEntries(
    Object.entries(rules).map(([rulesKey, rules]) => {
      const newRules = rules.map((rule) => {
        if (isRuleGroup(rule)) {
          return rule.map((r) => formatRule(r));
        } else {
          return formatRule(rule);
        }
      });
      return [rulesKey, newRules];
    }),
  );
};

export const getRowRulesFromBreakdowns = (
  breakdownColumns: string[],
  parentRowDrillDownKeys: string[],
  line: ComposeDataElement,
  curDrillDownValues: string[],
  drillDownFilterKeys?: Record<string, string>,
) => {
  const rowRules: ComposeRules = breakdownColumns.reduce((acc, key) => {
    return {
      ...acc,
      [key]: [
        {
          operator: RuleOperators.is,
          value: [line[key]],
        },
      ],
      ...(drillDownFilterKeys?.[key]
        ? {
            [drillDownFilterKeys?.[key]]: [
              {
                operator: RuleOperators.is,
                value: [line[drillDownFilterKeys?.[key]]],
              },
            ],
          }
        : {}),
    };
  }, {});
  parentRowDrillDownKeys.forEach((key, index) => {
    if (breakdownColumns.includes(key) || key === "") {
      return;
    }
    rowRules[key] = [
      {
        operator: RuleOperators.is,
        value: [curDrillDownValues[index]],
      },
    ];
  });
  return rowRules;
};

export const getDateRowRulesWithGranularity = (
  rules: ComposeRules | undefined,
  dateRage: DateRange,
  dateGranularity: Granularity | "none",
  weekStart: string,
) => {
  if (
    !rules ||
    !rules["date"] ||
    dateGranularity === "none" ||
    dateGranularity === "day"
  ) {
    return { rules, dateRange: undefined };
  }
  const { date, ...rest } = rules;
  const userMoment = createUserMoment(weekStart);
  const start = applyDateBoundaries(
    userMoment(moment(getIsExactFilterFirstValue(rules["date"]))),
    dateRage.start,
    dateRage.end,
  );
  const end = applyDateBoundaries(
    userMoment(start.clone()).endOf(dateGranularity),
    dateRage.start,
    dateRage.end,
  );

  return {
    rules: {
      ...rest,
    },
    dateRange: {
      start,
      end,
    } as DateRange,
  };
};

export const ruleToString = (
  filterElements: ComposeRuleElement | ComposeRuleElement[],
  isGroup?: boolean,
): string => {
  const resultParts = [];

  if (Array.isArray(filterElements)) {
    resultParts.push(
      filterElements
        .map((element) => ruleToString(element, Array.isArray(element)))
        .join(isGroup ? " AND " : " OR "),
    );
  } else {
    const { operator, value } = filterElements;

    if (!(operator === RuleOperators.is)) {
      resultParts.push(operator);
    }

    resultParts.push(`${value.join(` OR `)}`);
  }

  const result = resultParts.join(" ");
  return isGroup ? `(${result})` : result;
};
