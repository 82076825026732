import { ReactNode, useState } from "react";

import { Flex } from "../Flex";
import { IconButton } from "../IconButton";

import {
  StyledExpandableButtonContainer,
  StyledExpandableButtonDetail,
  StyledExpandableButtonHeader,
  StyledText,
} from "./ExpandableButton.styled";

export interface ExpandableButtonProps {
  label: string | ReactNode;
  leftComponent?: ReactNode;
  rightComponent?: ReactNode;
  children: ReactNode;
  canExpand?: boolean;
  isDefaultOpen?: boolean;
  className?: string;
  isOpen?: boolean;
  onSetOpen?: (isOpen: boolean) => void;
  toggleButton?: ReactNode;
}

const ExpandableButton = ({
  label,
  leftComponent,
  rightComponent,
  children,
  canExpand = true,
  isDefaultOpen = false,
  isOpen: isOpenProp,
  onSetOpen,
  toggleButton,
  className,
}: ExpandableButtonProps) => {
  const [localIsOpen, setLocalIsOpen] = useState(isDefaultOpen);

  const isOpen = isOpenProp === undefined ? localIsOpen : isOpenProp;
  const toggleOpen = () => {
    const newValue = !isOpen;

    setLocalIsOpen(newValue);
    onSetOpen?.(newValue);
  };

  return (
    <StyledExpandableButtonContainer className={className}>
      <StyledExpandableButtonHeader onClick={toggleOpen}>
        {typeof label === "string" ? (
          <StyledText variant="body14SemiBold">{label}</StyledText>
        ) : (
          label
        )}

        <Flex gap={16} alignItems="center">
          {leftComponent}
          {canExpand &&
            (toggleButton || (
              <IconButton
                name={isOpen ? "ArrowUp" : "ArrowDown"}
                color="default"
                size="small"
                onClick={toggleOpen}
              />
            ))}
          {rightComponent}
        </Flex>
      </StyledExpandableButtonHeader>

      {canExpand && isOpen && (
        <StyledExpandableButtonDetail>{children}</StyledExpandableButtonDetail>
      )}
    </StyledExpandableButtonContainer>
  );
};

export default ExpandableButton;
