import { useRef, useEffect } from "react";

export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef<() => void>();
  const firstCall = useRef(false);

  useEffect(() => {
    savedCallback.current = callback;
    if (!firstCall.current) {
      savedCallback.current?.();
      firstCall.current = true;
    }
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current?.();
    };

    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};
