import { ReactNode, createContext, useContext, useState } from "react";

import { SignUpPopup } from "../pages/login/SignupPopup";

type SignupFromOutboundPopupContextType = {
  isOpen: boolean;
  openPopup: () => void;
  closePopup: () => void;
};
const SignupFromOutboundPopupContext = createContext<
  SignupFromOutboundPopupContextType | undefined
>(undefined);

export const useSignupFromOutboundPopup = () => {
  const context = useContext(SignupFromOutboundPopupContext);
  if (!context) {
    throw new Error(
      "useSignupFromOutboundPopup must be used within ProvideSignupFromOutboundPopup. Make sure the Route's component is wrapped with withAuthentication",
    );
  }
  return context;
};

const useProvideSignupFromOutboundPopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  const SignupFromOutboundPopupContextValue: SignupFromOutboundPopupContextType =
    {
      isOpen,
      openPopup: () => setIsOpen(true),
      closePopup: () => setIsOpen(false),
    };

  return SignupFromOutboundPopupContextValue;
};

export const ProvideSignupFromOutboundPopup: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const contextValue = useProvideSignupFromOutboundPopup();

  return (
    <SignupFromOutboundPopupContext.Provider value={contextValue}>
      {children}
      {contextValue.isOpen && <SignUpPopup onClose={contextValue.closePopup} />}
    </SignupFromOutboundPopupContext.Provider>
  );
};
