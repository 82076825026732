import React, { ReactNode, createContext, useContext, useState } from "react";

import {
  Box,
  Button,
  Flex,
  Grid,
  Input,
  Loader,
  Popup,
  Select,
  Space,
  Text,
  legacyTheme,
} from "../icecube-ux";
import { _ } from "../languages/helper";

import { ModalExtraButtons, ModalSettings } from "./modalsTypes";

type ConfirmModalSettings = ModalSettings & {
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  onCancel?: () => void;
  onConfirm?: () => void | Promise<void>;
};

type AlertModalSettings = ModalSettings & {
  closeButtonLabel?: string;
  onClose?: () => void;
};

type InputModalSettings = ModalSettings &
  Omit<ConfirmModalSettings, "onConfirm"> & {
    inputPlaceholder?: string;
    inputInitialValue?: string;
    inputRightText?: string;
    inputLeftText?: string;
    onConfirm?: (value: string | number) => void | Promise<void>;
  };

type SelectModalSettings = ModalSettings &
  Omit<ConfirmModalSettings, "onConfirm"> & {
    selectPlaceholder?: string;
    selectInitialValue?: string;
    selectFooterAction?: {
      label: string | React.ReactElement;
      onClick: () => void;
    };
    options: Array<{ label: string; value: string | number }>;
    onConfirm?: (value: string | number) => void | Promise<void>;
  };

type CustomModalSettings = {
  modalId: string;
  component: (onClose: () => void) => React.ReactNode;
};

type ModalSetting = (
  | SelectModalSettings
  | InputModalSettings
  | AlertModalSettings
  | ConfirmModalSettings
  | CustomModalSettings
) & { type: "select" | "input" | "confirm" | "alert" | "custom" };

interface ModalsContextProps {
  modals?: { [key: string]: ModalSetting };
  loader?: React.ReactNode;
  inputValues: { [key: string]: string | number };
  setInputValue: (key: string, value: string) => void;
  confirm: (settings: ConfirmModalSettings) => void;
  alert: (settings: AlertModalSettings) => void;
  input: (settings: InputModalSettings) => void;
  select: (settings: SelectModalSettings) => void;
  showLoader: () => void;
  hideLoader: () => void;
  clear: () => void;
  hideModal: (id: string) => void;
}

const ModalsContext = createContext<ModalsContextProps | null>(null);

export function ProvideModals({ children }: { children: ReactNode }) {
  const {
    modals,
    loader,
    confirm,
    alert,
    input,
    select,
    showLoader,
    hideLoader,
    hideModal,
    clear,
    inputValues,
    setInputValue,
  } = useProvideModals();

  const renderTexts = (
    title: string | React.ReactElement,
    texts: string[] | React.ReactNode[],
    danger?: boolean,
  ) => (
    <>
      <Text
        fontWeight="400"
        fontSize={legacyTheme.typography.fontSizes.xxlarge}
        color={legacyTheme.colors.grey1}
        lineHeight={legacyTheme.typography.lineHeights.xxlarge}
        className="padding-bottom-small"
      >
        {title}
      </Text>
      {texts.map((text, i) =>
        text ? (
          <Text
            key={`text-${i}`}
            fontWeight="400"
            fontSize={legacyTheme.typography.fontSizes.large}
            lineHeight={legacyTheme.typography.lineHeights.regular}
            color={
              danger ? legacyTheme.colors.error1 : legacyTheme.colors.primary3
            }
          >
            {text}
          </Text>
        ) : (
          <Box
            key={`box-${i}`}
            height={legacyTheme.typography.lineHeights.regular}
          />
        ),
      )}
    </>
  );

  const renderExtraButtons = (id: string, extraButtons: ModalExtraButtons[]) =>
    extraButtons.map((button, i) => (
      <Button
        key={`extra-button-${i}`}
        size="large"
        label={button.label}
        color={button.color}
        onClick={() => {
          button.onClick && button.onClick();
          if (button.closeOnClick) {
            hideModal(id);
          }
        }}
      />
    ));

  const renderInput = (key: string, settings: InputModalSettings) => (
    <Popup
      key={key}
      onClose={() => {
        settings.onCancel && settings.onCancel();
        hideModal(key);
      }}
      footerComponent={
        <Grid
          gridTemplateColumns={`repeat(${
            2 + (settings.extraButtons || []).length
          }, 1fr)`}
          gap={10}
        >
          <Button
            size="large"
            label={settings.cancelButtonLabel || _`Cancel`}
            color="tertiary"
            onClick={() => {
              settings.onCancel && settings.onCancel();
              hideModal(key);
            }}
          />
          {renderExtraButtons(key, settings.extraButtons || [])}
          <Button
            size="large"
            label={settings.confirmButtonLabel || _`Confirm`}
            color="primary"
            onClick={() => {
              void settings.onConfirm?.(inputValues[key] ?? "");
              hideModal(key);
            }}
          />
        </Grid>
      }
    >
      {renderTexts(settings.title, settings.texts, settings.danger)}
      <div className="margin-top-xxlarge">
        <Input
          block
          autoFocus
          value={inputValues[key]}
          placeholder={settings.inputPlaceholder}
          onChange={(v) => setInputValue(key, v)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              void settings.onConfirm?.(inputValues[key] ?? "");
              hideModal(key);
            }
          }}
          leftText={settings.inputLeftText}
          rightText={settings.inputRightText}
        />
      </div>
    </Popup>
  );

  const renderConfirm = (key: string, settings: ConfirmModalSettings) => (
    <Popup
      key={key}
      onClose={() => {
        settings.onCancel && settings.onCancel();
        hideModal(key);
      }}
      footerComponent={
        <Grid
          gridTemplateColumns={`repeat(${
            2 + (settings.extraButtons || []).length
          }, 1fr)`}
          gap={10}
        >
          <Button
            size="large"
            label={settings.cancelButtonLabel || _`Cancel`}
            color="tertiary"
            onClick={() => {
              settings.onCancel && settings.onCancel();
              hideModal(key);
            }}
          />
          {renderExtraButtons(key, settings.extraButtons || [])}
          <Button
            size="large"
            label={settings.confirmButtonLabel || _`Confirm`}
            color={settings.danger ? "error" : "primary"}
            onClick={() => {
              void settings.onConfirm?.();
              hideModal(key);
            }}
          />
        </Grid>
      }
    >
      {renderTexts(settings.title, settings.texts, settings.danger)}
    </Popup>
  );

  const renderSelect = (key: string, settings: SelectModalSettings) => (
    <Popup
      key={key}
      onClose={() => {
        settings.onCancel && settings.onCancel();
        hideModal(key);
      }}
      footerComponent={
        <Grid
          gridTemplateColumns={`repeat(${
            2 + (settings.extraButtons || []).length
          }, 1fr)`}
          gap={10}
        >
          <Button
            size="large"
            label={settings.cancelButtonLabel || _`Cancel`}
            color="tertiary"
            onClick={() => {
              settings.onCancel && settings.onCancel();
              hideModal(key);
            }}
          />
          {renderExtraButtons(key, settings.extraButtons || [])}
          <Button
            size="large"
            label={settings.confirmButtonLabel || _`Confirm`}
            color="primary"
            onClick={() => {
              void settings.onConfirm?.(inputValues[key] || "");
              hideModal(key);
            }}
          />
        </Grid>
      }
    >
      {renderTexts(settings.title, settings.texts, settings.danger)}
      <div className="margin-top-xxlarge">
        <Select
          label={_`Select a dashboard`}
          block={true}
          multiple={false}
          onChange={(v) => setInputValue(key, (v as string[])[0])}
          selected={[inputValues[key] || settings.selectInitialValue || ""]}
          options={settings.options}
          footerAction={settings.selectFooterAction}
        />
      </div>
    </Popup>
  );

  const renderAlert = (key: string, settings: AlertModalSettings) => (
    <Popup
      key={key}
      onClose={() => {
        settings.onClose && settings.onClose();
        hideModal(key);
      }}
      footerComponent={
        <Grid gridTemplateColumns={"1fr"} gap={10}>
          {renderExtraButtons(key, settings.extraButtons || [])}
          <Button
            size="large"
            label={settings.closeButtonLabel || _`Close`}
            color="primary"
            onClick={() => {
              settings.onClose && settings.onClose();
              hideModal(key);
            }}
          />
        </Grid>
      }
    >
      {renderTexts(settings.title, settings.texts, settings.danger)}
    </Popup>
  );

  const renderCustomModal = (key: string, entry: CustomModalSettings) => {
    return entry.component(() => hideModal(key));
  };

  return (
    <ModalsContext.Provider
      value={{
        modals,
        loader,
        confirm,
        alert,
        input,
        select,
        showLoader,
        hideLoader,
        hideModal,
        clear,
        inputValues,
        setInputValue,
      }}
    >
      {children}
      {Object.entries(modals).map(([key, entry]) => {
        switch (entry.type) {
          case "input":
            return renderInput(key, entry as InputModalSettings);
          case "select":
            return renderSelect(key, entry as SelectModalSettings);
          case "confirm":
            return renderConfirm(key, entry as ConfirmModalSettings);
          case "custom":
            return renderCustomModal(key, entry as CustomModalSettings);
        }
        return renderAlert(key, entry as AlertModalSettings);
      })}
      {loader}
    </ModalsContext.Provider>
  );
}

export const useModals = () => {
  const context = useContext(ModalsContext);
  if (context === null) {
    throw Error("Modals context not provided");
  }
  return context;
};

let modalId = 0;
const getModalId = () => {
  modalId++;
  return `m${modalId}`;
};

const useProvideModals = () => {
  const [modals, setModals] = useState<{ [key: string]: ModalSetting }>({});
  const [inputValues, setInputValues] = useState<{
    [key: string]: string | number;
  }>({});
  const [loader, setLoader] = useState<React.ReactNode>(null);

  const clear = () => setModals({});

  const hideModal = (id: string) => {
    const newModals = { ...modals };
    delete newModals[id];
    setModals(newModals);
  };

  const setInputValue = (id: string, value: string | number) =>
    setInputValues((values) => {
      return { ...values, [id]: value };
    });

  const showLoader = () => {
    setLoader(
      <Popup hideCloseButton={true} onClose={() => {}}>
        <Space />
        <Flex justifyContent="center">
          <Loader data-cy="loader" />
        </Flex>
      </Popup>,
    );
  };

  const hideLoader = () => {
    setLoader(null);
  };

  const alert = (settings: AlertModalSettings) => {
    const id = getModalId();
    setModals((modals) => ({
      ...modals,
      [id]: { ...settings, type: "alert" },
    }));
  };

  const confirm = (settings: ConfirmModalSettings) => {
    const id = getModalId();
    setModals((modals) => ({
      ...modals,
      [id]: { ...settings, type: "confirm" },
    }));
  };

  const input = (settings: InputModalSettings) => {
    const id = getModalId();
    setInputValue(id, settings.inputInitialValue ?? "");
    setModals((modals) => ({
      ...modals,
      [id]: { ...settings, type: "input" },
    }));
  };

  const select = (settings: SelectModalSettings) => {
    const id = getModalId();
    setInputValue(id, settings.selectInitialValue ?? "");
    setModals((modals) => ({
      ...modals,
      [id]: { ...settings, type: "select" },
    }));
  };

  return {
    modals,
    loader,
    confirm,
    alert,
    input,
    select,
    showLoader,
    hideLoader,
    clear,
    hideModal,
    inputValues,
    setInputValue,
  };
};
