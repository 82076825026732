const ONE_MINUTE = 60000;

const getTokenExpirationDate = (token: string): number | null => {
  try {
    const payload = JSON.parse(atob(token.split(".")[1])) as {
      exp: number | null;
    };
    return payload.exp;
  } catch (e) {
    console.warn(e);
    return null;
  }
};

export const isTokenExpired = (token: string): boolean => {
  const exp = getTokenExpirationDate(token);
  return exp ? exp * 1000 < Date.now() + ONE_MINUTE : false;
};
