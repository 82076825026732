import React from "react";

import { Flex } from "../Flex";
import { theme } from "../theme";
import { Text } from "../Typography";

import { PopupBaseHeader } from "./PopupBaseHeader";

type PopupIconHeaderProps = {
  image: React.ReactNode;
  title: string;
  subtitle: string;
  className?: string;
  style?: React.CSSProperties;
};

export function PopupIconHeader({
  image,
  title,
  subtitle,
  className,
  style,
}: PopupIconHeaderProps) {
  return (
    <PopupBaseHeader
      style={{ padding: "16px", ...style }}
      className={className}
    >
      <Flex gap={16}>
        {image}
        <Flex flexDirection="column" justifyContent="center">
          <Text variant="body16Bold" color={theme.colors.text110}>
            {title}
          </Text>
          <Text variant="body12Regular" color={theme.colors.primary80}>
            {subtitle}
          </Text>
        </Flex>
      </Flex>
    </PopupBaseHeader>
  );
}
