const REGIONS = {
  NORTH_AMERICA: "north-america",
  EUROPE: "europe",
  ASIA_PACIFIC: "asia-pacific",
};

export const regionMappings: { [key: string]: string } = {
  US: REGIONS.NORTH_AMERICA,
  CA: REGIONS.NORTH_AMERICA,
  MX: REGIONS.NORTH_AMERICA,
  GB: REGIONS.EUROPE,
  DE: REGIONS.EUROPE,
  FR: REGIONS.EUROPE,
  IT: REGIONS.EUROPE,
  ES: REGIONS.EUROPE,
  NL: REGIONS.EUROPE,
  BE: REGIONS.EUROPE,
  SE: REGIONS.EUROPE,
  DK: REGIONS.EUROPE,
  FI: REGIONS.EUROPE,
  NO: REGIONS.EUROPE,
  PL: REGIONS.EUROPE,
  AT: REGIONS.EUROPE,
  CH: REGIONS.EUROPE,
  IE: REGIONS.EUROPE,
  PT: REGIONS.EUROPE,
  CZ: REGIONS.EUROPE,
  GR: REGIONS.EUROPE,
  HU: REGIONS.EUROPE,
  UA: REGIONS.EUROPE,
  RU: REGIONS.EUROPE,
  JP: REGIONS.ASIA_PACIFIC,
  CN: REGIONS.ASIA_PACIFIC,
  KR: REGIONS.ASIA_PACIFIC,
  IN: REGIONS.ASIA_PACIFIC,
  AU: REGIONS.ASIA_PACIFIC,
  NZ: REGIONS.ASIA_PACIFIC,
  ID: REGIONS.ASIA_PACIFIC,
  MY: REGIONS.ASIA_PACIFIC,
  PH: REGIONS.ASIA_PACIFIC,
  TH: REGIONS.ASIA_PACIFIC,
  VN: REGIONS.ASIA_PACIFIC,
  SG: REGIONS.ASIA_PACIFIC,
  PK: REGIONS.ASIA_PACIFIC,
  BD: REGIONS.ASIA_PACIFIC,
};
