import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useSmartFilter } from "../../hooks/useSmartFilter";
import {
  getIntegrationHandler,
  getIntegrationIcon,
} from "../../integrations/integration";
import { _ } from "../../languages/helper";
import { TRACKING_EVENTS, trackEvent } from "../../utils/trackingUtils";
import { Chip } from "../Chip";
import { Flex } from "../Flex";
import { Icon } from "../Icon";
import { Stack } from "../Stack";
import { Tag } from "../Tag";
import { theme } from "../theme";
import { Tooltip } from "../Tooltip";
import { StyledTooltipContent } from "../Tooltip/Tooltip.styled";
import { Text } from "../Typography";

import type { SyncingConnectors } from "./SyncingConnectors";

const StyledTooltip = styled(Tooltip)`
  ${StyledTooltipContent} {
    position: relative;
    border-radius: ${theme.borderRadius.r6px};
    padding: 12px;
    width: 364px;
    max-width: 100%;
    padding-bottom: 8px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      inset: 2px;
      backdrop-filter: blur(2px);
      border-radius: inherit;
      z-index: -1;
    }
  }
`;

const StyledSyncingStatusTagConnectorsList = styled(Flex)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;

  padding: 8px 12px;
  border-radius: ${theme.borderRadius.r4px};
  background-color: ${theme.colors.white4};
`;

type SyncingStatusTooltipProps = {
  children: React.ReactElement;
  syncingConnectors: SyncingConnectors;
  context: string;
};

const SyncingStatusTooltip = ({
  children,
  syncingConnectors,
  context,
}: SyncingStatusTooltipProps) => {
  const navigate = useNavigate();
  const { range } = useSmartFilter();
  return (
    <StyledTooltip
      mouseLeaveDelay={200}
      content={
        <Stack gap={8}>
          {syncingConnectors.current.length > 0 && (
            <StyledSyncingStatusTagConnectorsList>
              <Text
                variant="body12Medium"
                color={theme.colors.white100}
                style={{ width: "max-content" }}
              >
                {_`Syncing connectors`}:
              </Text>

              {syncingConnectors.current.map((connectorKey) => {
                return (
                  <Chip
                    key={connectorKey}
                    onBackground="black"
                    onClick={() => {
                      trackEvent(
                        TRACKING_EVENTS.SYNC_TOOLTIP_CONNECTOR_CLICKED,
                        {
                          context,
                          connectorKey,
                          syncingConnectors,
                          dateRange: range,
                          currentlySyncing: true,
                        },
                      );
                      navigate(`/connectors?openConnector=${connectorKey}`);
                    }}
                    size="xxsmall"
                    connectorIconName={getIntegrationIcon(connectorKey)}
                  >
                    {getIntegrationHandler(connectorKey).label || connectorKey}
                  </Chip>
                );
              })}
            </StyledSyncingStatusTagConnectorsList>
          )}

          {syncingConnectors.pending.length > 0 && (
            <StyledSyncingStatusTagConnectorsList>
              <Text
                variant="body12Medium"
                color={theme.colors.white100}
                style={{ width: "max-content" }}
              >
                {_`Connectors pending sync`}:
              </Text>

              {syncingConnectors.pending.map((connectorKey) => {
                return (
                  <Chip
                    key={connectorKey}
                    onBackground="black"
                    onClick={() => {
                      trackEvent(
                        TRACKING_EVENTS.SYNC_TOOLTIP_CONNECTOR_CLICKED,
                        {
                          context,
                          connectorKey,
                          syncingConnectors,
                          dateRange: range,
                          pendingSync: true,
                        },
                      );
                      navigate(
                        `/connectors?openConnector=${connectorKey}&viewStatuses=true`,
                      );
                    }}
                    size="xxsmall"
                    connectorIconName={getIntegrationIcon(connectorKey)}
                  >
                    {getIntegrationHandler(connectorKey).label || connectorKey}
                  </Chip>
                );
              })}
            </StyledSyncingStatusTagConnectorsList>
          )}
        </Stack>
      }
    >
      {children}
    </StyledTooltip>
  );
};

type SyncingStatusTagProps = {
  withText?: boolean;
  context: string;
  syncingConnectors: SyncingConnectors;
};

const getStatusText = (
  withText: boolean,
  syncingConnectors: SyncingConnectors,
) => {
  if (!withText) {
    return "";
  }

  if (syncingConnectors.current.length === 0) {
    return _`Pending sync` + " ";
  }

  return _`Syncing` + " ";
};

export const SyncingStatusTag = ({
  withText = false,
  context,
  syncingConnectors,
}: SyncingStatusTagProps) => {
  const [hoverDebounce, setHoverDebounce] = useState(false);

  return (
    <SyncingStatusTooltip
      context={context}
      syncingConnectors={syncingConnectors}
    >
      <Tag
        onMouseEnter={() => {
          if (!hoverDebounce) {
            setHoverDebounce(true);
            trackEvent(TRACKING_EVENTS.SYNC_STATUS_HOVERED, {
              context,
              syncingConnectors,
            });

            setTimeout(() => {
              setHoverDebounce(false);
            }, 1000);
          }
        }}
        color="primary"
      >
        {getStatusText(withText, syncingConnectors)}
        <Icon name="Loading" color={theme.colors.primary100} size={9} />
      </Tag>
    </SyncingStatusTooltip>
  );
};
