import classNames from "classnames";
import type { ReactElement } from "react";
import {
  createContext,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import { SerieFocusState } from "../BarLineCharts/components/Serie";
import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { theme } from "../theme";
import { Tooltip } from "../Tooltip";
import { Text } from "../Typography";

import {
  StyledChip,
  StyledLegend,
  StyledLegendChipTitleList,
  StyledLegendChipTitleListItem,
  StyledLegendSection,
  StyledLegendSectionList,
  StyledLegendSectionListItem,
} from "./Legend.styled";

const WrappingContext = createContext(false);

type LegendEntryProps = {
  serieName: string;
  backgroundColor: string;
  border?: string;
  focusState?: SerieFocusState;
  titleSegments: string[];
  onClick?: () => void;
  onChangeFocusState?: (serieName: string, focusState: SerieFocusState) => void;
  isDisabled?: boolean;
};

function LegendEntry({
  serieName,
  backgroundColor,
  border,
  titleSegments,
  onClick,
  focusState,
  onChangeFocusState,
  isDisabled = false,
}: LegendEntryProps) {
  const titleString = titleSegments.join(" | ");

  return (
    <StyledLegendSectionListItem
      onMouseLeave={() => {
        onChangeFocusState?.(serieName, "normal");
      }}
      onMouseEnter={() => {
        onChangeFocusState?.(serieName, "highlighted");
      }}
    >
      <Tooltip
        direction="top"
        content={
          <Text variant="body9Regular" color={theme.colors.white100}>
            {titleString}
          </Text>
        }
        delay={onChangeFocusState ? 0 : 500}
      >
        <StyledChip
          isDisabled={isDisabled}
          className="legend-chip"
          onBackground="light"
          selected={true}
          onClick={onClick}
          hovered={focusState === "highlighted"}
          size="small"
          leftSquare={{ backgroundColor, border }}
        >
          <StyledLegendChipTitleList>
            {titleSegments.map((titleSegment) => {
              return (
                <StyledLegendChipTitleListItem
                  key={titleSegment}
                  style={{
                    color: isDisabled ? theme.colors.text80 : "inherit",
                  }}
                >
                  {titleSegment}
                </StyledLegendChipTitleListItem>
              );
            })}
          </StyledLegendChipTitleList>
        </StyledChip>
      </Tooltip>
    </StyledLegendSectionListItem>
  );
}

type LegendSectionProps = {
  title?: string;
  children?: ReactElement<LegendEntryProps>[] | ReactElement<LegendEntryProps>;
};

function LegendSection({ title, children }: LegendSectionProps) {
  const isWrapping = useContext(WrappingContext);

  return (
    <StyledLegendSection isWrapping={isWrapping}>
      {title ? (
        <Text variant="body12Medium" color={theme.colors.text110}>
          {title}
        </Text>
      ) : null}

      <StyledLegendSectionList>{children}</StyledLegendSectionList>
    </StyledLegendSection>
  );
}

type LegendProps = InteractionEvents<HTMLDivElement> &
  ClassAndStyleProps & {
    children?:
      | ReactElement<LegendSectionProps>[]
      | ReactElement<LegendSectionProps>;
    withTopBorder?: boolean;
    withHorizontalPadding?: boolean;
  };

function Legend({
  className,
  style,
  children,
  withHorizontalPadding = false,
  withTopBorder = false,
  ...interactionEvents
}: LegendProps) {
  const parentRef = useRef<HTMLDivElement | null>(null);
  const [isWrapping, setIsWrapping] = useState(false);

  useLayoutEffect(() => {
    const parent = parentRef.current; // Cache the value so we can unobserve

    if (!parent) {
      return;
    }

    const resizeObserver = new ResizeObserver(([entry]) => {
      if (!entry?.target) {
        return;
      }

      const absoluteParentWidth = entry.target.scrollWidth;
      const combinedChipWidth = Array.from(
        entry.target?.querySelectorAll(".legend-chip"),
      ).reduce((sum, chip) => sum + chip.getBoundingClientRect().width, 0);

      const ARBITRARY_WIDTH_BUFFER_BASED_ON_EXPERIMENTATION = 120; // margin, padding etc.
      setIsWrapping(
        absoluteParentWidth - combinedChipWidth <
          ARBITRARY_WIDTH_BUFFER_BASED_ON_EXPERIMENTATION,
      );
    });

    resizeObserver.observe(parent);

    return () => resizeObserver.unobserve(parent);
  }, [parentRef]);

  return (
    <WrappingContext.Provider value={isWrapping}>
      <StyledLegend
        {...interactionEvents}
        style={style}
        className={classNames(className)}
        ref={parentRef}
        isWrapping={isWrapping}
        withTopBorder={withTopBorder}
        withHorizontalPadding={withHorizontalPadding}
      >
        {children}
      </StyledLegend>
    </WrappingContext.Provider>
  );
}

export default Object.assign(Legend, {
  Section: LegendSection,
  Entry: LegendEntry,
});
