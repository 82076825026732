import styled from "styled-components";

import { legacyTheme } from "../legacyTheme";

export const StyledTooltip = styled.div`
  display: inline-block;
`;

export const StyledTooltipWrapper = styled.div<{ overlayFixedBottom: boolean }>`
  position: fixed;
  z-index: ${legacyTheme.zIndexes.layer6};
  width: ${({ overlayFixedBottom }) =>
    overlayFixedBottom ? "default" : "400px"};
`;

export const StyledTooltipContent = styled.div<{
  overlayFixedBottom: boolean;
}>`
  display: inline-block;
  padding: 6px 10px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: ${legacyTheme.borderRadius.small};
  color: ${legacyTheme.colors.white};
  z-index: ${legacyTheme.zIndexes.layer6};
  max-width: ${({ overlayFixedBottom }) =>
    overlayFixedBottom ? "auto" : "400px"};

  font-family: ${legacyTheme.typography.default.fontFamily};
  font-style: ${legacyTheme.typography.default.fontStyle};
  font-weight: ${legacyTheme.typography.fontWeigth.w500};
  font-size: ${legacyTheme.typography.fontSizes.small};
  text-align: left;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    inset: 2px;
    backdrop-filter: blur(2px);
    border-radius: inherit;
    z-index: -1;
  }

  &.white {
    padding: 16px 20px;
    background: ${legacyTheme.colors.white};
    color: ${legacyTheme.colors.grey3};
    box-shadow: ${legacyTheme.shadows.regular};
  }
`;

export const StyledTooltipArrow = styled.div`
  position: fixed;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(0, 0, 0, 0.7);

  &.white {
    border-top: 4px solid ${legacyTheme.colors.white};
  }
`;

export const SyledFixedBottomShadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: ${legacyTheme.zIndexes.layer5};
`;
