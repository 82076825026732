import { useIntercom } from "react-use-intercom";

import useLocalStorage from "../../hooks/useLocalStorage";
import { Box, Button, Flex, Popup, theme, Text } from "../../icecube-ux";
import { _ } from "../../languages/helper";

export default function MigrateToStripePopup() {
  const { showNewMessages } = useIntercom();

  const [lastShownTime, setLastShownTime] = useLocalStorage(
    "migrate-to-stripe-last-shown-time",
    0,
  );

  const handleClick = () => {
    showNewMessages();
    setLastShownTime(Date.now());
  };

  if (lastShownTime > Date.now() - 1000 * 3600 * 24) {
    return null;
  }

  return (
    <Popup
      onClose={() => {}}
      hideCloseButton
      withPaddingTop={false}
      withPadding={false}
    >
      <Box width="550px" height="100%">
        <Flex flexDirection="column" style={{ height: "100%" }}>
          <img src="/img/desktop-app-modal.svg" alt="" />
          <Box padding="50px 34px">
            <Text
              variant="body28SemiBold"
              textAlign="center"
              color={theme.colors.text110}
            >
              {_`Important Billing Update`}
            </Text>
            <Text
              variant="body16Regular"
              textAlign="center"
              className="margin-top-xlarge"
              color={theme.colors.text80}
            >
              {_`We are transitioning our billing from Shopify to Stripe. To ensure you don’t lose access to your subscription, please reach out to our Support Team below to request your Stripe payment link.`}
            </Text>
          </Box>
          <Box padding="24px 34px" className="border-top" marginTop="auto">
            <Flex fullWidth justifyContent="center">
              <Button
                size="xxlarge"
                onClick={handleClick}
              >{_`Request your payment link`}</Button>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Popup>
  );
}
