import styled from "styled-components";

import { legacyTheme } from "../legacyTheme";
import { Text } from "../Typography";

export const StyledRadioSelect = styled.div`
  border-radius: ${legacyTheme.borderRadius.default};
  background-color: ${legacyTheme.colors.bg.inputs.light};
  padding: ${legacyTheme.padding.large};

  &:hover {
    background-color: ${legacyTheme.colors.bg.inputs.lightHover};
  }
`;

export const StyledRadioSelectOption = styled.div`
  & + & {
    margin-top: ${legacyTheme.margin.large};
  }
`;

export const StyledRadioSelectOptionInput = styled.input`
  display: none;
`;

export const StyledRadioSelectOptionLabel = styled.label`
  display: flex;
  align-items: center;
`;

export const StyledRadioSelectOptionLabelSection = styled.div`
  margin-left: ${legacyTheme.margin.large};
`;
export const StyledRadioSelectOptionLabelMain = styled(Text)``;
export const StyledRadioSelectOptionLabelHint = styled(Text)``;
