import React from "react";

import { _ } from "../../../../languages/helper";
import { Chip } from "../../../Chip";
import { ConnectorIconNames } from "../../../ConnectorIcon";
import { Flex } from "../../../Flex";
import { theme } from "../../../theme";
import { Text } from "../../../Typography";

import { StyledSelector } from "./Selector.styled";

interface SelectorProps {
  label: string;
  tabs?: Array<{ key: string; label: string; icon?: ConnectorIconNames }>;
  blocks: Array<{ key: string; element: React.ReactNode }>;
  filters: string[];
  fullWidth?: boolean;
  onFilterChange: (value: string) => void;
  onSelectAllFilters: () => void;
}

export default function Selector({
  label,
  tabs,
  blocks,
  filters,
  onFilterChange,
  onSelectAllFilters,
  fullWidth,
}: SelectorProps) {
  return (
    <StyledSelector fullWidth={fullWidth}>
      <Text
        variant="body12Regular"
        color={tabs ? theme.colors.text80 : theme.colors.text110}
      >
        {label}
      </Text>
      {tabs && (
        <Flex flexDirection="row" flexWrap="wrap" gap={4}>
          <Chip
            size="xsmall"
            onClick={onSelectAllFilters}
            selected={filters.length === 0}
          >
            {_`All`}
          </Chip>
          {tabs.map((tab) => (
            <Chip
              key={`table-${tab.key}`}
              connectorIconName={tab.icon}
              size="xsmall"
              selected={filters.includes(tab.key)}
              onClick={() => onFilterChange(tab.key)}
            >
              {tab.label}
            </Chip>
          ))}
        </Flex>
      )}
      {blocks
        .filter((block) => filters.length === 0 || filters.includes(block.key))
        .map((block, id) => (
          <React.Fragment key={`block-${block.key}-${id}`}>
            {block.element}
          </React.Fragment>
        ))}
    </StyledSelector>
  );
}
