import { useCallback, useState } from "react";

export const LOCAL_STORAGE_KEYS = {
  CUSTOM_METRIC_DIMENSIONS: "pa-custom-metric-dimensions",
  DASH_VIEWS_DIMENSIONS_PROVIDER_PREFIX: "pa-dash-views-dimension-provider",
  USER_TOKENS: "user-tokens",
  USER_ATTRIBUTES: "user-attributes",
  USER_TENANT: "user-tenant",
  USER_TEMP_CREDENTIALS: "user-temp-credentials",
};

export const removeMatchingLocalStorageKeys = (
  predicate: (key: string) => boolean,
) => {
  return Object.keys(localStorage)
    .filter(predicate)
    .forEach((key) => localStorage.removeItem(key));
};

export default function useLocalStorage<T>(
  key: string,
  initialValue: T,
  transformer: (data: T) => T = (p) => p,
) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? transformer(JSON.parse(item) as T) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: T | ((data: T) => T)) => {
      try {
        setStoredValue((previous) => {
          const valueToStore: T =
            value instanceof Function ? value(previous) : value;
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
          return valueToStore;
        });
      } catch (error) {
        console.error(error);
      }
    },
    [key],
  );
  return [storedValue, setValue] as [T, (value: T | ((data: T) => T)) => void];
}
