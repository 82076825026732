import { useState } from "react";

import { Flex } from "../../../../icecube-ux";
import { _ } from "../../../../languages/helper";

import AccordeonTitle from "./AccordeonTitle";
import Functions from "./Blocks/Functions";

enum DocBlocks {
  FUNCTIONS = 1,
  OPERATORS_FUNCTIONS = 2,
  LOGICAL_EXPR = 3,
  LOGICAL_OPERATOR = 4,
}

export default function Accordeon() {
  const [openedBlocks, setOpenedBlocks] = useState<DocBlocks | -1>(
    DocBlocks.FUNCTIONS,
  );

  const handleToggleBlock = (id: DocBlocks) => {
    setOpenedBlocks((current) => (current === id ? -1 : id));
  };

  return (
    <Flex flexDirection="column">
      <AccordeonTitle
        label={_`Functions`}
        opened={openedBlocks === DocBlocks.FUNCTIONS}
        onClick={() => handleToggleBlock(DocBlocks.FUNCTIONS)}
      >
        <Functions />
      </AccordeonTitle>
      <AccordeonTitle
        label={_`Text operators & functions`}
        opened={openedBlocks === DocBlocks.OPERATORS_FUNCTIONS}
        onClick={() => handleToggleBlock(DocBlocks.OPERATORS_FUNCTIONS)}
      />
      <AccordeonTitle
        label={_`Logical expressions`}
        opened={openedBlocks === DocBlocks.LOGICAL_EXPR}
        onClick={() => handleToggleBlock(DocBlocks.LOGICAL_EXPR)}
      />
      <AccordeonTitle
        label={_`Logical operators`}
        opened={openedBlocks === DocBlocks.LOGICAL_OPERATOR}
        onClick={() => handleToggleBlock(DocBlocks.LOGICAL_OPERATOR)}
      />
    </Flex>
  );
}
