import { useAddonPopup } from "../../hooks/useAddonPopup";
import { CapiPopup } from "../Plans/analyzeEnrichPlan/CapiPopup";
import { IntradayRefreshPopup } from "../Plans/analyzeEnrichPlan/IntradayRefreshPopup";
import { PolarPixelPopup } from "../Plans/analyzeEnrichPlan/PolarPixelPopup";

export const AddonPopupContainer = () => {
  const { addon, setAddon, disabledAnimation } = useAddonPopup();
  if (addon === undefined) {
    return null;
  }

  if (addon === "polar_pixel") {
    return (
      <PolarPixelPopup
        disableAnimation={disabledAnimation}
        onClickAddon={setAddon}
        onClose={() => setAddon(undefined)}
      />
    );
  } else if (addon === "capi") {
    return (
      <CapiPopup
        disableAnimation={disabledAnimation}
        onClickAddon={setAddon}
        onClose={() => setAddon(undefined)}
      />
    );
  } else if (addon === "hourly_refresh") {
    return (
      <IntradayRefreshPopup
        disableAnimation={disabledAnimation}
        onClickAddon={setAddon}
        onClose={() => setAddon(undefined)}
      />
    );
  }

  return null;
};
