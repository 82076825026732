import styled, { css } from "styled-components";

import { sizeStylesMap } from "../Button/sizeStylesMap";
import { ButtonSize } from "../Button/types";
import { theme } from "../theme";

export type SegmentedButtonSizeProp = {
  size?: ButtonSize;
};

export const StyledSegmentedButton = styled.button<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid ${theme.colors.white100};
  border-radius: ${theme.borderRadius.r3px};
  background: ${theme.colors.white100};

  ${(p) => {
    if (p.selected) {
      return css`
        background: ${theme.colors.primary10};
        border: 1px solid ${theme.colors.primary10};
        &,
        & * {
          color: ${theme.colors.primary100};
        }
      `;
    }

    return css`
      color: ${theme.colors.text80};
      & * {
        color: ${theme.colors.text80};
      }

      &:not(:disabled):hover,
      &:not(:disabled):hover * {
        color: ${theme.colors.text90};
      }

      &:not(:disabled) {
        cursor: pointer;
      }
    `;
  }}
`;

export const StyledSegmentedWrapper = styled.fieldset<SegmentedButtonSizeProp>`
  box-sizing: border-box;
  padding: 4px;
  margin: 0;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 0px 2px;

  border: 1px solid ${theme.colors.borderLight};
  border-radius: ${theme.borderRadius.r4px};

  ${({ size = "small" }) => {
    const {
      borderRadius,
      height,
      fontFamily,
      fontSize,
      fontStyle,
      fontWeight,
      lineHeight,
      gap,
    } = sizeStylesMap[size];

    return css`
      height: ${height};
      border-radius: ${borderRadius};
      ${StyledSegmentedButton} {
        height: ${parseInt(height) - 9 + "px"};
        gap: ${gap};
        font-family: ${fontFamily};
        font-size: ${fontSize};
        font-style: ${fontStyle};
        font-weight: ${fontWeight};
        line-height: ${lineHeight};
      }
    `;
  }}
`;
