import styled from "styled-components";

import { theme } from "../theme";

export const StyledEmptyStateContainer = styled.div`
  padding: 24px 0px;
  border-radius: ${theme.borderRadius.r4px};
  background: ${theme.colors.bgLight10};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.colors.borderLight};
`;

export const StyledEmptyStateIconContainer = styled.div`
  color: ${theme.colors.bgLight10};
  background: ${theme.colors.primary20};
  width: 130px;
  height: 130px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;
