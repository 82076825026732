import styled from "styled-components";

import { theme } from "../theme";

export const StyledSubscriptionPlanCard = styled.div<{
  current: boolean;
  selected: boolean;
}>`
  display: flex;
  flex-direction: column;
  border: solid 1px
    ${({ selected, current }) =>
      selected && !current
        ? theme.colors.primary100
        : theme.colors.borderLight};
  border-radius: 6px;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 640px;
  min-width: calc(33.33% - 16px);
  & + & {
    margin-left: 16px;
  }

  .subscription-plan-list-item {
    color: ${({ current }) =>
      current ? theme.colors.text80 : theme.colors.text90};
  }

  .subscription-tick {
    color: ${({ current }) =>
      current ? theme.colors.success100 : theme.colors.primary80};
  }

  .plan-price {
    color: ${({ current }) =>
      current ? theme.colors.text90 : theme.colors.text110};
  }
`;
