import { useCallback, useState } from "react";

export const useMutableRef = <T extends Element>(): [
  T | null,
  (node: T | null) => void,
] => {
  const [node, setNode] = useState<T | null>(null);

  const ref = useCallback(
    (e: T | null) => {
      setNode(e);
    },
    [setNode],
  );

  return [node, ref];
};
