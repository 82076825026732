import { axisLeft, axisRight, ScaleLinear, select } from "d3";
import { memo, useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";

import { useResizeObserver } from "../../hooks/useResizeObserver";
import { theme } from "../../theme";

import { usePosition } from "./PositionProvider";

interface XAxisProps {
  width: number;
  height: number;
  yScale: ScaleLinear<number, number>;
  tickCount: number;
  margin: [number, number, number, number];
  alignment: "left" | "right";
  legend?: string;
  fontSize?: number;
  highlightValue?: number;
  hidden?: boolean;
  onWidthChange: (width: number) => void;
  formatter?: (value: number, index: number) => string;
}

const YAxisComponent = styled.g<{ color: string }>`
  text {
    font-family: Poppins;
    font-weight: 300;
    color: ${({ color }) => color};
  }
  .domain {
    color: ${({ color }) => color};
  }
  .tick {
    color: ${({ color }) => color};
  }
`;
const YAxisLegend = styled.rect`
  fill: transparent;
`;
const YAxisLegendText = styled.text`
  fill: ${theme.colors.text80};
  font-family: Poppins;
  font-weight: 300;
`;

export default memo(function YAxis({
  width,
  height,
  yScale,
  margin,
  alignment,
  tickCount,
  hidden,
  legend,
  highlightValue,
  onWidthChange,
  fontSize = 12,
  formatter = (v) => `${v}`,
}: XAxisProps) {
  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    if (document.fonts && typeof document.fonts.ready) {
      void document.fonts.ready.then(function () {
        setFontLoaded(true);
      });
    } else {
      // IE 11
      setTimeout(setFontLoaded, 300);
    }
  }, [setFontLoaded]);

  const axisRef = useRef<SVGGElement>(null);
  const legendRef = useRef<SVGGElement>(null);
  const position = usePosition();
  const { width: axisWidth } = useResizeObserver(axisRef.current);

  const yAxisLegendWidth = 23;
  useEffect(() => {
    const legendWidth = legend ? 23 : 0;
    if (fontLoaded) {
      onWidthChange(axisWidth === 0 ? 0 : Math.round(axisWidth + legendWidth));
    }
  }, [axisWidth, onWidthChange, legend, yAxisLegendWidth, fontLoaded]);

  useLayoutEffect(() => {
    const yAxis = (alignment === "left" ? axisLeft : axisRight)(yScale)
      .ticks(tickCount)
      .tickSize(3)
      .tickSizeOuter(0)
      .tickFormat((v, i) => formatter(v as number, i));

    select(axisRef.current)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
      .call(yAxis as any)
      .selectAll("text")
      .attr(
        "color",
        position.index !== undefined
          ? theme.colors.text90
          : theme.colors.text80,
      );
    select(axisRef.current).selectAll("line").attr("stroke-linecap", "round");
    select(axisRef.current).selectAll("path").attr("stroke-linecap", "round");
  }, [
    alignment,
    position.index,
    tickCount,
    yScale,
    width,
    margin,
    height,
    formatter,
  ]);

  return (
    <g>
      <g ref={legendRef}>
        {legend && (
          <>
            <YAxisLegend
              width={yAxisLegendWidth}
              height={height}
              x={alignment === "left" ? 0 : width - yAxisLegendWidth}
            />
            <YAxisLegendText
              x={0}
              y={0}
              transform={`rotate(${
                alignment === "left" ? -90 : 90
              }) translate(${
                alignment === "left" ? -height / 2 : height / 2
              }, ${alignment === "left" ? 16 : -width + 16})`}
              fontSize={fontSize}
              textAnchor="middle"
            >
              {legend}
            </YAxisLegendText>
          </>
        )}
      </g>
      <YAxisComponent
        style={{ fontSize: fontSize ?? 11 }}
        transform={`translate(${
          alignment === "left" ? margin[3] : width - margin[1]
        }, 0)`}
        ref={axisRef}
        color={hidden ? "transparent" : theme.colors.text80}
      />

      {highlightValue && (
        <circle
          transform={`translate(${
            alignment === "left" ? margin[3] : width - margin[1]
          }, 0)`}
          cx="0"
          cy={yScale(highlightValue)}
          strokeWidth="1"
          stroke="white"
          r="4"
          fill={theme.colors.primary40}
        />
      )}
    </g>
  );
});
