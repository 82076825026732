import styled, { css } from "styled-components";

type ShowByMediaQueryProps = {
  mediaQuery: string;
};

export const StyledShowByMediaQuery = styled.div<ShowByMediaQueryProps>`
  ${({ mediaQuery }) => css`
    display: none;
    @media (${mediaQuery}) {
      display: block;
    }
  `}
`;
