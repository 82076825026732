import styled from "styled-components";

import { Button, theme } from "../../icecube-ux";
import { typographyStylesMap } from "../../icecube-ux/Typography/typographyStylesMap";

export const MobileOnboardingLandingStylesMainButton = styled(Button)`
  height: 43px;
  width: 246px;

  ${typographyStylesMap.body15SemiBold}

  box-shadow: ${theme.customColors.darkLandingPageBoxShadow};
`;

export const MobileOnboardingLandingStylesLogoutButton = styled(Button)`
  position: absolute;
  bottom: 0;
  background-color: ${theme.customColors.darkLandingPageBackground};

  padding: 11px 0;
  height: 40px;
  color: ${theme.colors.success40};

  ${typographyStylesMap.body12Medium}

  border-radius: 0;

  &:hover:enabled {
    background-color: ${theme.customColors
      .darkLandingPageLogoutBackgroundHover};
  }
`;
