import { ReactNode, createContext, useContext, useState } from "react";

import { provideDimensions } from "../lib/synthService";

import { useAuth } from "./auth/auth";

interface DimensionsProviderContextProps {
  get: (tables: string[], dimension: string, searchText?: string) => string[];
  load: (tables: string[], dimension: string, searchText?: string) => void;
  isLoading: (
    tables: string[],
    dimension: string,
    searchText?: string,
  ) => boolean;
}

const DimensionsProviderContext =
  createContext<DimensionsProviderContextProps | null>(null);

export function ProvideDimensions({ children }: { children: ReactNode }) {
  const provider = useProvideDimensions();
  return (
    <DimensionsProviderContext.Provider value={provider}>
      {children}
    </DimensionsProviderContext.Provider>
  );
}

export const useDimensionProvider = () => {
  const context = useContext(DimensionsProviderContext);
  if (context === null) {
    throw Error("Dimension provider context not provided");
  }
  return context;
};

function useProvideDimensions() {
  const auth = useAuth();

  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});
  const [dimensions, setDimensions] = useState<{ [key: string]: string[] }>({});

  const getKey = (tables: string[], dimension: string, searchText: string) =>
    `${tables.join("-")}.${dimension}#${searchText}`;

  const load = async (tables: string[], dimension: string, searchText = "") => {
    if (!dimension || (!tables?.length && !dimension.startsWith("custom_"))) {
      return;
    }
    const key = getKey(tables, dimension, searchText);
    if (dimensions[key]) {
      return;
    }

    setDimensions((d) => ({ ...d, [key]: [] }));
    setLoading((l) => ({ ...l, [key]: true }));
    const value = await provideDimensions(
      tables,
      dimension,
      searchText,
    )(await auth.getToken());

    setDimensions((d) => ({ ...d, [key]: value }));
    setLoading((l) => ({ ...l, [key]: false }));
  };

  const get = (tables: string[], dimension: string, searchText = "") => {
    return dimensions[getKey(tables, dimension, searchText)] || [];
  };

  const isLoading = (tables: string[], dimension: string, searchText = "") => {
    return loading[getKey(tables, dimension, searchText)] || false;
  };

  return {
    get,
    load,
    isLoading,
  };
}
