import { Box, theme, Text, Flex } from "../../../icecube-ux";

export default function CustomMetricHeader() {
  return (
    <Box
      padding="12px 16px"
      borderBottom={`1px solid ${theme.colors.borderLight}`}
    >
      <Flex alignItems={"center"} gap={8}>
        <Text variant="body18Regular">Custom Metrics</Text>
      </Flex>
    </Box>
  );
}
