import { ObjectValues } from "../common/types";
import { OutboundDemoData } from "../hooks/useOutboundDemoTypes";
import { deriveUserTimezone } from "../utils/dateUtils";
import { toSearchParams } from "../utils/searchParameterUtils";
import { SHOPIFY_UUID_STORAGE_KEY } from "../utils/shopifyUtils";

import { createClient } from "./apiClient";
import { getServiceHost } from "./services";

export interface LoginResult {
  id: string;
  accessToken: string;
  refreshToken: string;
  status?: string | null;
}

const client = createClient("auth-service");

export const SSOContexts = {
  SIGNUP: "signup",
  LOGIN: "login",
} as const;
export type SSOContext = ObjectValues<typeof SSOContexts>;

type BuildSSOUrlArgs = {
  context: SSOContext;
  outboundDemoData: OutboundDemoData | null;
  shopUUID?: string;
  isMobile?: boolean;
  location?: string;
};

const getAnonymousId = () => {
  const rawValue = localStorage.getItem("ajs_anonymous_id");
  return rawValue ? (JSON.parse(rawValue) as string) : undefined;
};

export const buildSSOUrl = ({
  context,
  outboundDemoData,
  isMobile,
  location,
}: BuildSSOUrlArgs) => {
  const anonymousId = getAnonymousId();
  const params = toSearchParams({
    context,
    demo_data: JSON.stringify(outboundDemoData),
    anonymous_id: anonymousId,
    timezone: deriveUserTimezone(),
    shop_uuid: localStorage.getItem(SHOPIFY_UUID_STORAGE_KEY) || "",
    is_mobile: String(isMobile),
    location: location || document.location.pathname,
  });
  return `${getServiceHost("auth-service")}/auth/sso/google/init${
    params ? `?${params}` : ""
  }`;
};

export const validateToken = async ({
  token,
}: {
  token: string;
}): Promise<boolean> => {
  const result = await client
    .new()
    .post("/auth/validate")
    .body({ token })
    .fetch();
  return !result.error;
};

export const register = async ({
  email,
  password,
  timezone,
  shopUUID,
  isMobile,
  testVersion,
  location,
  outboundDemoData,
}: {
  email: string;
  password: string;
  timezone: string;
  shopUUID?: string;
  isMobile: boolean;
  testVersion?: string;
  location?: string;
  outboundDemoData: OutboundDemoData | null;
}) => {
  const anonymousId = getAnonymousId();
  const result = await client
    .new()
    .post("/auth/register")
    .body({
      email,
      password,
      timezone,
      shop_uuid: shopUUID,
      is_mobile: isMobile,
      test_version: testVersion,
      location: location,
      demo_data: outboundDemoData,
      anonymous_id: anonymousId,
      path: document.location.pathname,
    })
    .fetch<{}, { reason: string }>();
  return result.error ? result.reason : true;
};

export const login = async ({
  email,
  password,
  outboundDemoData,
}: {
  email: string;
  password: string;
  outboundDemoData: OutboundDemoData | null;
}) => {
  const anonymousId = getAnonymousId();
  const result = await client
    .new()
    .post("/auth/login")
    .body({
      email,
      password,
      anonymous_id: anonymousId,
      demoData: outboundDemoData,
    })
    .fetch<{ data: LoginResult | string }, { message: string }>();
  if (result.error) {
    return result.message;
  }
  return result.data;
};

export const autoLogin = async ({
  token,
  timezone,
}: {
  token: string;
  timezone: string;
}) => {
  const result = await client
    .new()
    .post("/auth/autoLogin")
    .body({ token, timezone })
    .fetch<{ data: LoginResult | string }, { message: string }>();
  if (result.error) {
    return result.message;
  }
  return result.data;
};

export const resendValidationEmail = async ({
  email,
}: {
  email: string;
}): Promise<boolean> => {
  const result = await client
    .new()
    .post("/auth/email/resend")
    .body({ email })
    .fetch();
  return !result.error;
};

export const changePassword = async ({
  token,
  current,
  password,
}: {
  token: string;
  current: string;
  password: string;
}): Promise<boolean> => {
  const result = await client
    .new()
    .auth(token)
    .patch("/api/password")
    .body({ current, password })
    .fetch();
  return !result.error;
};

export const resetExpiredPassword = async ({
  token,
  password,
}: {
  token: string;
  password: string;
}): Promise<boolean> => {
  const result = await client
    .new()
    .auth(token)
    .patch("/api/password/resetExpired")
    .body({ password })
    .fetch();
  return !result.error;
};

export const requestPasswordReset = async ({
  email,
}: {
  email: string;
}): Promise<boolean> => {
  const result = await client
    .new()
    .patch("/auth/password/forgotten")
    .body({ email })
    .fetch();
  return !result.error;
};

export const resetPasswordWithCode = async ({
  email,
  code,
  password,
}: {
  email: string;
  code: string;
  password: string;
}): Promise<boolean> => {
  const result = await client
    .new()
    .patch("/auth/password/reset")
    .body({ email, code, password })
    .fetch();
  return !result.error;
};

export const validateEmail = async ({
  email,
  code,
}: {
  email: string;
  code: string;
}): Promise<boolean> => {
  const result = await client
    .new()
    .get("/api/email/confirm")
    .body({ email, code })
    .fetch();
  return !result.error;
};

export const resendEmailConfirmation = async (
  email: string,
): Promise<boolean> => {
  const result = await client
    .new()
    .post("/auth/email/resend")
    .body({ email })
    .fetch();
  return !result.error;
};

export const changeEmail = async ({
  token,
  email,
}: {
  token: string;
  email: string;
}) => {
  const result = await client
    .new()
    .auth(token)
    .patch("/api/email")
    .body({ email })
    .fetch();
  return !result.error;
};

export const refreshToken = async ({
  refreshToken,
  userId,
}: {
  refreshToken: string;
  userId: string;
}) => {
  const result = await client
    .new()
    .post("/auth/refresh")
    .body({ token: refreshToken, userId })
    .fetch<{ data: { accessToken: string; refreshToken: string } }>();
  if (result.error) {
    throw new Error(result.message);
  }
  return result.data;
};

export const checkEmail = async (
  token: string,
  email: string,
): Promise<boolean> => {
  const result = await client
    .new()
    .auth(token)
    .get(`/api/email/check?email=${encodeURIComponent(email)}`)
    .fetch();
  return result.error;
};
