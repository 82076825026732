import styled from "styled-components";

import { theme } from "../theme";

const variantColors = {
  success: {
    bar: theme.colors.success100,
    background: theme.colors.bgLight30,
  },
  warning: {
    bar: theme.colors.warning100,
    background: theme.colors.warning30,
  },
  default: {
    bar: theme.colors.grey80,
    background: theme.colors.grey60,
  },
};

const sizes = {
  large: {
    height: "8px",
  },
  small: {
    height: "6px",
  },
};

export const StyledProgressBarWrapper = styled.div<{
  width: string;
  withBorder?: boolean;
  variant: "success" | "warning" | "default";
  size: "large" | "small";
  borderRadius: "large" | "small";
}>`
  height: ${({ size }) => sizes[size].height};
  border-radius: ${({ borderRadius }) => (borderRadius === "large" ? 3 : 2)}px;
  border: ${({ withBorder }) =>
    withBorder ? `1px solid ${theme.colors.borderLight}` : "initial"};
  width: ${({ width }) => width};
  max-width: ${({ width }) => width};
  background-color: ${({ variant }) => variantColors[variant].background};
  flex-grow: 0;
  overflow: hidden;
`;

export const StyledProgressBar = styled.div<{
  variant: "success" | "warning" | "default";
  color?: string;
}>`
  height: 100%;
  width: ${({ "aria-valuenow": progress }) => progress || 0}%;
  background-color: ${({ variant, color }) =>
    color ?? variantColors[variant].bar};
  ${({ "aria-valuenow": progress }) =>
    progress && progress > 2 ? "border-radius:inherit;" : ""}
`;
