import { useEffect, useRef } from "react";
import "./auto-grow-input.css";

interface AutoGrowInputProps {
  text: string;
  onCursorMove: (left: boolean) => void;
  onTextChange: (value: string) => void;
  onComplete: () => void;
  onDelete: () => void;
  onBlur: () => void;
  onPressUp: () => void;
  onPressDown: () => void;
  onPressEnter: () => void;
}

export default function AutoGrowInput({
  text,
  onCursorMove,
  onTextChange,
  onBlur,
  onComplete,
  onDelete,
  onPressUp,
  onPressDown,
  onPressEnter,
}: AutoGrowInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const spanRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (inputRef.current && spanRef.current) {
      inputRef.current.style.width = `${Math.max(
        2,
        spanRef.current.getBoundingClientRect().width + 6,
      )}px`;
    }
  }, [text]);

  return (
    <div className="auto-grow-input">
      <input
        ref={inputRef}
        autoFocus={true}
        value={text}
        onBlur={onBlur}
        onChange={(e) => onTextChange(e.target.value)}
        onKeyDown={(event) => {
          if (event.key === "ArrowDown") {
            event.preventDefault();
            event.stopPropagation();
            onPressDown();
            return false;
          }
          if (event.key === "ArrowUp") {
            event.preventDefault();
            event.stopPropagation();
            onPressUp();
            return false;
          }
          if (event.key === "Enter" || event.key === "Tab") {
            if (onPressEnter && event.key === "Enter") {
              onPressEnter();
            } else {
              onComplete();
            }
          }
          if (event.key === "Backspace" && text.length === 0) {
            onDelete();
          }
          if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
            onCursorMove(event.key === "ArrowLeft");
          }
        }}
      />
      <span ref={spanRef}>{text}</span>
    </div>
  );
}
