import styled, { css } from "styled-components";

import { Grid } from "../Grid";
import { IconButton } from "../IconButton";
import { legacyTheme } from "../legacyTheme";
import { theme } from "../theme";

export const StyledPopupShadow = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${legacyTheme.zIndexes.layer5};
`;

export const StyledPopup = styled.div<{
  disableAnimation?: boolean;
  fullScreen?: boolean;
}>`
  pointer-events: all;
  ${({ disableAnimation }) =>
    disableAnimation
      ? ""
      : `animation: animation-appear-grow 0.2s ease-in-out;`}
  border-radius: 10px;
  box-shadow: ${legacyTheme.shadows.popup};
  background: ${legacyTheme.colors.white};
  max-height: calc(100% - 80px);
  max-width: calc(100% - 80px);
  width: ${({ fullScreen }) => (fullScreen ? "calc(100% - 80px)" : "auto")};
  height: ${({ fullScreen }) => (fullScreen ? "calc(100% - 80px)" : "auto")};
  min-width: 410px;
  overflow: auto;
  z-index: ${legacyTheme.zIndexes.layer6};

  @media (max-width: 480px) {
    min-width: 10px;
    max-width: calc(100% - 40px);
    max-height: calc(100% - 40px);
  }
`;

export const StyledPopupCloseButton = styled(IconButton).attrs({
  name: "Close",
  size: "xxl",
  color: "transparent",
})`
  cursor: pointer;
  justify-self: flex-end;
  align-self: start;
  z-index: ${theme.zIndexes.layer5};

  &:hover {
    color: ${theme.colors.text100};
  }
`;

export const StyledPopupContentGrid = styled(Grid).attrs({
  gridTemplateRows: "auto 1fr auto",
})<{ fullScreen?: boolean }>`
  max-width: 100%;
  overflow: hidden;
  height: ${({ fullScreen }) => (fullScreen ? "100%" : "auto")};
  max-height: ${({ fullScreen }) =>
    fullScreen ? "auto" : "calc(100vh - 100px)"};
`;

export const StyledPopupHeader = styled.div<{ withPaddingTop: boolean }>`
  box-sizing: border-box;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  padding: 18px 20px;
  padding-top: 16px;
  border-bottom: 1px solid #eae9ff;
  padding-bottom: 16px;

  & > div:first-child {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  ${({ withPaddingTop }) =>
    withPaddingTop
      ? css`
          padding-top: 16px;
        `
      : css`
          padding-top: 0px;
        `}
`;

export const StyledPopupBody = styled.div<{
  withPadding: boolean;
  withScroll: boolean;
}>`
  box-sizing: border-box;
  ${({ withPadding }) => (withPadding ? "padding: 18px 20px;" : "")}
  padding-top: 0;
  ${({ withScroll }) => (withScroll ? "overflow: auto;" : "overflow: hidden;")}
`;

export const StyledPopupFooter = styled.div`
  box-sizing: border-box;
  border-top: 1px solid ${legacyTheme.colors.borders.default};
  padding: 16px 24px;
`;

export const StyledPopupFooterGrid = styled(Grid).attrs({
  gridTemplateColumns: "1fr auto",
  gap: 12,
})`
  box-sizing: border-box;
  padding: 16px 24px;
`;

export const StyledPopupSection = styled.div`
  padding: 16px 24px;

  & + & {
    border-top: 1px solid ${theme.colors.borderLight};
  }
`;
