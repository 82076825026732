import React, { useContext } from "react";

import { StyledPopupCloseButton, StyledPopupHeader } from "./Popup.styled";
import { PopupContext } from "./PopupContext";

type BaseHeaderProps = {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};
export function PopupBaseHeader({
  children,
  className,
  style,
}: BaseHeaderProps) {
  const { onClose, withPaddingTop, withPadding, hideCloseButton } =
    useContext(PopupContext) ?? {};

  const CloseButton = !hideCloseButton && (
    <StyledPopupCloseButton onClick={onClose} data-cy="popup-close" />
  );

  if (!children) {
    return <>{CloseButton}</>;
  }

  return (
    <StyledPopupHeader
      style={style}
      className={className}
      withPaddingTop={Boolean(withPaddingTop && withPadding)}
    >
      <div>{children}</div>
      {CloseButton}
    </StyledPopupHeader>
  );
}
