import React, { createContext, useContext, useMemo } from "react";

import useViewSelector from "../elements/ViewsSelector/ViewsSelector.hook";
import {
  createCountableFormatter,
  createCurrencyFormatter,
  createIntegerFormatter,
  createNumberFormatter,
  createPercentageFormatter,
  createPrefixSuffixFormatter,
  createRatioFormatter,
  createTimedeltaFormatter,
} from "../utils/formatters";

import { useBootstrap } from "./bootstrap";
import { useSmartFilter } from "./useSmartFilter";

interface TenantFormatterSettings {
  useShortNumbers: boolean;
  useThousandsSeparator: boolean;
}

export interface TenantFormatters {
  formatterSettings: TenantFormatterSettings;
  integerFormatter: (value: number | string) => string;
  countableFormatter: (value: number | string) => string;
  percentageFormatter: (value: number) => string;
  timedeltaFormatter: (value: number) => string;
  ratioFormatter: (value: number | string) => string;
  numberFormatter: (value: number | string) => string;
  currencyFormatter: (amount: number) => string;
  prefixSuffixFormatter: (
    prefix: string,
    suffix: string,
    value: string | number,
    isInteger?: boolean,
  ) => string;
}

const FormattersContext = createContext<TenantFormatters | null>(null);

export function ProvideFormatters({ children }: { children: React.ReactNode }) {
  const provider = useProvideFormatters();
  return (
    <FormattersContext.Provider value={provider}>
      {children}
    </FormattersContext.Provider>
  );
}

export const useFormatters = () => {
  const context = useContext(FormattersContext);
  if (context === null) {
    throw Error("Formatters context not provided");
  }
  return context;
};

const useProvideFormatters = () => {
  const { tenant } = useBootstrap();
  const { selectedViewIds } = useSmartFilter();
  const { getCurrencyFromSelectedViews } = useViewSelector(
    () => {},
    selectedViewIds,
  );

  const currency = useMemo(
    () => getCurrencyFromSelectedViews(),
    [getCurrencyFromSelectedViews],
  );

  const formatters: TenantFormatters = useMemo(() => {
    const formatterSettings = {
      useShortNumbers: tenant?.settings?.use_short_number_format !== 0,
      useThousandsSeparator:
        tenant?.settings?.use_thousands_separator_number_format !== 0,
    };

    return {
      formatterSettings,
      currencyFormatter: createCurrencyFormatter(currency, formatterSettings),
      integerFormatter: createIntegerFormatter(formatterSettings),
      percentageFormatter: createPercentageFormatter(formatterSettings),
      timedeltaFormatter: createTimedeltaFormatter(formatterSettings),
      ratioFormatter: createRatioFormatter(formatterSettings),
      numberFormatter: createNumberFormatter(formatterSettings),
      countableFormatter: createCountableFormatter(formatterSettings),
      prefixSuffixFormatter: createPrefixSuffixFormatter(formatterSettings),
    };
  }, [
    currency,
    tenant?.settings?.use_short_number_format,
    tenant?.settings?.use_thousands_separator_number_format,
  ]);

  return formatters;
};
