import {
  CustomDimension,
  CustomDimensionRecord,
} from "../components/CustomBreakdowns/types";

import { createClient } from "./apiClient";

const client = createClient("views-service");

export const getCustomDimensions = async (token: string) => {
  const result = await client
    .new()
    .get("/api/dimensions")
    .auth(token)
    .fetch<{ data: CustomDimensionRecord[] }>();
  return result.error ? [] : result.data;
};

export const getCustomDimension = async (token: string, id: string) => {
  const result = await client
    .new()
    .get("/api/dimensions/" + id)
    .auth(token)
    .fetch<{ data: CustomDimensionRecord }>();
  return result.error ? null : result.data;
};

export const createCustomDimension = async (
  token: string,
  title: string,
  json: CustomDimension,
) => {
  const result = await client
    .new()
    .post("/api/dimensions")
    .body({ title, json })
    .auth(token)
    .fetch();
  return result.error ? null : result.data;
};

export const patchCustomDimension = async (
  token: string,
  title: string,
  json: CustomDimension,
  id: string,
) => {
  const result = await client
    .new()
    .patch("/api/dimensions/" + id)
    .body({ title, json })
    .auth(token)
    .fetch();
  return result.error ? null : result.data;
};

export const deleteCustomDimension = async (token: string, id: string) => {
  const result = await client
    .new()
    .delete("/api/dimensions/" + id)
    .body({})
    .auth(token)
    .fetch();
  return result.error ? null : result.data;
};

export const duplicateCustomDimension = async (token: string, id: string) => {
  const result = await client
    .new()
    .post(`/api/dimensions/${id}/duplicate`)
    .body({})
    .auth(token)
    .fetch();
  return result.error ? null : (result.data as string);
};
