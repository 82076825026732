import styled from "styled-components";

import { Flex } from "../Flex";
import { legacyTheme } from "../legacyTheme";

export const StyledSnack = styled.div`
  background: ${legacyTheme.colors.bg.darkBlue};
  padding: ${legacyTheme.padding.regular} ${legacyTheme.padding.xlarge};
  box-shadow: ${legacyTheme.shadows.darkBlue};
  border-radius: ${legacyTheme.borderRadius.default};
  animation: animation-appear-grow 0.5s ease-in-out;
`;

export const StyledSnackContainer = styled(Flex).attrs({
  alignItems: "center",
  flexDirection: "column",
  gap: 12,
})`
  z-index: ${legacyTheme.zIndexes.layer5};
  position: fixed;
  bottom: 24px;
  left: 0;
  right: 0;
  pointer-events: none;
`;
