import { theme } from "../theme";

export const typographyStylesMap: Record<
  string,
  {
    fontFamily: string;
    fontStyle: string;
    fontWeight: string;
    fontSize: string;
    lineHeight: string;
    textDecoration?: string;
  }
> = {
  body48SemiBold: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.semiBold,
    fontSize: theme.typography.fontSize.s48px,
    lineHeight: theme.typography.lineHeight.h72px,
  },
  body40SemiBold: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.semiBold,
    fontSize: theme.typography.fontSize.s40px,
    lineHeight: theme.typography.lineHeight.h60px,
  },
  body34SemiBold: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.semiBold,
    fontSize: theme.typography.fontSize.s34px,
    lineHeight: theme.typography.lineHeight.h51px,
  },
  body34Regular: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.regular,
    fontSize: theme.typography.fontSize.s34px,
    lineHeight: theme.typography.lineHeight.h51px,
  },
  body28SemiBold: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.semiBold,
    fontSize: theme.typography.fontSize.s28px,
    lineHeight: theme.typography.lineHeight.h42px,
  },
  body26Regular: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.regular,
    fontSize: theme.typography.fontSize.s26px,
    lineHeight: theme.typography.lineHeight.h39px,
  },
  body24Bold: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.bold,
    fontSize: theme.typography.fontSize.s24px,
    lineHeight: theme.typography.lineHeight.h36px,
  },
  body24SemiBold: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.semiBold,
    fontSize: theme.typography.fontSize.s24px,
    lineHeight: theme.typography.lineHeight.h36px,
  },
  body24Medium: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.medium,
    fontSize: theme.typography.fontSize.s24px,
    lineHeight: theme.typography.lineHeight.h36px,
  },
  body24Regular: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.regular,
    fontSize: theme.typography.fontSize.s24px,
    lineHeight: theme.typography.lineHeight.h36px,
  },
  body22SemiBold: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.semiBold,
    fontSize: theme.typography.fontSize.s22px,
    lineHeight: theme.typography.lineHeight.h33px,
  },
  body20Bold: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.bold,
    fontSize: theme.typography.fontSize.s20px,
    lineHeight: theme.typography.lineHeight.h30px,
  },
  body20Medium: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.medium,
    fontSize: theme.typography.fontSize.s20px,
    lineHeight: theme.typography.lineHeight.h30px,
  },
  body20SemiBold: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.semiBold,
    fontSize: theme.typography.fontSize.s20px,
    lineHeight: theme.typography.lineHeight.h30px,
  },
  body20Regular: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.regular,
    fontSize: theme.typography.fontSize.s20px,
    lineHeight: theme.typography.lineHeight.h30px,
  },
  body18Bold: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.bold,
    fontSize: theme.typography.fontSize.s18px,
    lineHeight: theme.typography.lineHeight.h27px,
  },
  body18SemiBold: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.semiBold,
    fontSize: theme.typography.fontSize.s18px,
    lineHeight: theme.typography.lineHeight.h27px,
  },
  body18Regular: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.regular,
    fontSize: theme.typography.fontSize.s18px,
    lineHeight: theme.typography.lineHeight.h27px,
  },
  body18Medium: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.medium,
    fontSize: theme.typography.fontSize.s18px,
    lineHeight: theme.typography.lineHeight.h27px,
  },
  body16Bold: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.bold,
    fontSize: theme.typography.fontSize.s16px,
    lineHeight: theme.typography.lineHeight.h24px,
  },
  body16SemiBold: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.semiBold,
    fontSize: theme.typography.fontSize.s16px,
    lineHeight: theme.typography.lineHeight.h24px,
  },
  body16Medium: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.medium,
    fontSize: theme.typography.fontSize.s16px,
    lineHeight: theme.typography.lineHeight.h24px,
  },
  body16Regular: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.regular,
    fontSize: theme.typography.fontSize.s16px,
    lineHeight: theme.typography.lineHeight.h24px,
  },
  body15SemiBold: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.semiBold,
    fontSize: theme.typography.fontSize.s15px,
    lineHeight: theme.typography.lineHeight.h22px,
  },
  body15Regular: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.regular,
    fontSize: theme.typography.fontSize.s15px,
    lineHeight: theme.typography.lineHeight.h22px,
  },
  body14SemiBold: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.semiBold,
    fontSize: theme.typography.fontSize.s14px,
    lineHeight: theme.typography.lineHeight.h21px,
  },
  body14Regular: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.regular,
    fontSize: theme.typography.fontSize.s14px,
    lineHeight: theme.typography.lineHeight.h21px,
  },
  body14Medium: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.medium,
    fontSize: theme.typography.fontSize.s14px,
    lineHeight: theme.typography.lineHeight.h21px,
  },
  body13Medium: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.medium,
    fontSize: theme.typography.fontSize.s13px,
    lineHeight: theme.typography.lineHeight.h19px,
  },
  body13SemiBold: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.semiBold,
    fontSize: theme.typography.fontSize.s13px,
    lineHeight: theme.typography.lineHeight.h19px,
  },
  body12Bold: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.bold,
    fontSize: theme.typography.fontSize.s12px,
    lineHeight: theme.typography.lineHeight.h18px,
  },
  body12SemiBold: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.semiBold,
    fontSize: theme.typography.fontSize.s12px,
    lineHeight: theme.typography.lineHeight.h18px,
  },
  body13Regular: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.regular,
    fontSize: theme.typography.fontSize.s13px,
    lineHeight: theme.typography.lineHeight.h19px,
  },
  body13Light: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.light,
    fontSize: theme.typography.fontSize.s13px,
    lineHeight: theme.typography.lineHeight.h19px,
  },
  body12Medium: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.medium,
    fontSize: theme.typography.fontSize.s12px,
    lineHeight: theme.typography.lineHeight.h18px,
  },
  body12Regular: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.regular,
    fontSize: theme.typography.fontSize.s12px,
    lineHeight: theme.typography.lineHeight.h18px,
  },
  body11SemiBold: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.semiBold,
    fontSize: theme.typography.fontSize.s11px,
    lineHeight: theme.typography.lineHeight.h16px,
  },
  body11Medium: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.medium,
    fontSize: theme.typography.fontSize.s11px,
    lineHeight: theme.typography.lineHeight.h16px,
  },
  body11Regular: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.regular,
    fontSize: theme.typography.fontSize.s11px,
    lineHeight: theme.typography.lineHeight.h16px,
  },
  body10Medium: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.medium,
    fontSize: theme.typography.fontSize.s10px,
    lineHeight: theme.typography.lineHeight.h15px,
  },
  body10Regular: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.regular,
    fontSize: theme.typography.fontSize.s10px,
    lineHeight: theme.typography.lineHeight.h15px,
  },
  body9SemiBold: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.semiBold,
    fontSize: theme.typography.fontSize.s9px,
    lineHeight: theme.typography.lineHeight.h14px,
  },
  body9Medium: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.medium,
    fontSize: theme.typography.fontSize.s9px,
    lineHeight: theme.typography.lineHeight.h14px,
  },
  body9Regular: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.regular,
    fontSize: theme.typography.fontSize.s9px,
    lineHeight: theme.typography.lineHeight.h14px,
  },
  body9Link: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.semiBold,
    fontSize: theme.typography.fontSize.s9px,
    lineHeight: theme.typography.lineHeight.h14px,
    textDecoration: "underline",
  },
  body8Regular: {
    fontFamily: theme.typography.fontFamily.poppins,
    fontStyle: theme.typography.fontStyle.normal,
    fontWeight: theme.typography.fontWeight.regular,
    fontSize: theme.typography.fontSize.s8px,
    lineHeight: theme.typography.lineHeight.h14px,
  },
};
