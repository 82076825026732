import { _, languageState } from "../languages/helper";

export const commaList = (
  words: string[],
  { maxLength }: { maxLength?: number } = {},
) => {
  if (words.length <= 1) {
    return words.toString();
  }

  let lastWord = words.at(-1);
  let allWordsExceptLast = words.slice(0, -1);

  if (maxLength && maxLength < words.length) {
    lastWord = `${words.length - maxLength} ${_`other`}`;
    allWordsExceptLast = words.slice(0, maxLength);
  }

  return `${allWordsExceptLast.join(", ")} ${_`and`} ${lastWord}`;
};

export const pluralize = (
  count: number,
  singularWord: string,
  pluralWord = languageState.current === "en"
    ? `${singularWord}s`
    : _(`${singularWord}|||plural`),
) => (count === 1 ? _(singularWord) : pluralWord);
