import classNames from "classnames";
import React from "react";

import { _ } from "../../languages/helper";
import { Flex } from "../Flex";
import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { Tag } from "../Tag";
import { theme } from "../theme";
import { Text } from "../Typography";

import {
  StyledSubscriptionPlanHeader,
  StyledSubscriptionPlanHeaderSection,
} from "./SubscriptionPlanHeader.styled";

export type SubscriptionPlanHeaderProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    planName: string;
    planInfo?: React.ReactNode;
    recommended?: boolean;
    current?: boolean;
    orders?: number;
    price?: number;
    priceInfo?: React.ReactNode;
    label?: React.ReactNode;
    onCustomPlan?: boolean;
    plus?: boolean;
  };

export default function SubscriptionPlanHeader({
  planName,
  planInfo,
  recommended,
  current,
  price,
  orders,
  priceInfo,
  onCustomPlan,
  plus,
  label,
  className,
  style,
  ...interactionEvents
}: SubscriptionPlanHeaderProps) {
  return (
    <StyledSubscriptionPlanHeader
      {...interactionEvents}
      className={classNames(className)}
      style={style}
    >
      <StyledSubscriptionPlanHeaderSection>
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Text
            variant="body16Medium"
            color={current ? theme.colors.text90 : theme.colors.text110}
          >
            {planName}
            {label}
          </Text>
          {recommended && <Tag color="primary">Recommended</Tag>}
          {current && <Tag color="default">Current Plan</Tag>}
        </Flex>
      </StyledSubscriptionPlanHeaderSection>
      <StyledSubscriptionPlanHeaderSection>
        {price && (
          <Text>
            {orders === 0 && (
              <Text variant="body20Medium" className="plan-price" inline>
                {_`From`}{" "}
              </Text>
            )}
            <Text variant="body20Medium" className="plan-price" inline>
              {plus ? "+" : ""}${price}
            </Text>
            <Text variant="body12Medium" className="plan-price" inline>
              /{_`mo`}
            </Text>
          </Text>
        )}
        {!price && (
          <Text variant="body20Medium" color={theme.colors.text110} inline>
            {`Request a quote`}
          </Text>
        )}
        {(planInfo || priceInfo) && (
          <Text variant="body11Regular" color={theme.colors.primary80}>
            {planInfo}
            {priceInfo}
          </Text>
        )}
      </StyledSubscriptionPlanHeaderSection>
    </StyledSubscriptionPlanHeader>
  );
}
