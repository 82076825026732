import type { SupportedPermissions } from "./types";

export const DEFAULT_PERMISSIONS: SupportedPermissions = {
  alert: {
    edit: { granted: true },
  },
  ask: {
    input: { granted: true },
  },
  connector: {
    edit: { granted: false },
  },
  data: {
    explore: { granted: true },
  },
  report: {
    open: { granted: true },
    edit: { granted: true },
  },
  key_indicator: {
    open: { granted: true },
    edit: { granted: true },
  },
  dashboard: {
    edit: { granted: true },
  },
  metric: {
    edit: { granted: true },
  },
  dimension: {
    edit: { granted: true },
  },
} as const;

export const INBOUND_DEMO_PERMISSIONS: SupportedPermissions = {
  connector: {
    edit: { granted: true },
  },
  report: {
    open: { granted: true },
  },
  key_indicator: {
    open: { granted: true },
  },
};

export const OUTBOUND_DEMO_PERMISSIONS: SupportedPermissions = {
  report: {
    open: { granted: true },
  },
  key_indicator: {
    open: { granted: true },
  },
};
