import classNames from "classnames";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";

import Icon, { IconNames } from "../Icon/Icon";
import { IconButton } from "../IconButton";
import { Loader } from "../Loader";
import { ClassAndStyleProps, FocusEvents, InteractionEvents } from "../shared";
import { OnBackground } from "../shared/baseInputFieldColorMap";
import { TextFieldSize } from "../shared/baseInputFieldSizeStylesMap";
import InputWrapper from "../shared/InputWrapper";
import { theme } from "../theme";

import {
  StyledLeftComponent,
  StyledLoadingComponent,
  StyledRightComponent,
  StyledTextField,
  StyledTextFieldInput,
  StyledTextFieldInputIconWrapper,
  StyledTextFieldInputWrapper,
  StyledTextFieldInputColorSquare,
} from "./TextField.styled";

export type TextFieldProps = InteractionEvents<HTMLInputElement> &
  FocusEvents<HTMLInputElement> &
  ClassAndStyleProps & {
    min?: number;
    max?: number;
    step?: number;
    required?: boolean;
    disabled?: boolean;
    autoFocus?: boolean;
    block?: boolean;
    loading?: boolean;
    placeholder?: string;
    value?: string | number;
    onChange: (value: string | number) => void;
    size?: TextFieldSize;
    onBackground?: OnBackground;
    error?: string | React.ReactNode;
    helpText?: string;
    id?: string;
    label?: string;
    leftIcon?: IconNames;
    rightIcon?: IconNames;
    type?: "text" | "number" | "email" | "password";
    onClickOnRightIcon?: () => void;
    onClickOnLeftIcon?: () => void;
    leftText?: string;
    leftColor?: string;
    rightText?: string;
    name?: string;
    noBorder?: boolean;
    transparent?: boolean;
    autoComplete?: "off";
    dataAttributes?: { [key: string]: string };
  };

let idIndex = 0;
const generateIncrementingId = () => `textfield-id-${idIndex++}`;

export default forwardRef(
  (
    {
      className,
      style,
      value,
      onChange,
      placeholder,
      disabled,
      autoFocus,
      error,
      helpText,
      leftIcon,
      rightIcon: rightIconProp,
      label,
      min,
      max,
      step,
      noBorder,
      required,
      id: initialId,
      type = "text",
      size = "large",
      onBackground = "whiteBackground",
      block,
      loading,
      onClickOnRightIcon: onClickOnRightIconProp,
      onClickOnLeftIcon,
      leftText,
      leftColor,
      rightText,
      name,
      onBlur,
      onFocus,
      autoComplete,
      transparent = false,
      dataAttributes,
      ...interactionEvents
    }: TextFieldProps,
    parentInputRef,
  ) => {
    const [id] = useState(initialId || generateIncrementingId);
    const [isFocused, setIsFocused] = useState(false);

    const [leftTxtPad, setLeftTxtPad] = useState<number>(0);
    const [rightTxtPad, setRightTxtPad] = useState<number>(0);
    const localInputRef = useRef<HTMLInputElement>(null);
    const inputRef =
      (parentInputRef as React.MutableRefObject<HTMLInputElement>) ||
      localInputRef;

    const [inputType, setInputType] = useState<TextFieldProps["type"]>(type);

    let rightIcon: TextFieldProps["rightIcon"] = rightIconProp;
    let onClickOnRightIcon: TextFieldProps["onClickOnRightIcon"] =
      onClickOnRightIconProp;

    if (type === "password" && !rightIconProp && !onClickOnRightIconProp) {
      rightIcon = inputType === "password" ? "EyeClosed" : "EyeOpen";
      onClickOnRightIcon = () =>
        setInputType(inputType === "password" ? "text" : "password");
    }

    useEffect(() => {
      setInputType(type);
    }, [type]);

    const leftTextRef = useCallback(
      (node: HTMLDivElement) => {
        if (node !== null) {
          setLeftTxtPad(node.getBoundingClientRect().width + 20);
          return;
        }
        setLeftTxtPad(0);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [leftText],
    );
    const rightTextRef = useCallback(
      (node: HTMLDivElement) => {
        if (node !== null) {
          setRightTxtPad(node.getBoundingClientRect().width + 20);
          return;
        }
        setRightTxtPad(0);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [rightText],
    );

    return (
      <StyledTextField
        className={classNames(className)}
        {...{ style, size, disabled }}
      >
        <InputWrapper
          htmlFor={id}
          {...{ label, error, helpText, isFocused, disabled }}
        >
          <StyledTextFieldInputWrapper
            block={block}
            hasLeftElement={!!leftIcon || !!leftColor}
            hasRightIcon={!!rightIcon}
            size={size}
            withBackground={onBackground}
            transparent={transparent}
            error={error}
            noBorder={noBorder}
          >
            <StyledTextFieldInput
              autoComplete={autoComplete}
              ref={inputRef}
              name={name}
              min={min}
              max={max}
              step={step}
              required={required}
              type={inputType}
              value={type === "number" ? value ?? "" : value}
              onChange={(e) =>
                onChange(
                  type === "number"
                    ? e.target.value === ""
                      ? ""
                      : Number(e.target.value)
                    : e.target.value, // Handle empty number input AND exponentials in number input
                )
              }
              onFocus={(e) => {
                setIsFocused(true);
                onFocus && onFocus(e);
              }}
              onBlur={(e) => {
                setIsFocused(false);
                onBlur && onBlur(e);
              }}
              autoFocus={autoFocus}
              {...interactionEvents}
              {...dataAttributes}
              {...{
                placeholder,
                disabled,
                id,
              }}
              style={{
                paddingLeft: leftTxtPad !== 0 ? leftTxtPad : undefined,
                paddingRight: rightTxtPad !== 0 ? rightTxtPad : undefined,
              }}
            />
            {leftText !== undefined && (
              <StyledLeftComponent ref={leftTextRef}>
                {leftText}
              </StyledLeftComponent>
            )}
            {loading && (
              <StyledLoadingComponent size={size}>
                <Loader size={16} />
              </StyledLoadingComponent>
            )}
            {!loading && rightText !== undefined && (
              <StyledRightComponent ref={rightTextRef}>
                {rightText}
              </StyledRightComponent>
            )}
            {leftIcon && (
              <StyledTextFieldInputIconWrapper
                pointer={onClickOnLeftIcon !== undefined}
                onClick={onClickOnLeftIcon}
              >
                <Icon
                  name={leftIcon}
                  style={{ marginLeft: 4, color: theme.colors.text90 }}
                />
              </StyledTextFieldInputIconWrapper>
            )}
            {leftColor && (
              <StyledTextFieldInputIconWrapper>
                <StyledTextFieldInputColorSquare
                  style={{ backgroundColor: leftColor }}
                />
              </StyledTextFieldInputIconWrapper>
            )}
            {rightIcon && (
              <StyledTextFieldInputIconWrapper
                pointer={onClickOnRightIcon !== undefined}
                onClick={onClickOnRightIcon}
                right
              >
                {rightIcon === "CloseCircle" ? (
                  <Icon name="CloseCircle" />
                ) : (
                  <IconButton
                    name={rightIcon}
                    color={
                      onBackground === "whiteBackground" ? "dark" : "light"
                    }
                    style={{
                      color: theme.colors.text90,
                    }}
                    onClick={onClickOnRightIcon}
                  />
                )}
              </StyledTextFieldInputIconWrapper>
            )}
          </StyledTextFieldInputWrapper>
        </InputWrapper>
      </StyledTextField>
    );
  },
);
