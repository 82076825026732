import styled, { css } from "styled-components";

import { Box, Button, Flex, Icon, Tag, Text, theme } from "../../../icecube-ux";
import { IconButton } from "../../../icecube-ux/IconButton";
import { Tooltip } from "../../../icecube-ux/Tooltip";
import { StyledTooltipContent } from "../../../icecube-ux/Tooltip/Tooltip.styled";
import { _ } from "../../../languages/helper";

import { AddonIcon } from "./AddonIcon";

interface AddonTagProps {
  tagText?: string;
  iconSide?: "left" | "right";
  onClose?: () => void;
  onClickAddon?: () => void;
  addonName: "Capi" | "IntradayRefresh" | "PolarPixel" | "Pixel&Capi";
  tagStyle?: React.CSSProperties;
  fullWidth?: boolean;
  customActionElement?: React.ReactNode;
}

const StyledAddonTooltip = styled(Tooltip)<{ fullWidth?: boolean }>`
  ${StyledTooltipContent} {
    padding: 0;
    white-space: normal;
  }
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
`;

const StyledIconButton = styled(IconButton)`
  display: inline-flex;
  color: ${theme.colors.warning50};
  margin: 2px 0 0 2px;
  &:hover:enabled {
    background: none;
    color: ${theme.colors.warning80};
  }
`;

const StyledIconBackground = styled(Box)<{
  name: string;
  size: string;
  stacked?: string;
}>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${theme.borderRadius.r2px};
  margin-left: auto;

  ${({ stacked }) => {
    if (stacked === "right") {
      return css`
        position: absolute;
        right: 0;
        top: 0;
      `;
    } else if (stacked === "left") {
      return css`
        position: absolute;
        left: 0;
        bottom: 0;
      `;
    }
  }}

  ${({ name }) => {
    switch (name) {
      case "Capi": {
        return css`
          background: ${theme.customColors.capiBg};
        `;
      }
      case "IntradayRefresh": {
        return css`
          background: ${theme.customColors.menuConnectorsBg};
        `;
      }
      case "PolarPixel": {
        return css`
          background: ${theme.customColors.pixelBg};
        `;
      }
    }
  }}
`;

const StyledMultipleIconWrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: auto;
`;

const addonTexts = {
  "Pixel&Capi": {
    title: _`Polar Pixel & CAPI Enhancer`,
    paragraph: _`Accurate first party attribution to identify successful campaigns and eliminate duplicate conversions. Also boost your ads with Polar’s conversion API Enhancer`,
  },
  Capi: {
    title: _`Polar CAPI Enhancer`,
    paragraph: _`Boost your ads with Polar’s conversion API Enhancer`,
  },
  PolarPixel: {
    title: _`Polar Pixel`,
    paragraph: _`Accurate first party attribution to identify successful campaigns and eliminate duplicate conversions`,
  },
  IntradayRefresh: {
    title: _`Intraday refresh`,
    paragraph: _`Sync your data continuously throughout the day to streamline operations and make decisions in real-time`,
  },
};

export const AddonTag = ({
  tagText,
  iconSide = "left",
  onClose,
  addonName,
  onClickAddon,
  tagStyle,
  fullWidth = false,
  customActionElement,
}: AddonTagProps) => {
  const { title: tooltipTitle, paragraph: tooltipText } = addonTexts[addonName];
  return (
    <StyledAddonTooltip
      white
      fullWidth={fullWidth}
      mouseLeaveDelay={300}
      content={
        <Flex
          style={{ padding: "12px", maxWidth: "265px" }}
          flexDirection="column"
          gap={12}
        >
          <Flex alignItems="center">
            <Text inline color={theme.colors.text110} variant="body12Medium">
              {tooltipTitle}
            </Text>
            <Icon style={{ marginLeft: "3px" }} name="Sparkle"></Icon>
            {addonName !== "Pixel&Capi" ? (
              <StyledIconBackground name={addonName} size="24px">
                <AddonIcon name={addonName} size={12} />
              </StyledIconBackground>
            ) : (
              <StyledMultipleIconWrapper>
                <StyledIconBackground
                  name="PolarPixel"
                  stacked="right"
                  size="14px"
                >
                  <AddonIcon name="PolarPixel" size={8} />
                </StyledIconBackground>
                <StyledIconBackground name="Capi" stacked="left" size="14px">
                  <AddonIcon name="Capi" size={8} />
                </StyledIconBackground>
              </StyledMultipleIconWrapper>
            )}
          </Flex>
          <Text color={theme.colors.text80} variant="body12Regular">
            {tooltipText}
          </Text>
          <Button
            size="small"
            color="primary"
            onClick={onClickAddon}
          >{_`Learn more`}</Button>
        </Flex>
      }
    >
      {customActionElement ? (
        customActionElement
      ) : (
        <Tag
          color="warning"
          style={{
            ...tagStyle,
            border: `1px solid ${theme.colors.warning40}`,
            background: theme.colors.warning20,
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          {...(iconSide === "left" && { icon: "Sparkle" })}
        >
          {tagText}
          {iconSide === "right" && (
            <Icon name="Sparkle" size={10} style={{ marginLeft: "3px" }} />
          )}
          {onClose && (
            <StyledIconButton
              onClick={onClose}
              name="Close"
              size="tiny"
              color="transparent"
            />
          )}
        </Tag>
      )}
    </StyledAddonTooltip>
  );
};
