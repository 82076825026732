import { useState } from "react";

import { useAuth } from "../../../hooks/auth/auth";
import { useBanners } from "../../../hooks/banners";
import { useCallBooking } from "../../../hooks/useCallBooking";
import { useSignupFromOutboundPopup } from "../../../hooks/useSignupFromOutboundPopup";
import { Banner, Button } from "../../../icecube-ux";
import { _ } from "../../../languages/helper";
import BookACall from "../../../pages/setup/BookACallPopup/BookACallPopup";
import { TRACKING_EVENTS, trackEvent } from "../../../utils/trackingUtils";

export default function LiveDemoBanner() {
  const { isShown } = useBanners();
  const auth = useAuth();
  const { openSetupCallBooking, isLoading: isCallBookingModalLoading } =
    useCallBooking();

  const [showBookACallPopup, setShowBookACallPopup] = useState(false);

  const { openPopup: openSignup } = useSignupFromOutboundPopup();

  if (!isShown) {
    return null;
  }

  return (
    <>
      {showBookACallPopup && (
        <BookACall onClose={() => setShowBookACallPopup(false)} />
      )}
      <Banner
        variant="primary"
        subtitle={_`You are currently in the Polar Analytics demo`}
        title={_`This is a sample account, create a free account to explore ${[
          "data",
          auth.outboundDemoData?.personalization?.domain
            ? _`the data from` +
              ` ${auth.outboundDemoData?.personalization?.domain}`
            : _`your own data`,
        ]}`}
        actionsChildren={
          <>
            <Button
              color="secondary-on-blue-background"
              block={false}
              onClick={() => {
                trackEvent(TRACKING_EVENTS.BOOK_A_CALL_BUTTON_CLICKED, {
                  buttonContext: "outbound-demo-banner",
                });
                void openSetupCallBooking();
              }}
              className="mrx-12"
              ctaSize
              loading={isCallBookingModalLoading}
            >
              {_`Schedule a discovery call`}
            </Button>
            <Button
              onClick={() => {
                trackEvent(TRACKING_EVENTS.SIGNUP_CTA_CLICKED, {
                  buttonContext: "outbound-demo-banner",
                });
                openSignup();
              }}
              color="primary-on-blue-background"
              block={false}
              ctaSize
            >
              {_`Try for free with your data`}
            </Button>
          </>
        }
      />
    </>
  );
}
