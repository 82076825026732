import styled from "styled-components";

import { legacyTheme } from "../legacyTheme";

export const StyledChipRadio = styled.div``;

export const StyledChipRadioFieldset = styled.div`
  border: none;
`;

export const StyledChipRadioLabel = styled.legend`
  display: block;
  margin-bottom: ${legacyTheme.margin.small};
  font-size: ${legacyTheme.typography.fontSizes.large};
  font-family: ${legacyTheme.typography.default.fontFamily};
  font-weight: ${legacyTheme.typography.fontWeigth.w600};
  color: ${legacyTheme.colors.text.regular};
`;

export const StyledChipRadioChiplabel = styled.label`
  cursor: pointer;
  border: 1px solid ${legacyTheme.colors.borders.default};
  text-align: center;
  color: ${legacyTheme.colors.text.regular};
  font-size: ${legacyTheme.typography.fontSizes.large};
  font-family: ${legacyTheme.typography.default.fontFamily};
  font-weight: ${legacyTheme.typography.default.fontWeight};
  display: inline-block;
  padding: 8px 14px;
  margin-right: 8px;
  border-radius: ${legacyTheme.borderRadius.default};

  &:hover {
    border-color: ${legacyTheme.colors.borders.hover};
  }

  input:checked + & {
    background-color: ${legacyTheme.colors.bg.light};
    border-color: ${legacyTheme.colors.bg.light};
    font-weight: ${legacyTheme.typography.default.fontWeight};

    &:hover {
      background-color: ${legacyTheme.colors.bg.inputs.lightHover};
    }
  }
`;

export const StyledChipRadioChipInput = styled.input`
  display: none;
`;
