import styled from "styled-components";

import { theme } from "../../../../icecube-ux";

export const StyledOnboardingProgressBarWrapper = styled.div`
  height: 4px;
  background-color: ${theme.colors.primary10};
  flex-grow: 0;
  overflow: hidden;
  width: 100%;
`;

export const StyledOnboardingProgressBar = styled.div`
  height: 100%;
  width: ${({ "aria-valuenow": progress }) => (progress || 0) * 100}%;
  background-color: ${theme.colors.primary100};
`;
