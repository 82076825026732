import { useEffect, useRef } from "react";

/* eslint-disable react-hooks/exhaustive-deps */

const useDebouncedEffect = (
  callback: (...args: unknown[]) => (() => void) | void,
  delay: number,
  deps: unknown[] = [],
) => {
  const data = useRef<{ firstTime: boolean; clearFunc?: () => void }>({
    firstTime: false,
  });

  useEffect(() => {
    const { firstTime, clearFunc } = data.current;

    if (firstTime) {
      data.current.firstTime = false;
      callback();
      return;
    }

    const handler = firstTime
      ? null
      : setTimeout(() => {
          if (clearFunc && typeof clearFunc === "function") {
            clearFunc();
          }
          data.current.clearFunc = callback() ?? undefined;
        }, delay);

    return () => {
      handler && clearTimeout(handler);
      data.current.clearFunc?.();
    };
  }, [delay, ...deps]);
};

export default useDebouncedEffect;
