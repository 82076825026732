import classNames from "classnames";
import React from "react";

import { ClassAndStyleProps, InteractionEvents } from "../shared";

import {
  StyledChipRadio,
  StyledChipRadioChipInput,
  StyledChipRadioChiplabel,
  StyledChipRadioFieldset,
  StyledChipRadioLabel,
} from "./ChipRadio.styled";

export type ChipRadioProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    name: string;
    label: string;
    value: string;
    options: Array<{ label: string; value: string | number }>;
    onChange: (value: string) => void;
  };

export default function ChipRadio({
  name,
  label,
  value: selectedValue,
  options,
  onChange,
  className,
  style,
  ...interactionEvents
}: ChipRadioProps) {
  return (
    <StyledChipRadio
      {...interactionEvents}
      className={classNames(className)}
      style={style}
    >
      <StyledChipRadioFieldset>
        <StyledChipRadioLabel>{label}</StyledChipRadioLabel>
        {options.map(({ label, value }) => (
          <React.Fragment key={`chip-${value}`}>
            <StyledChipRadioChipInput
              id={`${name}-${value}`}
              type="radio"
              value={value}
              checked={value === selectedValue}
              onChange={(e) => onChange(e.target.value)}
            />
            <StyledChipRadioChiplabel htmlFor={`${name}-${value}`}>
              {label}
            </StyledChipRadioChiplabel>
          </React.Fragment>
        ))}
      </StyledChipRadioFieldset>
    </StyledChipRadio>
  );
}
