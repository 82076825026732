import React, { Component } from "react";

import ScrollSyncContext from "./ScrollSyncContext";

interface ScrollSyncProps {
  children?: React.ReactNode;
  onSync?(el: Element): void;
  proportional?: boolean | undefined;
  vertical?: boolean | undefined;
  horizontal?: boolean | undefined;
  enabled?: boolean | undefined;
}

export default class ScrollSync extends Component<ScrollSyncProps> {
  static defaultProps = {
    proportional: true,
    vertical: true,
    horizontal: true,
    enabled: true,
  };

  getContextValue() {
    return {
      registerPane: this.registerPane,
      unregisterPane: this.unregisterPane,
    };
  }

  panes: { [key: string]: HTMLElement[] } = {};

  registerPane = (node: HTMLElement, groups: string[]) => {
    groups.forEach((group) => {
      if (!this.panes[group]) {
        this.panes[group] = [];
      }

      if (!this.findPane(node, group)) {
        if (this.panes[group].length > 0) {
          this.syncScrollPosition(this.panes[group][0], node, group);
        }
        this.panes[group].push(node);
      }
    });
    this.addEvents(node, groups);
  };

  unregisterPane = (node: HTMLElement, groups: string[]) => {
    groups.forEach((group) => {
      if (this.findPane(node, group)) {
        this.removeEvents(node);
        this.panes[group].splice(this.panes[group].indexOf(node), 1);
      }
    });
  };

  addEvents = (node: HTMLElement, groups: string[]) => {
    node.onscroll = this.handlePaneScroll.bind(this, node, groups);
  };

  removeEvents = (node: HTMLElement) => {
    node.onscroll = null;
  };

  findPane = (node: HTMLElement, group: string) => {
    if (!this.panes[group]) {
      return false;
    }

    return this.panes[group].find((pane) => pane === node);
  };

  handlePaneScroll = (node: HTMLElement, groups: string[]) => {
    if (!this.props.enabled) {
      return;
    }

    window.requestAnimationFrame(() => {
      this.syncScrollPositions(node, groups);
    });
  };

  syncScrollPosition(
    scrolledPane: HTMLElement,
    pane: HTMLElement,
    group: string,
  ) {
    const {
      scrollTop,
      scrollHeight,
      clientHeight,
      scrollLeft,
      scrollWidth,
      clientWidth,
    } = scrolledPane;
    const scrollTopOffset = scrollHeight - clientHeight;
    const scrollLeftOffset = scrollWidth - clientWidth;

    const { proportional, vertical, horizontal } = this.props;

    /* Calculate the actual pane height */
    const paneHeight = pane.scrollHeight - clientHeight;
    const paneWidth = pane.scrollWidth - clientWidth;
    /* Adjust the scrollTop position of it accordingly */
    if (
      vertical &&
      scrollTopOffset > 0 &&
      (group || "").startsWith("vertical") &&
      this.panes[group].includes(scrolledPane) &&
      this.panes[group].includes(pane)
    ) {
      pane.scrollTop = proportional
        ? (paneHeight * scrollTop) / scrollTopOffset
        : scrollTop; // eslint-disable-line
    }
    if (
      horizontal &&
      scrollLeftOffset > 0 &&
      (group || "").startsWith("horizontal") &&
      this.panes[group].includes(scrolledPane) &&
      this.panes[group].includes(pane)
    ) {
      pane.scrollLeft = proportional
        ? (paneWidth * scrollLeft) / scrollLeftOffset
        : scrollLeft; // eslint-disable-line
    }
  }

  syncScrollPositions = (scrolledPane: HTMLElement, groups: string[]) => {
    Object.keys(this.panes).forEach((group) => {
      this.panes[group].forEach((pane) => {
        if (scrolledPane !== pane) {
          this.removeEvents(pane); //, group);
          this.syncScrollPosition(scrolledPane, pane, group);
          window.requestAnimationFrame(() => {
            this.addEvents(pane, groups);
          });
        }
      });
    });
    if (this.props.onSync) this.props.onSync(scrolledPane as Element);
  };

  render() {
    return (
      <ScrollSyncContext.Provider value={this.getContextValue()}>
        {React.Children.only(this.props.children)}
      </ScrollSyncContext.Provider>
    );
  }
}
