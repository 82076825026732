import classNames from "classnames";
import React from "react";

import useDropdown from "../Dropdown/Dropdown.hooks";
import { IconNames } from "../Icon/Icon";
import { IconButton } from "../IconButton";
import { IconButtonColor } from "../IconButton/types";
import { ClassAndStyleProps, InteractionEvents } from "../shared";

import {
  StyledMoreOptions,
  StyledMoreOptionsMenu,
  StyledMoreOptionsMenuItem,
} from "./MoreOptions.styled";

export interface OptionItem {
  icon?: IconNames;
  leftComponents?: React.ReactElement[];
  label: string;
  onClick: (dataKey?: string) => void;
  rightComponent?: React.ReactElement;
  disabled?: boolean;
}

export type MoreOptionsProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    dataKey?: string;
    options: OptionItem[];
    anchorComponent?: React.ReactNode;
    iconButtonColor?: IconButtonColor;
    size?: "tiny" | "small" | "large";
  };

export default function MoreOptions({
  dataKey,
  options,
  anchorComponent,
  size = "small",
  className,
  style,
  iconButtonColor,
  ...interactionEvents
}: MoreOptionsProps) {
  const { refCallback, listPosition, open, close, handleClick } = useDropdown({
    onClick: interactionEvents.onClick,
  });

  return (
    <StyledMoreOptions
      {...interactionEvents}
      aria-label="More Options"
      onClick={anchorComponent ? handleClick : undefined}
      ref={refCallback}
      className={classNames(className, { open })}
      style={style}
      size={size}
    >
      {!anchorComponent && (
        <IconButton
          name="More"
          size={size}
          color={iconButtonColor}
          onClick={handleClick}
        />
      )}
      {anchorComponent}
      {open && options.length > 0 && (
        <StyledMoreOptionsMenu style={{ ...listPosition, minWidth: undefined }}>
          {options.map((o, i) => (
            <StyledMoreOptionsMenuItem
              key={`${o.label}-${i}`}
              leftIcon={o.icon}
              rightComponents={
                o.rightComponent ? [o.rightComponent] : undefined
              }
              leftComponents={o.leftComponents}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                !o.disabled && o.onClick(dataKey);
                !o.disabled && close();
                return false;
              }}
              block
              disabled={o.disabled}
            >
              {o.label}
            </StyledMoreOptionsMenuItem>
          ))}
        </StyledMoreOptionsMenu>
      )}
    </StyledMoreOptions>
  );
}
