import * as EmailValidator from "email-validator";
import React, { useRef, useState } from "react";

import { _ } from "../../languages/helper";

export interface FormError {
  element: HTMLElement;
  message: string;
}

interface FormProps {
  className?: string;
  children: React.ReactNode;
  onSubmit?: () => void;
  onBlur?: (e: React.FormEvent<HTMLFormElement>) => void;
  onFocus?: (e: React.FormEvent<HTMLFormElement>) => void;
  onVerify?: (errors: FormError[]) => void;
  dataAttributes?: { [key: string]: string };
}

export default function Form({
  children,
  className,
  onSubmit,
  onBlur,
  onFocus,
  onVerify,
  dataAttributes,
}: FormProps) {
  const formElement = useRef<HTMLFormElement>(null);
  const [enabled, setEnabled] = useState<HTMLInputElement[]>([]);

  const handleFocus = (e: React.FormEvent<HTMLFormElement>) => {
    setEnabled([...enabled, e.target as HTMLInputElement]);
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (onSubmit) {
      e.preventDefault();
      e.stopPropagation();
      onSubmit();
    }
  };

  const handleChange = (e: React.FormEvent<HTMLFormElement>) => {
    if (!formElement.current) return;

    setEnabled([...enabled, e.target as HTMLInputElement]);

    const elements = formElement.current.elements;
    const newErrors: FormError[] = [];
    for (let i = 0; i < elements.length; i++) {
      const element: HTMLInputElement = elements.item(i) as HTMLInputElement;
      if (enabled.indexOf(element) < 0) {
        continue;
      }

      // Required
      if (element.required && element.value === "") {
        newErrors.push({ element, message: _`This field is required` });
      }

      // Number
      if (
        element.type === "number" &&
        isNaN(element.value as unknown as number)
      ) {
        newErrors.push({ element, message: _`This should be a number` });
      }

      // Email
      if (element.type === "email" && !EmailValidator.validate(element.value)) {
        newErrors.push({
          element,
          message: _`This should be a valid email address`,
        });
      }

      // minLength
      if (
        element.minLength !== undefined &&
        element.minLength > -1 &&
        element.value.length > 0 &&
        element.value.length < element.minLength
      ) {
        newErrors.push({
          element,
          message:
            _`The value is too short. The minimum length is:` +
            " " +
            element.minLength,
        });
      }

      // maxLength
      if (
        element.maxLength !== undefined &&
        element.maxLength > -1 &&
        element.value.length > 0 &&
        element.value.length > element.maxLength
      ) {
        newErrors.push({
          element,
          message:
            _`The value is too long. The maximum length is:` +
            " " +
            element.maxLength,
        });
      }

      // Classes checkers
      element.classList.forEach((c) => {
        if (c.startsWith("equalTo:")) {
          const refElement = document.getElementById(
            c.slice(8),
          ) as HTMLInputElement;
          if (refElement.value && element.value !== refElement.value) {
            newErrors.push({
              element,
              message: _`The two values do not match`,
            });
          }
        }
      });
    }

    if (onVerify) {
      onVerify(newErrors);
    }
  };

  return (
    <form
      ref={formElement}
      className={className}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onBlur={onBlur}
      onFocus={handleFocus}
      {...dataAttributes}
    >
      {children}
    </form>
  );
}
