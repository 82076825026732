import classNames from "classnames";

import { ClassAndStyleProps, InteractionEvents } from "../shared";

import {
  StyledMenuFoldNavButton,
  StyledMenuFoldNavButtonIcon,
} from "./MenuFoldNavButton.styled";

export type MenuFoldNavButtonProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & { isToggledOpen: boolean };

export default function MenuFoldNavButton({
  className,
  style,
  isToggledOpen,
  ...interactionEvents
}: MenuFoldNavButtonProps) {
  return (
    <StyledMenuFoldNavButton
      {...interactionEvents}
      className={classNames(className)}
      style={style}
    >
      <StyledMenuFoldNavButtonIcon
        name={isToggledOpen ? "MenuFold" : "MenuUnfold"}
      />
    </StyledMenuFoldNavButton>
  );
}
