import { Navigate } from "react-router-dom";

import { useAuth } from "../../hooks/auth/auth";

export const NoMatch = () => {
  const auth = useAuth();

  if (!auth.processing) {
    return <Navigate to="/" replace />;
  }

  return null;
};
