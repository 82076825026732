import classNames from "classnames";
import React, { useEffect } from "react";

import { legacyTheme } from "../legacyTheme";
import { ClassAndStyleProps, InteractionEvents } from "../shared";

import {
  StyledPopup,
  StyledPopupBody,
  StyledPopupContentGrid,
  StyledPopupFooter,
  StyledPopupFooterGrid,
  StyledPopupSection,
  StyledPopupShadow,
} from "./Popup.styled";
import { PopupBaseHeader } from "./PopupBaseHeader";
import { PopupContext } from "./PopupContext";
import { PopupIconHeader } from "./PopupIconHeader";

export type PopupProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    children: React.ReactNode;
    onClose: (e: React.MouseEvent) => void;
    hideCloseButton?: boolean;
    headerComponent?: React.ReactNode;
    footerComponent?: React.ReactNode;
    footerLeftComponents?: React.ReactNode[];
    footerRightComponents?: React.ReactNode[];
    withPaddingTop?: boolean;
    withPadding?: boolean;
    withScroll?: boolean;
    zIndex?: number;
    justRenderChildren?: boolean;
    disableClosingOnShadowClick?: boolean;
    disableAnimation?: boolean;
    fullScreen?: boolean;
  };

function Popup({
  headerComponent,
  footerComponent,
  footerLeftComponents = [],
  footerRightComponents = [],
  onClose,
  zIndex = legacyTheme.zIndexes.layer5,
  hideCloseButton = false,
  children,
  className,
  style,
  withPaddingTop = true,
  withPadding = true,
  withScroll = true,
  justRenderChildren = false,
  disableClosingOnShadowClick = false,
  disableAnimation = false,
  fullScreen = false,
  ...interactionEvents
}: PopupProps) {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    return false;
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <PopupContext.Provider
      value={{ onClose, hideCloseButton, withPaddingTop, withPadding }}
    >
      <StyledPopupShadow
        style={{ zIndex }}
        onScroll={() => false}
        onClick={(e) => !disableClosingOnShadowClick && onClose(e)}
      >
        <StyledPopup
          {...interactionEvents}
          className={classNames(className)}
          style={{ ...style, zIndex: zIndex + 1 }}
          onClick={handleClick}
          disableAnimation={disableAnimation}
          fullScreen={fullScreen}
        >
          {justRenderChildren && children}
          {!justRenderChildren && (
            <StyledPopupContentGrid fullScreen={fullScreen}>
              {headerComponent || <PopupBaseHeader />}
              <StyledPopupBody
                withScroll={withScroll}
                withPadding={withPadding}
              >
                {children}
              </StyledPopupBody>
              {footerComponent && (
                <StyledPopupFooter>{footerComponent}</StyledPopupFooter>
              )}
              {footerLeftComponents.length + footerRightComponents.length >
                0 && (
                <StyledPopupFooterGrid
                  className={classNames({
                    "border-top": !footerComponent,
                    "padding-top-none": footerComponent !== undefined,
                  })}
                >
                  <div>{footerLeftComponents}</div>
                  <div>{footerRightComponents}</div>
                </StyledPopupFooterGrid>
              )}
            </StyledPopupContentGrid>
          )}
        </StyledPopup>
      </StyledPopupShadow>
    </PopupContext.Provider>
  );
}

export default Object.assign(Popup, {
  BaseHeader: PopupBaseHeader,
  IconHeader: PopupIconHeader,
  Section: StyledPopupSection,
});
