import styled from "styled-components";

import { theme } from "../theme";

export const StyledPopupShadow = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${theme.zIndexes.layer5};
`;

export const StyledPopupClosingIcon = styled.div`
  position: fixed;
  top: 8px;
  right: 8px;
  z-index: ${theme.zIndexes.layer6};
  color: ${theme.colors.white100};
  cursor: pointer;
`;

export const StyledPopupContent = styled.div`
  position: fixed;
  top: 41px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  overflow: auto;
  background: ${theme.colors.white100};
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
`;
