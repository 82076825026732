import { ConnectorIconNames } from "../icecube-ux";
import { getIntegrationIcon } from "../integrations/integration";
import { RefreshStatusData } from "../lib/integrationsService";
import {
  getIconsFromTableDependencies,
  PIXEL_METRICS_TAG,
} from "../pages/custom/shared/utils";
import { IMetric, IMetricsList } from "../types/synthesizer";

export const parseMetricKey = (metricKey: string) => {
  const [tableKey, type, metricId] = metricKey.split(".") as [
    string,
    "computed" | "raw",
    string,
  ];
  return {
    tableKey,
    type: metricId && type ? type : type && !metricId ? "unknown" : undefined,
    metricId: metricId ?? type ?? undefined,
  };
};

export const getMetricDetails = (
  metricKey: string,
  metricList: IMetricsList,
): IMetric => {
  const { tableKey, type, metricId } = parseMetricKey(metricKey);
  const def = { label: "", key: "" };

  if (type === "unknown") {
    return (
      metricList.tables?.[tableKey]?.metrics["raw"][metricId] ||
      metricList.tables?.[tableKey]?.metrics["computed"][metricId] ||
      def
    );
  } else if (type === "raw" || type === "computed") {
    return metricList.tables?.[tableKey]?.metrics[type][metricId] || def;
  }

  return (
    metricList.metrics?.[tableKey] ||
    metricList.customMetrics?.[tableKey] ||
    def
  );
};

export const getMetricIcon = (
  metricKey: string,
  metricList: IMetricsList,
  statuses: RefreshStatusData[],
  tableKeyToConnectorKey: { [key: string]: string[] },
): ConnectorIconNames[] => {
  const { tableKey } = parseMetricKey(metricKey);
  const metricDetails = getMetricDetails(metricKey, metricList);
  if (metricDetails.tags?.includes(PIXEL_METRICS_TAG)) {
    return ["PolarPixel"];
  } else if (
    metricDetails.tableDependencies &&
    metricDetails.tableDependencies.length > 0
  ) {
    return getIconsFromTableDependencies(
      metricDetails.tableDependencies,
      statuses,
      tableKeyToConnectorKey,
    );
  }

  const icon = getIntegrationIcon(tableKeyToConnectorKey[tableKey]?.[0]);
  return icon ? [icon] : ["Polar"];
};
