import { formatTimedelta } from "../common/formatting";
import { IMetric } from "../types/synthesizer";

interface FormatterOptions {
  useShortNumbers: boolean;
  useThousandsSeparator: boolean;
  minimumSignificantDigits?: number;
}

const DEFAULT_FORMATTER_TENANT_SETTINGS = {
  useShortNumbers: true,
  useThousandsSeparator: true,
};

export const getMetricPrefix = (metric: IMetric, currency: string) => {
  if (metric.prefix) return metric.prefix;
  if (metric.currency) {
    const formatter = createCurrencyFormatter(currency);
    return formatter(0).replace(/\d/g, "").replace(/\./g, "").trim();
  }
  return "";
};

export const getMetricSuffix = (metric: IMetric) => {
  if (metric.suffix) return metric.suffix;
  if (metric.percentage) return "%";
  return "";
};

const createIntlFloatFormatter = ({
  useShortNumbers,
  useThousandsSeparator,
} = DEFAULT_FORMATTER_TENANT_SETTINGS) =>
  new Intl.NumberFormat(undefined, {
    notation: useShortNumbers ? "compact" : undefined,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    useGrouping: useThousandsSeparator,
  });

const createIntlIntegerFormatter = ({
  useThousandsSeparator,
  useShortNumbers,
} = DEFAULT_FORMATTER_TENANT_SETTINGS) =>
  new Intl.NumberFormat(undefined, {
    maximumFractionDigits: useShortNumbers ? 2 : 0,
    minimumFractionDigits: 0,
    useGrouping: useThousandsSeparator,
    notation: useShortNumbers ? "compact" : undefined,
  });

export const createRatioFormatter =
  (options?: FormatterOptions) => (value: number | string) =>
    `${createIntlFloatFormatter(options).format(parseFloat(`${value}`))}x`;

export const createPercentageFormatter =
  (options?: FormatterOptions) => (value: number) => {
    const parsed = parseFloat(`${value}`);
    if (isNaN(parsed)) {
      return "0.00%";
    }
    return `${createIntlFloatFormatter(options).format(100 * value)}%`;
  };

export const createTimedeltaFormatter =
  (options?: FormatterOptions) => (value: number) => {
    const parsed = parseFloat(`${value}`);
    if (isNaN(parsed)) {
      return "0s";
    }
    const formatter = createIntlIntegerFormatter(options);
    return formatTimedelta(value)
      .map(([value, unit]) => `${formatter.format(value)}${unit}`)
      .join(" ");
  };

export const createNumberFormatter =
  (options?: FormatterOptions) => (value: number | string) =>
    createIntlFloatFormatter(options).format(parseFloat(`${value}`));

export const createIntegerFormatter =
  (options?: FormatterOptions) => (value: number | string) =>
    createIntlIntegerFormatter(options).format(
      Math.round(parseFloat(`${value}`)),
    );

export const createCountableFormatter =
  (options?: FormatterOptions) => (value: number | string) =>
    (value as number) > 0 && (value as number) < 1
      ? "< 1"
      : createIntegerFormatter(options)(value);

export const createCurrencyFormatter = (
  currency: string,
  options: FormatterOptions = DEFAULT_FORMATTER_TENANT_SETTINGS,
) => {
  try {
    const currencyFormatter = new Intl.NumberFormat(undefined, {
      notation: options.useShortNumbers ? "compact" : undefined,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      minimumSignificantDigits: options.minimumSignificantDigits,
      style: "currency",
      currency,
      useGrouping: options.useThousandsSeparator,
    });
    return (value: number) => {
      if (Number.isNaN(value)) {
        return "";
      }
      return currencyFormatter.format(parseFloat(`${value}`));
    };
  } catch (_) {
    return createNumberFormatter(options);
  }
};

export const createPrefixSuffixFormatter =
  (options?: FormatterOptions) =>
  (
    prefix: string,
    suffix: string,
    value: string | number,
    isInteger = false,
  ) => {
    const valueFormatter = isInteger
      ? createIntegerFormatter(options)
      : createNumberFormatter(options);
    const formatted = valueFormatter(value);
    return `${prefix}${formatted}${suffix}`;
  };
