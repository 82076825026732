import { Button, Flex } from "../../../../../icecube-ux";
import { ConfigurationButtonSectionProps } from "../../../../../integrations/integration";
import { _ } from "../../../../../languages/helper";

export const CustomConnectorButtonSection = ({
  status,
  onConnect,
  onConfigure,
  disabled,
}: ConfigurationButtonSectionProps) => {
  return (
    <Flex gap={12} style={{ marginTop: "auto" }}>
      <Button
        size="small"
        color="ghost"
        onClick={status === "empty" ? onConnect : onConfigure}
        disabled={disabled}
        block
      >
        {status === "empty" ? _`Request connector` : _`Edit Connector`}
      </Button>
    </Flex>
  );
};
