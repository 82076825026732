import type { ObjectValues } from "../types";

export const PricingModels = {
  PACKAGE: "PACKAGE",
  USAGE_BASED: "USAGE_BASED",
  GMV_TIER_BASED: "GMV_TIER_BASED",
} as const;
type PricingModel = ObjectValues<typeof PricingModels>;
export const DEFAULT_PRICING_MODEL = PricingModels.GMV_TIER_BASED;

export interface UserSetting {
  name: string;
  tenantId?: string;
  storeName?: string;
  role: string;
}

export interface GetUserSettings {
  error: boolean;
  result: UserSetting;
}

export interface Connectors {
  [key: string]: { id?: string; status?: string; disabled?: boolean }[];
}

export interface OnboardingFormData {
  isCompleted: boolean;
  responses: {
    questionKey: string;
    selection: string[];
    skipped: boolean;
    isAutocompleted?: boolean;
  }[];
}

export interface TenantSettings {
  website: string;
  timezone: string;
  timezoneSetByDefault?: boolean;
  currency: string;
  revenue_computation_include_discounts: 0 | 1;
  revenue_computation_include_refunds: 0 | 1;
  revenue_computation_include_shipping: 0 | 1;
  revenue_computation_include_tax: 0 | 1;
  revenue_computation_include_tip: 0 | 1;
  revenue_computation_deduct_cost_of_products: 0 | 1;
  revenue_computation_include_cancelled: 0 | 1;
  revenue_computation_include_0_orders: 0 | 1;
  key_indicators: string[];
  revenue_computation_deduct_expenses: 0 | 1;
  weekstart: string;
  avatar: string;
  snowflake?: boolean;
  whiteLabeling?: boolean;
  ga_four_start_date?: { [key: string]: string };
  ga_end_date?: { [key: string]: string };
  use_short_number_format?: number;
  use_thousands_separator_number_format?: number;
  addons?: string[];
  totalAnnualRevenue?: string;
  pricing_model?: PricingModel;
  price_determining_gmv?: number;
  company?: string;
  enable_ask_polar_insights?: boolean;
  disableAskPolarEnhancedMode?: boolean;
  onboarding_form?: OnboardingFormData;
}

export interface GetTenantSettings {
  error: boolean;
  result: TenantSettings;
}

export interface TenantStates {
  dashboard?: number;
  dashboardReadyAt?: string;
  availablePlans?: number[];
  appActivated?: boolean;
  hasBookedCall?: boolean;
  hasBookedCAPICall?: boolean;
  requestedConnectors?: string[];
  insertedPixelKISection?: boolean;
  requireMeeting?: boolean;
  autoKeyIndicatorsRefreshQuota?: number;
  showUsageBasedPlusPlan?: boolean;
  showInventoryAlerts?: boolean;
  canEditFacebookCampaigns?: boolean;
}

export interface GetTenantStatesResponse {
  error: boolean;
  result: TenantStates | null;
}

export interface Tenant {
  id: string;
  ownerId?: string;
  companyName: string;
  createdAt: string;
  settings: TenantSettings;
  states: TenantStates;
}

export interface GetTenantResult {
  error: boolean;
  result: Tenant;
}

export interface GetTenantsResult {
  error: boolean;
  result: Array<{
    id: string;
    company_name: string;
    created_at: string;
    owner_id: string;
  }>;
}

export interface BaseResult {
  error: boolean;
}

export interface TenantUserRecord {
  user_id: string;
  email: string;
  created_at: string;
  user_role: string;
}

export interface CheckEmailResult {
  error: boolean;
  result?: { free: boolean; disposable: boolean; undeliverable: boolean };
}
