import { theme } from "../theme";
import { typographyStylesMap } from "../Typography/typographyStylesMap";

export type TextFieldSize = "large" | "medium" | "small" | "tiny";

export const baseInputFieldSizeStylesMap = {
  large: {
    ...typographyStylesMap.body12Regular,
    borderRadius: theme.borderRadius.r4px,
    padding: "6px 12px",
    height: "34px",
  },
  medium: {
    ...typographyStylesMap.body12Regular,
    borderRadius: theme.borderRadius.r4px,
    padding: "6px 12px",
    height: "32px",
  },
  small: {
    ...typographyStylesMap.body11Regular,
    borderRadius: theme.borderRadius.r4px,
    padding: "4px 8px",
    height: "28px",
  },
  tiny: {
    ...typographyStylesMap.body12Regular,
    borderRadius: theme.borderRadius.r4px,
    padding: "0",
    height: "18px",
  },
} as const;
