import styled from "styled-components";

import { useAuth } from "../../hooks/auth/auth";
import { useBootstrap } from "../../hooks/bootstrap";
import { useCopyToClipboard } from "../../hooks/useCopyToClipboard";
import { Box, Button, Text, theme } from "../../icecube-ux";
import { _ } from "../../languages/helper";
import { TRACKING_EVENTS, trackEvent } from "../../utils/trackingUtils";

interface ReferralsGeneratedCodeSectionProps {
  referralCode: string;
  referralMessage: string;
}

const StyledReferralMessage = styled.div`
  padding: 9px 12px;
  border: 1px solid ${theme.colors.borderLight};
  background: ${theme.colors.bgLight10};
  border-radius: ${theme.borderRadius.r4px};
`;

const StyledGeneratedCodeSection = styled.div`
  margin-top: 4px;
  border-radius: ${theme.borderRadius.r6px};
  padding: 16px;
  border: 1px solid ${theme.colors.borderLight};
  background: ${theme.colors.bgLight10};
  display: flex;
  flex-direction: column;
`;

export const ReferralsGeneratedCodeSection = ({
  referralCode,
  referralMessage,
}: ReferralsGeneratedCodeSectionProps) => {
  const { copyToClipboard, hasJustCopied } = useCopyToClipboard();
  const { tenant } = useBootstrap();
  const auth = useAuth();
  const urlText = `https://polaranalytics.com/utm_source=referral&utm_campaign=${referralCode}`;
  return (
    <Box marginTop="16px">
      <Text
        variant="body11Medium"
        color={theme.colors.text100}
      >{_`Ask a brand to mention this code to our team`}</Text>
      <StyledGeneratedCodeSection>
        <Text inline variant="body12Regular" color={theme.colors.text90}>
          {_`Your referral code`}
        </Text>
        <Text inline variant="body28SemiBold" color={theme.colors.text100}>
          {referralCode}
        </Text>
      </StyledGeneratedCodeSection>
      <Text
        style={{ margin: "16px 0 4px" }}
        variant="body11Medium"
        color={theme.colors.text100}
      >{_`Here’s a message you can send`}</Text>
      <StyledReferralMessage>
        <Text variant="body12Regular" color={theme.colors.text100}>
          {referralMessage}
        </Text>
        <Text ellipsis variant="body12Regular" color={theme.colors.text100}>
          {urlText}
        </Text>
        <Text />
      </StyledReferralMessage>
      <Button
        style={{
          marginTop: "16px",
        }}
        color={hasJustCopied ? "success" : "primary"}
        label={hasJustCopied ? _`Message copied` : _`Copy message to clipboard`}
        rightIcon={hasJustCopied ? "Check" : "Clipboard"}
        onClick={() => {
          trackEvent(TRACKING_EVENTS.REFERRALS_COPY_TO_CLIPBOARD_CLICKED, {
            userId: auth.user?.id,
            tenantId: tenant.id,
          });
          copyToClipboard([referralMessage, urlText].join("\n"));
        }}
      />
    </Box>
  );
};
