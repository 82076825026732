import styled from "styled-components";

import { StyledIcon } from "../Icon/Icon.styled";
import { legacyTheme } from "../legacyTheme";

export const StyledCheckbox = styled.span`
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 15px;
  height: 15px;
  border-radius: ${legacyTheme.borderRadius.tiny};
  border: 1px solid ${legacyTheme.colors.borders.default};
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  &:hover {
    border: 1px solid ${legacyTheme.colors.borders.hover};
  }

  &.checked {
    background: ${legacyTheme.colors.success1};
    border: none;
  }

  &.checked:hover {
    background: ${legacyTheme.colors.success1darken};
  }

  &.disabled {
    background: ${legacyTheme.colors.bg.inputs.disabled};
    border: 1px dashed ${legacyTheme.colors.borders.hover};
  }

  ${StyledIcon} {
    position: absolute;
    fill: ${legacyTheme.colors.white};
    color: ${legacyTheme.colors.white};
    top: 2px;
    left: 1px;
  }
`;
