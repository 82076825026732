import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";

import { Box } from "../Box";
import { Checkbox } from "../Checkbox";
import { Flex } from "../Flex";
import { IconNames } from "../Icon/Icon";
import { Label } from "../Label";
import { Loader } from "../Loader";
import { Radio } from "../Radio";
import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { TextField } from "../TextField";
import { theme } from "../theme";

import { StyledLabel, StyledList, StyleSubcategory } from "./List.styled";

export interface ListOptions {
  isSubtitle?: boolean;
  label: string | React.ReactElement;
  searchableLabel?: string | React.ReactElement;
  sublabel?: string | React.ReactElement;
  value: string | number;
  leftIcon?: IconNames;
  rightIcon?: IconNames;
  leftComponents?: React.ReactElement[];
  rightComponents?: React.ReactElement[];
  toggleRightComponentsOnHover?: boolean;
  isSeparator?: boolean;
  className?: string;
  isDisabled?: boolean;
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  dataCy?: string;
}

export type ListOnlyProps = {
  loading?: boolean;
  options: ListOptions[];
  selected: Array<string | number>;
  withCheckboxes?: boolean;
  withRadio?: boolean;
  multiple?: boolean;
  withSearch?: boolean;
  initialSearch?: string;
  passedSearch?: string;
  onSearchChange?: (value: string) => void;
  disabled?: boolean;
  canUnselectWhenDisabled?: boolean;
  highlighted?: string;
  preSelectionHint?: string | number;
  onClickOnElement?: (value: string | number) => void;
  onSelectionChange?: (values: Array<string | number>) => void;
  onFocusElementChange?: (value: string | number | null) => void;
  selectedOptionsOnTop?: boolean;
  showSelectAll?: boolean;
  pointerCursor?: boolean;
  searchNoAutoFocus?: boolean;
  optionHoverStyle?: React.CSSProperties;
};

export type ListProps = InteractionEvents<HTMLDivElement> &
  ClassAndStyleProps &
  ListOnlyProps;

export function List({
  loading,
  options,
  selected,
  withCheckboxes,
  withRadio,
  withSearch,
  initialSearch,
  passedSearch,
  onSearchChange,
  onClickOnElement,
  multiple,
  disabled,
  canUnselectWhenDisabled,
  preSelectionHint,
  highlighted,
  selectedOptionsOnTop,
  onSelectionChange,
  pointerCursor,
  showSelectAll,
  searchNoAutoFocus,
  onFocusElementChange,
  className,
  style,
  optionHoverStyle,
  ...interactionEvents
}: ListProps) {
  const [search, setSearch] = useState("");
  const [focusedElement, setFocusedElement] = useState<string | number | null>(
    null,
  );

  const handleSelectAll = () => {
    if (selected.length === options.length) {
      onSelectionChange && onSelectionChange([]);
    } else {
      onSelectionChange && onSelectionChange(options.map((o) => o.value));
    }
  };

  const filteredOptions = useMemo(() => {
    return search === ""
      ? options
      : options.filter(
          (o) =>
            o.isSubtitle ||
            String(o.searchableLabel || o.label)
              .toLowerCase()
              .includes(search.toLowerCase()),
        );
  }, [options, search]);

  useEffect(() => {
    if (initialSearch && search === "") {
      setSearch(initialSearch);
    }
  }, [initialSearch, search]);

  useEffect(() => {
    if (passedSearch !== undefined) {
      setSearch(passedSearch);
    }
  }, [passedSearch]);

  useEffect(() => {
    const keyEventListener = (e: KeyboardEvent) => {
      if (e.key === "ArrowUp") {
        const index = filteredOptions.findIndex(
          (o) => o.value === focusedElement,
        );
        const element =
          index === -1
            ? filteredOptions[0].value
            : index === 0
            ? filteredOptions[filteredOptions.length - 1].value
            : filteredOptions[index - 1].value;
        setFocusedElement(element);
        onFocusElementChange && onFocusElementChange(element);

        e.stopPropagation();
        e.preventDefault();
        return false;
      } else if (e.key === "ArrowDown") {
        const index = filteredOptions.findIndex(
          (o) => o.value === focusedElement,
        );
        const element =
          index === -1 || index === filteredOptions.length - 1
            ? filteredOptions[0].value
            : filteredOptions[index + 1].value;
        setFocusedElement(element);
        onFocusElementChange && onFocusElementChange(element);

        e.stopPropagation();
        e.preventDefault();
        return false;
      }
    };
    document.addEventListener("keydown", keyEventListener);
    return () => {
      document.removeEventListener("keydown", keyEventListener);
    };
  }, [filteredOptions, focusedElement, onFocusElementChange]);

  const renderOption = (
    option: ListOptions,
    index: number,
    isSelected: boolean,
  ) => (
    <StyledLabel
      key={`option-${option.value}-${index}`}
      block={true}
      autoHeight={option.sublabel !== undefined}
      highlighted={search !== "" ? search : highlighted}
      leftIcon={option.leftIcon}
      rightIcon={option.rightIcon}
      style={option.style}
      contentStyle={option.contentStyle}
      disabled={disabled || option.isDisabled}
      toggleRightComponentsOnHover={option.toggleRightComponentsOnHover}
      leftComponents={[
        ...(option.leftComponents || []),
        ...(withCheckboxes
          ? [
              <Checkbox
                disabled={!isSelected && (disabled || option.isDisabled)}
                checked={isSelected}
              />,
            ]
          : []),
        ...(withRadio
          ? [<Radio disabled={!isSelected && disabled} checked={isSelected} />]
          : []),
      ]}
      pointerCursor={pointerCursor}
      rightComponents={option.rightComponents}
      onMouseMove={() => {
        setFocusedElement(option.value);
        onFocusElementChange && onFocusElementChange(option.value);
      }}
      onClick={() => {
        if (
          onClickOnElement &&
          (!(disabled || option.isDisabled) ||
            ((disabled || option.isDisabled) &&
              canUnselectWhenDisabled &&
              isSelected))
        ) {
          onClickOnElement(option.value);
        }
      }}
      className={classNames("list-option", option.className, {
        "margin-top-small": option.isSeparator,
        "padding-top-regular": option.isSeparator,
        preselected: preSelectionHint === option.value,
        focused: focusedElement === option.value,
      })}
      hoverStyle={optionHoverStyle}
      data-cy={option.dataCy}
    >
      {option.label}
      {option.sublabel}
    </StyledLabel>
  );

  return (
    <StyledList
      {...interactionEvents}
      style={style}
      className={classNames(className)}
      onMouseOut={() => {
        setFocusedElement(null);
        onFocusElementChange && onFocusElementChange(null);
      }}
    >
      {withSearch && (
        <TextField
          value={search}
          placeholder="Search"
          leftIcon="Search"
          rightIcon={search !== "" ? "CloseCircle" : undefined}
          onClickOnRightIcon={() => {
            setSearch("");
            onSearchChange && onSearchChange("");
          }}
          onChange={(v) => {
            setSearch(v as string);
            onSearchChange && onSearchChange(v as string);
          }}
          className="margin-bottom-large"
          autoFocus={searchNoAutoFocus !== true}
          block={true}
        />
      )}
      {loading && (
        <Flex justifyContent="center">
          <Loader size={18} />
        </Flex>
      )}

      {!loading && showSelectAll && (
        <Label
          block={true}
          leftComponents={[
            <Checkbox checked={selected.length === options.length} />,
          ]}
          className={classNames("border-bottom")}
          onClick={handleSelectAll}
        >
          <StyleSubcategory inline>Select all</StyleSubcategory>
        </Label>
      )}

      {selectedOptionsOnTop &&
        options
          .filter((o) => selected.includes(o.value))
          .map((o, i) => renderOption(o, i, true))}
      {filteredOptions.map((option, i) => {
        if (option.isSubtitle) {
          return (
            <Label
              key={`option-${option.value}`}
              block={true}
              leftIcon={option.leftIcon}
              rightIcon={option.rightIcon}
              leftComponents={[...(option.leftComponents || [])]}
              rightComponents={option.rightComponents}
              className={classNames(
                {
                  "margin-top-regular": i > 0,
                },
                option.className,
              )}
            >
              <StyleSubcategory key={`option-${option.value}`} inline>
                {option.label}
              </StyleSubcategory>
            </Label>
          );
        }

        if (option.isSeparator) {
          return (
            <Box
              background={theme.colors.borderLight}
              height="1px"
              width="calc(100% + 24px)"
              margin="3px -12px"
            />
          );
        }

        const isSelected = selected.includes(option.value);
        return isSelected && selectedOptionsOnTop
          ? null
          : renderOption(option, i, isSelected);
      })}
    </StyledList>
  );
}
