import {
  Subscription,
  SUBSCRIPTION_TYPES,
} from "../../common/types/subscription-service";
import { DashboardsElementsAssociationType } from "../../hooks/dashboards";
import { SmartFilterContextProps } from "../../hooks/useSmartFilter";
import { KISection } from "../../lib/kiSectionsService";
import { Tenant } from "../../types/tenant";
import { RuleOperators } from "../../utils/filterUtils";

const FREE_PLAN_MAXIMUM_SECTIONS = 2;
export const FREE_PLAN_MAX_KEY_INDICATORS = 8;

export const getVisibleSections = (
  subscription: Subscription | null,
  sectionsNotInDashboard: KISection[],
  dashboards: DashboardsElementsAssociationType[],
) => {
  const isFreePlan = subscription?.plan === SUBSCRIPTION_TYPES.FREE_PLAN;
  let counter = 0;
  if (isFreePlan) {
    const visibleDashboards = [];
    const visibleSectionsNotInDashboard = [];
    for (const dashboard of dashboards) {
      if (counter < FREE_PLAN_MAXIMUM_SECTIONS) {
        const newDashboard = {
          ...dashboard,
          elements: dashboard.elements.slice(
            0,
            FREE_PLAN_MAX_KEY_INDICATORS - counter,
          ),
        };
        counter += newDashboard.elements.length;
        visibleDashboards.push(newDashboard);
      }
    }
    for (const section of sectionsNotInDashboard) {
      if (counter < FREE_PLAN_MAXIMUM_SECTIONS) {
        visibleSectionsNotInDashboard.push(section);
        counter++;
      }
    }
    return {
      visibleDashboards,
      visibleSectionsNotInDashboard,
    };
  }
  return {
    visibleDashboards: dashboards,
    visibleSectionsNotInDashboard: sectionsNotInDashboard,
  };
};

export const getVisibleMetrics = (
  subscription: Subscription | null,
  metrics: string[],
): string[] => {
  const isFreePlan = subscription?.plan === SUBSCRIPTION_TYPES.FREE_PLAN;
  return isFreePlan ? metrics.slice(0, FREE_PLAN_MAX_KEY_INDICATORS) : metrics;
};

export const getComposeRules = (tenant: Tenant) => {
  return {
    ...(tenant?.settings?.revenue_computation_include_cancelled === 1
      ? {}
      : {
          is_cancelled: [{ operator: RuleOperators.is, value: ["false"] }],
        }),
    is_gift_card: [{ operator: RuleOperators.is, value: ["false"] }],
  };
};

export const getExtraParams = (smartFilter: SmartFilterContextProps) => {
  return {
    ...(smartFilter.attributionModel === "first_click"
      ? {}
      : { attribution_model: smartFilter.attributionModel }),
  };
};

export const getKeyIndicatorPath = (dashboardId: string | number = "other") => {
  return `/keyIndicators/dashboard/${dashboardId}/list`;
};
