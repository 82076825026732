import React from "react";

import { Grid } from "../../../../icecube-ux";
import { _ } from "../../../../languages/helper";

import "./formula-operators.css";
import Operators from "./Operators";

interface FormulaOperatorsProps {
  onClick: (operator: string) => void;
}

export default function FormulaOperators({ onClick }: FormulaOperatorsProps) {
  const handleClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: string,
  ) => {
    e.stopPropagation();
    e.preventDefault();
    onClick(value);
  };
  return (
    <div className="operator-list">
      <Grid gridTemplateColumns="1fr 1fr 1fr 1fr 1fr 1fr" gap={8}>
        <div className="block" onMouseDown={(e) => handleClick(e, "(")}>
          <Operators.OpenParenthesis />
        </div>
        <div className="block" onMouseDown={(e) => handleClick(e, ")")}>
          <Operators.CloseParenthesis />
        </div>
        <div className="block" onMouseDown={(e) => handleClick(e, "+")}>
          <Operators.PlusSign />
        </div>
        <div className="block" onMouseDown={(e) => handleClick(e, "-")}>
          <Operators.MinusSign />
        </div>
        <div className="block" onMouseDown={(e) => handleClick(e, "x")}>
          <Operators.MultiplySign />
        </div>
        <div className="block" onMouseDown={(e) => handleClick(e, "/")}>
          <Operators.DivideSign />
        </div>
      </Grid>
      <span>
        {_`To add an operation symbol, type it directly or click on it`}
      </span>
    </div>
  );
}
