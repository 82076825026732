import { Flex, Space, Text, theme } from "../../../icecube-ux";
import { _ } from "../../../languages/helper";
import { CustomReportTemplateCard } from "../SetupPageForOutbound/SetupPageForOutboundVersionB/CustomReportTemplateCard";

export default function ExploreOnMyOwn() {
  return (
    <>
      <Flex flexDirection="column" gap={8} alignItems="center">
        <Text variant="body18Medium">{_`Continue to explore the app`}</Text>
        <Text
          variant="body12Regular"
          color={theme.colors.primary80}
        >{_`Uncover the full potential: Dive deeper into Polar analytics`}</Text>
      </Flex>
      <Space size={34} />
      <Flex gap={16}>
        <CustomReportTemplateCard
          image="/img/outbound-welcome/acquisition.png"
          title={_`Test the Acquisition Dashboard`}
          description={_`Understand customer behavior, assess channel effectiveness, and optimize your strategy.`}
          buttonText={_`Go to Acquisitions`}
          redirectTo="/acquisition"
        />
        <CustomReportTemplateCard
          image="/img/outbound-welcome/creative-studio.png"
          title={_`Try the Creative Studio`}
          description={_`Maximize Meta Ads impact — Seamlessly integrate, analyze top-performing ads, and boost conversions effortlessly.`}
          buttonText={_`See Creative Studio`}
          redirectTo="/creative-studio"
        />
      </Flex>
    </>
  );
}
