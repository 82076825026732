import classNames from "classnames";
import React from "react";
import styled from "styled-components";

import { Grid } from "../../Grid";
import PercentChip from "../../PercentChip";
import { theme } from "../../theme";
import { WireframeLoader } from "../../WireframeLoader";

import { ReportTableHeaderColumn } from "./types";

export interface ReportTableFooterColumn {
  value: string | number;
  progression?: number;
  hideValue?: boolean;
  loadingInBatch?: boolean;
  invertedEvolution?: boolean;
}
const StyledReportTableFooterCell = styled(Grid).attrs({
  alignItems: "center",
})`
  height: 26px;
  box-sizing: border-box;
  background: ${theme.colors.bgLight30};
  border-radius: ${theme.borderRadius.r2px};
  border: 1px solid ${theme.colors.borderLight};
  padding: 0 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;
  color: ${theme.colors.text100};
  text-align: right;

  &.secondary {
    color: #b0b0d6;
  }
`;

type ReportTableFooterColumnsProps = {
  columns: ReportTableFooterColumn[];
  headers: ReportTableHeaderColumn[][];
  rightMargin: number;
  loading?: boolean;
  loadingSecondary?: boolean;
};

const ReportTableFooterColumns = React.forwardRef<
  HTMLDivElement,
  ReportTableFooterColumnsProps
>(({ columns, headers, rightMargin, loading, loadingSecondary }, ref) => {
  const headerLine = headers.length > 0 ? headers[headers.length - 1] : [];

  return (
    <Grid
      ref={ref}
      gridTemplateColumns={`repeat(${columns.length}, minmax(144px, 1fr)) ${
        rightMargin > 0 ? `${rightMargin}px` : ""
      }`}
      gap={4}
    >
      {!loading &&
        columns.map((footerColumn, i) => {
          const formatter = headerLine?.[i]?.formatter as Function | undefined;
          return (headerLine?.[i].isSecondary && loadingSecondary) ||
            footerColumn.loadingInBatch ? (
            <WireframeLoader
              key={`column-${i}`}
              background={theme.colors.grey40}
              height={"26px"}
              width={"100%"}
            />
          ) : (
            <StyledReportTableFooterCell
              key={`column-${i}`}
              className={classNames({
                secondary: headerLine?.[i].isSecondary,
              })}
              gridTemplateColumns={
                footerColumn.progression !== undefined && footerColumn.hideValue
                  ? "1fr auto"
                  : "auto 1fr"
              }
            >
              {footerColumn.hideValue && <div />}
              {footerColumn.progression !== undefined ? (
                <PercentChip
                  evolution={`${footerColumn.progression}%`}
                  negative={footerColumn.progression < 0}
                  small
                  style={{ lineHeight: "18px" }}
                  invertedEvolution={footerColumn.invertedEvolution}
                />
              ) : (
                <div />
              )}
              {!footerColumn.hideValue && (
                <div
                  style={{
                    overflow: "hidden",
                    // eslint-disable-next-line
                    // @ts-ignore
                    textWrap: "nowrap",
                  }}
                >
                  {formatter !== undefined
                    ? formatter(
                        footerColumn.value,
                        undefined,
                        undefined,
                        footerColumn.value,
                      )
                    : footerColumn.value}
                </div>
              )}
            </StyledReportTableFooterCell>
          );
        })}
      {loading &&
        columns.map((_, i) => (
          <WireframeLoader
            key={`column-${i}`}
            background={theme.colors.grey40}
            height={"26px"}
            width={"100%"}
          />
        ))}
      {rightMargin > 0 && <div />}
    </Grid>
  );
});

export default ReportTableFooterColumns;
