import styled from "styled-components";

import { Grid } from "../Grid";
import { StyledIcon } from "../Icon/Icon.styled";
import { Label } from "../Label";
import { legacyTheme } from "../legacyTheme";

export const StyledSortableGrid = styled(Grid).attrs({ gap: 8 })``;

export const StyledSortableGridLabel = styled(Label).attrs({ block: true })`
  position: relative;
  background: ${legacyTheme.colors.primary9};
  border-radius: ${legacyTheme.borderRadius.small};
  padding: ${legacyTheme.padding.regular};
  color: ${legacyTheme.colors.primary4};
  text-align: center;
  height: 34px;
  user-select: none;

  &.moving {
    opacity: 0.5;
  }

  &.target::before {
    position: absolute;
    left: -6px;
    content: " ";
    height: 34px;
    width: 4px;
    background: ${legacyTheme.colors.primary3};
  }

  &:hover {
    background: ${legacyTheme.colors.primary8};
    color: ${legacyTheme.colors.primary2};
  }

  & ${StyledIcon} {
    cursor: pointer;
  }
`;

export const StyledSortableGridLabelDragged = styled(StyledSortableGridLabel)`
  position: fixed;
  z-index: ${legacyTheme.zIndexes.layer6};
`;
