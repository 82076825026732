import classNames from "classnames";
import React from "react";

import { ConnectorIcon, ConnectorIconNames } from "../ConnectorIcon";
import Icon, { IconNames } from "../Icon/Icon";
import { ClassAndStyleProps, InteractionEvents } from "../shared";

import { StyledTag, TagColors } from "./Tag.styled";

export type TagProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    children: React.ReactNode;
    icon?: IconNames;
    connectorIconName?: ConnectorIconNames;
    withBorder?: boolean;
    color?: TagColors;
    size?: "small" | "medium" | "regular" | "large";
  };

export default function Tag({
  color = "default",
  size = "medium",
  className,
  style,
  icon,
  connectorIconName,
  children,
  withBorder = true,
  ...interactionEvents
}: TagProps) {
  return (
    <StyledTag
      color={color}
      size={size}
      {...interactionEvents}
      className={classNames(className)}
      style={style}
      withBorder={withBorder}
    >
      {icon && <Icon name={icon} size={10} />}
      {connectorIconName && (
        <ConnectorIcon name={connectorIconName} size={10} />
      )}
      {children}
    </StyledTag>
  );
}
