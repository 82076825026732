import { theme } from "../../icecube-ux";

/**
 * That constant is used to disable documentation feature for first version.
 * @todo Let's make it so this message is deleted before the end of 2023 ^^
 */
export const IS_DOC_READY = false;

export const borderBottomStyle = {
  borderBottomColor: theme.colors.borderLight,
  borderBottomStyle: "solid",
  borderBottomWidth: "1px",
};

export const borderRightStyle = {
  borderRightColor: theme.colors.borderLight,
  borderRightStyle: "solid",
  borderRightWidth: "1px",
};
