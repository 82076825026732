import { useState } from "react";

import { useAuth } from "../../../hooks/auth/auth";
import { useMetricList } from "../../../hooks/metricList";
import useDebouncedEffect from "../../../hooks/useDebounceEffect";
import {
  Box,
  ConnectorIcon,
  Flex,
  IconButton,
  ReportTable,
  ReportTableHeightManagementType,
  Text,
  theme,
} from "../../../icecube-ux";
import { getIntegrationIcon } from "../../../integrations/integration";
import { _ } from "../../../languages/helper";
import { previewCustomDimension } from "../../../lib/synthService";
import { CustomDimension } from "../types";

interface PreviewProps {
  dimension: CustomDimension;
  isValid: boolean;
  previewShown: boolean;
  onTogglePreviewShown: () => void;
}

export default function Preview({
  dimension,
  isValid,
  previewShown,
  onTogglePreviewShown,
}: PreviewProps) {
  const auth = useAuth();
  const { dimensions, tableKeyToConnectorKey } = useMetricList();

  const [loading, setLoading] = useState(false);
  const [previewData, setPreviewData] = useState<Record<string, unknown>[]>([]);

  const renderNoPreview = () => {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        background={theme.colors.bgLight10}
        border={`1px solid ${theme.colors.borderLight}`}
        height="100%"
      >
        <Box textAlign="center" margin="20px">
          <Text variant="body14SemiBold">{_`No preview`} </Text>
          <Text variant="body12Regular" color={theme.colors.primary60}>
            {_`Your custom dimension is currently not valid`}
          </Text>
        </Box>
      </Box>
    );
  };

  const renderTable = () => (
    <ReportTable
      loading={loading}
      heightManagementType={ReportTableHeightManagementType.MAX_ROW}
      header={{
        left: [],
        right: [
          [
            ...Object.keys(previewData?.[0] ?? {})
              .filter((key) => key !== "custom")
              .map((key) => ({
                key: key,
                label: dimensions[key.split(".")[1]].label,
                icon: (
                  <ConnectorIcon
                    name={
                      key.split(".")[0] === "blended" ||
                      key === "amazonads_campaign.date" // Used as default for shared date dimension
                        ? "Polar"
                        : getIntegrationIcon(
                            tableKeyToConnectorKey[key.split(".")[0]]?.[0],
                          )
                    }
                  />
                ),
                nonDraggable: true,
                nonOrderable: true,
                nonDeletable: true,
              })),
            {
              key: "custom",
              label: _`Result`,
              nonDraggable: true,
              nonOrderable: true,
              nonDeletable: true,
            },
          ],
        ],
      }}
      body={{
        left: [],
        right: (previewData ?? []).map((line) => ({
          cells: [
            ...Object.entries(line).map(([_, value]) => ({
              value: `${value as string}`,
              forceAlignLeft: true,
            })),
          ],
        })),
      }}
      footer={[]}
    />
  );

  useDebouncedEffect(
    () => {
      if (isValid && !auth.processing) {
        const abortController = new AbortController();
        const fetchPreview = async () => {
          setLoading(true);
          const data = await previewCustomDimension(
            abortController,
            await auth.getToken(),
            { id: "preview", title: "preview", json_value: dimension },
          );
          if (data) {
            setPreviewData(data);
          }
          setLoading(false);
        };
        void fetchPreview();
      }
    },
    1000,
    [isValid, JSON.stringify(dimension), auth],
  );

  return (
    <Box
      display="grid"
      gap="16px"
      gridTemplateRows={previewShown ? "auto 1fr" : "auto"}
      padding="24px"
      overflow="auto"
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        style={{ cursor: "pointer" }}
        onClick={onTogglePreviewShown}
      >
        <Text variant="body14SemiBold">
          {_`Which data are selected in your dimension`}
        </Text>
        <IconButton
          name={previewShown ? "ArrowDown" : "ArrowUp"}
          size="large"
          onClick={onTogglePreviewShown}
        />
      </Flex>
      {previewShown && (
        <Box>
          {!isValid && renderNoPreview()}
          {isValid && renderTable()}
        </Box>
      )}
    </Box>
  );
}
