import { Box, Text, theme } from "../../../icecube-ux";
import { _ } from "../../../languages/helper";
import { borderBottomStyle } from "../utils";

export default function Header({
  title = _`Custom Dimensions`,
  description = _`Create your own dimension to group and filter your data as you need`,
}: {
  title?: string;
  description?: string;
}) {
  return (
    <Box padding="16px 16px" {...{ ...borderBottomStyle }}>
      <Text variant="body18Regular">{title}</Text>
      <Text variant="body12Regular" color={theme.colors.text80}>
        {description}
      </Text>
    </Box>
  );
}
