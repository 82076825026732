import {
  GetApplicableGMVTierData,
  PlanSelector,
  UsageBasedPricing,
} from "../common/types/subscription-service";

import { createClient } from "./apiClient";

const client = createClient("subscriptions-service");

export const requestStartPlan = async (
  token: string,
  plan: string,
  yearly: boolean,
): Promise<{ url: string; reinstall?: boolean }> => {
  const json = await client
    .new()
    .post(`/api/shopify/plan/${plan}/${yearly ? "1" : "0"}`)
    .auth(token)
    .fetch<{ url: string; reinstall?: boolean }>();
  if (json.error === true) {
    throw new Error("Could not request new plan");
  }
  return json;
};

export const checkTenantShopConnection = async (
  token: string,
  shop: string,
): Promise<boolean> => {
  const json = await client
    .new()
    .get("/api/shopify/tenantShopConnection?shop=" + encodeURIComponent(shop))
    .auth(token)
    .fetch<{ connected: boolean }>();
  if (json.error === true) {
    throw new Error("Checking for tenant/shop connection failed");
  }
  return json.connected;
};

export const connectTenant = async (
  token: string,
  uuid: string,
): Promise<boolean> => {
  const json = await client
    .new()
    .post("/api/shopify/connect/" + uuid)
    .auth(token)
    .fetch();
  if (json.error === true) {
    throw new Error("Connecting shop to tenant failed");
  }
  return true;
};

export const getShopDetails = async (uuid: string) => {
  const json = await client
    .new()
    .get("/shopify/shop/" + uuid)
    .fetch<{ shop: { myshopify_domain?: string } }>();
  return json.error ? {} : json.shop;
};

export const getUsageBasedPrice = async (token: string) => {
  const json = await client
    .new()
    .get("/api/subscriptions/usageBasedPrice")
    .auth(token)
    .fetch<{
      data: UsageBasedPricing;
    }>();
  return json.error ? null : json.data;
};

export const getGMVTierBasedPrice = async (token: string) => {
  const json = await client
    .new()
    .get("/api/pricing/gmv-tier")
    .auth(token)
    .fetch<{
      data: GetApplicableGMVTierData;
    }>();
  return json.error ? null : json.data;
};

export const createStripeCheckoutSession = async (
  token: string,
  planSelector: PlanSelector,
) => {
  const json = await client
    .new()
    .post("/api/subscriptions/stripeCheckoutSession/" + planSelector.plan)
    .body({ ...planSelector, returnUrl: window.location.origin })
    .auth(token)
    .fetch<{ session: { url: string } }>();
  return json.error ? null : json.session;
};

export const upgradeStripeSubscription = async (
  token: string,
  planSelector: PlanSelector,
) => {
  const json = await client
    .new()
    .post("/api/subscriptions/upgrade")
    .body({
      returnUrl: window.location.origin + window.location.pathname,
      ...planSelector,
    })
    .auth(token)
    .fetch<{ session: { url: string } }>();
  return json.error ? null : json.session;
};

export const createStripeCustomerPortalSession = async (token: string) => {
  const json = await client
    .new()
    .post("/api/subscriptions/stripeCustomerPortalSession")
    .body({ returnUrl: window.location.origin + window.location.pathname })
    .auth(token)
    .fetch<{ session: { url: string } }>();
  return json.error ? null : json.session;
};
