import classNames from "classnames";

import EmojiIcons, { EmojiIconNames } from "../EmojiIcon/EmojiIcon";
import { Flex } from "../Flex";
import { legacyTheme } from "../legacyTheme";
import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { Text } from "../Typography";

import { StyledSnack } from "./Snack.styled";

export type SnackProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & { text: string; icon?: EmojiIconNames };

export default function Snack({
  text,
  icon,
  className,
  style,
  ...interactionEvents
}: SnackProps) {
  return (
    <StyledSnack
      {...interactionEvents}
      className={classNames(className)}
      style={style}
    >
      <Flex alignItems="center" gap={8}>
        <Text
          color={legacyTheme.colors.white}
          fontSize={legacyTheme.typography.fontSizes.large}
          fontWeight={legacyTheme.typography.fontWeigth.w500}
          textAlign="center"
        >
          {text}
        </Text>
        {icon && <EmojiIcons name={icon} size={14} />}
      </Flex>
    </StyledSnack>
  );
}
