import { theme } from "../theme";
import { typographyStylesMap } from "../Typography/typographyStylesMap";

export const sizeStylesMap = {
  xxlarge: {
    padding: "8px 44px",
    height: "43px",
    gap: "4px",
    borderRadius: theme.borderRadius.r4px,
    ...typographyStylesMap.body15SemiBold,
  },
  large: {
    padding: "8px 12px",
    height: "34px",
    gap: "4px",
    borderRadius: theme.borderRadius.r4px,
    ...typographyStylesMap.body12Medium,
  },
  small: {
    padding: "6px 8px",
    height: "28px",
    gap: "2px",
    borderRadius: theme.borderRadius.r4px,
    ...typographyStylesMap.body11Medium,
  },
  tiny: {
    padding: "2px 4px",
    height: "18px",
    gap: "2px",
    borderRadius: theme.borderRadius.r4px,
    ...typographyStylesMap.body9Medium,
  },
  icon: {
    padding: "0 2px",
    height: "20px",
    gap: "2px",
    borderRadius: theme.borderRadius.r4px,
    ...typographyStylesMap.body9Medium,
  },
};

export const iconModePadding = {
  xxlarge: {
    padding: "8px 8px !important",
  },
  large: {
    padding: "8px 8px !important",
  },
  small: {
    padding: "6px 6px !important",
  },
  tiny: {
    padding: "1.5px 2.5px !important",
  },
  icon: {
    padding: "0 2px !important",
  },
} as const;
