import styled, { css } from "styled-components";

import { Icon } from "../Icon";
import { theme } from "../theme";

export const StyledCustomerJourneyTable = styled.div<{
  areAllJourneysSingleTouch: boolean;
}>`
  position: relative;
  display: grid;
  grid-template-columns:
    minmax(
      ${({ areAllJourneysSingleTouch }) =>
        areAllJourneysSingleTouch ? "221px" : "350px"},
      auto
    )
    530px;
  grid-template-rows: 44px auto 44px;
  gap: 8px;
`;

const sharedCellStyles = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: ${theme.colors.bgLight30};
  border: 1px solid ${theme.colors.borderLight};
  border-radius: ${theme.borderRadius.r2px};
  padding: 0 8px;
`;

export const StyledCustomerJourneyTableHeaderCell = styled.div`
  ${sharedCellStyles}
  justify-content: flex-start;
  padding: 0 2px;
  cursor: pointer;
`;

export const StyledCustomerJourneyTableHeaderCellArrow = styled(Icon)`
  margin-left: 4px;
  color: ${theme.colors.grey80};

  *:hover > & {
    color: ${theme.colors.grey90};
  }
`;

export const StyledCustomerJourneyTableJourneySection = styled.div<{
  isLoading: boolean;
}>`
  position: relative;
  ${({ isLoading }) => (isLoading ? "opacity: 50%;" : "")}
`;

export const StyledCustomerJourneyTableJourneySectionScrollContainer = styled.div<{
  alignTouches: "left" | "right";
}>`
  overflow-y: auto;
  display: flex;
  flex-direction: ${({ alignTouches }) =>
    alignTouches === "left" ? "row" : "row-reverse"};
`;

export const StyledCustomerJourneyTableCell = styled.div`
  ${sharedCellStyles}
  background: ${theme.colors.bgLight10};
  & > div {
    flex-grow: 1;
  }
`;

export const StyledCustomerJourneyTableFooterCell = styled.div`
  ${sharedCellStyles}
  & > div {
    flex-grow: 1;
  }
`;

export const StyledCustomerJourneyTableScrollShadow = styled.div<{
  side: string;
}>`
  height: 100%;
  width: 22px;
  background: ${theme.customColors.shadowDivGradient};

  ${({ side }) =>
    side === "left"
      ? css`
          left: 0;
          transform: scaleX(-1);
        `
      : css`
          right: 0;
        `}
  top: 0;
  position: absolute;
  pointer-events: none;
`;
