import styled, { css } from "styled-components";

import { StyledIcon } from "../Icon/Icon.styled";
import { Label } from "../Label";
import { StyledLabel } from "../Label/Label.styled";
import { legacyTheme } from "../legacyTheme";
import { theme } from "../theme";

interface StyleMoreOptionsProps {
  size?: "tiny" | "small" | "large";
}

const sizes = {
  tiny: 10,
  small: 20,
  large: 30,
} as const;

export const StyledMoreOptions = styled.div<StyleMoreOptionsProps>`
  position: relative;

  ${({ size = "small" }) => {
    switch (size) {
      case "small":
        return css`
          height: ${sizes[size]}px;
          width: ${sizes[size]}px;
        `;
    }
  }}

  border-radius: ${legacyTheme.borderRadius.tiny};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  & ${StyledIcon} {
    vertical-align: middle;
  }
`;

export const StyledMoreOptionsMenu = styled.div`
  position: fixed;
  z-index: ${legacyTheme.zIndexes.layer2};
  padding: 8px 0;
  background: ${legacyTheme.colors.white};
  box-shadow: ${legacyTheme.shadows.regular};
  border-radius: 2px;
  min-width: 114px;
`;

export const StyledMoreOptionsMenuItem = styled(Label)<{
  disabled?: boolean;
}>`
  padding-left: 12px;
  padding-right: 12px;
  ${({ disabled }) =>
    disabled
      ? ""
      : css`

  &:hover {
    background: ${theme.colors.primary10};

    & > span.label-content {
      color: ${theme.colors.primary100};
    }
    & .left-icon {
      color: ${theme.colors.text90};
    }
  }

  & ${StyledLabel} {
    cursor: pointer;
    color: ${theme.colors.text90} !important;
  }

  & ${StyledLabel}:hover {
    color: ${theme.colors.primary100} !important;
    background: ${theme.colors.primary10} !important;
  `}
`;
