import React, { useEffect, useState } from "react";

export default function useScrollDirection(
  ref: React.RefObject<HTMLDivElement | null>,
) {
  const [scrollValue, setScrollValue] = useState(0);
  const [scrollDirection, setScrollDirection] = useState<"down" | "up" | null>(
    null,
  );

  useEffect(() => {
    if (ref && ref.current) {
      let lastScrollY = 0;
      const updateScrollDirection = () => {
        if (ref && ref.current) {
          const scrollY = ref.current.scrollTop;
          const direction = scrollY > lastScrollY ? "down" : "up";
          setScrollValue(scrollY);
          if (
            direction !== scrollDirection &&
            (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
          ) {
            setScrollDirection(direction);
          }
          lastScrollY = scrollY > 0 ? scrollY : 0;
        }
      };

      const current = ref.current;
      current.addEventListener("scroll", updateScrollDirection);
      return () => {
        current.removeEventListener("scroll", updateScrollDirection);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return { scrollDirection, scrollValue };
}
