import styled from "styled-components";

import {
  Box,
  Button,
  Flex,
  Grid,
  Input,
  Space,
  Text,
  theme,
} from "../../../icecube-ux";
import { _ } from "../../../languages/helper";

import LogoExchange from "./LogoExchange";

const StyledStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  background: ${theme.colors.bgLight10};

  & span {
    display: inline-block;
    color: ${theme.colors.primary100};
    margin-right: 8px;
    border-radius: 50%;
    background: ${theme.colors.primary20};
    width: 24px;
    line-height: 24px;
    text-align: center;
  }
`;

interface Step1Props {
  loading: boolean;
  error?: string;
  email: string;
  onEmailChange: (email: string) => void;
  goToNextStep: () => Promise<void>;
}

export default function Step1({
  loading,
  error,
  email,
  onEmailChange,
  goToNextStep,
}: Step1Props) {
  const handleEmailKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      void goToNextStep();
    }
  };

  return (
    <>
      <Flex flexDirection="column" gap={24} alignItems="center">
        <Flex flexDirection="column" gap={16} alignItems="center">
          <LogoExchange />
          <Flex flexDirection="column" gap={8} alignItems="center">
            <Text variant="body18Medium">{_`Book a call to get started for free`}</Text>
            <Text
              variant="body12Regular"
              color={theme.colors.primary80}
            >{_`What we’ll cover during the chat`}</Text>
          </Flex>
          <img
            src="/img/outbound-popup/sales-team.png"
            alt=""
            style={{ height: 53 }}
          />
        </Flex>
        <Grid gridTemplateColumns="1fr 1fr" gap={8}>
          <StyledStepContainer>
            <Text variant="body13Medium">
              <span>1</span>
              {_`Your 7 day free trial`}
            </Text>
            <Text
              variant="body12Regular"
              color={theme.colors.text90}
            >{_`Explore Polar with your own data after the call, no limits, no charges.`}</Text>
          </StyledStepContainer>
          <StyledStepContainer>
            <Text variant="body13Medium">
              <span>2</span>
              {_`One-click set up`}
            </Text>
            <Text
              variant="body12Regular"
              color={theme.colors.text90}
            >{_`Track what matters with Polar’s built-in eCommerce report toolkit and templates.`}</Text>
          </StyledStepContainer>
          <StyledStepContainer>
            <Text variant="body13Medium">
              <span>3</span>
              {_`A single source of truth`}
            </Text>
            <Text
              variant="body12Regular"
              color={theme.colors.text90}
            >{_`We use 45+ connectors to sync your sources and unify your data. No engineering required.`}</Text>
          </StyledStepContainer>
          <StyledStepContainer>
            <Text variant="body13Medium">
              <span>4</span>
              {_`Customizable building blocks`}
            </Text>
            <Text
              variant="body12Regular"
              color={theme.colors.text90}
            >{_`We scale with your needs with tailored-made reports, dimensions, and more!`}</Text>
          </StyledStepContainer>
        </Grid>
      </Flex>
      <Space size={24} />
      <Flex flexDirection="column" gap={24}>
        <Input
          error={error}
          label={_`First, add your email:`}
          value={email}
          onChange={(v) => onEmailChange(`${v}`)}
          onKeyDown={handleEmailKeyDown}
          block
          placeholder="E.g. name@company.com"
        />
        <Box
          height="1px"
          background={theme.colors.borderLight}
          margin="0 -24px"
        ></Box>
        <Button
          block
          disabled={loading}
          loading={loading}
          color="primary"
          onClick={() => void goToNextStep()}
        >{_`Pick a day and time`}</Button>
      </Flex>
    </>
  );
}
