import { useBootstrap } from "../../../../../hooks/bootstrap";
import { useCanSeePixel } from "../../../../../hooks/pixelConfiguration";
import { Button, Flex } from "../../../../../icecube-ux";
import { ConfigurationButtonSectionProps } from "../../../../../integrations/integration";
import { _ } from "../../../../../languages/helper";

import { PixelConnectorButtonSeeUTMs } from "./PixelConnectorButtonSeeUTMs";
import { PixelConnectorSubtitle } from "./PixelConnectorSubtitle";

export const PixelConnectorButtonSection = ({
  onConnect,
}: ConfigurationButtonSectionProps) => {
  const { hasPermission } = useBootstrap();
  const canEdit = hasPermission("connector.edit");
  const { canSeePixelSetup, canSeePixelStatus } = useCanSeePixel();

  return (
    <>
      {canSeePixelStatus && <PixelConnectorSubtitle />}

      <Flex gap={12}>
        <Button
          size="small"
          color={canSeePixelStatus ? "tertiary" : "ghost"}
          onClick={onConnect}
          disabled={!canEdit}
          block
        >
          {canSeePixelStatus
            ? _`Check status`
            : canSeePixelSetup
            ? _`Set up the Polar Pixel`
            : _`Understand the Polar Pixel`}
        </Button>

        {canSeePixelStatus && <PixelConnectorButtonSeeUTMs />}
      </Flex>
    </>
  );
};
