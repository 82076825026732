import styled, { css } from "styled-components";

import { theme } from "../theme";

import { colorStylesMap } from "./colorStylesMap";
import { iconModePadding, sizeStylesMap } from "./sizeStylesMap";
import { ButtonColor, ButtonSize } from "./types";

export const StyledButton = styled.button<{
  color: ButtonColor;
  size: ButtonSize;
  block?: boolean;
  autoHeight?: boolean;
  iconMode?: boolean;
  ctaSize?: boolean;
  hasClickHandler?: boolean;
}>`
  ${({ block }) =>
    block ? `display: flex; width: 100%;` : "display: inline-flex;"}
  ${({ hasClickHandler }) => hasClickHandler && "cursor: pointer"};
  border: 1px solid transparent;
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  ${({ color, size, iconMode }) => {
    const colorStyle = colorStylesMap[color];

    return css`
      ${sizeStylesMap[size]}
      ${iconMode ? iconModePadding[size] : ""}

      ${colorStyle.root}
      &:visited {
        ${colorStyle.root}
      }
      &:hover {
        ${colorStyle.hover}
      }
    `;
  }}

  ${({ ctaSize }) => (ctaSize ? `height: 40px !important;` : "")}
  ${({ autoHeight }) => (autoHeight ? `height: auto !important;` : "")}

  &:disabled, &[disabled], &:disabled:hover, &[disabled]:hover {
    pointer-events: none;
    cursor: default;
    color: ${theme.colors.text80};
    background-color: ${theme.colors.bgLight10};
    border: 1px dashed ${theme.colors.text80};
  }

  transition:
    background 0.2s ease-in-out,
    color 0.2s ease-in-out,
    border-color 0.2s ease-in-out,
    text-decoration-color 0.2s ease-in-out;
`;
