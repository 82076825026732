import React, { useCallback, useEffect, useRef, useState } from "react";

import { CheckIcon, TabulationIcon } from "../../../components/Icons/Icons";
import { Loader } from "../../../components/Loader/Loader";
import { useConnectorObservability } from "../../../hooks/connectorObservability";
import { useCustomElementEditors } from "../../../hooks/customElementEditors";
import { useMetricList } from "../../../hooks/metricList";
import useMouse from "../../../hooks/mouse";
import { Text, Box, theme } from "../../../icecube-ux";
import { _ } from "../../../languages/helper";
import {
  CustomMetricDefinition,
  CustomMetricElement,
  IMetricsList,
} from "../../../types/synthesizer";
import { OPERATOR_LIST } from "../../../utils/filterUtils";
import { getMetricIcon } from "../../../utils/metricUtils";
import { getFilteredMetricList, isValidMetricValue } from "../shared/utils";

import AutoGrowInput from "./components/AutoGrowInput";
import FormulaBlock from "./components/FormulaBlock";
import FormulaOperators from "./components/FormulaOperators";
import Operators from "./components/Operators";
import "./formula-editor.css";
import {
  extractCustomMetricId,
  getBadgeIndex,
  getMetricLabel,
  isCustomMetric,
  metricElementRulesCount,
} from "./utils";

interface FormulaEditorProps {
  text: string;
  onTextChange: (value: string) => void;
  loading: boolean;
  metric: CustomMetricDefinition;
  metricList: IMetricsList;
  onMetricChange: (metric: CustomMetricDefinition) => void;
  onOpenFilter: (index: number) => void;
  highlightedMetric?: number;
  cursorPosition: number;
  onCursorPositionChange: (position: number) => void;
  onPressUp?: () => void;
  onPressDown?: () => void;
}

export default function FormulaEditor({
  text,
  onTextChange,
  loading,
  metric,
  metricList,
  onMetricChange,
  onOpenFilter,
  highlightedMetric,
  cursorPosition,
  onCursorPositionChange,
  onPressUp = () => {},
  onPressDown = () => {},
}: FormulaEditorProps) {
  const { x, y } = useMouse();
  const { statuses } = useConnectorObservability();
  const { tableKeyToConnectorKey } = useMetricList();
  const elementEditors = useCustomElementEditors();
  const containerRef = useRef<HTMLDivElement>(null);

  const [editing, setEditing] = useState(false);
  const [draggingId, setDraggingId] = useState(-1);
  const [canComplete, setCanComplete] = useState(false);
  const [autoCompleteLabel, setAutoCompleteLabel] = useState("");
  const [selectedMetric, setSelectedMetric] = useState("");
  const [droppingPosition, setDroppingPosition] = useState({
    index: 0,
    position: "start",
  });

  const getElementIcon = (element: CustomMetricElement) => {
    if (element.type === "metric") {
      const icons = getMetricIcon(
        element.value,
        metricList,
        statuses,
        tableKeyToConnectorKey,
      );
      return icons.length > 1 ? "Polar" : icons[0]; // Fallback
    }
    return undefined;
  };

  const getElementLabel = (element: CustomMetricElement) => {
    if (element.type === "operator") {
      switch (element.value) {
        case "(":
          return Operators.OpenParenthesis({ fill: "#FA9826" });
        case ")":
          return Operators.CloseParenthesis({ fill: "#FA9826" });
        case "+":
          return Operators.PlusSign();
        case "-":
          return Operators.MinusSign();
        case "x":
          return Operators.MultiplySign();
        case "/":
          return Operators.DivideSign();
      }
    }

    if (element.type === "metric" && metricList) {
      return getMetricLabel(element.value, metricList);
    }

    return (
      <Text variant={"body12Regular"} color={theme.colors.text90}>
        {element.value}
      </Text>
    );
  };

  const handleAddOperator = useCallback(
    (value: string, at: number) => {
      const newElements = [...metric.elements];
      newElements.splice(at, 0, {
        type: "operator",
        value: value === "*" ? "x" : value,
        filters: [],
      });
      onMetricChange({
        ...metric,
        elements: [...newElements],
      });
      onCursorPositionChange(at + 1);
      setEditing(true);
    },
    [metric, onMetricChange, onCursorPositionChange],
  );

  const handleStopDragging = () => {
    if (draggingId > -1) {
      const newElements = [...metric.elements];
      const movedElement = { ...newElements[draggingId] };
      delete newElements[draggingId];
      if (droppingPosition.position === "start") {
        newElements.splice(droppingPosition.index, 0, movedElement);
      } else {
        newElements.splice(droppingPosition.index + 1, 0, movedElement);
      }
      onMetricChange({
        ...metric,
        elements: newElements.filter((e) => e !== undefined),
      });
      setDraggingId(-1);
    }
  };

  const handleAddValue = useCallback(
    (value: string) => {
      const newElements = [...metric.elements];
      newElements.splice(cursorPosition, 0, {
        type: "value",
        value,
        filters: [],
      });
      onMetricChange({
        ...metric,
        elements: [...newElements],
      });
      onCursorPositionChange(cursorPosition + 1);
    },
    [cursorPosition, metric, onMetricChange, onCursorPositionChange],
  );

  const handleDeleteItem = (index: number) => {
    const newElements = [...metric.elements];
    newElements.splice(index, 1);
    onMetricChange({
      ...metric,
      elements: newElements,
    });
  };

  const handleDelete = () => {
    if (
      cursorPosition <= metric.elements.length &&
      metric.elements.length > 0
    ) {
      handleDeleteItem(cursorPosition - 1);
      onCursorPositionChange(cursorPosition - 1);
    }
  };

  const handleCursorMove = (left: boolean) => {
    const newPos = left ? cursorPosition - 1 : cursorPosition + 1;
    onCursorPositionChange(
      Math.min(metric.elements.length, Math.max(0, newPos)),
    );
  };

  const handleLineBreak = () => {
    const newElements = [...metric.elements];
    newElements.splice(cursorPosition, 0, {
      type: "linebreak",
      value: "",
      filters: [],
    });
    onMetricChange({
      ...metric,
      elements: [...newElements],
    });
    onCursorPositionChange(cursorPosition + 1);
  };

  const handleComplete = useCallback(() => {
    if (text === "") {
      return;
    } else if (isValidMetricValue(text)) {
      handleAddValue(text);
    } else if (OPERATOR_LIST.includes(text)) {
      handleAddOperator(text, cursorPosition);
    } else {
      const newElements = [...metric.elements];
      newElements.splice(cursorPosition, 0, {
        type: "metric",
        value: selectedMetric,
        filters: [],
      });
      onMetricChange({
        ...metric,
        elements: [...newElements],
      });
      onCursorPositionChange(cursorPosition + 1);
    }
    onTextChange("");
    setSelectedMetric("");
    setAutoCompleteLabel("");
  }, [
    cursorPosition,
    handleAddOperator,
    handleAddValue,
    onTextChange,
    metric,
    selectedMetric,
    onMetricChange,
    text,
    onCursorPositionChange,
  ]);

  useEffect(() => {
    if (containerRef.current && draggingId > -1) {
      const closest = { distance: Infinity, index: -1, position: "start" };
      containerRef.current.childNodes.forEach((node, index) => {
        if ((node as HTMLDivElement).classList.contains("formula-block")) {
          const nodePosition = (node as HTMLDivElement).getBoundingClientRect();
          const distanceStart =
            Math.abs(x - nodePosition.x) * Math.abs(y - 18 - nodePosition.y);
          const distanceEnd =
            Math.abs(x - nodePosition.x - nodePosition.width) *
            Math.abs(y - 18 - nodePosition.y);
          if (distanceStart < closest.distance) {
            closest.distance = distanceStart;
            closest.index = index;
            closest.position = "start";
          }
          if (
            distanceEnd < closest.distance &&
            index < (containerRef?.current?.childNodes?.length || 0)
          ) {
            closest.distance = distanceEnd;
            closest.index = index;
            closest.position = "start";
            closest.position = "end";
          }
        }
      });
      setDroppingPosition({ index: closest.index, position: closest.position });
    }
  }, [x, y, draggingId]);

  useEffect(() => {
    if (OPERATOR_LIST.includes(text)) {
      return handleComplete();
    }

    if (text !== "" && isValidMetricValue(text)) {
      return setCanComplete(true);
    }

    const filteredMetricList = getFilteredMetricList(
      text,
      metricList,
      statuses,
      tableKeyToConnectorKey,
    );
    const metrics = Object.entries(filteredMetricList)
      .map(([_, t]) => [...t.raw, ...t.computed])
      .flat();
    setCanComplete(text !== "" && metrics.length > 0);
    if (metrics.length > 0) {
      setSelectedMetric(metrics[0].value);
      setAutoCompleteLabel(
        text.length > 0
          ? metrics[0].label.substring(Math.max(0, text.length))
          : "",
      );
    } else {
      setAutoCompleteLabel("");
    }
  }, [text, metricList, statuses, handleComplete, tableKeyToConnectorKey]);

  const renderAutoGrowInput = () => (
    <AutoGrowInput
      text={text}
      onPressUp={onPressUp}
      onPressDown={onPressDown}
      onCursorMove={handleCursorMove}
      onComplete={handleComplete}
      onDelete={handleDelete}
      onPressEnter={handleLineBreak}
      onTextChange={(v) => onTextChange(v)}
      onBlur={() => {
        setTimeout(() => {
          onTextChange("");
        }, 100);
        setEditing(false);
      }}
    />
  );

  if (loading) {
    return (
      <div className="formula-editor">
        <Loader color={"#E3E3FF"} size={24} />
      </div>
    );
  }

  return (
    <div
      className="formula-editor"
      ref={containerRef}
      onClick={() => {
        setEditing(true);
        onCursorPositionChange(metric.elements.length);
      }}
      style={{
        backgroundColor: theme.colors.bgLight10,
        border: `1px solid ${theme.colors.borderLight}`,
      }}
    >
      {metric.elements.map((e, i) => (
        <React.Fragment key={`metric-element-${i}`}>
          {cursorPosition === i && editing && renderAutoGrowInput()}
          {e.type === "linebreak" && <Box flexBasis="100%" height={0} />}
          {e.type !== "linebreak" && (
            <FormulaBlock
              key={`operant-${i}-${e.value}`}
              badgeIndex={getBadgeIndex(metric, e.value, i)}
              label={getElementLabel(e)}
              icon={getElementIcon(e)}
              onClick={
                e.type === "metric"
                  ? isCustomMetric(e.value)
                    ? () => {
                        elementEditors.editMetric(
                          extractCustomMetricId(e.value),
                        );
                      }
                    : () => {
                        onOpenFilter(i);
                      }
                  : undefined
              }
              iconName={isCustomMetric(e.value) ? "Edit" : "Triangle"}
              filterCount={metricElementRulesCount(e.filters)}
              canDrag={metric.elements.length > 1}
              dragging={draggingId === i}
              isDroppingTarget={draggingId > -1 && droppingPosition.index === i}
              droppingTargetPosition={droppingPosition.position}
              onStartDragging={() => setDraggingId(i)}
              onStopDragging={handleStopDragging}
              onDelete={() => handleDeleteItem(i)}
              highlighted={highlightedMetric === i}
            />
          )}
        </React.Fragment>
      ))}
      {draggingId > -1 && (
        <div
          className="formula-block-dragger"
          style={{ top: `${y - 18}px`, left: `${x - 10}px` }}
        />
      )}
      <FormulaOperators onClick={(o) => handleAddOperator(o, cursorPosition)} />

      {metric.elements.length === cursorPosition &&
        editing &&
        renderAutoGrowInput()}
      {!editing && <div style={{ width: "6px" }} />}
      <div className="auto-complete">
        {autoCompleteLabel !== "" && editing
          ? autoCompleteLabel
          : editing
          ? ""
          : _`Write or select metrics and write your formula...`}
      </div>

      {canComplete && (
        <div className="autocomplete-instructions">
          <span onClick={handleComplete}>
            {_`OK`} <CheckIcon size={10} className="middle" />
          </span>{" "}
          {_`or press Tab`} <TabulationIcon size={16} className="middle" />
        </div>
      )}
    </div>
  );
}
