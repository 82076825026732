const connectorIconNames = {
  ActiveCampaign: "ActiveCampaign",
  AdRoll: "AdRoll",
  AppleAppStore: "AppleAppStore",
  AppleSearchAds: "AppleSearchAds",
  Amazon: "Amazon",
  Awin: "Awin",
  Attentive: "Attentive",
  Bing: "Bing",
  Benchmarks: "Benchmarks",
  CampaignMonitor: "CampaignMonitor",
  Criteo: "Criteo",
  CustomerIo: "CustomerIo",
  Facebook: "Facebook",
  Fairing: "Fairing",
  Gmail: "Gmail",
  GoogleAds: "GoogleAds",
  GoogleAdManager: "GoogleAdManager",
  GoogleAnalytics: "GoogleAnalytics",
  GoogleCampaignManager360: "GoogleCampaignManager360",
  GoogleDisplayVideo360: "GoogleDisplayVideo360",
  GoogleSearchConsole: "GoogleSearchConsole",
  GoogleSheet: "GoogleSheet",
  GoogleSheets: "GoogleSheets",
  Google: "Google",
  Gorgias: "Gorgias",
  Grin: "Grin",
  Impact: "Impact",
  Instagram: "Instagram",
  InstagramBusiness: "InstagramBusiness",
  LinkedinAds: "LinkedinAds",
  LoopReturns: "LoopReturns",
  Klaviyo: "Klaviyo",
  Mailchimp: "Mailchimp",
  Mailjet: "Mailjet",
  Omnisend: "Omnisend",
  Outbrain: "Outbrain",
  Outreach: "Outreach",
  Paypal: "Paypal",
  Pinterest: "Pinterest",
  Polar: "Polar",
  PolarPixel: "PolarPixel",
  Postscript: "Postscript",
  Prestashop: "Prestashop",
  Recharge: "Recharge",
  RedditAds: "RedditAds",
  Sendinblue: "Sendinblue",
  Shiphero: "Shiphero",
  ShipStation: "ShipStation",
  SpotifyAds: "SpotifyAds",
  StackAdapt: "StackAdapt",
  Snapchat: "Snapchat",
  Shopify: "Shopify",
  Slack: "Slack",
  Taboola: "Taboola",
  TheTradeDesk: "TheTradeDesk",
  Tiktok: "Tiktok",
  TwitterAds: "TwitterAds",
  UpPromote: "UpPromote",
  WordPress: "WordPress",
  YahooDSP: "YahooDSP",
  Yotpo: "Yotpo",
  YouTubeAnalytics: "YouTubeAnalytics",
  ZohoCampaigns: "ZohoCampaigns",
  ZohoCRM: "ZohoCRM",
  Direct: "Direct",
} as const;

export type ConnectorIconNames = keyof typeof connectorIconNames;

export const isValidConnectorIcon = (v: unknown): v is ConnectorIconNames => {
  return !!connectorIconNames[v as ConnectorIconNames];
};
