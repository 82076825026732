const OpenParenthesis = ({ fill = "#776FFE" }: { fill?: string }) => (
  <svg
    width="5"
    height="11"
    viewBox="0 0 5 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="operator"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.1006 2.26274C4.1006 2.26274 4.1006 2.26274 4.1006 2.26274L4.10088 2.26254C4.10078 2.26261 4.10069 2.26268 4.1006 2.26274ZM4.10417 2.26005C4.54239 1.92788 4.63033 1.30363 4.3 0.863194C3.96863 0.421366 3.34183 0.331823 2.9 0.663194L3.48883 1.4483C2.9 0.663197 2.89968 0.663436 2.89935 0.66368L2.89866 0.664203L2.89712 0.665357L2.89351 0.668098L2.88409 0.675305L2.85684 0.696604C2.83506 0.713845 2.80617 0.7372 2.77119 0.766555C2.70132 0.8252 2.60667 0.908193 2.49559 1.01464C2.2743 1.22672 1.98282 1.53663 1.69126 1.93752C1.11094 2.73546 0.5 3.93766 0.5 5.46319C0.5 6.98872 1.11094 8.19093 1.69126 8.98886C1.98282 9.38976 2.2743 9.69967 2.49559 9.91175C2.60667 10.0182 2.70132 10.1012 2.77119 10.1598C2.80617 10.1892 2.83506 10.2125 2.85684 10.2298L2.88409 10.2511L2.89351 10.2583L2.89712 10.261L2.89866 10.2622L2.89935 10.2627C2.89968 10.263 2.9 10.2632 3.5 9.46319L2.9 10.2632C3.34183 10.5946 3.96863 10.505 4.3 10.0632C4.63033 9.62276 4.54239 8.99851 4.10417 8.66634L4.09824 8.66169C4.09072 8.65574 4.07664 8.64443 4.05693 8.62788C4.01743 8.59473 3.95583 8.54101 3.87941 8.46777C3.7257 8.32047 3.51718 8.09913 3.30874 7.81252C2.88906 7.23546 2.5 6.43766 2.5 5.46319C2.5 4.48872 2.88906 3.69093 3.30874 3.11387C3.51718 2.82726 3.7257 2.60592 3.87941 2.45862C3.95583 2.38538 4.01743 2.33166 4.05693 2.2985C4.07664 2.28196 4.09072 2.27065 4.09824 2.2647L4.10417 2.26005Z"
      fill={fill}
    />
  </svg>
);

const CloseParenthesis = ({ fill = "#776FFE" }: { fill?: string }) => (
  <svg
    width="5"
    height="11"
    viewBox="0 0 5 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="operator"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.899402 2.26274C0.899401 2.26274 0.899399 2.26274 0.899397 2.26274L0.899124 2.26254C0.899217 2.26261 0.899309 2.26268 0.899402 2.26274ZM0.895835 2.26005C0.457612 1.92788 0.369673 1.30363 0.7 0.863194C1.03137 0.421366 1.65817 0.331823 2.1 0.663194L1.51117 1.4483C2.1 0.663197 2.10032 0.663436 2.10065 0.66368L2.10134 0.664203L2.10288 0.665357L2.10649 0.668098L2.11591 0.675305L2.14316 0.696604C2.16494 0.713845 2.19383 0.7372 2.22881 0.766555C2.29868 0.8252 2.39333 0.908193 2.50441 1.01464C2.7257 1.22672 3.01718 1.53663 3.30874 1.93752C3.88906 2.73546 4.5 3.93766 4.5 5.46319C4.5 6.98872 3.88906 8.19093 3.30874 8.98886C3.01718 9.38976 2.7257 9.69967 2.50441 9.91175C2.39333 10.0182 2.29868 10.1012 2.22881 10.1598C2.19383 10.1892 2.16494 10.2125 2.14316 10.2298L2.11591 10.2511L2.10649 10.2583L2.10288 10.261L2.10134 10.2622L2.10065 10.2627C2.10032 10.263 2.1 10.2632 1.5 9.46319L2.1 10.2632C1.65817 10.5946 1.03137 10.505 0.7 10.0632C0.369674 9.62276 0.457612 8.99851 0.895833 8.66634L0.901758 8.66169C0.909276 8.65574 0.923355 8.64443 0.943066 8.62788C0.982567 8.59473 1.04417 8.54101 1.12059 8.46777C1.2743 8.32047 1.48282 8.09913 1.69126 7.81252C2.11094 7.23546 2.5 6.43766 2.5 5.46319C2.5 4.48872 2.11094 3.69093 1.69126 3.11387C1.48282 2.82726 1.2743 2.60592 1.12059 2.45862C1.04417 2.38538 0.982567 2.33166 0.943066 2.2985C0.923355 2.28196 0.909276 2.27065 0.901758 2.2647L0.895835 2.26005Z"
      fill={fill}
    />
  </svg>
);

const PlusSign = () => (
  <svg
    width="9"
    height="10"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="operator"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 7.46313C3.5 8.01542 3.94771 8.46313 4.5 8.46313C5.05229 8.46313 5.5 8.01542 5.5 7.46313V5.46313H7.5C8.05229 5.46313 8.5 5.01542 8.5 4.46313C8.5 3.91085 8.05229 3.46313 7.5 3.46313H5.5V1.46313C5.5 0.91085 5.05228 0.463135 4.5 0.463135C3.94771 0.463135 3.5 0.91085 3.5 1.46313V3.46313H1.5C0.947715 3.46313 0.5 3.91085 0.5 4.46313C0.5 5.01542 0.947715 5.46313 1.5 5.46313H3.5V7.46313Z"
      fill="#776FFE"
    />
  </svg>
);

const MinusSign = () => (
  <svg
    width="9"
    height="10"
    viewBox="0 0 9 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="operator"
  >
    <path
      d="M0.5 1.46313C0.5 0.91085 0.947715 0.463135 1.5 0.463135H7.5C8.05228 0.463135 8.5 0.91085 8.5 1.46313C8.5 2.01542 8.05228 2.46313 7.5 2.46313H1.5C0.947715 2.46313 0.5 2.01542 0.5 1.46313Z"
      fill="#776FFE"
    />
  </svg>
);

const MultiplySign = () => (
  <svg
    width="9"
    height="10"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="operator"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.19391 6.67928C8.60193 7.08731 8.60193 7.74885 8.19391 8.15687C7.78589 8.5649 7.12435 8.5649 6.71633 8.15687L4.50008 5.9406L2.28383 8.15687C1.87581 8.5649 1.21428 8.5649 0.806253 8.15687C0.398231 7.74885 0.398231 7.08731 0.806254 6.67928L3.0225 4.46301L0.806017 2.2465C0.397995 1.83848 0.397994 1.17694 0.806017 0.76891C1.21404 0.360884 1.87557 0.360884 2.28359 0.76891L4.50008 2.98542L6.71657 0.76891C7.12459 0.360884 7.78612 0.360884 8.19415 0.76891C8.60217 1.17694 8.60217 1.83848 8.19415 2.2465L5.97766 4.46301L8.19391 6.67928Z"
      fill="#776FFE"
    />
  </svg>
);

const DivideSign = () => (
  <svg
    width="8"
    height="11"
    viewBox="0 0 8 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="operator"
  >
    <path
      d="M1.40483 10.0339C0.905105 9.74537 0.733889 9.10637 1.02241 8.60664L5.20165 1.36798C5.49016 0.868255 6.12916 0.697041 6.62888 0.985563C7.1286 1.27408 7.29982 1.91308 7.0113 2.41281L2.83206 9.65147C2.54354 10.1512 1.90455 10.3224 1.40483 10.0339Z"
      fill="#776FFE"
    />
  </svg>
);

const Operators = {
  OpenParenthesis,
  CloseParenthesis,
  PlusSign,
  MinusSign,
  MultiplySign,
  DivideSign,
};

export default Operators;
