import classNames from "classnames";
import React from "react";

import { Flex } from "../Flex";
import { Label } from "../Label";
import { Loader } from "../Loader";
import MoreOptions, { OptionItem } from "../MoreOptions/MoreOptions";
import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { useSortableGrid } from "../SortableGrid";

import {
  StyledDraggerIcon,
  StyledSortableNavigation,
  StyledSortableNavigationContainer,
  StyledSortableNavigationLabelDragged,
  StyledSortableNavigationLoader,
} from "./SortableNavigation.styled";

export interface NavigationItem {
  value: string | number;
  label: string;
  className?: string;
  dataCy?: string;
  options: OptionItem[];
  onClick?: () => void;
  notSortable?: boolean;
  style?: React.CSSProperties;
  prepend?: React.ReactNode;
}

type SortableNavigationProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    items: NavigationItem[];
    onSort: (values: Array<string | number>, from: number, to: number) => void;
    disableSort?: boolean;
    loading?: boolean;
  };

export default function SortableNavigation({
  items,
  onSort,
  disableSort = false,
  loading = false,
  className,
  style,
  ...interactionEvents
}: SortableNavigationProps) {
  const { x, y, ref, handleMouseDown, movingIndex, toIndex } = useSortableGrid({
    seriesLength: 1,
    options: items,
    onSort,
    disableSort,
  });

  return (
    <StyledSortableNavigationContainer
      ref={ref}
      {...interactionEvents}
      className={classNames(className)}
      style={style}
      gridTemplateRows="1fr"
    >
      {loading && (
        <StyledSortableNavigationLoader>
          <Flex justifyContent="center" alignItems="center">
            <Loader size={12} />
          </Flex>
        </StyledSortableNavigationLoader>
      )}
      {items.map((item, i) => (
        <StyledSortableNavigation
          key={`i-${item.value}`}
          data-cy={item.dataCy ?? ""}
          className={classNames({
            moving: movingIndex === i,
            target: toIndex === i,
          })}
        >
          {disableSort || item.notSortable ? (
            <div />
          ) : (
            <StyledDraggerIcon
              onMouseDown={() => handleMouseDown(i)}
              data-cy="nav-item-dragger"
            />
          )}
          <Label className={item.className} style={item.style}>
            {item.prepend}
            <span onClick={item.onClick}>{item.label}</span>
          </Label>
          {item.options.length > 0 && (
            <MoreOptions data-cy="nav-item-more" options={item.options} />
          )}
        </StyledSortableNavigation>
      ))}

      {movingIndex !== null && (
        <StyledSortableNavigationLabelDragged
          style={{ left: `${x}px`, top: `${y}px` }}
        >
          <StyledDraggerIcon />
          <Label>{items[movingIndex].label}</Label>
          <MoreOptions options={[]} />
        </StyledSortableNavigationLabelDragged>
      )}
    </StyledSortableNavigationContainer>
  );
}
