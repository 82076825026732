import * as d3 from "d3";
import { HTMLAttributes, memo, useEffect, useMemo, useRef } from "react";

import { SerieFocusState } from "./Serie";

type BarProps = {
  serieName: string;
  barXPosition: number;
  barYPosition: number;
  barWidth: number;
  barHeight: number;
  value: number;
  fill: string;
  focusState?: SerieFocusState;
  onChangeFocusState?: (serieName: string, focusState: SerieFocusState) => void;
} & HTMLAttributes<SVGPathElement>;

const Bar = memo(function Bar({
  serieName,
  barXPosition,
  barYPosition,
  barWidth,
  barHeight,
  value,
  fill,
  focusState,
  onChangeFocusState,
}: BarProps) {
  const pathRef = useRef<null | SVGPathElement>(null);
  const radius = Math.min(10, Math.abs(barHeight), barWidth / 3);
  const plusMinus = value < 0 ? -1 : 1; //used for transition

  const firstRender = useRef(0);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current++;
      return;
    }
  });

  const fixedBarYPosition = isNaN(barYPosition) ? 0 : barYPosition;
  const fixedRadius = isNaN(radius) ? 0 : radius;
  const fixedBarHeight = isNaN(barHeight) ? 0 : barHeight;
  const fixedPlusMinus = isNaN(plusMinus) ? 0 : plusMinus;

  const dFrom = `
    M${barXPosition},${fixedBarYPosition}
    v0
    q${
      value < 0
        ? `0,${fixedRadius} ${fixedRadius},${fixedRadius}`
        : `0,-${fixedRadius} ${fixedRadius},-${fixedRadius}`
    }
    h${barWidth - fixedRadius * 2}
    q${
      value < 0
        ? `${fixedRadius},0 ${fixedRadius},-${fixedRadius}`
        : `${fixedRadius},0 ${fixedRadius},${fixedRadius}`
    }
    v0
    z
    `;

  const dTo = `
    M${barXPosition},${fixedBarYPosition}
    v${fixedBarHeight + fixedPlusMinus * fixedRadius}
    q${
      value < 0
        ? `0,${fixedRadius} ${fixedRadius},${fixedRadius}`
        : `0,-${fixedRadius} ${fixedRadius},-${fixedRadius}`
    }
    h${barWidth - fixedRadius * 2}
    q${
      value < 0
        ? `${fixedRadius},0 ${fixedRadius},-${fixedRadius}`
        : `${fixedRadius},0 ${fixedRadius},${fixedRadius}`
    }
    v${-fixedBarHeight - fixedPlusMinus * fixedRadius}
    z
    `;

  useEffect(() => {
    if (!pathRef.current) {
      return;
    }
  }, [pathRef]);

  const setupTransition = useMemo(() => {
    const setupState = { hasSetupAnimation: false };
    return (node: SVGPathElement) => {
      if (!node) {
        return;
      }

      if (setupState.hasSetupAnimation) {
        return;
      }

      d3.select(node)
        .transition(d3.transition().duration(1000).ease(d3.easeLinear))
        .attrTween("d", function () {
          const interpolator = d3.interpolateString(dFrom, dTo);
          return function (t) {
            return interpolator(t);
          };
        });

      setupState.hasSetupAnimation = true;
    };
  }, [dFrom, dTo]);

  return (
    <path
      ref={setupTransition}
      fill={fill}
      fillOpacity={focusState === "dimmed" ? 0.15 : 1}
      onMouseLeave={() => {
        onChangeFocusState?.(serieName, "normal");
      }}
      onMouseEnter={() => {
        onChangeFocusState?.(serieName, "highlighted");
      }}
    ></path>
  );
});

export default Bar;
