import classNames from "classnames";
import React, { ReactNode } from "react";

import { _ } from "../../languages/helper";
import { Button } from "../Button";
import { ConnectorIconNames, ConnectorIconWrapper } from "../ConnectorIcon";
import { Counter } from "../Counter";
import { IconButton } from "../IconButton";
import { HorizontalList, List } from "../List";
import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { stopPropagation } from "../utils";

import useGroupedList, { GroupedListValues } from "./GroupedList.hooks";
import {
  StyledGroupedList,
  StyledGroupedListBlock,
  StyledGroupedListBlockTitle,
  StyledGroupedListHeader,
} from "./GroupedList.styled";

export type GroupedListProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    icon?: ConnectorIconNames;
    background?: string;
    iconComponent?: ReactNode;
    label: string;
    connected: boolean;
    opened: boolean;
    direction: "vertical" | "horizontal";
    onToggleOpen: () => void;
    onClickOnElement?: (value: string | number) => void;
    onClickPlusButton?: () => void;
    selected: string[];
    search?: string;
    groupedList: GroupedListValues;
    allowSelection: boolean;
    withCross?: boolean;
    onRemoveElement?: (value: string | number) => void;
    addingDisabled?: boolean;
    sectionDisabled?: boolean;
    headerInfo?: React.ReactNode;
    canUnselectWhenDisabled?: boolean;
  };

export default function GroupedList({
  direction = "horizontal",
  icon,
  background,
  iconComponent,
  label,
  connected,
  search = "",
  opened,
  selected,
  onToggleOpen,
  onClickOnElement,
  onClickPlusButton,
  allowSelection,
  groupedList,
  withCross,
  onRemoveElement,
  addingDisabled,
  canUnselectWhenDisabled,
  sectionDisabled,
  headerInfo,
  className,
  style,
  ...interactionEvents
}: GroupedListProps) {
  const { isNull, selectedCount, isOpened, filledSubcategory, selectedList } =
    useGroupedList(search, selected, opened, groupedList);

  if (isNull) {
    return null;
  }

  return (
    <StyledGroupedList
      data-cy={label}
      {...interactionEvents}
      className={classNames(className)}
      style={style}
    >
      <StyledGroupedListHeader
        className={classNames({ disabled: !connected || sectionDisabled })}
        onClick={onToggleOpen}
      >
        {icon && (
          <ConnectorIconWrapper
            name={icon}
            size="large"
            background={background ?? "#fff"}
            className="metric-icon"
          />
        )}
        {iconComponent}
        <div className="metric-label" style={{ flexGrow: 1 }}>
          {label}
        </div>
        {selectedCount > 0 ? (
          <Counter value={selectedCount} variant="small" />
        ) : (
          <div />
        )}
        {onClickPlusButton && (
          <IconButton
            size="small"
            name="Add"
            color="default"
            disabled={sectionDisabled}
            onClick={(e) => {
              stopPropagation(e);
              onClickPlusButton();
            }}
          />
        )}
        {headerInfo}
        {connected && search === "" && (
          <IconButton
            size="small"
            disabled={sectionDisabled}
            name={opened ? "ArrowUp" : "ArrowDown"}
            color="default"
            onClick={onToggleOpen}
          />
        )}
        {!connected && search === "" && (
          <Button
            size="small"
            color="warning"
            label={_`Connect your account`}
          />
        )}
        {search !== "" && <div />}
      </StyledGroupedListHeader>
      {direction === "vertical" &&
        isOpened &&
        allowSelection &&
        selectedList.length > 0 && (
          <StyledGroupedListBlock>
            <List
              highlighted={search !== "" ? search : undefined}
              withCheckboxes={allowSelection && search === ""}
              options={selectedList}
              selected={selected}
              onClickOnElement={onClickOnElement}
              disabled={addingDisabled}
              pointerCursor={true}
              canUnselectWhenDisabled={canUnselectWhenDisabled}
            />
          </StyledGroupedListBlock>
        )}
      {isOpened &&
        Object.entries(groupedList).map(([key, options]) => {
          if (options.length === 0) {
            return null;
          }

          return (
            <StyledGroupedListBlock key={key}>
              {filledSubcategory > 1 && (
                <StyledGroupedListBlockTitle>{key}</StyledGroupedListBlockTitle>
              )}
              {direction === "vertical" && (
                <List
                  highlighted={search !== "" ? search : undefined}
                  withCheckboxes={allowSelection && search === ""}
                  options={
                    allowSelection
                      ? options.filter(
                          (m) => !selected.includes(m.value as string),
                        )
                      : options
                  }
                  selected={selected}
                  onClickOnElement={onClickOnElement}
                  disabled={addingDisabled}
                  pointerCursor={true}
                />
              )}
              {direction === "horizontal" && (
                <HorizontalList
                  highlighted={search !== "" ? search : undefined}
                  withCheckboxes={allowSelection}
                  withCross={withCross}
                  options={options}
                  selected={selected}
                  onClickOnElement={onClickOnElement}
                  onRemoveElement={onRemoveElement}
                  disabled={addingDisabled}
                />
              )}
            </StyledGroupedListBlock>
          );
        })}
    </StyledGroupedList>
  );
}
