import { Box } from "../Box";

const SPACE_TYPE_MAP = {
  tiny: "4px",
  small: "8px",
  regular: "16px",
  large: "32px",
};

interface SpaceProps {
  type?: keyof typeof SPACE_TYPE_MAP;
  size?: number;
}

export default function Space({ type = "regular", size }: SpaceProps) {
  return <Box height={size ? `${size}px` : SPACE_TYPE_MAP[type]}>&nbsp;</Box>;
}
