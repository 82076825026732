import { useMemo } from "react";

import { useMetricList } from "../../../../hooks/metricList";
import { Flex } from "../../../../icecube-ux";
import { CustomDimension } from "../../types";

import WhenThenElse from "./WhenThenElse";

interface BuilderProps {
  dimension: CustomDimension;
  onChange: (newDimension: CustomDimension) => void;
}

export default function Builder({ dimension, onChange }: BuilderProps) {
  const { metrics } = useMetricList();

  const usedBreakdownList = useMemo(
    () =>
      Array.from(
        new Set([
          ...dimension.whenThen
            .map((wT) => wT.when)
            .flat()
            .map((w) => w.dimensionKey)
            .filter((d) => d !== ""),
        ]),
      ),
    [dimension],
  );

  const availableBreakdownsForThenElse = useMemo(() => {
    if (usedBreakdownList.length === 0) {
      return undefined;
    }

    const usedTables = usedBreakdownList
      .map((dim) => (dim.includes(".") ? dim.split(".")[0] : ""))
      .filter((key) => key !== "" && key !== "*");

    if (usedTables.length === 0) {
      return undefined;
    }

    const tableDimensionMap: { [key: string]: string[] } = {};
    usedTables.forEach((tableKey) => {
      if (!metrics.tables[tableKey]) {
        return;
      }

      tableDimensionMap[tableKey] = Object.keys(
        metrics.tables[tableKey].dimensions,
      );
    });
    const sharedDimensions = Object.values(tableDimensionMap).reduce(
      (a, b) => a.filter((c) => b.includes(c)),
      [],
    );

    return { tableDimensionMap, sharedDimensions };
  }, [usedBreakdownList, metrics.tables]);

  return (
    <Flex flexDirection="column" gap={16}>
      <WhenThenElse
        dimension={dimension}
        onChange={onChange}
        availableBreakdownsForThenElse={availableBreakdownsForThenElse}
      />
    </Flex>
  );
}
