import styled from "styled-components";

import { legacyTheme } from "../legacyTheme";

export const StyledSwitchContainer = styled.div`
  display: flex;
  background: ${legacyTheme.colors.white};
  border-radius: ${legacyTheme.borderRadius.default};
  box-sizing: border-box;
  width: 500px;
  padding: ${legacyTheme.padding.regular} ${legacyTheme.padding.xlarge};
  align-items: center;
  min-height: 50px;

  font-family: ${legacyTheme.typography.default.fontFamily};
  font-style: ${legacyTheme.typography.default.fontStyle};
  font-weight: ${legacyTheme.typography.default.fontWeight};
  font-size: ${legacyTheme.typography.fontSizes.regular};

  &:hover {
    background: ${legacyTheme.colors.bg.light};
  }
`;

export const StyledSwitchContainerLabelSection = styled.div``;

export const StyledSwitchContainerDescription = styled.div`
  color: ${legacyTheme.colors.grey6};
  font-size: ${legacyTheme.typography.fontSizes.small};
  line-height: 15px;
`;

export const StyledSwitchContainerInputSection = styled.div`
  margin-left: auto;
`;
