import moment from "moment";

import { startOfDayInTimezone } from "../../../../icecube-ux/dateUtils";
import { RefreshStatusData } from "../../../../lib/integrationsService";
import { getDatasourceStatusesByConnector } from "../../../../utils/connectors";
import { removeDuplicates } from "../../../../utils/utils";

export const getHasHadSyncForTodaysDate = (status: RefreshStatusData) => {
  return (
    status.last_refresh_date &&
    moment(
      startOfDayInTimezone(
        new Date(status.last_refresh_date),
        status.polarConnectorTimezone,
      ),
    ).isSameOrAfter(moment().startOf("day"))
  );
};

export const getSyncingConnectors = ({
  hasDataForSelectedView,
  requirements,
  allStatuses,
  rangeEnd,
  tableKeyToConnectorKey,
  doSelectedViewsCoverDatasource,
}: {
  hasDataForSelectedView: boolean;
  requirements: string[] | string[][];
  allStatuses: RefreshStatusData[];
  rangeEnd: moment.Moment;
  tableKeyToConnectorKey: { [key: string]: string[] };
  doSelectedViewsCoverDatasource: (datasourceId: string) => boolean;
}) => {
  const windowToShowSyncing = moment().subtract(1, "day").startOf("day");
  if (rangeEnd.isBefore(windowToShowSyncing) || !hasDataForSelectedView) {
    return {
      pending: [],
      current: [],
      any: false,
    };
  }

  const flatRequirements = Array.isArray(requirements[0])
    ? removeDuplicates(requirements.flat())
    : (requirements as string[]);

  const statusesByConnector = getDatasourceStatusesByConnector(allStatuses);

  const requiredConnectors = flatRequirements
    .map((requiredTable) => tableKeyToConnectorKey[requiredTable])
    .flat();

  const currentlySyncing = requiredConnectors.filter((connector) => {
    return statusesByConnector
      .get(connector)
      ?.some(
        (status) =>
          status.setup_state === "syncing" &&
          status.polarConnectorAccounts?.some((account) =>
            doSelectedViewsCoverDatasource(account.id),
          ),
      );
  });

  const pendingSync = requiredConnectors.filter((connector) => {
    return statusesByConnector.get(connector)?.some((status) => {
      const isActive = status.setup_state === "active";
      const isCoveredByViews = status.polarConnectorAccounts?.some((account) =>
        doSelectedViewsCoverDatasource(account.id),
      );
      const hasHadSyncForTodaysDate = getHasHadSyncForTodaysDate(status);
      return isActive && isCoveredByViews && !hasHadSyncForTodaysDate;
    });
  });
  return {
    pending: pendingSync,
    current: currentlySyncing,
    any: currentlySyncing.length > 0 || pendingSync.length > 0,
  };
};
