import classNames from "classnames";
import React from "react";

import { ClassAndStyleProps, InteractionEvents } from "../shared";

import { StyledSubscriptionPlanCard } from "./SubscriptionPlanCard.styled";

export type SubscriptionPlanCardProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    children: React.ReactNode;
    selected: boolean;
    current: boolean;
  };

export default function SubscriptionPlanCard({
  selected,
  current,
  children,
  className,
  style,
  ...interactionEvents
}: SubscriptionPlanCardProps) {
  return (
    <StyledSubscriptionPlanCard
      current={current}
      selected={selected}
      {...interactionEvents}
      className={classNames(className)}
      style={style}
    >
      {children}
    </StyledSubscriptionPlanCard>
  );
}
