import { useEffect, useRef } from "react";

export const usePrevious = <T>(
  value: T,
  { ignoreWhen }: { ignoreWhen?: (value: T) => boolean } = {},
) => {
  const ref = useRef(value);

  useEffect(() => {
    if (ignoreWhen && ignoreWhen(value)) {
      return;
    }
    ref.current = value;
  }, [value, ignoreWhen]);

  return ref.current;
};