import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  key: "recharge",
  category: INTEGRATION_CATEGORIES.sales_channels,
  dependsOnAccounts: true,
  image: "Recharge",
  backgroundColor: theme.customColors.rechargeBg,
  borderColor: theme.customColors.rechargeBorder,
  label: "Recharge",
  get description() {
    return _`Recurring Billing, Subscription for Ecommerce`;
  },
});
