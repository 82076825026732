import React from "react";
import "../global.css";
import "./typography.css";

interface TypographyProps {
  children?: React.ReactNode;
  className?: string;
}

const H1 = ({ children, className }: TypographyProps) => {
  return <h1 className={className}>{children}</h1>;
};

const H2 = ({ children, className }: TypographyProps) => {
  return <h2 className={className}>{children}</h2>;
};

const H3 = ({ children, className }: TypographyProps) => {
  return <h3 className={className}>{children}</h3>;
};

const H4 = ({ children, className }: TypographyProps) => {
  return <h4 className={className}>{children}</h4>;
};

const Subtitle = ({ children, className }: TypographyProps) => {
  return <div className={"text-subtitle " + (className || "")}>{children}</div>;
};

const PopupSubtitle = ({ children, className }: TypographyProps) => {
  return (
    <div className={"text-popup-subtitle " + (className || "")}>{children}</div>
  );
};

const P = ({ children, className }: TypographyProps) => {
  return <p className={"text-regular " + (className || "")}>{children}</p>;
};

const Small = ({ children, className }: TypographyProps) => {
  return <p className={"text-small " + (className || "")}>{children}</p>;
};

const PreTitle = ({ children, className }: TypographyProps) => {
  return <p className={"text-pre-title " + (className || "")}>{children}</p>;
};

const Tiny = ({ children, className }: TypographyProps) => {
  return <p className={"text-tiny " + (className || "")}>{children}</p>;
};

const A = (
  props: TypographyProps &
    React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >,
) => {
  return (
    <a {...props} className={"text-link " + (props.className || "")}>
      {props.children}
    </a>
  );
};

const typography = {
  H1,
  H2,
  H3,
  H4,
  Subtitle,
  PopupSubtitle,
  P,
  Small,
  PreTitle,
  Tiny,
  A,
};

export default typography;
