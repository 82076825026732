import { _ } from "../../languages/helper";
import { Button } from "../Button";
import { Flex } from "../Flex";

type UseNoDataReasonArgs = {
  hasNoDataForSelectedView?: boolean;
  loadingConnector?: string;
  notReadyConnector?: string;
  missingConnectorLabel?: string;
  issueWithMetric?: string;
  missingConnectorOnClick: () => void;
};

export const useNoDataReason = ({
  hasNoDataForSelectedView,
  loadingConnector,
  notReadyConnector,
  missingConnectorLabel,
  issueWithMetric,
  missingConnectorOnClick,
}: UseNoDataReasonArgs) => {
  if (hasNoDataForSelectedView) {
    return <>{_`No data because of selected view`}</>;
  }

  if (issueWithMetric) {
    return <>{issueWithMetric}.</>;
  }

  if (notReadyConnector) {
    return (
      <>
        {_`No data available`}.
        <br />
        {_`Your|||connector`} {notReadyConnector}{" "}
        {_`connector is not setup properly`}
      </>
    );
  }

  if (loadingConnector) {
    return (
      <>
        {_`No data available`}.
        <br />
        {_`Your|||data`} {loadingConnector} {_`data is being imported`}
      </>
    );
  }

  if (missingConnectorLabel) {
    return (
      <Flex justifyContent="space-between" gap={8} alignItems="center">
        {missingConnectorLabel // first letter upper case
          .charAt(0)
          .toUpperCase() + missingConnectorLabel.slice(1)}
        <br />
        <Button
          color="warning"
          size="small"
          onClick={missingConnectorOnClick}
          style={{
            zIndex: 1,
          }}
        >
          {_`Connect to Polar Analytics`}
        </Button>
      </Flex>
    );
  }

  return null;
};
