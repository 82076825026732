import styled, { css } from "styled-components";

import { theme } from "../theme";

import { colorStylesMap } from "./colorStylesMap";
import { sizeStylesMap } from "./sizeStylesMap";
import { IconButtonColor, IconButtonSize } from "./types";

export const StyledIconButton = styled.button<{
  color: IconButtonColor;
  size: IconButtonSize;
  inline?: boolean;
}>`
  ${({ inline }) => (inline ? "display: inline-flex;" : `display: flex;`)}

  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  border: 0;
  padding: 0;
  margin: 0;

  ${({ color, size }) => {
    const colorStyle = colorStylesMap[color];

    return css`
      ${sizeStylesMap[size]}
      ${colorStyle.root}
      &:hover:enabled {
        ${colorStyle.hover}
      }
    `;
  }}

  &:disabled {
    cursor: default;
    color: ${theme.colors.text80};
    background-color: ${theme.colors.bgLight10};
    border: 1px dashed ${theme.colors.text80};
  }

  transition:
    background 0.2s ease-in-out,
    color 0.2s ease-in-out,
    border-color 0.2s ease-in-out,
    text-decoration-color 0.2s ease-in-out;
`;
