import moment from "moment";

import { Grid, Select, Text } from "../../icecube-ux";
import { _ } from "../../languages/helper";
import {
  DateRange,
  Granularity,
  PREFILTERS,
  SmartDateComparePeriod,
} from "../../utils/dateUtils";
import { SmartFilterSelector } from "../../utils/filterUtils";

import "./smart-date-filter.css";
import SmartDateFilterPicker from "./SmartDateFilterPicker";

interface SmartDateFilterProps {
  onApply?: (
    range: DateRange,
    compareRange: DateRange,
    selector: SmartFilterSelector,
    comparePeriod: SmartDateComparePeriod | "range",
  ) => void;
  allowCompare?: boolean;
  withPopup?: boolean;
  range: DateRange;
  matchingDayOfWeek: boolean;
  granularity: Granularity | "none";
  compareRange: DateRange;
  comparePeriod: SmartDateComparePeriod | "range";
  selector: SmartFilterSelector;
  setRange: (value: DateRange) => void;
  setCompareRange: (value: DateRange) => void;
  setComparePeriod: (value: SmartDateComparePeriod | "range") => void;
  setSelector: (value: SmartFilterSelector) => void;
  setMatchingDayOfWeek: (value: boolean) => void;
  displayDatePicker: boolean;
  setDisplayDatePicker: (display: boolean) => void;
  disableDateFilterSelection?: boolean;
  disableGranularity: boolean;
}

export default function SmartDateFilter({
  onApply,
  allowCompare = false,
  withPopup = true,
  range,
  granularity,
  matchingDayOfWeek,
  compareRange,
  comparePeriod,
  selector,
  setRange,
  setCompareRange,
  setSelector,
  setComparePeriod,
  setMatchingDayOfWeek,
  displayDatePicker,
  setDisplayDatePicker,
  disableDateFilterSelection = false,
  disableGranularity,
}: SmartDateFilterProps) {
  return (
    <>
      {displayDatePicker && (
        <SmartDateFilterPicker
          allowCompare={allowCompare}
          onClose={() => setDisplayDatePicker(false)}
          onApply={onApply}
          asPopup={withPopup}
          {...{
            range,
            matchingDayOfWeek,
            granularity: disableGranularity ? "none" : granularity,
            compareRange,
            comparePeriod,
            selector,
            setRange,
            setCompareRange,
            setSelector,
            setComparePeriod,
            setMatchingDayOfWeek,
            disableDateFilterSelection,
          }}
        />
      )}
      {!withPopup && !displayDatePicker && (
        <>
          {selector.tab === "predefined" && (
            <Grid gridTemplateColumns="auto 1fr" gap={8} alignItems="center">
              <Text variant="BodyRegular">From</Text>
              <Select
                forcedDisplayedValue={
                  PREFILTERS.find(
                    (filter) => filter.value === selector.predefinedSelection,
                  )?.label || ""
                }
                options={[]}
                selected={[]}
                notOpenable={true}
                onClick={() => setDisplayDatePicker(true)}
                style={{ minWidth: 200 }}
                block
              />
            </Grid>
          )}
          {selector.tab === "relative" && (
            <Grid
              gridTemplateColumns="auto 1fr 1fr"
              gap={8}
              alignItems="center"
            >
              <Text variant="BodyRegular">{_`Last|||plural`}</Text>
              <Select
                placeholder={selector.relativeSelection.count.toString()}
                options={[]}
                selected={[]}
                notOpenable={true}
                onClick={() => setDisplayDatePicker(true)}
                style={{ minWidth: 100 }}
              />
              <Select
                placeholder={
                  selector.relativeSelection.granularity +
                  (selector.relativeSelection.count === 1 ? "" : "s")
                }
                options={[]}
                selected={[]}
                notOpenable={false}
                onClick={() => setDisplayDatePicker(true)}
                style={{ minWidth: 100 }}
              />
            </Grid>
          )}
        </>
      )}
      {((!displayDatePicker && selector.tab === "range") || withPopup) && (
        <Grid
          gridTemplateColumns={
            "auto 1fr" +
            (allowCompare && window.innerWidth > 1240 ? " auto" : "")
          }
          gap={8}
          alignItems="center"
          {...(disableDateFilterSelection
            ? { style: { pointerEvents: "none" } }
            : {})}
        >
          <Select
            leftIcon="Calendar"
            placeholder={`${moment(range.start).format("MMM D, YY")} - ${moment(
              range.end,
            ).format("MMM D, YY")}`}
            options={[]}
            selected={[]}
            notOpenable={true}
            onClick={() => setDisplayDatePicker(true)}
            dataAttributes={{ "data-cy": "header-filter-calendar" }}
            block
          />
          {allowCompare && window.innerWidth > 1240 && (
            <>
              <Text variant="BodyRegular">{_`vs.`}</Text>
              <Select
                leftIcon="Calendar"
                placeholder={`${moment(compareRange.start).format(
                  "MMM D, YY",
                )} - ${moment(compareRange.end).format("MMM D, YY")}`}
                options={[]}
                selected={[]}
                notOpenable={true}
                onClick={() => setDisplayDatePicker(true)}
              />
            </>
          )}
        </Grid>
      )}
    </>
  );
}
