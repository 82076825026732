import { Suspense } from "react";

import { Box } from "../Box";
import { IconSVGProps } from "../Icon/types";

import { EmojisIconSVG } from "./svgEmojis";

export type EmojiIconNames =
  | "Acquisition"
  | "Retention"
  | "Products"
  | "Engagement"
  | "Custom"
  | "Alerts"
  | "Subscriptions"
  | "Earth"
  | "HandOverMouth"
  | "Check"
  | "Compass"
  | "Favorite"
  | "Rocket"
  | "Sparkle"
  | "Goals"
  | "Connectors"
  | "Party"
  | "Money"
  | "AskPolar"
  | "CreativeStudio"
  | "Battery"
  | "Inventory"
  | "Audience";

export type EmojiIconProps = IconSVGProps & { name: EmojiIconNames };

export default function EmojiIcon({
  name,
  size = 24,
  ...props
}: Omit<EmojiIconProps, "size"> & {
  size?: number;
}) {
  if (EmojisIconSVG[name] === undefined) {
    console.warn("Emoji icon not found", name);
    return null;
  }

  const SvgComponent = EmojisIconSVG[name];

  return (
    <Suspense
      fallback={
        <Box width={size} height={size}>
          &nbsp;
        </Box>
      }
    >
      <SvgComponent size={size} {...props} />
    </Suspense>
  );
}
