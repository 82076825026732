import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { PixelConnectorButtonSection } from "../pages/connectors/popups/connect/polarPixel/PixelConnectorButtonSection";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  key: "polar-pixel",
  image: "PolarPixel",
  label: "Polar Pixel",
  isAddon: true,
  get description() {
    return _`A first-party pixel for accurate attribution and insights`;
  },
  category: INTEGRATION_CATEGORIES.analytics_and_attribution,
  backgroundColor: theme.customColors.pixelBg,
  borderColor: theme.customColors.pixelBorder,
  getButtonSection: () => PixelConnectorButtonSection,
  addonTooltip: "polar-pixel",
});
