import classNames from "classnames";
import React from "react";

import { ClassAndStyleProps, InteractionEvents } from "../shared";

import { StyledShowByMediaQuery } from "./ShowByMediaQuery.styled";

export type ShowByMediaQueryProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    children: React.ReactNode;
    mediaQuery: string;
  };

export default function ShowByMediaQuery({
  className,
  style,
  children,
  mediaQuery,
  ...interactionEvents
}: ShowByMediaQueryProps) {
  return (
    <StyledShowByMediaQuery
      {...interactionEvents}
      className={classNames(className)}
      mediaQuery={mediaQuery}
      style={style}
    >
      {children}
    </StyledShowByMediaQuery>
  );
}
