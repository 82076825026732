export const LIVE_DEMO_TENANT_ID = "00000000-0000-0000-0000-000000000000";
export const LIVE_DEMO_EMAIL = "livedemo@polaranalytics.co";

/**
 * Expire on August 29th, 2033.
 */
export const OUTBOUND_DEMO_TOKENS =
  process.env.REACT_APP_ENV === "production"
    ? {
        id: "f42e0d29-f883-4d65-86e4-6761e36993aa",
        accessToken:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImY0MmUwZDI5LWY4ODMtNGQ2NS04NmU0LTY3NjFlMzY5OTNhYSIsImVtYWlsIjoibGl2ZWRlbW9AcG9sYXJhbmFseXRpY3MuY28iLCJlbWFpbFZhbGlkYXRlZEF0IjoiMjAyMy0wNy0xNlQxNjoyNzo1NS4yODZaIiwiaWF0IjoxNjkzMjk0OTIxLCJleHAiOjIwMDg4NzA5MjF9.BmObR_xbXt7617N6OsW9CcPKGunt5SaGsqcuvuvEC2g",
        refreshToken:
          "8RuqNURz4uBF5kQzwka9B3E0aNn4YL4aSiFrIUVfNPD6Jh5g2tpBfrVU5DtCeE2y",
        status: null,
      }
    : {
        id: "7049435c-9a06-4c67-9f73-d8481bf85da2",
        accessToken:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjcwNDk0MzVjLTlhMDYtNGM2Ny05ZjczLWQ4NDgxYmY4NWRhMiIsImVtYWlsIjoibGl2ZWRlbW9AcG9sYXJhbmFseXRpY3MuY28iLCJlbWFpbFZhbGlkYXRlZEF0IjoiMjAyMy0wNy0xNlQxNjoyOTo1Ni42ODZaIiwiaWF0IjoxNjkzMjk1OTU4LCJleHAiOjIwMDg4NzE5NTh9.6VrIbk_JFUTy1JJ0z9CP1Uc4Ju0RCsMA5p0FjJVWwOQ",
        refreshToken:
          "3vy2JlDV9A0d9lFc1bN2CgjnufddCQ5co0IySON3mYcEPgmN0odnoZTpLkpVP8Kf",
        status: null,
      };

export const DEMO_PERSONALIZED_DATA_STORAGE_KEY = "pa-demo-custom-data";
export const DEMO_DOMAIN_NAME_STORAGE_KEY = "pa-demo-domain-name";
export const DEMO_EMAIL_STORAGE_KEY = "pa-demo-email";
export const OUTBOUND_DEMO_IDENTIFICATION_STORAGE_KEY =
  "pa-outbound-demo-identification";
