import React from "react";
import "react-notifications/lib/notifications.css";
import { RouterProvider } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";

import AppRouter from "./AppRouter";
import { QueryProvider } from "./hooks/query/QueryProvider";
import { GlobalCss } from "./icecube-ux";
import PageLoader from "./pages/PageLoader";

export default function App() {
  return (
    <IntercomProvider appId="lfrl4yis" autoBoot>
      <style dangerouslySetInnerHTML={{ __html: GlobalCss }} />
      <QueryProvider>
        <React.Suspense fallback={<PageLoader />}>
          <RouterProvider router={AppRouter} />
        </React.Suspense>
      </QueryProvider>
    </IntercomProvider>
  );
}
