import classNames from "classnames";
import React, { CSSProperties } from "react";

import { ClassAndStyleProps, InteractionEvents } from "../shared";

import { StyledTypography } from "./Typography.styled";

export type TextVariant =
  | "SectionTitle"
  | "Subtitle"
  | "BodyRegular"
  | "BodyRegularBold"
  | "MediumRegular"
  | "MediumRegularBold"
  | "Tiny"
  | "TinyMedium"
  | "body48SemiBold"
  | "body40SemiBold"
  | "body34SemiBold"
  | "body34Regular"
  | "body28SemiBold"
  | "body26Regular"
  | "body24Bold"
  | "body24SemiBold"
  | "body24Medium"
  | "body24Regular"
  | "body22SemiBold"
  | "body20Bold"
  | "body20SemiBold"
  | "body20Medium"
  | "body20Regular"
  | "body18SemiBold"
  | "body18Bold"
  | "body18Medium"
  | "body18Regular"
  | "body16Bold"
  | "body16SemiBold"
  | "body16Medium"
  | "body16Regular"
  | "body15SemiBold"
  | "body15Regular"
  | "body14SemiBold"
  | "body14Medium"
  | "body14Regular"
  | "body13SemiBold"
  | "body13Medium"
  | "body13Regular"
  | "body13Light"
  | "body12Medium"
  | "body12SemiBold"
  | "body12Bold"
  | "body12Regular"
  | "body11SemiBold"
  | "body11Medium"
  | "body11Regular"
  | "body10Regular"
  | "body10Medium"
  | "body9SemiBold"
  | "body9Medium"
  | "body9Regular"
  | "body9Link"
  | "body8Regular";

export type TypographyProps = InteractionEvents<HTMLDivElement> &
  ClassAndStyleProps & {
    children?: React.ReactNode;
    variant?: TextVariant;
    uppercase?: boolean;
    color?: string;
    fontWeight?: string | number;
    fontSize?: string;
    fontStyle?: string;
    lineHeight?: string;
    textAlign?:
      | "center"
      | "end"
      | "justify"
      | "left"
      | "match-parent"
      | "right"
      | "start";
    inline?: boolean;
    noWrap?: boolean;
    whiteSpace?: CSSProperties["whiteSpace"];
    opacity?: string;
    pointer?: boolean;
    underline?: boolean;
    disableSelection?: boolean;
    ellipsis?: boolean;
    title?: string;
    dataAttributes?: { [key: string]: string };
  };

function Typography({
  children,
  className,
  style,
  variant,
  color,
  fontWeight,
  fontSize,
  fontStyle,
  textAlign,
  lineHeight,
  noWrap,
  whiteSpace,
  opacity,
  inline,
  ellipsis,
  uppercase,
  pointer,
  underline,
  disableSelection,
  title,
  dataAttributes,
  ...interactionEvents
}: TypographyProps) {
  return (
    <StyledTypography
      {...interactionEvents}
      variant={variant}
      style={{
        ...{
          color,
          fontWeight,
          fontSize,
          lineHeight,
          textAlign,
          fontStyle,
          opacity,
          whiteSpace,
          display: inline ? "inline" : "block",
        },
        ...style,
      }}
      className={classNames(className, variant, {
        uppercase,
        pointer,
        underline,
        disableSelection,
        noWrap,
        ellipsis,
      })}
      title={title}
      {...dataAttributes}
    >
      {children}
    </StyledTypography>
  );
}

export const Text = Typography;
