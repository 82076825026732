import { Helmet } from "react-helmet";

import { _ } from "../../languages/helper";
import Centering from "../Helpers/Centering";

import { Loader } from "./Loader";

export default function FullPageLoader() {
  return (
    <div className="full-page-loader">
      <Helmet>
        <title>{_`Loading...`} | Polar Analytics</title>
      </Helmet>
      <Centering>
        <Loader />
      </Centering>
    </div>
  );
}
