import { theme } from "../../theme";
import { Text } from "../../Typography";

interface ParenthesisProps {
  value: "(" | ")" | ",";
}

export default function Parenthesis({ value }: ParenthesisProps) {
  return (
    <Text
      variant="body12Regular"
      color={theme.colors.formulaEditor.orange}
      style={
        value === "("
          ? { marginLeft: "4px", marginRight: "-5px" }
          : { marginLeft: "1px" }
      }
    >
      {value}
    </Text>
  );
}
