export const DASHBOARD_STATES = {
  NO_DATA: 0,
  IN_PROGRESS: 1,
  READY: 2,
} as const;

export const CALL_STATES = {
  INITIAL: "INITIAL",
  BOOKED: "BOOKED",
  DONE: "DONE",
} as const;

export const SETUP_STATES = {
  NO_DATA_NO_CALL: 1,
  WAITING_DATA_NO_CALL: 2,
  READY_DATA_NO_CALL: 3,
  NO_DATA_WAITING_CALL: 4,
  WAITING_DATA_WAITING_CALL: 5,
  READY_DATA_WAITING_CALL: 6,
  NO_DATA_DONE_CALL: 7,
  WAITING_DATA_DONE_CALL: 8,
  READY_DATA_DONE_CALL: 9,
} as const;
