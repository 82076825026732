import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { useAuth } from "../../../hooks/auth/auth";
import {
  ChatObject,
  DraftUIAskPolarChatItem,
  PromptType,
  UIChat,
  createChat,
  deleteAChat,
  generateAskPolarAnswer,
  getChats,
  updateChat,
  updateChatItem,
} from "../../../lib/aiService";

interface ExampleQuestion {
  question: string;
  answer: DraftUIAskPolarChatItem;
}

interface ChatContextProps {
  chats: ChatObject[];
  getChat: (id: string) => ChatObject | null;
  onReply: ({
    chatId,
    message,
    promptType,
  }: {
    chatId: string;
    message: string;
    promptType: PromptType;
  }) => Promise<void>;
  deleteChat: ({ chatId }: { chatId: string }) => Promise<void>;
  updateLike: (
    chatId: string,
    chatItemId: string,
    like: "false" | "true",
  ) => void;
  onGenerateAnswer: ({
    chatId,
    abortController,
    setPartialAnswer,
  }: {
    chatId: string;
    abortController: AbortController;
    setPartialAnswer: (value: string | undefined) => void;
  }) => Promise<void>;
  createNewChat: ({
    message,
    onSuccess,
    abortController,
    promptType,
  }: {
    message?: string;
    onSuccess?: (id: string) => void;
    abortController?: AbortController;
    promptType: PromptType;
  }) => Promise<void>;
  sendExampleQuestion: ({
    exampleQuestion,
    onSuccess,
    abortController,
  }: {
    exampleQuestion?: ExampleQuestion;
    onSuccess?: (id: string) => void;
    abortController?: AbortController;
  }) => Promise<void>;
  loading: boolean;
}

const ChatContext = createContext<ChatContextProps | null>(null);

export const useChats = () => {
  const context = useContext(ChatContext);
  if (context === null) {
    throw Error("Chat context not provided");
  }
  return context;
};

interface LoadChatsProps {
  abortController?: AbortController;
}

const useProvideChats = () => {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [chats, setChats] = useState<ChatObject[]>([]);

  const getChat = (chatId: string) => {
    return chats.find(({ id }) => id === chatId) ?? null;
  };

  const loadChats = async ({ abortController }: LoadChatsProps) => {
    const token = await auth.getToken();
    const result = await getChats({
      abortController,
      token,
    });
    if (!result.error && result.data) {
      setChats(result.data);
    } else {
      throw new Error("loadChats failed");
    }

    setLoading(false);
  };

  const onGenerateAnswer: ChatContextProps["onGenerateAnswer"] = async ({
    chatId,
    abortController,
    setPartialAnswer,
  }) => {
    const token = await auth.getToken();
    setChats((chats) =>
      chats.map((chat) =>
        chat.id === chatId ? { ...chat, lastAnswerLoading: true } : chat,
      ),
    );

    const onError = () => {
      setChats((chats) =>
        chats.map((chat) =>
          chat.id === chatId
            ? { ...chat, lastAnswerError: true, lastAnswerLoading: false }
            : chat,
        ),
      );
      setPartialAnswer(undefined);
    };
    const onSuccess = (newChat: UIChat) => {
      setChats((chats) =>
        chats.map((chat) =>
          chat.id === chatId
            ? { ...chat, chat: newChat, lastAnswerLoading: false }
            : chat,
        ),
      );
      setPartialAnswer(undefined);
    };
    try {
      const result = await generateAskPolarAnswer({
        chatId,
        token,
        abortController,
      });

      const reader = result?.body?.getReader();
      let chunks = "";
      let stopLoop = false;
      const decoder = new TextDecoder();

      while (reader && !stopLoop) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value || new Uint8Array(), {
          stream: !done,
        });
        const chunkListWithCommas = "[" + chunk.split("}{").join("},{") + "]";
        const chunkList = JSON.parse(chunkListWithCommas) as ({
          chunk: string;
        } & { data: UIChat | undefined; error: boolean })[];

        for (let index = 0; index < chunkList.length; index++) {
          const parsed = chunkList[index];
          if (parsed.chunk) {
            chunks = chunks + parsed.chunk;
            setPartialAnswer(chunks);
          }
          const newChat = parsed.data;

          if (newChat && !parsed.error) {
            onSuccess(newChat);
            stopLoop = true;
            return;
          } else if (parsed.error) {
            onError();
            stopLoop = true;
            return;
          }
        }
      }
    } catch (_) {
      onError();
    }
  };

  const createNewChat: ChatContextProps["createNewChat"] = async ({
    message,
    abortController,
    onSuccess,
    promptType,
  }) => {
    const token = await auth.getToken();
    const { chatId } = await createChat({
      abortController,
      token,
      message,
      promptType,
    });
    if (chatId) {
      await loadChats({});
      onSuccess?.(chatId);
    }
  };

  const sendExampleQuestion: ChatContextProps["sendExampleQuestion"] = async ({
    exampleQuestion: { question, answer } = {},
    onSuccess,
    abortController,
  }) => {
    const token = await auth.getToken();
    const { chatId } = await createChat({
      abortController,
      token,
      message: question,
      responseFromTemplate: answer,
      promptType: "template",
    });
    if (chatId) {
      await loadChats({});
      onSuccess?.(chatId);
    }
  };

  const deleteChat: ChatContextProps["deleteChat"] = async ({ chatId }) => {
    const token = await auth.getToken();
    const result = await deleteAChat({
      token,
      chatId,
    });
    if (!result.error) {
      setChats(chats.filter((chat) => chat.id !== chatId));
    }
  };

  const onReply: ChatContextProps["onReply"] = async ({
    chatId,
    message,
    promptType,
  }) => {
    const abortController = new AbortController();
    const token = await auth.getToken();
    if (chatId) {
      const result = await updateChat({
        abortController,
        token,
        message,
        chatId,
        promptType,
      });
      if (!result.error && result.data) {
        setChats((chats) =>
          chats.map((chat) =>
            chat.id === chatId ? { ...chat, chat: result.data } : chat,
          ),
        );
      } else {
        throw new Error("Error on chat reply");
      }
    }
  };

  const updateLike = async (
    chatId: string,
    chatItemId: string,
    like: "true" | "false",
  ) => {
    const abortController = new AbortController();
    const token = await auth.getToken();
    if (chatId) {
      await updateChatItem({
        abortController,
        token,
        chatId,
        chatItemId,
        like,
      });
      setChats((chats) =>
        chats.map((chat) =>
          chat.id === chatId
            ? {
                ...chat,
                chat: chat.chat.map((chatItem) =>
                  chatItem.id === chatItemId ? { ...chatItem, like } : chatItem,
                ),
              }
            : chat,
        ),
      );
    }
  };

  useEffect(() => {
    if (auth.processing || !auth.isLoggedIn) {
      return;
    }
    const abortController = new AbortController();
    void loadChats({ abortController });
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.processing, auth.isLoggedIn]);

  return {
    chats,
    loading,
    onReply,
    onGenerateAnswer,
    getChat,
    updateLike,
    deleteChat,
    createNewChat,
    sendExampleQuestion,
  };
};

export const ProvideChats = ({ children }: { children: ReactNode }) => {
  const provider = useProvideChats();
  return (
    <ChatContext.Provider value={provider}>{children}</ChatContext.Provider>
  );
};
