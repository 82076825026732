import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  key: "facebook-pages",
  image: "Facebook",
  label: "Facebook Pages",
  category: INTEGRATION_CATEGORIES.marketing,
  backgroundColor: theme.customColors.fallbackConnectorBg,
  borderColor: theme.customColors.fallbackConnectorBorder,
  get description() {
    return _``;
  },
  get customConnectorInfo() {
    return [
      _`Due to API limitations, metrics may slightly differ from the Facebook Insights dashboard`,
      _`Facebook Pages metrics are in UTC timezone`,
      _`Data can take up to 24h to be exposed in the Meta API`,
    ];
  },
  isNewIntegrationService: true,
  dependsOnAccounts: true,
  canReconnectOAuth: true,
});
