import { useEffect, useState } from "react";
import styled from "styled-components";

import { Text, theme } from "../../icecube-ux";

const placeHolderImage = "/img/cell-placeholder.png";
const imageSize = 34;

const StyledCellWithImage = styled.div`
  display: flex;
  align-items: center;
  margin-left: -4px;
`;

const StyledProductImage = styled.img<{ loaded: boolean }>`
  background: ${(props) => (props.loaded ? "white" : "transparent")};
  border-radius: ${theme.borderRadius.r4px};
  width: ${`${imageSize}px`};
  height: ${`${imageSize}px`};
  margin-right: 4px;
  border: 1px solid ${theme.colors.borderLight};
`;

const StyledCellText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
`;

const ProductImage = ({ src, alt }: { src: string; alt: string }) => {
  const [currentSrc, setCurrentSrc] = useState<string>(placeHolderImage);
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  useEffect(() => {
    setCurrentSrc(src);
  }, [src]);

  const handleImageError = () => {
    setCurrentSrc(placeHolderImage);
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <StyledProductImage
      loading="lazy"
      src={currentSrc}
      alt={alt}
      onLoad={handleImageLoad}
      onError={handleImageError}
      loaded={imageLoaded}
    />
  );
};

export const ProductCell = ({
  imageUrl,
  value,
}: {
  imageUrl: string;
  value: string;
}) => {
  const downsizedImageUrl = imageUrl.replace(
    /\.(png|jpg|webp)(?=\?v=\d+)/,
    `_${imageSize * 2}x${imageSize * 2}.$1`,
  );
  return (
    <StyledCellWithImage>
      <ProductImage src={downsizedImageUrl} alt={downsizedImageUrl} />
      <StyledCellText
        variant="body12Regular"
        color={theme.colors.text100}
        title={value}
      >
        {value}
      </StyledCellText>
    </StyledCellWithImage>
  );
};
