import classNames from "classnames";
import React from "react";

import { _ } from "../../languages/helper";
import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { theme } from "../theme";
import { Text } from "../Typography";

import {
  StyledSubscriptionPlanList,
  StyledSubscriptionPlanListDivider,
} from "./SubscriptionPlanList.styled";

export type SubscriptionPlanListProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    heading: string;
    showDifferenceWith?: string;
    children: React.ReactNode;
  };

export default function SubscriptionPlanList({
  heading,
  showDifferenceWith,
  children,
  className,
  style,
  ...interactionEvents
}: SubscriptionPlanListProps) {
  return (
    <StyledSubscriptionPlanList
      {...interactionEvents}
      className={classNames(className)}
      style={style}
    >
      <div>
        <Text variant="body13Regular" color={theme.colors.text80}>
          {heading}
        </Text>
      </div>
      {showDifferenceWith && (
        <div>
          <Text variant="body14Medium" color={theme.colors.text110}>
            {_`Everything in ${["previousPlan", showDifferenceWith]}, plus...`}
          </Text>
        </div>
      )}
      <div>{children}</div>
    </StyledSubscriptionPlanList>
  );
}

export const SubscriptionPlanListDivider = StyledSubscriptionPlanListDivider;
