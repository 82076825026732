import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCombinedRefs = (...refs: any[]) => {
  const targetRef = React.useRef();

  React.useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === "function") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        ref(targetRef.current);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef as unknown as React.RefObject<HTMLInputElement>;
};

export default useCombinedRefs;
