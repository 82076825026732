import { useEffect, useRef } from "react";
import styled from "styled-components";

import { useAuth } from "../../hooks/auth/auth";
import { useBootstrap } from "../../hooks/bootstrap";
import { Icon, theme, Text, Flex, Box } from "../../icecube-ux";
import { _ } from "../../languages/helper";
import { identifyUser } from "../../utils/cannyUtils";
import { trackEvent, TRACKING_EVENTS } from "../../utils/trackingUtils";

const Wrapper = styled(Flex)`
  cursor: pointer;
  overflow: visible;
  position: relative;
`;

const IconContainer = styled.div`
  display: flex;
  border-radius: 8px;
  overflow: hidden;
`;

export const FeedbackTab = ({ isExpanded }: { isExpanded: boolean }) => {
  const auth = useAuth();
  const { tenant } = useBootstrap();
  const user = auth.user;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (user && tenant) {
      identifyUser({
        user: {
          id: user.id,
          email: user.email,
          name: user.name && user.name !== "" ? user.name : user.email,
          companies: [{ id: tenant.id, name: tenant.company_name ?? "" }],
        },
      });
    }
  }, [user, tenant]);

  if (auth.isOutboundDemo) {
    return null;
  }

  const handleClick = () => {
    if (!auth.user?.id) return;
    trackEvent(TRACKING_EVENTS.SUGGEST_A_FEATURE_OPENED, {
      userId: auth.user?.id,
      tenantId: tenant.id,
    });
  };

  return (
    <Wrapper ref={ref}>
      <a
        data-canny-link
        href="https://polaranalytics.canny.io/feedback"
        target="_blank"
        rel="noreferrer"
        style={{ width: "100%" }}
      >
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          rowGap={4}
          columnGap={12}
          onClick={handleClick}
          style={{ padding: 5 }}
          fullWidth
        >
          <IconContainer>
            <Icon name="SuggestAFeature" size={40}></Icon>
          </IconContainer>
          {isExpanded && (
            <Box flexGrow="1">
              <Text variant="body12Medium" color={theme.colors.text110}>
                {_`Give us feedback`}
              </Text>
              <Text variant="body11Regular" color={theme.colors.text90}>
                {_`Add or vote on ideas`}
              </Text>
            </Box>
          )}
        </Flex>
      </a>
    </Wrapper>
  );
};
