import { Grid, Input, RadioSelect, Text } from "../../../icecube-ux";
import { _ } from "../../../languages/helper";
import {
  CustomMetricDefinition,
  CustomMetricFormattingNumberType,
} from "../../../types/synthesizer";

interface NumberFormattingOptionsProps {
  metric: CustomMetricDefinition;
  onChange: (metric: CustomMetricDefinition) => void;
}

export default function NumberFormattingOptions({
  metric,
  onChange,
}: NumberFormattingOptionsProps) {
  return (
    <div>
      <Grid
        gridTemplateColumns="1fr 1fr"
        rowGap="4px"
        gap={8}
        className="margin-top-xlarge"
      >
        <Text variant="BodyRegularBold">{_`Prefix`}</Text>
        <Text variant="BodyRegularBold">{_`Suffix`}</Text>
        <Input
          placeholder={_`E.g.` + " #"}
          type="text"
          value={metric.formatting.prefix}
          onChange={(prefix) =>
            onChange({
              ...metric,
              formatting: {
                ...metric.formatting,
                prefix: prefix as string,
              },
            })
          }
          block
        />
        <Input
          placeholder={_`E.g.` + " k"}
          type="text"
          value={metric.formatting.suffix}
          onChange={(suffix) =>
            onChange({
              ...metric,
              formatting: {
                ...metric.formatting,
                suffix: suffix as string,
              },
            })
          }
          block
        />
      </Grid>
      <RadioSelect
        className="margin-top-x4large"
        name="number-type"
        value={metric.formatting.numberType}
        options={[
          {
            label: _`Whole number`,
            labelHint: _`E.g.` + " 5 " + _`or` + " -24",
            value: "integer",
          },
          {
            label: _`Decimal`,
            labelHint: _`E.g.` + " 5.34 " + _`or` + " -23.70",
            value: "decimal",
          },
        ]}
        onChange={(numberType) =>
          onChange({
            ...metric,
            formatting: {
              ...metric.formatting,
              numberType: numberType as CustomMetricFormattingNumberType,
            },
          })
        }
      />
    </div>
  );
}
