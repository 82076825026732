import {
  PlanName,
  SUBSCRIPTION_TYPE_NAMES,
} from "../../../common/types/subscription-service";
import {
  useRedirectToStripeCheckout,
  useTenantPlan,
  useSubscriptionEnd,
} from "../../../hooks/useAddonUtils";
import {
  Box,
  Grid,
  SubscriptionPlanCard,
  SubscriptionPlanFooter,
  SubscriptionPlanHeader,
  SubscriptionPlanListDivider,
  Tag,
} from "../../../icecube-ux";
import { _ } from "../../../languages/helper";
import {
  BasicAccountSupport,
  BasicPlatformAccess,
  PlusAccountSupport,
  PlusPlatformAccess,
} from "../components/basicPlanContent";
import SubscriptionPlanButton from "../components/SubscriptionPlanButton";

type CustomPlanCardProps = {
  isOnlyCard: boolean;
  highlightedPlan: PlanName | null;
};

const CustomPlanCard = ({
  isOnlyCard,
  highlightedPlan,
}: CustomPlanCardProps) => {
  const { isOnCustomPlan } = useTenantPlan();
  const { isSubscriptionEnding, subscriptionEndDate } = useSubscriptionEnd();
  const { isRedirecting, redirectToStripeCustomerPortal } =
    useRedirectToStripeCheckout();

  return (
    <SubscriptionPlanCard
      selected={highlightedPlan === SUBSCRIPTION_TYPE_NAMES.CUSTOM}
      current={isOnCustomPlan}
    >
      <SubscriptionPlanHeader
        current={isOnCustomPlan}
        planName={_`Custom Enterprise Plan`}
        label={
          isOnCustomPlan &&
          isSubscriptionEnding && (
            <Tag color="warning" size="medium" className="margin-left-regular">
              {_`Cancelled`}
            </Tag>
          )
        }
        recommended={highlightedPlan === SUBSCRIPTION_TYPE_NAMES.CUSTOM}
      />

      <SubscriptionPlanListDivider />

      <Grid gridTemplateColumns={isOnlyCard ? "1fr auto 1fr" : "1fr"}>
        <Grid gridTemplateRows={isOnlyCard ? "1fr 1fr" : "1fr"}>
          {isOnlyCard && <BasicPlatformAccess />}
          <PlusPlatformAccess
            showDifference={!isOnlyCard}
            showPlus={!isOnCustomPlan}
            isCustomPlan
          />
        </Grid>

        <SubscriptionPlanListDivider isVertical={isOnlyCard} />

        <Grid gridTemplateRows={isOnlyCard ? "1fr 1fr" : "1fr"}>
          {isOnlyCard && <BasicAccountSupport isCustomPlan />}
          <PlusAccountSupport
            showDifference={!isOnlyCard}
            showPlus={!isOnCustomPlan}
            isCustomPlan
          />
        </Grid>
      </Grid>

      <Box marginTop="auto" padding="0px 16px 16px 16px">
        <SubscriptionPlanButton
          isOnPlan={isOnCustomPlan}
          isRedirecting={isRedirecting}
          subscriptionEndDate={subscriptionEndDate}
          isSubscriptionEnding={isSubscriptionEnding}
          redirectToStripeCustomerPortal={redirectToStripeCustomerPortal}
        />

        <SubscriptionPlanFooter>
          {_`Fixed pricing available.`}
        </SubscriptionPlanFooter>
      </Box>
    </SubscriptionPlanCard>
  );
};

export default CustomPlanCard;
