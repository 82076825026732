import classNames from "classnames";
import React, { CSSProperties } from "react";

import { Flex } from "../Flex";
import { ClassAndStyleProps, InteractionEvents } from "../shared";

import useTooltip, { TooltipDirection } from "./Tooltip.hooks";
import {
  StyledTooltip,
  StyledTooltipArrow,
  StyledTooltipContent,
  StyledTooltipWrapper,
  SyledFixedBottomShadow,
} from "./Tooltip.styled";

const fixedBottomStyle = {
  bottom: 0,
  left: 0,
  right: 0,
};

export type TooltipProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    children?: React.ReactNode;
    content:
      | React.ReactNode
      | string
      | ((onClose: () => void) => React.ReactNode);
    direction?: TooltipDirection;
    parentDisplay?: string;
    fullHeight?: boolean;
    white?: boolean;
    bypass?: boolean;
    contentStyle?: CSSProperties;
    wrapperStyle?: CSSProperties;
    overlayFixedBottom?: boolean;
    persistOnClick?: boolean;
    isInteractive?: boolean;
    alignCenter?: boolean;
    delay?: number;
    mouseLeaveDelay?: number;
  };

export default function Tooltip({
  direction = "top",
  parentDisplay = "inline-block",
  white,
  content,
  overlayFixedBottom,
  persistOnClick = false,
  isInteractive = true,
  alignCenter = false,
  delay,
  mouseLeaveDelay,
  children,
  className,
  fullHeight,
  style,
  wrapperStyle,
  contentStyle,
  bypass,
  ...interactionEvents
}: TooltipProps) {
  const {
    anchorRefCallback,
    tooltipRefCallback,
    shown,
    handleMouseMove,
    handleMouseLeave,
    handleMouseEnter,
    handleMouseClick,
    hide,
    tooltipStyle,
    arrowStyle,
    realDirection,
    tooltipContentAlignement,
  } = useTooltip({
    direction,
    persistOnClick,
    isInteractive,
    delay,
    mouseLeaveDelay,
  });
  if (bypass) {
    return <>{children}</>;
  }
  return (
    <StyledTooltip
      {...interactionEvents}
      ref={anchorRefCallback}
      className={classNames(className)}
      style={{
        display: parentDisplay,
        height: fullHeight ? "100%" : undefined,
        ...style,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      {alignCenter ? (
        <Flex
          onClick={handleMouseClick}
          style={{
            cursor: persistOnClick ? "pointer" : undefined,
            ...wrapperStyle,
          }}
          alignContent="center"
        >
          {children}
        </Flex>
      ) : (
        <div
          onClick={handleMouseClick}
          style={{
            cursor: persistOnClick ? "pointer" : undefined,
            ...wrapperStyle,
          }}
        >
          {children}
        </div>
      )}

      {shown && overlayFixedBottom && (
        <SyledFixedBottomShadow onTouchStart={() => handleMouseLeave()} />
      )}
      {shown && (
        <StyledTooltipWrapper
          style={{
            opacity: tooltipStyle === null ? 0 : undefined,
            textAlign: tooltipContentAlignement,
            ...contentStyle,
            ...(!isInteractive ? { pointerEvents: "none" } : {}),
            ...(overlayFixedBottom ? fixedBottomStyle : tooltipStyle),
          }}
          overlayFixedBottom={overlayFixedBottom || false}
        >
          <StyledTooltipContent
            ref={tooltipRefCallback}
            className={classNames({ white })}
            overlayFixedBottom={overlayFixedBottom || false}
          >
            {typeof content === "function" ? content(hide) : content}
            {!overlayFixedBottom && (
              <StyledTooltipArrow
                className={classNames(realDirection, { white })}
                style={{ ...arrowStyle }}
              />
            )}
          </StyledTooltipContent>
        </StyledTooltipWrapper>
      )}
    </StyledTooltip>
  );
}
