import { useEffect } from "react";

export default function useOutsideClickHandler(
  onClickOutside: () => void,
  ref: HTMLElement | null,
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref && !ref.contains(event.target as Node)) {
        onClickOutside();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClickOutside, ref]);
}
