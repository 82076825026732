import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";

interface MenuContextProps {
  elementExtraSettings: ExtraSettings;
  addElementPlusButton: (key: string, onClickCallback: () => void) => void;
  addElementPlusButtonOptions: (key: string, options: Options[]) => void;
  removeElementPlusButton: (key: string) => void;
  addElementSubComponent: (key: string, component: React.ReactNode) => void;
  removeElementSubComponent: (key: string) => void;
  hideAnchors: Record<string, boolean>;
  setHideAnchorForKey: (key: string, value: boolean) => void;
}

interface Options {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

interface ExtraSettings {
  [key: string]: {
    plusButtonCallback?: () => void;
    plusButtonOptionItems?: Options[];
    subComponent?: React.ReactNode;
  };
}

const MenuContext = createContext<MenuContextProps | null>(null);

export function ProvideMenu({ children }: { children: ReactNode }) {
  const banners = useProvideMenu();
  return (
    <MenuContext.Provider value={banners}>{children}</MenuContext.Provider>
  );
}

export const useMenu = () => {
  const context = useContext(MenuContext);
  if (context === null) {
    throw Error("Menu context not provided");
  }
  return context;
};

function useProvideMenu() {
  const [elementExtraSettings, setElementExtraSettings] =
    useState<ExtraSettings>({});

  const [hideAnchors, setHideAnchors] = useState<Record<string, boolean>>({});

  const addElementPlusButton = (key: string, onClickCallback: () => void) => {
    setElementExtraSettings((s) => ({
      ...s,
      [key]: { ...s[key], plusButtonCallback: onClickCallback },
    }));
  };

  const addElementPlusButtonOptions = (key: string, options: Options[]) => {
    setElementExtraSettings((s) => ({
      ...s,
      [key]: { ...s[key], plusButtonOptionItems: options },
    }));
  };

  const removeElementPlusButton = (key: string) => {
    setElementExtraSettings((s) => ({
      ...s,
      [key]: {
        ...s[key],
        plusButtonCallback: undefined,
        plusButtonOptionItems: undefined,
      },
    }));
  };

  const addElementSubComponent = (key: string, component: React.ReactNode) => {
    setElementExtraSettings((s) => ({
      ...s,
      [key]: { ...s[key], subComponent: component },
    }));
  };

  const removeElementSubComponent = (key: string) => {
    setElementExtraSettings((s) => ({
      ...s,
      [key]: { ...s[key], subComponent: undefined },
    }));
  };

  const setHideAnchorForKey = useCallback((key: string, value: boolean) => {
    setHideAnchors((s) => ({ ...s, [key]: value }));
  }, []);

  return {
    elementExtraSettings,
    addElementPlusButton,
    addElementPlusButtonOptions,
    removeElementPlusButton,
    addElementSubComponent,
    removeElementSubComponent,
    hideAnchors,
    setHideAnchorForKey,
  };
}
