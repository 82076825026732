import { Granularity } from "../utils/dateUtils";

import { createClient } from "./apiClient";

export type ScheduleTestTargets = "me" | "all";

export type ScheduleType = "ki" | "report" | "dashboard";

export interface ScheduleBlock {
  type: "KI" | "CustomReport";
  id: string | null;
  description?: string;
}

export interface ScheduleComparePeriod {
  previousPeriod: boolean;
  previousYear: boolean;
}

export interface Schedule {
  id?: number;
  title: string;
  type: ScheduleType;
  elements: string[];
  blocks: ScheduleBlock[];
  period: string;
  relativePeriodOption: { count: number; granularity: Granularity };
  frequency: {
    timezone: string;
    hour: number;
    every: "day" | "week" | "month";
    selectedDays: number[];
  };
  compare: boolean;
  comparePeriod: ScheduleComparePeriod;
  applyViews: boolean;
  customReportGranularity: Granularity | "none";
  viewItems: Array<{ viewId?: string; viewItemId?: string }>;
  medias: {
    slack: boolean;
    email: {
      activated: boolean;
      emails: string[];
      ccs?: string[];
      title: string;
      description: string;
    };
  };
}

export const EMPTY_SCHEDULE = (
  timezone?: string,
  email?: string,
): Schedule => ({
  title: "",
  type: "ki",
  elements: [],
  blocks: [{ type: "KI", id: null }],
  period: "yesterday",
  relativePeriodOption: {
    count: 10,
    granularity: "day",
  },
  frequency: {
    timezone: timezone || "Europe/Paris",
    hour: 6.5,
    every: "day",
    selectedDays: [0],
  },
  compare: false,
  comparePeriod: { previousPeriod: false, previousYear: false },
  customReportGranularity: "none",
  applyViews: false,
  viewItems: [{}],
  medias: {
    slack: true,
    email: {
      activated: true,
      emails: email ? [email] : [],
      title: "",
      description: "",
    },
  },
});

const client = createClient("views-service");
const clientEmail = createClient("email-service");

export const getSchedules = async (token: string) => {
  const result = await client
    .new()
    .get("/api/schedules")
    .auth(token)
    .fetch<{ data: { id: number; json_value: Schedule }[] }>();
  return result.error
    ? []
    : (result.data.map((i) => ({ ...i.json_value, id: i.id })) as Schedule[]);
};

export const testSchedule = async (
  token: string,
  target: ScheduleTestTargets,
  schedule: Schedule,
) => {
  const result = await clientEmail
    .new()
    .post(`/api/schedules/test`)
    .body({ json_value: schedule, target })
    .auth(token)
    .fetch();
  return result;
};

export const createSchedule = async (
  token: string,
  type: string,
  schedule: Schedule,
) => {
  const result = await client
    .new()
    .post("/api/schedules")
    .body({ type, json_value: schedule })
    .auth(token)
    .fetch<{ data: number }>();
  return result;
};

export const patchSchedule = async (
  token: string,
  type: string,
  schedule: Schedule,
  id: number,
) => {
  const result = await client
    .new()
    .patch("/api/schedules/" + id)
    .body({ type, json_value: schedule })
    .auth(token)
    .fetch();
  return result;
};

export const deleteSchedule = async (token: string, id: number) => {
  const result = await client
    .new()
    .delete("/api/schedules/" + id)
    .body({})
    .auth(token)
    .fetch();
  return result;
};
