import { ReactNode } from "react";

import { ConnectorIconNames, ConnectorIconWrapperList } from "../ConnectorIcon";
import { Flex } from "../Flex";
import { Tag } from "../Tag";
import { theme } from "../theme";
import { Text } from "../Typography";

import { StyledSetupConnectorCard } from "./SetupConnector.styled";

type ReactChildren<
  T,
  TWrapped = T extends string ? T : React.ReactElement<T>,
> = TWrapped | TWrapped[];

const SetupConnectorTitle = ({
  children,
}: {
  children: ReactChildren<string>;
}) => {
  return <>{children}</>;
};

const Body = ({ children }: { children: ReactNode }) => <>{children}</>;

const SetupConnector = ({
  title,
  description,
  status,
  icons,
}: {
  title: string;
  description: string;
  icons: ConnectorIconNames[];
  status: {
    current: number;
    max: number;
  };
}) => {
  const isAccomplished = status.current > 0;

  return (
    <StyledSetupConnectorCard
      isAccomplished={isAccomplished}
      style={{ flexGrow: 1 }}
    >
      <Flex justifyContent="space-between">
        <Text
          color={
            isAccomplished
              ? theme.customColors.categoryEmail
              : theme.colors.text100
          }
          variant="body14SemiBold"
          style={{ paddingTop: 24 }}
        >
          {title}
        </Text>
        <Flex
          alignItems="center"
          columnGap={8}
          style={{ maxHeight: 24, position: "absolute", right: 8, top: 8 }}
        >
          <ConnectorIconWrapperList size="large" max={4} names={icons} />
          <Tag
            size="regular"
            color={isAccomplished ? "successOnGreen" : "contrastOnGray"}
          >
            {status.current} / {status.max}
          </Tag>
        </Flex>
      </Flex>
      <Text
        color={theme.colors.text90}
        variant="body12Regular"
        style={{ paddingBottom: 24 }}
      >
        {description}
      </Text>
    </StyledSetupConnectorCard>
  );
};

export default Object.assign(SetupConnector, {
  Title: SetupConnectorTitle,
  Description: Body,
  Counter: Body,
});
