import styled, { css } from "styled-components";

export const StyledTabSelector = styled.div<{
  isSelected: boolean;
  background?: string;
  selectedBorderBottomColor: string;
  tabBorderRightColor?: string;
  tabBorderBottomColor?: string;
  forceWidth?: string;
  disabled?: boolean;
}>`
  display: flex;
  padding: 8px 18px 8px 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  ${({ disabled }) =>
    disabled
      ? ""
      : css`
          cursor: pointer;
        `};
  ${({ tabBorderRightColor }) =>
    tabBorderRightColor &&
    css`
      border-right: 1px solid ${tabBorderRightColor};
    `}
  ${({ tabBorderBottomColor }) =>
    tabBorderBottomColor &&
    css`
      border-bottom: 1px solid ${tabBorderBottomColor};
    `}
  ${({ background }) =>
    background &&
    css`
      &:hover {
        background: ${background};
      }
    `};
  ${({ forceWidth }) =>
    forceWidth &&
    css`
      width: ${forceWidth};
    `}
  ${({ isSelected, background, selectedBorderBottomColor }) =>
    isSelected === true
      ? css`
          background: ${background};
          border-bottom: 2px solid ${selectedBorderBottomColor};
        `
      : ""};
`;
