import classNames from "classnames";

import { legacyTheme } from "../legacyTheme";
import Radio from "../Radio/Radio";
import { ClassAndStyleProps, InteractionEvents } from "../shared";

import {
  StyledRadioSelect,
  StyledRadioSelectOption,
  StyledRadioSelectOptionInput,
  StyledRadioSelectOptionLabel,
  StyledRadioSelectOptionLabelHint,
  StyledRadioSelectOptionLabelMain,
  StyledRadioSelectOptionLabelSection,
} from "./RadioSelect.styled";

export type RadioSelectProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    name: string;
    label?: string;
    value: string;
    options: Array<{
      label: string;
      labelHint?: string;
      value: string | number;
    }>;
    onChange: (value: string) => void;
  };

export default function RadioSelect({
  name,
  label: mainLabel,
  value: selectedValue,
  options,
  onChange,
  className,
  style,
  ...interactionEvents
}: RadioSelectProps) {
  return (
    <StyledRadioSelect
      {...interactionEvents}
      className={classNames(className)}
      style={style}
    >
      {mainLabel}
      {options.map(({ label, value, labelHint }) => (
        <StyledRadioSelectOption>
          <StyledRadioSelectOptionInput
            id={`${name}:${value}`}
            value={value}
            type="radio"
            checked={value === selectedValue}
            onChange={(e) => onChange(e.target.value)}
          />
          <StyledRadioSelectOptionLabel htmlFor={`${name}:${value}`}>
            <Radio />
            <StyledRadioSelectOptionLabelSection>
              <StyledRadioSelectOptionLabelMain
                variant="BodyRegularBold"
                lineHeight="18px"
              >
                {label}
              </StyledRadioSelectOptionLabelMain>

              {labelHint && (
                <StyledRadioSelectOptionLabelHint
                  fontWeight={legacyTheme.typography.fontWeigth.w400}
                  fontSize={legacyTheme.typography.fontSizes.regular}
                  lineHeight="15px"
                  color={legacyTheme.colors.grey6}
                >
                  {labelHint}
                </StyledRadioSelectOptionLabelHint>
              )}
            </StyledRadioSelectOptionLabelSection>
          </StyledRadioSelectOptionLabel>
        </StyledRadioSelectOption>
      ))}
    </StyledRadioSelect>
  );
}
