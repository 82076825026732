import { useCallback, useEffect, useState } from "react";

const useMouse = (pauseStatus = false) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [paused, setPaused] = useState(pauseStatus);

  const updateMousePosition = useCallback(
    (ev: MouseEvent) => {
      if (!paused) {
        setMousePosition({ x: ev.clientX, y: ev.clientY });
      }
    },
    [paused],
  );

  const pause = () => setPaused(true);

  const restart = () => setPaused(false);

  useEffect(() => {
    window.addEventListener("mousemove", updateMousePosition);

    return () => window.removeEventListener("mousemove", updateMousePosition);
  }, [updateMousePosition]);

  return { ...mousePosition, pause, restart };
};

export default useMouse;
