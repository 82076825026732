import * as d3 from "d3";
import { useEffect, useRef } from "react";

import useMouse from "../../hooks/useMouse";

import { usePosition } from "./PositionProvider";

interface EventReceiverProps {
  width: number;
  height: number;
  margin: [number, number, number, number];
  totalNumberOfSeries: number;
  minIndex?: number;
  maxIndex: number;
}

const EventReceiver = ({
  width,
  height,
  margin,
  minIndex = 0,
  maxIndex,
  totalNumberOfSeries,
}: EventReceiverProps) => {
  const position = usePosition();
  const { mousePosition } = useMouse();

  const eventReceiverRef = useRef<SVGRectElement>(null);

  useEffect(() => {
    if (eventReceiverRef.current === null || !position.isEnabled) {
      return;
    }

    const xLineScale = d3
      .scaleLinear()
      .domain([margin[3], width - margin[1]])
      .range([minIndex, maxIndex]);

    const xBarScale = d3
      .scaleBand<number>()
      .domain(d3.range(maxIndex))
      .range([margin[3], width - margin[1]]);

    const rect = eventReceiverRef.current.getBoundingClientRect();
    const xIndex = Math.floor(xLineScale((mousePosition.x || 0) - rect.left));

    if (xIndex < 0 || xIndex >= maxIndex) {
      return;
    }

    position.setIndex(xIndex);

    if (totalNumberOfSeries) {
      const barWidth = xBarScale.bandwidth() / totalNumberOfSeries;
      position.setX(
        parseFloat(`${xBarScale(xIndex)}`) +
          (barWidth * totalNumberOfSeries) / 2,
      );
    } else {
      position.setX(parseFloat(`${xBarScale(xIndex)}`));
    }
  }, [
    eventReceiverRef,
    mousePosition,
    margin,
    width,
    position,
    minIndex,
    maxIndex,
    totalNumberOfSeries,
  ]);

  return (
    <g>
      <rect
        ref={eventReceiverRef}
        width={width}
        height={height}
        fill="transparent"
        style={{ pointerEvents: "none" }}
      />
    </g>
  );
};

export default EventReceiver;
