import styled from "styled-components";

import { Flex, Text, theme } from "../../../icecube-ux";

interface AddonDetailsCardProps {
  title: string;
  paragraph?: string;
  span?: number;
  customContent?: React.ReactNode;
}

const StyledAddonDetailsCard = styled(Flex)<{ span: number }>`
  background: ${theme.colors.bgLight20};
  padding: 12px;
  grid-column: ${({ span }) => `span ${span}`};
  border-radius: ${theme.borderRadius.r6px};
`;

export const AddonDetailsCard = ({
  title,
  paragraph,
  customContent,
  span = 1,
}: AddonDetailsCardProps) => (
  <StyledAddonDetailsCard flexDirection="column" span={span}>
    <Text
      variant="body13Medium"
      color={theme.colors.text110}
      style={{ marginBottom: "4px" }}
    >
      {title}
    </Text>
    {customContent ? (
      customContent
    ) : (
      <Text variant="body12Regular" color={theme.colors.text90}>
        {paragraph}
      </Text>
    )}
  </StyledAddonDetailsCard>
);
