import { useMemo } from "react";

import { ListOptions } from "../List/List";

export interface GroupedListValues {
  [key: string]: ListOptions[];
}

export default function useGroupedList(
  search: string,
  selected: string[],
  opened: boolean,
  groupedList: GroupedListValues,
) {
  const filledSubcategory = Object.entries(groupedList)
    .map(([_, values]) => values.length)
    .filter((d) => d > 0).length;

  const calculateSelectedCount = () => {
    const groups = Object.keys(groupedList)
      .map((k) => groupedList[k])
      .flat()
      .map((m) => m.value);
    return selected.filter((s) => groups.includes(s)).length;
  };

  const selectedList = useMemo(
    () =>
      Object.entries(groupedList)
        .map(([_, groups]) =>
          groups.filter((m) => selected.includes(m.value as string)),
        )
        .flat(),
    [groupedList, selected],
  );

  const selectedCount = calculateSelectedCount();
  const isOpened = opened || search !== "";

  return {
    isNull: filledSubcategory === 0 && search !== "",
    selectedCount,
    isOpened,
    filledSubcategory,
    selectedList,
  };
}
