import * as d3 from "d3";
import { memo, useCallback, useMemo, useState } from "react";
import styled, { keyframes } from "styled-components";

import {
  AreaDataPoint,
  DataPoint,
  hashSeries,
  isAreaDataPoint,
  Serie,
  SerieFocusState,
} from "./Serie";

type LineProps<T extends DataPoint> = {
  serie: Serie<T>;
  width: number;
  height: number;
  margin: [number, number, number, number];
  totalNumberOfLines: number;
  yScale: d3.ScaleLinear<number, number>;
  barWidth: number;
  xLineScale: d3.ScaleLinear<number, number>;
  onChangeFocusState?: (serieName: string, focusState: SerieFocusState) => void;
  withArea?: boolean;
};

const linePath = d3.line().curve(d3.curveMonotoneX);

type FilledLineArgs = {
  barWidth: number;
  totalNumberOfLines: number;
  yScale: d3.ScaleLinear<number, number>;
  xLineScale: d3.ScaleLinear<number, number>;
};
const buildFilledLineFn = ({
  barWidth,
  totalNumberOfLines,
  xLineScale,
  yScale,
}: FilledLineArgs) => {
  return linePath
    .x((_, i) => xLineScale(i) + (barWidth * totalNumberOfLines) / 2)
    .y(([_, value]) => yScale(value));
};

type LineArgs = {
  width: number;
  height: number;
  margin: [number, number, number, number];
  barWidth: number;
  totalNumberOfLines: number;
  xLineScale: d3.ScaleLinear<number, number, never>;
  yScale: d3.ScaleLinear<number, number>;
};
const buildLineFn = ({
  xLineScale,
  width,
  height,
  margin,
  barWidth,
  totalNumberOfLines,
  yScale,
}: LineArgs) => {
  return linePath
    .x((_, i) => {
      return i === 0
        ? width - margin[1] - +(barWidth * totalNumberOfLines) / 2
        : i === 1
        ? margin[3] + (barWidth * totalNumberOfLines) / 2
        : xLineScale(i - 2) + (barWidth * totalNumberOfLines) / 2;
    })
    .y(([_, value], i) => {
      if (i <= 1) {
        return height - margin[2];
      }

      return yScale(value);
    });
};

type FadeInArgs = {
  widthFrom: number;
  widthTo: number;
};

const fadeIn = ({ widthFrom, widthTo }: FadeInArgs) => keyframes`
  from {
    width: ${widthFrom};
  }
  to {
    width: ${widthTo}px;
  }
`;

const AnimatedRect = styled.rect<FadeInArgs>`
  animation: ${fadeIn} 1s forwards;
`;

const buildDashArray = (totalLength: number) => {
  const dashing = "6, 6";
  const dashLength = dashing
    .split(/[\s,]/)
    .map(function (a) {
      return parseFloat(a) || 0;
    })
    .reduce(function (a, b) {
      return a + b;
    });
  const dashCount = Math.ceil((totalLength ?? 0) / dashLength);
  const newDashes = new Array(dashCount).join(dashing + " ");
  return newDashes + " 0, " + totalLength;
};

const buildAreaFn = ({
  barWidth,
  totalNumberOfLines,
  xLineScale,
  yScale,
}: FilledLineArgs) => {
  return d3
    .area<AreaDataPoint>()
    .curve(d3.curveMonotoneX)
    .x((_, i) => {
      return xLineScale(i) + (barWidth * totalNumberOfLines) / 2;
    })
    .y0((d) => yScale(d.yUpper))
    .y1((d) => yScale(d.yLower));
};

const Line = memo<LineProps<DataPoint>>(
  function Line<T extends DataPoint>({
    serie,
    width,
    height,
    margin,
    totalNumberOfLines,
    yScale,
    onChangeFocusState,
    withArea,
    barWidth,
    xLineScale,
  }: LineProps<T>) {
    const [lineTotalLength, setLineTotalLength] = useState(0);

    const measuredLengthRef = useCallback((node: SVGPathElement) => {
      if (node !== null) {
        setLineTotalLength(node.getTotalLength() ?? 0);
      }
    }, []);

    const isRegularLine = serie.fillColor && !serie.withAreaRange;

    const lineFn = isRegularLine
      ? buildLineFn({
          xLineScale,
          width,
          height,
          margin,
          barWidth,
          totalNumberOfLines,
          yScale,
        })
      : buildFilledLineFn({ barWidth, totalNumberOfLines, yScale, xLineScale });

    const areaFn = buildAreaFn({
      barWidth,
      totalNumberOfLines,
      yScale,
      xLineScale,
    });

    const clipId = useMemo(() => {
      const uuid = Math.random().toString(36).slice(2);
      return encodeURI(`mask-line-${uuid}`);
    }, []);

    const patchedValues = isRegularLine
      ? [0, 0, ...serie.values]
      : serie.values;

    const lineSerie = patchedValues.map((value, i) => {
      if (isAreaDataPoint(value)) {
        return [i, value.y];
      }
      return [i, value];
    });

    const d = lineFn(lineSerie as [number, number][]) ?? undefined;

    const strokeWidth = serie.strokeWidth ?? 1;

    const color = serie.color;
    const fillColor =
      !serie.withAreaRange && serie.fillColor ? serie.fillColor : "none";
    return (
      <>
        {serie.withAreaRange ? (
          <>
            <path
              d={areaFn(serie.values as AreaDataPoint[]) ?? undefined}
              fill={serie.fillColor}
              fillOpacity={0.07}
              stroke="none"
              clipPath={`url(#${clipId})`}
            />
          </>
        ) : (
          <></>
        )}
        <path
          ref={measuredLengthRef}
          d={d}
          stroke={withArea ? "white" : color}
          fill={withArea ? color : fillColor}
          strokeWidth={strokeWidth}
          strokeOpacity={serie.focusState === "dimmed" ? 0.15 : 1}
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeDasharray={
            serie.dashed ? buildDashArray(lineTotalLength) : undefined
          }
          clipPath={`url(#${clipId})`}
        />
        {!!onChangeFocusState && (
          <path
            fill={"none"}
            d={d}
            stroke={serie.color}
            strokeOpacity={0}
            strokeWidth={10}
            onMouseLeave={() => {
              onChangeFocusState(serie.name, "normal");
            }}
            onMouseEnter={() => {
              onChangeFocusState(serie.name, "highlighted");
            }}
          />
        )}
        {serie.withCircles !== false &&
          !serie?.withAreaRange &&
          serie.values.map((value, index) => (
            <circle
              key={`point-${serie.name}-${index}`}
              style={{ cursor: "auto" }}
              strokeOpacity={serie.focusState === "dimmed" ? 0.3 : 1}
              fillOpacity={serie.focusState === "dimmed" ? 0.3 : 1}
              stroke={withArea ? "white" : color}
              fill={withArea ? color : "white"}
              strokeWidth={strokeWidth}
              r={withArea ? 4 : 3}
              clipPath={`url(#${clipId})`}
              cx={xLineScale(index) + (barWidth * totalNumberOfLines) / 2}
              cy={yScale(value as number)}
              onMouseLeave={() => {
                onChangeFocusState?.(serie.name, "normal");
              }}
              onMouseEnter={() => {
                onChangeFocusState?.(serie.name, "highlighted");
              }}
            />
          ))}
        <defs>
          <clipPath id={clipId}>
            <AnimatedRect
              x="0"
              y="0"
              height={height}
              widthFrom={0}
              widthTo={width}
            />
          </clipPath>
        </defs>
      </>
    );
  },
  ({ serie: prevSerie, ...prevProps }, { serie: nextSerie, ...nextProps }) => {
    const keysToCheck = ["name", "values", "color"];
    for (const key of Object.keys(nextProps)) {
      if (
        hashSeries([prevSerie], keysToCheck) !==
        hashSeries([nextSerie], keysToCheck)
      ) {
        return false;
      }

      if (
        prevProps[key as keyof typeof nextProps] !==
        nextProps[key as keyof typeof nextProps]
      ) {
        return false;
      }
    }

    return true;
  },
);

export default Line;
