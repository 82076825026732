import styled from "styled-components";

import { theme } from "../../../theme";

export const StyledTableBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 9px 12px 3px;
  margin: 0 -12px;
  border-top: 1px solid ${theme.colors.borderLight};
`;
