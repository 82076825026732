import { Fragment } from "react";

import { GroupDetail } from "../../../../components/Filters/GroupDetail";
import { OperatorSeparator } from "../../../../components/Filters/OperatorSeparator";
import { RuleDetail } from "../../../../components/Filters/RuleDetail";
import { RuleDimensionSelector } from "../../../../components/Filters/RuleDimensionSelector";
import { Loader } from "../../../../components/Loader/Loader";
import { useConnectorObservability } from "../../../../hooks/connectorObservability";
import { useCustomElementEditors } from "../../../../hooks/customElementEditors";
import { useMetricList } from "../../../../hooks/metricList";
import { Button, Flex, Grid, IconButton } from "../../../../icecube-ux";
import { _ } from "../../../../languages/helper";
import {
  CustomMetricDefinition,
  CustomMetricElement,
  CustomMetricRule,
  IMetricsList,
} from "../../../../types/synthesizer";
import {
  DimensionFilter,
  getDefaultRuleForDimension,
  isRuleGroup,
  Rule,
  RuleElement,
  RuleOperators,
  TypeOrArrayOfType,
} from "../../../../utils/filterUtils";
import { getMetricIcon } from "../../../../utils/metricUtils";
import {
  getMetricLabel,
  isCustomMetric,
  metricElementRulesCount,
} from "../utils";

import "./metric-filter-list-block.css";
import TableLogo from "./TableLogo";

interface MetricFilterListBlockProps {
  open: boolean;
  onToggleOpen: () => void;
  element: CustomMetricElement;
  metric: CustomMetricDefinition;
  metricList: IMetricsList;
  onFilterChange: (filters: TypeOrArrayOfType<CustomMetricRule>[]) => void;
  badgeIndex?: number;
  loading: boolean;
  dimensions: DimensionFilter[];
  dimensionsError?: string;
}

export default function MetricFilterListBlock({
  open,
  onToggleOpen,
  element,
  metricList,
  onFilterChange,
  badgeIndex,
  loading,
  dimensions,
  dimensionsError,
}: MetricFilterListBlockProps) {
  const customElementEditors = useCustomElementEditors();
  const { statuses } = useConnectorObservability();
  const { tableKeyToConnectorKey } = useMetricList();
  const icons = getMetricIcon(
    element.value,
    metricList,
    statuses,
    tableKeyToConnectorKey,
  );
  const icon = icons.length > 1 ? "Polar" : icons[0]; // Fallback
  const isCustom = isCustomMetric(element.value);
  const metricLabel = getMetricLabel(element.value, metricList);
  if (loading) {
    return (
      <div className="metric-filter-block">
        <Loader size={24} color={"#898eb8"} />
      </div>
    );
  }
  const getDefaultRule = (dimensionFilter?: DimensionFilter) => {
    const { operator, value } = dimensionFilter
      ? getDefaultRuleForDimension(dimensionFilter)
      : { operator: RuleOperators.is, value: [] };
    return {
      operator: operator,
      value: value,
      dimensionFilter: dimensionFilter ?? null,
    };
  };

  const filterDimensions = Object.fromEntries(
    dimensions.map((d) => [d.name, d]),
  );
  const mapMetricRuleToRule = (rule: CustomMetricRule): Rule => {
    return {
      operator: rule.operator,
      value: rule.values,
      dimensionFilter: filterDimensions[rule.dimension] ?? null,
    };
  };

  const mapMetricRuleElementToRuleElement = (
    rule: TypeOrArrayOfType<CustomMetricRule>,
  ): RuleElement => {
    if (isRuleGroup(rule)) {
      return rule.map(mapMetricRuleToRule);
    } else {
      return mapMetricRuleToRule(rule);
    }
  };

  const rules = element.filters.map(mapMetricRuleElementToRuleElement);

  const mapRuleToMetricRule = (rule: Rule): CustomMetricRule => {
    return {
      operator: rule.operator,
      values: rule.value,
      dimension: rule.dimensionFilter?.name ?? "",
    };
  };
  const mapRuleElementToMetricRuleElement = (
    rule: TypeOrArrayOfType<Rule>,
  ): TypeOrArrayOfType<CustomMetricRule> => {
    if (isRuleGroup(rule)) {
      return rule.map(mapRuleToMetricRule);
    } else {
      return mapRuleToMetricRule(rule);
    }
  };

  const updateRuleElement = (i: number, ruleElement: Rule | Rule[]) => {
    const newRules = rules.map((existingRuleElement, index) =>
      index === i
        ? mapRuleElementToMetricRuleElement(ruleElement)
        : mapRuleElementToMetricRuleElement(existingRuleElement),
    );

    onFilterChange(newRules);
  };

  const deleteRuleElement = (i: number) => {
    const newRules = rules
      .filter((_, index) => index !== i)
      .map(mapRuleElementToMetricRuleElement);
    onFilterChange(newRules);
  };

  const addRule = () => {
    const newRules = [...rules, getDefaultRule()].map(
      mapRuleElementToMetricRuleElement,
    );
    onFilterChange(newRules);
  };
  const addGroup = () => {
    const newRules = [...rules, [getDefaultRule()]].map(
      mapRuleElementToMetricRuleElement,
    );
    onFilterChange(newRules);
  };

  const rulesCount = metricElementRulesCount(element.filters);

  return (
    <div className="metric-filter-block">
      <Grid
        alignItems="center"
        gridTemplateColumns="auto 1fr auto auto"
        onClick={isCustom ? undefined : onToggleOpen}
        gap={8}
        style={{ cursor: "pointer" }}
      >
        <TableLogo icon={icon} badgeIndex={badgeIndex} />
        <div className="metric-name">{metricLabel}</div>
        {rulesCount > 0 ? (
          <div className="selected-count">{rulesCount}</div>
        ) : (
          <div />
        )}
        {isCustom ? (
          <IconButton
            name={"Edit"}
            onClick={() =>
              customElementEditors.editMetric(element.value.substring(7))
            }
          ></IconButton>
        ) : (
          <IconButton
            name={open ? "ArrowUp" : "ArrowDown"}
            size="large"
            color="default"
            onClick={onToggleOpen}
          />
        )}
      </Grid>
      {open && (
        <Flex className="content" flexDirection="column" gap={8}>
          {rules.map((ruleElement, index) => {
            return (
              <Fragment key={index}>
                {isRuleGroup(ruleElement) ? (
                  <GroupDetail
                    getDefaultRule={getDefaultRule}
                    index={index + 1}
                    ruleGroup={ruleElement}
                    onGroupChange={(rule) => updateRuleElement(index, rule)}
                    onGroupDelete={() => deleteRuleElement(index)}
                  >
                    {(
                      dimensionKey: string,
                      updateRule: (filterDimension: DimensionFilter) => void,
                    ) => (
                      <RuleDimensionSelector
                        possibleDimensions={dimensions}
                        dimensionKey={dimensionKey}
                        onDimensionChange={(newDimensionFilter) => {
                          updateRule(filterDimensions[newDimensionFilter]);
                        }}
                        error={dimensionsError}
                      />
                    )}
                  </GroupDetail>
                ) : (
                  <RuleDetail
                    rule={ruleElement}
                    index={index + 1}
                    onRuleChange={(rule) => updateRuleElement(index, rule)}
                    onRuleDelete={() => deleteRuleElement(index)}
                  >
                    <RuleDimensionSelector
                      possibleDimensions={dimensions}
                      dimensionKey={ruleElement.dimensionFilter?.name ?? ""}
                      onDimensionChange={(newDimensionFilter) => {
                        const dimensionFilter =
                          filterDimensions[newDimensionFilter] ?? null;
                        updateRuleElement(index, {
                          ...getDefaultRule(dimensionFilter),
                          dimensionFilter:
                            filterDimensions[newDimensionFilter] ?? null,
                        });
                      }}
                      error={dimensionsError}
                    />
                  </RuleDetail>
                )}
                {index !== rules.length - 1 && (
                  <OperatorSeparator
                    content={_`Or`}
                    withMargin
                  ></OperatorSeparator>
                )}
              </Fragment>
            );
          })}
          <Grid gap={8}>
            <Button color="tertiary" leftIcon="Add" onClick={addRule}>
              {_`Add rule`}
            </Button>
            <Button color="tertiary" leftIcon="Add" onClick={addGroup}>
              {_`Add group`}
            </Button>
          </Grid>
        </Flex>
      )}
    </div>
  );
}
