import styled, { css } from "styled-components";

import { Chip } from "../Chip";
import { theme } from "../theme";

export const StyledChip = styled(Chip)<{ isDisabled: boolean }>`
  ${({ isDisabled }) =>
    isDisabled
      ? css`
          border: 1px solid ${theme.colors.borderLight};
          background-color: ${theme.colors.white100};
        `
      : css`
          border: "none";
        `}
`;

export const StyledLegend = styled.aside<{
  isWrapping: boolean;
  withTopBorder: boolean;
  withHorizontalPadding: boolean;
}>`
  box-sizing: border-box;
  background-color: ${theme.colors.white100};
  overflow-y: auto;

  display: flex;
  ${({ isWrapping }) =>
    isWrapping
      ? css`
          flex-direction: column;
        `
      : ""}

  ${({ withTopBorder }) =>
    withTopBorder
      ? css`
          box-shadow: 0px -1px 0px #eae9ff;
        `
      : ""}

  ${({ withHorizontalPadding }) =>
    withHorizontalPadding
      ? css`
          padding: 12px 20px;
        `
      : css`
          padding: 12px 0px;
        `}



  gap: 16px 0px;
`;

export const StyledLegendSection = styled.section<{ isWrapping: boolean }>`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  gap: 4px;

  ${({ isWrapping }) =>
    isWrapping
      ? ""
      : css`
          border-left: 1px solid ${theme.colors.borderMedium};
          padding-left: 16px;
          padding-right: 16px;

          &:first-child {
            border-left: none;
            border-left: 1px solid transparent;
            padding-left: 0px;
          }

          &:last-child {
            padding-right: 0px;
          }
        `}
`;

export const StyledLegendSectionList = styled.ul`
  all: unset;
  box-sizing: border-box;

  flex-wrap: wrap;
  display: flex;
  gap: 8px;
`;

export const StyledLegendSectionListItem = styled.li`
  all: unset;
  box-sizing: border-box;

  display: flex;
  gap: 8px;
`;

export const StyledLegendChipTitleList = styled.ul`
  all: unset;
  box-sizing: border-box;
  display: inline;

  text-overflow: ellipsis;
  overflow-x: hidden;

  max-width: 234px;
  text-align: center;
`;

export const StyledLegendChipTitleListItem = styled.li`
  all: unset;
  display: inline;
  box-sizing: border-box;

  padding-left: 4px;
  padding-right: 4px;
  border-left: 1px solid ${theme.colors.borderMedium};

  &:first-child {
    border-left: none;
    padding-left: 0px;
  }

  &:last-child {
    padding-right: 0px;
  }
`;
