import React from "react";
import styled from "styled-components";

import {
  AddonType,
  SUBSCRIPTION_TYPES,
  SUBSCRIPTION_TYPE_NAMES,
} from "../../common/types/subscription-service";
import { useBootstrap } from "../../hooks/bootstrap";
import { useTenantAddOns } from "../../hooks/useAddonStatuses";
import {
  useAddonInfoRedirect,
  useGMVTierBasedPricing,
} from "../../hooks/useAddonUtils";
import {
  Button,
  Flex,
  Icon,
  IconButton,
  Popup,
  Text,
  theme,
} from "../../icecube-ux";
import { _ } from "../../languages/helper";
import { openIntercom } from "../../utils/intercomUtils";
import {
  isFreeOrTrialPlan,
  isSubscriptionActive,
} from "../../utils/subscriptionsUtils";
import { AddonCard } from "../Plans/analyzeEnrichPlan/AddonCard";
import {
  AddonIcon,
  AddonIconNames,
} from "../Plans/analyzeEnrichPlan/AddonIcon";
import {
  PopupSubscribeSection,
  PopupUpgradeSection,
} from "../Plans/analyzeEnrichPlan/AddonPopupSubscriptionSection";

interface AddonPopupProps {
  onClose: (e: React.MouseEvent) => void;
  addonDetailsContent?: React.ReactNode;
  addonName?: "Capi" | "IntradayRefresh" | "PolarPixel";
  iconName: "Capi" | "IntradayRefresh" | "PolarPixel" | "Addons";
  headerTitle: string;
  headerSubtitle: string;
  headerIconColor: string;
  bodyTitle: string;
  onClickAddon?: (selectedAddon: AddonType) => void;
  loading?: boolean;
  customLeftBody?: React.ReactNode;
  customRightBody?: React.ReactNode;
  bodyMaxHeight?: React.CSSProperties["maxHeight"];
  disableAnimation?: boolean;
}

interface AddonPopupHeaderProps {
  title: string;
  subtitle: string;
  iconName: AddonIconNames | "Addons";
  iconBackgroundColor: string;
  showBookACall: boolean;
  onClose: (e: React.MouseEvent) => void;
}

interface AddonPopupBodyProps {
  onClickAddon?: (addonName: AddonType) => void;
  addonDetailsContent: React.ReactNode;
  bodyTitle: string;
  showSubscribeContent: boolean;
  addonName?: "Capi" | "IntradayRefresh" | "PolarPixel";
}

const StyledLeftBody = styled(Flex)`
  padding: 16px 12px;
  gap: 16px;
  border-right: 1px solid ${theme.colors.borderLight};
  flex-grow: 1;
  overflow-y: scroll;
`;

const StyledRightBody = styled(Flex)`
  padding: 16px;
  max-width: 300px;
  min-width: 300px;
`;

const StyledAddonPopupHeader = styled(Flex)`
  padding: 16px;
  gap: 16px;
`;

const StyledSeparator = styled.div`
  background: ${theme.colors.borderLight};
  height: 1px;
  width: 100%;
`;

const StyledIconButton = styled(IconButton)`
  align-self: center;
  margin-right: 8px;
  &:hover:enabled {
    background: none;
  }
  ${Icon} {
    width: 22px;
    height: 22px;
  }
`;

const StyledAddonIconWrapper = styled.div<{ $backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${theme.borderRadius.r8px};
  background: ${({ $backgroundColor }) => $backgroundColor};
  width: 48px;
  height: 48px;
`;

const AddonPopupHeader = ({
  title,
  subtitle,
  iconName,
  iconBackgroundColor,
  showBookACall = false,
  onClose,
}: AddonPopupHeaderProps) => (
  <StyledAddonPopupHeader>
    <StyledAddonIconWrapper $backgroundColor={iconBackgroundColor}>
      <AddonIcon name={iconName} size={26} />
    </StyledAddonIconWrapper>
    <Flex flexDirection="column" style={{ flexGrow: 1 }}>
      <Text variant="body18Medium" color={theme.colors.text110}>
        {title}
      </Text>
      <Text
        variant="body12Regular"
        color={theme.colors.primary80}
        style={{ maxWidth: "900px" }}
      >
        {subtitle}
      </Text>
    </Flex>
    <Flex gap={16} alignItems={"center"}>
      {showBookACall && (
        <Button
          block={true}
          onClick={() => {
            openIntercom();
          }}
          color="primary"
        >
          {_`Book a call to unlock`}
        </Button>
      )}
      <StyledIconButton
        color="transparent"
        name="Close"
        size="xxl"
        onClick={onClose}
      />
    </Flex>
  </StyledAddonPopupHeader>
);

const AddonPopupBody = ({
  bodyTitle,
  onClickAddon,
  addonDetailsContent,
  showSubscribeContent,
  addonName,
}: AddonPopupBodyProps) => {
  const { handleSeeAllPlans } = useAddonInfoRedirect();
  const { isHourlyRefreshEnabled, isCapiEnabled, isPolarPixelEnabled } =
    useTenantAddOns();

  const displayPolarPixelInOtherFeatures =
    !isPolarPixelEnabled && addonName !== "PolarPixel";
  const displayIntradayRefreshInOtherFeatures =
    !isHourlyRefreshEnabled && addonName !== "IntradayRefresh";
  const displayCapiInOtherFeatures = !isCapiEnabled && addonName !== "Capi";
  const hideOtherFeatures =
    !displayPolarPixelInOtherFeatures &&
    !displayIntradayRefreshInOtherFeatures &&
    !displayCapiInOtherFeatures;
  return (
    <>
      <Text variant="body14Medium" color={theme.colors.text110}>
        {bodyTitle}
      </Text>

      {addonDetailsContent}
      {!hideOtherFeatures && (
        <>
          <StyledSeparator />
          <Flex alignItems="center">
            <Text variant="body14Medium">{_`Other features`}</Text>
            <Icon
              name="VerifiedFilled"
              color={theme.colors.success70}
              size={18}
              style={{ marginLeft: "4px" }}
            />
            {showSubscribeContent && (
              <Button
                onClick={handleSeeAllPlans}
                color="tertiary"
                size="small"
                style={{ marginLeft: "auto" }}
              >
                {" "}
                {_`See pricing plans`}
              </Button>
            )}
          </Flex>
          <Flex gap={12}>
            {displayPolarPixelInOtherFeatures && (
              <AddonCard
                onClick={() => onClickAddon?.("polar_pixel")}
                iconBackground={theme.customColors.pixelBg}
                title={_`Polar Pixel`}
                subtitle={_`Discover the Power of the Polar Pixel & First-party Attribution.`}
                paragraph={_`Drive your marketing ROI to new heights by pinpointing successful campaigns, eliminating duplicate conversions, and supercharging your ad algorithm training with clear, actionable data.`}
                iconName="PolarPixel"
              />
            )}
            {displayIntradayRefreshInOtherFeatures && (
              <AddonCard
                onClick={() => onClickAddon?.("hourly_refresh")}
                iconBackground={theme.customColors.menuConnectorsBg}
                title={_`Intraday refresh`}
                subtitle={_`Elevate Your Shopify Performance with Polar's Intraday Refresh.`}
                paragraph={_`Unlock the potential of real-time data with continuous syncs throughout the day, and respond to consumer behavior instantly.`}
                iconName="IntradayRefresh"
              />
            )}
            {displayCapiInOtherFeatures && (
              <AddonCard
                onClick={() => onClickAddon?.("capi")}
                iconBackground={theme.customColors.capiBg}
                title={_`Conversion API Enhancer`}
                subtitle={_`Boost your ads with Polar’s conversion API Enhancer`}
                paragraph={_`Maximize your ad platform conversion tracking so they never miss a conversion again and enhance algorithm performance with zero change in ad spend.`}
                iconName="Capi"
              />
            )}
          </Flex>
        </>
      )}
    </>
  );
};

const TEMPORARILY_BLOCK_SELF_UPGRADE = true;

export default function AddonPopup({
  onClose,
  addonDetailsContent,
  addonName,
  headerTitle,
  headerSubtitle,
  headerIconColor,
  bodyTitle,
  onClickAddon,
  iconName,
  customLeftBody,
  customRightBody,
  bodyMaxHeight = "670px",
  disableAnimation = false,
}: AddonPopupProps) {
  const { subscription } = useBootstrap();
  const { isLoading: gmvLoading, data: gmvTierBasedPricing } =
    useGMVTierBasedPricing();
  const isFreeOrTrial = isFreeOrTrialPlan(subscription);
  const isActive = isSubscriptionActive(subscription);
  const showSubscribeContent = isFreeOrTrial || !isActive;

  const plan =
    gmvTierBasedPricing &&
    Object.values(gmvTierBasedPricing?.plans).find(
      (plan) => plan.plan === SUBSCRIPTION_TYPES.USAGE_BASED_PLUS_ACTIVATE_PLAN,
    );
  const tier = plan?.tiers.find(
    (tier) => tier.threshold === gmvTierBasedPricing?.recommendedThreshold ?? 0,
  );
  const currentTier =
    gmvTierBasedPricing &&
    Object.values(gmvTierBasedPricing?.plans)
      .find((plan) => plan.id === gmvTierBasedPricing.currentTier?.wrapperId)
      ?.tiers.find(
        (tier) =>
          tier.tierIndex === gmvTierBasedPricing.currentTier?.tierIndex ?? 0,
      );

  return (
    <Popup
      style={{ maxWidth: "1050px", whiteSpace: "normal" }}
      onClose={onClose}
      withScroll={false}
      withPadding={false}
      disableAnimation={disableAnimation}
      headerComponent={
        <AddonPopupHeader
          onClose={onClose}
          title={headerTitle}
          subtitle={headerSubtitle}
          iconName={iconName}
          iconBackgroundColor={headerIconColor}
          showBookACall={TEMPORARILY_BLOCK_SELF_UPGRADE}
        />
      }
    >
      <StyledSeparator />
      <Flex
        style={{
          minHeight: "300px",
          maxHeight: bodyMaxHeight,
        }}
      >
        <StyledLeftBody flexDirection="column">
          {customLeftBody ? (
            customLeftBody
          ) : (
            <AddonPopupBody
              bodyTitle={bodyTitle}
              showSubscribeContent={showSubscribeContent}
              addonName={addonName}
              addonDetailsContent={addonDetailsContent}
              onClickAddon={onClickAddon}
            />
          )}
        </StyledLeftBody>
        {customRightBody}
        {!TEMPORARILY_BLOCK_SELF_UPGRADE && (
          <StyledRightBody>
            {customRightBody ? (
              customRightBody
            ) : showSubscribeContent ? (
              <PopupSubscribeSection
                plan={SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLUS_ACTIVATE_PLAN}
                loading={gmvLoading}
                price={tier?.price ?? 400}
                tierIndex={tier?.tierIndex}
                tierWrapperId={plan?.id}
              />
            ) : (
              <PopupUpgradeSection
                curPrice={currentTier?.price ?? 300}
                newPrice={tier?.price ?? 400}
                loading={gmvLoading}
                plan={SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLUS_ACTIVATE_PLAN}
                tierIndex={tier?.tierIndex}
                tierWrapperId={plan?.id}
              />
            )}
          </StyledRightBody>
        )}
      </Flex>
    </Popup>
  );
}
