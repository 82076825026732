import { Flex, Button } from "../../../../icecube-ux";
import { ConfigurationButtonSectionProps } from "../../../../integrations/integration";

export const ConnectorConfigureOnlyButton = ({
  status,
  onConfigure,
  disabled,
}: ConfigurationButtonSectionProps) => {
  if (status === "empty") {
    return <></>;
  }
  return (
    <Flex gap={12} style={{ marginTop: "auto" }}>
      <Button
        size="small"
        color="ghost"
        onClick={onConfigure}
        disabled={disabled}
        block
      >
        {"Configure"}
      </Button>
    </Flex>
  );
};
