import classNames from "classnames";
import React, { forwardRef } from "react";

import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { ResponsiveAttribute, getResponsiveCSSFromProps } from "../utils";

import { StyledFlex } from "./Flex.styled";

export type FlexProps = InteractionEvents<HTMLDivElement> &
  ClassAndStyleProps & {
    children?: React.ReactNode;
    asHTMLTag?: string;
    display?: ResponsiveAttribute<"flex" | "inline-flex">;
    flexDirection?: ResponsiveAttribute<
      "row" | "row-reverse" | "column" | "column-reverse"
    >;
    flexWrap?: ResponsiveAttribute<"nowrap" | "wrap" | "wrap-reverse">;
    gap?: ResponsiveAttribute<number>;
    rowGap?: ResponsiveAttribute<number>;
    columnGap?: ResponsiveAttribute<number>;
    fullWidth?: ResponsiveAttribute<boolean>;
    justifyContent?: ResponsiveAttribute<
      | "flex-start"
      | "flex-end"
      | "center"
      | "space-between"
      | "space-around"
      | "space-evenly"
      | "start"
      | "end"
      | "left"
      | "right"
    >;
    alignItems?: ResponsiveAttribute<
      | "stretch"
      | "flex-start"
      | "flex-end"
      | "center"
      | "baseline"
      | "first baseline"
      | "last baseline"
      | "start"
      | "end"
      | "self-start"
      | "self-end"
    >;
    alignContent?: ResponsiveAttribute<
      | "flex-start"
      | "flex-end"
      | "center"
      | "space-between"
      | "space-around"
      | "space-evenly"
      | "stretch"
      | "start"
      | "end"
      | "baseline"
      | "first baseline"
      | "last baseline"
    >;
  };

const Flex = forwardRef<HTMLDivElement, FlexProps>(
  (
    {
      className,
      style,
      children,
      asHTMLTag = "div",
      display = "flex",
      flexDirection = "row",
      flexWrap,
      gap,
      rowGap,
      columnGap,
      fullWidth = false,
      justifyContent,
      alignContent,
      alignItems,
      ...interactionEvents
    }: FlexProps,
    ref,
  ) => {
    return (
      <StyledFlex
        ref={ref}
        {...interactionEvents}
        className={classNames(className)}
        style={{ ...style, ...{ width: fullWidth ? "100%" : style?.width } }}
        responsiveCss={getResponsiveCSSFromProps({
          display,
          flexDirection,
          flexWrap,
          gap,
          rowGap,
          columnGap,
          justifyContent,
          alignContent,
          alignItems,
        })}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
        as={asHTMLTag as any}
      >
        {children}
      </StyledFlex>
    );
  },
);

export default Flex;
