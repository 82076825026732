import { useEffect, useState } from "react";
import styled from "styled-components";

import { Button, Flex, theme } from "../../../icecube-ux";
import { _ } from "../../../languages/helper";

interface CustomMetricFooterProps {
  onMetricSave: () => Promise<void>;
  onMetricDiscardChanges: () => void;
  onClose: () => void;
  loading: boolean;
  editing: boolean;
  empty: boolean;
}

const CustomMetricFormFooter = styled.div`
  background: white;
  border-radius: 0 0 12px 12px;
  text-align: right;
  display: grid;
  grid-template-columns: 220px 1fr;
`;
export default function CustomMetricFooter({
  onMetricDiscardChanges,
  onMetricSave,
  onClose,
  loading,
  editing,
  empty,
}: CustomMetricFooterProps) {
  const defaultButtonString = editing ? _`Save metric` : _`Save as metric`;
  const savingString = _`Saving...`;
  const savedString = _`Saved`;
  const [buttonString, setButtonString] = useState<string>(defaultButtonString);
  useEffect(() => {
    if (loading) {
      setButtonString(savingString);
    } else {
      if (buttonString === savingString) {
        setTimeout(() => {
          setButtonString(savedString);
        }, 300);
        setTimeout(() => {
          setButtonString(defaultButtonString);
        }, 1300);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);
  return (
    <CustomMetricFormFooter>
      <Flex
        justifyContent="space-between"
        flexDirection="row"
        alignItems="center"
        style={{
          borderRight: `1px solid ${theme.colors.borderLight}`,
          padding: "8px 88px 8px 8px",
        }}
      >
        <Flex gap={8} flexDirection="row">
          <Button disabled={loading} color="tertiary" onClick={onClose} ctaSize>
            {_`Close`}
          </Button>
        </Flex>
      </Flex>
      <Flex
        justifyContent="space-between"
        style={{
          padding: "8px 88px 8px 8px",
          display: empty ? "none" : "flex",
        }}
      >
        {editing ? (
          <Button
            disabled={loading}
            color="error"
            onClick={onMetricDiscardChanges}
            ctaSize
          >
            {_`Discard changes`}
          </Button>
        ) : (
          <div />
        )}
        <Button
          disabled={loading}
          color="primary"
          leftIcon={buttonString === savedString ? "Check" : undefined}
          onClick={() => void onMetricSave()}
          ctaSize
        >
          {buttonString}
        </Button>
      </Flex>
    </CustomMetricFormFooter>
  );
}
