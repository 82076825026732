import { useBootstrap } from "../../hooks/bootstrap";
import { useFormatters } from "../../hooks/useFormatters";
import {
  ConnectorIcon,
  Flex,
  isValidConnectorIcon,
  Text,
  theme,
} from "../../icecube-ux";
import { oldImageNamesMapping } from "../../icecube-ux/utils";
import { MetricDescription } from "../../types/synthesizer";
import {
  getTooltipElements,
  getTooltipOrderSettingsElements,
} from "../../utils/chartUtils";

const MetricDescriptionText = ({
  description,
}: {
  description: MetricDescription[];
}) => {
  const { getUserTenantSetting } = useBootstrap();
  const { currencyFormatter } = useFormatters();

  return (
    <Flex alignItems="center" gap={4} flexWrap="wrap">
      {description
        .map((e) =>
          e.isOrderSettings
            ? getTooltipOrderSettingsElements(
                getUserTenantSetting,
                currencyFormatter,
              )
            : e.isRevenueComputation
            ? getTooltipElements(
                getUserTenantSetting,
                e.revenueComputationOptions,
              )
            : e,
        )
        .flat()
        .map((element, i) => {
          const textColor = element.disabled
            ? theme.colors.text80
            : theme.colors.text100;
          return (
            <span
              key={`formula-tooltip-${i}`}
              style={{
                width: i > 0 && element.operator === "" ? "100%" : undefined,
              }}
              className={element.block === true ? "display-block" : undefined}
            >
              {element.openParenthesis && "("}
              {i > 0 && element.operator !== "" && (
                <Text color={textColor} inline>
                  {element.operator}
                </Text>
              )}{" "}
              <span style={{ lineHeight: "20px" }}>
                {element.image &&
                  (Array.isArray(element.image)
                    ? element.image
                    : [element.image]
                  )
                    .filter((i) => i !== "")
                    .map((img, i2) => (
                      <ConnectorIcon
                        key={`img-${img}-${i2}`}
                        disabled={element.disabled}
                        style={{
                          display: "inline-block",
                          verticalAlign: "middle",
                        }}
                        name={
                          isValidConnectorIcon(img)
                            ? img
                            : oldImageNamesMapping[`/img/connectors/${img}.png`]
                        }
                      />
                    ))}{" "}
                <Text color={textColor} inline>
                  {element.label}
                </Text>
              </span>{" "}
              {element.closeParenthesis && ")"}
            </span>
          );
        })}
    </Flex>
  );
};

export default MetricDescriptionText;
