import moment from "moment";
import { useEffect, useState } from "react";

import { useSmartFilter } from "../../../hooks/useSmartFilter";
import { KeyIndicatorGoal } from "../../../icecube-ux";
import { KIGoalSettings } from "../../../lib/kiSectionsService";
import {
  getDayCountInGranularity,
  getDayCountInRangeType,
  Granularity,
} from "../../../utils/dateUtils";

type UseGoalSettingsArgs = {
  goal?: KIGoalSettings;
  ratio?: boolean;
  percentage?: boolean;
  granularity?: Granularity;
  current?: number;
  formatter?: (value: number | string) => string | number;
};

export const useGoalSettings = ({
  goal,
  ratio,
  percentage,
  granularity,
  current,
  formatter,
}: UseGoalSettingsArgs) => {
  const smartFilter = useSmartFilter();

  const [goalSettings, setGoalSettings] = useState<
    KeyIndicatorGoal | undefined
  >();

  useEffect(() => {
    if (!goal) {
      if (goalSettings !== undefined) {
        setGoalSettings(undefined);
      }
      return;
    }

    const absolute = ratio || percentage || goal.frequency === "all time";
    const dayCount =
      moment(smartFilter.range.end).diff(smartFilter.range.start, "day") + 1;
    const frequencyDayCount = getDayCountInRangeType(goal.frequency);
    const perDayGoalValue = goal.value / frequencyDayCount;
    const proratedGoalValue = absolute
      ? goal.value
      : perDayGoalValue * dayCount;
    const proratedChartValue = absolute
      ? goal.value
      : perDayGoalValue *
        (granularity ? getDayCountInGranularity(granularity) : dayCount);
    const fixedProratedGoalValue = percentage
      ? proratedGoalValue / 100
      : proratedGoalValue;
    const fixedProratedChartValue = percentage
      ? proratedChartValue / 100
      : proratedChartValue;
    const progression = Math.round(
      ((current as number) / fixedProratedGoalValue) * 100,
    );

    const newGoalSettings: KeyIndicatorGoal = {
      value: fixedProratedGoalValue,
      chartValue: fixedProratedChartValue,
      valueString: formatter
        ? formatter(fixedProratedGoalValue).toString()
        : String(fixedProratedGoalValue),
      progression,
      warning: goal.success === "lower" ? progression > 100 : progression < 100,
      prorated: !absolute,
    };

    if (
      !goalSettings ||
      Object.keys(goalSettings).some((k) => {
        const key = k as keyof KeyIndicatorGoal;

        // Workaround for strange comparison behavior.
        return String(goalSettings[key]) !== String(newGoalSettings[key]);
      })
    ) {
      setGoalSettings(newGoalSettings);
    }
  }, [
    formatter,
    goal,
    granularity,
    percentage,
    ratio,
    current,
    smartFilter.range,
    goalSettings,
  ]);

  return { goalSettings };
};
