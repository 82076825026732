import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { resetViewEditor } from "../../components/CountryFilters/views/ViewProvider";
import { useAuth } from "../../hooks/auth/auth";
import { useBootstrap } from "../../hooks/bootstrap";
import { useCallBooking } from "../../hooks/useCallBooking";
import { Box, Flex, Logo, Text, theme } from "../../icecube-ux";
import { _ } from "../../languages/helper";
import { TRACKING_EVENTS, trackEvent } from "../../utils/trackingUtils";

import {
  MobileOnboardingLandingStylesLogoutButton,
  MobileOnboardingLandingStylesMainButton,
} from "./MobileOnboardingLanding.styles";

const Highlight = ({ children }: { children: React.ReactNode }) => (
  <Text
    variant="body15Regular"
    color={theme.colors.success40}
    inline
    style={{
      background:
        theme.customColors.darkLandingPageMainContentHighlightBackground,
      padding: "0 2px",
    }}
  >
    {children}
  </Text>
);

export function MobileOnboardingLanding() {
  const { tenant } = useBootstrap();
  const auth = useAuth();
  const navigate = useNavigate();
  const { openSetupCallBooking, isLoading: isCallBookingModalLoading } =
    useCallBooking();

  const hasBookedCall = tenant?.states?.hasBookedCall;

  return (
    <Box
      height="100vh"
      minHeight={hasBookedCall ? "420px" : "500px"}
      backgroundColor={theme.customColors.darkLandingPageBackground}
      backgroundImage="url('img/dark-gradient-background-nb-mobile.svg')"
      position="relative"
      backgroundSize="cover"
      backgroundPositionX="right"
      backgroundPositionY="bottom"
    >
      <Helmet>
        <title>Polar Analytics</title>
      </Helmet>
      <Box
        position="absolute"
        top={0}
        padding={32}
        left="50%"
        transform="translate(-50%, 0)"
      >
        <Logo variant="light" height={70} />
      </Box>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%", padding: "0 20px" }}
      >
        <Text
          variant="body34SemiBold"
          color={theme.colors.white100}
          textAlign="center"
        >
          {_`Welcome to`}
          <br />
          {_`Polar Analytics`}!
        </Text>
        <Text
          variant="body15Regular"
          color={theme.customColors.darkLandingPageMainContentText}
          textAlign="center"
          style={{ marginTop: 24, maxWidth: "470px" }}
        >
          {hasBookedCall ? (
            <>
              {_`Continue on desktop to`}{" "}
              <Highlight>
                {_`connect your data sources|||dependent clause`}
              </Highlight>{" "}
              {_`and`}{" "}
              <Highlight>{_`start exploring|||dependent clause`}</Highlight>
            </>
          ) : (
            <>
              <Highlight>{_`Activate your data`}</Highlight>{" "}
              {_`with a 15 min integration call with our specialists`}, {_`and`}{" "}
              <Highlight>{_`continue on desktop`}</Highlight>{" "}
              {_`to start exploring`}
            </>
          )}
        </Text>
        {!hasBookedCall && (
          <MobileOnboardingLandingStylesMainButton
            style={{ marginTop: 36 }}
            onClick={() => {
              void openSetupCallBooking();
              trackEvent(TRACKING_EVENTS.BOOK_A_CALL_BUTTON_CLICKED, {
                buttonContext: "mobile-onboarding-landing",
              });
            }}
            loading={isCallBookingModalLoading}
          >
            {_`Book integration call`}
          </MobileOnboardingLandingStylesMainButton>
        )}
      </Flex>
      <MobileOnboardingLandingStylesLogoutButton
        rightIcon="Logout"
        onClick={() => {
          trackEvent(TRACKING_EVENTS.LOGOUT_LINK_CLICKED, {
            tenant_id: tenant?.id || "N/A",
          });
          auth.logout(resetViewEditor);
          navigate("/");
        }}
        block
      >
        {_`Logout`}
      </MobileOnboardingLandingStylesLogoutButton>
    </Box>
  );
}
