import { ReactNode, createContext, useContext, useState } from "react";

interface BannersContextProps {
  show: () => void;
  hide: () => void;
  isShown: boolean;
}

const BannersContext = createContext<BannersContextProps | null>(null);

export function ProvideBanners({ children }: { children: ReactNode }) {
  const banners = useProvideBanners();
  return (
    <BannersContext.Provider value={banners}>
      {children}
    </BannersContext.Provider>
  );
}

export const useBanners = () => {
  const context = useContext(BannersContext);
  if (context === null) {
    throw Error("Banner context not provided");
  }
  return context;
};

function useProvideBanners() {
  const [isShown, setIsShown] = useState(true);

  const show = () => setIsShown(true);
  const hide = () => setIsShown(false);

  return {
    show,
    hide,
    isShown,
  };
}
