import React from "react";

import { Grid } from "../../Grid";

import ReportTableBodyCells from "./ReportTableBodyCells";
import { ReportTableFooterColumn } from "./ReportTableFooterColumns";
import {
  ReportTableBodyLine,
  ReportTableCheckboxConfig,
  ReportTableHeaderColumn,
} from "./types";

type ReportTableBodyLinesProps = ReportTableCheckboxConfig & {
  lines: ReportTableBodyLine[];
  headers: ReportTableHeaderColumn[][];
  leftPart?: boolean;
  page: number;
  pageLength: number;
  loading?: boolean;
  loadingSecondary?: boolean;
  hoverAnchor: "top" | "bottom";
  readOnly?: boolean;
  footerColumns?: ReportTableFooterColumn[];
  cellSize?: "regular" | "big";
  gap?: number | string;
};
const ReportTableBodyLines = React.forwardRef<
  HTMLDivElement,
  ReportTableBodyLinesProps
>(
  (
    {
      maxCheckedRows,
      checkbox,
      checkedRows,
      onRowCheckboxChange,
      lines,
      headers,
      leftPart,
      page,
      pageLength,
      loading,
      loadingSecondary,
      hoverAnchor,
      readOnly,
      footerColumns,
      cellSize,
      gap = 4,
    },
    ref,
  ) => {
    const linesToDisplay = loading
      ? [
          {
            cells: [{ value: 0 }],
          },
        ]
      : lines.slice(page * pageLength, page * pageLength + pageLength);

    if (linesToDisplay.length === 1 && linesToDisplay[0].cells.length === 0) {
      linesToDisplay.pop();
    }
    if (linesToDisplay.length === 0) {
      return <div ref={ref} />;
    }
    const gridTemplateColumns =
      (checkbox ? "32px " : "") +
      (loading
        ? headers
            .at(-1)
            ?.map((line) =>
              line.forceWidth ? line.forceWidth : "minmax(144px, 1fr)",
            )
            .join(" ")
        : lines[0]?.cells
            .map((line) =>
              line.forceWidth ? line.forceWidth : "minmax(144px, 1fr)",
            )
            .join(" "));
    return (
      <Grid ref={ref} gridTemplateColumns={gridTemplateColumns} gap={gap}>
        {linesToDisplay.map((line, i) => (
          <ReportTableBodyCells
            maxCheckedRows={maxCheckedRows}
            checkbox={checkbox}
            checkedRows={checkedRows}
            onRowCheckboxChange={onRowCheckboxChange}
            key={`line-${i}`}
            line={line}
            headers={headers}
            lineIndex={i}
            leftPart={leftPart}
            loading={loading}
            loadingSecondary={loadingSecondary}
            hoverAnchor={hoverAnchor}
            readOnly={readOnly}
            footerColumns={footerColumns}
            cellSize={cellSize}
          />
        ))}
      </Grid>
    );
  },
);

export default ReportTableBodyLines;
