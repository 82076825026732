import React, { useImperativeHandle } from "react";

import { Box } from "../../Box";
import { Flex } from "../../Flex";
import { Grid } from "../../Grid";
import { Icon } from "../../Icon";
import { IconNames } from "../../Icon/Icon";
import { useSortableGrid } from "../../SortableGrid";
import { StyledSortableGridLabelDragged } from "../../SortableGrid/SortableGrid.styled";
import { theme } from "../../theme";

import ReportTableHeaderColumns from "./ReportTableHeaderColumns";
import { ReportTableHeaderColumn } from "./types";

type ReportTableHeaderLinesProps = {
  checkbox?: boolean;
  checkboxHeaderIcon?: IconNames;
  left: boolean;
  lines: ReportTableHeaderColumn[][];
  rightMargin: number;
  readOnly?: boolean;
  onReorderHeaderColumns: (
    values: Array<string | number>,
    from: number,
    to: number,
  ) => void;
  minimalColumnHeader?: boolean;
};

const ReportTableHeaderLines = React.forwardRef<
  HTMLDivElement,
  ReportTableHeaderLinesProps
>(
  (
    {
      checkbox,
      checkboxHeaderIcon,
      left,
      lines,
      rightMargin,
      readOnly,
      onReorderHeaderColumns,
      minimalColumnHeader,
    },
    ref,
  ) => {
    const boxRef = React.useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => {
      return boxRef.current as HTMLDivElement;
    });

    const { x, y, handleMouseDown, movingIndex, toIndex } = useSortableGrid({
      ref: boxRef,
      seriesLength: lines?.[0]?.length || 0,
      options: lines?.[0]?.map((c) => ({ value: c.key, label: c.label })) || [],
      onSort: onReorderHeaderColumns,
    });

    if (lines.length === 1 && lines[0].length === 0) {
      lines.pop();
    }

    if (lines.length === 0) {
      return <Box ref={boxRef} userSelect="none"></Box>;
    }

    const gridTemplateColumns = `${checkbox ? "32px " : ""}${lines
      .at(-1)
      ?.map((line) =>
        line.forceWidth ? line.forceWidth : "minmax(144px, 1fr)",
      )
      .join(" ")} ${rightMargin > 0 ? `${rightMargin}px` : ""}`;
    return (
      <Box
        display="grid"
        gridTemplateRows="subgrid"
        ref={boxRef}
        userSelect="none"
        gridRowEnd={`span ${lines.length}`}
      >
        <Grid
          gridTemplateColumns={gridTemplateColumns}
          gridTemplateRows="subgrid"
          style={{ gridRowEnd: `span ${lines.length}` }}
          gap={4}
        >
          {checkbox ? (
            <Flex
              flexDirection={"column"}
              style={{
                background: theme.colors.bgLight20,
                border: `1px solid ${theme.colors.borderLight}`,
                borderRadius: "2px 0px 0px 2px",
              }}
              justifyContent={"flex-end"}
              alignItems={"flex-start"}
            >
              {checkboxHeaderIcon ? (
                <Flex
                  justifyContent={"center"}
                  alignItems={"center"}
                  style={{
                    padding: "4px 4px 4px 8px",
                  }}
                >
                  <Icon
                    name={checkboxHeaderIcon}
                    color={theme.colors.text100}
                  />
                </Flex>
              ) : (
                <></>
              )}
            </Flex>
          ) : (
            <></>
          )}
          {lines.map((headerLine, i) => (
            <ReportTableHeaderColumns
              checkbox={checkbox}
              key={`line-${i}`}
              left={left}
              lineIndex={i}
              columns={headerLine}
              startDragEvent={handleMouseDown}
              dragStatus={{ movingIndex, toIndex }}
              readOnly={readOnly}
              minimalColumnHeader={minimalColumnHeader}
            />
          ))}
          {rightMargin > 0 && <div />}

          {movingIndex !== null && (
            <StyledSortableGridLabelDragged
              style={{ left: `${x}px`, top: `${y}px`, background: "#f0f2f6" }}
              leftComponents={[<Icon name="Dragger" />]}
            >
              {lines?.[0]?.[movingIndex].label || ""}
            </StyledSortableGridLabelDragged>
          )}
        </Grid>
      </Box>
    );
  },
);

export default ReportTableHeaderLines;
