import { theme } from "../theme";

export const colorStylesMap = {
  primary: {
    root: {
      color: theme.colors.white100,
      backgroundColor: theme.colors.primary100,
    },
    hover: {
      backgroundColor: theme.colors.primary110,
    },
  },
  secondary: {
    root: {
      color: theme.colors.primary100,
      backgroundColor: theme.colors.primary10,
    },
    hover: {
      backgroundColor: theme.colors.primary20,
    },
  },
  tertiary: {
    root: {
      color: theme.colors.text100,
      backgroundColor: theme.colors.bgLight10,
      borderColor: theme.colors.borderLight,
    },
    hover: {
      backgroundColor: theme.colors.bgLight20,
      borderColor: theme.colors.borderMedium,
    },
  },
  grey: {
    root: {
      color: theme.colors.text100,
      backgroundColor: theme.colors.grey50,
      borderColor: theme.colors.grey50,
    },
    hover: {
      backgroundColor: theme.colors.grey60,
      borderColor: theme.colors.grey60,
    },
  },
  error: {
    root: {
      color: theme.colors.text100,
      backgroundColor: theme.colors.bgLight10,
      borderColor: theme.colors.borderLight,
    },
    hover: {
      color: theme.colors.error100,
      backgroundColor: theme.colors.error10,
      borderColor: theme.colors.error20,
    },
  },
  errorAlways: {
    root: {
      color: theme.colors.error100,
      backgroundColor: theme.colors.error10,
      borderColor: theme.colors.error20,
    },
    hover: {
      color: theme.colors.error100,
      backgroundColor: theme.colors.error10,
      borderColor: theme.colors.error20,
    },
  },
  success: {
    root: {
      color: theme.colors.success100,
      backgroundColor: theme.colors.success10,
      borderColor: "none",
    },
    hover: {
      color: theme.colors.success100,
      backgroundColor: theme.colors.success10,
      borderColor: "none",
    },
  },
  ghost: {
    root: {
      color: theme.colors.text90,
      backgroundColor: theme.colors.white100,
      borderColor: theme.colors.borderLight,
    },
    hover: {
      color: theme.colors.text100,
      backgroundColor: theme.colors.bgLight10,
      borderColor: theme.colors.borderMedium,
    },
  },
  warning: {
    root: {
      color: theme.colors.warning100,
      backgroundColor: theme.colors.warning10,
      borderColor: theme.colors.warning20,
    },
    hover: {
      color: theme.colors.warning110,
      backgroundColor: theme.colors.warning20,
      borderColor: theme.colors.warning40,
    },
  },
  "primary-on-blue-background": {
    root: {
      color: theme.colors.primary100,
      backgroundColor: theme.colors.primary10,
    },
    hover: {
      backgroundColor: theme.colors.white100,
    },
  },
  "secondary-on-blue-background": {
    root: {
      color: theme.colors.white100,
      backgroundColor: theme.colors.white12,
    },
    hover: {
      backgroundColor: theme.colors.white22,
    },
  },
  "tertiary-on-blue-background": {
    root: {
      color: theme.colors.white100,
      backgroundColor: "transparent",
    },
    hover: {
      backgroundColor: theme.colors.white12,
    },
  },
  "warning-on-orange-background": {
    root: {
      color: theme.colors.warning100,
      backgroundColor: theme.colors.white100,
      borderColor: theme.colors.warning40,
    },
    hover: {
      backgroundColor: theme.colors.warning20,
      borderColor: theme.colors.warning40,
    },
  },
  "link-primary": {
    root: {
      color: theme.colors.white100,
      backgroundColor: theme.colors.primary100,
      fontWeight: theme.typography.fontWeight.semiBold,
    },
    hover: {
      backgroundColor: theme.colors.primary110,
    },
  },
  "link-secondary": {
    root: {
      color: theme.colors.primary100,
      backgroundColor: "transparent",
      fontWeight: theme.typography.fontWeight.semiBold,
      textDecoration: "underline",
      textDecorationColor: theme.colors.primary100,
    },
    hover: {
      backgroundColor: theme.colors.primary10,
      textDecorationColor: "transparent",
    },
  },
  "link-tertiary": {
    root: {
      color: theme.colors.text80,
      backgroundColor: "transparent",
      fontWeight: theme.typography.fontWeight.semiBold,
      textDecoration: "underline",
      textDecorationColor: theme.colors.text80,
    },
    hover: {
      color: theme.colors.text90,
      backgroundColor: theme.colors.bgLight20,
      borderColor: theme.colors.borderMedium,
      textDecorationColor: "transparent",
    },
  },
  "dropdown-tertiary": {
    root: {
      color: theme.colors.text90,
      backgroundColor: theme.colors.bgLight10,
      borderColor: theme.colors.borderLight,
    },
    hover: {
      backgroundColor: theme.colors.bgLight20,
      borderColor: theme.colors.borderMedium,
    },
  },
  transparent: {
    root: {
      color: theme.colors.text100,
      backgroundColor: "transparent",
      border: "none",
    },
    hover: {
      backgroundColor: theme.colors.bgLight20,
      borderColor: theme.colors.borderMedium,
    },
  },
} as const;
