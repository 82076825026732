import classNames from "classnames";

import { Icon } from "../Icon";
import { ClassAndStyleProps, InteractionEvents } from "../shared";

import { SortableGridHookProps, useSortableGrid } from "./SortableGrid.hook";
import {
  StyledSortableGrid,
  StyledSortableGridLabel,
  StyledSortableGridLabelDragged,
} from "./SortableGrid.styled";

type SortableGridProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps &
  SortableGridHookProps;

export default function SortableGrid({
  seriesLength,
  options,
  onSort,
  onRemove,
  className,
  style,
  ...interactionEvents
}: SortableGridProps) {
  const { x, y, ref, handleMouseDown, movingIndex, toIndex } = useSortableGrid({
    seriesLength,
    options,
    onSort,
  });

  return (
    <StyledSortableGrid
      ref={ref}
      {...interactionEvents}
      className={classNames(className)}
      style={style}
      gridTemplateColumns={`repeat(${seriesLength}, calc(${
        100 / seriesLength
      }% - 4px))`}
    >
      {options.map((option, i) => (
        <StyledSortableGridLabel
          key={option.value}
          leftComponents={[
            <Icon name="Dragger" onMouseDown={() => handleMouseDown(i)} />,
          ]}
          rightComponents={
            onRemove
              ? [
                  <Icon
                    name="CloseCircle"
                    onClick={() => onRemove(option.value)}
                  />,
                ]
              : []
          }
          className={classNames({
            moving: movingIndex === i,
            target: toIndex === i,
          })}
        >
          {option.label}
        </StyledSortableGridLabel>
      ))}

      {movingIndex !== null && (
        <StyledSortableGridLabelDragged
          style={{ left: `${x}px`, top: `${y}px` }}
          leftComponents={[<Icon name="Dragger" />]}
        >
          {options[movingIndex].label}
        </StyledSortableGridLabelDragged>
      )}
    </StyledSortableGrid>
  );
}
