import classNames from "classnames";
import React from "react";

import { ClassAndStyleProps, InteractionEvents } from "../shared";

import ConnectorIconWrapper from "./ConnectorIconWrapper";
import {
  StyledConnectorIconWrapperList,
  StyledConnectorIconWrapperListCounter,
  StyledWhiteSeparator,
} from "./ConnectorIconWrapperList.styled";
import { ConnectorIconNames } from "./types";

export type ConnectorIconWrapperListProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    names: ConnectorIconNames[];
    max?: number;
    size?: "tiny" | "small" | "large";
  };

export default function ConnectorIconWrapperListComponent({
  names,
  max = Infinity,
  className,
  style,
  size = "small",
  ...interactionEvents
}: ConnectorIconWrapperListProps) {
  const shortenNames = max < names.length ? names.slice(0, max - 1) : names;
  const showCounter = max < names.length;

  return (
    <StyledConnectorIconWrapperList
      {...interactionEvents}
      className={classNames(className)}
      size={size}
      style={style}
      showCounter={showCounter}
    >
      {showCounter && (
        <StyledConnectorIconWrapperListCounter
          style={{ zIndex: names.length * 2 + 2 }}
          size={size}
        >
          {names.length - max}+
        </StyledConnectorIconWrapperListCounter>
      )}
      {shortenNames.map((name, i) => (
        <React.Fragment key={`${name}-${i}`}>
          {(i > 0 || showCounter) && (
            <StyledWhiteSeparator
              size={size}
              style={{ zIndex: names.length * 2 - i * 2 + 1 }}
            />
          )}
          <ConnectorIconWrapper
            name={name}
            style={{ zIndex: names.length * 2 - i * 2 }}
            background="#fff"
            size={size}
          />
        </React.Fragment>
      ))}
    </StyledConnectorIconWrapperList>
  );
}
