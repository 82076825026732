import React from "react";

import { useTenantAddOns } from "../../hooks/useAddonStatuses";
import { _ } from "../../languages/helper";
import { trackEvent, TRACKING_EVENTS } from "../../utils/trackingUtils";
import { Box } from "../Box";
import { Button } from "../Button";
import { ConnectorIconNames } from "../ConnectorIcon";
import ConnectorIconWrapperList from "../ConnectorIcon/ConnectorIconWrapperList";
import { Flex } from "../Flex";
import { Grid } from "../Grid";
import { IconButton } from "../IconButton";
import { ExploreMetricLink } from "../KeyIndicatorCard/ExploreMetricLink";
import type { SyncingConnectors } from "../KeyIndicatorCard/SyncingConnectors";
import { SyncingStatusTag } from "../KeyIndicatorCard/SyncingStatusTag";
import { useNoDataReason } from "../KeyIndicatorCard/useNoDataReason";
import PercentChip from "../PercentChip";
import { theme } from "../theme";
import { Tooltip } from "../Tooltip";
import { Text } from "../Typography";

import {
  ChartContainer,
  StyledFocusedDate,
  StyledKeyIndicatorCardWithChart,
  StyledKeyIndicatorWithChartValues,
} from "./KeyIndicatorCardWithChart.styled";

export interface KeyIndicatorCardWithChartProps {
  indicatorKey: string;
  title: string;
  wasValue: string | undefined;
  evolution: string;
  value: string | undefined;
  icons: ConnectorIconNames[];
  negative?: boolean;
  invertedEvolution?: boolean;
  infoComponent?: React.ReactNode;
  loading?: boolean;
  hasNoDataForSelectedView?: boolean;
  loadingConnector?: string;
  notReadyConnector?: string;
  issueWithMetric?: string;
  missingConnectorLabel?: string;
  missingConnectorOnClick: () => void;
  syncingConnectors: SyncingConnectors;
  onEditGoal?: () => void;
  mobileVersion?: boolean;
  hasGoal?: boolean;
  focusedDate?: string;
  children: React.ReactNode;
  showExploreMetric?: boolean;
  isMetricExploreNew?: boolean;
  hideGoalButton?: boolean;
}

export const KeyIndicatorCardWithChart = React.memo(
  React.forwardRef<HTMLDivElement, KeyIndicatorCardWithChartProps>(
    // Defining this as a normal (not arrow) function to be able to name it.
    function KeyIndicatorCardWithChart(
      {
        indicatorKey,
        title,
        wasValue,
        evolution,
        value,
        icons,
        negative = false,
        invertedEvolution = false,
        loading = false,
        loadingConnector,
        notReadyConnector,
        missingConnectorLabel,
        issueWithMetric,
        missingConnectorOnClick,
        syncingConnectors,
        hasNoDataForSelectedView,
        infoComponent,
        hasGoal,
        onEditGoal: onEditGoalProp,
        focusedDate,
        mobileVersion,
        children,
        showExploreMetric,
        isMetricExploreNew,
      }: KeyIndicatorCardWithChartProps,
      ref,
    ) {
      const { isHourlyRefreshEnabled } = useTenantAddOns();
      const noDataReason = useNoDataReason({
        loadingConnector,
        notReadyConnector,
        missingConnectorLabel,
        missingConnectorOnClick,
        issueWithMetric,
        hasNoDataForSelectedView,
      });
      const canDisplayValue = !loading && !noDataReason;

      const iconComponent = icons?.filter((d) => d !== undefined).length >
        0 && (
        <Tooltip direction="top" content={icons.join(", ")}>
          <ConnectorIconWrapperList names={icons || []} max={4} />
        </Tooltip>
      );

      const onEditGoal = () => {
        if (onEditGoalProp) {
          trackEvent(TRACKING_EVENTS.TARGETS_STARTED);
          onEditGoalProp();
        }
      };

      return (
        <StyledKeyIndicatorCardWithChart
          ref={ref}
          data-cy="key-indicator-card-with-chart"
        >
          <Box flexGrow="1">
            <Flex flexDirection="row" justifyContent="space-between">
              <Flex flexDirection="row" gap={6}>
                {hasGoal && !mobileVersion && (
                  <IconButton
                    name="Target"
                    size="small"
                    color="blue"
                    onClick={onEditGoal}
                  />
                )}
                {!hasGoal && !mobileVersion && (
                  <Button
                    leftIcon="Target"
                    size="tiny"
                    color="secondary"
                    onClick={onEditGoal}
                  >
                    {_`Add target`}
                  </Button>
                )}
                {iconComponent}
                {!isHourlyRefreshEnabled && syncingConnectors.any && (
                  <SyncingStatusTag
                    syncingConnectors={syncingConnectors}
                    context="ki-chart"
                  />
                )}
              </Flex>
              {canDisplayValue && wasValue !== undefined && (
                <Grid
                  gridTemplateColumns="auto 1fr"
                  alignItems="center"
                  gap={6}
                >
                  <Text
                    variant="body10Regular"
                    color={theme.colors.text80}
                    noWrap
                    ellipsis
                  >
                    {_`Was`} {wasValue}
                  </Text>
                  <PercentChip
                    negative={negative}
                    invertedEvolution={invertedEvolution}
                    evolution={evolution}
                    small
                  />
                </Grid>
              )}
            </Flex>
            <StyledKeyIndicatorWithChartValues
              showExploreLink={focusedDate !== undefined}
            >
              <Flex gap={4}>
                <Text
                  variant="body12Regular"
                  color={theme.colors.text90}
                  data-cy="key-indicator-card-title"
                  noWrap
                  ellipsis
                >
                  {title}
                </Text>
                {infoComponent}
              </Flex>
              <Box position="relative">
                {!canDisplayValue && (
                  <Text
                    variant="body11Regular"
                    color={theme.colors.text80}
                    noWrap
                    ellipsis
                  >
                    {noDataReason}
                  </Text>
                )}
                {canDisplayValue && value !== undefined && (
                  <Text
                    fontSize="16px"
                    lineHeight="24px"
                    fontWeight={600}
                    color={theme.colors.text110}
                    data-cy="key-indicator-card-value"
                    noWrap
                    ellipsis
                  >
                    {value}
                  </Text>
                )}
                <StyledFocusedDate>
                  <Text
                    variant="body10Regular"
                    color={theme.colors.text80}
                    noWrap
                    ellipsis
                  >
                    {focusedDate}
                  </Text>
                </StyledFocusedDate>
              </Box>
            </StyledKeyIndicatorWithChartValues>

            <ChartContainer>{children}</ChartContainer>
            {showExploreMetric && (
              <ExploreMetricLink
                metricKey={indicatorKey}
                isNew={isMetricExploreNew}
              />
            )}
          </Box>
        </StyledKeyIndicatorCardWithChart>
      );
    },
  ),
);
