import React from "react";

import { FocusEvents, InteractionEvents } from "../shared";
import { ResponsiveAttribute, getResponsiveCSSFromProps } from "../utils";

import { StyledBox } from "./Box.styled";

type BoxProps = InteractionEvents<HTMLDivElement> &
  FocusEvents<HTMLDivElement> &
  ResponsiveAttribute<React.CSSProperties> & {
    className?: string;
    children?: React.ReactNode;
  };

const Box = React.forwardRef<HTMLDivElement, BoxProps>(
  (
    {
      className,
      children,
      onClick,
      onMouseDown,
      onMouseUp,
      onMouseMove,
      onMouseEnter,
      onMouseLeave,
      onKeyDown,
      onKeyUp,
      onFocus,
      onBlur,
      onEnter,
      onScroll,
      ...style
    },
    ref,
  ) => {
    return (
      <StyledBox
        ref={ref}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onFocus={onFocus}
        onBlur={onBlur}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
        onScroll={onScroll}
        className={className}
        responsiveCss={getResponsiveCSSFromProps(style)}
      >
        {children}
      </StyledBox>
    );
  },
);

export default Box;
