import { CSSProperties } from "react";
import styled, { css } from "styled-components";

import { theme } from "../theme";

const sizeStyles = {
  small: css`
    border-radius: ${theme.borderRadius.r4px};

    height: 26px;
    width: 26px;
  `,
  nav: css`
    border-radius: ${theme.borderRadius.r8px};
    height: 32px;
    width: 32px;
  `,
  medium: css`
    border-radius: 5px;
    width: 36px;
    height: 36px;
  `,
  large: css`
    border-radius: ${theme.borderRadius.r8px};
    padding: 11px;
  `,
};

type BackgroundSize = keyof typeof sizeStyles;

const StyledBackground = styled.div<{
  background: string;
  size: BackgroundSize;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(p) => sizeStyles[p.size]}

  background-color: ${(p) => p.background};
`;

export default function EmojiIconBackground({
  style,
  className,
  background,
  size,
  children,
}: {
  style?: CSSProperties;
  className?: string;
  background: string;
  size: BackgroundSize;
  children: React.ReactNode;
}) {
  return (
    <StyledBackground
      style={style}
      className={className}
      background={background}
      size={size}
    >
      {children}
    </StyledBackground>
  );
}
