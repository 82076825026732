import { ReactNode } from "react";

import FullPageError from "../../components/Loader/FullPageError";
import FullPageLoader from "../../components/Loader/FullPageLoader";

import { useProvideAuth, AuthContext } from "./auth";

export function ProvideAuth({ children }: { children: ReactNode }) {
  const auth = useProvideAuth();
  return (
    <AuthContext.Provider value={auth}>
      {auth.processing && <FullPageLoader />}
      {auth.criticalFailure && <FullPageError />}
      {children}
    </AuthContext.Provider>
  );
}
