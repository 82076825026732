import { useEffect, useState } from "react";

import { OnboardingFormData } from "../../../common/types/users-service";
import { useBootstrap } from "../../../hooks/bootstrap";
import { useCallBooking } from "../../../hooks/useCallBooking";
import {
  Button,
  EmojiIcon,
  Flex,
  Loader,
  Logo,
  Space,
  Text,
  theme,
} from "../../../icecube-ux";
import useWindowSize from "../../../icecube-ux/hooks/useWindowSize";
import { _ } from "../../../languages/helper";
import {
  INTERCOM_ARTICLE_IDS,
  openIntercomArticle,
} from "../../../utils/intercomUtils";
import { onboardingQuestionKeys } from "../onboardingQuestions";

import {
  StyledOnboardingFormCallSlide,
  StyledOnboardingFormCallSlideActions,
  StyledOnboardingFormCallSlideBookingButtonWrapper,
  StyledOnboardingFormCallSlideCallPanel,
  StyledOnboardingFormCallSlideCallPanelEmbed,
  StyledOnboardingFormCallSlideFormPanel,
  StyledOnboardingFormCallSlideHeader,
  StyledOnboardingFormCallSlideHeaderTitleWrapper,
  StyledOnboardingFormCallSlideHeaderWrapper,
  StyledOnboardingFormLinkButton,
} from "./OnboardingFormCallSlide.styles";

interface OnboardingFormProps {
  onSubmit: (
    latestResponses?: OnboardingFormData["responses"],
  ) => Promise<void>;
  onboardingForm: OnboardingFormData;
}

function OnboardingFormCallSlide({
  onSubmit,
  onboardingForm,
}: OnboardingFormProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasLoadedEmbed, setHasLoadedEmbed] = useState(false);
  const { openSetupCallBooking, isLoading: isCallBookingLoading } =
    useCallBooking();
  const { forceRefresh, tenant } = useBootstrap();
  const { width } = useWindowSize();

  useEffect(() => {
    void forceRefresh();
    const asyncListener = async (e: MessageEvent) => {
      if ((e.data as { type?: string }).type === "MEETING_BOOKED") {
        setIsSubmitting(true);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        await onSubmit();
      }
    };
    const listener = (e: MessageEvent) => void asyncListener(e);
    window.addEventListener("message", listener);
    return () => window.removeEventListener("message", listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (width > 1024 && !hasLoadedEmbed) {
      void openSetupCallBooking({ embed: true, onboardingForm });
      setHasLoadedEmbed(true);
    }
  }, [
    width,
    setHasLoadedEmbed,
    hasLoadedEmbed,
    openSetupCallBooking,
    onboardingForm,
  ]);

  const isActivate =
    tenant?.settings?.onboarding_form?.responses?.[0].selection?.includes(
      "activate",
    );

  return (
    <StyledOnboardingFormCallSlide>
      <StyledOnboardingFormCallSlideFormPanel>
        <StyledOnboardingFormCallSlideHeaderWrapper>
          <StyledOnboardingFormCallSlideHeader>
            <Logo width={140} variant="colored" />
            <StyledOnboardingFormCallSlideHeaderTitleWrapper>
              <Text variant="body20Bold" color={theme.colors.text110}>
                {_`Get started with us!`}
              </Text>
            </StyledOnboardingFormCallSlideHeaderTitleWrapper>
            <Space size={24} />
            <Text variant="body16SemiBold" color={theme.colors.text110}>
              {isActivate
                ? _`15 minutes to walk you through the Klaviyo Enricher setup and the 30 days money back guarantee.`
                : _`15 minutes to activate your free trial account with our help`}
            </Text>
            <Space size={16} />
            <Text variant="body14Regular" color={theme.colors.text110}>
              {_`On the call we'll check your data together to make sure everything is 100% accurate.  We'll also answer any questions you might have.`}
            </Text>
            <Space size={40} />
            <Flex alignItems="center" gap={8}>
              <EmojiIcon name="Party" />
              <Text variant="body14Regular" color={theme.colors.text110}>
                {isActivate
                  ? _`After the call, you will start your 30 days money back guarantee trial.`
                  : _`After the call, you will be granted a 7 day free trial.`}
                {!isActivate && (
                  <>
                    <br />
                    {_`No credit card required!`}
                  </>
                )}
              </Text>
            </Flex>
            <Space size={24} />
            <StyledOnboardingFormCallSlideActions>
              <StyledOnboardingFormCallSlideBookingButtonWrapper>
                <Button
                  onClick={() =>
                    void openSetupCallBooking({ embed: false, onboardingForm })
                  }
                  size="xxlarge"
                  loading={isSubmitting || isCallBookingLoading}
                  style={{ maxWidth: "100%", minWidth: 304 }}
                >
                  {_`Get my spot now`}
                </Button>
                <Space size={24} />
              </StyledOnboardingFormCallSlideBookingButtonWrapper>
              <Button
                color="tertiary"
                onClick={() =>
                  openIntercomArticle(INTERCOM_ARTICLE_IDS.MANDATORY_CALL)
                }
                leftIcon="Documentation"
                size="large"
                style={{ maxWidth: "100%", minWidth: 304 }}
              >
                {_`Why do we require a call?`}
              </Button>
              <Space size={56} />
              <StyledOnboardingFormLinkButton
                disabled={isSubmitting}
                onClick={
                  isSubmitting
                    ? undefined
                    : () => {
                        setIsSubmitting(true);
                        void onSubmit(
                          onboardingForm.responses.map((response) =>
                            response.questionKey ===
                            onboardingQuestionKeys.callBooking
                              ? {
                                  ...response,
                                  skipped: true,
                                }
                              : response,
                          ),
                        );
                      }
                }
              >{_`I'll book the call later`}</StyledOnboardingFormLinkButton>
            </StyledOnboardingFormCallSlideActions>
          </StyledOnboardingFormCallSlideHeader>
        </StyledOnboardingFormCallSlideHeaderWrapper>
      </StyledOnboardingFormCallSlideFormPanel>
      <StyledOnboardingFormCallSlideCallPanel>
        <StyledOnboardingFormCallSlideCallPanelEmbed id="revenue-hero-embed">
          {isCallBookingLoading && <Loader />}
        </StyledOnboardingFormCallSlideCallPanelEmbed>
      </StyledOnboardingFormCallSlideCallPanel>
    </StyledOnboardingFormCallSlide>
  );
}

export default OnboardingFormCallSlide;
