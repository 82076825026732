import { ConnectorIconNames } from "../ConnectorIcon";

export const CHANNEL_NAME_TO_ICON_MAPPING_ICE_CUBE: {
  [key: string]: ConnectorIconNames;
} = {
  "Google Ads": "GoogleAds",
  "Bing Ads": "Bing",
  "Facebook Ads": "Facebook",
  "Pinterest Ads": "Pinterest",
  "Snapchat Ads": "Snapchat",
  "TikTok Ads": "Tiktok",
  Klaviyo: "Klaviyo",
  "Amazon Ads": "Amazon",
  Direct: "Direct",
  "Tiktok Ads": "Tiktok",
  Criteo: "Criteo",
  Instagram: "Instagram",
};
