import { useState } from "react";
import { NotificationManager } from "react-notifications";

import Tag from "../../components/Tag/Tag";
import { useAuth } from "../../hooks/auth/auth";
import { useModals } from "../../hooks/modals";
import { Button, Text, legacyTheme } from "../../icecube-ux";
import { _ } from "../../languages/helper";
import { requestStartPlan } from "../../lib/subscriptionsService";

import Plans from "./PackagePlans";

const PLANS = ["trial", "start", "growth", "plus", "free"];

interface PlansPopupProps {
  asPopup?: boolean;
}

export default function PackagePlansContainer({
  asPopup = true,
}: PlansPopupProps) {
  const auth = useAuth();
  const { showLoader, hideLoader, alert } = useModals();

  const [yearly, setYearly] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSelect = async (plan: number) => {
    setLoading(true);
    showLoader();
    try {
      const result = await requestStartPlan(
        await auth.getToken(),
        PLANS[plan],
        yearly,
      );
      if (result.reinstall) {
        hideLoader();
        setLoading(false);
        alert({
          title: _`Credentials expired`,
          texts: [
            _`Your Shopify credentials for the Polar Analytics app have expired.`,
            _`You need to uninstall and reinstall the Polar Analytics application to refresh your token.`,
            _`Please contact our support if you need guidance.`,
          ],
        });
        return;
      }
      window.document.location.href = result.url;
    } catch (e) {
      hideLoader();
      setLoading(false);
      console.error(e);
      NotificationManager.error(
        _`Please make sure you connected your Shopify account.`,
        _`Error`,
      );
    }
  };

  return (
    <>
      <div className={asPopup ? "sub-popup-grid" : ""}>
        {asPopup && (
          <img src="/img/logo-violet.svg" className="h-16" alt="Polar" />
        )}
        <div className="text-center mbx-30">
          <Text
            fontSize={legacyTheme.typography.fontSizes.x6large}
            fontWeight={legacyTheme.typography.fontWeigth.w600}
            lineHeight="36px"
            color="var(--primary3)"
            className="margin-bottom-large"
            style={{ letterSpacing: "-0.02em" }}
          >
            {_`Subscribe to Polar Analytics`}
          </Text>
          <Button
            color={!yearly ? "primary" : "tertiary"}
            block={false}
            className="mr-2"
            onClick={() => setYearly(false)}
          >
            {_`Monthly`}
          </Button>
          <Button
            color={yearly ? "primary" : "tertiary"}
            block={false}
            className="ml-2"
            onClick={() => setYearly(true)}
          >
            {_`Yearly`}
            <Tag>{_`Save 20%`}</Tag>
          </Button>
        </div>
        {asPopup && <img src="/img/billing_need.png" alt="" />}
      </div>
      <Plans
        yearly={yearly}
        onSelect={(plan) => {
          void handleSelect(plan);
        }}
        loading={loading}
      />
    </>
  );
}
