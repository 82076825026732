type QueryDispatcherArgs = {
  batchSize?: number;
};

type QueryDispatcherRequestStatus =
  | "init"
  | "running"
  | "waiting"
  | "failed"
  | "completed";

export const IMMEDIATE = "immediate";
export type QueryDispatcherPriority = number | typeof IMMEDIATE;

type DispatchMetadata = {
  dispatchId: string;
  onStatusUpdate?: (status: QueryDispatcherRequestStatus) => void;
};

type DispatchResponse<T> = {
  dispatchId: string;
  updatePriority: (newPriority: QueryDispatcherPriority) => void;
  response: Promise<T>;
};

export const createQueryDispatcher = ({
  batchSize = 5,
}: QueryDispatcherArgs = {}) => {
  let runningRequests: {
    [dispatchId: string]: {
      metadata: DispatchMetadata;
      response: Promise<unknown>;
    };
  } = {};
  let waitingRequests: {
    [dispatchId: string]: {
      metadata: DispatchMetadata;
      priority: number;
      startRequest: () => Promise<unknown>;
    };
  } = {};

  const startRunning = (waitingRequest: (typeof waitingRequests)[string]) => {
    delete waitingRequests[waitingRequest.metadata.dispatchId];
    runningRequests[waitingRequest.metadata.dispatchId] = {
      metadata: waitingRequest.metadata,
      response: waitingRequest.startRequest(),
    };
    waitingRequest.metadata.onStatusUpdate?.("running");
  };

  const markAsCompleted = (metadata: DispatchMetadata) => {
    delete runningRequests[metadata.dispatchId];

    if (
      Object.values(waitingRequests).length === 0 ||
      Object.values(runningRequests).length >= batchSize
    ) {
      return;
    }

    startRunning(
      Object.values(waitingRequests).reduce((mostImportantSoFar, current) => {
        if (current.priority < mostImportantSoFar.priority) {
          return current;
        }

        return mostImportantSoFar;
      }),
    );
  };

  const updatePriority = ({
    dispatchId,
    priority,
  }: {
    dispatchId: string;
    priority: QueryDispatcherPriority;
  }) => {
    const request = waitingRequests[dispatchId];

    if (!request) {
      return;
    }

    if (priority === IMMEDIATE) {
      startRunning(request);
      return;
    }

    request.priority = priority;
  };

  const purge = () => {
    waitingRequests = {};
    runningRequests = {};
  };

  /**
   * - Lower priorities get executed sooner.
   */
  const dispatch = <T>(
    fn: (...args: unknown[]) => Promise<T>,
    {
      priority,
      dispatchId = crypto.randomUUID(),
      onStatusUpdate,
    }: { priority: QueryDispatcherPriority } & Partial<
      Omit<DispatchMetadata, "priority">
    >,
  ): DispatchResponse<T> => {
    const metadata: DispatchMetadata = {
      dispatchId,
      onStatusUpdate,
    };

    const response = new Promise<T>((resolveDispatch, rejectDispatch) => {
      const startRequest = async () => {
        try {
          const result = await fn();
          metadata.onStatusUpdate?.("completed");
          resolveDispatch(result);
        } catch (e) {
          metadata.onStatusUpdate?.("failed");
          rejectDispatch(e);
        } finally {
          markAsCompleted(metadata);
        }
      };

      if (
        Object.keys(runningRequests).length < batchSize ||
        priority === IMMEDIATE
      ) {
        onStatusUpdate?.("running");
        runningRequests[metadata.dispatchId] = {
          metadata,
          response: startRequest(),
        };

        return;
      }

      onStatusUpdate?.("waiting");
      let updatedPriority = priority;
      if (priority < 1 || Math.floor(priority) !== priority) {
        console.warn("Rounding priority to the closest positive integer!");
        updatedPriority = Math.max(1, Math.floor(priority));
      }

      waitingRequests[metadata.dispatchId] = {
        priority: updatedPriority,
        metadata,
        startRequest,
      };
    });

    return {
      dispatchId,
      updatePriority: (newPriority) => {
        if (!newPriority) {
          return null;
        }

        updatePriority({ dispatchId, priority: newPriority });
      },
      response,
    };
  };

  return {
    runningRequests,
    waitingRequests,
    dispatch,
    purge,
  };
};

export const queryDispatcher = createQueryDispatcher();
