import * as d3 from "d3";
import { memo } from "react";

import Bar from "./Bar";
import { hashSeries, Serie, SerieFocusState } from "./Serie";

type SingleSerieAsBarsProps = {
  serie: Serie<number>;
  width: number;
  height: number;
  margin: [number, number, number, number];
  totalNumberOfBarSeries: number;
  yScale: d3.ScaleLinear<number, number>;
  barSerieIndex: number;
  maxIndex: number;
  onChangeFocusState?: (serieName: string, focusState: SerieFocusState) => void;
};

const SingleSerieAsBars = memo<SingleSerieAsBarsProps>(
  function SingleSerieAsBars({
    serie,
    margin,
    width,
    barSerieIndex,
    yScale,
    totalNumberOfBarSeries,
    maxIndex,
    onChangeFocusState,
  }: SingleSerieAsBarsProps) {
    const x = d3
      .scaleBand<number>()
      .domain(d3.range(maxIndex))
      .range([margin[3], width - margin[1]]);

    const barWidth = Math.max(0, x.bandwidth() / (totalNumberOfBarSeries + 1));

    return (
      <>
        {serie.values.map((value, barIndex) => {
          return (
            <Bar
              key={barIndex}
              serieName={serie.name}
              barWidth={barWidth}
              barHeight={yScale(value) - yScale(0)}
              barYPosition={yScale(0)}
              barXPosition={Math.floor(
                (x(barIndex) ?? 0) +
                  barWidth * barSerieIndex +
                  barWidth / 2 +
                  0.5,
              )}
              value={value}
              fill={
                serie.colorFunction
                  ? serie.colorFunction(value, barIndex)
                  : serie.color
              }
              focusState={serie?.focusState}
              onChangeFocusState={onChangeFocusState}
            />
          );
        })}
      </>
    );
  },
  ({ serie: prevSerie, ...prevProps }, { serie: nextSerie, ...nextProps }) => {
    const keysToCheck = ["name", "values"];
    for (const key of Object.keys(nextProps)) {
      if (
        hashSeries([prevSerie], keysToCheck) !==
        hashSeries([nextSerie], keysToCheck)
      ) {
        return false;
      }

      if (
        prevProps[key as keyof typeof nextProps] !==
        nextProps[key as keyof typeof nextProps]
      ) {
        return false;
      }
    }

    return true;
  },
);

export default SingleSerieAsBars;
