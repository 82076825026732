import { useEffect, useState } from "react";

import { FiltersIcon } from "../../../../components/Icons/Icons";
import { useAuth } from "../../../../hooks/auth/auth";
import { useModals } from "../../../../hooks/modals";
import useLocalStorage, {
  LOCAL_STORAGE_KEYS,
} from "../../../../hooks/useLocalStorage";
import { Flex } from "../../../../icecube-ux";
import { _ } from "../../../../languages/helper";
import { getFilters } from "../../../../lib/synthService";
import {
  CustomMetricDefinition,
  CustomMetricElement,
  CustomMetricRule,
  IMetricsList,
} from "../../../../types/synthesizer";
import {
  TypeOrArrayOfType,
  DimensionFilter,
} from "../../../../utils/filterUtils";
import { cloneObject } from "../../../../utils/utils";
import NoResult from "../../shared/NoResult";
import { getBadgeIndex, metricElementRulesCount } from "../utils";

import "./metric-filter-list.css";
import MetricFilterListBlock from "./MetricFilterListBlock";

interface MetricFilterListProps {
  open: number;
  onToggleOpen: (index: number) => void;
  metric: CustomMetricDefinition;
  metricList: IMetricsList;
  onChange: (metric: CustomMetricDefinition) => void;
}

export default function MetricFilterList({
  open,
  onToggleOpen,
  metric,
  metricList,
  onChange,
}: MetricFilterListProps) {
  const auth = useAuth();
  const { confirm } = useModals();

  const [loadings, setLoadings] = useState<{
    [metricKey: string]: boolean;
  }>({});
  const [dimensionsErrors, setDimensionsErrors] = useState<{
    [metricKey: string]: string;
  }>({});
  const [dimensions, setDimensions] = useLocalStorage<
    Record<string, DimensionFilter[] | null | undefined>
  >(LOCAL_STORAGE_KEYS.CUSTOM_METRIC_DIMENSIONS, {});

  const handleChangeElement = (element: CustomMetricElement, index: number) => {
    metric.elements[index] = cloneObject(element);
    onChange(metric);
  };

  const handleClearFilters = () => {
    confirm({
      danger: true,
      title: _`Clear filters`,
      texts: [_`Are you sure you want to delete all your filters?`],
      onConfirm: () => {
        metric.elements.forEach((m) => (m.filters = []));
        onChange(metric);
      },
    });
  };

  useEffect(() => {
    const fetch = async (key: string) => {
      setLoadings((l) => ({ ...l, [key]: true }));
      try {
        const data = await getFilters(
          new AbortController(),
          await auth.getToken(),
          [key],
          [],
          {},
        );
        if (data?.every((dimension) => !dimension.label)) {
          throw new Error("No labels for any dimensions");
        }
        setDimensions((l) => ({ ...l, [key]: data }));
      } catch (e) {
        setDimensionsErrors((l) => ({
          ...l,
          [key]: _`Failed to load dimensions`,
        }));
        setDimensions((l) => ({ ...l, [key]: undefined }));
        throw e;
      } finally {
        setLoadings((l) => ({ ...l, [key]: false }));
      }
    };

    metric.elements
      .filter((e) => e.type === "metric")
      .forEach((m) => {
        const dimensionsForMetric = dimensions[m.value];
        if (
          !dimensionsForMetric &&
          !loadings[m.value] &&
          !dimensionsErrors[m.value]
        ) {
          void fetch(m.value);
        }
      });
  }, [
    metric.elements,
    dimensions,
    setDimensions,
    loadings,
    dimensionsErrors,
    auth,
  ]);

  if (metric.elements.filter((e) => e.type === "metric").length === 0) {
    return (
      <NoResult
        title={_`Filter metrics`}
        text={_`Add a metric to define its filters`}
        image="no-filter.svg"
      />
    );
  }

  const totalRules = metric.elements
    .map((e) => metricElementRulesCount(e.filters))
    .reduce((p, v) => p + v, 0);

  const handleFilterChange = (
    element: CustomMetricElement,
    filters: TypeOrArrayOfType<CustomMetricRule>[],
    index: number,
  ) => {
    handleChangeElement(
      {
        ...element,
        filters,
      },
      index,
    );
  };

  return (
    <Flex className="metric-filter-list" flexDirection="column" gap={16}>
      {totalRules > 0 && (
        <div className="clear-filters" onClick={() => handleClearFilters()}>
          <FiltersIcon size={16} className="mrx-8" />
          {_`Clear filters`}
        </div>
      )}
      {metric.elements.map((m, i) =>
        m.type !== "metric" ? null : (
          <MetricFilterListBlock
            key={`m-${m.value}-${i}`}
            open={open === i}
            onToggleOpen={() => onToggleOpen(i)}
            metric={metric}
            metricList={metricList}
            element={m}
            onFilterChange={(v) => handleFilterChange(m, v, i)}
            badgeIndex={getBadgeIndex(metric, m.value, i)}
            loading={loadings[m.value] || false}
            dimensions={dimensions[m.value] ?? []}
            dimensionsError={dimensionsErrors[m.value]}
          />
        ),
      )}
    </Flex>
  );
}
