import styled, { css } from "styled-components";

import { StyledIcon } from "../Icon/Icon.styled";
import { legacyTheme } from "../legacyTheme";
import { theme } from "../theme";
import { typographyStylesMap } from "../Typography/typographyStylesMap";

const sizeProperties = {
  small: {
    padding: `0px ${legacyTheme.padding.small}`,
    fontWeight: legacyTheme.typography.fontWeigth.w400,
    fontSize: legacyTheme.typography.fontSizes.tiny,
    lineHeight: "12px",
  },
  medium: {
    padding: `${legacyTheme.padding.tiny} ${legacyTheme.padding.small}`,
    fontWeight: legacyTheme.typography.fontWeigth.w400,
    fontSize: "10px",
    lineHeight: "12px",
    height: "18px",
  },
  regular: {
    padding: `3px 7.5px`,
    ...typographyStylesMap.body11SemiBold,
  },
  large: {
    padding: `4px 8px`,
    ...typographyStylesMap.body13Medium,
  },
} as const;

export type TagColors =
  | "default"
  | "primary"
  | "primaryLight"
  | "warning"
  | "contrast"
  | "contrastOnGray"
  | "success"
  | "successOnGreen"
  | "primaryDarker"
  | "grey"
  | "blue"
  | "error"
  | "errorLight";

export const StyledTag = styled.span<{
  color: TagColors;
  size: "small" | "medium" | "regular" | "large";
  withBorder: boolean;
}>`
  display: inline-block;
  border-radius: ${legacyTheme.borderRadius.tiny};
  text-align: center;
  vertical-align: middle;
  letter-spacing: -0.04em;
  white-space: nowrap;
  border: 1px solid
    ${({ withBorder }) => (withBorder ? "#e2e4f4" : theme.colors.bgLight30)};

  transition:
    background 0.2s ease-in-out,
    color 0.2s ease-in-out;

  & ${StyledIcon} {
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 2px;
  }

  background: ${theme.colors.bgLight30};
  color: ${theme.colors.text80};

  ${({ color }) => {
    switch (color) {
      case "primary": {
        return css`
          background: ${theme.colors.primary20};
          color: ${theme.colors.primary100};
        `;
      }
      case "primaryLight": {
        return css`
          background: ${theme.colors.bgBody};
          color: ${theme.colors.primary70};
          border: solid 1px ${theme.colors.bgBody};
        `;
      }
      case "blue": {
        return css`
          background: ${theme.colors.primary30};
          color: ${theme.colors.primary100};
          border: solid 1px ${theme.colors.primary30};
        `;
      }
      case "success": {
        return css`
          background: ${theme.colors.success20};
          color: ${theme.colors.success100};
          border: solid 1px ${theme.colors.success20};
        `;
      }
      case "successOnGreen": {
        return css`
          background: ${theme.colors.success100};
          color: ${theme.colors.white100};
        `;
      }
      case "contrast": {
        return css`
          background: ${theme.colors.bgLight30};
          color: ${theme.colors.text90};
          border: solid 1px ${theme.colors.bgLight30};
        `;
      }
      case "contrastOnGray": {
        return css`
          background: ${theme.colors.grey50};
          color: ${theme.colors.text100};
        `;
      }
      case "primaryDarker": {
        return css`
          background: ${theme.colors.primary30};
          color: ${theme.colors.primary110};
          border: 1px solid ${theme.colors.primary30};
        `;
      }
      case "warning": {
        return css`
          background: ${theme.colors.warning30};
          color: ${theme.colors.warning100};
          border: solid 1px ${theme.colors.warning30};
        `;
      }
      case "error": {
        return css`
          background: ${theme.colors.error20};
          color: ${theme.colors.error110};
          border: solid 1px ${theme.colors.error20};
        `;
      }
      case "errorLight": {
        return css`
          background: ${theme.colors.error10};
          color: ${theme.colors.error100};
          border: solid 1px ${theme.colors.error10};
        `;
      }
      case "grey": {
        return css`
          background: ${theme.colors.bgLight20};
          color: ${theme.colors.text80};
          border: solid 1px ${theme.colors.bgLight20};
        `;
      }
    }
  }}

  ${({ size }) => size && sizeProperties[size]};
`;
