import { useBootstrap } from "./bootstrap";

export const useTenantAddOns = () => {
  const { subscription } = useBootstrap();

  const isCapiEnabled = subscription?.add_ons.includes("capi") ?? false;
  const isPolarPixelEnabled =
    subscription?.add_ons.includes("polar_pixel") ?? false;
  const isHourlyRefreshEnabled =
    subscription?.add_ons.includes("hourly_refresh") ?? false;

  return { isCapiEnabled, isPolarPixelEnabled, isHourlyRefreshEnabled };
};
