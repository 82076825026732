import { ReactNode } from "react";
import styled from "styled-components";

import useDropdown from "../../Dropdown/Dropdown.hooks";
import { Flex } from "../../Flex";
import { Icon } from "../../Icon";
import { Tag } from "../../Tag";

import {
  StyleButtonDropdownChildren,
  StyleDropdownChildren,
} from "./ReportTableHeaderColumns.styled";

const StyledReportTableHeaderColumnCellRightButtonWrapper = styled.div`
  cursor: pointer;
`;

export interface ReportTableHeaderColumnCellRightButtonProps {
  button: {
    label: string;
    dropdownContent?: ReactNode;
  };
}

const ReportTableHeaderColumnCellRightButton = ({
  button,
}: ReportTableHeaderColumnCellRightButtonProps) => {
  const { refCallback, open, handleClick, listPosition } = useDropdown({
    allowToggle: true,
  });

  return (
    <StyleButtonDropdownChildren ref={refCallback} onClick={handleClick}>
      <StyledReportTableHeaderColumnCellRightButtonWrapper>
        <Tag
          color="primary"
          style={{ paddingRight: button.dropdownContent ? 0 : "initial" }}
          size="small"
        >
          <Flex alignItems="center">
            {button.label}
            {button.dropdownContent ? (
              <Icon name="ArrowDown" style={{ marginBottom: -2 }} />
            ) : (
              <></>
            )}
          </Flex>
        </Tag>
      </StyledReportTableHeaderColumnCellRightButtonWrapper>
      {open && (
        <StyleDropdownChildren style={listPosition}>
          {button.dropdownContent}
        </StyleDropdownChildren>
      )}
    </StyleButtonDropdownChildren>
  );
};

export default ReportTableHeaderColumnCellRightButton;
