import moment from "moment";

import { PlanCard, PlanCardContainer } from "../../components/Card/PlanCard";
import { useBootstrap } from "../../hooks/bootstrap";
import { _ } from "../../languages/helper";
import { LIVE_DEMO_TENANT_ID } from "../../utils/demoConfig";

interface PlansProps {
  yearly?: boolean;
  loading?: boolean;
  onSelect?: (plan: number) => void;
}

export default function Plans({
  onSelect = () => {},
  yearly = false,
  loading = false,
}: PlansProps) {
  const { subscription, tenant } = useBootstrap();

  return (
    <PlanCardContainer>
      <PlanCard
        title={_`Start|||plan`}
        image="/img/bearfeet.svg"
        price={yearly ? "$119" : "$149"}
        granularity="month"
        line1={_`Up to 100,000 visits per month`}
        line2={_`15+ Polar Integrations`}
        item1={_`Daily refresh of data`}
        item2={`1 ${_`Store`}`}
        item3={_`Unlimited historical data`}
        item4={_`Unlimited users`}
        onClick={() => onSelect(1)}
        loading={loading}
        selected={
          subscription?.plan === 1 &&
          (subscription.valid_until === null ||
            moment(subscription.valid_until).isAfter(moment()))
        }
        disabled={
          tenant.id === LIVE_DEMO_TENANT_ID ||
          tenant?.states?.availablePlans?.[0] === false
        }
      />
      <PlanCard
        title={_`Growth|||plan`}
        image="/img/billing_growth.svg"
        price={yearly ? "$279" : "$349"}
        granularity="month"
        line1={_`Up to 1,000,000 visits per month`}
        line2={_`15+ Polar Integrations`}
        item1={_`Daily refresh of data`}
        item2={_`Multi-stores capabilities`}
        item3={_`Unlimited historical data`}
        item4={_`Unlimited users`}
        item5={_`Dedicated Slack channel`}
        onClick={() => onSelect(2)}
        loading={loading}
        selected={
          subscription?.plan === 2 &&
          (subscription.valid_until === null ||
            moment(subscription.valid_until).isAfter(moment()))
        }
        disabled={
          tenant.id === LIVE_DEMO_TENANT_ID ||
          (tenant?.states?.availablePlans as boolean[])?.[1] === false
        }
      />
      <PlanCard
        title={_`Plus|||plan`}
        image="/img/billing_plus.svg"
        price={yearly ? "$519" : "$649"}
        granularity="month"
        line1={_`Unlimited visits per month`}
        line2={_`15+ Polar Integrations`}
        item1={_`Hourly refresh of data`}
        item2={_`Multi-brands capabilities`}
        item3={_`Unlimited historical data & users`}
        item4={_`Attribution Model`}
        item5={_`Dedicated Slack channel`}
        onClick={() => onSelect(3)}
        loading={loading}
        selected={
          subscription?.plan === 3 &&
          (subscription.valid_until === null ||
            moment(subscription.valid_until).isAfter(moment()))
        }
        disabled={
          tenant.id === LIVE_DEMO_TENANT_ID ||
          (tenant?.states?.availablePlans as boolean[])?.[2] === false
        }
      />
    </PlanCardContainer>
  );
}
