const MILLISECOND = 1;
const SECOND = 1000 * MILLISECOND;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const YEAR = 365 * DAY;
const units = [
  [YEAR, "y"],
  [DAY, "d"],
  [HOUR, "h"],
  [MINUTE, "m"],
  [SECOND, "s"],
  [MILLISECOND, "ms"],
] as const;

type TimedeltaFormat = [number, string][];
/**
 * Splits a duration into parts (with units) for display.
 * Inverts the sign of the first node for negative durations
 *
 * @inputValue The duration in seconds.
 */
export const formatTimedelta = (inputValue: number): TimedeltaFormat => {
  let rest = Math.round(Math.abs(inputValue) * SECOND);
  const sign = inputValue < 0 ? -1 : 1;
  const result: TimedeltaFormat = [];

  for (const [threshold, unit] of units) {
    const value = Math.floor(rest / threshold);
    if (value > 0) {
      rest = rest - value * threshold;
      result.push([value, unit]);
    }
  }

  if (result.length === 0) {
    return [[0, "s"]];
  }

  result[0][0] = sign * result[0][0];

  return result;
};
