import { useState } from "react";

import { useBanners } from "../../hooks/banners";
import { useCallBooking } from "../../hooks/useCallBooking";
import { Banner, Button } from "../../icecube-ux";
import { _ } from "../../languages/helper";
import { pluralize } from "../../utils/languageUtils";
import PlansPopup from "../Popups/PlansPopup";

interface FreeTrialCountDownProps {
  dayCount: number;
}

export default function FreeTrialCountDown({
  dayCount,
}: FreeTrialCountDownProps) {
  const { isShown } = useBanners();
  const [isShowingPlans, setIsShowingPlans] = useState(false);
  const { openSetupCallBooking, isLoading: isCallBookingModalLoading } =
    useCallBooking();

  if (!isShown) {
    return null;
  }
  let bannerTitle = _`Your 7 day free trial has started`;

  if (dayCount === 0) {
    bannerTitle = _`Your free trial ends today`;
  } else if (dayCount < 10) {
    bannerTitle = _`Only ${["amount", String(dayCount)]} ${[
      "unit",
      pluralize(dayCount, "day"),
    ]} before the end of your free trial`;
  }

  return (
    <>
      {isShowingPlans ? (
        <PlansPopup onClose={() => setIsShowingPlans(false)} />
      ) : null}

      <Banner
        variant="primary"
        title={bannerTitle}
        subtitle={_`Subscribe now to get unlimited historical data`}
        actionsChildren={
          <>
            <Button
              onClick={() => void openSetupCallBooking()}
              color="secondary-on-blue-background"
              block={false}
              className="mrx-12"
              ctaSize
              loading={isCallBookingModalLoading}
            >
              {_`Request a demo`}
            </Button>
            <Button
              color="primary-on-blue-background"
              block={false}
              onClick={() => setIsShowingPlans(true)}
              ctaSize
            >
              {_`View plans`}
            </Button>
          </>
        }
      />
    </>
  );
}
