import styled, { css } from "styled-components";

import { theme } from "../theme";

export const StyledSetupConnectorCard = styled.div<{ isAccomplished: boolean }>`
  padding: 0 8px 0 24px;
  border-radius: ${theme.borderRadius.r4px};
  border: 1px solid ${theme.colors.borderLight};
  background: ${theme.colors.white100};

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    border: none;
    background: ${theme.colors.bgLight20};
    border: 1px solid ${theme.colors.bgLight20};
  }

  ${({ isAccomplished }) =>
    isAccomplished &&
    css`
      border: none;
      background: ${theme.colors.success10};
      border: 1px solid ${theme.colors.success10};

      &:hover {
        background: ${theme.colors.success20};
        border-color: ${theme.colors.success20};
      }
    `}
`;
