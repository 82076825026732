import { useNavigate } from "react-router-dom";

import {
  PixelStatus,
  usePixelConfiguration,
} from "../../../../../hooks/pixelConfiguration";
import { Button, theme } from "../../../../../icecube-ux";
import { _ } from "../../../../../languages/helper";
import {
  TRACKING_EVENTS,
  trackEvent,
} from "../../../../../utils/trackingUtils";

export const PixelConnectorButtonSeeUTMs = () => {
  const navigate = useNavigate();
  const { status } = usePixelConfiguration();

  return status === PixelStatus.MissingUtmSetup ? (
    <Button
      size="small"
      style={{ border: `1px solid ${theme.colors.warning30}` }}
      color="warning"
      block={true}
      onClick={() => {
        trackEvent(TRACKING_EVENTS.PIXEL_SETUP_CTA_CLICKED, {
          buttonId: "Connectors Page - Set up UTM parameters",
        });
        navigate("/connectors/polar-pixel/utm");
      }}
    >
      {_`Set up UTMs`}
    </Button>
  ) : (
    <></>
  );
};
