import { Banner, Button, Text, theme } from "../../icecube-ux";
import { _ } from "../../languages/helper";
import { openIntercom } from "../../utils/intercomUtils";

export const ShopifyConnectorHistoricalSyncing = ({
  displayActionButton,
}: {
  displayActionButton?: boolean;
}) => {
  return (
    <Banner
      variant="notification"
      title={
        <>
          {_`Shopify data sync in progress: `}
          <Text
            variant={"body11Regular"}
            style={{ display: "inline-flex" }}
            color={theme.customColors.textWarningNotification}
          >
            {_`This typically takes less than 24 hours for most brands, but depending on the amount of data, your shopify plan and other variables, this may extend the process to 2-3 days. Use this time to explore the app!`}
          </Text>
        </>
      }
      subtitle={
        <Text
          variant={"body11SemiBold"}
          color={theme.customColors.textWarningNotification}
        >{_`Please note that some metrics may not be fully accurate until the sync is complete. If it has been more than 72 hours - please reach out to our support team via chat.`}</Text>
      }
      actionsChildren={
        displayActionButton ? (
          <Button
            color="warning-on-orange-background"
            onClick={() => {
              openIntercom("showNewMessage");
            }}
          >
            Chat with us
          </Button>
        ) : (
          <></>
        )
      }
    />
  );
};
