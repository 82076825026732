import React, { ReactNode } from "react";

import { ConnectorIconNames } from "../icecube-ux/ConnectorIcon/types";
import { theme } from "../icecube-ux/theme";
import { _ } from "../languages/helper";
import { CustomViewRule } from "../lib/viewsService";
import { MetricDescription } from "../types/synthesizer";
import {
  INTEGRATION_CATEGORIES,
  IntegrationCategoryKey,
} from "../utils/integrationUtils";

const FRONTEND_INTEGRATIONS_REGISTRY: Record<string, ConnectorSetting> = {};
interface RuleOption {
  value: string;
  label: string;
}
export interface ConnectorAccounts {
  [connector: string]: {
    connectorKey: string;
    name: string;
    image: string;
    accounts: Array<{
      title: string;
      subtitle: string;
      identifier: string;
    }>;
    accountsLoaded: boolean;
    defaultRules: CustomViewRule[];
    extraFilters?: RuleOption[];
    datasourceError?: boolean;
  };
}

export interface ConfigurationButtonSectionProps {
  status: "empty" | "configuration" | "ready" | "requested";
  onConnect: () => void;
  onConfigure: () => void;
  disabled?: boolean;
}
export interface GenericDataSourceConfiguration {
  id: string;
  accountData?: {
    data?: Record<string, unknown>;
  };
  config?: Record<string, unknown>;
  privateData?: Record<string, unknown>; // Token authentication integrations
  userId?: string; // Alloy
  disabled: boolean;
  integrationAccountId: string;
}

export interface ViewConnectorSetting {
  key: string;
  dependency?: string;
  label?: string;
  extraFilters?: { value: string; label: string }[];
}

export interface ConnectorSetting {
  key: string;
  label: string;
  image: ConnectorIconNames;

  description: string;
  backgroundColor: string;
  borderColor: string;
  category: (typeof INTEGRATION_CATEGORIES)[keyof typeof INTEGRATION_CATEGORIES];
  userInstructions?: ReactNode;

  getAccountTitle?: (
    datasource: GenericDataSourceConfiguration,
  ) => string | undefined;
  isNewIntegrationService?: boolean;
  isCustomConnector?: boolean;
  customConnectorWarning?: string;
  customConnectorInfo?: string[];
  isAlloyIntegration?: boolean;
  openInNewWindow?: boolean;
  canReconnectOAuth?: boolean;
  dependsOnAccounts?: boolean;
  isRetired?: boolean;
  isBeta?: boolean;
  isAddon?: boolean;
  adPlatformChannelName?: string;
  utmGuide?: string;
  utmCode?: string;

  getButtonSection?: () => React.FC<ConfigurationButtonSectionProps>;
  SetupInstructionsSection?: React.FC;
  addonTooltip?: "capi" | "polar-pixel";
  views?: ViewConnectorSetting[];
}

export const registerFrontendIntegration = (definition: ConnectorSetting) => {
  FRONTEND_INTEGRATIONS_REGISTRY[definition.key] = definition;
};

export const getIntegrations = () => {
  return FRONTEND_INTEGRATIONS_REGISTRY;
};

export const withNewIntegrationService = (connectorKey: string) => {
  return (
    FRONTEND_INTEGRATIONS_REGISTRY[connectorKey]?.isNewIntegrationService ??
    false
  );
};

export const withConnectorDescription = (
  connectorKey: string,
  metricDescription: MetricDescription[] = [],
) => {
  const connector: ConnectorSetting | undefined =
    getIntegrationHandler(connectorKey);

  const connectorDescription = connector?.customConnectorInfo
    ? connector.customConnectorInfo.map((line) => ({
        image: connector.image,
        label: line,
        block: true,
      }))
    : [];

  return [...metricDescription, ...connectorDescription];
};

export const isCustomConnector = (connectorKey: string) => {
  return (
    FRONTEND_INTEGRATIONS_REGISTRY[connectorKey]?.isCustomConnector ?? false
  );
};

export const isAlloyIntegration = (connectorKey: string) => {
  return (
    FRONTEND_INTEGRATIONS_REGISTRY[connectorKey]?.isAlloyIntegration ?? false
  );
};

export const shouldOpenInNewWindow = (connectorKey: string) => {
  return FRONTEND_INTEGRATIONS_REGISTRY[connectorKey].openInNewWindow ?? false;
};

let cachedIconBackgrounds: null | Record<string, string> = null;
export const getBackgroundForIcon = (iconName: string) => {
  if (cachedIconBackgrounds === null) {
    cachedIconBackgrounds = Object.fromEntries(
      Object.values(FRONTEND_INTEGRATIONS_REGISTRY).map(
        ({ image, backgroundColor }) => [image, backgroundColor],
      ),
    );
  }

  return cachedIconBackgrounds[iconName] || theme.customColors.polarBg;
};

let cachedIconBorders: null | Record<string, string> = null;
export const getBorderForIcon = (iconName: string) => {
  if (cachedIconBorders === null) {
    cachedIconBorders = Object.fromEntries(
      Object.values(FRONTEND_INTEGRATIONS_REGISTRY).map(
        ({ image, borderColor }) => [image, borderColor],
      ),
    );
  }

  return cachedIconBorders[iconName] || theme.customColors.polarBorder;
};

export const getIntegrationIcon = (connectorKey: string) => {
  if (connectorKey === "benchmarks") {
    return "Benchmarks";
  }
  return connectorKey
    ? FRONTEND_INTEGRATIONS_REGISTRY[connectorKey]?.image
    : "Polar";
};

export const getConnectorList = () => {
  const connectors = Object.values(getIntegrations()).reduce(
    (result, current) => {
      const list = result[current.category.key];
      if (!list) {
        result[current.category.key] = [];
      }
      const intgrationObject = getIntegrationConnectorObject(current.key);
      if (intgrationObject) {
        result[current.category.key] = [
          ...result[current.category.key],
          intgrationObject,
        ];
      }
      return result;
    },
    {} as {
      [key in IntegrationCategoryKey]: ConnectorSetting[];
    },
  );

  return connectors;
};

export const getIntegrationConnectorObject = (
  connectorKey: string,
): ConnectorSetting | undefined => {
  const integration = FRONTEND_INTEGRATIONS_REGISTRY[connectorKey];
  if (!integration) {
    return undefined;
  }
  return integration;
};

export const getIntegrationHandler = (connectorKey: string) => {
  const integration = FRONTEND_INTEGRATIONS_REGISTRY[connectorKey];
  return integration;
};

export const getIntegrationViews = (connectorKey: string) => {
  const integration = FRONTEND_INTEGRATIONS_REGISTRY[connectorKey];
  return (
    integration.views?.map((v) => ({
      ...v,
      label: v.label ?? integration.label,
    })) ?? [
      {
        key: integration.key,
        label: integration.label,
      },
    ]
  );
};

export const getIntegrationDefaultView = (connectorKey: string) => {
  const integration = FRONTEND_INTEGRATIONS_REGISTRY[connectorKey];

  return (datasource: GenericDataSourceConfiguration, index: number) => ({
    title:
      integration?.getAccountTitle?.(datasource) ||
      _`Account` + ` ${index + 1}`,
    subtitle: integration.label,
    identifier: datasource.id,
  });
};

export const getViewsList = () =>
  Object.values(getIntegrations()).reduce(
    (result, current) => {
      const list = result[current.category.key];
      if (!list) {
        result[current.category.key] = [];
      }

      result[current.category.key] = [
        ...result[current.category.key],
        ...getIntegrationViews(current.key),
      ];
      return result;
    },
    {} as Record<
      string,
      Array<{
        key: string;
        label: string;
        dependency?: string;
        extraFilters?: { value: string; label: string }[];
      }>
    >,
  );

export const getDefaultViewsAccounts = () =>
  Object.values(getIntegrations()).reduce((result, current) => {
    const views = getIntegrationViews(current.key);
    views.forEach((v) => {
      result[v.key] = {
        connectorKey: v.key,
        name: v.label,
        image: current.image,
        accounts: [],
        accountsLoaded: false,
        ...(v.extraFilters ? { extraFilters: v.extraFilters } : {}),
        defaultRules: [
          {
            connectorKey: v.key,
            filterKey: "",
            operator: "IS",
            value: "",
          },
        ],
      };
    });
    return result;
  }, {} as ConnectorAccounts);
