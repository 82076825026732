import { useEffect } from "react";
import { Helmet } from "react-helmet";

import { useAuth } from "../../hooks/auth/auth";
import { Text } from "../../icecube-ux";
import { _ } from "../../languages/helper";
import Centering from "../Helpers/Centering";

export default function FullPageError() {
  const auth = useAuth();

  useEffect(() => {
    let i = 0;
    let key = localStorage.key(i);
    const toDelete = [];
    while (key !== null) {
      toDelete.push(key);
      i++;
      key = localStorage.key(i);
    }

    toDelete.forEach((k) => localStorage.removeItem(k));

    auth.logout(() => {});
  }, [auth]);

  return (
    <div className="full-page-loader">
      <Helmet>
        <title>{_`Error...`} | Polar Analytics</title>
      </Helmet>
      <Centering>
        <Text variant="SectionTitle">{_`Oops!`}</Text>
        <Text variant="Subtitle">
          {_`An error has occured. It is probably just a temporary failure, please refresh the page in a short while.`}
        </Text>
        <Text variant="Subtitle">{_`Our engineers have been informed.`}</Text>
      </Centering>
    </div>
  );
}
