type ChipArrowTailProps = { color: string };

export const ChipArrowTail = ({ color }: ChipArrowTailProps) => (
  <svg
    viewBox="0.65 0.5 10 27.95"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    style={{
      height: "100%",
      flexShrink: 0,
    }}
  >
    <path
      d="M1.68125 5.50595C-0.101183 3.5864 1.26014 0.4646 3.87964 0.4646L11 0.4646V14.4646V28.4646H3.87964C1.26014 28.4646 -0.101182 25.3428 1.68126 23.4232L8.10446 16.506C9.17328 15.3549 9.17328 13.5743 8.10446 12.4232L1.68125 5.50595Z"
      fill={color}
    />
  </svg>
);
