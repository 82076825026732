import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { CustomConnectorButtonSection } from "../pages/connectors/popups/connect/customConnectors/CustomConnectorButtonSection";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  key: "twitter-organic",
  image: "TwitterAds",
  label: "Twitter Organic",
  category: INTEGRATION_CATEGORIES.marketing,
  get description() {
    return _``;
  },
  isCustomConnector: true,
  backgroundColor: theme.customColors.fallbackConnectorBg,
  borderColor: theme.customColors.fallbackConnectorBorder,
  getButtonSection: () => CustomConnectorButtonSection,
});
