import React from "react";
import "../global.css";
import "./loader.css";

interface WireframeLoaderProps {
  width: number | string;
  height: number;
  radius?: number;
  className?: string;
  style?: React.CSSProperties;
  measure?: string;
}

export function WireframeLoader({
  width,
  height,
  radius = 5,
  className = "",
  measure = "px",
  style,
}: WireframeLoaderProps) {
  return (
    <span
      className={"wireframe-loader " + className}
      style={{
        width: `${width}${measure}`,
        height: `${height}${measure}`,
        borderRadius: `${radius}px`,
        ...style,
      }}
    ></span>
  );
}

interface LoaderProps {
  color?: string;
  size?: number;
  className?: string;
}

export function Loader({ color, className, size = 32 }: LoaderProps) {
  return (
    <div
      className={`loader ${className}`}
      style={{
        width: size + "px",
        height: size + "px",
        borderWidth: `${Math.floor(size / 6)}px`,
        borderRightColor: color || undefined,
        borderTopColor: color || undefined,
        borderBottomColor: color || undefined,
        borderLeftColor: `transparent`,
      }}
    ></div>
  );
}
