import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { VIEW_KEY_TO_CONNECTOR_ICON_MAPPING } from "../components/CountryFilters/views/viewMapping";
import { useViews } from "../components/CountryFilters/views/ViewProvider";
import { getIntegrationIcon } from "../integrations/integration";
import {
  customMetricsFromViewsServiceQueryKey,
  useCustomMetricsFromViewsService,
} from "../lib/customMetricService";
import { listMetrics } from "../lib/synthService";
import { CustomView } from "../lib/viewsService";
import {
  CustomMetricDefinition,
  IDimension,
  IMetric,
  IMetricsList,
  MetricDescription,
} from "../types/synthesizer";
import { getMetricDetails, parseMetricKey } from "../utils/metricUtils";
import { removeDuplicates } from "../utils/utils";

import { useAuth } from "./auth/auth";
import { useBootstrap } from "./bootstrap";

export interface MetricDetails {
  metric: IMetric;
  tableKey: string;
  areDependenciesCovered?: boolean;
}

export interface MetricListContextProps {
  loading: boolean;
  metrics: IMetricsList;
  customMetricsDefinitions: CustomMetricDefinition[];
  dimensions: { [key: string]: IDimension };
  metricsNoCustom: IMetricsList;
  flattenMetrics: { [key: string]: IMetric };
  treeMetrics: { [key: string]: IMetric };
  connectorKeyToTableKey: { [key: string]: string };
  tableKeyToConnectorKey: { [key: string]: string[] };
  refresh: () => void;
  getMetricDetailsFromId: (
    key: string,
    forcedViews?: CustomView[],
  ) => MetricDetails;
  loaderIndex: number;
}

const MetricListContext = createContext<MetricListContextProps | null>(null);

export function ProvideMetricList({ children }: { children: ReactNode }) {
  const provider = useProvideMetricList();
  return (
    <MetricListContext.Provider value={provider}>
      {children}
    </MetricListContext.Provider>
  );
}

export const useMetricList = () => {
  const context = useContext(MetricListContext);
  if (context === null) {
    throw Error("Metric list context not provided");
  }
  return context;
};

const metricListQueryKey = ["synth", "metricList"];
const useMetricListQuery = () => {
  const auth = useAuth();
  const bootstrap = useBootstrap();
  return useQuery(
    metricListQueryKey,
    async ({ signal }) => {
      const { customMetrics, ...data } = await listMetrics(
        await auth.getToken(),
        {
          withCustomMetrics: !auth.isOutboundDemo && !bootstrap.isDemoData,
          withCustomDimensions: true,
        },
        signal,
      );
      return {
        data,
        customMetrics,
      };
    },
    {
      enabled: !auth.processing && auth.isLoggedIn,
    },
  );
};

export const getTableToConnectorMapping = (metrics: IMetricsList) => {
  return Object.entries(metrics.tables).reduce(
    (result, entry) => {
      const [tableKey, table] = entry;
      if (!table.connectorKeys || table.connectorKeys.length === 0) {
        return result;
      }
      table.connectorKeys.forEach((connectorKey) => {
        if (!result[tableKey]) {
          result[tableKey] = [];
        }
        if (VIEW_KEY_TO_CONNECTOR_ICON_MAPPING[connectorKey]) {
          result[tableKey].push(
            VIEW_KEY_TO_CONNECTOR_ICON_MAPPING[connectorKey],
          );
        }
        result[tableKey].push(connectorKey);
      });
      return result;
    },
    {} as { [key: string]: string[] },
  );
};

const useProvideMetricList = () => {
  const [loaderIndex, setLoaderIndex] = useState(0);
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching, isFetched, isError, refetch } =
    useMetricListQuery();
  const {
    refetch: refetchCustomMetricInViewService,
    data: customMetricsDefinitions,
  } = useCustomMetricsFromViewsService();
  const [metricsNoCustom, setMetricsNoCustom] = useState<IMetricsList>({
    tables: {},
    metrics: {},
  });
  const auth = useAuth();

  const [metrics, setMetrics] = useState<IMetricsList>({
    tables: {},
    metrics: {},
  });
  const { doViewsCoverConnectors, selectedViews } = useViews();

  const refresh = () => setLoaderIndex((i) => i + 1);

  const flattenMetrics = useMemo(() => {
    if (!metrics.tables || !metrics.metrics) {
      return {};
    }

    const flatten: { [key: string]: IMetric } = {};
    Object.entries(metrics.tables).forEach(([tableKey, entry]) => {
      Object.entries(entry.metrics.computed).forEach(([key, metric]) => {
        flatten[`${tableKey}.computed.${key}`] = metric;
      });
      Object.entries(entry.metrics.raw).forEach(([key, metric]) => {
        flatten[`${tableKey}.raw.${key}`] = metric;
      });
    });
    Object.entries(metrics.metrics).forEach(([key, metric]) => {
      flatten[key] = metric;
    });
    Object.entries(metrics.customMetrics || {}).forEach(([key, metric]) => {
      flatten[key] = metric;
    });
    return flatten;
  }, [metrics]);

  const treeMetrics: Record<string, IMetric> = useMemo(() => {
    return Object.entries(flattenMetrics).reduce(
      (acc: Record<string, IMetric>, [key, metric]: [string, IMetric]) => {
        if (
          (!acc[metric.key] && (metric?.influences?.length ?? 0) > 0) ||
          (metric?.influencedBy?.length ?? 0) > 0
        ) {
          return { ...acc, [metric.key]: { ...metric, key } };
        }
        return acc;
      },
      {},
    );
  }, [flattenMetrics]);

  // The longer term vision here, is to make the frontend depend on connector keys intead of table names
  // But this need some bigger changes like changing metric keys to use the connector key instead of the table name
  const tableKeyToConnectorKey: {
    [key: string]: string[];
  } = useMemo(() => {
    const tableExtensions: {
      [key: string]: string[];
    } = {
      // Need to better handle this so that we don't have to add each new table here
      // Looks like this is used to get dimensions icons in filters, we can get the connector key from the synth instead (in compose options)
      shopify_customer_cohorts: ["shopify"],
      shopify_inventory_snapshot: ["shopify"],
      shopify_sales_main_attribution: ["shopify"],
      recharge_customers_subscriptions: ["recharge"],
      klaviyo_unique_campaign_and_date: ["klaviyo"],
      klaviyo_unique_flow_and_date: ["klaviyo"],
      facebookads_ad_image: ["facebook-ads"],
      facebookads_ad_body: ["facebook-ads"],
    };

    return {
      ...tableExtensions,
      ...getTableToConnectorMapping(metrics),
    };
  }, [metrics]);

  const connectorKeyToTableKey: {
    [key: string]: string;
  } = useMemo(
    () =>
      Object.entries(tableKeyToConnectorKey).reduce(
        (result, entry) => {
          entry[1].forEach((connectorKey) => {
            result[connectorKey] = entry[0];
          });
          return result;
        },
        {} as { [key: string]: string },
      ),
    [tableKeyToConnectorKey],
  );

  const getMetricDetailsFromId = useCallback(
    (metricKey: string, forcedViews?: CustomView[]) => {
      const { tableKey } = parseMetricKey(metricKey);
      const metric = getMetricDetails(metricKey, metrics);
      const dependencies = metric.tableDependencies ||
        metric.dependencies || [metricKey.split(".")[0]];
      const areDependenciesCovered = doViewsCoverConnectors(
        forcedViews || selectedViews,
        dependencies,
        tableKeyToConnectorKey,
      );

      return {
        metric,
        tableKey,
        areDependenciesCovered,
      };
    },
    [metrics, doViewsCoverConnectors, selectedViews, tableKeyToConnectorKey],
  );

  const dimensions = useMemo(() => {
    let dimensions: { [key: string]: IDimension } = {};
    const tables = Object.values(metrics.tables);
    tables.forEach((table) => {
      Object.entries(table.dimensions).forEach(([key, dim]) => {
        dimensions[key] = dim;
      });
    });

    dimensions = { ...dimensions, ...(metrics.customDimensions ?? {}) };
    return dimensions;
  }, [metrics]);

  const getCustomMetricDict = useCallback(
    (customMetrics: {
      [key: string]: {
        raw: CustomMetricDefinition;
        canBeUsedInReports: boolean;
      };
    }) => {
      const customMetricsObject: { [key: string]: IMetric } = {};
      Object.values(customMetrics).forEach(
        ({ raw: jsonMetric, canBeUsedInReports }) => {
          const description = jsonMetric.elements.reduce<MetricDescription[]>(
            (accumulator, element) => {
              if (element.value) {
                const { tableKey } = parseMetricKey(element.value);
                const icon = getIntegrationIcon(
                  tableKeyToConnectorKey[tableKey ?? ""]?.[0],
                );
                const metric = getMetricDetails(element.value, metrics);
                accumulator.push({
                  label: metric.label ? metric.label : element.value,
                  image: metric.label ? icon : "",
                });
              }
              return accumulator;
            },
            [],
          );
          if (jsonMetric.description) {
            description.push({
              label: jsonMetric.description,
              block: true,
            });
          }
          customMetricsObject[`custom_${jsonMetric.id}`] = {
            label: jsonMetric.title,
            key: `custom_${jsonMetric.id}`,
            dependencies: jsonMetric.elements
              .filter((e) => e.type === "metric")
              .map((e) => e.value),
            filterDependencies: removeDuplicates(
              jsonMetric.elements
                .filter((e) => e.type === "metric")
                .map((e) =>
                  e.filters
                    .map((f) => {
                      return Array.isArray(f)
                        ? f.map((f2) => f2.dimension)
                        : f.dimension;
                    })
                    .filter((r) => r !== "")
                    .flat(),
                )
                .flat(),
            ),
            currency: jsonMetric.formatting?.type === "currency",
            percentage: jsonMetric.formatting?.type === "percent",
            timedelta: jsonMetric.formatting?.type === "timedelta",
            integer: jsonMetric.formatting?.numberType === "integer",
            prefix: jsonMetric.formatting?.prefix,
            suffix: jsonMetric.formatting?.suffix,
            extraSettings: jsonMetric.formatting?.currency
              ? { currency: jsonMetric.formatting?.currency }
              : undefined,
            description,
            canBeUsedInReports: canBeUsedInReports,
          };
        },
      );
      return customMetricsObject;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify({ metrics, tableKeyToConnectorKey })],
  );

  const getToken = auth.getToken;

  useEffect(() => {
    void refetch();
    void refetchCustomMetricInViewService();
    return () => {
      void queryClient.cancelQueries(metricListQueryKey);
      void queryClient.cancelQueries(customMetricsFromViewsServiceQueryKey);
    };
    // This is ignored because we have array and object dependencies, so we needed to stringify the dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaderIndex, getToken, refetch]);

  useEffect(() => {
    if (isFetched && !isError) {
      setMetrics({
        ...data!.data,
        customMetrics: getCustomMetricDict(data?.customMetrics ?? {}),
      });
      setMetricsNoCustom(data!.data);
    }
  }, [isFetched, isError, data, getCustomMetricDict]);
  return {
    loading: isFetching || isLoading,
    metrics,
    customMetricsDefinitions: customMetricsDefinitions || [],
    dimensions,
    flattenMetrics,
    treeMetrics,
    metricsNoCustom,
    refresh,
    loaderIndex,
    getMetricDetailsFromId,
    tableKeyToConnectorKey,
    connectorKeyToTableKey,
  };
};
