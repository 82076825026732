import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  key: "pinterest-ads",
  category: INTEGRATION_CATEGORIES.advertising,
  image: "Pinterest",
  dependsOnAccounts: true,
  backgroundColor: theme.customColors.pinterestBg,
  borderColor: theme.customColors.pinterestBorder,
  label: "Pinterest Ads",
  adPlatformChannelName: "Pinterest Ads",
  utmGuide: "6781400",
  utmCode:
    "{lpurl}?utm_source=pinterest&ad_id={adid}&campaign_id={campaignid}&utm_medium=cpc&utm_campaign={campaign_name}&utm_term={adgroupname}&utm_content={adid}",
  get description() {
    return _`Online advertising platform from ${["provider", "Pinterest"]}`;
  },
  canReconnectOAuth: true,
});
