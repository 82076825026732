import classNames from "classnames";

import { ConnectorIcon, ConnectorIconNames } from "../ConnectorIcon";
import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { theme } from "../theme";
import { Tooltip } from "../Tooltip";
import { Text } from "../Typography";

import { ChipArrowHead } from "./ChipArrowHead";
import { ChipArrowTail } from "./ChipArrowTail";
import {
  StyledCustomerJourneyChip,
  StyledCustomerJourneyChipMain,
} from "./CustomerJourneyChip.styled";

export type CustomerJourneyChipProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    name: string;
    tooltipContent: string;
    icon?: ConnectorIconNames;
    isFirst?: boolean;
    isLast?: boolean;
    alignTouches: "left" | "right";
  };

export default function CustomerJourneyChip({
  name,
  isFirst,
  isLast,
  icon,
  tooltipContent,
  alignTouches,
  className,
  style,
  ...interactionEvents
}: CustomerJourneyChipProps) {
  return (
    <Tooltip content={tooltipContent} delay={500} alignCenter>
      <StyledCustomerJourneyChip
        {...interactionEvents}
        className={classNames(className)}
        style={style}
      >
        {!isFirst && <ChipArrowTail color={theme.colors.bgLight30} />}
        <StyledCustomerJourneyChipMain
          isFirst={isFirst}
          isLast={isLast}
          alignTouches={alignTouches}
        >
          {icon && (
            <ConnectorIcon
              name={icon}
              size={12}
              wrapperSize="20px"
              withBackground
              rootClassName="margin-right-small"
            />
          )}
          <Text
            color={theme.colors.text90}
            variant="body12Medium"
            ellipsis
            style={{ whiteSpace: "nowrap" }}
            className="margin-left-small"
          >
            {name}
          </Text>
        </StyledCustomerJourneyChipMain>
        {!isLast && <ChipArrowHead color={theme.colors.bgLight30} />}
      </StyledCustomerJourneyChip>
    </Tooltip>
  );
}
