import React, { useCallback, useState } from "react";

import useOutsideClickHandler from "../hooks/useOutsideClickHandler";

interface InvisibleInputHookProps {
  value: string | number;
  onChange: (value: string | number) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onBlur?: (value: string | number) => void;
  onFocus?: () => void;
  allowEditing?: boolean;
}

export default function useInvisibleInput({
  value,
  onChange,
  onKeyDown,
  onClick,
  onFocus = () => {},
  onBlur = () => {},
  allowEditing,
}: InvisibleInputHookProps) {
  const [editing, setEditing] = useState(false);
  const [node, setNode] = useState<HTMLInputElement | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    onChange(e.target.value);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (["Enter", "Escape"].includes(e.key)) {
      setEditing(false);
      onBlur(value);
    }

    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setEditing(true);

    if (onClick) {
      onClick(e);
    }
    onFocus();
  };

  const refCallback = useCallback((element: HTMLInputElement) => {
    setNode(element);
  }, []);

  const outsideClick = useCallback(() => {
    onBlur(value);
    setEditing(false);
  }, [value, onBlur]);

  useOutsideClickHandler(outsideClick, node);

  return {
    refCallback,
    editing: allowEditing ? editing : false,
    handleClick,
    handleKeyDown,
    handleChange,
  };
}
