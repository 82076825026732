import React from "react";

import { StyledSnackContainer } from "./Snack.styled";

export default function SnackContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return <StyledSnackContainer>{children}</StyledSnackContainer>;
}
