export const sizes = {
  borderRadius: {
    r2px: "2px",
    r3px: "3px",
    r4px: "4px",
    r6px: "6px",
    r8px: "8px",
    r10px: "10px",
    r16px: "16px",
    circle: "50%",
  },
};
