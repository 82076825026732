import { Fragment } from "react";

const supportedLanguages = ["en", "fr", "de", "debug"] as const;
export type SupportedLanguage = (typeof supportedLanguages)[number];

const isSupportedLanguage = (
  language: string | null,
): language is SupportedLanguage => {
  return (
    !!language && supportedLanguages.includes(language as SupportedLanguage)
  );
};

const DEFAULT_LANGUAGE = "en";

const localStorageKey = "polar-analytics-language";

const params = new URLSearchParams(window.location.search);
const languageInput =
  params.get("lang") || localStorage.getItem(localStorageKey);

export const languageState: {
  current: SupportedLanguage;
  translations: Record<string, string>;
  loading: boolean;
} = {
  loading: true,
  current:
    isSupportedLanguage(languageInput) &&
    !(process.env.REACT_APP_ENV === "production" && languageInput === "debug")
      ? languageInput
      : DEFAULT_LANGUAGE,
  translations: {},
};

localStorage.setItem(localStorageKey, languageState.current);

const loadLanguage = async (language: SupportedLanguage) => {
  if (language === "debug") {
    const { default: translations } = await import(`./en.json`);
    languageState.translations = Object.fromEntries(
      Object.keys(translations).map((key) => [key, ""]),
    );
    languageState.loading = false;
    return;
  }

  try {
    const { default: translations } = (await import(`./${language}.json`)) as {
      default: Record<string, string>;
    };
    languageState.translations = translations;
  } finally {
    languageState.loading = false;
  }
};

void loadLanguage(languageState.current);

type Placeholder<TValue> = [key: string, value: TValue];

const placeholderMarker = (placeholder: Placeholder<unknown>) => {
  return `%{${placeholder[0]}}`;
};

const placeholderValue = <TValue,>(placeholder: Placeholder<TValue>) => {
  return placeholder[1];
};

export const setLanguageWithRefresh = (language: SupportedLanguage) => {
  localStorage.setItem(localStorageKey, language);
  window.location.href = `${window.location.protocol}//${window.location.host}/settings`;
};

let debugTimeout: NodeJS.Timeout | null = null;
const missingTranslations = new Set<string>();

const getTemplate = (
  inputValue: TemplateStringsArray | string,
  ...placeholders: [key: string, value: unknown][]
) => {
  const key =
    typeof inputValue === "string" // Allow for _(`Template string ${value}s`)
      ? inputValue
      : inputValue
          ?.flatMap((v, i) => {
            if (placeholders[i]) {
              return [v, placeholderMarker(placeholders[i])];
            }

            return [v];
          })
          ?.join("");

  if (
    process.env.REACT_APP_ENV !== "production" &&
    !languageState.loading &&
    languageState.translations[key] === undefined &&
    key !== ""
  ) {
    // For practical reasons, we want to log the entire array
    missingTranslations.add(key);
    if (debugTimeout) {
      clearTimeout(debugTimeout);
    }
    debugTimeout = setTimeout(() => {
      console.warn("[Missing Translations]");
      console.warn(
        Array.from(missingTranslations)
          .map((key) => `"${key}": "${key}",`)
          .join("\r\n"),
      );
    }, 2000);
  }

  // Remove context suffix.
  const translation = (languageState.translations[key] || key)?.split("|||")[0];
  return translation || "";
};

/**
 * Used to intersperse React nodes as placeholders.
 * Especially handy when there are highlights, links etc.
 */
export const _node = (
  inputValue: TemplateStringsArray,
  ...placeholders: Placeholder<React.ReactNode>[]
): React.ReactNode[] => {
  let rest = getTemplate(inputValue, ...placeholders);
  const result: React.ReactNode[] = [];

  placeholders.forEach((p, index) => {
    const parts = rest.split(placeholderMarker(p));
    rest = parts[1] || "";
    result.push(
      <Fragment key={`static-${index}`}>{parts[0]}</Fragment>,
      <Fragment key={`placeholder-${index}`}>{placeholderValue(p)}</Fragment>,
    );
  });

  if (languageState.current === "debug") {
    return placeholders.map(placeholderValue);
  }

  return [...result, rest];
};

/**
 * Used to create strings by interspersing strings as placeholders.
 * Accepts both strings (i.e. for dynamic values) and template strings.
 */
export const _ = (
  inputValue: TemplateStringsArray | string,
  ...placeholders: Placeholder<string>[]
): string => {
  let result = getTemplate(inputValue, ...placeholders);

  placeholders.forEach((p) => {
    result = result.replace(placeholderMarker(p), placeholderValue(p));
  });

  if (languageState.current === "debug") {
    return "";
  }

  return result;
};
