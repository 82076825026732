import { useLocation } from "react-router-dom";

import { Box, Button, Flex, Popup, theme, Text } from "../../icecube-ux";
import { _ } from "../../languages/helper";

const useRedirectToStripeCustomerPortal = () => {
  window.open(
    "https://billing.stripe.com/p/login/6oE28O4RCaDp0mIaEE",
    "_blank",
  );
};

export default function SubscriptionUnpaidPopup() {
  const location = useLocation();

  if (location.pathname === "/settings/account") {
    return null;
  }

  return (
    <Popup
      onClose={() => {}}
      hideCloseButton
      withPaddingTop={false}
      withPadding={false}
    >
      <Box width="550px" height="100%">
        <Flex flexDirection="column" style={{ height: "100%" }}>
          <img src="/img/desktop-app-modal.svg" alt="" />
          <Box padding="50px 34px">
            <Text
              variant="body28SemiBold"
              textAlign="center"
              color={theme.colors.text110}
            >
              {_`Account suspended`}
            </Text>
            <Text
              variant="body16Regular"
              textAlign="center"
              className="margin-top-xlarge"
              color={theme.colors.text80}
            >
              {_`Due to several unsuccessful payment attempts, we've had to suspend your account.`}
              <br />
              <br />
              {_`Please update your billing details to continue using Polar, and you'll regain access right away.`}
              <br />
              <br />
              {_`If you're having any issues, reach out to us through our support chat or by email at`}{" "}
              <a href="mailto:support@polaranalytics.co">
                support@polaranalytics.co
              </a>
              .
            </Text>
          </Box>
          <Box padding="24px 34px" className="border-top" marginTop="auto">
            <Flex fullWidth justifyContent="center">
              <Button
                size="xxlarge"
                onClick={useRedirectToStripeCustomerPortal}
              >
                {_`Update billing details`}
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Popup>
  );
}
