import classNames from "classnames";

import { ClassAndStyleProps, InteractionEvents } from "../shared";

import useInvisibleInput from "./InvisibleInput.hooks";
import {
  StyledInvisibleInput,
  StyledInvisibleInputWrapper,
  StyledInvisiblePlaceholder,
  StyledInvisibleValueDisplay,
} from "./InvisibleInput.styled";

export type InvisibleInputProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    block?: boolean;
    placeholder?: string;
    value: string | number;
    onChange: (value: string | number) => void;
    onBlur?: (value: string | number) => void;
    onFocus?: () => void;
    size?: "large" | "small";
    name?: string;
    allowEditing?: boolean;
  };

export default function InvisibleInput({
  placeholder,
  value,
  onChange,
  onBlur,
  onFocus,
  block,
  name,
  className,
  style,
  size = "large",
  onClick,
  onKeyDown,
  allowEditing = true,
  ...interactionEvents
}: InvisibleInputProps) {
  const { refCallback, editing, handleClick, handleKeyDown, handleChange } =
    useInvisibleInput({
      value,
      onChange,
      onKeyDown,
      onClick,
      onFocus,
      onBlur,
      allowEditing,
    });

  return (
    <StyledInvisibleInputWrapper
      {...interactionEvents}
      className={classNames(className, {
        block,
        editing,
      })}
      style={style}
      onClick={allowEditing ? handleClick : undefined}
    >
      {!editing && (
        <>
          {value && (
            <StyledInvisibleValueDisplay
              data-cy={name}
              $size={size}
              allowEditing={allowEditing}
            >
              {value}
            </StyledInvisibleValueDisplay>
          )}
          {!value && (
            <StyledInvisiblePlaceholder
              data-cy={name}
              $size={size}
              allowEditing={allowEditing}
            >
              {placeholder}
            </StyledInvisiblePlaceholder>
          )}
        </>
      )}
      {editing && (
        <StyledInvisibleInput
          autoFocus
          ref={refCallback}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          $size={size}
          allowEditing={allowEditing}
          name={name}
          placeholder={placeholder}
        />
      )}
    </StyledInvisibleInputWrapper>
  );
}
