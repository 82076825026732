import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  key: "google-analytics-four",
  category: INTEGRATION_CATEGORIES.analytics_and_attribution,
  image: "GoogleAnalytics",
  dependsOnAccounts: true,
  backgroundColor: theme.customColors.googleFbInstaBg,
  borderColor: theme.customColors.googleFbInstaBorder,
  label: "Google Analytics 4",
  get description() {
    return _`Tracking system and Analytics platform`;
  },
  canReconnectOAuth: true,
});
