import classNames from "classnames";

import Icon, { IconNames } from "../Icon/Icon";
import { ClassAndStyleProps, InteractionEvents } from "../shared";

import { StyledIconButton } from "./IconButton.styled";
import { IconButtonColor, IconButtonSize } from "./types";

export type IconButtonProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    dataCy?: string;
    name: IconNames;
    type?: "button" | "submit";
    size?: IconButtonSize;
    color?: IconButtonColor;
    disabled?: boolean;
    dataAttributes?: { [key: string]: string };
    inline?: boolean;
    iconSize?: number;
  };

export default function IconButton({
  className,
  style,
  size = "small",
  color = "default",
  name,
  disabled,
  inline,
  type = "button",
  dataAttributes = {},
  iconSize,
  ...interactionEvents
}: IconButtonProps) {
  const iconSizes = {
    tiny: 12,
    small: 12,
    large: 16,
    xxl: 16,
    huge: 22,
  };

  return (
    <StyledIconButton
      {...interactionEvents}
      type={type}
      onClick={(e) => {
        e.stopPropagation();
        if (interactionEvents.onClick && !disabled) {
          interactionEvents.onClick(e);
        }
      }}
      style={style}
      className={classNames(className)}
      {...{
        color,
        disabled,
        size,
        inline,
      }}
      {...dataAttributes}
    >
      <Icon name={name} size={iconSize || iconSizes[size]} />
    </StyledIconButton>
  );
}
