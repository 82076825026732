export const typography = {
  fontSize: {
    s8px: "8px",
    s9px: "9px",
    s10px: "10px",
    s11px: "11px",
    s12px: "12px",
    s13px: "13px",
    s14px: "14px",
    s15px: "15px",
    s16px: "16px",
    s18px: "18px",
    s20px: "20px",
    s22px: "22px",
    s24px: "24px",
    s26px: "26px",
    s28px: "28px",
    s34px: "34px",
    s40px: "40px",
    s48px: "48px",
  },
  lineHeight: {
    h12px: "12px",
    h14px: "14px",
    h15px: "15px",
    h16px: "16px",
    h18px: "18px",
    h19px: "19px",
    h21px: "21px",
    h22px: "22px",
    h24px: "24px",
    h27px: "27px",
    h30px: "30px",
    h33px: "33px",
    h36px: "36px",
    h39px: "39px",
    h42px: "42px",
    h51px: "51px",
    h60px: "60px",
    h72px: "72px",
  },
  fontWeight: {
    bold: "700",
    semiBold: "600",
    medium: "500",
    regular: "400",
    light: "300",
  },
  fontFamily: {
    poppins: "'Poppins', sans-serif",
  },
  fontStyle: {
    normal: "normal",
  },
};
