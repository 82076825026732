import classNames from "classnames";
import { ReactNode } from "react";

import { Box } from "../Box";
import { ClassAndStyleProps, InteractionEvents } from "../shared";

import {
  StyledLabel,
  StyledRightComponent,
  StyledTabList,
  StyledTabListTab,
} from "./TabList.styled";

export type TabListProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    tabs: {
      leftComponent?: ReactNode;
      value: string;
      label: string;
      rightComponent?: ReactNode;
    }[];
    currentTab: string;
    onChange: (value: string) => void;
    size?: "large" | "small";
    stacked?: boolean;
    tabWidth?: string;
    showRightComponentOnlyOnHover?: boolean;
  };

export default function TabList({
  className,
  style,
  tabs,
  currentTab,
  onChange,
  size = "large",
  tabWidth,
  showRightComponentOnlyOnHover = false,
  stacked = false,
  ...interactionEvents
}: TabListProps) {
  return (
    <StyledTabList
      {...interactionEvents}
      className={classNames(className)}
      style={style}
      role="tablist"
      stacked={stacked}
    >
      {tabs.map(({ value, label, leftComponent, rightComponent }) => (
        <StyledTabListTab
          key={value}
          className="tab-list-tab"
          onClick={() => onChange(value)}
          isCurrentTab={value === currentTab}
          role="tab"
          showRightComponentOnlyOnHover={showRightComponentOnlyOnHover}
          stacked={stacked}
          size={size}
          style={{ width: tabWidth }}
          data-cy={"tab-" + value}
        >
          {leftComponent ? <div>{leftComponent}</div> : null}
          <StyledLabel>{label}</StyledLabel>
          {rightComponent && (
            <StyledRightComponent>
              {stacked ? (
                <Box marginLeft="auto">{rightComponent}</Box>
              ) : (
                rightComponent
              )}
            </StyledRightComponent>
          )}
        </StyledTabListTab>
      ))}
    </StyledTabList>
  );
}
