import { ReactNode } from "react";

import { CustomElementEditorsContextProps } from "../../../hooks/customElementEditorsTypes";
import {
  ConnectorIconNames,
  GroupedListValues,
  MoreOptions,
  OptionItem,
} from "../../../icecube-ux";
import { TableDefinition } from "../../../icecube-ux/MultiStepFormulaInput/components/Selector";
import {
  getIntegrationConnectorObject,
  getIntegrationIcon,
} from "../../../integrations/integration";
import { _ } from "../../../languages/helper";
import { RefreshStatusData } from "../../../lib/integrationsService";
import {
  CustomMetric,
  CustomMetricDefinition,
  IMetric,
  IMetricsList,
  ITable,
} from "../../../types/synthesizer";
import { Tenant } from "../../../types/tenant";
import {
  connectorStatus,
  getDatasourceStatusesByConnector,
  hasAtLeastOneConnector,
} from "../../../utils/connectors";
import { OPERATOR_LIST } from "../../../utils/filterUtils";
import { removeDuplicates } from "../../../utils/utils";
import ConnectorIconList from "../components/ConnectorIconList";

export const PIXEL_METRICS_TAG = "pixel";
const KLAVIYO_METRICS_TAG = "klaviyo";

const TAG_TO_TABLE_KEY = {
  [KLAVIYO_METRICS_TAG]: "klaviyo_sales_main",
};

export interface MetricOption {
  label: string;
  value: string;
  metric: IMetric;
}

interface FilteredMetrics {
  [key: string]: { raw: MetricOption[]; computed: MetricOption[] };
}

interface Block {
  blockKey: string;
  label: string;
  icon?: ConnectorIconNames;
  background?: string;
  iconComponent?: ReactNode;
  metrics: GroupedListValues;
  actions?: () => void;
  disabled?: boolean;
  headerInfo?: ReactNode;
  addonTooltip?: "capi" | "polar-pixel";
}

interface TableMetric {
  tableKey: string;
  label: string;
  rawMetrics: MetricOption[];
  computedMetrics: MetricOption[];
  connectorKeys?: string[];
}
interface GetTableMetricsProps {
  metricList: IMetricsList;
  statuses: RefreshStatusData[];
  filteredMetrics: FilteredMetrics;
  tableKeyToConnectorKey: { [key: string]: string[] };
}

export const getIconsFromTableDependencies = (
  tableDependencies: string[],
  statuses: RefreshStatusData[],
  tableKeyToConnectorKey: { [key: string]: string[] },
): ConnectorIconNames[] => {
  const availableTables = tableDependencies
    .map((table) => ({ table, connector: tableKeyToConnectorKey[table] }))
    .filter(({ connector: connectors }) => {
      const isAtLeastOneConnectorAvailable = connectors?.some(
        (connector) =>
          connector && connectorStatus(connector, statuses) !== "none",
      );
      return isAtLeastOneConnectorAvailable;
    })
    .map(({ table }) => table);
  const iconConnectorNames = removeDuplicates(
    availableTables.map((r) =>
      getIntegrationIcon(tableKeyToConnectorKey[r]?.[0]),
    ),
  );
  return iconConnectorNames;
};

export const getBlendedMetricSectionIcon = (
  statuses: RefreshStatusData[],
  tableKeyToConnectorKey: { [key: string]: string[] },
): ReactNode => {
  const iconConnectorNames = getIconsFromTableDependencies(
    Object.keys(tableKeyToConnectorKey),
    statuses,
    tableKeyToConnectorKey,
  );
  return <ConnectorIconList names={iconConnectorNames} />;
};

export const shouldIgnoreStatusInKI = (tableKey: string) => {
  return (
    tableKey.startsWith("generated_root_") || tableKey === "benchmarks_master"
  );
};

const shouldIgnoreStatus = (table: ITable) => {
  return (
    (table.key.startsWith("generated_root_") && table.isAvailable === true) ||
    table.key === "benchmarks_master"
  );
};

export const getTableMetrics = ({
  metricList,
  statuses,
  filteredMetrics,
  tableKeyToConnectorKey,
}: GetTableMetricsProps): TableMetric[] => {
  const connectorGroups = getDatasourceStatusesByConnector(statuses);
  return Object.entries(metricList?.tables || {})
    .sort(([_, a], [__, b]) => (a.order || Infinity) - (b.order || Infinity))
    .map(([tableKey, table]) => {
      const connectorKeys =
        tableKeyToConnectorKey[tableKey] ?? table.connectorKeys;

      const connectorHasData = (c: RefreshStatusData) =>
        c.last_refresh_date !== null && c.last_refresh_date !== undefined;
      const hasAtLeastOneReadyConnector =
        connectorKeys &&
        connectorKeys.some((c) => {
          const connectors = connectorGroups.get(c);
          return (
            connectors &&
            connectors.length !== 0 &&
            connectors.some(connectorHasData)
          );
        });
      if (!(hasAtLeastOneReadyConnector || shouldIgnoreStatus(table))) {
        return null;
      }

      const rawMetrics = filteredMetrics[tableKey].raw;

      const label = metricList.tables[tableKey].label;

      const computedMetrics = filteredMetrics[tableKey].computed;

      if (rawMetrics.length + computedMetrics.length < 1) {
        return null;
      }
      return {
        tableKey,
        label,
        rawMetrics,
        computedMetrics,
        connectorKeys: table.connectorKeys,
      } as TableMetric;
    })
    .filter((element): element is TableMetric => element !== null);
};

export const getMetricsWithTableName = (
  metrics: IMetricsList,
  statuses: RefreshStatusData[],
  tableKeyToConnectorKey: { [key: string]: string[] },
) => {
  const filteredMetrics = getFilteredMetricList(
    "",
    metrics,
    statuses,
    tableKeyToConnectorKey,
  );
  const tableMetrics = getTableMetrics({
    filteredMetrics,
    statuses,
    metricList: metrics,
    tableKeyToConnectorKey,
  });

  const tableMetricItems = tableMetrics.flatMap((table) =>
    [...table.rawMetrics, ...table.computedMetrics].map((metric) => ({
      tableLabel: table.label,
      metricLabel: metric.label,
      value: metric.value,
    })),
  );

  const pixelMetrics = [
    ...filteredMetrics.pixel.raw,
    ...filteredMetrics.pixel.raw,
  ].map((metric) => ({
    tableLabel: "Pixel Metrics",
    metricLabel: metric.label,
    value: metric.value,
  }));

  const blendedMetrics = [
    ...filteredMetrics.blended.raw,
    ...filteredMetrics.blended.raw,
  ].map((metric) => ({
    tableLabel: "Blended Metrics",
    metricLabel: metric.label,
    value: metric.value,
  }));

  const customMetrics = [
    ...filteredMetrics.custom.raw,
    ...filteredMetrics.custom.raw,
  ].map((metric) => ({
    tableLabel: "Custom Metrics",
    metricLabel: metric.label,
    value: metric.value,
  }));

  return [
    ...tableMetricItems,
    ...pixelMetrics,
    ...blendedMetrics,
    ...customMetrics,
  ];
};

interface GetMetricBlockListProps {
  showCustomMetric: boolean;
  metric: CustomMetricDefinition | null;
  metricList: IMetricsList;
  tenant?: Tenant;
  isAdmin?: boolean;
  startsWithMode: boolean;
  search: string;
  createMetric: CustomElementEditorsContextProps["createMetric"];
  editMetric: CustomElementEditorsContextProps["editMetric"];
  duplicateMetric: CustomElementEditorsContextProps["duplicateMetric"];
  deleteMetric: CustomElementEditorsContextProps["deleteMetric"];
  canSeePixelMetrics: boolean;
  statuses: RefreshStatusData[];
  tableKeyToConnectorKey: { [key: string]: string[] };
}

export const getMetricBlockList = ({
  showCustomMetric,
  metric,
  metricList,
  tenant,
  isAdmin,
  startsWithMode,
  search,
  createMetric,
  editMetric,
  duplicateMetric,
  deleteMetric,
  canSeePixelMetrics,
  statuses,
  tableKeyToConnectorKey,
}: GetMetricBlockListProps): Block[] => {
  if (!tenant) {
    return [];
  }
  const filteredMetrics = getFilteredMetricList(
    search,
    metricList,
    statuses,
    tableKeyToConnectorKey,
    startsWithMode,
  );
  const customBlock: Block | undefined =
    showCustomMetric &&
    (search === "" || filteredMetrics.custom.computed.length > 0)
      ? {
          blockKey: "Custom Metrics",
          label: _`Custom Metrics`,
          icon: "Polar",
          background: "#f6f9fe",
          metrics: {
            raw: [],
            computed: filteredMetrics.custom.computed
              .sort((m, n) => m.label.localeCompare(n.label))
              .map((m) => ({
                label: m.label,
                value: m.value,
                searchableLabel: m.label,
                toggleRightComponentsOnHover: true,
                rightComponents: [
                  <MoreOptions
                    options={[
                      {
                        icon: "Edit",
                        label: _`Edit`,
                        onClick: () => {
                          editMetric(m.value.substring(7));
                        },
                      },
                      {
                        icon: "Duplicate",
                        label: _`Duplicate`,
                        onClick: () => {
                          duplicateMetric(m.value.substring(7));
                        },
                      },
                      ...(isAdmin
                        ? [
                            {
                              icon: "Delete",
                              label: _`Delete`,
                              onClick: () => {
                                deleteMetric(m.value.substring(7));
                              },
                            } as OptionItem,
                          ]
                        : []),
                    ]}
                  />,
                ],
              })),
          },
          actions:
            // only display add button on the create/edit report screen
            metric === null
              ? () => {
                  createMetric();
                }
              : undefined,
        }
      : undefined;

  const blendedBlock: Block | undefined =
    filteredMetrics.blended.computed.length > 0
      ? {
          blockKey: "Blended Metrics",
          label: _`Blended Metrics`,
          iconComponent: getBlendedMetricSectionIcon(
            statuses,
            tableKeyToConnectorKey,
          ),
          metrics: {
            raw: [],
            computed: filteredMetrics.blended.computed.map((m) => ({
              label: m.label,
              value: m.value,
              searchableLabel: m.label,
            })),
          },
        }
      : undefined;

  const pixelBlock: Block | undefined =
    filteredMetrics.pixel.raw.length + filteredMetrics.pixel.computed.length > 0
      ? {
          blockKey: "Polar Pixel Metrics",
          label: _`Polar Pixel Metrics`,
          icon: "PolarPixel",
          background: "#f6f9fe",
          metrics: {
            raw: filteredMetrics.pixel.raw.map((m) => ({
              label: m.label,
              value: m.value,
              searchableLabel: m.label,
            })),
            computed: filteredMetrics.pixel.computed.map((m) => ({
              label: m.label,
              value: m.value,
              searchableLabel: m.label,
            })),
          },
          disabled: !canSeePixelMetrics,
          addonTooltip: getIntegrationConnectorObject(
            tableKeyToConnectorKey["polar-pixel"]?.[0],
          )?.addonTooltip,
        }
      : undefined;

  const tableMetrics = getTableMetrics({
    filteredMetrics,
    statuses,
    metricList,
    tableKeyToConnectorKey,
  });

  const tableBlocks: Block[] = tableMetrics.map(
    ({ tableKey, label, rawMetrics, computedMetrics, connectorKeys }) => ({
      blockKey: tableKey,
      label,
      icon:
        getIntegrationIcon(
          tableKeyToConnectorKey[tableKey]?.[0] ?? connectorKeys?.[0],
        ) || "Polar",
      background: getIntegrationConnectorObject(
        tableKeyToConnectorKey[tableKey]?.[0] ?? connectorKeys?.[0],
      )?.backgroundColor,
      metrics: { raw: rawMetrics, computed: computedMetrics },
      addonTooltip: getIntegrationConnectorObject(
        tableKeyToConnectorKey[tableKey]?.[0] ?? connectorKeys?.[0],
      )?.addonTooltip,
    }),
  );

  return [
    ...(customBlock ? [customBlock] : []),
    ...(blendedBlock ? [blendedBlock] : []),
    ...(pixelBlock ? [pixelBlock] : []),
    ...tableBlocks,
  ];
};

export const getFilteredMetricList = (
  search: string,
  metricList: IMetricsList,
  statuses: RefreshStatusData[],
  tableKeyToConnectorKey: { [key: string]: string[] },
  startsWithMode = true,
): FilteredMetrics => {
  const filtered: FilteredMetrics = {};
  filtered.custom = { raw: [], computed: [] };
  filtered.pixel = { raw: [], computed: [] };
  Object.entries(metricList.customMetrics || {})
    .filter(([_, m]) =>
      startsWithMode
        ? (typeof m.label === "string" ? m.label : `${m.key}`)
            .toLowerCase()
            .startsWith(search.toLowerCase())
        : (typeof m.label === "string" ? m.label : `${m.key}`)
            .toLowerCase()
            .includes(search.toLowerCase()),
    )
    .forEach(([metricKey, m]) => {
      if (m.tags?.includes(PIXEL_METRICS_TAG)) {
        filtered.pixel.computed.push({
          label: m.label,
          value: metricKey,
          metric: m,
        });
      } else {
        filtered.custom.computed.push({
          label: m.label,
          value: metricKey,
          metric: m,
        });
      }
    });

  Object.entries(metricList?.tables || {})
    .sort(([_, a], [__, b]) => (a.order || Infinity) - (b.order || Infinity))
    .forEach(([tableKey, table]) => {
      filtered[tableKey] = { raw: [], computed: [] };
      Object.entries(table.metrics.raw)
        .filter(([_, m]) =>
          startsWithMode
            ? m.label.toLowerCase().startsWith(search.toLowerCase())
            : m.label.toLowerCase().includes(search.toLowerCase()),
        )
        .forEach(([metricKey, m]) => {
          if (m.tags?.includes(PIXEL_METRICS_TAG)) {
            filtered.pixel.raw.push({
              label: m.label,
              value: tableKey + ".raw." + metricKey,
              metric: m,
            });
          } else {
            filtered[tableKey].raw.push({
              label: m.label,
              value: tableKey + ".raw." + metricKey,
              metric: m,
            });
          }
        });
      Object.entries(table.metrics.computed)
        .filter(([_, m]) =>
          startsWithMode
            ? m.label.toLowerCase().startsWith(search.toLowerCase())
            : m.label.toLowerCase().includes(search.toLowerCase()),
        )
        .forEach(([metricKey, m]) => {
          if (
            hasAtLeastOneConnector(
              m.tableDependencies || [],
              statuses,
              tableKeyToConnectorKey,
            )
          ) {
            if (m.tags?.includes(PIXEL_METRICS_TAG)) {
              filtered.pixel.computed.push({
                label: m.label,
                value: tableKey + ".computed." + metricKey,
                metric: m,
              });
            } else {
              filtered[tableKey].computed.push({
                label: m.label,
                value: tableKey + ".computed." + metricKey,
                metric: m,
              });
            }
          }
        });
    });

  filtered.blended = { raw: [], computed: [] };
  Object.entries(metricList?.metrics || {})
    .filter(([_, m]) =>
      startsWithMode
        ? m.label.toLowerCase().startsWith(search.toLowerCase())
        : m.label.toLowerCase().includes(search.toLowerCase()),
    )
    .forEach(([metricKey, m]) => {
      if (m.tags?.includes(PIXEL_METRICS_TAG)) {
        filtered.pixel.computed.push({
          label: m.label,
          value: metricKey,
          metric: m,
        });
      } else if (m.tags?.includes(KLAVIYO_METRICS_TAG)) {
        filtered[TAG_TO_TABLE_KEY[KLAVIYO_METRICS_TAG]].computed.push({
          label: m.label,
          value: metricKey,
          metric: m,
        });
      } else {
        filtered.blended.computed.push({
          label: m.label,
          value: metricKey,
          metric: m,
        });
      }
    });

  const filteredKlaviyoMetrics =
    filtered[TAG_TO_TABLE_KEY[KLAVIYO_METRICS_TAG]];
  if (filteredKlaviyoMetrics) {
    filteredKlaviyoMetrics.computed = [...filteredKlaviyoMetrics.computed].sort(
      (a, b) => {
        const KLAVIYO_PREFIX = "Klaviyo ";
        if (
          b.label.startsWith(KLAVIYO_PREFIX) &&
          a.label.startsWith(KLAVIYO_PREFIX)
        ) {
          return a.label.localeCompare(b.label);
        } else if (a.label.startsWith(KLAVIYO_PREFIX)) {
          return -1;
        } else if (b.label.startsWith(KLAVIYO_PREFIX)) {
          return 1;
        }
        return a.label.localeCompare(b.label);
      },
    );
  }

  filtered.pixel.computed = [...filtered.pixel.computed].sort((a, b) => {
    if (
      b.label.includes("Polar Pixel Paid") &&
      a.label.includes("Polar Pixel Paid")
    ) {
      return b.label.localeCompare(a.label);
    } else if (a.label.includes("Polar Pixel Paid")) {
      return -1;
    } else if (b.label.includes("Polar Pixel Paid")) {
      return 1;
    }
    return b.label.localeCompare(a.label);
  });
  return filtered;
};

interface GetMetricList {
  filteredMetrics: FilteredMetrics;
  tableMetrics: TableMetric[];
  tableKeyToConnectorKey: { [key: string]: string[] };
  metricFilterFunction?: ({ key }: { key: string }) => boolean;
}

export const getMetricListByTable = ({
  filteredMetrics,
  tableMetrics,
  tableKeyToConnectorKey,
  metricFilterFunction = () => true,
}: GetMetricList): TableDefinition[] => {
  return [
    {
      key: "custom",
      icon: "Polar",
      label: _`Custom metrics`,
      background: "#f6f9fe",
      metrics: [
        ...filteredMetrics.custom.raw,
        ...filteredMetrics.custom.computed,
      ]
        .map((cm) => ({
          label: cm.label,
          key: cm.value,
        }))
        .filter(metricFilterFunction),
    },
    {
      key: "blended",
      icon: "Polar",
      label: _`Blended metrics`,
      background: "#f6f9fe",
      metrics: [
        ...filteredMetrics.blended.raw,
        ...filteredMetrics.blended.computed,
      ]
        .map((cm) => ({
          label: cm.label,
          key: cm.value,
        }))
        .filter(metricFilterFunction),
    },
    {
      key: "pixel",
      icon: "PolarPixel",
      label: _`Pixel metrics`,
      background: "#f6f9fe",
      metrics: [...filteredMetrics.pixel.raw, ...filteredMetrics.pixel.computed]
        .map((cm) => ({
          label: cm.label,
          key: cm.value,
        }))
        .filter(metricFilterFunction),
    },
    ...Object.values(tableMetrics).map((table) => ({
      key: table.tableKey,
      icon: getIntegrationIcon(tableKeyToConnectorKey[table.tableKey]?.[0]),
      background:
        getIntegrationConnectorObject(
          tableKeyToConnectorKey[table.tableKey]?.[0],
        )?.backgroundColor ?? "#fff",
      label: table.label,
      metrics: [
        ...table.rawMetrics
          .map((m) => ({
            label: m.label,
            key: m.value,
          }))
          .filter(metricFilterFunction),
        ...table.computedMetrics
          .map((m) => ({
            label: m.label,
            key: m.value,
          }))
          .filter(metricFilterFunction),
      ],
    })),
  ];
};

export const isValidMetricValue = (value: string) =>
  value.match(/^[+-]?([0-9]*[.])?[0-9]+$/);

export const getMetricFormulaError = (elements: CustomMetric["elements"]) => {
  const state = {
    previousType: "",
    previousValue: "",
    openParenthesisCount: 0,
  };

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    switch (element.type) {
      case "operator":
        if (!OPERATOR_LIST.includes(element.value)) {
          return _`${["operator", element.value]} is not a valid operator.`;
        }

        if (
          state.previousType === "" &&
          ["/", "*", "+", "-", "x"].includes(element.value)
        ) {
          return _`Formula cannot start with an operator.`;
        }

        if (
          ["/", "*", "+", "-", "x"].includes(state.previousValue) &&
          ["/", "*", "+", "-", "x"].includes(element.value)
        ) {
          return _`An operator must be followed by a value, a parenthesis or a metric.`;
        }

        if (
          ["("].includes(state.previousValue) &&
          ["/", "*", "+", "-", "x"].includes(element.value)
        ) {
          return _`An operator cannot appear right after an opening parenthesis.`;
        }

        if (element.value === ")") {
          if (["/", "*", "+", "-", "(", "x"].includes(state.previousValue)) {
            return _`Unexpected closing parenthesis.`;
          }

          state.openParenthesisCount--;
          if (state.openParenthesisCount < 0) {
            return _`A closing parenthesis must be preceded by an opening parenthesis.`;
          }
        }

        if (element.value === "(") {
          state.openParenthesisCount++;
          if (state.previousType !== "" && state.previousType !== "operator") {
            return _`An opening parenthesis can only appear after a operator.`;
          }
        }
        break;

      case "metric":
        if ([")"].includes(state.previousValue)) {
          return _`A closing parenthesis can only be followed by an operator`;
        }
        if (["metric", "value"].includes(state.previousType)) {
          return _`A metric can only appear after an operator.`;
        }
        break;

      case "value":
        if ([")"].includes(state.previousValue)) {
          return _`A closing parenthesis can only be followed by an operator`;
        }
        if (["metric", "value"].includes(state.previousType)) {
          return _`A metric can only appear after an operator.`;
        }

        if (!isValidMetricValue(element.value)) {
          return _`The value ${[
            "value",
            element.value,
          ]} is not valid. Only integers and floats can be used.`;
        }
        break;
      case "linebreak":
        continue;
    }

    state.previousType = element.type;
    state.previousValue = element.value;
  }

  if (state.openParenthesisCount > 0) {
    return _`Closing parenthesis missing.`;
  }

  if (
    state.previousType === "operator" &&
    ["+", "-", "/", "x"].includes(state.previousValue)
  ) {
    return _`Formula should only ends with a value, a metric, or a closing parenthesis.`;
  }

  return "";
};
