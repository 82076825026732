import classNames from "classnames";

import { Button } from "../Button";
import { legacyTheme } from "../legacyTheme";
import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { Text } from "../Typography";

import {
  StyledFreeTrialMarketingPageSection,
  StyledFreeTrialMarketingPageSectionImage,
  StyledFreeTrialMarketingPageSectionImageSide,
  StyledFreeTrialMarketingPageSectionTextSide,
} from "./FreeTrialMarketingPageSection.styled";

export type FreeTrialMarketingPageSectionProps =
  InteractionEvents<HTMLSpanElement> &
    ClassAndStyleProps & {
      heading?: string;
      text?: string;
      image: string;
      formattedText?: React.ReactNode;
      customButtons?: React.ReactNode;
      primaryButtonLabel?: string;
      imageSide: "left" | "right";
      imageStyle?: React.CSSProperties;
      extraSpacing?: boolean;
      showViewPlans?: boolean;
      textAlign?: "left" | "center" | "right" | "justify";
      onViewPlans?: () => void;
      onRequestFreeTrial: (() => Promise<void>) | (() => void);
      primaryButtonStyle?: React.CSSProperties;
      isLoading?: boolean;
    };

export default function FreeTrialMarketingPageSection({
  heading,
  text,
  image,
  imageSide = "right",
  onRequestFreeTrial,
  className,
  style,
  formattedText,
  customButtons,
  textAlign,
  extraSpacing = false,
  showViewPlans = false,
  imageStyle = {},
  primaryButtonStyle: primaryButtonStyleOverrides,
  primaryButtonLabel = "Request a free trial",
  onViewPlans,
  isLoading,
  ...interactionEvents
}: FreeTrialMarketingPageSectionProps) {
  let primaryButtonStyle = { minWidth: 151, ...primaryButtonStyleOverrides };
  if (!showViewPlans) {
    primaryButtonStyle = {
      ...primaryButtonStyle,
      ...{ margin: "0 auto", display: "block" },
    };
  }

  return (
    <StyledFreeTrialMarketingPageSection
      {...interactionEvents}
      reverse={imageSide === "left"}
      className={classNames(className)}
      style={style}
    >
      <StyledFreeTrialMarketingPageSectionTextSide
        style={extraSpacing ? { paddingRight: "60px" } : undefined}
      >
        {heading && (
          <Text
            color={legacyTheme.colors.grey1}
            fontWeight={legacyTheme.typography.fontWeigth.w700}
            fontSize="38px"
            lineHeight="50px"
          >
            {heading}
          </Text>
        )}
        {formattedText ? (
          formattedText
        ) : (
          <Text
            textAlign="justify"
            color={legacyTheme.colors.grey5}
            fontWeight={legacyTheme.typography.fontWeigth.w400}
            fontSize={legacyTheme.typography.fontSizes.x3large}
            lineHeight="31px"
            style={{ margin: "40px 0" }}
          >
            {" "}
            {text}
          </Text>
        )}
        {customButtons ? (
          customButtons
        ) : (
          <>
            <Button
              size="large"
              style={primaryButtonStyle}
              onClick={() => void onRequestFreeTrial()}
              color="secondary"
              className="margin-right-large"
              loading={isLoading}
            >
              {primaryButtonLabel}
            </Button>
            {showViewPlans && (
              <Button
                size="large"
                style={{ minWidth: 151 }}
                onClick={onViewPlans}
              >
                View plans
              </Button>
            )}
          </>
        )}
      </StyledFreeTrialMarketingPageSectionTextSide>
      <StyledFreeTrialMarketingPageSectionImageSide
        style={{ paddingLeft: imageSide === "left" ? 10 : 0 }}
      >
        <StyledFreeTrialMarketingPageSectionImage
          style={imageStyle}
          src={`/img/marketing/${image}`}
        />
      </StyledFreeTrialMarketingPageSectionImageSide>
    </StyledFreeTrialMarketingPageSection>
  );
}
