import {
  ConnectorIconNames,
  ConnectorIconWrapperList,
  Tooltip,
} from "../../../icecube-ux";

interface ConnectorIconListProps {
  names: ConnectorIconNames[];
  size?: "tiny" | "small" | "large";
}

const ConnectorIconList = ({
  names,
  size = "small",
}: ConnectorIconListProps) => {
  return names.filter((d) => d !== undefined).length > 0 ? (
    <Tooltip direction="top" content={names.join(", ")}>
      <ConnectorIconWrapperList names={names || []} max={4} size={size} />
    </Tooltip>
  ) : (
    <></>
  );
};

export default ConnectorIconList;
