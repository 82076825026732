import styled from "styled-components";

import { Button, Flex, Text, theme } from "../../../icecube-ux";
import { _ } from "../../../languages/helper";

import { AddonIcon, AddonIconNames } from "./AddonIcon";

interface AddonCardProps {
  title: string;
  subtitle: string;
  paragraph: string;
  iconName: AddonIconNames;
  iconBackground: string;
  onClick: () => void;
}

const StyledAddonCard = styled(Flex)`
  padding: 12px;
  border-radius: ${theme.borderRadius.r6px};
  border: 1px solid ${theme.colors.borderLight};
  width: 50%;
`;

const StyledIconWrapper = styled(Flex)<{ iconBackground: string }>`
  width: 20px;
  height: 20px;
  background: ${({ iconBackground }) => iconBackground};
`;

export const AddonCard = ({
  title,
  subtitle,
  paragraph,
  iconName,
  iconBackground,
  onClick,
}: AddonCardProps) => {
  return (
    <StyledAddonCard flexDirection="column">
      <Flex justifyContent="space-between">
        <Text variant="body13Regular" color={theme.colors.text110}>
          {title}
        </Text>
        <StyledIconWrapper
          alignItems="center"
          justifyContent="center"
          iconBackground={iconBackground}
        >
          <AddonIcon name={iconName} size={12} />
        </StyledIconWrapper>
      </Flex>
      <Text
        variant="body12Regular"
        color={theme.colors.primary80}
        style={{ margin: "4px 0 12px" }}
      >
        {subtitle}
      </Text>
      <Text
        variant="body12Regular"
        color={theme.colors.text90}
        style={{ marginBottom: "12px" }}
      >
        {paragraph}
      </Text>
      <Button
        onClick={onClick}
        size="small"
        color="ghost"
        style={{ width: "74px", marginTop: "auto" }}
      >{_`Learn more`}</Button>
    </StyledAddonCard>
  );
};
