import { ReactElement, useMemo } from "react";

import { FullWidthWrapper } from "../FullWidthWrapper";
import { RatioWrapper } from "../RatioWrapper";
import WireframeLoader from "../WireframeLoader/WireframeLoader";

import { ChartBarsLoader, ChartLoaderContainer } from "./ChartLoader.styled";

export interface ChartLoaderProps {
  withMargin?: boolean;
  height?: number;
}

export const ChartLoader = ({
  withMargin = true,
  height,
}: ChartLoaderProps) => {
  const bars = useMemo(() => {
    const n = 20;

    return (
      <ChartBarsLoader>
        {Array.from({ length: n }, () => Math.random() * 90 + 10 + "%").map(
          (mask, i) => (
            <WireframeLoader
              key={`l-${i}`}
              height={mask}
              width={100 / (n * 2) + "%"}
              radius={100}
              style={{
                left: `${(100 / n) * i}%`,
                position: "absolute",
                bottom: -12,
              }}
            />
          ),
        )}
      </ChartBarsLoader>
    );
  }, []);

  const Wrapper = ({ children }: { children: ReactElement }) =>
    height ? (
      <FullWidthWrapper height={height}>{children}</FullWidthWrapper>
    ) : (
      <RatioWrapper onStyle={true}>{children}</RatioWrapper>
    );

  return (
    <>
      <ChartLoaderContainer style={{ margin: withMargin ? "0 42px" : "" }}>
        <Wrapper>{bars}</Wrapper>
      </ChartLoaderContainer>
    </>
  );
};
