export const colors = {
  white: "#FFFFFF",
  black: "#000000",
  transparent: "transparent",

  bg: {
    body: "#F0F9FF",
    light: "#F7F8FD",
    lightPlus: "#FAFBFE",
    darkBlue: "#373375",
    inputs: {
      light: "#F7F8FD",
      lightHover: "#F2F4FB",
      disabled: "#F8F9FC",
      lightOnDark: "#EAEDF6",
      lightOnDarkHover: "#E1E1EF",
      disabledOnDark: "  #EFEFF8",
    },
  },

  borders: {
    default: "#EAE9FF",
    hover: "#DAD8FF",
  },

  text: {
    regular: "#55557D",
    regularHover: "#303051",
    regularOnDark: "#0C0C1A",
    placeholder: "#B2B6D7",
    placeholderDark: "#999FC8",
  },

  warning1darken: "#DB6D0A",
  warning1: "#FA9826",
  warning7: "#FFEBA2",
  warning8: "#FFF3C6",
  warning9: "#FFF8DF",
  warning10: "#FFFCEF",

  success1darken: "#35b394",
  success1: "#1DBC8C",
  success3: "#20CF9A",
  success5: "#32DFAB",
  success7: "#C4F0E6",
  success8: "#D4F8EF",
  success9: "#D9F6EF",
  sucess10: "#F0FDFA",

  error1darken: "#F92572",
  error1: "#FA3C81",
  error8: "#FDC0D6",
  error9: "#FEE7EF",
  error10: "#FFF5F9",

  primary1darken: "#4337FE",
  primary1: "#5A50FE",
  primary2: "#665DFE",
  primary3: "#776FFE",
  primary4: "#8780FE",
  primary5: "#9892FE",
  primary6: "#A8A3FE",
  primary7: "#B9B5FF",
  primary8: "#DAD8FF",
  primary9: "#EAE9FF",
  primary10: "#F9F9FB",

  grey1: "#333352",
  grey2: "#3E3E63",
  grey3: "#938FC5",
  grey4: "#646464",
  grey5: "#7e7eae",
  grey6: "#959BC7",
  grey7: "#A9AED4",
  grey8: "#C0C4E2",
  grey9: "#D5D9F0",
  grey10: "#E2E4F4",
  grey11: "#EDEFF8",
  grey12: "#F6F6FB",
  grey13: "#FCFCFD",
} as const;

export const legacyTheme = {
  colors,

  shadows: {
    small:
      "0px 2px 4px rgba(43, 150, 210, 0.1), 0px 12px 32px rgba(43, 150, 210, 0.05)",
    regular:
      "0px 8px 24px rgba(47, 41, 156, 0.08), 0px 2px 4px rgba(47, 41, 156, 0.12)",
    darkBlue:
      "0px 1px 2px rgba(30, 26, 104, 0.08), 0px 4px 8px rgba(78, 73, 164, 0.15)",
    popup: "box-shadow: 0px 8px 54px rgba(0, 0, 0, 0.35)",
  },

  textShadows: {
    regular: "0px 1px 1px rgba(47, 41, 156, 0.22)",
  },

  borderRadius: {
    tiny: "4px",
    small: "8px",
    default: "10px",
    large: "12px",
    all: "50%",
  },

  padding: {
    none: "0",
    tiny: "2px",
    small: "4px",
    fair: "6px",
    regular: "8px",
    medium: "10px",
    large: "12px",
    xlarge: "16px",
    xxlarge: "18px",
    x3large: "20px",
    x4large: "22px",
    x5large: "24px",
  },

  margin: {
    tiny: "2px",
    small: "4px",
    regular: "8px",
    large: "12px",
    xlarge: "16px",
    xxlarge: "18px",
    x3large: "20px",
    x4large: "22px",
    x5large: "24px",
    x6large: "48px",
  },

  zIndexes: {
    layer1: 50000,
    layer2: 100000,
    layer3: 150000,
    layer4: 200000,
    layer5: 250000,
    layer6: 300000,
    layer7: 350000,
  },

  typography: {
    fontSizes: {
      tiny: "8px",
      small: "10px",
      regular: "11px",
      large: "12px",
      xlarge: "14px",
      xxlarge: "16px",
      x3large: "18px",
      x4large: "20px",
      x5large: "22px",
      x6large: "24px",
    },
    lineHeights: {
      tiny: "10px",
      small: "14px",
      regular: "18px",
      large: "20px",
      xlarge: "22px",
      xxlarge: "24px",
    },
    fontWeigth: {
      w300: 300,
      w400: 400,
      w500: 500,
      w600: 600,
      w700: 700,
    },
    default: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
    },
  },
} as const;
