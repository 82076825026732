import classNames from "classnames";
import { useCallback } from "react";
import styled, { css } from "styled-components";

import { Flex } from "../../Flex";
import { Grid } from "../../Grid";
import { IconButton } from "../../IconButton";
import { legacyTheme } from "../../legacyTheme";
import { Loader } from "../../Loader";
import PercentChip from "../../PercentChip";
import { theme } from "../../theme";
import { Tooltip } from "../../Tooltip";
import { Text } from "../../Typography";
import { WireframeLoader } from "../../WireframeLoader";
import { useReportTable } from "../ReportTable.context";

import { FolderStructureDecorator } from "./FolderStructureDecorator";
import { ReportTableFooterColumn } from "./ReportTableFooterColumns";
import { ReportTableBodyCell } from "./types";

const StyledTooltipIconButton = styled.div`
  position: absolute;
  z-index: 2;
  top: 3px;
  left: 6px;
  background: linear-gradient(
    90deg,
    #f4f6fa 0%,
    #f4f6fa 60%,
    rgba(244, 246, 250, 0) 100%
  );
`;
const StyledContentWrapper = styled.div<{
  expandDepth?: number;
  leftPart?: boolean;
}>`
  margin-left: ${(p) => (p.expandDepth ? p.expandDepth * 20 : 0)}px;
  display: block;
`;

const StyledNormalText = styled(StyledContentWrapper)`
  align-items: center;
`;

const StyledOverText = styled(StyledContentWrapper)<{
  clickable?: boolean;
}>`
  ${(props) =>
    props.clickable &&
    css`
      &:hover {
        color: ${theme.colors.primary100};
        cursor: pointer;
      }
    `}
`;

const StyledReportTableBodyCell = styled(Grid).attrs({
  alignItems: "center",
})<{
  isSub?: boolean;
  leftPart: boolean;
  forceAlignLeft?: boolean;
  color?: string;
  background?: string;
  hoverAnchor: "top" | "bottom";
  cellSize: "regular" | "big";
  borderColor?: string;
}>`
  margin-top: ${(p) => (p.isSub ? "-4px" : "0")};
  position: relative;
  height: 26px;
  box-sizing: border-box;
  background: ${(p) =>
    p.background ||
    (p.leftPart
      ? theme.colors.bgLight30
      : p.isSub
      ? theme.colors.grey40
      : theme.colors.bgLight10)};
  border-radius: ${(p) => (p.isSub ? 0 : theme.borderRadius.r2px)};
  padding: 4px 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${(p) =>
    p.leftPart ? theme.colors.text100 : p.color ?? theme.colors.text90};
  text-align: ${(p) => (p.leftPart || p.forceAlignLeft ? "left" : "right")};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border: ${(p) =>
    p.isSub
      ? "none"
      : `1px solid ${p.borderColor || theme.colors.borderLight}`};

  ${(p) => {
    if (p.cellSize === "big") {
      const justifyContent = p.leftPart ? "flex-start" : "space-between";
      return css`
        height: 44px;
        padding: 4px 8px;
        display: inline-flex;
        justify-content: ${justifyContent};
      `;
    }
  }}

  &.secondary {
    background: #f4f6fa;
    color: #b0b0d6;
  }

  &:hover {
    overflow: visible;
  }

  ${StyledNormalText} {
    overflow: hidden;
  }

  ${StyledOverText} {
    display: none;
    position: absolute;
    background: inherit;
    border-radius: inherit;
    border: 1px solid ${theme.colors.borderLight};
    ${(p) => (p.hoverAnchor === "top" ? "top: -1px" : "bottom: -1px")};
    left: -1px;
    right: -1px;
    z-index: 1;
    padding: 3px 8px;
    word-break: break-all;
    overflow-wrap: break-word;
    white-space: normal;
  }

  &:hover ${StyledOverText} {
    display: block;
    ${(p) => {
      if (p.cellSize === "big") {
        return css`
          display: none;
        `;
      }
    }}
  }

  &.rightAlign {
    text-align: right;
  }

  &.rowHover {
    background: ${(p) =>
      p.leftPart ? theme.colors.grey50 : theme.colors.bgLight30};
    cursor: pointer;
  }

  &.withSubtext ${StyledNormalText} {
    padding-top: 4px;
    padding-bottom: 4px;
    word-break: balanced;
    overflow-wrap: break-word;
    text-wrap: wrap;
    line-height: 12px;
  }

  & ${StyledTooltipIconButton} {
    display: none;
  }

  &:hover ${StyledTooltipIconButton} {
    display: block;
  }
`;

const StyledReportTableBodyCellIconWrapper = styled.span`
  margin-right: 4px;
`;

const StyledReportTableBodyCellSubText = styled.div`
  grid-column-start: span 2;
  text-align: center;
  font-weight: 500;
  font-size: 9px;
  color: ${legacyTheme.colors.text.placeholder};
`;

const CLICKABLE_CELL_STYLE = (color?: {
  color: string;
  background: string;
}) => ({
  cursor: "pointer",
  color: color?.color ?? theme.colors.primary100,
});

const CellContent = ({
  cell,
  leftPart,
  hovered,
  formatter,
  footerColumnValue,
  cellIndex,
  lineIndex,
}: {
  cell: ReportTableBodyCell;
  leftPart: boolean;
  hovered: boolean;
  formatter: Function | undefined;
  footerColumnValue?: string | number;
  cellIndex: number;
  lineIndex: number;
}) => {
  const cellFormattedValue =
    (formatter?.(
      cell.value,
      cellIndex,
      lineIndex,
      footerColumnValue,
    ) as string) ?? cell.value;
  return (
    <Flex
      justifyContent={leftPart ? "space-between" : "flex-end"}
      alignItems={"center"}
    >
      <Flex style={{ overflow: "hidden", width: "100%" }}>
        {cell.expandDepth ? (
          <FolderStructureDecorator
            depth={cell.expandDepth}
            lastPerDepth={cell.lastPerDepth || []}
          />
        ) : (
          <></>
        )}
        {cell.enableRowExpand ? (
          <Tooltip
            content={
              <Text variant="body9Regular" color={theme.colors.white100}>
                {cell.expandButtonTooltip}
              </Text>
            }
            style={{
              display: "flex",
              alignItems: "center",
            }}
            alignCenter
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Flex display={"inline-flex"} style={{ marginRight: 4 }}>
              <IconButton
                data-cy="drill-down-button"
                size="small"
                color="dark"
                name={cell.rowExpanded ? "ArrowDown" : "CaretNext"}
                onClick={() => {
                  if (cell.onRowExpand) {
                    cell.onRowExpand();
                  }
                }}
                style={{
                  width: 17,
                  height: 17,
                }}
              />
            </Flex>
          </Tooltip>
        ) : (
          <></>
        )}
        {cell.iconComponent !== undefined ? (
          <StyledReportTableBodyCellIconWrapper>
            {cell.iconComponent}
          </StyledReportTableBodyCellIconWrapper>
        ) : (
          <></>
        )}

        <div
          style={{
            width: "100%",
            display: "inline-block",
            marginLeft:
              !(cell?.enableRowExpand ?? false) && (cell?.expandDepth ?? 0) > 0
                ? 2
                : 0,
            ...(cell.onCellClick ? CLICKABLE_CELL_STYLE(cell.colors) : {}),
          }}
        >
          {cell.loading ? (
            <Loader size={8} />
          ) : cell.cellTooltipText ? (
            <Tooltip
              content={
                <Text variant="body9Regular" color={theme.colors.white100}>
                  {cell.cellTooltipText}
                </Text>
              }
              wrapperStyle={{
                display: "inline-flex",
                ...(cell.onCellClick ? CLICKABLE_CELL_STYLE(cell.colors) : {}),
              }}
            >
              {cellFormattedValue}
            </Tooltip>
          ) : (
            cell.renderer ?? cellFormattedValue
          )}
        </div>
        {cell.subText && (
          <StyledReportTableBodyCellSubText>
            {cell.subText}
          </StyledReportTableBodyCellSubText>
        )}
      </Flex>

      <Flex gap={4}>
        {cell.additionalRightComponentOnHover && (
          <div
            style={{
              opacity: hovered ? 1 : 0,
            }}
          >
            {cell.additionalRightComponentOnHover}
          </div>
        )}
        {cell.rightComponent}
      </Flex>
    </Flex>
  );
};
export const ReportTableBodyCellDisplay = ({
  cell,
  formatter,
  isSub,
  secondary,
  leftPart,
  readOnly,
  hoverAnchor,
  footerColumnValue,
  cellSize = "regular",
  cellIndex,
  lineIndex,
}: {
  cell: ReportTableBodyCell;
  formatter: Function | undefined;
  isSub: boolean;
  secondary?: boolean;
  leftPart: boolean;
  readOnly?: boolean;
  hoverAnchor: "top" | "bottom";
  footerColumns?: ReportTableFooterColumn[];
  cellSize?: "regular" | "big";
  footerColumnValue?: string | number;
  cellIndex: number;
  lineIndex: number;
}) => {
  const { onRowHoverEnd, onRowHoverStart, hoveredRowIndex, allowHover } =
    useReportTable();
  const shouldHover = readOnly && allowHover;

  const onHoverStart = useCallback(() => {
    if (lineIndex !== undefined) {
      onRowHoverStart(lineIndex);
    }
  }, [lineIndex, onRowHoverStart]);

  const getGrid = (c: ReportTableBodyCell) => {
    if (c.progression !== undefined && c.hideValue && !c.rightComponent) {
      return "1fr auto";
    }
    if (!c.hideValue && c.rightComponent) {
      return "auto 1fr auto";
    }
    return "auto 1fr";
  };
  return cell.loadingInBatch ? (
    <WireframeLoader
      key={`cell-${cellIndex}`}
      background={leftPart ? theme.colors.grey50 : theme.colors.grey40}
      height={"26px"}
      width={"100%"}
      style={{
        marginTop: isSub ? "-4px" : undefined,
      }}
    />
  ) : (
    <StyledReportTableBodyCell
      isSub={isSub}
      cellSize={cellSize}
      leftPart={leftPart}
      key={`cell-${cellIndex}`}
      className={classNames({
        secondary: secondary,
        rightAlign: cell.forceDisplayAsValue,
        withSubtext: cell.subText,
        rowHover: shouldHover && hoveredRowIndex === lineIndex,
      })}
      gridTemplateColumns={getGrid(cell)}
      hoverAnchor={hoverAnchor}
      forceAlignLeft={cell.forceAlignLeft}
      color={cell.colors?.color}
      borderColor={cell.colors?.borderColor}
      background={cell.colors?.background}
      {...(shouldHover && {
        onMouseLeave: onRowHoverEnd,
        onMouseEnter: onHoverStart,
      })}
    >
      {cell.tooltipIcon && cell.tooltipText && (
        <StyledTooltipIconButton>
          <Tooltip content={cell.tooltipText}>
            <IconButton size="small" color="dark" name={cell.tooltipIcon} />
          </Tooltip>
        </StyledTooltipIconButton>
      )}
      {cell.hideValue && <div />}
      {cell.progression !== undefined ? (
        <PercentChip
          onBackground={
            cell.progressionBackground === "dark" ? "dark" : "light"
          }
          invertedEvolution={cell.invertedEvolution}
          evolution={`${cell.progression}%`}
          negative={cell.progression < 0}
          small
          style={{ marginTop: "-2px" }}
        />
      ) : (
        <div />
      )}
      {!cell.hideValue && (
        <>
          <StyledNormalText expandDepth={cell.expandDepth} leftPart={leftPart}>
            <CellContent
              hovered={false}
              cell={cell}
              leftPart={leftPart}
              formatter={formatter}
              cellIndex={cellIndex}
              lineIndex={lineIndex}
              footerColumnValue={footerColumnValue}
            />
          </StyledNormalText>
          <StyledOverText
            clickable={cell.onCellClick !== undefined}
            onClick={
              cell.onCellClick
                ? () => {
                    cell.onCellClick?.(cell);
                  }
                : undefined
            }
          >
            <StyledContentWrapper
              expandDepth={cell.expandDepth}
              leftPart={leftPart}
              style={{
                position: "initial",
              }}
            >
              <CellContent
                hovered={true}
                cell={cell}
                leftPart={leftPart}
                formatter={formatter}
                cellIndex={cellIndex}
                lineIndex={lineIndex}
                footerColumnValue={footerColumnValue}
              />
            </StyledContentWrapper>
          </StyledOverText>
        </>
      )}
    </StyledReportTableBodyCell>
  );
};
