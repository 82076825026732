import styled, { css } from "styled-components";

import { legacyTheme } from "../legacyTheme";
import { theme } from "../theme";

type StyledBlockProps = {
  variant?: "normal" | "warning";
};

export const StyledBlock = styled.div<StyledBlockProps>`
  border-radius: ${legacyTheme.borderRadius.default};

  ${({ variant }) => {
    if (variant === "normal") {
      return css`
        background: ${legacyTheme.colors.white};
        box-shadow: ${legacyTheme.shadows.small};
      `;
    } else if (variant === "warning") {
      return css`
        background: ${theme.colors.warning10};
        color: ${theme.colors.warning80};
        border: solid 1px ${theme.colors.warning40};
      `;
    } else if (variant === "info") {
      return css`
        background: ${theme.colors.primary10};
        color: ${theme.colors.primary100};
        border: solid 1px ${theme.colors.primary30};
      `;
    }
  }}
`;
