import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  key: "facebook-ads",
  category: INTEGRATION_CATEGORIES.advertising,
  image: "Facebook",
  dependsOnAccounts: true,
  backgroundColor: theme.customColors.facebookBg,
  borderColor: theme.customColors.facebookBorder,
  label: "Facebook Ads",
  adPlatformChannelName: "Facebook Ads",
  utmGuide: "6778955",
  utmCode:
    "utm_source=facebook&campaign_id={{campaign.id}}&ad_id={{ad.id}}&utm_medium=cpc&utm_campaign={{campaign.name}}&utm_term={{adset.name}}&utm_content={{ad.name}}&adset_id={{adset.id}}",
  get description() {
    return _`Online advertising platform from ${["provider", "Facebook"]}`;
  },
  canReconnectOAuth: true,
  addonTooltip: "capi",
});
