import { ChipRadio, Text, TextArea } from "../../../icecube-ux";
import { _ } from "../../../languages/helper";
import {
  DEFAULT_CUSTOM_METRIC_FORMATTING,
  CustomMetricFormattingType,
  CustomMetricDefinition,
} from "../../../types/synthesizer";

import CurrencyFormattingOptions from "./CurrencyFormattingOptions";
import NumberFormattingOptions from "./NumberFormattingOptions";

interface MetricFormattingProps {
  loading: boolean;
  metric: CustomMetricDefinition;
  onChange: (metric: CustomMetricDefinition) => void;
}

export default function MetricFormatting({
  metric,
  onChange,
}: MetricFormattingProps) {
  return (
    <div className="border-top padding-xlarge">
      <ChipRadio
        label={_`Format`}
        name="formatting-type"
        value={metric.formatting.type}
        options={[
          { label: _`Number`, value: "number" },
          { label: _`Percent`, value: "percent" },
          { label: _`Currency`, value: "currency" },
          { label: _`Duration`, value: "timedelta" },
        ]}
        onChange={(formattingType) => {
          onChange({
            ...metric,
            formatting: {
              ...DEFAULT_CUSTOM_METRIC_FORMATTING,
              type: formattingType as CustomMetricFormattingType,
            },
          });
        }}
      />
      <Text
        variant="BodyRegularBold"
        className="margin-top-xlarge margin-bottom-small"
      >
        Description
      </Text>
      <TextArea
        placeholder={_`Explain what this metric is used for...`}
        value={metric.description || ""}
        onChange={(decription) =>
          onChange({
            ...metric,
            description: `${decription}`,
          })
        }
        rows={4}
      />

      {metric.formatting.type === "number" && (
        <NumberFormattingOptions metric={metric} onChange={onChange} />
      )}

      {metric.formatting.type === "currency" && (
        <CurrencyFormattingOptions metric={metric} onChange={onChange} />
      )}
    </div>
  );
}
