import React, { useEffect } from "react";

import { IconButton } from "../IconButton";

import {
  StyledPopupClosingIcon,
  StyledPopupContent,
  StyledPopupShadow,
} from "./FullScreenPopup.styled";

interface FullScreenPopupProps {
  onClose: () => void;
  children?: React.ReactNode;
}

export default function FullScreenPopup({
  onClose,
  children,
}: FullScreenPopupProps) {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <StyledPopupShadow>
      <StyledPopupClosingIcon>
        <IconButton
          name="Close"
          size="large"
          color="transparentOnDark"
          onClick={onClose}
        />
      </StyledPopupClosingIcon>
      <StyledPopupContent>{children}</StyledPopupContent>
    </StyledPopupShadow>
  );
}
