/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, {
  Children,
  cloneElement,
  Component,
  createRef,
  ReactElement,
} from "react";

import ScrollSyncContext from "./ScrollSyncContext";

interface ScrollSyncPaneProps {
  attachTo?: { current: HTMLDivElement | null };
  children?: React.ReactNode;
  group?: string | string[] | undefined;
  enabled?: boolean | undefined;
}

interface ScrollSyncContextType {
  registerPane: (node: HTMLDivElement, groups: string[]) => void;
  unregisterPane: (node: HTMLDivElement, groups: string[]) => void;
}

export default class ScrollSyncPane extends Component<ScrollSyncPaneProps> {
  static contextType = ScrollSyncContext;
  context!: ScrollSyncContextType;

  childRef: React.RefObject<HTMLDivElement>;
  node: HTMLDivElement | null;

  static defaultProps = {
    group: "default",
    enabled: true,
  };

  constructor(props: ScrollSyncPaneProps) {
    super(props);
    this.childRef = createRef();
    this.node = null;
  }

  componentDidMount() {
    if (this.props.enabled) {
      this.updateNode();
      if (this.node) {
        this.context.registerPane(this.node, this.toArray(this.props.group));
      }
    }
  }

  componentDidUpdate(prevProps: ScrollSyncPaneProps) {
    if (this.props.attachTo !== prevProps.attachTo) {
      if (this.node) {
        this.context.unregisterPane(this.node, this.toArray(prevProps.group));
      }
      this.updateNode();
      if (this.node) {
        this.context.registerPane(this.node, this.toArray(prevProps.group));
      }
    }
    if (this.node && this.props.enabled !== prevProps.enabled) {
      if (this.props.enabled) {
        this.context.registerPane(this.node, this.toArray(prevProps.group));
      } else {
        this.context.unregisterPane(this.node, this.toArray(prevProps.group));
      }
    }
    if (
      this.node &&
      this.props.enabled &&
      this.props.group !== prevProps.group
    ) {
      this.context.unregisterPane(this.node, this.toArray(prevProps.group));
      this.context.registerPane(this.node, this.toArray(this.props.group));
    }
  }

  componentWillUnmount() {
    if (this.node && this.props.enabled) {
      this.context.unregisterPane(this.node, this.toArray(this.props.group));
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
  toArray = (groups: any) => [].concat(groups);

  updateNode = () => {
    if (this.props.attachTo) {
      this.node = this.props.attachTo.current;
    } else {
      this.node = this.childRef.current;
    }
  };

  render() {
    if (this.props.attachTo) {
      return this.props.children;
    }
    return cloneElement(Children.only(this.props.children as ReactElement), {
      ref: this.childRef,
    });
  }
}
