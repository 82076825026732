import { theme } from "../../theme";

export const Annotation = ({
  x,
  y,
  totalCount,
}: {
  x: number;
  y: number;
  totalCount: number;
}) => (
  <g>
    <foreignObject x={x} y={y} width={14} height={13}>
      <svg width="100%" height="100%">
        <path
          d="M0.335938 6.06491C0.335938 2.7512 3.02223 0.0649109 6.33594 0.0649109H7.33594C10.6496 0.0649109 13.3359 2.7512 13.3359 6.06491C13.3359 9.37862 10.6496 12.0649 7.33594 12.0649H0.335938V6.06491Z"
          fill={theme.colors.primary100}
          style={{ cursor: "pointer" }}
        />
        <text
          x="6.33594"
          y="6"
          fill="white"
          textAnchor="middle"
          alignmentBaseline="central"
          fontSize="8px"
          fontWeight="bold"
          fontFamily="Poppins"
          cursor="pointer"
        >
          {totalCount}
        </text>
      </svg>
    </foreignObject>
  </g>
);
