import React, { createContext, useContext, useMemo } from "react";

import { BREAKPOINTS } from "../utils";

export interface DeviceTypeContextProps {
  isMobileOrTablet: boolean;
  isMobile: boolean;
  isTablet: boolean;
  windowSize: { width: number; height: number };
}

export const DeviceTypeContext = createContext<DeviceTypeContextProps | null>(
  null,
);

export function ProvideDeviceType({ children }: { children: React.ReactNode }) {
  const provider = useProvideDeviceType();
  return (
    <DeviceTypeContext.Provider value={provider}>
      {children}
    </DeviceTypeContext.Provider>
  );
}

export const useDeviceType = () => {
  const context = useContext(DeviceTypeContext);
  if (context === null) {
    throw Error("DeviceType context not provided");
  }
  return context;
};

const useProvideDeviceType = () => {
  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  React.useEffect(() => {
    const handler = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", handler);
    return () => {
      window.removeEventListener("resize", handler);
    };
  }, []);
  const isMobile = windowSize.width <= BREAKPOINTS.xs;
  const isTablet = windowSize.width <= BREAKPOINTS.s;

  const returnValue = useMemo(() => {
    return {
      isMobileOrTablet: isMobile || isTablet,
      isMobile,
      isTablet,
      windowSize,
    } as const;
  }, [isMobile, isTablet, windowSize]);

  return returnValue;
};
