import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import {
  CREATION_CUSTOM_METRIC_ID,
  EMPTY_STATE_CUSTOM_METRIC_ID,
  isCustomMetricDefinitionDifferent,
  useCustomElementEditors,
} from "../../../hooks/customElementEditors";
import { useMetricList } from "../../../hooks/metricList";
import {
  Box,
  Button,
  EmptyState,
  Flex,
  Grid,
  IconButton,
  Input,
  Stack,
  TabSelector,
  theme,
} from "../../../icecube-ux";
import TabList from "../../../icecube-ux/TabList";
import { TabElement } from "../../../icecube-ux/TabSelector/TabSelector";
import { _ } from "../../../languages/helper";
import {
  CustomMetricDefinition,
  IMetricsList,
} from "../../../types/synthesizer";
import { removeDuplicates } from "../../../utils/utils";

import FormulaError from "./components/FormulaError";
import CustomMetricHeader from "./CustomMetricHeader";
import CustomMetricTitle from "./CustomMetricTitle";
import FormulaEditor from "./FormulaEditor";
import Sidebar from "./Sidebar";
import { extractCustomMetricId } from "./utils";
import "./central-form.css";

interface CentralFormProps {
  metric?: CustomMetricDefinition;
  error?: string;
  onMetricChange: (m: CustomMetricDefinition) => void;
  metricList: IMetricsList;
  loading: boolean;
}

const StyledTabList = styled(TabList)`
  display: flex;
  flex-direction: column;
  word-break: break-all;
`;

// add force width to last tab to display the full bottom border
// the selected tab will have white bottom border
const getTabElements = (
  customMetricsDefinitions: CustomMetricDefinition[],
  openedCustomMetrics: CustomMetricDefinition[],
  selectedTab: string,
  closeMetric: (id: string) => void,
) => {
  const tabs = openedCustomMetrics.map<TabElement>((customMetric) => {
    const customMetricIdString = customMetric.id.toString();
    const originalCustomMetric = customMetricsDefinitions.find(
      (m) => m.id.toString() === customMetricIdString,
    );
    const isDiff = isCustomMetricDefinitionDifferent(
      customMetric,
      originalCustomMetric,
    );

    return {
      label:
        customMetricIdString === CREATION_CUSTOM_METRIC_ID
          ? _`Create custom metric`
          : customMetric.title,
      background:
        selectedTab === customMetricIdString
          ? theme.colors.white100
          : theme.colors.bgLight10,
      borderBottom:
        selectedTab === customMetricIdString
          ? theme.colors.white100
          : theme.colors.borderLight,
      value: customMetricIdString,
      rightIcon: (
        <Flex alignItems={"center"} gap={4}>
          {isDiff ? (
            <div
              style={{
                color: theme.colors.warning100,
                fontSize: 24,
              }}
            >
              &#x2022;
            </div>
          ) : (
            <></>
          )}
          <IconButton
            name={"Close"}
            onClick={() => {
              closeMetric(customMetricIdString);
            }}
            size={"tiny"}
          />
        </Flex>
      ),
    };
  });
  tabs.push({
    label: "",
    background: theme.colors.bgLight10,
    borderBottom: theme.colors.borderLight,
    value: "empty-tab-for-nice-bottom-border",
    forceWidth: "100%",
    disabled: true,
  });
  return tabs;
};

const SIDEBAR_WIDTH_AND_SPACING = 250;

export default function CentralForm({
  metric,
  error,
  onMetricChange,
  metricList,
  loading,
}: CentralFormProps) {
  const { customMetricsDefinitions } = useMetricList();
  const {
    editingMetrics,
    openedMetricEditor,
    createMetric,
    editMetric,
    deleteMetric,
    closeMetric,
  } = useCustomElementEditors();
  const [sidebarValue, setSidebarValue] = useState("metrics");
  const [search, setSearch] = useState("");
  const [searchCustomMetric, setSearchCustomMetric] = useState("");
  const [formulaInputValue, setFormulaInputValue] = useState("");
  const [filterOpen, setFilterOpen] = useState(-1);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [filteredSelectedIndex, setFilteredSelectedIndex] = useState(0);

  const handleToggleOpen = (index: number) => {
    setFilterOpen((v) => (index === v ? -1 : index));
  };

  const getSelectedMetrics = (): string[] => {
    if (!metric) {
      return [];
    }
    return removeDuplicates(
      metric.elements.filter((e) => e.type === "metric").map((e) => e.value),
    );
  };

  const addMetric = (metricName: string) => {
    if (!metric) {
      return;
    }
    const newElements = [...metric.elements];
    newElements.splice(cursorPosition, 0, {
      type: "metric",
      value: metricName,
      filters: [],
    });
    onMetricChange({
      ...metric,
      elements: [...newElements],
    });
    setCursorPosition(cursorPosition + 1);
    setSearch("");
    setSidebarValue("filters");
  };

  const handleMetricChange = (newValue: CustomMetricDefinition) => {
    if (!metric) {
      return;
    }
    if (
      metric.elements.length > 0 &&
      newValue.elements.length > metric.elements.length &&
      metric.elements[metric.elements.length - 1].type === "metric"
    ) {
      setSidebarValue("filters");
    }
    onMetricChange(newValue);
  };

  const handleOpenFilter = (index: number) => {
    setFilterOpen(index);
    setSidebarValue("filters");
  };

  const handleFilterSelectChange = useCallback(
    (p: number) => setFilteredSelectedIndex(p),
    [],
  );

  useEffect(() => {
    if (search !== "") {
      setSidebarValue("metrics");
    }
  }, [search]);

  return (
    <Grid gridTemplateRows={"50px auto"}>
      <CustomMetricHeader />
      <Grid
        gridTemplateColumns={"220px 1fr"}
        gap={0}
        className="custom-metric-form-container"
      >
        <Grid
          gridTemplateRows={`61px calc(${window.innerHeight}px - 230px)`}
          style={{
            borderRight: `1px solid ${theme.colors.borderLight}`,
          }}
        >
          <Stack
            gap={10}
            style={{
              padding: 16,
              borderBottom: `1px solid ${theme.colors.borderLight}`,
            }}
          >
            <Button
              size={"small"}
              onClick={() => {
                createMetric();
              }}
              leftIcon={"Add"}
              label={_`New custom metric`}
              color={"tertiary"}
            />
          </Stack>
          <Stack
            gap={10}
            style={{
              padding: 16,
              width: 220,
              overflowY: "scroll",
            }}
          >
            <Input
              autoComplete={"off"}
              block
              size={"small"}
              leftIcon="Search"
              rightIcon={searchCustomMetric !== "" ? "CloseCircle" : undefined}
              value={searchCustomMetric}
              onChange={(v) => setSearchCustomMetric(v as string)}
              placeholder={_`Search...`}
              onClickOnRightIcon={() => setSearchCustomMetric("")}
            />
            <StyledTabList
              stacked
              size={"small"}
              currentTab={openedMetricEditor || EMPTY_STATE_CUSTOM_METRIC_ID}
              showRightComponentOnlyOnHover
              tabs={Object.values(metricList.customMetrics || {})
                .filter((m) =>
                  m.label
                    .toLowerCase()
                    .includes(searchCustomMetric.toLowerCase()),
                )
                .sort((m, n) => m.label.localeCompare(n.label))
                .map((m) => ({
                  label: m.label,
                  value: extractCustomMetricId(m.key),
                  rightComponent: (
                    <IconButton
                      name={"Delete"}
                      size={"tiny"}
                      onClick={() => {
                        deleteMetric(extractCustomMetricId(m.key));
                      }}
                    />
                  ),
                }))}
              onChange={(newTab) => {
                editMetric(newTab);
              }}
            />
          </Stack>
        </Grid>
        {metric ? (
          <Grid gridTemplateRows={"38px auto"}>
            <TabSelector
              gap={0}
              labelTextVariant={"body12Regular"}
              maxTabSize={280}
              tabBackground={theme.colors.bgLight10}
              tabBorderRightColor={theme.colors.borderLight}
              tabBorderBottomColor={theme.colors.borderLight}
              tabSpacingNumber={30}
              elements={getTabElements(
                customMetricsDefinitions,
                editingMetrics,
                metric.id.toString(),
                closeMetric,
              )}
              onClick={(id) => {
                editMetric(id);
              }}
              selectedValue={metric.id.toString()}
              availableWidth={window.innerWidth - SIDEBAR_WIDTH_AND_SPACING}
            />
            <Grid
              gridTemplateColumns={"1fr 400px"}
              gridTemplateRows={`calc(${window.innerHeight}px - 230px)`}
            >
              <Box
                padding={16}
                gap={16}
                display={"flex"}
                flexDirection={"column"}
              >
                <CustomMetricTitle
                  loading={loading}
                  title={metric.title}
                  onTitleChange={(title) =>
                    onMetricChange({ ...metric, title })
                  }
                />
                <FormulaEditor
                  text={formulaInputValue}
                  onTextChange={(v) => setFormulaInputValue(v)}
                  loading={loading}
                  metricList={metricList}
                  metric={metric}
                  onMetricChange={handleMetricChange}
                  onOpenFilter={handleOpenFilter}
                  highlightedMetric={
                    sidebarValue === "filters" ? filterOpen : -1
                  }
                  cursorPosition={cursorPosition}
                  onCursorPositionChange={(p) => setCursorPosition(p)}
                  onPressUp={() => setFilteredSelectedIndex((i) => i - 1)}
                  onPressDown={() => setFilteredSelectedIndex((i) => i + 1)}
                />
                {error && <FormulaError reason={error || ""} />}
              </Box>
              <Sidebar
                filterOpen={filterOpen}
                onToggleOpenFilter={handleToggleOpen}
                search={search}
                onSearchChange={(v) => setSearch(v)}
                loading={loading}
                tab={sidebarValue}
                metricList={metricList}
                metric={metric}
                selectedMetrics={getSelectedMetrics()}
                onChange={(t) => setSidebarValue(t)}
                onAddMetric={addMetric}
                onMetricChange={handleMetricChange}
                filteredSelectedIndex={filteredSelectedIndex}
                onFilteredSelectedIndexChange={handleFilterSelectChange}
              />
            </Grid>
          </Grid>
        ) : (
          <EmptyState
            heading={_`No metric selected`}
            subheading={""}
            onButtonClick={createMetric}
            buttonText={_`New metric`}
            buttonColor={"secondary"}
            buttonLeftIcon={"Add"}
            iconName={"Group"}
            iconSize={130}
            iconContainerStyle={{
              height: 130,
              width: 130,
              borderRadius: 16,
              padding: 4,
              background: theme.colors.bgLight10,
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}
