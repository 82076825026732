import { Box, Grid, Text, theme } from "../../../../icecube-ux";
import { _ } from "../../../../languages/helper";
import { ucFirst } from "../../../../utils/utils";
interface FormulaOperatorProps {
  operator: "and" | "or";
}

export default function FormulaOperator({ operator }: FormulaOperatorProps) {
  if (operator === "and") {
    return (
      <Text
        variant="body11Regular"
        color={theme.colors.text80}
        style={{ margin: "6px 1px" }}
      >
        {ucFirst(_`and`)}
      </Text>
    );
  }
  return (
    <Grid gridTemplateColumns="auto 1fr" alignItems="center" gap={8}>
      <Text
        variant="body11Regular"
        color={theme.colors.text80}
        style={{ marginLeft: "6px" }}
      >
        {ucFirst(_`or`)}
      </Text>
      <Box height="1px" background={theme.colors.borderLight} />
    </Grid>
  );
}
