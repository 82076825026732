import styled from "styled-components";

import { useAuth } from "../../hooks/auth/auth";
import { useSignup } from "../../hooks/useSignup";
import {
  Button,
  ConnectorIcon,
  EmojiIconBackground,
  Flex,
  Icon,
  Popup,
  Space,
  Text,
  TextField,
  theme,
} from "../../icecube-ux";
import { _ } from "../../languages/helper";
import { SSOContexts } from "../../lib/authService";
import Form from "../../shared/Forms/Form";

import { GoogleSSOButton } from "./components/GoogleSSOButton";
import { HorizontalRuleWord } from "./SignUp/HorizontalRuleWord";

interface SignUpPopupProps {
  onClose: () => void;
}

const StyledSignUpPopupCompanyLogo = styled.img`
  border: 1px solid ${theme.colors.borderLight};
  background-color: ${theme.colors.white100};
  border-radius: 9px;
  width: 54px;
  height: 54px;
  padding: 4px;
`;

const StyledSignUpPopupTextField = styled(TextField)`
  width: 80vw;
  max-width: 358px;
`;

export const SignUpPopup = ({ onClose }: SignUpPopupProps) => {
  const { formData, error, loading, handleSignUp, updateFormData } = useSignup({
    testVersion: "popup",
  });
  const auth = useAuth();

  return (
    <Popup
      onClose={onClose}
      headerComponent={
        <Popup.BaseHeader style={{ borderBottom: "none", paddingBottom: 0 }}>
          {" "}
        </Popup.BaseHeader>
      }
      footerComponent={
        <Button
          type="submit"
          onClick={() => {
            void handleSignUp();
          }}
          loading={loading}
          block={true}
          size="large"
        >
          {_`Sign up to Polar Analytics`}
        </Button>
      }
    >
      <Flex flexDirection="column" alignItems="center">
        {" "}
        <Flex gap={8} alignItems="center">
          {auth.outboundDemoData?.personalization?.logo && (
            <>
              <StyledSignUpPopupCompanyLogo
                src={auth.outboundDemoData?.personalization?.logo}
              />
              <Icon name="Connection" size={24} style={{ fill: "#6C6C9C" }} />
            </>
          )}
          <EmojiIconBackground
            background={theme.colors.white100}
            style={{ border: `1px solid ${theme.colors.borderLight}` }}
            size="large"
          >
            <ConnectorIcon size={30} name="Polar" />
          </EmojiIconBackground>
        </Flex>
        <Space />
        <Form
          onSubmit={() => {
            void handleSignUp();
          }}
        >
          <Flex flexDirection="column" fullWidth alignItems="stretch">
            <Flex flexDirection="column" alignItems="center">
              <Text
                variant="body18Medium"
                color={theme.colors.text110}
              >{`Sign up for free`}</Text>
              <Text
                variant="body12Medium"
                color={theme.colors.primary80}
              >{`No credit card required`}</Text>
            </Flex>

            <Space type={"large"} />

            <GoogleSSOButton size="large" context={SSOContexts.SIGNUP} />

            <Space />

            <HorizontalRuleWord variant="body12Regular">{_`Or`}</HorizontalRuleWord>

            <Space />

            <Flex gap={8} flexDirection="column">
              <Text
                fontWeight={500}
                fontSize="13px"
                color={theme.colors.text100}
              >
                {_`Work email`}
              </Text>
              <StyledSignUpPopupTextField
                error={error?.email}
                required={true}
                type="email"
                placeholder={_`Write your work email`}
                value={formData.email}
                onChange={(v) => updateFormData("email", v as string)}
                dataAttributes={{ "data-cy": "email" }}
              />
            </Flex>

            <Space />

            <Flex gap={8} flexDirection="column">
              <Text fontWeight={500} fontSize="13px" color="#4B4B7E">
                {_`Password`}
              </Text>
              <StyledSignUpPopupTextField
                error={error?.password}
                required={true}
                type="password"
                placeholder={_`Choose wisely`}
                value={formData.password}
                onChange={(v) => updateFormData("password", v as string)}
                dataAttributes={{ "data-cy": "password" }}
              />
            </Flex>
          </Flex>
        </Form>
      </Flex>
    </Popup>
  );
};
