import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

interface PositionContextProps {
  setX: (x: number | undefined) => void;
  setIndex: (x: number | undefined) => void;
  setEnabled: (x: boolean) => void;
  setFrozen: (x: boolean) => void;
  isEnabled: boolean;
  isFrozen: boolean;
  x: number | undefined;
  index: number | undefined;
}

export const PositionContext = createContext<PositionContextProps | null>(null);

export function withPosition<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>,
>(Component: T) {
  return function (props: React.ComponentProps<T>) {
    const position = useProvidePosition();

    return (
      <PositionContext.Provider value={position}>
        <Component {...props} />
      </PositionContext.Provider>
    );
  };
}

export const usePosition = () => {
  const context = useContext(PositionContext);
  if (context === null) {
    throw Error("Position context not provided");
  }
  return context;
};

function useProvidePosition(): PositionContextProps {
  const [enabled, setEnabled] = useState(false);
  const [frozen, setFrozen] = useState(false);

  const [x, setX_] = useState<number | undefined>(undefined);
  const [index, setIndex_] = useState<number | undefined>(undefined);

  const setX = useCallback(
    (newX: number | undefined) => {
      if (frozen) {
        return;
      }
      setX_(newX);
    },
    [frozen],
  );

  const setIndex = useCallback(
    (newIndex: number | undefined) => {
      if (frozen) {
        return;
      }
      setIndex_(newIndex);
    },
    [frozen],
  );

  return useMemo(() => {
    return {
      setX,
      setIndex,
      setEnabled,
      setFrozen,
      isEnabled: enabled,
      isFrozen: frozen,
      x: enabled ? x : undefined,
      index: enabled ? index : undefined,
    };
  }, [enabled, setEnabled, setIndex, setX, setFrozen, frozen, index, x]);
}
