import moment from "moment";

import { ProductCell } from "../../../elements/ReportTableWrapper/ProductCell";
import { TenantFormatters } from "../../../hooks/useFormatters";
import { _ } from "../../../languages/helper";
import {
  CustomMetric,
  CustomMetricRule,
  IDimension,
  IMetric,
  IMetricsList,
} from "../../../types/synthesizer";
import {
  applyDateBoundaries,
  DateRange,
  getDateFormatByGranularity,
  Granularity,
} from "../../../utils/dateUtils";
import { DimensionTypes } from "../../../utils/filterUtils";
import { createCurrencyFormatter } from "../../../utils/formatters";
export const IMAGE_BREAKDOWN_KEY = "product_image";

const CUSTOM_METRIC_PREFIX = "custom_";
export const isCustomMetric = (metricName: string) =>
  metricName.startsWith(CUSTOM_METRIC_PREFIX);
export const extractCustomMetricId = (metricName: string) =>
  metricName.replace(CUSTOM_METRIC_PREFIX, "");

export const metricElementRulesCount = (
  filters: Array<CustomMetricRule | CustomMetricRule[]>,
): number => {
  return filters
    .map((g) =>
      Array.isArray(g)
        ? metricElementRulesCount(g)
        : g.dimension !== ""
        ? 1
        : 0,
    )
    .reduce((p, v) => p + v, 0);
};

export const getBadgeIndex = (
  metric: CustomMetric,
  value: string,
  index: number,
) => {
  let elementBefore = 0;
  const totalElementWithValue = metric.elements.filter((e, i) => {
    const match = e.type === "metric" && e.value === value;
    if (match && i < index) {
      elementBefore++;
    }
    return match;
  }).length;

  return totalElementWithValue < 2 ? -1 : elementBefore;
};

export const getBreakdownFormatter = (
  breakdownKey: string,
  breakdownDetails: IDimension | null,
  granularity: Granularity | "none",
  range?: DateRange,
) => {
  if (breakdownKey === "date") {
    return (v: string) =>
      moment(v).isValid()
        ? applyDateBoundaries(moment(v), range?.start, range?.end).format(
            getDateFormatByGranularity(granularity),
          )
        : "";
  }

  if (breakdownKey === IMAGE_BREAKDOWN_KEY) {
    return () => ""; //we don't show the value
  }

  if (breakdownDetails?.type === DimensionTypes.date) {
    return (v: string) => (v === null ? "-" : moment(v).format("MMM D, YYYY"));
  }

  if (breakdownDetails?.type === DimensionTypes.datetime) {
    return (v: string) =>
      v === null ? "-" : moment(v).format("YYYY-MM-DD HH:mm:ss");
  }

  if (breakdownDetails?.type === DimensionTypes.boolean) {
    return (v: boolean) => (v === true ? "true" : "false");
  }

  return (v: string) => `${v === null ? "-" : v}`;
};

export const getBreakdownRightComponent = (
  breakdownKey: string,
  line: Record<string, string | number>,
) => {
  if (breakdownKey === IMAGE_BREAKDOWN_KEY) {
    return <ProductCell imageUrl={String(line[breakdownKey])} value={""} />;
  }
  return undefined;
};

export const getMetricFormatter = (
  metricKey: string,
  metricList: IMetricsList,
  formatters: TenantFormatters,
  showPercentageForMetrics?: string[],
  breakdowns?: (string | number)[],
) => {
  const [table, type, metric] = metricKey.split(".") as [
    string,
    "computed" | "raw",
    string,
  ];

  const metricDetails = type
    ? metricList.tables?.[table]?.metrics[type][metric]
    : metricList.metrics?.[table] || metricList.customMetrics?.[table];

  // This is a temporary fix to format the attribution metrics as floats even if the integer flag is set for the Shopify Sales Main metric
  const isAttributionMetric =
    breakdowns?.some((breakdown) =>
      [
        "channel",
        "campaign",
        "account_id",
        "account_name",
        "utm_medium",
        "utm_content",
        "utm_term",
        "utm_source",
        "utm_campaign",
        "utm_ad_id",
        "utm_campaign_id",
        "is_paid_channel",
        "adset_name",
        "ad_name",
        "utm_adset",
        "utm_ad",
        "utm_adset_id",
      ].includes(breakdown as string),
    ) && table === "shopify_sales_main";

  const valueFormatter = (value: string | number) => {
    if (!metricDetails) {
      return value;
    }

    const nonNullValue = value || 0;
    if (metricDetails?.currency === true) {
      if (metricDetails?.extraSettings?.currency !== undefined) {
        return createCurrencyFormatter(
          metricDetails.extraSettings.currency as string,
          formatters.formatterSettings,
        )(Number(nonNullValue));
      }
      return formatters.currencyFormatter(Number(nonNullValue));
    }

    if (metricDetails?.timedelta === true) {
      return formatters.timedeltaFormatter(Number(nonNullValue));
    }

    if (metricDetails?.percentage === true) {
      return formatters.percentageFormatter(Number(nonNullValue));
    }
    if (metricDetails.ratio === true) {
      if (value === null) {
        return "-";
      }
      return formatters.ratioFormatter(Number(nonNullValue));
    }
    if (metricDetails?.suffix || metricDetails?.prefix) {
      return formatters.prefixSuffixFormatter(
        metricDetails?.prefix || "",
        metricDetails?.suffix || "",
        nonNullValue,
        metricDetails.integer,
      );
    }

    if (metricDetails?.integer === true && !isAttributionMetric) {
      return formatters.integerFormatter(nonNullValue);
    }

    return formatters.numberFormatter(nonNullValue);
  };

  if (showPercentageForMetrics?.includes(metricKey)) {
    return (value: number | string, _?: number, __?: number, total?: number) =>
      typeof total !== "undefined"
        ? `${valueFormatter(value)} (${formatters.percentageFormatter(
            Number(value || 0) / Number(total || 1),
          )})`
        : valueFormatter(value);
  } else {
    return valueFormatter;
  }
};

type SupportUnderlyingDataArgs = {
  metricKey?: string;
  isMobile: boolean;
};
export const supportsUnderlyingData = ({
  metricKey,
  isMobile,
}: SupportUnderlyingDataArgs) => {
  return !isMobile && metricKey === "shopify_sales_main.computed.total_sales";
};

type IsAffectedByDataSettingsPage = {
  metricKey: string;
  isMobile: boolean;
};
export const isAffectedByDataSettingsPage = ({
  metricKey,
  isMobile,
}: IsAffectedByDataSettingsPage) => {
  return !isMobile && metricKey === "shopify_sales_main.computed.total_sales";
};

export const isMetricExploreNew = (metricKey?: string) => {
  return metricKey === "shopify_sales_main.computed.total_sales";
};

export const isMetricExplorable = (
  flattenMetrics: Record<string, IMetric>,
  metricKey?: string,
) => {
  if (!flattenMetrics || !metricKey) {
    return false;
  }
  return (
    metricKey === "shopify_sales_main.computed.total_sales" ||
    (flattenMetrics?.[metricKey]?.influencedBy?.length ?? 0) > 0 ||
    (flattenMetrics?.[metricKey]?.influences?.length ?? 0) > 0
  );
};

const UNKNOWN_METRIC = _`Unknown Metric`;
export const getMetricLabel = (
  metricName: string,
  metricList: IMetricsList,
) => {
  const isCustom = isCustomMetric(metricName);
  if (isCustom) {
    return metricList?.customMetrics?.[metricName]?.label || UNKNOWN_METRIC;
  }
  const [table, type, metric] = metricName.split(".");
  const metricDetails = type
    ? metricList.tables[table].metrics[type as "computed" | "raw"][metric]
    : metricList.metrics[table];
  return metricDetails?.label || UNKNOWN_METRIC;
};
