import classNames from "classnames";

import { Icon } from "../Icon";
import { ClassAndStyleProps, InteractionEvents } from "../shared";

import { StyledPercentChip } from "./PercentChip.styled";

export type PercentChipProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    negative?: boolean;
    evolution: string;
    invertedEvolution?: boolean;
    hideArrow?: boolean;
    small?: boolean;
    onBackground?: "light" | "dark";
  };

export default function PercentChip({
  className,
  style,
  evolution,
  negative = false,
  invertedEvolution = false,
  hideArrow = false,
  small = false,
  onBackground = "light",
  ...interactionEvents
}: PercentChipProps) {
  return (
    <StyledPercentChip
      {...interactionEvents}
      className={classNames(className, { hideArrow })}
      style={style}
      negative={negative ? !invertedEvolution : invertedEvolution}
      small={small}
      withBackground={onBackground}
    >
      {!hideArrow && <Icon name={negative ? "EvolutionDown" : "EvolutionUp"} />}
      <span>{evolution}</span>
    </StyledPercentChip>
  );
}
