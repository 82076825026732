import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  category: INTEGRATION_CATEGORIES.advertising,
  key: "criteo",
  image: "Criteo",
  dependsOnAccounts: true,
  backgroundColor: theme.customColors.bingBg,
  borderColor: theme.customColors.bingBorder,
  label: "Criteo",
  get description() {
    return _`Online advertising platform`;
  },
});
