import styled from "styled-components";

import { legacyTheme } from "../legacyTheme";

export const StyledSwitch = styled.div`
  display: inline-block;
`;

export const StyledSwitchInputElement = styled.input`
  display: none;
`;

export const StyledSwitchInputRail = styled.label`
  cursor: pointer;
  display: block;
  height: 14px;
  width: 34px;
  border-radius: 7px;
  background-color: ${legacyTheme.colors.grey12};
  position: relative;
  box-shadow: inset 0px 2px 4px rgb(85 91 142 / 12%);

  :hover {
    background-color: ${legacyTheme.colors.grey11};
  }

  input:checked + & {
    background-color: ${legacyTheme.colors.success8};
    :hover {
      background-color: ${legacyTheme.colors.success7};
    }
  }

  input:disabled + & {
    background-color: ${legacyTheme.colors.grey12};
  }
`;

export const StyledSwitchInputHandle = styled.div`
  height: 20px;
  width: 20px;
  top: -3px;
  border-radius: 10px;
  background-color: ${legacyTheme.colors.grey10};
  position: absolute;
  box-shadow: 0px 4px 6px rgb(85 91 142 / 12%);

  label:hover & {
    background-color: ${legacyTheme.colors.grey9};
  }

  input:checked + label & {
    right: 0;
    background-color: ${legacyTheme.colors.success5};
    box-shadow:
      0px 0px 2px rgb(31 231 167 / 12%),
      0px 2px 12px rgb(31 231 167 / 50%);
  }

  input:checked + label:hover & {
    background-color: ${legacyTheme.colors.success3};
  }

  input:disabled + label & {
    background-color: ${legacyTheme.colors.grey11};
  }
`;
