import classNames from "classnames";
import styled from "styled-components";

import { Box } from "../../Box";
import { Grid } from "../../Grid";
import { legacyTheme } from "../../legacyTheme";
import { theme } from "../../theme";

import { ReportTableHeaderColumn } from "./types";

export interface ReportTableSubHeaderTexts {
  primary: string;
  secondary?: string;
}

const StyledReportSubHeaderCell = styled.div`
  height: 24px;
  border-radius: ${theme.borderRadius.r2px};
  background: ${legacyTheme.colors.bg.light};
  text-align: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 24px;
  color: ${legacyTheme.colors.text.placeholder};

  &.secondary {
    background: ${legacyTheme.colors.bg.inputs.lightOnDark};
    color: ${legacyTheme.colors.text.placeholderDark};
  }
`;

interface ReportTableSubHeaderLinesProps {
  checkbox?: boolean;
  lines: ReportTableHeaderColumn[][];
  rightMargin: number;
  subHeaderTexts?: ReportTableSubHeaderTexts;
}

export default function ReportTableSubHeaderLines({
  checkbox,
  lines,
  rightMargin,
  subHeaderTexts,
}: ReportTableSubHeaderLinesProps) {
  if (lines.length === 1 && lines[0].length === 0) {
    lines.pop();
  }

  if (lines.length === 0) {
    return null;
  }

  const maxColumnCount = Math.max(...lines.map((u) => u.length));
  return (
    <Box display="grid" gridTemplateRows="subgrid" userSelect="none">
      <Grid
        gridTemplateColumns={`${
          checkbox ? "32px " : ""
        }repeat(${maxColumnCount}, minmax(144px, 1fr)) ${
          rightMargin > 0 ? `${rightMargin}px` : ""
        }`}
        gap={4}
        gridTemplateRows="subgrid"
      >
        {checkbox ? (
          <div
            style={{
              background: theme.colors.bgLight10,
              borderRight: `1px solid ${theme.colors.borderLight}`,
            }}
          ></div>
        ) : (
          <></>
        )}
        {lines.length > 0 &&
          lines[lines.length - 1].map((column, i) => (
            <StyledReportSubHeaderCell
              key={`subheader-column-${i}`}
              className={classNames({ secondary: column.isSecondary })}
            >
              {column.isSecondary
                ? subHeaderTexts?.secondary
                : subHeaderTexts?.primary}
            </StyledReportSubHeaderCell>
          ))}
        {rightMargin > 0 && <div />}
      </Grid>
    </Box>
  );
}
