import { useEffect, useState } from "react";
import styled from "styled-components";

import { useAuth } from "../../hooks/auth/auth";
import { useBootstrap } from "../../hooks/bootstrap";
import { Block, Button, Flex, IconButton, Text, theme } from "../../icecube-ux";
import { _ } from "../../languages/helper";
import {
  generateReferralCode,
  getUserReferralCode,
} from "../../lib/usersService";
import {
  isFreeOrTrialPlan,
  isSubscriptionActive,
} from "../../utils/subscriptionsUtils";
import { trackEvent, TRACKING_EVENTS } from "../../utils/trackingUtils";

import { ReferralsGeneratedCodeSection } from "./ReferralsGeneratedCodeSection";

const StyledVoucherCard = styled.section`
  margin-top: 16px;
  padding: 16px;
  min-height: 177px;
  border-radius: ${theme.borderRadius.r6px};
  border: 1px solid ${theme.colors.borderLight};
`;

const StyledRequireSubscriptionMessage = styled(Block).attrs({
  variant: "warning",
})`
  flex-grow: 1;
  display: flex;
  align-content: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export function ReferralsGenerateCode() {
  const auth = useAuth();
  const { tenant, subscription } = useBootstrap();
  const [referralCode, setReferralCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [showShareCodeSection, setShowShareCodeSection] =
    useState<boolean>(false);

  const isFreeOrTrial = isFreeOrTrialPlan(subscription);
  const isActive = isSubscriptionActive(subscription);
  const referralMessage = _`Hi! I think you’d like Polar Analytics. If you mention my code ${[
    "referral code",
    referralCode,
  ]} you’ll get 20% off for 3 months.`;

  useEffect(() => {
    const fetch = async () => {
      if (!auth.user?.id) {
        return;
      }
      setLoading(true);
      const code = await getUserReferralCode(await auth.getToken());
      setLoading(false);
      if (code) {
        setReferralCode(String(code));
        setShowShareCodeSection(true);
      }
    };
    void fetch();
  }, [auth, tenant.id]);
  const handleClick = async () => {
    if (!auth.user?.id) return;
    trackEvent(TRACKING_EVENTS.REFERRALS_GENERATE_CODE_CLICKED, {
      userId: auth.user?.id,
      tenantId: tenant.id,
    });
    const code = await generateReferralCode(await auth.getToken());
    if (code) {
      setReferralCode(String(code));
      setShowShareCodeSection(true);
    }
  };

  return isFreeOrTrial || !isActive ? (
    <StyledRequireSubscriptionMessage>
      <Flex alignItems={"center"}>
        <IconButton name={"InfoCircle"} color="warning" />
        <Text
          color={theme.colors.warning80}
        >{_`The referral program will become available when you activate your Polar subscription`}</Text>
      </Flex>
    </StyledRequireSubscriptionMessage>
  ) : (
    <StyledVoucherCard>
      <Text
        variant="body16Medium"
        color={theme.colors.text100}
      >{_`Create your own discount code to start referring brands to Polar`}</Text>
      <Flex alignItems="center" gap={16}>
        <Text
          variant="body40SemiBold"
          color={theme.colors.text100}
        >{_`20%`}</Text>
        <Text
          variant="body12Regular"
          color={theme.colors.text90}
          style={{ maxWidth: "100px" }}
        >{_`Polar discount for 3 months`}</Text>
      </Flex>

      {!showShareCodeSection || loading ? (
        <Button
          style={{ marginTop: "16px" }}
          onClick={() => {
            void handleClick();
          }}
        >{_`Create my code`}</Button>
      ) : (
        <ReferralsGeneratedCodeSection
          referralCode={referralCode}
          referralMessage={referralMessage}
        />
      )}
    </StyledVoucherCard>
  );
}
