import { useState } from "react";
import { Helmet } from "react-helmet";

import { OnboardingFormData } from "../../common/types/users-service";
import { useAuth } from "../../hooks/auth/auth";
import { useBootstrap } from "../../hooks/bootstrap";
import { setTenantSetting } from "../../lib/usersService";
import { TRACKING_EVENTS, trackEvent } from "../../utils/trackingUtils";

import OnboardingFormCallSlide from "./OnboardingFormCallSlide/OnboardingFormCallSlide";
import OnboardingFormQuestionSlide from "./OnboardingFormQuestionSlide/OnboardingFormQuestionSlide";
import {
  onboardingQuestionKeys,
  onboardingQuestions,
} from "./onboardingQuestions";

declare const dataLayer: Record<string, unknown>[];

const NUMBER_OF_QUESTIONS = onboardingQuestions.length;

const initialResponses = onboardingQuestions.map((question) => {
  if (question.slideType === "callBooking") {
    return {
      questionKey: question.key,
      skipped: false,
      selection: [],
    };
  } else {
    const guessedValue = question.tryGet?.();
    return {
      questionKey: question.key,
      selection: guessedValue ? [guessedValue] : [],
      skipped: false,
      isAutocompleted: Boolean(guessedValue),
    };
  }
});

function OnboardingForm() {
  const auth = useAuth();
  const { tenant, forceRefresh } = useBootstrap();

  const [onboardingForm, setOnboardingForm] = useState<OnboardingFormData>(
    tenant.settings.onboarding_form || {
      isCompleted: false,
      responses: initialResponses,
    },
  );

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(
    onboardingForm.responses.findIndex((r) => r.selection.length === 0) || 0,
  );
  const currentQuestion = onboardingQuestions[currentQuestionIndex];

  const currentResponse = onboardingForm.responses[currentQuestionIndex];

  const handleSubmit = async (
    latestResponses?: OnboardingFormData["responses"],
  ) => {
    const isCompleted = currentQuestionIndex === NUMBER_OF_QUESTIONS - 1;
    const onboarding_form = {
      responses: latestResponses || onboardingForm.responses,
      isCompleted,
    };
    if (!isCompleted) {
      trackEvent(TRACKING_EVENTS.ONBOARDING_FORM_NEXT_STEP, {
        ...onboarding_form,
        tenantId: tenant?.id,
        email: auth.user?.email,
      });
      setCurrentQuestionIndex((prev) => {
        const isNextAutoCompleted = Boolean(
          onboardingForm.responses[prev + 1].isAutocompleted,
        );
        return isNextAutoCompleted ? prev + 2 : prev + 1;
      });
    }
    await setTenantSetting(
      await auth.getToken(),
      "onboarding_form",
      onboarding_form,
    );
    if (isCompleted) {
      await forceRefresh();
      await auth.refreshUser(auth.tokens);
      const gmv = onboarding_form?.responses?.find(
        (response) => response.questionKey === onboardingQuestionKeys.gmv,
      )?.selection[0];
      const region = onboarding_form?.responses?.find(
        (response) => response.questionKey === onboardingQuestionKeys.region,
      )?.selection[0];
      const goals = onboarding_form?.responses?.find(
        (response) => response.questionKey === onboardingQuestionKeys.goals,
      )?.selection[0];
      trackEvent(TRACKING_EVENTS.ONBOARDING_FORM_COMPLETED, {
        ...onboarding_form,
        tenantId: tenant?.id,
        email: auth.user?.email,
        gmv,
        region,
        goals,
      });
      dataLayer?.push({
        event: "onboarding_form_completed",
        gmv,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Polar Analytics</title>
      </Helmet>
      {currentQuestion.slideType === "question" && (
        <OnboardingFormQuestionSlide
          onSubmit={handleSubmit}
          currentQuestion={currentQuestion}
          currentResponse={currentResponse}
          setOnboardingForm={setOnboardingForm}
          currentQuestionIndex={currentQuestionIndex}
          previousQuestion={
            currentQuestionIndex !== 0
              ? () => {
                  trackEvent(TRACKING_EVENTS.ONBOARDING_FORM_CLICK_PREVIOUS, {
                    tenantId: tenant?.id,
                    email: auth.user?.email,
                  });
                  setCurrentQuestionIndex((prev) => {
                    const isPrevAutoCompleted = Boolean(
                      onboardingForm.responses[prev - 1].isAutocompleted,
                    );
                    return isPrevAutoCompleted ? prev - 2 : prev - 1;
                  });
                }
              : undefined
          }
        />
      )}
      {currentQuestion.slideType === "callBooking" && (
        <OnboardingFormCallSlide
          onSubmit={handleSubmit}
          onboardingForm={onboardingForm}
        />
      )}
    </>
  );
}

export default OnboardingForm;
