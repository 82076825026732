import { Flex } from "../../Flex";
import { Icon } from "../../Icon";
import { theme } from "../../theme";
import { Text } from "../../Typography";

interface CompletionHintProps {
  inputValue: string;
  placeholder: React.ReactNode;
  completionBestOption: string | null;
  allowCustomValue?: boolean;
}

export default function CompletionHint({
  inputValue,
  placeholder,
  completionBestOption,
  allowCustomValue,
}: CompletionHintProps) {
  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      style={{ color: theme.colors.primary60, paddingRight: "6px" }}
    >
      <Text
        variant="body12Regular"
        color={theme.colors.text80}
        style={{ whiteSpace: "pre" }}
      >
        {inputValue === "" ? placeholder : completionBestOption}
      </Text>
      {inputValue !== "" &&
        (completionBestOption !== null || allowCustomValue) && (
          <>
            <Text
              variant="body9Regular"
              color={theme.colors.primary60}
              style={{ paddingLeft: "8px" }}
            >
              Press tab
            </Text>
            <Icon name="Tabulation" size={14} />
            <Text variant="body9Regular" color={theme.colors.primary60}>
              or enter
            </Text>
            <Icon name="Enter" size={14} />
          </>
        )}
    </Flex>
  );
}
