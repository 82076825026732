export type ColorFormat = {
  background: string;
  color: string;
  iconBackground: string;
  borderColor?: string;
};

export const colors = {
  black: "#000000",

  white100: "rgb(255 ,255, 255)",
  white80: "rgba(255 ,255, 255, 80%)",
  white70: "rgba(255 ,255, 255, 70%)",
  white50: "rgba(255 ,255, 255, 50%)",
  white22: "rgba(255 ,255, 255, 22%)",
  white12: "rgba(255 ,255, 255, 12%)",
  white8: "rgba(255 ,255, 255, 8%)",
  white4: "rgba(255 ,255, 255, 4%)",

  bgBody: "#F0F9FF",
  bgLight10: "#FAFBFE",
  bgLight20: "#F7F8FD",
  bgLight30: "#EFF1FA",

  text110: "#303051",
  text100: "#4B4B7E",
  text95: "#55557D",
  text90: "#6C6C9C",
  text80: "#999FC8",
  text70: "#C0C4E2",

  grey100: "#646494",
  grey90: "#8383A9",
  grey80: "#A9AED4",
  grey70: "#C0C4E2",
  grey60: "#D5D9F0",
  grey50: "#E2E4F4",
  grey40: "#EDEFF8",
  grey30: "#F6F6FB",
  grey20: "#FCFCFD",

  borderDark: "#D3D1FF",
  borderMedium: "#DAD8FF",
  borderLight: "#EAE9FF",

  boxShadow: "#2F299C",

  primary110: "#4337FE",
  primary100: "#5A50FE",
  primary90: "#6B62FE",
  primary80: "#7B73FE",
  primary70: "#8B85FE",
  primary60: "#9C96FE",
  primary50: "#ACA7FF",
  primary40: "#BDB9FF",
  primary30: "#CECBFF",
  primary20: "#DEDCFF",
  primary10: "#EFEDFF",

  secondary100: "#82E2C8",

  error110: "#F4075D",
  error100: "#F92572",
  error90: "#FA3B80",
  error80: "#FA518E",
  error70: "#FB669C",
  error60: "#FB7CAA",
  error50: "#FC92B8",
  error40: "#FDA8C7",
  error30: "#FDBED5",
  error20: "#FED3E3",
  error10: "#FCF6F8",

  success110: "#00A574",
  success100: "#00BC84",
  success90: "#1AC390",
  success80: "#33C99C",
  success70: "#4DD0A9",
  success60: "#66D7B5",
  success50: "#80DEC1",
  success40: "#99E4CE",
  success30: "#B2EBDA",
  success20: "#CCF2E6",
  success10: "#F2FAF8",

  warning110: "#EB6300",
  warning100: "#F87700",

  warning90: "#F8841A",
  warning80: "#F99E33",
  warning70: "#FAB54D",
  warning60: "#F4C620",
  warning50: "#FAD44D",
  warning40: "#FFE47F",
  warning30: "#FFF0A3",
  warning20: "#FFFDD5",
  warning10: "#FFFFF3",

  formulaEditor: {
    blue: "#3F9FF8",
    orange: "#F87700",
    purple: "#F038F3",
    purple10: "#FFEBFF",
    purple50: "rgba(240, 56, 243, 0.4)",
  },
};

export const customColors = {
  capiBg: "#F4FFCD",

  polarBg: "#F7F6FE",
  polarBorder: "#E2DFFA",

  pixelBg:
    "linear-gradient(47deg, #FFE6F1 6.14947%, #F3FAE4 49.0086%, #D4F2FF 100%)",
  pixelBorder: "#EAE9FF",

  linearBg: "linear-gradient(0deg, #7FC9F333 20%, #625FF233 100%)",

  ShopifyBg: "#F5FDE5",
  ShopifyBorder: "#DCF1B3",

  fallbackConnectorBg: "F7F7F7",
  fallbackConnectorBorder: "CCCCCC",

  attentiveBg: "#FFFCE3",
  attentiveBorder: "#FFF6A3",

  prestashopBg: "#EFFAFD",
  prestashopBorder: "#CEEDF5",

  redditBg: "#FFF3EF",
  redditBorder: "#FFC9B8",

  zohoBg: "#FDF5E7",
  zohoBorder: "#FAE5C2",

  googleAnalyticsBg: "#FFFAE7",
  googleAnalyticsBorder: "#F4E8B8",

  googleFbInstaBg: "#F6F9FE",
  googleFbInstaBorder: "#D9E8FF",

  googleSheetsBg: "#EFFCF3",
  googleSheetsBorder: "#CEECD7",

  gorgiasBg: "#FFF2E9",
  gorgiasBorder: "#FFD2B3",

  klaviyoBg: "#EFEFEF",
  klaviyoBorder: "#CCCCCC",

  slackBg: "#F6FCFE",
  slackBorder: "#CFE9F0",

  snapchatBg: "#FFFEDB",
  snapchatBorder: "#EDECB8",

  tiktokBg: "#F2FCFC",
  tiktokBorder: "#CFEBEB",

  pinterestBg: "#FEF6F7",
  pinterestBorder: "#F7DFE3",

  bingBg: "#F1FBFA",
  bingBorder: "#D5EFED",

  facebookBg: "#F0F2F8",
  facebookBorder: "#BAC3DE",

  fairingBg: "#F9F8FF",
  fairingBorder: "#C2B8FF",

  omnisendBg: "#F9F8FF",
  omnisendBorder: "#C2B8FF",

  rechargeBg: "#E3E0F3",
  rechargeBorder: "#F9F8FF",

  yotpoBg: "#F6FAFE",
  yotpoBorder: "#D7E6F4",

  criteoBg: "#FEF8F6",
  criteoBorder: "#F2E1D9",

  amazonBg: "#FFF7EB",
  amazonBorder: "#F1DDBE",

  menuKIsBG: "#F5E3FF",
  menuKIsBGHover: "#F3DDFF",

  menuConnectorsBg: "#EEF8FF",
  menuConnectorsBgHover: "#DFECF5",

  menuAcquisitionBg: "#EEF5FF",
  menuAcquisitionBgHover: "#E1EBFA",

  menuCreativeStudioBg: "#F99E3326",
  menuCreativeStudioBgHover: "#F298313E",

  menuRetentionBg: "#EAF6F9",
  menuRetentionBgHover: "#DDF1F5",

  menuEngagementBg: "#FFF0F0",
  menuEngagementBgHover: "#FFE3E3",

  menuAudienceBg: "#DEF8FA",
  menuAudienceBgHover: "#DDF7F9",

  menuProductsBg: "#FFEDFB",
  menuProductsBgHover: "#FFE6F9",

  menuInventoryBg: "#FCF0E3",
  menuInventoryBgHover: "#FBE3CA",

  menuAskPolarBg: "#eef8ff",
  menuAskPolarBgHover: "#e2f3ff",

  menuCustomReportBg: "#EAF3F9",
  menuCustomReportBgHover: "#E0F1FC",

  menuAlertsBg: "#FFF9E5",
  menuAlertsBgHover: "#FFF5D6",

  menuSubscriptionsBg: "#FFFCE0",
  menuSubscriptionsBgHover: "#FFFAD0",

  menuKeyIndicatorsBg: "#F5E3FF",
  menuKeyIndicatorsBgHover: "#F3DDFF",

  menuConversionEnhancerBg: "#f4ffcd",
  menuConversionEnhancerBgHover: "#e0f596",

  gradientBlue: colors.primary100,
  gradientPurple: "#7D50FE",

  primaryBackground: "#7038F7;",

  primaryBackgroundGradient:
    "linear-gradient(89.86deg, #4d42fe 0%, #7e42fe 100%)",
  shadowDivGradient:
    "linear-gradient(270deg, rgba(47, 41, 156, 0.06) 0%, rgba(47, 41, 156, 0) 100%), linear-gradient(270deg, rgba(47, 41, 156, 0.03) 0%, rgba(47, 41, 156, 0) 14.29%)",

  warningText: "#A3822D",
  textWarningNotification: "#C08B00",

  categoryAnalytics: "#E37400",
  categoryEmail: "#23AC69",

  darkerLandingPageBackground: "#161349",

  darkLandingPageBackground: "#0d0b2d",
  darkLandingPageLogoutBackgroundHover: "#1d1b3d",
  darkLandingPageMainContentText: "#C1CEFF",
  darkLandingPageMainContentHighlightBackground:
    "linear-gradient(268.58deg, rgba(139, 133, 254, 0.41) 0%, rgba(139, 133, 254, 0.18) 100%)",
  darkLandingPageBoxShadow: "0px 0px 14px rgba(80, 129, 254, 0.55)",
};
