import { Box, Button, Grid } from "../../../icecube-ux";
import { _ } from "../../../languages/helper";
import { borderRightStyle, IS_DOC_READY } from "../utils";

interface FooterProps {
  onCancel: () => void;
  onSave: () => void;
  onDelete?: () => void;
  canSave: boolean;
  canDelete: boolean;
  docOpened: boolean;
  onToggleDoc: (state: boolean) => void;
}

export default function Footer({
  onCancel,
  onSave,
  canSave,
  onDelete,
  canDelete,
  docOpened,
  onToggleDoc,
}: FooterProps) {
  const render = () => (
    <>
      {IS_DOC_READY && (
        <Box padding="12px" {...borderRightStyle}>
          <Button
            color="ghost"
            leftIcon="Question"
            onClick={() => onToggleDoc(!docOpened)}
            block
          >
            {docOpened ? _`Close documentation` : _`Open documentation`}
          </Button>
        </Box>
      )}
      <Box padding="12px">
        <Grid
          gridTemplateColumns={`${
            canDelete ? "276px " : ""
          }276px 1fr 276px 56px`}
          gap={12}
        >
          <Button color="tertiary" block onClick={onCancel}>
            {_`Cancel`}
          </Button>
          {canDelete && (
            <Button color="error" block onClick={onDelete}>
              {_`Delete dimension`}
            </Button>
          )}
          <div />
          <Button
            color="primary"
            disabled={!canSave}
            block
            onClick={() => onSave()}
          >
            {_`Save dimension`}
          </Button>
          <div />
        </Grid>
      </Box>
    </>
  );

  if (docOpened) {
    return render();
  }

  return (
    <Grid gridTemplateColumns={IS_DOC_READY ? "300px 1fr" : "1fr"}>
      {render()}
    </Grid>
  );
}
