import { CSSProperties, Suspense } from "react";

import { Box } from "../Box";
import { IconSVGProps } from "../Icon/types";

import { StyledConnectorIconWrapper } from "./ConnectorIcon.styled";
import { ConnectorsIconSVG } from "./svgsConnectors";
import { ConnectorIconNames } from "./types";

export type ConnectorIconProps = IconSVGProps & {
  name: ConnectorIconNames;
  withBackground?: boolean;
  withBorder?: boolean;
  rootClassName?: string;
  wrapperSize?: string;
  disabled?: boolean;
};

export default function ConnectorIcon({
  name,
  withBackground,
  withBorder,
  size = 16,
  rootClassName,
  wrapperSize = "18px",
  disabled,
  style,
  ...props
}: Omit<ConnectorIconProps, "size"> & { size?: number }) {
  if (!name) {
    return null;
  }
  if (ConnectorsIconSVG[name] === undefined) {
    console.warn("Connector icon not found", name);
    return null;
  }

  const disabledIconStyles: CSSProperties = {
    filter: "grayscale(1)",
    opacity: 0.6,
  };

  const SVGComponent = ConnectorsIconSVG[name];

  if (withBackground) {
    return (
      <StyledConnectorIconWrapper
        wrapperSize={wrapperSize}
        withBorder={withBorder}
        className={rootClassName}
        style={disabled ? { filter: "grayscale(1)" } : undefined}
      >
        <Suspense
          fallback={
            <Box width={size} height={size}>
              &nbsp;
            </Box>
          }
        >
          <SVGComponent
            style={{ ...style, ...(disabled ? disabledIconStyles : undefined) }}
            size={size}
            {...props}
          />
        </Suspense>
      </StyledConnectorIconWrapper>
    );
  }
  return (
    <Suspense
      fallback={
        <Box width={size} height={size}>
          &nbsp;
        </Box>
      }
    >
      <SVGComponent
        style={{ ...style, ...(disabled ? disabledIconStyles : undefined) }}
        size={size}
        {...props}
      />
    </Suspense>
  );
}
