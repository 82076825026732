import styled from "styled-components";

import { theme } from "../../theme";

export const StyledFunctionHelper = styled.div`
  position: absolute;
  bottom: 30px;
  box-shadow: ${theme.shadows.regular};
  border-radius: ${theme.borderRadius.r4px};
  border: 1px solid ${theme.colors.borderLight};
`;

export const StyledFunctionHelperTitle = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: ${theme.borderRadius.r4px} ${theme.borderRadius.r4px} 0 0;
  background: ${theme.colors.bgLight10};
  padding: 4px 8px;
  border-bottom: 1px solid ${theme.colors.borderLight};
  gap: 8px;
  align-items: center;
  color: ${theme.colors.text100};
`;

export const StyledFunctionHelperContent = styled.div`
  display: flex;
  gap: 2px;
  flex-direction: column;
  border-radius: 0 0 ${theme.borderRadius.r4px} ${theme.borderRadius.r4px};
  background: ${theme.colors.white100};
  padding: 6px 8px;
  border-bottom: 1px solid ${theme.colors.borderLight};
`;

export const StyledFunctionCurrentParameter = styled.span`
  border-radius: ${theme.borderRadius.r2px};
  background: ${theme.colors.bgLight30};
  color: ${theme.colors.text90};
  padding: 0 2px;
`;
