import styled from "styled-components";

import { legacyTheme } from "../legacyTheme";
import { theme } from "../theme";

export const StyledAddKeyIndicator = styled.div`
  cursor: pointer;
  border: 1px dashed ${theme.colors.borderLight};
  border-radius: ${theme.borderRadius.r4px};
  box-sizing: border-box;
  height: 138px;
  background: ${legacyTheme.colors.bg.light};
  color: ${legacyTheme.colors.primary4};
  text-align: center;
  line-height: 138px;
`;
