import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { useAuth } from "../hooks/auth/auth";
import { useBootstrap } from "../hooks/bootstrap";
import { useModals } from "../hooks/modals";
import { Button, Grid, Popup, Text, legacyTheme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { addSlackIntegration } from "../lib/emailService";

export default function SlackConnector() {
  const auth = useAuth();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { forceRefresh } = useBootstrap();
  const { showLoader, hideLoader } = useModals();

  const [modal, setModal] = useState(-1);

  useEffect(() => {
    const connectSlack = async () => {
      if (!auth.processing) {
        if (searchParams.get("redirect_slack") === "1") {
          showLoader();
          const result = await addSlackIntegration(
            await auth.getToken(),
            searchParams.get("code") || "",
            searchParams.get("settings") === "1",
          );
          await forceRefresh();
          setModal(result ? 1 : 0);
          searchParams.delete("redirect_slack");
          searchParams.delete("code");
          searchParams.delete("state");
          setSearchParams(searchParams);
          hideLoader();
        }
      }
    };
    void connectSlack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, auth.processing, location, location.search]);

  return modal < 0 ? null : (
    <Popup
      onClose={() => setModal(-1)}
      footerComponent={
        <Grid key="footer-buttons" gridTemplateColumns="1fr" gap={10}>
          <Button onClick={() => setModal(-1)} color="primary">
            {modal === 0 ? _`Close` : _`Continue`}
          </Button>
        </Grid>
      }
    >
      <Text
        textAlign="center"
        className="margin-top-x3large margin-bottom-x3large"
      >
        <img src="/img/polarSlack.svg" alt="" />
        {modal === 1 && (
          <>
            <div>
              <Text
                color={legacyTheme.colors.success1darken}
                className="background-success9 border-radius-default padding-regular"
                fontSize="21px"
                fontWeight={600}
                lineHeight="68px"
                inline
              >
                {_`Slack is successfully connected`}
              </Text>
            </div>
            <div>
              <Text
                color={legacyTheme.colors.success1darken}
                className="background-success9 border-radius-default padding-regular"
                fontSize="21px"
                fontWeight={600}
                inline
              >
                {_`to Polar Analytics`}
              </Text>
            </div>
          </>
        )}
        {modal === 0 && (
          <>
            <div>
              <Text
                color={legacyTheme.colors.error1darken}
                className="background-error9 border-radius-default padding-regular"
                fontSize="21px"
                fontWeight={600}
                lineHeight="60px"
                inline
              >
                {_`Sorry, something's wrong.`}
              </Text>
            </div>
            <div>
              <Text
                color={legacyTheme.colors.error1darken}
                className="background-error9 border-radius-default padding-regular"
                fontSize="21px"
                fontWeight={600}
                lineHeight="30px"
                inline
              >
                {_`Please try again`}
              </Text>
            </div>
          </>
        )}
      </Text>
    </Popup>
  );
}
