import { useEffect, useState } from "react";

export const useIsInView = (
  node: Element | null,
  {
    root = null,
    rootMargin = "0px",
    threshold = 0,
  }: IntersectionObserverInit = {},
) => {
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      {
        root,
        rootMargin,
        threshold,
      },
    );

    if (node) {
      observer.observe(node);
    }

    return () => {
      if (node) {
        observer.unobserve(node);
      }
    };
  }, [node, root, rootMargin, threshold]);

  return isInView;
};
