import { _ } from "../../languages/helper";

const PASSWORD_LEN = { min: 8, max: 64 };

export const isPasswordStrongEnough = (password: string) => {
  const passwordWrongLen =
    password.length < PASSWORD_LEN.min || password.length > PASSWORD_LEN.max;
  if (passwordWrongLen) {
    return _`The password is to short: minimum length is 8 characters.`;
  }

  return true;
};
