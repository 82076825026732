import { useState } from "react";
import { useLocation } from "react-router-dom";

import RoundedCard from "../../components/Card/RoundedCard";
import FloatingLogout from "../../components/FloatingLogout/FloatingLogout";
import Centering from "../../components/Helpers/Centering";
import Typography from "../../components/Typography/Typography";
import { Button, Popup } from "../../icecube-ux";
import { _ } from "../../languages/helper";

import PlansPopup from "./PlansPopup";

export default function FreeTrialEndedPopup() {
  const location = useLocation();
  const [isShowingPlans, setIsShowingPlans] = useState(false);

  if (location.pathname === "/settings/account") {
    return null;
  }

  return (
    <>
      {isShowingPlans && (
        <PlansPopup onClose={() => setIsShowingPlans(false)} />
      )}

      <Popup onClose={() => {}} hideCloseButton>
        <FloatingLogout />
        <Centering>
          <RoundedCard className="text-center">
            <img className="inline" src="/img/billing_need.png" alt="" />
            <Typography.H2>{_`Upgrade your account`}</Typography.H2>
            <Typography.P className="text-xs text-lightText mtx-30 mbx-30">
              {_`Your free trial has expired`}
              <br />
              {_`Upgrade to continue using Polar Analytics`}
            </Typography.P>
            <Button
              className="w-96"
              block={false}
              onClick={() => setIsShowingPlans(true)}
            >
              {_`View plans`}
            </Button>
            <Typography.P className="mtx-30">
              {_`We look forward to be working with you!`}{" "}
              <img
                className="inline"
                src="/img/emojis/cool.png"
                alt=""
                style={{ height: "16px" }}
              />
            </Typography.P>
          </RoundedCard>
        </Centering>
      </Popup>
    </>
  );
}
