import { MouseEvent } from "react";
import { css, CSSObject } from "styled-components";

import { ConnectorIconNames } from "./ConnectorIcon";

export type Breakpoint = "xs" | "s" | "l" | "xl";

export const BREAKPOINTS: Record<Breakpoint, number> = {
  xs: 480,
  s: 1024,
  l: 1536,
  xl: 1920,
} as const;

type Prefixes = "xs" | "s" | "l" | "xl";
export type ResponsiveAttribute<T> = Partial<Record<Prefixes, T>> | T;

export function getResponsiveCSSFromProps(props: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: ResponsiveAttribute<any>;
}) {
  const responsiveCss: Partial<
    Record<Prefixes | "all", TemplateStringsArray | CSSObject>
  > = { all: {} };
  Object.entries(props).forEach(([key, value]) => {
    if (typeof value === "object") {
      /*  eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
      value.xs !== undefined &&
        (responsiveCss.xs = { ...responsiveCss.xs, [key]: value.xs });
      value.s !== undefined &&
        (responsiveCss.s = { ...responsiveCss.s, [key]: value.s });
      value.l !== undefined &&
        (responsiveCss.l = { ...responsiveCss.l, [key]: value.l });
      value.xl !== undefined &&
        (responsiveCss.xl = { ...responsiveCss.xl, [key]: value.xl });
    } else {
      value !== undefined &&
        (responsiveCss.all = { ...responsiveCss.all, [key]: value });
    }
    /*  eslint-enable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
  });
  return `
  ${
    responsiveCss.xl
      ? `@media(min-width: ${BREAKPOINTS.l + 1}px) {
    ${css(responsiveCss.xl).join("\r\n")}
  }`
      : ""
  }
  ${
    responsiveCss.l
      ? `@media(max-width: ${BREAKPOINTS.l}px) {
    ${css(responsiveCss.l).join("\r\n")}
  }`
      : ""
  }
  ${
    responsiveCss.s
      ? `@media(max-width: ${BREAKPOINTS.s}px) {
    ${css(responsiveCss.s).join("\r\n")}
  }`
      : ""
  }
  ${
    responsiveCss.xs
      ? `@media(max-width: ${BREAKPOINTS.xs}px) {
    ${css(responsiveCss.xs).join("\r\n")}
  }`
      : ""
  }
  ${responsiveCss.all ? css(responsiveCss.all).join("\r\n") : ""}
  `;
}

export const stopPropagation = (e: MouseEvent<HTMLElement, Event>) => {
  e.stopPropagation();
  e.preventDefault();
  return false;
};

export const oldImageNamesMapping: Record<string, ConnectorIconNames> = {
  "/img/connectors/shopify.png": "Shopify",
  "/img/connectors/amazon.png": "Amazon",
  "/img/connectors/facebook.png": "Facebook",
  "/img/connectors/bing.png": "Bing",
  "/img/connectors/criteo.png": "Criteo",
  "/img/connectors/ga.png": "GoogleAnalytics",
  "/img/connectors/gads.png": "GoogleAds",
  "/img/connectors/google-sheets.png": "GoogleSheets",
  "/img/connectors/klaviyo.png": "Klaviyo",
  "/img/connectors/pinterest.png": "Pinterest",
  "/img/connectors/polar.png": "Polar",
  "/img/connectors/recharge.png": "Recharge",
  "/img/connectors/snapads.png": "Snapchat",
  "/img/connectors/tiktok.png": "Tiktok",
  "/img/connectors/yotpo.png": "Yotpo",
};
