import styled from "styled-components";

import { InformationFilledIcon } from "../../../components/Icons/Icons";
import { theme } from "../../../icecube-ux";

export const StyledInformationFilledIcon = styled(InformationFilledIcon)`
  color: ${theme.colors.primary80};
  vertical-align: middle;
  margin-left: 3px;
`;
