import { ReactNode } from "react";

import { Flex } from "../Flex";
import { theme } from "../theme";
import { Text, TextVariant } from "../Typography";

import { StyledTabSelector } from "./TabSelector.styled";
export interface TabElement {
  value: string;
  label?: string;
  description?: string;
  background?: string;
  borderBottom: string;
  icon?: ReactNode;
  rightIcon?: ReactNode;
  forceWidth?: string;
  disabled?: boolean;
}
interface TabSelectorProps {
  elements: TabElement[];
  gap?: number;
  labelTextVariant?: TextVariant;
  descriptionTextVariant?: TextVariant;
  tabBackground?: string;
  tabBorderRightColor?: string;
  tabBorderBottomColor?: string;
  tabSpacingNumber?: number;
  maxTabSize?: number;
  selectedValue: string;
  onClick: (value: string) => void;
  availableWidth?: number;
}

const DEFAULT_MAX_TAB_SIZE = 220;
const DEFAULT_MIN_TAB_SIZE = 26;

const getMaxTabSize = (
  openedElementNumber: number,
  availableWidth: number,
  maxTabSize: number,
  spacingNumber: number, // padding + margin etc
) => {
  const tabSize =
    Math.min(Math.floor(availableWidth / openedElementNumber), maxTabSize) -
    spacingNumber;
  return Math.max(tabSize, DEFAULT_MIN_TAB_SIZE);
};

export const TabSelector = ({
  elements,
  selectedValue,
  onClick,
  gap = 4,
  labelTextVariant = "body14Medium",
  descriptionTextVariant = "body12Regular",
  tabBackground = theme.colors.white100,
  maxTabSize = DEFAULT_MAX_TAB_SIZE,
  tabBorderRightColor,
  tabBorderBottomColor,
  tabSpacingNumber = 30,
  availableWidth = window.innerWidth,
}: TabSelectorProps) => {
  return (
    <Flex
      gap={gap}
      style={{
        background: tabBackground,
      }}
    >
      {elements.map((item) => (
        <StyledTabSelector
          tabBorderRightColor={tabBorderRightColor}
          tabBorderBottomColor={tabBorderBottomColor}
          key={item.value}
          isSelected={item.value === selectedValue}
          background={item.background}
          selectedBorderBottomColor={item.borderBottom}
          forceWidth={item.forceWidth}
          disabled={item.disabled}
          onClick={item.disabled ? undefined : () => onClick(item.value)}
        >
          <Flex
            gap={12}
            alignItems={"center"}
            style={{
              maxWidth: getMaxTabSize(
                elements.length,
                availableWidth,
                maxTabSize,
                tabSpacingNumber,
              ),
            }}
          >
            {item.icon}
            <Flex
              flexDirection={"column"}
              style={{
                width: "calc(100% - 26px)",
              }}
            >
              {item.label ? (
                <Text
                  variant={labelTextVariant}
                  color={theme.colors.text110}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item.label}
                </Text>
              ) : (
                <></>
              )}
              {item.description ? (
                <Text
                  variant={descriptionTextVariant}
                  color={theme.colors.text90}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item.description}
                </Text>
              ) : (
                <></>
              )}
            </Flex>
            {item.rightIcon}
          </Flex>
        </StyledTabSelector>
      ))}
    </Flex>
  );
};
