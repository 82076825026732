import { useEffect, useState } from "react";
import { Link, matchPath, useLocation } from "react-router-dom";

import { useBootstrap } from "../../hooks/bootstrap";
import { Flex, Icon, Text, theme } from "../../icecube-ux";
import { _ } from "../../languages/helper";
import { shouldShowReferral } from "../../utils/subscriptionsUtils";

const excludePaths = [
  "/ask",
  "/ask/*",
  "/custom/create",
  "/custom/create/*",
  "/referrals",
  "/report/edit/*",
];
const baseFooterStyle = {
  marginTop: 16,
  borderTop: `1px solid ${theme.colors.borderLight}`,
  paddingTop: 4,
};
export const ReferralsFooter = ({
  parentContainer,
  childrenContainer,
}: {
  parentContainer: HTMLDivElement | null;
  childrenContainer?: HTMLDivElement | null;
}) => {
  const location = useLocation();
  const { subscription, isDemoData } = useBootstrap();
  const showReferral = shouldShowReferral(isDemoData, subscription);
  const isExcluded = excludePaths.some((excludedPath) =>
    matchPath(excludedPath, location.pathname),
  );
  const [isScroll, setIsScroll] = useState(false);
  useEffect(() => {
    if (!childrenContainer) return;
    if (ResizeObserver) {
      const resizeObserver = new ResizeObserver(() => {
        const isScroll =
          (parentContainer?.clientHeight ?? 0) <
          (childrenContainer?.scrollHeight ?? 0);
        setIsScroll(isScroll);
      });
      if (childrenContainer) {
        resizeObserver.observe(childrenContainer);
      }
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [parentContainer, childrenContainer]);
  if (isExcluded || !showReferral) return <></>;

  return (
    <Flex
      fullWidth={isScroll}
      justifyContent={"flex-start"}
      alignItems={"center"}
      style={
        isScroll
          ? { ...baseFooterStyle }
          : {
              ...baseFooterStyle,
              position: "fixed",
              bottom: 0,
              marginBottom: 24,
              width: "calc(100% - 322px)",
            }
      }
    >
      <Icon name="ColoredGift" size={17} />
      <Link
        to="/referrals"
        style={{
          height: 17,
          lineHeight: 17,
        }}
      >
        <Text
          variant="body12Medium"
          color={theme.colors.primary100}
          style={{ marginLeft: "6px" }}
        >
          {_`PS: Refer a friend and get up to $300 for every brand`}
        </Text>
      </Link>
    </Flex>
  );
};
