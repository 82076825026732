import { createClient } from "./apiClient";

export interface KlaviyoFlowsSetup {
  shopify_url: string;
  trigger_type: string;
  original_flow_json: { id: string };
  polar_flow_json: { id: string };
}

const client = createClient("capi-service");

export const getTenantKlaviyoFlowsSetups = async (token: string) => {
  const result = await client
    .new()
    .get("/api/klaviyoFlowsSetups")
    .auth(token)
    .fetch();
  if (result.error) {
    throw new Error(result.message);
  }
  return result.data as KlaviyoFlowsSetup[];
};
