import type {
  PermissionCheck,
  PermissionKey,
  SupportedPermissions,
} from "./types";

/**
 * Traverses a tree of permissions along a path, looking for nodes that
 * are granted. If the path contains subselections, valdiates the paths
 * both with and without said selections.
 *
 * - If such a node can be found, returns true.
 * - Otherwise, returns false.
 */
export const hasPermission = (
  permissions: SupportedPermissions,
  permissionKey: PermissionKey,
): boolean => {
  // We are doing type magic here, so we don't care about the node types
  // inside the function. What's important is that the function API is
  // well typed.

  const permissionPath = permissionKey.split(".");

  const traversalResult = permissionPath.reduce((acc: unknown, pathSegment) => {
    if (typeof acc === "boolean") {
      return acc;
    }

    const node = (acc as Record<string, unknown> | undefined)?.[pathSegment];

    if ((node as Record<string, unknown> | undefined)?.granted) {
      return true;
    }

    return node;
  }, permissions);

  return traversalResult === true;
};

export const buildPermissionCheck =
  (permissions: SupportedPermissions): PermissionCheck =>
  (key: PermissionKey) =>
    hasPermission(permissions, key);
