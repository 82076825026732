import styled from "styled-components";

import { legacyTheme } from "../legacyTheme";

export const StyledPaginator = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: ${legacyTheme.margin.large};
  font-weight: ${legacyTheme.typography.fontWeigth.w500};
  font-size: ${legacyTheme.typography.fontSizes.large};
  line-height: 18px;
  color: ${legacyTheme.colors.grey1};
  background: ${legacyTheme.colors.white};
  user-select: none;
`;

export const StyledPaginatorPageInfo = styled.div`
  margin: 0 ${legacyTheme.margin.large};
`;
