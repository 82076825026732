import { randomString } from "../utils/utils";

import { getServiceHost } from "./services";

export const addSlackIntegration = async (
  token: string,
  code: string,
  fromSettings: boolean,
): Promise<boolean> => {
  const response = await fetch(
    getServiceHost("email-service") + "/api/integrations/slack",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        LogContext: randomString(12),
        token,
      },
      body: JSON.stringify({
        code,
        fromSettings,
        subdomain:
          process.env.REACT_APP_ENV !== "production" ? "staging" : "app",
      }),
    },
  );
  const json = await response.json() as { error: boolean } //To fix, request can fail;;
  return !json.error;
};

export const removeSlackIntegration = async (
  token: string,
): Promise<boolean> => {
  const response = await fetch(
    getServiceHost("email-service") + "/api/integrations/slack",
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        LogContext: randomString(12),
        token,
      },
    },
  );
  const json = await response.json() as { error: boolean } //To fix, request can fail;;
  return !json.error;
};

export const getScheduledReports = async (token: string) => {
  const response = await fetch(
    getServiceHost("email-service") + "/api/schedules",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        LogContext: randomString(12),
        token,
      },
    },
  );
  const json = (await response.json()) as {
    error?: boolean;
    data: { method: "email" | "slack"; tenant_id: string; user_id: string }[];
  }; //To fix, request can fail

  if (json.error === true) {
    return [];
  }
  return json.data;
};

export const createScheduledReports = async (
  token: string,
  method: "email" | "slack",
): Promise<boolean> => {
  const response = await fetch(
    getServiceHost("email-service") + "/api/schedules",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        LogContext: randomString(12),
        token,
      },
      body: JSON.stringify({ method }),
    },
  );
  const json = (await response.json()) as { error: boolean }; //To fix, request can fail;
  return !json.error;
};

export const deleteScheduledReports = async (
  token: string,
  method: "email" | "slack",
): Promise<boolean> => {
  const response = await fetch(
    getServiceHost("email-service") + "/api/schedules/" + method,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        LogContext: randomString(12),
        token,
      },
    },
  );
  const json = (await response.json()) as { error: boolean }; //To fix, request can fail;
  return !json.error;
};

export const patchScheduledReports = async (
  token: string,
  timezone: string,
): Promise<boolean> => {
  const response = await fetch(
    getServiceHost("email-service") + "/api/schedules/timezone",
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        LogContext: randomString(12),
        token,
      },
      body: JSON.stringify({ timezone }),
    },
  );
  const json = (await response.json()) as { error: boolean }; //To fix, request can fail;
  return !json.error;
};
