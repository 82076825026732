import { Element } from "./dashboardElement";
import {
  createKISection,
  deleteKISection,
  getKISections,
  KISection,
  patchKISection,
} from "./kiSectionsService";
import {
  createCustomReport,
  deleteCustomReport,
  getCustomReports,
  patchCustomReport,
  Report,
} from "./reportsService";

export const isKISectionElement = (element?: Element): element is KISection =>
  (element as KISection)?.metrics !== undefined;

export const kiElementController = {
  getAll: async (token: string) => {
    return await getKISections(token);
  },
  create: async (token: string, section: Element) => {
    if (!isKISectionElement(section)) {
      return false;
    }
    return await createKISection({
      token,
      title: section.title,
      metrics: section.metrics,
      templateId: section.templateId,
    });
  },
  edit: async (token: string, id: number, section: Element) => {
    if (!id) return false;
    if (!isKISectionElement(section)) {
      return false;
    }
    const updatedSection = await patchKISection({
      token,
      title: section.title,
      metrics: section.metrics,
      goals: section?.goals ?? {},
      id,
      templateId: section.templateId,
    });
    return !!updatedSection;
  },
  delete: async (token: string, id: number, force?: true) => {
    return await deleteKISection(token, id, force);
  },
};

export const isReportElement = (element?: Element): element is Report =>
  (element as Report)?.json_value !== undefined;

export const reportElementController = {
  getAll: async (token: string) => {
    return await getCustomReports(token);
  },
  create: async (token: string, report: Element) => {
    if (!isReportElement(report)) {
      return null;
    }
    return await createCustomReport(
      token,
      report.title,
      report.json_value,
      report.section_key,
    );
  },
  edit: async (token: string, id: number, report: Element) => {
    if (!id) return false;
    if (!isReportElement(report)) {
      return false;
    }
    const updatedReport = await patchCustomReport(
      token,
      report.title,
      { ...report.json_value, title: report.title },
      id,
    );
    return !!updatedReport;
  },
  delete: async (token: string, id: number, force?: true) => {
    return await deleteCustomReport(token, id, force);
  },
};
