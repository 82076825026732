import { useEffect, useState } from "react";

import { _ } from "../../../../languages/helper";
import { ConnectorIconNames } from "../../../ConnectorIcon";
import { ListOptions } from "../../../List/List";

import Selector from "./Selector";
import TableBlock, { DimensionOrMetricDefinition } from "./TableBlock";

export interface TableDefinition {
  label: string;
  key: string;
  icon: ConnectorIconNames;
  background: string;
  dimensions?: DimensionOrMetricDefinition[];
  metrics?: DimensionOrMetricDefinition[];
}

type DimensionSelectorProps = {
  tables: TableDefinition[];
  options?: ListOptions[];
  onValueChange?: (key: string) => void;
  onFilterOptions?: (options: ListOptions[]) => void;
  highlight?: string;
  preSelectionHint?: string;
  fullWidth?: boolean;
  label?: string;
  withTabs?: boolean;
};

export default function DimensionOrMetricSelector({
  tables,
  options = [],
  onValueChange,
  onFilterOptions,
  highlight,
  preSelectionHint,
  fullWidth,
  label,
  withTabs = true,
}: DimensionSelectorProps) {
  const [tableFilters, setTableFilters] = useState<string[]>([]);

  const handleSelectTable = (key: string) => {
    setTableFilters((filters) =>
      filters.includes(key)
        ? filters.filter((f) => f !== key)
        : [...filters, key],
    );
  };

  useEffect(() => {
    onFilterOptions &&
      onFilterOptions(
        tableFilters.length === 0
          ? options
          : tables
              .filter((table) => tableFilters.includes(table.key))
              .map((t) =>
                [...(t.dimensions ?? []), ...(t.metrics ?? [])].map((d) => ({
                  label: d.label,
                  value: d.key,
                })),
              )
              .flat(),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilters]);

  return (
    <Selector
      label={label ?? _`I'm looking for data from...`}
      tabs={
        withTabs
          ? [
              ...tables
                .filter(({ metrics, dimensions }) => {
                  return (
                    (metrics?.length ?? 0) > 0 || (dimensions?.length ?? 0) > 0
                  );
                })
                .map((table) => ({
                  key: table.key,
                  icon: table.icon,
                  label: table.label,
                })),
            ]
          : undefined
      }
      blocks={tables
        .filter(({ metrics, dimensions }) => {
          return (metrics?.length ?? 0) > 0 || (dimensions?.length ?? 0) > 0;
        })
        .map((table) => ({
          key: table.key,
          element: (
            <TableBlock
              highlight={highlight}
              preSelectionHint={preSelectionHint}
              onSelectElement={onValueChange}
              {...table}
            />
          ),
        }))}
      filters={tableFilters}
      onFilterChange={handleSelectTable}
      onSelectAllFilters={() => setTableFilters([])}
      fullWidth={fullWidth}
    />
  );
}
