import Centering from "../../../../components/Helpers/Centering";
import { _ } from "../../../../languages/helper";
import "./placeholders.css";

export default function SomethingWrong() {
  return (
    <Centering className="placeholder">
      <img src="/img/no-result-found.svg" alt="" />
      <div className="info">{_`Oops! Something went wrong`}</div>
      <div className="subtext gray">
        {_`You can refresh the page and try again.`}
        <br />
        {_`If the issue persists, please contact us in the chat or send an e-mail to`}
        <br />
        <a href="mailto:support@polaranalytics.co">support@polaranalytics.co</a>
      </div>
    </Centering>
  );
}
