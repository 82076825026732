import classNames from "classnames";

import { ClassAndStyleProps, InteractionEvents } from "../shared";

import ConnectorIcon from "./ConnectorIcon";
import { StyledConnectorIconWrapper } from "./ConnectorIconWrapper.styled";
import { ConnectorIconNames } from "./types";

type ConnectorIconWrapperProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    name: ConnectorIconNames;
    background: string;
    size?: "tiny" | "small" | "large";
  };

const sizes = {
  tiny: 8,
  small: 10,
  large: 14,
} as const;

export default function ConntectorIconWrapperComponent({
  name,
  background,
  size = "small",
  className,
  style,
  ...interactionEvents
}: ConnectorIconWrapperProps) {
  return (
    <StyledConnectorIconWrapper
      {...interactionEvents}
      className={classNames(className)}
      style={{
        ...style,
        background,
      }}
      size={size}
    >
      <ConnectorIcon name={name} size={sizes[size]} />
    </StyledConnectorIconWrapper>
  );
}
