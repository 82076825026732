import React from "react";

import { _ } from "../../languages/helper";

import { StyledFreeTrialMarketingPopupHeaderHighlight } from "./FreeTrialMarketingPopup.styled";

export type freeTrialMarketingPopupSlideId = string; //TODO: use specific type options: "unlimited-key-indicators" | "all-data" || "connectors";

export const slides: {
  id: freeTrialMarketingPopupSlideId;
  headerText: React.ReactNode;
  headerImage: string;
  contentText: React.ReactNode;
  imageStyle?: React.CSSProperties;
}[] = [
  {
    id: "connectors",
    headerText: (
      <>
        {_`Connect`}{" "}
        <StyledFreeTrialMarketingPopupHeaderHighlight>
          {_`all connectors`}
        </StyledFreeTrialMarketingPopupHeaderHighlight>{" "}
        {_`and`}{" "}
        <StyledFreeTrialMarketingPopupHeaderHighlight>
          {_`Stores`}
        </StyledFreeTrialMarketingPopupHeaderHighlight>{" "}
      </>
    ),
    headerImage: "connectors",
    contentText: _`Enjoy all Polar Analytics connectors for 7 days. Add all your stores and create views to analyze your performance more precisely`,
    imageStyle: { width: "130%", left: -25 },
  },
  {
    id: "unlimited-key-indicators",
    headerText: (
      <>
        <StyledFreeTrialMarketingPopupHeaderHighlight>
          {_`Add|||imperative`}
        </StyledFreeTrialMarketingPopupHeaderHighlight>{" "}
        {_`as many`}{" "}
        <StyledFreeTrialMarketingPopupHeaderHighlight>
          {_`key indicators`}
        </StyledFreeTrialMarketingPopupHeaderHighlight>{" "}
        {_`as you need`}
      </>
    ),
    headerImage: "key-indicators",
    contentText: _`No more limit, add as many key indicators as you want. Create sections by theme to save analysis time and focus on the essentials`,
    imageStyle: { left: -8 },
  },
  {
    id: "all-data",
    headerText: (
      <>
        {_`Access your`}{" "}
        <StyledFreeTrialMarketingPopupHeaderHighlight>
          {_`full data history`}
        </StyledFreeTrialMarketingPopupHeaderHighlight>
      </>
    ),
    headerImage: "year-old-date",
    contentText: _`Analyze and compare all your data without the 1 year limit. Observe annual trends that emerge to improve performance.`,
  },
  {
    id: "alerts",
    headerText: (
      <>
        <StyledFreeTrialMarketingPopupHeaderHighlight>
          {_`Create alerts|||imperative`}
        </StyledFreeTrialMarketingPopupHeaderHighlight>{" "}
        {_`so you don't miss a thing`}
      </>
    ),
    headerImage: "alerts",
    contentText: _`Don't miss a thing and configure alerts on significant metrics to always be one step ahead.`,
    imageStyle: { width: "120%" },
  },
  {
    id: "analyze",
    headerText: (
      <>
        <StyledFreeTrialMarketingPopupHeaderHighlight>
          {_`Analyze|||imperative`}
        </StyledFreeTrialMarketingPopupHeaderHighlight>{" "}
        {_`your|||performance`}{" "}
        <StyledFreeTrialMarketingPopupHeaderHighlight>
          {_`overall performance`}
        </StyledFreeTrialMarketingPopupHeaderHighlight>
      </>
    ),
    headerImage: "analyze",
    contentText: _`Identify how to drive revenues, ROI optimizations and automate your reporting with Polar Analytics.`,
    imageStyle: { width: "120%", left: -36, top: -20 },
  },
];
