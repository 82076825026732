import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import Centering from "./components/Helpers/Centering";
import { Flex, Logo, Text } from "./icecube-ux";
import { _ } from "./languages/helper";
import { isInMaintenanceMode } from "./lib/usersService";

interface MaintenanceProps {
  children: React.ReactElement;
}

export default function Maintenance({ children }: MaintenanceProps) {
  const [maintenanceMode, setMaintenanceMode] = useState(false);

  useEffect(() => {
    void isInMaintenanceMode().then(setMaintenanceMode);
  }, []);

  if (maintenanceMode) {
    return (
      <div className="full-page-loader">
        <Helmet>
          <title>{_`Loading...`} | Polar Analytics</title>
        </Helmet>
        <Centering>
          <Flex flexDirection="column" gap={40} alignItems="center">
            <Logo variant="colored" />
            <Text variant="body14Regular" textAlign="center">
              {_`Polar is currently performing a system upgrade to provide you with an even better experience.`}
              <br />
              {_`We apologize for the inconvenience and will be back online shortly.`}
              <br />
              <br />
              {_`Thank you for your patience!`}
            </Text>
          </Flex>
        </Centering>
      </div>
    );
  }

  return children;
}
