const memoryCacheInstance: { [key: string]: unknown } = {};

export const MemoryCache = {
  set: (key: string, value: unknown) => {
    memoryCacheInstance[key] = value;
  },

  get: (key: string, defaultValue: unknown = null) => {
    return memoryCacheInstance[key] || defaultValue;
  },

  has: (key: string) => memoryCacheInstance[key] !== undefined,
};
