import styled from "styled-components";

export const StyledSubscriptionPlanHeader = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80px;
  padding: 16px 16px 12px;
  gap: 4px;
`;

export const StyledSubscriptionPlanHeaderSection = styled.div`
  flex-grow: 1;
`;
