import classNames from "classnames";

import {
  ClassAndStyleProps,
  InteractionEvents,
} from "../../../../icecube-ux/shared";

import {
  StyledOnboardingProgressBar,
  StyledOnboardingProgressBarWrapper,
} from "./OnboardingProgressBar.styled";

type OnboardingProgressBarProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    /**
     * A fraction between 0 and 1
     */
    progress: number;
  };

export default function OnboardingProgressBar({
  className,
  style,
  progress,
  ...interactionEvents
}: OnboardingProgressBarProps) {
  return (
    <StyledOnboardingProgressBarWrapper
      {...interactionEvents}
      className={classNames(className)}
      style={style}
    >
      <StyledOnboardingProgressBar
        role="progressbar"
        aria-valuenow={progress}
        aria-valuemin={0}
        aria-valuemax={1}
      />
    </StyledOnboardingProgressBarWrapper>
  );
}
