import classNames from "classnames";

import { Box } from "../Box";
import Grid from "../Grid/Grid";
import { IconNames } from "../Icon/Icon";
import { ClassAndStyleProps, InteractionEvents } from "../shared";

import ExampleLayout from "./components/ExampleLayout";
import { StyledExamples } from "./Examples.styled";

export type ExamplesProps = InteractionEvents<HTMLDivElement> &
  ClassAndStyleProps & {
    questions: string[];
    leftIcon: IconNames;
    onExampleClick: (template: string) => void;
    rightIcon: IconNames;
    headerComponent?: React.ReactNode;
  };

export default function Examples({
  className,
  style,
  questions,
  leftIcon,
  rightIcon,
  onExampleClick,
  headerComponent,
  ...interactionEvents
}: ExamplesProps) {
  return (
    <StyledExamples
      {...interactionEvents}
      style={style}
      className={classNames(className)}
    >
      <Grid gridTemplateColumns="1fr 1fr" gap={16}>
        {headerComponent && <Box gridColumn="span 2">{headerComponent}</Box>}
        {questions.map((question) => (
          <ExampleLayout
            key={question}
            question={question}
            leftIcon={leftIcon}
            rightIcon={rightIcon}
            onClick={() => onExampleClick(question)}
          />
        ))}
      </Grid>
    </StyledExamples>
  );
}
