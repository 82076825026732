import React from "react";

import { theme } from "../../icecube-ux";
import { useDeviceType } from "../../icecube-ux/hooks/provideDeviceType";
import TrustedBy from "../../pages/login/components/TrustedBy";
import "./mobile-container.css";

interface MobileContainerProps {
  img?: string;
  title?: React.ReactElement | string;
  children?: React.ReactElement | null;
}

export default function MobileContainer({
  img,
  title,
  children,
}: MobileContainerProps) {
  const { isMobile } = useDeviceType();

  if (!isMobile) {
    return null;
  }
  return (
    <div
      className="mobile-container"
      style={{
        background: "url(img/grainy-background.png)",
        backgroundColor: theme.customColors.darkerLandingPageBackground,
        backgroundSize: "cover",
      }}
    >
      <div>
        <div className="centering">
          <div className="m-auto">
            <a href="https://www.polaranalytics.co/">
              <img
                src="/img/whiteLogo.svg"
                className="inline"
                alt="Polar"
                style={{ height: "60px" }}
              />
            </a>
          </div>
        </div>
      </div>
      <div>
        <div className="mobile-bottom-container">
          {img && (
            <>
              <img src={img} alt="" className="mobile-block-img" />
              <div className="mobile-block-img-separator"></div>
            </>
          )}
          {title && <div className="page-title">{title}</div>}
          {children}
        </div>
      </div>
      <TrustedBy />
    </div>
  );
}
