import classNames from "classnames";

import { ClassAndStyleProps, InteractionEvents } from "../shared";

import { StyledTextArea } from "./TextArea.styled";

export type TextAreaProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    block?: boolean;
    placeholder?: string;
    value: string;
    onChange: (value: string) => void;
    rows?: number;
  };

export default function TextArea({
  className,
  style,
  value,
  onChange,
  rows,
  ...interactionEvents
}: TextAreaProps) {
  return (
    <StyledTextArea
      value={value}
      onChange={(e) => onChange(e.target.value)}
      rows={rows}
      {...interactionEvents}
      className={classNames(className)}
      style={style}
    />
  );
}
