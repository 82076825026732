import { ReactNode, createContext, useContext, useRef, useState } from "react";

import { AddonType } from "../common/types/subscription-service";

type AddonContextType = {
  addon: AddonType | undefined;
  setAddon: (addon: AddonType | undefined) => void;
  disabledAnimation: boolean;
};
const AddonContext = createContext<AddonContextType | undefined>(undefined);

export const useAddonPopup = () => {
  const context = useContext(AddonContext);
  if (!context) {
    throw new Error(
      "useAddon must be used within an AddonProvider. Make sure the Route's component is wrapped with withAuthentication",
    );
  }
  return context;
};

const useProvideAddonPopups = () => {
  const [addonPopup, setAddonPopup] = useState<AddonType | undefined>();
  const [disabledAnimation, setDisabledAnimation] = useState<boolean>(false);
  const previousAddon = useRef<string | undefined>();

  const addonContextValue: AddonContextType = {
    addon: addonPopup,
    setAddon: (addon) => {
      setAddonPopup(addon);
      setDisabledAnimation(previousAddon.current !== undefined);
      previousAddon.current = addon;
    },
    disabledAnimation,
  };

  return addonContextValue;
};
export const ProvideAddonPopups: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const ctx = useProvideAddonPopups();

  return <AddonContext.Provider value={ctx}>{children}</AddonContext.Provider>;
};
