import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../hooks/auth/auth";
import { useBootstrap } from "../../hooks/bootstrap";
import { useCustomElementEditors } from "../../hooks/customElementEditors";
import { CustomDimensionCategory } from "../../hooks/customElementEditorsTypes";
import { useModals } from "../../hooks/modals";
import {
  LOCAL_STORAGE_KEYS,
  removeMatchingLocalStorageKeys,
} from "../../hooks/useLocalStorage";
import {
  Box,
  FullScreenPopup,
  Grid,
  Input,
  Select,
  theme,
} from "../../icecube-ux";
import { _ } from "../../languages/helper";
import {
  createCustomDimension,
  getCustomDimension,
  patchCustomDimension,
} from "../../lib/customDimensionService";
import { TRACKING_EVENTS, trackEvent } from "../../utils/trackingUtils";

import Builder from "./components/Builder/Builder";
import {
  createNewCustomDimension,
  isValidCustomDimension,
} from "./components/Builder/utils";
import Documentation from "./components/Documentation/Documentation";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Preview from "./components/Preview";
import { CustomDimension } from "./types";
import { IS_DOC_READY, borderBottomStyle, borderRightStyle } from "./utils";

interface CustomBreakdownsPopupProps {
  onClose: () => void;
  id?: string;
}

export default function CustomBreakdownsPopup({
  onClose,
  id,
}: CustomBreakdownsPopupProps) {
  const auth = useAuth();
  const { tenant } = useBootstrap();
  const { showLoader, hideLoader, confirm, input } = useModals();
  const {
    deleteDimension,
    disableEdit,
    setDisableEdit,
    dimensionCategory,
    setDimensionCategory,
  } = useCustomElementEditors();

  const navigate = useNavigate();

  const [customDimension, setCustomDimension] = useState<CustomDimension>(
    createNewCustomDimension(),
  );
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [docOpened, setDocOpened] = useState(true);
  const [previewShown, setPreviewShown] = useState(true);

  const isValid = useMemo(() => {
    return isValidCustomDimension(customDimension);
  }, [customDimension]);

  const handleChange = (newDimension: CustomDimension) =>
    setCustomDimension(newDimension);

  const handleClose = () => {
    confirm({
      title: _`Are you sure you want to exit?`,
      texts: [_`All unsaved changes will be lost.`],
      onConfirm: onClose,
      confirmButtonLabel: _`Yes, exit`,
      cancelButtonLabel: _`No, go back`,
      danger: true,
    });
  };

  const handleDelete = () => {
    id !== undefined && deleteDimension(id);
  };

  const handleSave = async (forcedTitleInput?: string) => {
    trackEvent(TRACKING_EVENTS.CUSTOM_DIMENSION_SAVE, {
      tenant_id: tenant?.id || "N/A",
      dimensionCategory,
    });

    const forcedTitle = forcedTitleInput || title;
    if (!forcedTitle || forcedTitle.trim() === "") {
      return input({
        title: _`Enter a name`,
        texts: [_`Please enter a name to save this dimension`],
        onConfirm: async (value) => {
          setTitle(`${value}`);
          await handleSave(`${value}`);
        },
      });
    }

    showLoader();
    if (id) {
      await patchCustomDimension(
        await auth.getToken(),
        forcedTitle,
        customDimension,
        id,
      );
    } else {
      await createCustomDimension(
        await auth.getToken(),
        forcedTitle,
        customDimension,
      );
    }

    localStorage.removeItem(LOCAL_STORAGE_KEYS.CUSTOM_METRIC_DIMENSIONS);
    removeMatchingLocalStorageKeys((key) =>
      key.startsWith(LOCAL_STORAGE_KEYS.DASH_VIEWS_DIMENSIONS_PROVIDER_PREFIX),
    );
    if (!document.location.pathname.startsWith("/custom/create")) {
      navigate("/custom/create");
    }
    hideLoader();
    onClose();
  };

  useEffect(() => {
    if (!auth.processing && id) {
      const loadDimension = async () => {
        showLoader();
        const record = await getCustomDimension(await auth.getToken(), id);
        if (record) {
          setTitle(record?.title);
          setCustomDimension(record.json_value);
          setDescription(record?.description || "");
          setDisableEdit(record.disableEdit || false);
        }
        hideLoader();
      };
      void loadDimension();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, auth, auth.processing]);

  return (
    <FullScreenPopup onClose={handleClose}>
      <Grid
        style={{ height: "100%" }}
        gridTemplateColumns={docOpened && IS_DOC_READY ? "300px 1fr" : "1fr"}
        gridTemplateRows="auto 1fr auto"
      >
        <Header
          title={disableEdit ? title : undefined}
          description={disableEdit ? description : undefined}
        />
        {docOpened && IS_DOC_READY && (
          <Box
            overflow="auto"
            {...{ ...borderBottomStyle, ...borderRightStyle }}
          >
            <Documentation />
          </Box>
        )}
        <Box overflow="auto" {...borderBottomStyle}>
          <Grid
            gridTemplateRows={previewShown ? "60% 40%" : "1fr auto"}
            style={{ height: "100%" }}
          >
            <Box
              borderBottom={`1px solid ${theme.colors.borderLight}`}
              overflow="auto"
            >
              {!disableEdit && (
                <Box
                  borderBottom={`1px solid ${theme.colors.borderLight}`}
                  padding="24px"
                >
                  <Grid gridTemplateColumns="1fr 1fr" gap={16}>
                    <Input
                      label={_`Name`}
                      placeholder={_`Name your custom dimension...`}
                      value={title}
                      onChange={(v) => setTitle(`${v}`)}
                    />
                    <Select
                      label={_`Type`}
                      options={[
                        {
                          label: "Custom dimension (all dimensions available)",
                          value: "any",
                        },
                        {
                          label: "Channel Grouping",
                          value: "channelGrouping",
                        },
                      ]}
                      selected={[dimensionCategory]}
                      onChange={(v) =>
                        setDimensionCategory(v[0] as CustomDimensionCategory)
                      }
                    />
                  </Grid>
                </Box>
              )}

              <Box padding="24px">
                <Builder dimension={customDimension} onChange={handleChange} />
              </Box>
            </Box>
            <Preview
              isValid={isValid}
              dimension={customDimension}
              previewShown={previewShown}
              onTogglePreviewShown={() => setPreviewShown((v) => !v)}
            />
          </Grid>
        </Box>
        <Footer
          onCancel={handleClose}
          onSave={() => void handleSave()}
          canDelete={!disableEdit && id !== undefined}
          onDelete={handleDelete}
          docOpened={docOpened && IS_DOC_READY}
          onToggleDoc={(v) => setDocOpened(v)}
          canSave={!disableEdit && isValid}
        />
      </Grid>
    </FullScreenPopup>
  );
}
