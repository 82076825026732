import RoundedCard from "../../components/Card/RoundedCard";
import Centering from "../../components/Helpers/Centering";
import Typography from "../../components/Typography/Typography";
import { useCallBooking } from "../../hooks/useCallBooking";
import { Button, EmbeddedVideo, Popup } from "../../icecube-ux";
import { _ } from "../../languages/helper";

interface RequireMeetingPopupProps {
  setSkippedRequireMeetingAt: (val: Date) => void;
}

export default function RequireMeetingPopup({
  setSkippedRequireMeetingAt,
}: RequireMeetingPopupProps) {
  const { openSetupCallBooking, isLoading: isCallBookingModalLoading } =
    useCallBooking();

  return (
    <>
      <Popup onClose={() => {}} hideCloseButton>
        <Centering>
          <RoundedCard
            className="text-center"
            style={{ paddingBottom: "20px" }}
          >
            <img className="inline" src="/img/billing_need.png" alt="" />
            <Typography.H2 className="mbx-30">
              {_`Schedule your free trial set-up call`}
            </Typography.H2>

            <EmbeddedVideo
              title={_`Dashboard is ready`}
              src="https://www.loom.com/embed/3f23d8ebaa0d4edda7b3090d3a95b501"
              height="34vh"
              style={{ marginRight: "auto", marginLeft: "auto" }}
            />

            <Typography.P className="text-xs text-lightText mtx-12 mbx-12">
              {_`In this call, a product specialist will give you strategic insights and best practices on how to use the app to achieve your goals.`}
            </Typography.P>
            <Button
              size="large"
              onClick={() => void openSetupCallBooking()}
              loading={isCallBookingModalLoading}
            >
              {_`Get set-up for free`}
            </Button>
            <div className="margin-top-x5large">
              <Button
                size="large"
                color="ghost"
                onClick={() => setSkippedRequireMeetingAt(new Date())}
              >
                {_`Skip, I'll do it later`}
              </Button>
            </div>
          </RoundedCard>
        </Centering>
      </Popup>
    </>
  );
}
