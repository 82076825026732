import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { CustomConnectorButtonSection } from "../pages/connectors/popups/connect/customConnectors/CustomConnectorButtonSection";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";
registerFrontendIntegration({
  key: "instagram-business",
  image: "InstagramBusiness",
  label: "Instagram Business",
  category: INTEGRATION_CATEGORIES.marketing,
  backgroundColor: theme.customColors.fallbackConnectorBg,
  borderColor: theme.customColors.fallbackConnectorBorder,
  get description() {
    return _``;
  },
  get customConnectorInfo() {
    return [
      _`Content metrics use the date of whenever the content was created, not the date when the event happened`,
      _`Instagram metrics are in UTC timezone`,
      _`Data can take up to 24h to be exposed in the Meta API`,
    ];
  },
  // fixme: enable me when the scopes are acquired with facebook team
  // isNewIntegrationService: true,
  // dependsOnAccounts: true,
  // canReconnectOAuth: true,

  // fixme: remove me when the scopes are acquired with facebook team
  isCustomConnector: true,
  getButtonSection: () => CustomConnectorButtonSection,
});
