import classNames from "classnames";
import moment from "moment";

import { DateRange } from "../dateUtils";
import { Grid } from "../Grid";
import { IconButton } from "../IconButton";
import { legacyTheme } from "../legacyTheme";
import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { Text } from "../Typography";

import { useCalendar } from "./Calendar.hook";
import {
  StyledCalendar,
  StyledDateAndMonth,
  StyledDayFlex,
  StyledDayLetter,
  StyledDayNumber,
} from "./Calendar.styled";

export type CalendarProps = InteractionEvents<HTMLDivElement> &
  ClassAndStyleProps & {
    weekStartsOn?: number;
    currentMonth?: number;
    currentYear?: number;
    selectionRange?: DateRange;
    onSelectRange?: (range: DateRange) => void;
    maxDate?: moment.Moment;
    minDate?: moment.Moment;
    clear?: boolean;
    width?: string | number;
    shadowed?: boolean;
    selectSingleDate?: boolean;
    renderTodayTooltip?: (children: React.ReactNode) => React.ReactNode;
    withTodayTooltip?: boolean;
  };

export default function Calendar({
  weekStartsOn,
  currentMonth,
  currentYear,
  selectionRange,
  onSelectRange,
  maxDate,
  minDate,
  clear,
  width = 440,
  shadowed,
  selectSingleDate,
  className,
  style,
  withTodayTooltip = false,
  renderTodayTooltip,
  ...interactionEvents
}: CalendarProps) {
  const {
    containerRef,
    extraWidth,
    days,
    weeks,
    dayLetters,
    rest,
    canShowNextMonth,
    handleDayClick,
    handleDayMouseMove,
    handleShowNextMonth,
    handleShowPreviousMonth,
    displayedYear,
    displayedMonth,
  } = useCalendar({
    weekStartsOn,
    currentMonth,
    currentYear,
    selectionRange,
    onSelectRange,
    maxDate,
    minDate,
    selectSingleDate,
  });

  return (
    <StyledCalendar
      {...interactionEvents}
      ref={containerRef}
      style={style}
      className={classNames(className, { clear, shadowed })}
      width={width}
    >
      <Grid
        gridTemplateColumns="auto 1fr auto"
        alignItems="center"
        justifyItems="center"
      >
        <IconButton
          size="tiny"
          color="blue"
          name="ArrowLeft"
          onClick={handleShowPreviousMonth}
          dataAttributes={{ "data-cy": "calendar-previous-month" }}
        />
        <StyledDateAndMonth>
          <Text
            variant="BodyRegular"
            dataAttributes={{ "data-cy": "calendar-month-display" }}
          >
            {" "}
            {moment().month(displayedMonth).format("MMMM")}
          </Text>
          <Text
            variant="Tiny"
            color={legacyTheme.colors.primary3}
            dataAttributes={{ "data-cy": "calendar-year-display" }}
          >
            {displayedYear}
          </Text>
        </StyledDateAndMonth>
        <IconButton
          size="tiny"
          color="blue"
          name="ArrowRight"
          disabled={!canShowNextMonth}
          onClick={canShowNextMonth ? handleShowNextMonth : () => {}}
        />
      </Grid>
      <StyledDayFlex>
        {dayLetters.map((day, i) => (
          <StyledDayLetter key={`${day}-${i}`} data-cy="day">
            {day}
          </StyledDayLetter>
        ))}
      </StyledDayFlex>
      {weeks.map((_, i) => (
        <StyledDayFlex key={`week-${i}`}>
          {days.slice(i * 7, i * 7 + 7).map((day, r) => {
            const shouldShowTodayTooltip =
              withTodayTooltip && day.className.includes("today");
            const DayNumber = (
              <StyledDayNumber
                key={`day-${day.date?.toISOString() || r}`}
                data-before={day?.date?.date() || undefined}
                className={day.className}
                onClick={() => handleDayClick(day?.date)}
                onMouseMove={() => handleDayMouseMove(day?.date)}
                extraWidth={extraWidth}
                highlightToday={shouldShowTodayTooltip}
              />
            );
            if (shouldShowTodayTooltip) {
              return renderTodayTooltip?.(DayNumber) ?? DayNumber;
            }
            return DayNumber;
          })}

          {weeks.length - 1 === i &&
            rest < 7 &&
            new Array(rest)
              .fill(1)
              .map((_, r) => (
                <StyledDayNumber
                  key={`rest-${r}`}
                  className="empty"
                  extraWidth={extraWidth}
                />
              ))}
        </StyledDayFlex>
      ))}
    </StyledCalendar>
  );
}
