import { Text, Input, Grid, Loader, Stack } from "../../../icecube-ux";
import { _ } from "../../../languages/helper";

interface CustomMetricTitleProps {
  loading: boolean;
  title: string;
  onTitleChange: (value: string) => void;
}

export default function CustomMetricTitle({
  loading,
  title,
  onTitleChange,
}: CustomMetricTitleProps) {
  return loading ? (
    <Loader size={24} />
  ) : (
    <Stack gap={4}>
      <Text variant="body11Regular">{_`Add a name for your metric`} </Text>
      <Grid gridTemplateColumns="400px 1fr" gap={0}>
        <Input
          placeholder={_`Name your custom metric...`}
          value={title}
          onChange={(v) => onTitleChange(`${v}`)}
        />
        <div />
      </Grid>
    </Stack>
  );
}
