import React from "react";

const ScrollSyncContext = React.createContext({
  registerPane: () => {},
  unregisterPane: () => {},
} as {
  registerPane: (node: HTMLElement, groups: string[]) => void;
  unregisterPane: (node: HTMLElement, groups: string[]) => void;
});

export default ScrollSyncContext;
