import { Helmet } from "react-helmet";

import Centering from "../components/Helpers/Centering";
import { Loader } from "../components/Loader/Loader";
import { _ } from "../languages/helper";
import EmptyLayout from "../shared/Layouts/EmptyLayout";

export default function PageLoader() {
  return (
    <EmptyLayout>
      <Helmet>
        <title>{_`Loading...`} | Polar Analytics</title>
      </Helmet>
      <Centering>
        <Loader />
      </Centering>
    </EmptyLayout>
  );
}
