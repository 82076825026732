import styled from "styled-components";

import { useFormatters } from "../../hooks/useFormatters";
import { Flex, Icon, Text, Tooltip, theme } from "../../icecube-ux";
import { TooltipDirection } from "../../icecube-ux/Tooltip/Tooltip.hooks";
import { StyledTooltipContent } from "../../icecube-ux/Tooltip/Tooltip.styled";
import { _ } from "../../languages/helper";

interface UsageTooltipProps {
  direction: TooltipDirection;
  persistOnClick: boolean;
  orders?: number;
  icon: React.ReactNode;
}

const StyledUsageTooltip = styled(Tooltip)`
  ${StyledTooltipContent} {
    background: rgba(12, 12, 26, 0.7);
    position: relative;
    border-radius: ${theme.borderRadius.r6px};
    padding: 0;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      inset: 2px;
      backdrop-filter: blur(2px);
      border-radius: inherit;
      z-index: -1;
    }
  }
`;
export const UsageTooltip = ({
  direction,
  persistOnClick,
  orders,
  icon,
}: UsageTooltipProps) => {
  const { integerFormatter } = useFormatters();

  return (
    <StyledUsageTooltip
      persistOnClick={persistOnClick}
      direction={direction}
      mouseLeaveDelay={300}
      content={
        <div
          style={{
            textAlign: "left",
          }}
        >
          <div
            style={{
              borderBottom: `solid 1px rgba(256,256,256,0.4)`,
              padding: "10px 12px 8px 12px",
            }}
          >
            <Text variant="body12Medium" color={theme.colors.white100}>
              {_`Your consumption`}
            </Text>
            <Text variant="body11Regular" color={theme.colors.grey70}>
              <Flex flexDirection="row">
                {_`Based on the last 30 days`}{" "}
                <Icon
                  name="Clock"
                  style={{
                    marginLeft: "3px",
                    alignSelf: "center",
                  }}
                  size={12}
                />
              </Flex>
            </Text>
          </div>
          <div
            style={{
              borderBottom: `solid 1px rgba(256,256,256,0.4)`,
              padding: "8px 12px",
            }}
          >
            <Text variant="body11Regular" color={theme.colors.grey70}>
              {_`Number of tracked orders was`}
            </Text>
            <Text variant="body14Medium" color={theme.colors.white100}>
              {integerFormatter(orders ?? 0)}
              <Text variant="body14Medium" color={theme.colors.white100} inline>
                /{_`mo`}
              </Text>
            </Text>
          </div>
          <Text variant="body11Regular" style={{ padding: "8px 12px 12px" }}>
            <a
              style={{
                color: theme.colors.white100,
                textDecoration: "underline",
              }}
              target="_blank"
              rel="noreferrer"
              href="https://intercom.help/polar-app/en/articles/6531316-how-does-pricing-work"
            >
              {_`Learn more about our pricing model here`}
            </a>
          </Text>
        </div>
      }
    >
      {icon}
    </StyledUsageTooltip>
  );
};
