import classNames from "classnames";
import React from "react";

import { _ } from "../../languages/helper";
import { Button } from "../Button";
import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { Text } from "../Typography";

import {
  StyledFreeTrialMarketingPageLayout,
  StyledFreeTrialMarketingPageLayoutJumbotron,
  StyledFreeTrialMarketingPageLayoutJumbotronActions,
  StyledFreeTrialMarketingPageLayoutJumbotronContent,
} from "./FreeTrialMarketingPageLayout.styled";

export type FreeTrialMarketingPageLayoutProps =
  InteractionEvents<HTMLSpanElement> &
    ClassAndStyleProps & {
      heading: string | React.ReactNode;
      subheading: string;
      children: React.ReactNode;
      showViewPlans?: boolean;
      primaryButtonLabel?: string;
      onViewPlans?: () => void;
      onRequestFreeTrial: (() => Promise<void>) | (() => void);
      isLoading?: boolean;
    };

export default function FreeTrialMarketingPageLayout({
  heading,
  subheading,
  children,
  onRequestFreeTrial,
  className,
  style,
  showViewPlans = false,
  onViewPlans,
  primaryButtonLabel = "Request a free trial",
  isLoading,
  ...interactionEvents
}: FreeTrialMarketingPageLayoutProps) {
  return (
    <StyledFreeTrialMarketingPageLayout
      {...interactionEvents}
      className={classNames(className)}
      style={style}
    >
      <StyledFreeTrialMarketingPageLayoutJumbotron>
        <StyledFreeTrialMarketingPageLayoutJumbotronContent>
          <Text
            color="white"
            fontSize="38px"
            lineHeight="57px"
            textAlign="center"
            fontWeight="700"
          >
            {heading}
          </Text>
          <Text
            variant="Subtitle"
            color="rgba(256, 256, 256, 80%)"
            fontSize="18px"
            lineHeight="27px"
            textAlign="center"
            fontWeight="500"
            className="margin-top-x5large"
          >
            {subheading}
          </Text>
          <StyledFreeTrialMarketingPageLayoutJumbotronActions>
            <Button
              size="large"
              color="secondary"
              style={{ minWidth: 200 }}
              onClick={() => void onRequestFreeTrial()}
              className="margin-right-large"
              loading={isLoading}
            >
              {primaryButtonLabel}
            </Button>
            {showViewPlans && (
              <Button
                size="large"
                color="tertiary"
                style={{ minWidth: 200 }}
                onClick={onViewPlans}
              >
                {_`View plans`}
              </Button>
            )}
          </StyledFreeTrialMarketingPageLayoutJumbotronActions>
        </StyledFreeTrialMarketingPageLayoutJumbotronContent>
      </StyledFreeTrialMarketingPageLayoutJumbotron>
      {children}
    </StyledFreeTrialMarketingPageLayout>
  );
}
