import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";

import useCompose, { UseComposeProps } from "../hooks/useCompose";
import { Serie, theme } from "../icecube-ux";
import { BarLineChartsProps } from "../icecube-ux/BarLineCharts/BarLineCharts";
import { noTz } from "../icecube-ux/dateUtils";
import { _ } from "../languages/helper";
import { KIGoalSettings } from "../lib/kiSectionsService";
import { getMetricFormatter } from "../pages/custom/metric-components/utils";
import { useGoalSettings } from "../pages/key-indicators/hooks/useGoalSettings";
import { getComposeRules, getExtraParams } from "../pages/key-indicators/utils";
import MetricDescriptionText from "../shared/Metrics/MetricDescriptionText";
import { IMetric } from "../types/synthesizer";
import { ComposeRules } from "../utils/composeUtils";
import {
  Granularity,
  getDatesByGranularity,
  getGranularityFromRange,
} from "../utils/dateUtils";
import { EMPTY_ARRAY } from "../utils/utils";

import { useBootstrap } from "./bootstrap";
import { useMetricList } from "./metricList";
import { useFormatters } from "./useFormatters";
import { useSmartFilter } from "./useSmartFilter";

type UseLoadKeyIndicatorProps = {
  granularity?: Granularity;
  metricName: string;
  goal?: KIGoalSettings;
  requestPriorities: UseComposeProps["requestPriorities"];
  options?: UseComposeProps["options"];
  extraRules?: ComposeRules;
};

export const SELECTED_RANGE = "Selected range";
export const PREVIOUS_RANGE = "Previous range";

export const useLoadKeyIndicatorMetric = ({
  granularity: granularityProp,
  metricName,
  goal,
  requestPriorities,
  options,
  extraRules,
}: UseLoadKeyIndicatorProps) => {
  const { metrics, flattenMetrics } = useMetricList();
  const [focusedMainValue, setFocusedMainValue] = useState<number | undefined>(
    undefined,
  );
  const [focusedDate, setFocusedDate] = useState<string | undefined>(undefined);
  const [focusedComparedValue, setFocusedComparedValue] = useState<
    number | undefined
  >(undefined);

  const resetHoverState = useCallback(() => {
    setFocusedMainValue(undefined);
    setFocusedComparedValue(undefined);
    setFocusedDate(undefined);
  }, [setFocusedMainValue, setFocusedComparedValue, setFocusedDate]);

  useEffect(() => {
    resetHoverState();
  }, [resetHoverState]);

  const smartFilter = useSmartFilter();
  const { tenant } = useBootstrap();
  const formatters = useFormatters();
  const metric: IMetric | undefined = flattenMetrics?.[metricName];
  const granularity =
    granularityProp ?? getGranularityFromRange(smartFilter.range);
  const rules: ComposeRules = useMemo(() => {
    const defaultRules = getComposeRules(tenant);
    return {
      ...defaultRules,
      ...extraRules,
    };
  }, [tenant, extraRules]);
  const extraParams = useMemo(() => getExtraParams(smartFilter), [smartFilter]);

  const actualQuery = useCompose({
    granularity,
    metrics: [metricName],
    ordering: "",
    compareSettings: {
      metrics: [metricName],
      displayValue: true,
      displayPercentage: true,
      period: smartFilter.comparePeriod,
      compareRange: smartFilter.compareRange,
      matchingDaysOfWeek: smartFilter.matchingDayOfWeek,
    },
    direction: "ASC",
    views: smartFilter.selectedViewIds,
    rules,
    range: smartFilter.range,
    requestPriorities,
    options: {
      ...options,
      skipAll: options?.skipAll || !metric?.canBeUsedInReports,
    },
    extraParams,
  });

  const rawCurrent = actualQuery.totalData?.[0]?.[metricName];
  const current = rawCurrent === undefined ? undefined : Number(rawCurrent);
  const rawPrevious = actualQuery.compareTotalData?.[0]?.[metricName];
  const previous = rawPrevious === undefined ? undefined : Number(rawPrevious);

  const formatter = useCallback(
    (v?: string | number): string => {
      return getMetricFormatter(
        metricName,
        metrics,
        formatters,
      )?.(v ?? 0)?.toString();
    },
    [metrics, formatters, metricName],
  );

  const { goalSettings } = useGoalSettings({
    goal,
    granularity,
    ratio: metric?.ratio,
    percentage: metric?.percentage,
    formatter,
    current,
  });

  const { xValues, dateRangeFromLastPeriod } = useMemo(() => {
    return {
      xValues: getDatesByGranularity(
        granularity,
        smartFilter.range,
        tenant.settings.weekstart ?? "sunday",
      ),
      dateRangeFromLastPeriod: getDatesByGranularity(
        granularity,
        smartFilter.compareRange,
        tenant.settings.weekstart ?? "sunday",
      ),
    };
  }, [granularity, smartFilter.range, smartFilter.compareRange, tenant]);

  const rangeValues = useMemo(() => {
    if (actualQuery.loadingData) {
      return { x: EMPTY_ARRAY, y: EMPTY_ARRAY };
    }

    const yValues = xValues.map((date) => {
      const point = actualQuery.tableData.find((d) =>
        noTz(moment(d.date)).isSame(date, "day"),
      );
      return Number(point?.[metricName] || 0);
    });

    return { x: xValues, y: yValues };
  }, [actualQuery.tableData, actualQuery.loadingData, metricName, xValues]);

  const comparedRangeValues = useMemo(() => {
    if (actualQuery.loadingCompare) {
      return { y: EMPTY_ARRAY };
    }

    const yValues = dateRangeFromLastPeriod.map((date) => {
      const point = actualQuery.compareTableData.find((d) =>
        noTz(moment(d.date)).isSame(date, "day"),
      );

      return Number(point?.[metricName] || 0);
    });

    return {
      y: yValues,
    };
  }, [
    actualQuery.compareTableData,
    actualQuery.loadingCompare,
    metricName,
    dateRangeFromLastPeriod,
  ]);

  const { leftSeries, entries } = useMemo(() => {
    const goalValue = goalSettings?.chartValue ?? undefined;

    const targetValues = Array.from(
      { length: rangeValues.x.length },
      () => goalValue ?? 0,
    );

    const goalProgressionColor = goalSettings?.warning
      ? theme.colors.warning100
      : theme.colors.success100;
    const previousProgressionColor = goalSettings?.warning
      ? "#F8AAC7"
      : "#B1E3D4"; // TODO add to consts

    const rangeData: Serie<number> | undefined =
      rangeValues.x.length > 0
        ? {
            displayType: "line",
            yAxisKey: "left",
            name: SELECTED_RANGE,
            color: goalProgressionColor,
            values: rangeValues.y,
            label: _`Selected range`,
            strokeWidth: 2,
            withCircles: false,
            valueFormatter: formatter,
            zOrder: 3,
          }
        : undefined;

    const comparedRangeData: Serie<number> | undefined =
      comparedRangeValues.y.length > 0
        ? {
            displayType: "line",
            yAxisKey: "left",
            name: PREVIOUS_RANGE,
            color: previousProgressionColor,
            legendStyle: {
              backgroundColor: theme.colors.white100,
              border: `1px dashed ${previousProgressionColor}`,
            },
            dashed: true,
            withCircles: false,
            values: comparedRangeValues.y,
            label: _`Previous range`,
            valueFormatter: formatter,
            zOrder: 2,
          }
        : undefined;

    const targetData: Serie<number> | undefined = goal
      ? {
          displayType: "line",
          yAxisKey: "left",
          name: "Target",
          label: _`Target`,
          color: theme.colors.primary10,
          legendStyle: {
            backgroundColor: theme.colors.primary30,
            border: `1px solid ${theme.colors.primary70}`,
          },
          fillColor: theme.colors.bgLight10,
          withCircles: false,
          values: targetValues,
          valueFormatter: formatter,
          zOrder: 0,
        }
      : undefined;

    return {
      entries: [rangeData, comparedRangeData, targetData],
      leftSeries: [
        ...(targetData ? [targetData] : EMPTY_ARRAY),
        ...(rangeData ? [rangeData] : EMPTY_ARRAY),
        ...(comparedRangeData ? [comparedRangeData] : EMPTY_ARRAY),
      ] as BarLineChartsProps["series"],
    };
  }, [
    formatter,
    goal,
    rangeValues.x,
    comparedRangeValues.y,
    rangeValues.y,
    goalSettings,
  ]);

  const onIndexChange = useCallback(
    (index: number | undefined) => {
      if (index !== undefined) {
        setFocusedMainValue?.(rangeValues.y[index]);
        setFocusedComparedValue?.(comparedRangeValues.y[index]);
        setFocusedDate?.(moment.utc(xValues[index]).format("LL"));
      } else {
        resetHoverState?.();
      }
    },
    [comparedRangeValues.y, rangeValues.y, resetHoverState, xValues],
  );

  const info = useMemo(() => {
    if (!metric?.description) return null;
    if (typeof metric.description === "string") return metric.description;

    return <MetricDescriptionText description={metric.description} />;
  }, [metric?.description]);

  return {
    actualQuery,
    formatter,
    granularity,
    xValues,
    dateRangeFromLastPeriod,
    info,
    current: focusedDate ? focusedMainValue : current,
    previous: focusedDate ? focusedComparedValue : previous,
    focusedDate,
    onIndexChange,
    entries,
    leftSeries,
    goalSettings,
    rangeValues,
    issueWithMetric:
      metrics.customMetrics?.[metricName] && !metric?.canBeUsedInReports
        ? _`Please check the metric configuration.`
        : undefined,
  };
};
