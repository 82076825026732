import * as React from "react";
import styled, { CSSProperties } from "styled-components";

import { Flex, Text, theme, Space, TextVariant } from "../../../icecube-ux";
import { useDeviceType } from "../../../icecube-ux/hooks/provideDeviceType";
import { _ } from "../../../languages/helper";
import "./trust-by.css";

type TrustedByProps = {
  variant?: "default" | "dark-background";
  logoRows?: TrustedByLogo[][];
  textVariant?: TextVariant;
  rowHeight?: number;
  style?: CSSProperties;
};

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTextWrapper = styled.div`
  max-width: 300px;
`;

const StyledLogoWrapper = styled.ul<{ height: number }>`
  display: flex;
  height: ${(p) => p.height}px;

  gap: 16px;
  align-items: center;
  justify-content: center;
  margin: 0 16px;
  @media screen and (max-width: 1165px) {
    gap: 12px;
  }
  @media screen and (max-width: 1114px) {
    gap: 8px;
  }
`;

const StyledLogo = styled.img`
  width: 100%;
  filter: opacity(50%);
`;

export const TrustedByLogos = {
  volcom: "volcom.svg",
  lemaire: "lemaire.svg",
  seavees: "seavees.svg",
  albion: "albion.svg",
  kiss: "kiss.svg",
  polene: "polene.svg",
  ripndip: "ripndip.svg",
} as const;
type TrustedByLogo = (typeof TrustedByLogos)[keyof typeof TrustedByLogos];

const defaultLogoRows = [
  [
    TrustedByLogos.albion,
    TrustedByLogos.kiss,
    TrustedByLogos.polene,
    TrustedByLogos.ripndip,
    TrustedByLogos.volcom,
    TrustedByLogos.lemaire,
    TrustedByLogos.seavees,
  ],
];

export default function TrustedBy({
  variant = "default",
  logoRows = defaultLogoRows,
  textVariant = "body12Medium",
  rowHeight = 34,
  style,
}: TrustedByProps = {}) {
  const { isMobile } = useDeviceType();

  if (isMobile) {
    return (
      <div className="trusted-by-mobile" style={style}>
        <div className="legend">Trusted by 2700+ Shopify brands</div>
        <div>
          <img
            src="/img/trusted_logo_black.png"
            alt=""
            style={{ width: "100%" }}
          />
        </div>
      </div>
    );
  }

  if (variant === "dark-background") {
    return (
      <StyledWrapper style={style}>
        <StyledTextWrapper>
          <Text
            color={theme.colors.white50}
            variant={textVariant}
            textAlign="center"
          >
            {_`2700+ leading Shopify brands use Polar Analytics to stop manually compiling their data`}
          </Text>
        </StyledTextWrapper>
        <Space />
        {logoRows.map((logos, index) => {
          return (
            <StyledLogoWrapper key={index} height={rowHeight}>
              {logos.map((logo) => {
                return (
                  <React.Fragment key={logo}>
                    <li>
                      <StyledLogo
                        src={`/img/trustedBy/dark-background/${logo}`}
                      />
                    </li>
                  </React.Fragment>
                );
              })}
            </StyledLogoWrapper>
          );
        })}
      </StyledWrapper>
    );
  }

  return (
    <Flex flexDirection="column" alignItems="center" rowGap={8} style={style}>
      <Text color={theme.colors.white100} variant="body16Regular">
        2700+{" "}
        {_`leading Shopify brands use Polar Analytics to stop manually compiling their data`}
      </Text>
      <div>
        <img src="/img/trusted_logos.png" alt="" style={{ height: "36px" }} />
      </div>
    </Flex>
  );
}
