import styled from "styled-components";

import { theme } from "../theme";

export const StyledNotificationBullet = styled.div`
  height: 7px;
  width: 7px;
  border-radius: 50%;

  background-color: ${theme.colors.warning100};
  border: 1px solid ${theme.colors.white100};
`;
