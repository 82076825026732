import classNames from "classnames";

import { ClassAndStyleProps, InteractionEvents } from "../shared";

import { StyledNotificationBullet } from "./NotificationBullet.styled";

type NotificationBulletProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps;

export default function NotificationBullet({
  className,
  style,
  ...interactionEvents
}: NotificationBulletProps) {
  return (
    <StyledNotificationBullet
      {...interactionEvents}
      className={classNames(className)}
      style={style}
    />
  );
}
