import styled from "styled-components";

import { Flex } from "../Flex";
import { legacyTheme } from "../legacyTheme";

export const StyledGroupedList = styled.div`
  background: ${legacyTheme.colors.white};
  border: 1px solid ${legacyTheme.colors.primary9};
  box-sizing: border-box;
  border-radius: ${legacyTheme.borderRadius.default};

  font-family: ${legacyTheme.typography.default.fontFamily};
  font-style: ${legacyTheme.typography.default.fontStyle};
`;

export const StyledGroupedListHeader = styled(Flex).attrs({
  gap: 6,
  alignItems: "center",
})`
  cursor: pointer;
  color: ${legacyTheme.colors.text.regular};
  font-weight: ${legacyTheme.typography.fontWeigth.w500};
  font-size: ${legacyTheme.typography.fontSizes.xlarge};
  padding: 0 ${legacyTheme.padding.large};
  height: 46px;
  align-items: middle;

  &.disabled .metric-icon,
  &.disabled .metric-label {
    filter: grayscale(100%);
    color: ${legacyTheme.colors.grey7};
  }
`;

export const StyledGroupedListBlock = styled.div`
  padding: ${legacyTheme.padding.large};
  padding-top: 0;
`;

export const StyledGroupedListBlockTitle = styled.div`
  font-weight: ${legacyTheme.typography.fontWeigth.w600};
  font-size: ${legacyTheme.typography.fontSizes.small};
  line-height: 15px;
  text-transform: uppercase;
  color: ${legacyTheme.colors.primary3};
  margin-bottom: ${legacyTheme.margin.regular};
`;
