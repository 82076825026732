import React from "react";

import { AddonType } from "../../../common/types/subscription-service";
import { Text, Flex, theme, Grid } from "../../../icecube-ux";
import { _ } from "../../../languages/helper";
import AddonPopup from "../../Popups/AddonPopup";

import { AddonDetailsCard } from "./AddonDetailsCard";

interface CapiPopupProps {
  onClickAddon: (selectedAddon: AddonType) => void;
  onClose: (e: React.MouseEvent) => void;
  disableAnimation?: boolean;
}

export const CapiPopup = ({
  onClickAddon,
  onClose,
  disableAnimation = false,
}: CapiPopupProps) => {
  return (
    <AddonPopup
      disableAnimation={disableAnimation}
      onClickAddon={onClickAddon}
      headerTitle={_`Conversion API Enhancer`}
      headerSubtitle={_`Send first-party data back to ad platforms to train algorithms more efficiently`}
      headerIconColor={theme.customColors.capiBg}
      bodyTitle={_`Supercharge your ROAS by 20% with Polar's Conversion API Enhancer`}
      addonName="Capi"
      onClose={onClose}
      iconName="Capi"
      loading={false}
      addonDetailsContent={
        <Grid gridTemplateColumns="repeat(2, 1fr)" gap={12}>
          <AddonDetailsCard
            title={_`What is the Conversion API Enhancer?`}
            paragraph={_`The Conversion API Enhancer uses first-party data with Polar Pixel to refine algorithms and boost ad efficiency.`}
          />
          <AddonDetailsCard
            title={_`Achieve Maximum Click ID Match Rate`}
            paragraph={_`Polar Pixel's server-side tracking captures missed conversion data and matches them with ad clicks using our in-house identity resolution. Our real-time data delivery ensures accurate tracking and better advertising decisions.`}
          />
          <AddonDetailsCard
            title={_`We do the hard work for you`}
            customContent={
              <Flex flexDirection="column">
                <ol>
                  <li>
                    <Text variant="body12Regular" color={theme.colors.text90}>
                      {_`1. Install Polar Pixel by following our guide.`}
                    </Text>
                  </li>
                  <li>
                    <Text variant="body12Regular" color={theme.colors.text90}>
                      {_`2. Schedule an implementation call with your Customer Success Manager.`}
                    </Text>
                  </li>
                  <li>
                    <Text variant="body12Regular" color={theme.colors.text90}>
                      {_`3. Approve access and provide an Access Token.`}
                    </Text>
                  </li>
                  <li>
                    <Text variant="body12Regular" color={theme.colors.text90}>
                      {_`4. Maintain ad spend for two weeks while we monitor results and provide updates.`}
                    </Text>
                  </li>
                </ol>
              </Flex>
            }
          />
          <AddonDetailsCard
            title={_`Achieve better ROAS or get your money back`}
            paragraph={_`Get the CAPI Enhancer risk-free with a 30-day money-back guarantee. It improves ad platform performance by allowing more events without affecting ad metrics. The Frankie Shop saw a 22% drop in Cost Per Purchase and a 30% increase in ROAS within a week.`}
          />
        </Grid>
      }
    />
  );
};
