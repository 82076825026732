import { createContext, useCallback, useContext, useState } from "react";

export interface ReportTableOrdering {
  columnKey: string;
  direction: "ASC" | "DESC";
}
interface ReportTableContextProps {
  registerChangeOrderingCallback: (callback: ChangeOrderingCallback) => void;
  registerDeleteColumn: (callback: DeleteColumnCallback) => void;
  registerOrdering: (ordering: ReportTableOrdering) => void;
  changeOrdering: (columnKey: string, direction?: "ASC" | "DESC") => void;
  deleteColumn: (left: boolean, columnKey: string) => void;
  onRowHoverStart: (rowIndex: number) => void;
  onRowHoverEnd: () => void;
  hoveredRowIndex: number | null | undefined;
  ordering: ReportTableOrdering;
  allowHover: boolean;
}

interface ProvideReportTableProps {
  children: React.ReactNode;
  allowHover?: boolean;
}

const ReportTableContext = createContext<ReportTableContextProps | null>(null);

export function ProvideReportTable({
  children,
  allowHover = false,
}: ProvideReportTableProps) {
  const provider = useProvideReportTable(allowHover);
  return (
    <ReportTableContext.Provider value={provider}>
      {children}
    </ReportTableContext.Provider>
  );
}

export const useReportTable = () => {
  const context = useContext(ReportTableContext);
  if (context === null) {
    throw Error("Report table context not provided");
  }
  return context;
};

type ChangeOrderingCallback = (
  columnKey: string,
  direction: "ASC" | "DESC" | undefined,
) => void;
type DeleteColumnCallback = (left: boolean, key: string) => void;
function useProvideReportTable(allowHover = false) {
  const [changeOrderingCallback, setChangeOrderingCallback] =
    useState<ChangeOrderingCallback | null>(null);
  const [deleteColumnCallback, setDeleteColumnCallback] =
    useState<DeleteColumnCallback | null>(null);
  const [ordering, setOrdering] = useState<ReportTableOrdering>({
    columnKey: "",
    direction: "ASC",
  });
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>();
  const registerChangeOrderingCallback = (cb: ChangeOrderingCallback) =>
    setChangeOrderingCallback(() => cb);

  const registerDeleteColumn = (cb: DeleteColumnCallback) =>
    setDeleteColumnCallback(() => cb);

  const registerOrdering = (ordering: ReportTableOrdering) =>
    setOrdering(ordering);

  const changeOrdering = (
    columnKey: string,
    direction: "ASC" | "DESC" | undefined,
  ) => changeOrderingCallback && changeOrderingCallback(columnKey, direction);

  const deleteColumn = (left: boolean, columnKey: string) =>
    deleteColumnCallback && deleteColumnCallback(left, columnKey);

  const onRowHoverStart = useCallback((lineIndex: number) => {
    setHoveredRowIndex(lineIndex);
  }, []);

  const onRowHoverEnd = useCallback(() => {
    setHoveredRowIndex(null);
  }, []);

  return {
    registerChangeOrderingCallback,
    registerDeleteColumn,
    registerOrdering,
    changeOrdering,
    deleteColumn,
    onRowHoverStart,
    onRowHoverEnd,
    ordering,
    hoveredRowIndex,
    allowHover,
  };
}
