import CustomBreakdownsListPopup from "../../components/CustomBreakdowns/CustomBreakdownsListPopup";
import CustomBreakdownsPopup from "../../components/CustomBreakdowns/CustomBreakdownsPopup";
import { useBootstrap } from "../../hooks/bootstrap";
import { useCustomElementEditors } from "../../hooks/customElementEditors";

import CreateCustomMetrics from "./CreateCustomMetrics";

export const CustomElementEditors = () => {
  const provider = useCustomElementEditors();
  const openedMetricEditor = provider.openedMetricEditor;
  const { isDemoData } = useBootstrap();
  if (isDemoData) {
    return null;
  }
  return (
    <>
      {openedMetricEditor !== null && <CreateCustomMetrics />}
      {provider.openedDimensionEditor !== null && (
        <CustomBreakdownsPopup
          id={
            provider.openedDimensionEditor !== ""
              ? provider.openedDimensionEditor
              : undefined
          }
          onClose={provider.hideDimension}
        />
      )}
      {provider.openedDimensionList && (
        <CustomBreakdownsListPopup onClose={provider.hideDimensionList} />
      )}
    </>
  );
};
