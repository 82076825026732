import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";

import { Granularity } from "../utils/dateUtils";

import { useDashboards } from "./dashboards";
import useLocalStorage from "./useLocalStorage";

interface KISectionsContextProps {
  chartGranularity?: Granularity;
  chartDisplayState: Record<string, boolean>;
  setIsChartDisplay: (v: Record<string, boolean>) => void;
}

const KISectionsContext = createContext<KISectionsContextProps | null>(null);

export function ProvideKISections({ children }: { children: ReactNode }) {
  const provider = useProvideKISections();
  return (
    <KISectionsContext.Provider value={provider}>
      {children}
    </KISectionsContext.Provider>
  );
}

export const useKISections = () => {
  const context = useContext(KISectionsContext);
  if (context === null) {
    throw Error("KISections context not provided");
  }
  return context;
};

const useProvideKISections = () => {
  const { elementStore } = useDashboards();
  const sections = useMemo(
    () => elementStore?.kiSection ?? [],
    [elementStore?.kiSection],
  );
  const chartGranularity = useRef<Granularity | undefined>(undefined);

  const [chartDisplayState, setIsChartDisplay] = useLocalStorage<
    Record<string, boolean>
  >(`ki-section-chart-display`, {});

  useEffect(() => {
    const key = sections?.[0]?.id;

    if (key === undefined || key in chartDisplayState) {
      return;
    }

    setIsChartDisplay({
      ...chartDisplayState,
      [key]: true,
    });
  }, [chartDisplayState, sections, setIsChartDisplay]);

  return {
    chartDisplayState,
    chartGranularity: chartGranularity.current,
    setIsChartDisplay,
  };
};
