import classNames from "classnames";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";

import { DateRange } from "../dateUtils";

const DAY_LETTERS = ["S", "M", "T", "W", "T", "F", "S"];

interface CalendarHookProps {
  weekStartsOn?: number;
  currentMonth?: number;
  currentYear?: number;
  selectionRange?: DateRange;
  onSelectRange?: (range: DateRange) => void;
  maxDate?: moment.Moment;
  minDate?: moment.Moment;
  selectSingleDate?: boolean;
}

export function useCalendar({
  weekStartsOn = 0,
  currentMonth,
  currentYear,
  selectionRange,
  onSelectRange,
  maxDate,
  minDate,
  selectSingleDate,
}: CalendarHookProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  const [displayedMonth, setDisplayedMonth] = useState(0);
  const [displayedYear, setDisplayedYear] = useState(0);
  const [selection, setSelection] = useState<{
    selecting: boolean;
    range: DateRange;
    initialDate: moment.Moment;
  }>({
    selecting: false,
    range: { start: moment(), end: moment() },
    initialDate: moment(),
  });

  const handleShowPreviousMonth = () => {
    setDisplayedMonth((month) => {
      const newMonth = month - 1;
      if (newMonth < 0) {
        setDisplayedYear(displayedYear - 1);
      }
      return (newMonth + 12) % 12;
    });
  };

  const handleShowNextMonth = () => {
    setDisplayedMonth((month) => {
      const newMonth = month + 1;
      if (newMonth > 11) {
        setDisplayedYear(displayedYear + 1);
      }
      return newMonth % 12;
    });
  };

  const canShowNextMonth = useMemo(() => {
    const firstDayOfMonth = moment()
      .year(displayedYear)
      .month(displayedMonth + 1)
      .startOf("month");
    return maxDate ? firstDayOfMonth.isBefore(maxDate) : true;
  }, [displayedMonth, displayedYear, maxDate]);

  const handleDayClick = (date?: moment.Moment) => {
    if (!date) {
      return;
    }

    if (maxDate && maxDate.isBefore(date)) {
      return;
    }

    if (minDate && minDate.isAfter(date)) {
      return;
    }

    if (selectSingleDate) {
      setSelection({
        selecting: false,
        range: { start: date.clone(), end: date.clone() },
        initialDate: date.clone(),
      });
      if (onSelectRange) {
        onSelectRange({
          start: date.clone(),
          end: date.clone(),
        });
      }
      return;
    }

    if (selection.selecting) {
      const isBefore = date.isBefore(selection.initialDate);
      setSelection({
        ...selection,
        selecting: false,
      });
      if (onSelectRange) {
        if (isBefore) {
          onSelectRange({
            start: date,
            end: selection.initialDate,
          });
        } else {
          onSelectRange({
            start: selection.initialDate,
            end: date,
          });
        }
      }
    } else {
      setSelection({
        selecting: true,
        range: { start: date.clone(), end: date.clone() },
        initialDate: date.clone(),
      });
    }
  };

  const handleDayMouseMove = (date?: moment.Moment) => {
    if (!date) {
      return;
    }

    if (maxDate && maxDate.isBefore(date)) {
      return;
    }

    if (minDate && minDate.isAfter(date)) {
      return;
    }
    if (selection.selecting) {
      const isBefore = date.isBefore(selection.initialDate);
      if (isBefore) {
        setSelection({
          selecting: true,
          range: {
            start: date,
            end: selection.initialDate,
          },
          initialDate: selection.initialDate,
        });
      } else {
        setSelection({
          selecting: true,
          range: {
            start: selection.initialDate,
            end: date,
          },
          initialDate: selection.initialDate,
        });
      }
    }
  };

  const days = useMemo(() => {
    const now = moment();
    const firstDayOfMonth = moment()
      .year(displayedYear)
      .month(displayedMonth)
      .startOf("month");
    const firstDayOfMonthIndex =
      firstDayOfMonth.day() - weekStartsOn < 0
        ? 7 - (firstDayOfMonth.day() + weekStartsOn)
        : firstDayOfMonth.day() - weekStartsOn;
    const blocks: Array<{ date?: moment.Moment; className: string }> = [];
    let i = 0;
    while (i < firstDayOfMonthIndex) {
      i++;
      blocks.push({ className: "empty" });
    }

    const date = firstDayOfMonth.clone();
    const currentSelectionRange = selection.selecting
      ? selection.range
      : {
          start: moment(selectionRange?.start),
          end: moment(selectionRange?.end),
        };

    while (date.month() === displayedMonth) {
      const clonedDate = date.clone();
      blocks.push({
        date: clonedDate,
        className: classNames({
          "last-of-line": (i + 1) % 7 === 0,
          disabled:
            (maxDate && moment(maxDate).isBefore(clonedDate)) ||
            (minDate && moment(minDate).isAfter(clonedDate)),
          range:
            currentSelectionRange &&
            (currentSelectionRange.start.isSame(clonedDate, "day") ||
              currentSelectionRange.end.isSame(clonedDate, "day")),
          end:
            currentSelectionRange &&
            currentSelectionRange.end.isSame(clonedDate, "day"),
          today: now.isSame(clonedDate, "day"),
          selected:
            currentSelectionRange &&
            clonedDate.isBetween(
              currentSelectionRange.start,
              currentSelectionRange.end,
            ),
        }),
      });
      date.add(1, "day");
      i++;
    }

    blocks[blocks.length - 1].className += " " + classNames("last-of-line");

    return blocks;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedYear, displayedMonth, selection, selectionRange, weekStartsOn]);

  useEffect(() => {
    const today = moment();
    setDisplayedMonth(currentMonth || today.month());
    setDisplayedYear(currentYear || today.year());
  }, [currentMonth, currentYear]);

  useEffect(() => {
    setDisplayedMonth(moment(selectionRange?.start).month() || 0);
    setDisplayedYear(moment(selectionRange?.start).year() || 0);
  }, [selectionRange]);

  const dayLetters = DAY_LETTERS.map(
    (_, i) => DAY_LETTERS[(i + weekStartsOn) % 7],
  );

  const extraWidth = useMemo(() => {
    if (containerRef.current) {
      return `${containerRef.current.getBoundingClientRect().width / 7}px`;
    }
    return "0px";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current]);

  return {
    containerRef,
    extraWidth,
    days,
    weeks: Array(Math.ceil(days.length / 7)).fill(1),
    rest: 7 - (days.length % 7),
    dayLetters,
    canShowNextMonth,
    handleDayClick,
    handleDayMouseMove,
    handleShowNextMonth,
    handleShowPreviousMonth,
    displayedYear,
    displayedMonth,
  };
}
