import classnames from "classnames";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

import { useAuth } from "../../hooks/auth/auth";
import { useBootstrap } from "../../hooks/bootstrap";
import { useAddonPopup } from "../../hooks/useAddonPopup";
import { useTenantAddOns } from "../../hooks/useAddonStatuses";
import { useShowNewPricingComponents } from "../../hooks/useAddonUtils";
import {
  Block,
  Button,
  Calendar,
  Chip,
  Grid,
  Icon,
  Input,
  Scroller,
  Select,
  Text,
  legacyTheme,
  theme,
} from "../../icecube-ux";
import { _ } from "../../languages/helper";
import { getMinDate } from "../../lib/synthService";
import { AddonTag } from "../../shared/Plans/analyzeEnrichPlan/AddonTag";
import PlansPopup from "../../shared/Popups/PlansPopup";
import {
  DateRange,
  DATE_GRANULARITY_OPTIONS,
  demoDateBoundaries,
  FILTER_TYPES,
  getCompareDateRange,
  getDateRangeFromPeriod,
  getDateRangeFromRelativeRange,
  Granularity,
  PREFILTERS,
  PREFILTERS_VALUES,
  SmartDateComparePeriod,
  PREFILTERS_COMPARE_VALUES,
  isPrefilterInRange,
  shouldWarnTooManyDatapoints,
} from "../../utils/dateUtils";
import { SmartFilterSelector } from "../../utils/filterUtils";
import { pluralize } from "../../utils/languageUtils";
import { isFreeOrTrialPlan } from "../../utils/subscriptionsUtils";
import TabButtons from "../TabButtons/TabButtons";

import ComparisonTypeSelector from "./ComparisonTypeSelector";

interface SmartDateFilterPickerProps {
  onApply?: (
    range: DateRange,
    compareRange: DateRange,
    selector: SmartFilterSelector,
    comparePeriod: SmartDateComparePeriod | "range",
    matchingDayOfWeek: boolean,
  ) => void;
  onClose: () => void;
  allowCompare?: boolean;
  asPopup?: boolean;
  granularity: Granularity | "none";
  range: DateRange;
  compareRange: DateRange;
  comparePeriod: SmartDateComparePeriod | "range";
  matchingDayOfWeek: boolean;
  selector: SmartFilterSelector;
  setRange: (value: DateRange) => void;
  setCompareRange: (value: DateRange) => void;
  setComparePeriod: (value: SmartDateComparePeriod | "range") => void;
  setSelector: (value: SmartFilterSelector) => void;
  setMatchingDayOfWeek: (value: boolean) => void;
  mobileVersion?: boolean;
  noMaxHeight?: boolean;
}

export default function SmartDateFilterPicker({
  onApply,
  onClose,
  allowCompare,
  asPopup = true,
  range,
  granularity,
  matchingDayOfWeek,
  compareRange,
  comparePeriod,
  selector,
  setRange,
  setCompareRange,
  setSelector,
  setComparePeriod,
  setMatchingDayOfWeek,
  mobileVersion,
  noMaxHeight,
}: SmartDateFilterPickerProps) {
  const auth = useAuth();
  const bootstrap = useBootstrap();
  const { setAddon } = useAddonPopup();
  const { isUserEligibleToSeeNewPricing } = useShowNewPricingComponents();
  const { isHourlyRefreshEnabled } = useTenantAddOns();

  const maySeeTodayData = bootstrap.hasPermission("data.seeTodayData");

  const { getUserTenantSetting, isDemoData } = useBootstrap();

  const [isShowingPlans, setIsShowingPlans] = useState(false);
  const [newMatchingDayOfWeek, setNewMatchingDayOfWeek] = useState(false);
  const [newRange, setNewRange] = useState<DateRange>({ ...range });
  const [newCompareRange, setNewCompareRange] = useState<DateRange>({
    ...compareRange,
  });
  const [newComparePeriod, setNewComparePeriod] = useState<
    SmartDateComparePeriod | "range"
  >(comparePeriod);
  const [newSelector, setNewSelector] = useState<SmartFilterSelector>({
    ...selector,
  });

  const isFreeOrTrial = isFreeOrTrialPlan(bootstrap.subscription);

  const minDate = useMemo(() => {
    if (isDemoData) {
      return demoDateBoundaries.start;
    } else if (isFreeOrTrial) {
      return moment(bootstrap.subscription?.created_at).subtract(1, "year");
    } else {
      return undefined;
    }
  }, [bootstrap.subscription?.created_at, isFreeOrTrial, isDemoData]);

  const maxDate = useMemo(() => {
    if (isDemoData) {
      return demoDateBoundaries.end;
    } else if (maySeeTodayData) {
      return moment().endOf("day");
    } else {
      return moment().subtract(1, "day").endOf("day");
    }
  }, [isDemoData, maySeeTodayData]);
  const areDatesOutsideOfBounds = useMemo(() => {
    return (
      (minDate &&
        (newRange.start.isBefore(minDate) ||
          (allowCompare && newCompareRange.start.isBefore(minDate)))) ||
      (maxDate && newRange.end.isAfter(maxDate))
    );
  }, [allowCompare, minDate, newRange, newCompareRange, maxDate]);

  const handleTabChange = async (v: string | number) => {
    setNewSelector((s) => ({
      ...s,
      tab: v as "predefined" | "relative" | "range",
    }));
    if (v === FILTER_TYPES.PREDEFINED) {
      await handleClickOnPrefilter(newSelector.predefinedSelection);
    } else if (v === FILTER_TYPES.RELATIVE) {
      handleRelativeFilterChange(
        newSelector.relativeSelection.count,
        newSelector.relativeSelection.granularity,
      );
    }
  };

  const handleClickOnPrefilter = async (filterValue: string) => {
    const minDate = await getMinDate(await auth.getToken());
    const defaultRangeForTab = getDateRangeFromPeriod(
      filterValue,
      getUserTenantSetting("weekstart", "sunday") === "sunday" ? 0 : 1,
      maySeeTodayData,
      minDate,
    );
    if (
      !isDemoData ||
      isPrefilterInRange({
        dateBoundaries: demoDateBoundaries,
        prefilter: filterValue,
      })
    ) {
      if (newComparePeriod === PREFILTERS_COMPARE_VALUES.PREVIOUS_PERIOD) {
        setNewMatchingDayOfWeek(false);
      }
      setNewRange(defaultRangeForTab);
      setNewCompareRange(
        getCompareDateRange(
          defaultRangeForTab,
          comparePeriod,
          undefined,
          matchingDayOfWeek,
          granularity,
        ),
      );
    }
    setNewSelector((s) => ({
      ...s,
      tab: "predefined",
      predefinedSelection: filterValue,
      rangeSelection: isDemoData ? s.rangeSelection : defaultRangeForTab,
    }));
  };

  const handleRelativeFilterChange = (
    amount: number,
    granularity: Granularity,
  ) => {
    const range = getDateRangeFromRelativeRange(
      {
        amount,
        type: granularity,
      },
      false,
    );
    if (!isDemoData) {
      setNewRange(range);
      setNewCompareRange(
        getCompareDateRange(
          range,
          newComparePeriod,
          undefined,
          newMatchingDayOfWeek,
          granularity,
        ),
      );
    }
    setNewSelector((s) => ({
      ...s,
      tab: "relative",
      relativeSelection: {
        count: amount,
        granularity,
      },
      rangeSelection: isDemoData ? s.rangeSelection : range,
    }));
  };

  const handleApply = () => {
    setRange({ ...newRange });
    setCompareRange({ ...newCompareRange });
    setSelector({ ...newSelector });
    setComparePeriod(newComparePeriod);
    setMatchingDayOfWeek(newMatchingDayOfWeek);
    onApply &&
      onApply(
        newRange,
        newCompareRange,
        newSelector,
        newComparePeriod,
        newMatchingDayOfWeek,
      );
    onClose();
  };

  useEffect(() => {
    setNewRange({ ...range });
    setNewCompareRange({ ...compareRange });
    setNewComparePeriod(comparePeriod);
    setNewSelector({ ...selector });
    setNewMatchingDayOfWeek(matchingDayOfWeek);
  }, [range, compareRange, comparePeriod, selector, matchingDayOfWeek]);

  const warnTooManyDatapoints = shouldWarnTooManyDatapoints(
    newRange,
    granularity,
  );

  return (
    <OutsideClickHandler onOutsideClick={() => onClose()}>
      {isShowingPlans ? (
        <PlansPopup onClose={() => setIsShowingPlans(false)} />
      ) : null}

      <div
        data-cy="calendar-popup"
        className={classnames("smart-date-popup", { asPopup })}
      >
        <TabButtons
          value={newSelector.tab}
          onChange={(v) => void handleTabChange(v)}
          withoutMargin
          tabs={[
            { label: _`Quick filters`, value: FILTER_TYPES.PREDEFINED },
            {
              label: _`Relative filters`,
              value: FILTER_TYPES.RELATIVE,
            },
            { label: _`Date range`, value: FILTER_TYPES.RANGE },
          ]}
          className="border-bottom padding-right-regular padding-bottom-regular padding-left-regular"
        />
        <Scroller maxHeight={noMaxHeight ? undefined : "calc(100vh - 300px)"}>
          <div className="padding-xlarge padding-bottom-none">
            {isDemoData && (
              <Block variant="warning" className="margin-bottom-large">
                <Text
                  variant="body12Regular"
                  color={theme.colors.warning100}
                  textAlign="center"
                >
                  {_`Only data from period available in preview mode`}
                </Text>
              </Block>
            )}
            {newSelector.tab === FILTER_TYPES.PREDEFINED && (
              <Grid
                gridTemplateColumns="1fr 1fr 1fr 1fr"
                className="margin-bottom-xlarge"
                gap={8}
              >
                {PREFILTERS.map((filter) => {
                  const showHourlyRefreshTooltip =
                    filter.value === PREFILTERS_VALUES.TODAY &&
                    !isHourlyRefreshEnabled &&
                    isUserEligibleToSeeNewPricing;
                  const ChipComponent = (
                    <Chip
                      key={`filter-${filter.value}`}
                      size="small"
                      selected={
                        newSelector.predefinedSelection === filter.value
                      }
                      onClick={() => void handleClickOnPrefilter(filter.value)}
                      disabled={
                        (filter.value === PREFILTERS_VALUES.TODAY &&
                          !maySeeTodayData) ||
                        (filter.isDisabledForDemo && isDemoData)
                      }
                      style={{
                        width: "100%",
                        gridColumn:
                          filter.value === PREFILTERS_VALUES.ALL
                            ? "1 / 5"
                            : undefined,
                      }}
                    >
                      {filter.label}
                      {showHourlyRefreshTooltip && (
                        <Icon
                          name="Sparkle"
                          size={12}
                          style={{ marginLeft: "3px", verticalAlign: "sub" }}
                        />
                      )}
                    </Chip>
                  );
                  if (showHourlyRefreshTooltip) {
                    return (
                      <AddonTag
                        fullWidth
                        addonName="IntradayRefresh"
                        customActionElement={ChipComponent}
                        onClickAddon={() => setAddon("hourly_refresh")}
                      />
                    );
                  }
                  return ChipComponent;
                })}
              </Grid>
            )}
            {newSelector.tab === FILTER_TYPES.RELATIVE && (
              <Grid
                gridTemplateColumns="auto 1fr 1fr"
                className="margin-bottom-xlarge"
                gap={8}
                alignItems="center"
              >
                <Text variant="BodyRegular">{_`Last|||plural`}</Text>
                <Input
                  min={1}
                  block={true}
                  type="number"
                  value={newSelector.relativeSelection.count}
                  onChange={(v) =>
                    handleRelativeFilterChange(
                      v as number,
                      newSelector.relativeSelection.granularity,
                    )
                  }
                  disabled={isDemoData}
                />
                <Select
                  block={true}
                  placeholder={
                    newSelector.relativeSelection.granularity +
                    (newSelector.relativeSelection.count === 1 ? "" : "s")
                  }
                  options={DATE_GRANULARITY_OPTIONS.map((o) => ({
                    ...o,
                    label: pluralize(
                      newSelector.relativeSelection.count,
                      o.value,
                    ),
                  }))}
                  onChange={(v) =>
                    handleRelativeFilterChange(
                      newSelector.relativeSelection.count,
                      v[0] as Granularity,
                    )
                  }
                  selected={[newSelector.relativeSelection.granularity]}
                  withRadio={false}
                  disabled={isDemoData}
                />
              </Grid>
            )}
            {(!mobileVersion || newSelector.tab === FILTER_TYPES.RANGE) && (
              <div
                className={
                  "calendar-container border-bottom" +
                  (newSelector.tab === FILTER_TYPES.RANGE ? "" : " border-top")
                }
              >
                <Calendar
                  withTodayTooltip={
                    !isHourlyRefreshEnabled && isUserEligibleToSeeNewPricing
                  }
                  minDate={minDate}
                  maxDate={maxDate}
                  width="100%"
                  selectionRange={newRange}
                  weekStartsOn={
                    getUserTenantSetting("weekstart", "sunday") === "sunday"
                      ? 0
                      : 1
                  }
                  onSelectRange={(range) => {
                    setNewRange(range);
                    setNewCompareRange(
                      getCompareDateRange(
                        range,
                        newComparePeriod,
                        undefined,
                        newMatchingDayOfWeek,
                        granularity,
                      ),
                    );
                    setNewSelector((s) => ({
                      ...s,
                      tab: "range",
                      rangeSelection: range,
                    }));
                  }}
                  renderTodayTooltip={
                    !isHourlyRefreshEnabled
                      ? (children) => (
                          <AddonTag
                            addonName="IntradayRefresh"
                            customActionElement={children}
                            onClickAddon={() => setAddon("hourly_refresh")}
                          />
                        )
                      : undefined
                  }
                />
              </div>
            )}
            {allowCompare && (
              <div className="margin-top-large">
                <ComparisonTypeSelector
                  matchingDaysOfWeek={newMatchingDayOfWeek}
                  onDayOfWeekMatchingChange={setNewMatchingDayOfWeek}
                  granularity={granularity}
                  range={newRange}
                  compareRange={newCompareRange}
                  onCompareRangeChange={setNewCompareRange}
                  period={newComparePeriod}
                  onPeriodTypeChange={setNewComparePeriod}
                />
              </div>
            )}
          </div>
        </Scroller>
        <div className="padding-xlarge padding-bottom-none padding-top-none">
          {areDatesOutsideOfBounds &&
            (isDemoData ? (
              <Block
                variant="warning"
                className="margin-bottom-large margin-top-large"
              >
                <Text
                  variant="body12Regular"
                  color={theme.colors.warning100}
                  textAlign="center"
                >
                  {_`Please select both actual and compare range inside the available demo data`}
                </Text>
              </Block>
            ) : isFreeOrTrial ? (
              <div className="subscription-needed">
                <Grid gridTemplateColumns="auto 1fr" gap={8}>
                  <div className="rocket-icon">
                    <img src="/img/emojis/rocket.png" alt="" />
                  </div>
                  <div>
                    {_`Please upgrade to view more than one year of historical data, including the comparison timeframe`}
                  </div>
                </Grid>
                <Button
                  color="warning"
                  block={true}
                  className="margin-top-regular"
                  onClick={() => setIsShowingPlans(true)}
                >
                  {_`Upgrade now`}
                </Button>
              </div>
            ) : null)}
          {allowCompare && (
            <Text
              variant="Tiny"
              textAlign="center"
              className="margin-top-large"
            >
              {_`Compare the period`} {_`from|||time`}{" "}
              <Text variant="Tiny" inline color={legacyTheme.colors.primary1}>
                {newRange.start.format(
                  `dddd DD MMMM${
                    newRange.start.isSame(newRange.end, "year") ? "" : " YYYY"
                  }`,
                )}{" "}
                {_`to|||time`} {newRange.end.format("dddd DD MMMM YYYY")}
              </Text>{" "}
              {_`to the period|||comparison`} {_`from|||time`}{" "}
              <Text variant="Tiny" inline color={legacyTheme.colors.primary5}>
                {newCompareRange.start.format(
                  `dddd DD MMMM${
                    newCompareRange.start.isSame(newCompareRange.end, "year")
                      ? ""
                      : " YYYY"
                  }`,
                )}{" "}
                {_`to|||time`} {newCompareRange.end.format("dddd DD MMMM YYYY")}
              </Text>
            </Text>
          )}
          {warnTooManyDatapoints && (
            <div className="subscription-needed">
              <div>
                {_`As your current date range and granularity are quite large, you may experience performance issues in your reports. We suggest either reducing your date range or a viewing a higher granularity (i.e. By Year, By Quarter).`}
              </div>
            </div>
          )}
          <Button
            onClick={handleApply}
            className="margin-top-large"
            color="primary"
            label={_`Apply`}
            block={true}
            disabled={areDatesOutsideOfBounds}
          />
        </div>
      </div>
    </OutsideClickHandler>
  );
}
