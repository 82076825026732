import { useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";

import { useAuth } from "../../../hooks/auth/auth";
import {
  useCallBooking,
  useCallEventListener,
} from "../../../hooks/useCallBooking";
import { useSignupFromOutboundPopup } from "../../../hooks/useSignupFromOutboundPopup";
import { Button, Flex, Icon, Loader, Text, theme } from "../../../icecube-ux";
import { _ } from "../../../languages/helper";
import { TRACKING_EVENTS, trackEvent } from "../../../utils/trackingUtils";

import LogoExchange from "./LogoExchange";

const StyledRevenuHeroContainer = styled.div`
  position: relative;
  width: 620px;
  max-width: 90vw;
  height: 440px;
  max-height: 90vw;
`;

const StyledFooterMessageContainer = styled.div`
  margin: 0 -20px;
  width: calc(100% + 40px);
  border-top: 1px solid ${theme.colors.borderLight};
  padding: 12px 20px 0;
`;

const StyledRoundedFooter = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: ${theme.colors.bgLight20};
  padding: 12px;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;

const StyledGreenDot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  background: ${theme.colors.success20};
  border-radius: 50%;
  color: ${theme.colors.success100};
`;

interface Step2Props {
  onCancel: () => void;
  goToNextStep: () => void;
  email: string;
}

export default function Step2({ onCancel, goToNextStep, email }: Step2Props) {
  const { openSetupCallBooking, isLoading } = useCallBooking();
  const hasStarted = useRef(false);
  const { outboundDemoData } = useAuth();
  const { openPopup: openSignup } = useSignupFromOutboundPopup();

  const [formState, setFormState] = useState<"initial" | "booked">("initial");

  useCallEventListener(
    ({ data }) => {
      if (data.type === "MEETING_BOOKED") {
        setFormState("booked");

        trackEvent(TRACKING_EVENTS.SETUP_CALL_BOOKED, {
          ...outboundDemoData,
        });
      }

      if (data.type === "CLOSE_DIALOG") {
        if (formState === "booked") {
          goToNextStep();
          return;
        }
        onCancel();
      }
    },
    [formState, goToNextStep, onCancel],
  );

  useLayoutEffect(() => {
    if (hasStarted.current) {
      return;
    }

    hasStarted.current = true;
    void openSetupCallBooking({ embed: true, overriddenEmail: email });
  }, [openSetupCallBooking, email]);

  return (
    <>
      <Flex
        flexDirection="column"
        gap={24}
        alignItems="center"
        style={{ position: "relative" }}
      >
        {formState !== "booked" && (
          <Flex flexDirection="column" gap={16} alignItems="center">
            <LogoExchange />
            <Text variant="body18Medium">{_`Book a call to get started for free`}</Text>
          </Flex>
        )}
        {formState === "booked" && (
          <Flex flexDirection="column" gap={16} alignItems="center">
            <StyledGreenDot>
              <Icon name="Check" size={32} />
            </StyledGreenDot>
            <Text variant="body18Medium">{_`Thank you for booking the call`}</Text>
          </Flex>
        )}
        {isLoading && <Loader />}
        <StyledRevenuHeroContainer id="revenue-hero-embed" />
        {formState === "booked" && (
          <StyledFooterMessageContainer>
            <StyledRoundedFooter>
              <Text
                variant="body12Regular"
                color={theme.colors.text100}
                textAlign="center"
              >
                {_`Got time while you're waiting?`}
                <br />
                {_`Create your free trial account to connect your data.`}
              </Text>
              <Button onClick={openSignup} block>
                {_`Sign up for free`}
              </Button>
            </StyledRoundedFooter>
          </StyledFooterMessageContainer>
        )}
      </Flex>
    </>
  );
}
