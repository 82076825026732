import classNames from "classnames";
import React from "react";

import { ClassAndStyleProps, InteractionEvents } from "../shared";

import { SectionWrapperStyled } from "./SectionWrapper.styled";

type SectionWrapperProps = InteractionEvents<HTMLDivElement> &
  ClassAndStyleProps & {
    children: React.ReactNode;
    withPadding?: number;
  };

const SectionWrapper = ({
  children,
  className,
  style,
  withPadding,
  ...interactionEvents
}: SectionWrapperProps) => {
  return (
    <SectionWrapperStyled
      {...interactionEvents}
      className={classNames(className)}
      style={{
        padding:
          typeof withPadding === "number" ? `${withPadding}px` : undefined,
        ...style,
      }}
    >
      {children}
    </SectionWrapperStyled>
  );
};

export default SectionWrapper;
