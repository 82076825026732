import styled, { css } from "styled-components";

import { StyledIcon } from "../../Icon/Icon.styled";
import { legacyTheme } from "../../legacyTheme";
import { StyledMoreOptions } from "../../MoreOptions/MoreOptions.styled";
import { theme } from "../../theme";

export const StyledReportTableHeaderCellRightIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 16px;
  border-radius: ${theme.borderRadius.r2px};
`;

export const StyledReportTableHeaderCellIconWrapper = styled.div``;

export const StyledReportTableHeaderCellLabel = styled.div`
  font-weight: 400;
  padding: 0 4px;
  font-size: ${theme.typography.fontSize.s12px};
  color: ${theme.colors.text100};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  box-pack: end;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 14px;
  width: 100%;

  &.orderable {
    cursor: pointer;
  }
`;

export const StyledReportTableHeaderCell = styled.div<{
  background?: string;
  borderColor?: string;
  color?: string;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: ${(p) => p.background || theme.colors.bgLight30};
  border: 1px solid ${(p) => p.borderColor || theme.colors.borderLight};
  border-radius: ${theme.borderRadius.r2px};
  padding: 4px;
  gap: 4px;
  color: ${(p) => p.background || legacyTheme.colors.grey5};

  &.dragMoving {
    opacity: 0.5;
  }

  &.dragTarget::before {
    position: absolute;
    opacity: 0.5;
    left: 0px;
    content: " ";
    height: 34px;
    width: 4px;
    background: ${legacyTheme.colors.primary3};
  }

  & > ${StyledIcon}, & > ${StyledMoreOptions} > ${StyledIcon} {
    color: ${legacyTheme.colors.primary7};
    cursor: pointer;
  }

  & > ${StyledIcon}:hover, & > ${StyledMoreOptions}:hover > ${StyledIcon} {
    color: ${legacyTheme.colors.primary4};
  }

  &.secondary {
    color: #b0b0d6;
  }

  &.secondary ${StyledReportTableHeaderCellIconWrapper} {
    opacity: 0.5;
  }

  &.secondary > ${StyledIcon} {
    opacity: 0.5;
  }

  &.hidden {
    opacity: 0;
  }
`;

export const StyledReportTableHeaderActionsRow = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: min-content;
  justify-content: space-between;
  align-items: center;
`;

export const StyledReportTableHeaderActionsGroup = styled.div<{
  side: "left" | "right";
}>`
  display: flex;
  flex-direction: row;
  ${(p) =>
    p.side === "right" &&
    css`
      gap: 6px;
    `}
  align-items: center;
`;

export const StyleButtonDropdownChildren = styled.div<{ maxHeight?: string }>`
  position: relative;
`;

export const StyleDropdownChildren = styled.div<{ maxHeight?: string }>`
  position: fixed;
  max-width: 300px;
  z-index: ${legacyTheme.zIndexes.layer4};
  border-radius: ${theme.borderRadius.r4px};
  background: ${theme.colors.white100};
  box-sizing: border-box;
  box-shadow: ${legacyTheme.shadows.regular};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "300px")};
  overflow: auto;
`;
