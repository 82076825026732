import "./integrations";

import LogRocket from "logrocket";
import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import { setupWebVitalsTracking } from "./hooks/usePerformanceMeasurement";
import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container!);

setupWebVitalsTracking();
if (process.env.REACT_APP_ENV === "production") {
  LogRocket.init("4hmgha/polar-analytics");
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

//reportWebVitals(console.log);
