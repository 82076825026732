import moment from "moment";
import React, { createContext, useContext, useMemo, useState } from "react";

import { SUBSCRIPTION_TYPES } from "../common/types/subscription-service";
import {
  clampDateRange,
  DateRange,
  DATE_GRANULARITY,
  getCompareDateRange,
  getDateRangeFromRelativeRange,
  demoDateBoundaries,
  getDemoDateDefaults,
  getLastMonthDateRange,
  Granularity,
  SmartDateComparePeriod,
  FILTER_TYPES,
} from "../utils/dateUtils";
import { SmartFilterSelector } from "../utils/filterUtils";

import { useBootstrap } from "./bootstrap";
import useLocalStorage from "./useLocalStorage";

export type AttributionModel =
  | "full_paid_overlap"
  | "first_click"
  | "last_click"
  | "linear"
  | "linear_paid"
  | "u_shaped"
  | "full_impact"
  | "time_decay"
  | "full_paid_overlap_fb_views";

export interface SmartFilterContextProps {
  range: DateRange;
  compareRange: DateRange;
  comparePeriod: SmartDateComparePeriod | "range";
  granularity: Granularity | "none";
  granularityNoEntireRange: Granularity;
  matchingDayOfWeek: boolean;
  selector: SmartFilterSelector;
  stores: string[];
  timezone: string;
  selectedViewIds: string[];
  search: string;
  attributionModel: AttributionModel;
  benchmarkIndustry: string;
  setSearch: (value: string) => void;
  setRange: (value: DateRange) => void;
  setCompareRange: (value: DateRange) => void;
  setComparePeriod: (value: SmartDateComparePeriod | "range") => void;
  setGranularity: (value: Granularity | "none") => void;
  setSelector: (value: SmartFilterSelector) => void;
  setAttributionModel: (values: AttributionModel) => void;
  setBenchmarkIndustry: (values: string) => void;
  setStores: (values: string[]) => void;
  setTimezone: (value: string) => void;
  setMatchingDayOfWeek: (value: boolean) => void;
  setSelectedViewIds: (values: string[]) => void;
}

type FILTER_KEYS =
  | "default"
  | "subscriptions"
  | "retention"
  | "acquisition"
  | "products";

export const ALL_BENCHMARK_INDUSTRY = "All Industries";

const DEFAULT_VALUES: {
  default: SmartFilterContextProps;
} & { [Key in FILTER_KEYS]?: Partial<SmartFilterContextProps> } = {
  default: {
    matchingDayOfWeek: false,
    search: "",
    range: getDateRangeFromRelativeRange(
      {
        amount: 30,
        type: DATE_GRANULARITY.DAY,
      },
      false,
    ),
    compareRange: getCompareDateRange(
      getDateRangeFromRelativeRange(
        {
          amount: 30,
          type: DATE_GRANULARITY.DAY,
        },
        false,
      ),
      "previousPeriod",
    ),
    comparePeriod: "previousPeriod",
    granularity: DATE_GRANULARITY.DAY,
    granularityNoEntireRange: DATE_GRANULARITY.DAY,
    selector: {
      tab: FILTER_TYPES.RELATIVE as "relative",
      predefinedSelection: "yesterday",
      relativeSelection: {
        count: 30,
        granularity: DATE_GRANULARITY.DAY,
      },
      rangeSelection: getLastMonthDateRange(),
    },
    attributionModel: "first_click",
    benchmarkIndustry: ALL_BENCHMARK_INDUSTRY,
    stores: [],
    selectedViewIds: [],
    timezone: "Europe/Paris",
    setSearch: () => {},
    setRange: () => {},
    setCompareRange: () => {},
    setComparePeriod: () => {},
    setGranularity: () => {},
    setMatchingDayOfWeek: () => {},
    setSelector: () => {},
    setStores: () => {},
    setBenchmarkIndustry: () => {},
    setAttributionModel: () => {},
    setTimezone: () => {},
    setSelectedViewIds: () => {},
  },
  retention: {
    range: getDateRangeFromRelativeRange(
      {
        amount: 12,
        type: DATE_GRANULARITY.MONTH,
      },
      false,
    ),
    compareRange: getCompareDateRange(
      getDateRangeFromRelativeRange(
        {
          amount: 12,
          type: DATE_GRANULARITY.MONTH,
        },
        false,
      ),
      "previousPeriod",
    ),
    granularity: DATE_GRANULARITY.MONTH,
    granularityNoEntireRange: DATE_GRANULARITY.MONTH,
    selector: {
      tab: "relative",
      predefinedSelection: "yesterday",
      relativeSelection: {
        count: 12,
        granularity: DATE_GRANULARITY.MONTH,
      },
      rangeSelection: getLastMonthDateRange(),
    },
  },
  acquisition: undefined,
};

const SmartFilterContext = createContext<SmartFilterContextProps | null>(null);

export function ProvideSmartFilter({
  children,
  filterKey = "default",
}: {
  children: React.ReactNode;
  filterKey?: FILTER_KEYS;
}) {
  const smartFilter = useProvideSmartFilter(filterKey);
  return (
    <SmartFilterContext.Provider value={smartFilter}>
      {children}
    </SmartFilterContext.Provider>
  );
}

export function ProvideFilterOverrides({
  children,
  keyByElement = undefined,
  filterKey = "default",
}: {
  children: React.ReactNode;
  keyByElement?: Partial<Record<KeyProperties, FILTER_KEYS>>;
  filterKey?: FILTER_KEYS;
}) {
  const smartFilter = useProvideSmartFilter(filterKey, keyByElement);
  return (
    <SmartFilterContext.Provider value={smartFilter}>
      {children}
    </SmartFilterContext.Provider>
  );
}

export const useSmartFilter = () => {
  const context = useContext(SmartFilterContext);
  if (context === null) {
    throw Error("Smart filter context not provided");
  }
  return context;
};

type KeyProperties = keyof SmartFilterContextProps;

function useProvideSmartFilter(
  key: FILTER_KEYS,
  keyByElement?: Partial<Record<KeyProperties, FILTER_KEYS>>,
): SmartFilterContextProps {
  const {
    subscription,
    tenant: {
      settings: { timezone: tenantTimezone },
    },
  } = useBootstrap();
  const { isDemoData } = useBootstrap();
  const isFreePlan = subscription?.plan === SUBSCRIPTION_TYPES.FREE_PLAN;

  const defaultValuesForKey = {
    ...DEFAULT_VALUES.default,
    ...(DEFAULT_VALUES[key] ?? {}),
  };
  const keyForElement = (property: KeyProperties) =>
    keyByElement?.[property] ?? key;

  const [search, setSearch] = useState("");

  const [stores, setStores] = useLocalStorage<string[]>(
    `smart-date-filter-${keyForElement("stores")}-stores`,
    [...defaultValuesForKey.stores],
  );

  const [timezone, setTimezone] = useLocalStorage<string>(
    `smart-date-filter-${keyForElement("timezone")}-timezone`,
    tenantTimezone || defaultValuesForKey.timezone,
  );

  const [selectedViewIds, setSelectedViewIds] = useLocalStorage<string[]>(
    `smart-date-filter-${keyForElement("selectedViewIds")}-views`,
    [...defaultValuesForKey.selectedViewIds],
  );

  const [attributionModel, setAttributionModel] =
    useLocalStorage<AttributionModel>(
      `smart-filter-${keyForElement("attributionModel")}-attribution-model`,
      defaultValuesForKey.attributionModel,
    );

  const [benchmarkIndustry, setBenchmarkIndustry] = useLocalStorage<string>(
    `smart-filter-${keyForElement("benchmarkIndustry")}-benchmark-industry`,
    defaultValuesForKey.benchmarkIndustry,
  );

  const [range, setRange] = useLocalStorage<DateRange>(
    `smart-date-filter-${keyForElement("range")}-range`,
    isDemoData ? getDemoDateDefaults(key) : defaultValuesForKey.range,
    (data) => ({
      start: moment(data.start),
      end: moment(data.end),
    }),
  );

  const [compareRange, setCompareRange] = useLocalStorage<DateRange>(
    `smart-date-filter-${keyForElement("compareRange")}-compare-range`,
    isDemoData
      ? getDemoDateDefaults(key, true)
      : defaultValuesForKey.compareRange,
    (data) => ({
      start: moment(data.start),
      end: moment(data.end),
    }),
  );

  const [granularity, setGranularity] = useLocalStorage<Granularity | "none">(
    `smart-date-filter-${keyForElement("granularity")}-granularity`,
    defaultValuesForKey.granularity,
  );

  const [granularityNoEntireRange, setGranularityNoEntireRange] =
    useLocalStorage<Granularity>(
      `smart-date-filter-${keyForElement(
        "granularityNoEntireRange",
      )}-granularity-no-entire-range`,
      defaultValuesForKey.granularityNoEntireRange,
    );

  const [matchingDayOfWeek, setMatchingDayOfWeek] = useLocalStorage<boolean>(
    `smart-date-filter-${keyForElement(
      "matchingDayOfWeek",
    )}-matching-day-of-week`,
    defaultValuesForKey.matchingDayOfWeek,
  );

  const initialSelector = isDemoData
    ? { ...defaultValuesForKey.selector, tab: FILTER_TYPES.RANGE as "range" }
    : { ...defaultValuesForKey.selector };
  const [selector, setSelector] = useLocalStorage<SmartFilterSelector>(
    `smart-date-filter-${keyForElement("selector")}-selector`,
    initialSelector,
    (data) => ({
      ...data,
      rangeSelection: {
        start: moment(data.rangeSelection.start),
        end: moment(data.rangeSelection.end),
      },
    }),
  );

  const [comparePeriod, setComparePeriod] = useLocalStorage<
    SmartDateComparePeriod | "range"
  >(
    `smart-date-filter-${keyForElement("comparePeriod")}-compare-period`,
    defaultValuesForKey.comparePeriod,
  );

  const handleSetMatchingDayOfWeek = (value: boolean) =>
    setMatchingDayOfWeek(value);
  const handleSetRange = (value: DateRange) => setRange(value);
  const handleSetCompareRange = (value: DateRange) => setCompareRange(value);
  const handleSetComparePeriod = (value: SmartDateComparePeriod | "range") =>
    setComparePeriod(value);
  const handleSetSelector = (value: SmartFilterSelector) => setSelector(value);
  const handleSetGranularity = (value: Granularity | "none") => {
    if (value !== "none") {
      setGranularityNoEntireRange(value);
    }
    setGranularity(value);
  };

  const patchedRange = useMemo(
    () => (isDemoData ? clampDateRange(demoDateBoundaries, range) : range),
    [isDemoData, range],
  );

  const patchedCompareRange = useMemo(
    () =>
      isDemoData
        ? clampDateRange(demoDateBoundaries, compareRange)
        : compareRange,
    [isDemoData, compareRange],
  );

  const handleSetStores = (value: string[]) => setStores(value);
  const handleSetViews = (value: string[]) => setSelectedViewIds(value);
  const handleSetTimezone = (value: string) => setTimezone(value);

  return {
    range: patchedRange,
    compareRange: patchedCompareRange,
    comparePeriod,
    granularity,
    granularityNoEntireRange,
    selector,
    matchingDayOfWeek,
    search,
    attributionModel,
    benchmarkIndustry,
    stores,
    selectedViewIds: isFreePlan ? [] : selectedViewIds,
    timezone,
    setRange: handleSetRange,
    setCompareRange: handleSetCompareRange,
    setComparePeriod: handleSetComparePeriod,
    setGranularity: handleSetGranularity,
    setSelector: handleSetSelector,
    setMatchingDayOfWeek: handleSetMatchingDayOfWeek,
    setSearch: (v: string) => setSearch(v),
    setAttributionModel,
    setBenchmarkIndustry,
    setStores: handleSetStores,
    setSelectedViewIds: handleSetViews,
    setTimezone: handleSetTimezone,
  };
}
