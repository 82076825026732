import { SubscriptionPlanList } from "../../../icecube-ux";
import { _ } from "../../../languages/helper";

import { SubscriptionPlanListItemLi } from "./subscriptionPlanListItemLi";

const BasicPlatformAccessContent = () => {
  return (
    <>
      <SubscriptionPlanListItemLi
        text={_`All business intelligence features`}
      />
      <SubscriptionPlanListItemLi text={_`Ask-Polar (AI-assistant)`} />
      <SubscriptionPlanListItemLi text={_`Benchmarks`} />
      <SubscriptionPlanListItemLi text={_`Alerts, Insights & Goals`} />
    </>
  );
};

const BasicAccountSupportContent = ({
  isCustomPlan,
}: {
  isCustomPlan?: boolean;
}) => {
  return (
    <>
      <SubscriptionPlanListItemLi text={_`Unlimited users`} />
      <SubscriptionPlanListItemLi text={_`Unlimited historical data`} />
      <SubscriptionPlanListItemLi text={_`Unlimited connectors`} />
      {!isCustomPlan && (
        <SubscriptionPlanListItemLi text={_`Dedicated CSM onboarding`} />
      )}
      {isCustomPlan && (
        <SubscriptionPlanListItemLi
          text={_`Priority support with personal DM channel`}
        />
      )}
    </>
  );
};

export const BasicPlatformAccess = () => {
  return (
    <SubscriptionPlanList heading={_`Platform Access`}>
      <BasicPlatformAccessContent />
    </SubscriptionPlanList>
  );
};

export const BasicAccountSupport = ({
  isCustomPlan,
}: {
  isCustomPlan?: boolean;
}) => {
  return (
    <SubscriptionPlanList heading={_`Account & Support`}>
      <BasicAccountSupportContent isCustomPlan={isCustomPlan} />
    </SubscriptionPlanList>
  );
};

const PlusPlatformAccessContent = ({
  showLearnMore,
  showPlus,
  isCustomPlan,
}: {
  showLearnMore?: boolean;
  showPlus?: boolean;
  isCustomPlan?: boolean;
}) => {
  return (
    <>
      <SubscriptionPlanListItemLi
        text={_`Intraday refresh`}
        addOn={showLearnMore ? "hourly_refresh" : undefined}
        plus={showPlus}
      />
      <SubscriptionPlanListItemLi
        text={_`Polar first-party pixel`}
        addOn={showLearnMore ? "polar_pixel" : undefined}
        plus={showPlus}
      />
      <SubscriptionPlanListItemLi text={_`Sales forecasts`} plus={showPlus} />
      {isCustomPlan && (
        <>
          <SubscriptionPlanListItemLi
            text={_`Conversion API (CAPI) Enhancer`}
            plus={showPlus}
          />
          <SubscriptionPlanListItemLi
            text={_`Snowflake database access`}
            plus={showPlus}
          />
          <SubscriptionPlanListItemLi
            text={_`Klaviyo Flow Enricher`}
            plus={showPlus}
          />
        </>
      )}
    </>
  );
};

const PlusAccountSupportContent = ({
  showPlus,
  isCustomPlan,
}: {
  showPlus?: boolean;
  isCustomPlan?: boolean;
}) => {
  return (
    <>
      {isCustomPlan ? (
        <>
          <SubscriptionPlanListItemLi
            text={_`Training & done-for-you report building`}
            plus={showPlus}
          />
          <SubscriptionPlanListItemLi
            text={_`Dedicated Activate Implementation Engineer`}
            plus={showPlus}
          />
        </>
      ) : (
        <SubscriptionPlanListItemLi
          text={_`Dedicated Slack Channel`}
          plus={showPlus}
        />
      )}
      <SubscriptionPlanListItemLi
        text={_`Dedicated Pixel Solution Engineer`}
        plus={showPlus}
      />
    </>
  );
};

export const PlusPlatformAccess = ({
  isCustomPlan,
  showLearnMore,
  showDifference,
  showPlus,
}: {
  isCustomPlan?: boolean;
  showLearnMore?: boolean;
  showDifference?: boolean;
  showPlus?: boolean;
}) => {
  return (
    <SubscriptionPlanList
      heading={
        isCustomPlan && !showDifference
          ? _`Custom plan based on your needs`
          : _`Platform Access`
      }
      showDifferenceWith={showDifference ? "Analyze" : undefined}
    >
      {!isCustomPlan && !showDifference && <BasicPlatformAccessContent />}
      <PlusPlatformAccessContent
        showLearnMore={showLearnMore}
        showPlus={showPlus}
        isCustomPlan={isCustomPlan}
      />
    </SubscriptionPlanList>
  );
};

export const PlusAccountSupport = ({
  isCustomPlan,
  showDifference,
  showPlus,
}: {
  isCustomPlan?: boolean;
  showDifference?: boolean;
  showPlus?: boolean;
}) => {
  return (
    <SubscriptionPlanList
      heading={
        isCustomPlan && !showDifference
          ? _`Custom plan based on your needs`
          : _`Account & Support`
      }
      showDifferenceWith={showDifference ? "Analyze" : undefined}
    >
      {!isCustomPlan && !showDifference && <BasicAccountSupportContent />}
      <PlusAccountSupportContent
        showPlus={showPlus}
        isCustomPlan={isCustomPlan}
      />
    </SubscriptionPlanList>
  );
};

export const ActivatePlatformAccess = ({
  isCustomPlan,
  showLearnMore,
  showDifference,
  showPlus,
}: {
  isCustomPlan?: boolean;
  showLearnMore?: boolean;
  showDifference?: boolean;
  showPlus?: boolean;
}) => {
  return (
    <SubscriptionPlanList
      heading={
        isCustomPlan && !showDifference
          ? _`Custom plan based on your needs`
          : _`Platform Access`
      }
      showDifferenceWith={showDifference ? "Analyze & Enrich" : undefined}
    >
      {!isCustomPlan && !showDifference && <PlusPlatformAccessContent />}
      <SubscriptionPlanListItemLi
        text={_`Conversion API (CAPI) Enhancer`}
        addOn={showLearnMore ? "capi" : undefined}
        plus={showPlus}
      />
      <SubscriptionPlanListItemLi
        text={_`Klaviyo Flow Enricher`}
        plus={showPlus}
      />
    </SubscriptionPlanList>
  );
};

export const ActivateAccountSupport = ({
  isCustomPlan,
  showDifference,
  showPlus,
}: {
  isCustomPlan?: boolean;
  showDifference?: boolean;
  showPlus?: boolean;
}) => {
  return (
    <SubscriptionPlanList
      heading={
        isCustomPlan && !showDifference
          ? _`Custom plan based on your needs`
          : _`Account & Support`
      }
      showDifferenceWith={showDifference ? "Analyze & Enrich" : undefined}
    >
      {!isCustomPlan && !showDifference && <PlusAccountSupportContent />}
      <SubscriptionPlanListItemLi text={_`Priority support`} plus={showPlus} />
      <SubscriptionPlanListItemLi
        text={_`Dedicated Activate Implementation Engineer`}
        plus={showPlus}
      />
    </SubscriptionPlanList>
  );
};
