/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import styled from "styled-components";

import { StyledCheckbox } from "../Checkbox/Checkbox.styled";
import { Label } from "../Label";
import { theme } from "../theme";
import { Text } from "../Typography";

export const StyledList = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyleSubcategory = styled(Text).attrs({
  variant: "body12Regular",
  color: theme.colors.text80,
  fontWeight: 400,
})``;

export const StyledLabel = styled(Label)<{
  hoverStyle?: React.CSSProperties;
  disabled?: boolean;
}>`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  color: ${theme.colors.text90};
  font-weight: 400;

  border-radius: ${theme.borderRadius.r4px};

  &.preselected {
    background: ${theme.colors.primary10};
    color: ${theme.colors.primary100};
    border-radius: 3px;
  }

  &.focused {
    ${({ hoverStyle }) => hoverStyle as any}
  }

  &.focused ${StyledCheckbox} {
    border: 1px solid ${theme.colors.borderDark};
  }

  &.focused ${StyledCheckbox}.checked {
    border: none;
  }
`;
