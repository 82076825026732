import styled from "styled-components";

import { theme } from "../theme";

export const StyledVerticalActionButton = styled.div<{ danger?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 22px;
  cursor: pointer;

  height: 100%;
  color: ${theme.colors.grey60};
  background: ${theme.colors.bgLight10};
  border-radius: ${theme.borderRadius.r4px};

  transition:
    color 0.2s ease-in-out,
    brackground-color 0.2s ease-in-out;

  &:hover {
    color: ${({ danger }) =>
      danger ? theme.colors.error100 : theme.colors.text80};
    background: ${theme.colors.bgLight20};
  }
`;
