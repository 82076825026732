import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  key: "attentive",
  category: INTEGRATION_CATEGORIES.marketing,
  image: "Attentive",
  label: "Attentive",
  isNewIntegrationService: true,
  isAlloyIntegration: true,
  openInNewWindow: true,

  getAccountTitle: (datasource) =>
    (datasource?.accountData?.data as { fullName?: string })?.fullName,

  dependsOnAccounts: true,
  backgroundColor: theme.customColors.attentiveBg,
  borderColor: theme.customColors.attentiveBorder,
  get description() {
    return _`SMS for Ecommerce`;
  },
});
