import React from "react";

import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { theme } from "../theme";
import { Text } from "../Typography";

export type SubscriptionPlanFooterProps = InteractionEvents<HTMLDivElement> &
  ClassAndStyleProps & { children: React.ReactNode };

export default function SubscriptionPlanFooter({
  className,
  style,
  children,
  ...interactionEvents
}: SubscriptionPlanFooterProps) {
  return (
    <div {...interactionEvents}>
      <Text
        variant="body11Regular"
        textAlign="center"
        color={theme.colors.text80}
        style={{ marginTop: "12px" }}
      >
        {children}
      </Text>
    </div>
  );
}
