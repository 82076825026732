import { AskPolarReport } from "../shared/AskPolar/Provider/AskPolarReport";
import { ComposeTopFilter } from "../types/synthesizer";
import { ComposeRules } from "../utils/composeUtils";
import { Granularity } from "../utils/dateUtils";

import { createClient } from "./apiClient";
import { getServiceHost } from "./services";

const client = createClient("ai-service");

interface UIUserChatItem {
  timestamp: number;
  id: string;
  who: "user";
  content: string;
  promptType?: PromptType;
}

export interface UIAskPolarChatItem {
  timestamp: number;
  id: string;
  like?: "none" | "true" | "false";
  who: "askPolar";
  content: string;
  report: AskPolarReport;
  matchedObjects: {
    matchedMetrics: { selectedMetric: string; metricsSuggestions: string[] }[];
  };
}

export type ChatObject = {
  id: string;
  updatedAt: string;
  chat: UIChat;
  lastAnswerError: boolean | undefined;
  lastAnswerLoading: boolean | undefined;
};

export type UIChatItem = UIUserChatItem | UIAskPolarChatItem;

export type UIChat = UIChatItem[];

export interface DraftUIAskPolarChatItem {
  content: string;
  report: AskPolarReport;
}

export const createChat = async ({
  abortController,
  token,
  message,
  responseFromTemplate,
  promptType,
}: {
  abortController: AbortController | undefined;
  token: string;
  message?: string;
  responseFromTemplate?: DraftUIAskPolarChatItem;
  promptType: PromptType;
}) => {
  const result = await client
    .new(abortController?.signal)
    .post("/api/chat")
    .auth(token)
    .body({ message, responseFromTemplate, promptType })
    .fetch<{ data: { chatId: string } }>();
  if (result.error === true) {
    throw new Error(result.message);
  }
  return result.data;
};

export const deleteAChat = async ({
  abortController,
  token,
  chatId,
}: {
  abortController?: AbortController | undefined;
  token: string;
  chatId: string;
}) => {
  const result = await client
    .new(abortController?.signal)
    .delete(`/api/chat/${chatId}`)
    .auth(token)
    .fetch<{ data: undefined }>();
  return result;
};

export const getChats = async ({
  abortController,
  token,
}: {
  abortController: AbortController | undefined;
  token: string;
}) => {
  const result = await client
    .new(abortController?.signal)
    .get(`/api/chats`)
    .auth(token)
    .fetch<{ data: ChatObject[] }>();
  return result;
};

export const generateAskPolarAnswer = async ({
  abortController,
  token,
  chatId,
}: {
  abortController: AbortController | undefined;
  token: string;
  chatId: string;
}) => {
  const result = await fetch(
    getServiceHost("ai-service") + `/api/chat/${chatId}/ask`,
    {
      signal: abortController?.signal,
      headers: {
        token: token,
      },
    },
  );

  return result;
};

export interface InsightsCustomReport {
  metricList: string[];
  range: { start: string; end: string };
  granularity: Granularity | "none";
  selectedBreakdowns: string[];
  rules: ComposeRules;
  metricRules?: ComposeRules;
  ordering: string;
  direction: "ASC" | "DESC";
  views: string[];
  flags?: string[];
  top?: ComposeTopFilter;
  extraParams?: Record<string, string>;
}
export interface GetMetricsValuesResult {
  error: boolean;
  data: Array<{ [key: string]: string | number }>;
}

export const generateAskPolarInsights = async ({
  abortController,
  token,
  reportDefinition,
  reportValues,
  previousPeriodValues,
}: {
  abortController: AbortController | undefined;
  token: string;
  reportDefinition: InsightsCustomReport;
  reportValues: GetMetricsValuesResult;
  previousPeriodValues: GetMetricsValuesResult;
}) => {
  const result = await client
    .new(abortController?.signal)
    .post("/api/insights")
    .auth(token)
    .body({ reportDefinition, reportValues, previousPeriodValues })
    .stream();
  return result;
};

export type PromptType = "basic" | "enhanced" | "template";
export const updateChat = async ({
  abortController,
  token,
  message,
  chatId,
  promptType,
}: {
  abortController: AbortController | undefined;
  token: string;
  chatId: string;
  message: string;
  promptType: PromptType;
}) => {
  const result = await client
    .new(abortController?.signal)
    .patch(`/api/chat/${chatId}`)
    .auth(token)
    .body({
      message,
      promptType,
    })
    .fetch<{ data: UIChat }>();
  return result;
};

export const updateChatItem = async ({
  abortController,
  token,
  like,
  chatId,
  chatItemId,
}: {
  abortController: AbortController | undefined;
  token: string;
  like: "true" | "false";
  chatId: string;
  chatItemId: string;
}) => {
  const result = await client
    .new(abortController?.signal)
    .patch(`/api/chat/${chatId}/item/${chatItemId}`)
    .auth(token)
    .body({
      like,
    })
    .fetch();
  return result;
};
