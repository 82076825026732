import styled, { css } from "styled-components";

import { theme } from "../theme";

const sizes = {
  tiny: 12,
  small: 18,
  large: 24,
} as const;

export const StyledConnectorIconWrapper = styled.div<{
  size?: "tiny" | "small" | "large";
}>`
  display: inline-flex;
  font-family: ${theme.typography.fontFamily.poppins};
  font-size: ${theme.typography.fontSize.s8px};
  font-weight: ${theme.typography.fontWeight.semiBold};
  box-sizing: border-box;
  background: ${theme.colors.primary20};
  border-radius: ${({ size }) =>
    size === "small" ? theme.borderRadius.r2px : theme.borderRadius.r4px};
  color: ${theme.colors.primary100};
  text-align: center;

  ${({ size = "small" }) => {
    return css`
      width: ${sizes[size]}px;
      height: ${sizes[size]}px;
      line-height: ${sizes[size] - 2}px;
    `;
  }}

  border: 1px solid ${theme.colors.borderLight};
  align-items: center;
  justify-content: center;
`;
