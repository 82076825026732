import React from "react";

import { AddonType } from "../../../common/types/subscription-service";
import { Grid, theme } from "../../../icecube-ux";
import { _ } from "../../../languages/helper";
import AddonPopup from "../../Popups/AddonPopup";

import { AddonDetailsCard } from "./AddonDetailsCard";

interface IntradayRefreshPopupProps {
  onClickAddon: (selectedAddon: AddonType) => void;
  onClose: (e: React.MouseEvent) => void;
  disableAnimation?: boolean;
}

export const IntradayRefreshPopup = ({
  onClickAddon,
  onClose,
  disableAnimation = false,
}: IntradayRefreshPopupProps) => {
  return (
    <AddonPopup
      disableAnimation={disableAnimation}
      onClickAddon={onClickAddon}
      headerTitle={_`Intraday refresh`}
      headerSubtitle={_`Sync your data continuously throughout the day`}
      headerIconColor={theme.customColors.menuConnectorsBg}
      bodyTitle={_`Elevate Your Shopify Performance with Polar's Intraday Refresh`}
      addonName="IntradayRefresh"
      onClose={onClose}
      iconName="IntradayRefresh"
      loading={false}
      addonDetailsContent={
        <Grid gridTemplateColumns="repeat(2, 1fr)" gap={12}>
          <AddonDetailsCard
            title={_`Real-Time Data, Real-Time Decisions`}
            paragraph={_`Stay ahead with data-driven decisions. Intraday Refresh updates frequently, enabling quick strategy pivots and capitalizing on opportunities.`}
          />
          <AddonDetailsCard
            title={_`Unleash Promotional Power`}
            paragraph={_`Adjust campaigns on the fly during peak retail events like Black Friday. Maximize the effectiveness of every promotional offer, turning high-traffic days into high-profit milestones.`}
          />
          <AddonDetailsCard
            span={2}
            title={_`Optimize as You Go`}
            paragraph={_`Achieve unparalleled attribution clarity by combining Intraday Refresh with the Polar Pixel and CAPI. Optimize ad platform targeting and make sure that every ad dollar spent means maximum return on investment.`}
          />
        </Grid>
      }
    />
  );
};
