import { createClient } from "./apiClient";
import {
  Dashboard,
  DashboardElement,
  DashboardElementType,
  DashboardPage,
} from "./dashboardElement";

const client = createClient("views-service");

export const getDashboards = async (token: string) => {
  const result = await client.new().get("/api/dashboards").auth(token).fetch<{
    data: Dashboard[];
  }>();
  if (result.error) {
    return [];
  }
  const { data } = result;
  return data.map((dashboard) => ({
    ...dashboard,
  })) as Dashboard[];
};

export const createDashboard = async (
  token: string,
  title: string,
  elements: DashboardElement[],
  page: DashboardPage,
) => {
  const result = await client
    .new()
    .post("/api/dashboards")
    .body({ title, elements, page })
    .auth(token)
    .fetch<{ data: number | null }>();
  return result.error ? null : result.data;
};

export const patchDashboard = async (
  token: string,
  title: string,
  elements: DashboardElement[],
  id: number,
) => {
  const result = await client
    .new()
    .patch("/api/dashboards/" + id)
    .body({ title, elements })
    .auth(token)
    .fetch<{ data: string | null }>();
  return result.error ? null : result.data;
};

export const deleteDashboard = async (token: string, id: number) => {
  const result = await client
    .new()
    .delete("/api/dashboards/" + id)
    .body({})
    .auth(token)
    .fetch();
  return result.error ? null : result.data;
};

export const orderDashboards = async (
  token: string,
  from: number,
  to: number,
  inDashboard: number,
  page: DashboardPage,
) => {
  const result = await client
    .new()
    .patch("/api/dashboards/order")
    .body({ from, to, inDashboard, page })
    .auth(token)
    .fetch();
  return result.error ? null : result.data;
};

export interface DashboardReorderPayload {
  fromDashboardId: number;
  fromItemId: number;
  fromItemType: DashboardElementType | "dashboard";
  toDashboardId: number;
  toItemId: number;
  toItemType: DashboardElementType | "dashboard";
  page: DashboardPage;
}

export const orderDashboardsV2 = async (
  token: string,
  data: DashboardReorderPayload,
) => {
  const result = await client
    .new()
    .patch("/api/dashboards/order")
    .body(data)
    .auth(token)
    .fetch();
  return result.error ? null : result.data;
};
