import styled from "styled-components";

export const StyledFreeTrialMarketingPageSection = styled.div<{
  reverse: boolean;
}>`
  display: flex;
  ${({ reverse }) => (reverse ? `flex-direction: row-reverse;` : "")}
  padding: 32px 20px;
`;

export const StyledFreeTrialMarketingPageSectionImageSide = styled.div`
  padding-top: 25px;
  width: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledFreeTrialMarketingPageSectionTextSide = styled.div`
  padding-top: 80px;
  padding-left: 60px;
  width: 50%;
  box-sizing: border-box;
`;

export const StyledFreeTrialMarketingPageSectionImage = styled.img`
  width: 100%;
`;
