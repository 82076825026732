import React from "react";
import {
  onLCP,
  onFID,
  onCLS,
  CLSMetricWithAttribution,
  FIDMetricWithAttribution,
  LCPMetricWithAttribution,
} from "web-vitals/attribution";

import {
  integrations,
  trackEvent,
  TRACKING_EVENTS,
} from "../utils/trackingUtils";

import { LOCAL_STORAGE_KEYS } from "./useLocalStorage";

export const PerformanceEvent = {
  COMPOSE_QUERY_STARTED: "COMPOSE_QUERY_STARTED",
  COMPOSE_QUERY_FINISHED: "COMPOSE_QUERY_FINISHED",

  APP_LOADED: "APP_LOADED",
  PAGE_LOADED: "PAGE_LOADED",
  PAGE_LOAD_CHECKPOINT: "PAGE_LOAD_CHECKPOINT",

  USE_COMPOSE_STARTED: "USE_COMPOSE_STARTED",

  USE_COMPOSE_TABLE_DATA_READY: "USE_COMPOSE_TABLE_DATA_READY",
  USE_COMPOSE_TOTAL_DATA_READY: "USE_COMPOSE_TOTAL_DATA_READY",

  USE_COMPOSE_COMPARE_TABLE_DATA_READY: "USE_COMPOSE_COMPARE_TABLE_DATA_READY",
  USE_COMPOSE_COMPARE_TOTAL_DATA_READY: "USE_COMPOSE_COMPARE_TOTAL_DATA_READY",

  KI_SECTIONS_REQUEST_STARTED: "KI_SECTIONS_REQUEST_STARTED",
  KI_SECTIONS_REQUEST_FINISHED: "KI_SECTIONS_REQUEST_FINISHED",

  CSV_DOWNLOAD_STARTED: "CSV_DOWNLOAD_STARTED",
  CSV_DOWNLOAD_FINISHED: "CSV_DOWNLOAD_FINISHED",
} as const;

type ComposeField =
  | "metricList"
  | "range"
  | "granularity"
  | "selectedBreakdowns"
  | "rules"
  | "ordering"
  | "direction"
  | "views"
  | "flags"
  | "top"
  | "extraParams";

type StartEventRecord =
  | {
      performanceEvent:
        | typeof PerformanceEvent.USE_COMPOSE_STARTED
        | typeof PerformanceEvent.COMPOSE_QUERY_STARTED;

      compose: Partial<Record<ComposeField, unknown>>;
    }
  | {
      performanceEvent:
        | typeof PerformanceEvent.KI_SECTIONS_REQUEST_STARTED
        | typeof PerformanceEvent.CSV_DOWNLOAD_STARTED;
    };

export type ComposeFinishedEvent =
  | typeof PerformanceEvent.COMPOSE_QUERY_FINISHED
  | typeof PerformanceEvent.USE_COMPOSE_TABLE_DATA_READY
  | typeof PerformanceEvent.USE_COMPOSE_TOTAL_DATA_READY
  | typeof PerformanceEvent.USE_COMPOSE_COMPARE_TABLE_DATA_READY
  | typeof PerformanceEvent.USE_COMPOSE_COMPARE_TOTAL_DATA_READY;

type ComposeFinishedEventRecord = {
  performanceEvent: ComposeFinishedEvent;
};

type KISectionFinishedEventRecord = {
  performanceEvent: typeof PerformanceEvent.KI_SECTIONS_REQUEST_FINISHED;
  nMetricsBySection: number[];
};

type SingleEventRecord =
  | {
      performanceEvent: typeof PerformanceEvent.PAGE_LOAD_CHECKPOINT;
      navigationTiming: unknown;
    }
  | {
      performanceEvent:
        | typeof PerformanceEvent.APP_LOADED
        | typeof PerformanceEvent.PAGE_LOADED;
    };

type ComposeStreamFinishedEventRecord = {
  performanceEvent: typeof PerformanceEvent.CSV_DOWNLOAD_FINISHED;
  fileSizeInBytes: number;
};

type PerformanceMeasurementEvent = {
  timeElapsedInMS: number;
} & (
  | ComposeFinishedEventRecord
  | KISectionFinishedEventRecord
  | ComposeStreamFinishedEventRecord
);

type PerformanceEventRecord = { traceId: string } & (
  | SingleEventRecord
  | StartEventRecord
  | PerformanceMeasurementEvent
);

const WebVitalsEvent = {
  LARGEST_CONTENTFUL_PAINT: "LARGEST_CONTENTFUL_PAINT",
  FIRST_INPUT_DELAY: "FIRST_INPUT_DELAY",
  CUMULATIVE_LAYOUT_SHIFT: "CUMULATIVE_LAYOUT_SHIFT",
} as const;

type WebVitalsEventRecord = { traceId: string } & (
  | {
      performanceEvent: typeof WebVitalsEvent.LARGEST_CONTENTFUL_PAINT;
      webVitals: LCPMetricWithAttribution;
    }
  | {
      performanceEvent: typeof WebVitalsEvent.FIRST_INPUT_DELAY;
      webVitals: FIDMetricWithAttribution;
    }
  | {
      performanceEvent: typeof WebVitalsEvent.CUMULATIVE_LAYOUT_SHIFT;
      webVitals: CLSMetricWithAttribution;
    }
);

const PERFORMANCE_CHANNEL =
  localStorage.getItem("feature-flag-performance-channel") ||
  (process.env.REACT_APP_ENV === "production" ? "segment" : "");

/**
 * Segment has built-in batching, but with the large amount of performance
 * events we're logging it was still throttling functional requests, so
 * added an extra layer of batching here.
 */
const batchState: {
  batches: Record<string, unknown>[][];
  nextDispatch: NodeJS.Timeout | null;
} = {
  batches: [],
  nextDispatch: null,
};
const sessionId = crypto?.randomUUID?.() || "";

/**
 * Attempts to dispatch a performance event. Makes no guarantee of delivery if
 * the customer navigates away from the page.
 */
const dispatchPerformanceEvent = ({
  performanceEvent,
  traceId,
  ...eventRecord
}: PerformanceEventRecord | WebVitalsEventRecord) => {
  try {
    const { id: userId, tenantId } = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ATTRIBUTES) || "{}",
    ) as { id?: string; tenantId?: string };

    const eventProperties = {
      performanceEvent,
      sessionId,
      traceId,
      userId,
      tenantId,
      currentUrl: window.location.href,
      eventData: JSON.stringify(eventRecord),
      absoluteISOTimestamp: new Date().toISOString(),
      relativeTimestamp: performance.now(),
    };

    let lastBatch = batchState.batches.at(-1);
    if (!lastBatch || lastBatch.length >= 100) {
      lastBatch = [];
      batchState.batches.push(lastBatch);
    }

    lastBatch.push(eventProperties);

    if (batchState.nextDispatch) {
      return;
    }

    // Yield to functional code.
    batchState.nextDispatch = setTimeout(() => {
      const [batch, ...batches] = batchState.batches;
      batchState.batches = batches;
      batchState.nextDispatch = null;

      for (const event of batch) {
        if (PERFORMANCE_CHANNEL === "segment") {
          trackEvent(TRACKING_EVENTS.PERFORMANCE_EVENT_RECEIVED, event, {
            sendTo: [integrations.segment],
          });
        } else if (PERFORMANCE_CHANNEL === "console") {
          // eslint-disable-next-line no-console
          console.log(event);
        }
      }
    }, 500);
  } catch (e) {
    console.warn(`Error dispatching performance event`, e);
  }
};

const createWebVitalsDispatcher = <
  TEvent extends WebVitalsEventRecord["performanceEvent"],
>(
  performanceEvent: TEvent,
  traceId: string,
) => {
  return (webVitals: unknown) => {
    dispatchPerformanceEvent({
      performanceEvent,
      traceId,
      webVitals,
    } as WebVitalsEventRecord);
  };
};

const globalPageLoadTrackingState = {
  [PerformanceEvent.PAGE_LOAD_CHECKPOINT]: false,
  [PerformanceEvent.APP_LOADED]: false,
  [PerformanceEvent.PAGE_LOADED]: false,
};
export const trackPageLoadCheckpoint = (
  performanceEvent: SingleEventRecord["performanceEvent"],
) => {
  try {
    if (globalPageLoadTrackingState[performanceEvent]) {
      return;
    } else {
      globalPageLoadTrackingState[performanceEvent] = true;
    }

    dispatchPerformanceEvent({
      traceId: sessionId,
      performanceEvent,
      navigationTiming:
        performanceEvent === PerformanceEvent.PAGE_LOAD_CHECKPOINT
          ? ((
              window.performance &&
              performance.getEntriesByType &&
              performance.getEntriesByType("navigation")[0]
            )?.toJSON() as unknown)
          : undefined,
    });
  } catch (e) {
    console.warn(e);
  }
};

export const setupWebVitalsTracking = () => {
  trackPageLoadCheckpoint(PerformanceEvent.APP_LOADED);
  const traceId = crypto.randomUUID();
  onCLS(
    createWebVitalsDispatcher(WebVitalsEvent.CUMULATIVE_LAYOUT_SHIFT, traceId),
  );
  onFID(createWebVitalsDispatcher(WebVitalsEvent.FIRST_INPUT_DELAY, traceId));
  onLCP(
    createWebVitalsDispatcher(WebVitalsEvent.LARGEST_CONTENTFUL_PAINT, traceId),
  );
};

export const startPerformanceMeasurement = ({
  traceId = crypto.randomUUID(),
  eventRecord,
}: {
  traceId?: PerformanceEventRecord["traceId"];
  eventRecord: StartEventRecord;
}) => {
  const start = performance.now();

  if (eventRecord) {
    dispatchPerformanceEvent({ traceId, ...eventRecord });
  }

  const reportTimeElapsed = (
    event:
      | ComposeFinishedEventRecord
      | KISectionFinishedEventRecord
      | ComposeStreamFinishedEventRecord,
  ) => {
    dispatchPerformanceEvent({
      traceId,
      timeElapsedInMS: performance.now() - start,
      ...event,
    });
  };

  return {
    reportTimeElapsed,
    traceId,
  };
};

export const usePerformanceMeasurement = ({
  traceId,
  eventRecord,
}: {
  traceId?: PerformanceEventRecord["traceId"];
  eventRecord?: StartEventRecord;
}) => {
  const hasEventRecord = !!eventRecord;
  const eventDiff =
    hasEventRecord &&
    JSON.stringify("compose" in eventRecord && eventRecord.compose);

  const measurement = React.useMemo(() => {
    if (!hasEventRecord) {
      return null;
    }

    return startPerformanceMeasurement({
      traceId,
      eventRecord,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [traceId, eventDiff, hasEventRecord]);

  return measurement;
};
