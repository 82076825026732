import { useCallback, useEffect, useMemo, useState } from "react";
import uniqid from "uniqid";

import { useViews } from "../../components/CountryFilters/views/ViewProvider";
import { useAuth } from "../../hooks/auth/auth";
import { useBootstrap } from "../../hooks/bootstrap";
import { useModals } from "../../hooks/modals";
import { Button } from "../../icecube-ux";
import { ListOptions } from "../../icecube-ux/List/List";
import { _ } from "../../languages/helper";
import {
  CustomView,
  CustomViewGroup,
  createCustomView,
  deleteCustomView,
  getCustomViews,
  patchCustomView,
} from "../../lib/viewsService";
import { DEFAULT_CURRENCY } from "../../utils/currencyUtils";
import { LIVE_DEMO_TENANT_ID } from "../../utils/demoConfig";
import { TRACKING_EVENTS, trackEvent } from "../../utils/trackingUtils";
import { randomString } from "../../utils/utils";

export default function useViewSelector(
  onChange: (values: Array<number | string>) => void,
  selectedViewIds: string[],
) {
  const auth = useAuth();
  const {
    viewsBeingEdited: views,
    setViewsBeingEdited: setViews,
    setViewIndexBeingEdited,
    customViewGroups,
    setCustomViewGroups,
  } = useViews();
  const { confirm, showLoader, hideLoader } = useModals();
  const { isDemoData, tenant } = useBootstrap();

  const [loading, setLoading] = useState(false);
  const [showCreateViewPopup, setShowCreateViewPopup] = useState(false);
  const [editViewId, setEditViewId] = useState<string | null>(null);
  const [saveCacheKey, setSaveCacheKey] = useState(randomString(6));

  const formatSelected = useMemo(
    () =>
      selectedViewIds.map((s) =>
        [...s.split("-").slice(0, 2), saveCacheKey].join("-"),
      ),
    [selectedViewIds, saveCacheKey],
  );

  const getCurrencyFromSelectedViews = useCallback(() => {
    const parsedSelectedViews: Map<string, string[]> = new Map();
    selectedViewIds.forEach((s) => {
      const [viewId, viewItemId] = s.split("-");
      parsedSelectedViews.set(viewId, [
        ...(parsedSelectedViews.get(viewId) || []),
        viewItemId,
      ]);
    });

    const selectedViews: CustomView[] = [];
    (customViewGroups ?? []).forEach((view) => {
      if (parsedSelectedViews.has(`${view.id}`)) {
        view.json_value.forEach((element) => {
          if (parsedSelectedViews.get(`${view.id}`)?.includes(element.id)) {
            selectedViews.push(element);
          }
        });
      }
    });

    return (
      selectedViews
        .filter((v) => v.currency !== undefined)
        ?.map((v) => v.currency)?.[0] ||
      tenant.settings.currency ||
      DEFAULT_CURRENCY
    );
  }, [customViewGroups, selectedViewIds, tenant.settings.currency]);

  const handleClick = (view: CustomViewGroup) => {
    setViews(JSON.parse(JSON.stringify(view.json_value)) as CustomView[]);
    trackEvent(TRACKING_EVENTS.VIEW_EDIT, {});
    setEditViewId(view.id);
  };

  const getViewsList = (withEditButtons = true) => {
    if (!customViewGroups) {
      return [];
    }

    const list: ListOptions[] = [];
    customViewGroups.forEach((view) => {
      list.push({
        label: view.title,
        value: `${view.id}`,
        isSubtitle: true,
        rightComponents: withEditButtons
          ? [
              <Button
                color="ghost"
                size="small"
                label={_`Edit`}
                leftIcon="Edit"
                onClick={() => handleClick(view)}
                disabled={
                  isDemoData &&
                  !(auth.isGod() && tenant.id === LIVE_DEMO_TENANT_ID)
                }
              />,
            ]
          : [],
      });
      try {
        view.json_value.forEach((element) =>
          list.push({
            label: element.title,
            value: `${view.id}-${element.id}-${saveCacheKey}`,
          }),
        );
      } catch (e) {
        console.error(e);
      }
    });
    return list;
  };

  const handleClickAdd = () => {
    setViews([
      {
        title: _`Untitled View`,
        connectors: [],
        id: uniqid(),
      },
    ]);
    setViewIndexBeingEdited(0);
    setShowCreateViewPopup(true);
    trackEvent(TRACKING_EVENTS.VIEW_CREATE, {});
  };

  const handleSaveViews = async (title: string) => {
    const viewId = editViewId;
    setLoading(true);
    setShowCreateViewPopup(false);
    setEditViewId(null);

    const cleanedViews = [...views];
    cleanedViews.forEach((view) => {
      view.connectors.forEach((connector) => {
        if (connector.connectorAccounts) {
          connector.connectorAccounts = undefined;
        }
      });
    });
    if (viewId) {
      await patchCustomView(await auth.getToken(), viewId, title, cleanedViews);
      setCustomViewGroups(null);
    } else {
      await createCustomView(await auth.getToken(), title, cleanedViews);
      setCustomViewGroups(null);
    }
    trackEvent(TRACKING_EVENTS.VIEW_SAVE, {});
    const key = randomString(6);
    setSaveCacheKey(key);
    setLoading(false);
    handleChange(selectedViewIds, key);
  };

  const handleChange = (views: string[], key?: string) => {
    const newViews: string[] = [];
    views.forEach((v) => {
      const [viewId, viewItemId] = v.split("-");
      if (
        customViewGroups &&
        customViewGroups.map((c) => `${c.id}`).includes(viewId)
      ) {
        const view = customViewGroups.find((c) => `${c.id}` === viewId);
        if (view && view.json_value.find((v) => v.id === viewItemId)) {
          newViews.push(`${viewId}-${viewItemId}-${key || saveCacheKey}`);
        }
      }
    });
    onChange(newViews);
  };

  const handleClickCancel = () => {
    setViews([]);
    setEditViewId(null);
    setCustomViewGroups(null);
    setShowCreateViewPopup(false);
  };

  const handleStartDelete = () => {
    confirm({
      danger: true,
      title: _`Delete views`,
      confirmButtonLabel: _`Delete All`,
      texts: [
        _`Are you sure you want to delete this section and all its views?`,
      ],
      onConfirm: async () => {
        showLoader();
        try {
          setCustomViewGroups(
            (views) => views?.filter((v) => v.id !== editViewId) || [],
          );
          onChange(
            selectedViewIds.filter(
              (viewName) => viewName.split("-")?.[0] !== String(editViewId),
            ),
          );
          setEditViewId(null);
          setShowCreateViewPopup(false);
          await deleteCustomView(await auth.getToken(), editViewId || "");
        } catch (e) {
          console.error(e);
        }
        hideLoader();
      },
    });
  };

  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      if (customViewGroups === null) {
        setLoading(true);
        const viewGroups = await getCustomViews(await auth.getToken());
        if (!viewGroups || !mounted) {
          return;
        }
        setCustomViewGroups(viewGroups);
        setLoading(false);

        const selectedViewsWithoutDeletedViews = selectedViewIds.filter(
          (select) => {
            const [viewGroupId, viewIndex] = select.split("-");
            const viewGroup = (viewGroups || []).find(
              (v) => `${v.id}` === `${viewGroupId}`,
            );
            return (
              viewGroup && viewGroup.json_value.find((v) => v.id === viewIndex)
            );
          },
        );
        if (
          selectedViewsWithoutDeletedViews.length !== selectedViewIds.length
        ) {
          onChange(selectedViewsWithoutDeletedViews);
        }
      } else {
        setLoading(false);
      }
    };
    void fetch();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, [customViewGroups]);

  return {
    formatSelected,
    loading,
    showCreateViewPopup,
    customViewGroups,
    editViewId,
    getViewsList,
    handleSaveViews,
    handleClickAdd,
    handleClickCancel,
    handleStartDelete,
    getCurrencyFromSelectedViews,
  };
}
