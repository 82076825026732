import { useEffect, useState } from "react";

import { CURRENCIES, getCurrencies } from "../lib/currenciesService";

import { useAuth } from "./auth/auth";

const useCurrencies = () => {
  const auth = useAuth();
  const [currencies, setCurrencies] = useState<CURRENCIES | null>(null);

  useEffect(() => {
    const loadCurrencies = async () => {
      try {
        setCurrencies(await getCurrencies(await auth.getToken()));
      } catch (err) {
        console.error(err);
        setCurrencies({});
      }
    };
    void loadCurrencies();
  }, [auth]);

  return currencies;
};

export default useCurrencies;
