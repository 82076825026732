import { CSSProperties } from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  padding: 4px 0px;

  box-sizing: border-box;
`;

const StyledSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: var(--primary9);
`;

type NavigationSeparatorType = {
  style?: CSSProperties;
};

export const NavigationSeparator = ({ style }: NavigationSeparatorType) => {
  return (
    <StyledWrapper style={style}>
      <StyledSeparator>&nbsp;</StyledSeparator>
    </StyledWrapper>
  );
};
