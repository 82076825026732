import { _ } from "../../languages/helper";

export interface CustomDimensionRecord {
  id: string;
  title: string;
  json_value: CustomDimension;
  disableEdit?: boolean;
  description?: string;
  categories?: {
    channelGrouping: boolean;
  };
}

export interface CustomDimension {
  whenThen: Array<{
    when: CustomDimensionWhenBlock[];
    then: CustomDimensionThenBlock;
  }>;
  else: CustomDimensionThenBlock;
}

export type CustomDimensionWhenBlockOperators =
  | "contains"
  | "notContains"
  | "containsCaseSensitive"
  | "notContainsCaseSensitive"
  | "is"
  | "isNot"
  | "lower"
  | "greater"
  | "lowerOrEqual"
  | "greaterOrEqual"
  | "startsWith"
  | "endsWith"
  | "isBefore"
  | "isAfter";

export interface CustomDimensionWhenBlock {
  lineOperator: "and" | "or" | "";
  dimensionKey: string;
  operator: "" | CustomDimensionWhenBlockOperators;
  value: string;
}

export type CustomDimensionThenBlock = Array<string | null>;

type CustomDimensionFunctionNames =
  | "LEFT"
  | "RIGHT"
  | "LOWER"
  | "UPPER"
  | "CONCAT"
  | "TRIM"
  | "REPLACE"
  | "DATEDIFF"
  | "DATETRUNC"
  | "SPLIT_PART"
  | "DAY"
  | "DAYOFWEEK"
  | "DAYNAME"
  | "WEEK"
  | "MONTH"
  | "MONTHNAME"
  | "QUARTER"
  | "YEAR"
  | "REGEXP";

export interface CustomDimensionFunctions {
  key: string;
  label: string;
  params: Array<{ name: string; description: string }>;
}

export const CUSTOM_DIMENSIONS_FUNCTIONS: {
  [key in CustomDimensionFunctionNames]: CustomDimensionFunctions;
} = {
  LEFT: {
    key: "operation.LEFT",
    label: "LEFT",
    params: [
      { name: "value", description: _`The value to shorten` },
      { name: "length", description: _`The length of the final string` },
    ],
  },
  RIGHT: {
    key: "operation.RIGHT",
    label: "RIGHT",
    params: [
      { name: "value", description: _`The value to shorten` },
      { name: "length", description: _`The length of the final string` },
    ],
  },
  LOWER: {
    key: "operation.LOWER",
    label: "LOWER",
    params: [
      { name: "value", description: _`The value to be put in lower case` },
    ],
  },
  UPPER: {
    key: "operation.UPPER",
    label: "UPPER",
    params: [
      { name: "value", description: _`The value to be put in upper case` },
    ],
  },
  CONCAT: {
    key: "operation.CONCAT",
    label: "CONCAT",
    params: [
      { name: "value1", description: _`The first value to concatenate` },
      { name: "value2", description: _`The second value to concatenate` },
    ],
  },
  TRIM: {
    key: "operation.TRIM",
    label: "TRIM",
    params: [{ name: "value", description: _`The value to be trimed` }],
  },
  REPLACE: {
    key: "operation.REPLACE",
    label: "REPLACE",
    params: [
      {
        name: "subject",
        description: _`The string in which to do the replacements`,
      },
      {
        name: "pattern",
        description: _`The substring that you want to replace`,
      },
      { name: "replacement", description: _`The value used as a replacement` },
    ],
  },
  DATEDIFF: {
    key: "operation.DATEDIFF",
    label: "DATEDIFF",
    params: [
      {
        name: "date_part",
        description: _`The unit of time (year, month, day, quarter...)`,
      },
      { name: "date1", description: _`The first value to compare` },
      { name: "date2", description: _`The second value to compare` },
    ],
  },
  DATETRUNC: {
    key: "operation.DATETRUNC",
    label: "DATETRUNC",
    params: [
      {
        name: "date_part",
        description: _`The unit of time (year, month, day, quarter...)`,
      },
      { name: "date", description: _`The date to be truncated` },
    ],
  },
  SPLIT_PART: {
    key: "operation.SPLIT_PART",
    label: "SPLIT_PART",
    params: [
      {
        name: "string",
        description: _`Text to be split into parts`,
      },
      {
        name: "delimiter",
        description: _`The text representing the delimiter to split by`,
      },
      {
        name: "part number",
        description: _`The requested part of the split (starts at 1)`,
      },
    ],
  },
  DAY: {
    key: "operation.DAY",
    label: "DAY",
    params: [
      {
        name: "date",
        description: _`The date from which to get the day of the month`,
      },
    ],
  },
  DAYOFWEEK: {
    key: "operation.DAYOFWEEK",
    label: "DAYOFWEEK",
    params: [
      {
        name: "date",
        description: _`The date from which to get the day of the week`,
      },
    ],
  },
  DAYNAME: {
    key: "operation.DAYNAME",
    label: "DAYNAME",
    params: [
      {
        name: "date",
        description: _`The date from which to get the day name of the week (ex: Mon, Tue, Wed)`,
      },
    ],
  },
  WEEK: {
    key: "operation.WEEK",
    label: "WEEK",
    params: [
      {
        name: "date",
        description: _`The date from which to get the week of the year`,
      },
    ],
  },
  MONTH: {
    key: "operation.MONTH",
    label: "MONTH",
    params: [
      {
        name: "date",
        description: _`The date from which to get the month of the year`,
      },
    ],
  },
  MONTHNAME: {
    key: "operation.MONTHNAME",
    label: "MONTHNAME",
    params: [
      {
        name: "date",
        description: _`The date from which to get the month name of (ex: Jan, Feb, Mar)`,
      },
    ],
  },
  QUARTER: {
    key: "operation.QUARTER",
    label: "QUARTER",
    params: [
      {
        name: "date",
        description: _`The date from which to get the quarter of the year`,
      },
    ],
  },
  YEAR: {
    key: "operation.YEAR",
    label: "YEAR",
    params: [
      { name: "date", description: _`The date from which to get the year` },
    ],
  },
  REGEXP: {
    key: "operation.REGEXP",
    label: "REGEXP",
    params: [
      {
        name: "subject",
        description: _`The string on which you want to apply the regexp`,
      },
      {
        name: "pattern",
        description: _`The regexp expression`,
      },
    ],
  },
};
