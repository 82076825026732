import styled from "styled-components";

import { legacyTheme } from "../legacyTheme";

export const StyledFreeTrialMarketingPageLayout = styled.div`
  overflow-y: hidden;
  border-radius: ${legacyTheme.borderRadius.large};
  background-color: ${legacyTheme.colors.white};
`;

export const StyledFreeTrialMarketingPageLayoutJumbotron = styled.div`
  background-image: url("/img/bg-gradient-stars-min.png");
  background-size: cover;

  background-repeat: no-repeat;
  padding: 16vw 80px 60px 80px;

  height: 45vw;
  min-height: 600px;
  min-width: 632px;
  box-sizing: border-box;
  background-position: top center;
  margin-top: -15vw;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledFreeTrialMarketingPageLayoutJumbotronContent = styled.div`
  max-width: 884px;
`;

export const StyledFreeTrialMarketingPageLayoutJumbotronActions = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;
