import { Link, useLocation, useNavigate } from "react-router-dom";

import { useBanners } from "../../hooks/banners";
import { useBootstrap } from "../../hooks/bootstrap";
import { useConnectorObservability } from "../../hooks/connectorObservability";
import { Banner, Button, ConnectorIcon, theme } from "../../icecube-ux";
import {
  getIntegrationConnectorObject,
  getIntegrationHandler,
  getIntegrationIcon,
} from "../../integrations/integration";
import { _, _node } from "../../languages/helper";
import { commaList, pluralize } from "../../utils/languageUtils";

export default function ConnectorError() {
  const navigate = useNavigate();
  const location = useLocation();
  const { connectorsWithErrors } = useConnectorObservability();
  const { isShown } = useBanners();
  const { hasPermission } = useBootstrap();
  const canEdit = hasPermission("connector.edit");

  if (!isShown) {
    return null;
  }

  const { integrationsToReconnect, integrationsWithErrorsButNoReconnect } =
    connectorsWithErrors.reduce(
      (acc, status) => {
        if (
          status.errors &&
          status.errors.join(" ").toLocaleLowerCase().includes("reconnect") &&
          getIntegrationHandler(status.integration)?.canReconnectOAuth
        ) {
          acc.integrationsToReconnect.add(status.integration);
        } else {
          acc.integrationsWithErrorsButNoReconnect.add(status.integration);
        }
        return acc;
      },
      {
        integrationsToReconnect: new Set<string>(),
        integrationsWithErrorsButNoReconnect: new Set<string>(),
      },
    );

  const isOnConnectorsPageAlready = location.pathname === "/connectors";
  const reconnectLink =
    integrationsToReconnect.size === 1
      ? `/connectors?openConnector=${[...integrationsToReconnect][0]}`
      : "/connectors";

  let subtitle: string | JSX.Element;
  if (integrationsToReconnect.size === 0 && canEdit) {
    subtitle = _`Please check your connectors.`;
  } else if (canEdit) {
    subtitle = (
      <>
        {_node`Please ${[
          "reconnect",
          isOnConnectorsPageAlready ? (
            "reconnect"
          ) : (
            <Link
              to={reconnectLink}
              style={{
                color: theme.colors.warning100,
                textDecoration: "underline",
              }}
            >
              reconnect
            </Link>
          ),
        ]} your ${[
          "account",
          pluralize(integrationsToReconnect.size, "account"),
        ]} to refresh the data`}
      </>
    );
  } else {
    subtitle = _`Please contact an admin to reconnect your data`;
  }

  return (
    <Banner
      variant="notification"
      title={_`There is an error with your ${[
        "connectors",
        `${commaList(
          [
            ...integrationsToReconnect,
            ...integrationsWithErrorsButNoReconnect,
          ].map((integration) => getIntegrationHandler(integration)?.label),
          { maxLength: 3 },
        )} ${pluralize(connectorsWithErrors.length, "connector")}`,
      ]}`}
      subtitle={subtitle}
      actionsChildren={
        <>
          {canEdit
            ? [...integrationsToReconnect].slice(0, 3).map((integration) => (
                <Button
                  key={integration}
                  color="warning-on-orange-background"
                  onClick={() =>
                    navigate(`/connectors?openConnector=${integration}`)
                  }
                  className="margin-left-regular"
                >
                  <ConnectorIcon name={getIntegrationIcon(integration)} />
                  Reconnect {getIntegrationConnectorObject(integration)?.label}
                </Button>
              ))
            : null}
          {canEdit
            ? [...integrationsWithErrorsButNoReconnect]
                .slice(0, 1)
                .map((integration) => (
                  <Button
                    key={integration}
                    leftIcon={getIntegrationIcon(integration)}
                    color="warning-on-orange-background"
                    onClick={() =>
                      navigate(`/connectors?openConnector=${integration}`)
                    }
                    className="margin-left-regular"
                  >
                    Open {getIntegrationHandler(integration)?.label}
                  </Button>
                ))
            : null}
        </>
      }
    />
  );
}
