import styled from "styled-components";

export const StyledWireframeLoader = styled.div`
  background: #e8f4fe;
  position: relative;
  overflow: hidden;
  display: block;
  border-radius: 2px;

  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.55),
      transparent
    );
    animation: wireframe-loading 1.5s infinite;
  }
`;
