import styled, { css } from "styled-components";

import { InteractionEvents } from "../shared";
import { theme } from "../theme";
import { typographyStylesMap } from "../Typography/typographyStylesMap";

import { chipColorStylesMap } from "./chipColorStylesMap";
import { chipSizeStylesMap } from "./chipSizeStylesMap";

type GetChipColorStyleArgs = {
  warning?: boolean;
  selected: boolean;
  background: "white" | "light" | "black";
  size: "large" | "small" | "xsmall" | "xxsmall";
};

const chooseChipColorStyle = ({
  warning,
  selected,
  size,
  background,
}: GetChipColorStyleArgs) => {
  if (warning) {
    return chipColorStylesMap.warning;
  }
  if (background === "black") {
    return chipColorStylesMap.onBlackBackground;
  }
  if (!selected) {
    return chipColorStylesMap.default;
  }
  if (size === "xsmall") {
    return chipColorStylesMap.xSmallSelected;
  }
  if (background === "white") {
    return chipColorStylesMap.selectedOnWhiteBackground;
  }
  return chipColorStylesMap.selectedOnLightBackground;
};

export const StyledChipTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ActionsContainer = styled.div`
  margin-left: auto;
`;

export const StyledChip = styled.button<
  {
    warning?: boolean;
    hovered?: boolean;
    displayActionOnHover?: boolean;
    noContent: boolean;
    canDrag: boolean;
    align: "left" | "center";
    onClick?: InteractionEvents<HTMLButtonElement>["onClick"];
  } & GetChipColorStyleArgs
>`
  outline: revert;
  display: inline-flex;
  align-items: center;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;

  ${(props) => {
    const { size, noContent, align } = props;

    return css`
      ${chooseChipColorStyle(props).default}
      ${chipSizeStylesMap[size]}
      padding-left: ${noContent
        ? chipSizeStylesMap[size].paddingTop
        : chipSizeStylesMap[size].paddingLeft};
      padding-right: ${noContent
        ? chipSizeStylesMap[size].paddingTop
        : chipSizeStylesMap[size].paddingRight};
      padding-top: ${chipSizeStylesMap[size].paddingTop};
      padding-bottom: ${chipSizeStylesMap[size].paddingBottom};
      justify-content: ${align === "left" ? "start" : "center"};
    `;
  }}

  ${(props) => {
    return (
      props.hovered &&
      css`
        ${chooseChipColorStyle(props).hover}
      `
    );
  }}

  

  ${(props) => {
    if (!props.onClick && !props.canDrag) {
      return css`
        cursor: default;
      `;
    }

    return css`
      &:hover:not(:disabled) {
        ${chooseChipColorStyle(props).hover}
      }
    `;
  }}

  &:disabled {
    ${chipColorStylesMap.disabled}
    cursor: default;
  }

  &:focus-visible {
    outline: solid 3px ${theme.colors.primary10};
  }
  ${({ displayActionOnHover }) =>
    displayActionOnHover &&
    css`
      ${ActionsContainer} {
        display: none;
      }
      &:hover {
        ${ActionsContainer} {
          display: block;
        }
      }
    `}
`;

export const StyledChipCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${theme.colors.primary100};
  background: ${theme.colors.primary20};

  ${typographyStylesMap.body9SemiBold}
  height: 14px;
  width: 14px;
  border-radius: 50%;
`;
