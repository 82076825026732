import { ReactNode, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

import { useAnnotationPopup } from "../../../hooks/useAnnotationPopup";
import { _ } from "../../../languages/helper";
import { ConnectorIcon } from "../../ConnectorIcon";
import { Flex } from "../../Flex";
import { IconButton } from "../../IconButton";
import { ConfirmPopup } from "../../Popup";
import { theme } from "../../theme";
import { Text } from "../../Typography";

const StyledIconWrapper = styled.div`
  margin-right: 4px;
  width: 18px;
  min-width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.borderLight};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export interface AnnotationEvent {
  date: string;
  iconName?: string;
  order: number;
  title: string;
  eventType: string;
  [eventType: string]: unknown;
}

export const AnnotationEntry = ({
  eventType,
  event,
}: {
  eventType: string;
  event: AnnotationEvent;
}) => {
  const { setOpenInfo, deleteAnnotation, refetchManualAnnotations } =
    useAnnotationPopup();
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);

  const handleDelete = async (id: number) => {
    await deleteAnnotation(id);
    await refetchManualAnnotations();
  };
  return (
    <>
      {deleteConfirmation ? (
        ReactDOM.createPortal(
          <ConfirmPopup
            onConfirm={() => void handleDelete(event?.id as number)}
            onDecline={() => setDeleteConfirmation(false)}
            confirmLabel={_`Yes`}
            declineLabel={_`No`}
          >
            <Text
              variant="body12Regular"
              color={theme.colors.text90}
              textAlign="center"
              style={{ paddingLeft: 4, paddingRight: 4 }}
            >
              {_`Are you sure you want to delete this annotation?`}
            </Text>
          </ConfirmPopup>,
          document.body,
        )
      ) : (
        <></>
      )}

      {eventType === "manual" ? (
        <Flex justifyContent="space-between">
          <Text
            ellipsis
            noWrap
            variant="body10Regular"
            color={theme.colors.text90}
          >
            {event?.description as ReactNode}
          </Text>
          <Flex>
            <IconButton
              name="Edit"
              onClick={() =>
                setOpenInfo({
                  description: String(event?.description),
                  id: event?.id as number,
                  mode: "edit",
                  selectedDate: String(event.datetime),
                  viewIds: event.views_ids as string[],
                })
              }
            />
            <IconButton
              name="Delete"
              onClick={() => setDeleteConfirmation(true)}
            />
          </Flex>
        </Flex>
      ) : (
        <Flex alignItems="center">
          {event?.iconName ? (
            <StyledIconWrapper>
              <ConnectorIcon size={12} name="Facebook" />
            </StyledIconWrapper>
          ) : (
            <></>
          )}

          <Text
            ellipsis
            noWrap
            variant="body10Regular"
            color={theme.colors.text90}
          >
            {event?.[eventType] as ReactNode}
          </Text>
        </Flex>
      )}
    </>
  );
};
