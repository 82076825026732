import styled from "styled-components";

import { theme } from "../../../icecube-ux";
import { typographyStylesMap } from "../../../icecube-ux/Typography/typographyStylesMap";

export const StyledOnboardingFormCallSlide = styled.div`
  display: flex;
`;

export const StyledOnboardingFormCallSlideFormPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  height: 100vh;
  position: relative;
  width: 60%;
  background-color: ${theme.colors.bgLight30};
`;

export const StyledOnboardingFormCallSlideCallPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  width: 40%;
  min-height: 100vh;
  background-color: ${theme.colors.bgLight10};
  padding: 24px 46px 0 46px;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const StyledOnboardingFormCallSlideHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 16px;

  @media screen and (min-width: 1440px) {
    padding-top: 24px;
  }
`;

export const StyledOnboardingFormCallSlideHeader = styled.div`
  width: 480px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledOnboardingFormCallSlideHeaderTitleWrapper = styled.div`
  @media screen and (max-width: 1024px) {
    text-align: center;
  }
`;

export const StyledOnboardingFormCallSlideActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (max-width: 1024px) {
    align-items: center;
  }
`;

export const StyledOnboardingFormCallSlideBookingButtonWrapper = styled.div`
  display: none;
  max-width: 100%;
  @media screen and (max-width: 1024px) {
    display: block;
  }
`;

export const StyledOnboardingFormCallSlideCallPanelEmbed = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledOnboardingFormLinkButton = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;

  ${typographyStylesMap.body14SemiBold};
  color: ${theme.colors.primary90};
  text-decoration: underline;
  cursor: pointer;
`;
