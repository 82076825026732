import React from "react";
import "react-notifications/lib/notifications.css";
import { Route, Outlet } from "react-router-dom";

import { ProvideDashboards } from "../../hooks/dashboards";
import { ProvideKISections } from "../../hooks/kiSections";
import { ProvideSchedules } from "../../hooks/schedules";
import { ProvideAnnotations } from "../../hooks/useAnnotationPopup";
import { ProvideAudience } from "../../pages/audience/context";
import { ProvideChats } from "../../shared/AskPolar/Provider/useChats";

const CreativePulse = React.lazy(
  () => import("../../pages/creativePulse/CreativePulse"),
);

const InventoryPlanning = React.lazy(
  () => import("../../pages/inventoryPlanning/InventoryPlanning"),
);

const MetricRecipe = React.lazy(
  () => import("../../pages/metrics/MetricRecipe"),
);

const Acquisition = React.lazy(
  () => import("../../pages/acquisition/Acquisition"),
);
const AcquisitionFreeTrialMarketingPage = React.lazy(
  () =>
    import("../../pages/freeTrialMarketing/AcquisitionFreeTrialMarketingPage"),
);
const ProductsFreeTrialMarketingPage = React.lazy(
  () => import("../../pages/freeTrialMarketing/ProductsFreeTrialMarketingPage"),
);
const SubscriptionsFreeTrialMarketingPage = React.lazy(
  () =>
    import(
      "../../pages/freeTrialMarketing/SubscriptionsFreeTrialMarketingPage"
    ),
);
const EngagementFreeTrialMarketingPage = React.lazy(
  () =>
    import("../../pages/freeTrialMarketing/EngagementFreeTrialMarketingPage"),
);
const CustomReportsFreeTrialMarketingPage = React.lazy(
  () =>
    import(
      "../../pages/freeTrialMarketing/CustomReportsFreeTrialMarketingPage"
    ),
);
const AlertsFreeTrialMarketingPage = React.lazy(
  () => import("../../pages/freeTrialMarketing/AlertsFreeTrialMarketingPage"),
);
const AdvancedFeaturesMarketingPage = React.lazy(
  () => import("../../pages/freeTrialMarketing/AdvancedFeaturesMarketingPage"),
);
const KeyIndicators = React.lazy(
  () => import("../../pages/key-indicators/KeyIndicators"),
);
const Setup = React.lazy(() => import("../../pages/setup/Setup"));
const Products = React.lazy(() => import("../../pages/products/Products"));
const Engagement = React.lazy(
  () => import("../../pages/engagement/Engagement"),
);
const AskPolarHome = React.lazy(
  () => import("../../pages/askPolar/AskPolarHome"),
);
const AskPolarChat = React.lazy(
  () => import("../../pages/askPolar/AskPolarChat"),
);
const Retention = React.lazy(() => import("../../pages/retention/Retention"));
const Subscriptions = React.lazy(
  () => import("../../pages/subscriptions/Subscriptions"),
);
const Alerts = React.lazy(() => import("../../pages/alerts/Alerts"));
const CreateCustomReport = React.lazy(
  () => import("../../pages/custom/CreateCustomReport"),
);
const SectionReportEdit = React.lazy(
  () => import("../../pages/acquisition/blocks/SectionReportEdit"),
);
const CustomList = React.lazy(
  () => import("../../pages/custom/ListCustomReport"),
);
const GenerateCustomReportsPage = React.lazy(
  () => import("../../pages/custom/GenerateCustomReportsPage"),
);
const ActivatePage = React.lazy(() => import("../../pages/audience/Audience"));
const PlanActivatedPopup = React.lazy(
  () => import("../../shared/Plans/analyzeEnrichPlan/PlanActivatedPopup"),
);
const RetentionFreeTrialMarketingPage = React.lazy(
  () =>
    import("../../pages/freeTrialMarketing/RetentionFreeTrialMarketingPage"),
);

type WrappedWithProps = {
  Component: (args: { children: React.ReactNode }) => JSX.Element;
};

const WrappedWith = ({ Component }: WrappedWithProps) => {
  return (
    <Component>
      <Outlet />
    </Component>
  );
};

const ProvideSearch = ({ children }: { children: React.ReactNode }) => (
  <ProvideChats>
    <ProvideDashboards>{children}</ProvideDashboards>
  </ProvideChats>
);

export const LoggedInRoutes = (
  <>
    <Route element={<WrappedWith Component={ProvideSearch} />}>
      <Route path="/keyIndicators" element={<KeyIndicators />} />
      <Route
        path="/keyIndicators/dashboard/:dashboardId/list"
        element={<KeyIndicators />}
      />
      <Route path="/setup" element={<Setup />} />
      <Route path="/acquisition" element={<Acquisition />} />
      <Route path="/creative-studio" element={<CreativePulse />} />
      <Route path="/inventory-planning" element={<InventoryPlanning />} />
      <Route path="/products" element={<Products />} />
      <Route path="/engagement" element={<Engagement />} />
      <Route path="/retention" element={<Retention />} />
      <Route path="/subscriptions" element={<Subscriptions />} />

      <Route element={<WrappedWith Component={ProvideKISections} />}>
        <Route element={<WrappedWith Component={ProvideSchedules} />}>
          <Route path="/alerts" element={<Alerts />} />
          <Route path="/custom/create/:id" element={<CreateCustomReport />} />
          <Route path="/custom/dashboard/:id/list" element={<CustomList />} />
          <Route path="/custom/list" element={<CustomList />} />
          <Route>
            <Route path="/custom/create" element={<CreateCustomReport />} />
            <Route
              path="/custom/create/dashboard/:dashboardId"
              element={<CreateCustomReport />}
            />
          </Route>
        </Route>
      </Route>

      <Route path="/report/edit/:reportKey" element={<SectionReportEdit />} />

      <Route
        path="/metric/:metricId"
        element={
          <ProvideAnnotations>
            <MetricRecipe />
          </ProvideAnnotations>
        }
      />

      <Route
        path="/activate"
        element={
          <ProvideAudience>
            <ActivatePage />
          </ProvideAudience>
        }
      />
    </Route>

    <Route element={<WrappedWith Component={ProvideChats} />}>
      <Route path="/ask" element={<AskPolarHome />} />
      <Route path="/ask/:chatId" element={<AskPolarChat />} />
    </Route>

    <Route path="/enrichPlanActivated" element={<PlanActivatedPopup />} />

    <Route
      path="/acquisition/freeTrial"
      element={<AcquisitionFreeTrialMarketingPage />}
    />
    <Route
      path="/retention/freeTrial"
      element={<RetentionFreeTrialMarketingPage />}
    />
    <Route
      path="/products/freeTrial"
      element={<ProductsFreeTrialMarketingPage />}
    />
    <Route
      path="/subscriptions/freeTrial"
      element={<SubscriptionsFreeTrialMarketingPage />}
    />
    <Route
      path="/engagement/freeTrial"
      element={<EngagementFreeTrialMarketingPage />}
    />
    <Route
      path="/custom/freeTrial"
      element={<CustomReportsFreeTrialMarketingPage />}
    />
    <Route path="/custom/generate" element={<GenerateCustomReportsPage />} />
    <Route
      path="/alerts/freeTrial"
      element={<AlertsFreeTrialMarketingPage />}
    />
    <Route
      path="/advancedFeatures"
      element={<AdvancedFeaturesMarketingPage />}
    />
  </>
);
