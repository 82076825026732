import classNames from "classnames";

import { Flex } from "../Flex";
import { IconNames } from "../Icon/Icon";
import { legacyTheme } from "../legacyTheme";
import { ClassAndStyleProps } from "../shared";
import { Text } from "../Typography";

import { StyledCallout, StyledCalloutIcon } from "./Callout.styled";

export type CalloutProps = ClassAndStyleProps & {
  text: React.ReactNode;
  icon: IconNames;
};

export default function Callout({
  text,
  className,
  style,
  ...otherProps
}: CalloutProps) {
  return (
    <StyledCallout className={classNames(className)} style={style}>
      <Flex alignItems="center" gap={8}>
        <StyledCalloutIcon name={otherProps.icon} size={14} />
        <Text
          color={legacyTheme.colors.primary1}
          variant="body10Regular"
          textAlign="center"
        >
          {text}
        </Text>
      </Flex>
    </StyledCallout>
  );
}
