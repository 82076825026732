import classNames from "classnames";
import React, { forwardRef } from "react";

import { ClassAndStyleProps, InteractionEvents } from "../shared";

import { StyledBlock } from "./Block.styled";

export type BlockProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    children?: React.ReactNode;
    asHTMLTag?: string;
    padding?:
      | "tiny"
      | "small"
      | "regular"
      | "large"
      | "xlarge"
      | "xxlarge"
      | "x3large"
      | "x4large"
      | "x5large";
    variant?: "normal" | "warning" | "info";
  };

export default forwardRef(function Block(
  {
    className,
    style,
    children,
    asHTMLTag,
    padding = "regular",
    variant = "normal",
    ...interactionEvents
  }: BlockProps,
  ref,
) {
  return (
    <StyledBlock
      {...interactionEvents}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
      as={asHTMLTag as any}
      className={classNames(className, `padding-${padding}`)}
      variant={variant}
      style={style}
      ref={ref}
    >
      {children}
    </StyledBlock>
  );
});
