import React from "react";
import "../global.css";
import "./tag.css";

export default function Tag({
  children,
  className = "",
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) {
  return <div className={"tag " + className}>{children}</div>;
}
