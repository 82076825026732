import styled from "styled-components";

import { theme } from "../theme";

export const StyledHorizontalCard = styled.section`
  border: solid 1px ${theme.colors.borderLight};
  border-radius: ${theme.borderRadius.r16px};
  overflow: hidden;
  min-width: 100%;
`;

export const StyledHorizontalCardMain = styled.section`
  display: flex;
  align-items: stretch;
  height: 90px;
`;

export const StyledHorizontalCardImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 146px;
  min-height: 60px;
  flex-shrink: 0;
  background-color: ${theme.colors.bgLight20};
`;

export const StyledHorizontalCardImage = styled.img`
  max-height: 90%;
`;

export const StyledHorizontalCardMainContent = styled.section`
  flex-grow: 1;
`;

export const StyledHorizontalCardExpandedContent = styled.section`
  border-top: solid 1px ${theme.colors.borderLight};
`;
