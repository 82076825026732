import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { OnboardingFormData } from "../common/types/users-service";
import { updateTenantState } from "../lib/usersService";
import { onboardingQuestionKeys } from "../pages/onboardingForm/onboardingQuestions";
import { TRACKING_EVENTS, trackEvent } from "../utils/trackingUtils";

import { useAuth } from "./auth/auth";
import { useBootstrap } from "./bootstrap";

type OpenCallBookingOptions = {
  /**
   * Requires #revenue-hero-embed to be present in the DOM
   */
  embed?: boolean;
  onboardingForm?: OnboardingFormData;
  greetingText?: string;
  overriddenEmail?: string;
};
export type RevenueHeroMeetingTypeID = "2915" | "2593" | "5177";
const REVENUE_HERO_IDS = {
  CAPI: "723",
  SETUP: "827",
  CUSTOM_CONNECTORS: "869",
  AUDIENCE_SETUP: "1411",
};

const REVENUE_HERO_MEETING_TYPE_ID_STATE_KEY_MAP: Record<
  RevenueHeroMeetingTypeID,
  string
> = {
  "2915": "hasBookedCall",
  "2593": "hasBookedCAPICall",
  "5177": "hasBookedAudienceFlowSetupCall",
};

declare const dataLayer: Record<string, unknown>[];

type REVENUE_HERO_ID_VALUES =
  (typeof REVENUE_HERO_IDS)[keyof typeof REVENUE_HERO_IDS];

export const useCallBooking = () => {
  const { user, isOutboundDemo } = useAuth();
  const { tenant } = useBootstrap();
  const [isLoading, setIsLoading] = useState<boolean | undefined>();

  const openCallBooking = useCallback(
    async (
      routerId: REVENUE_HERO_ID_VALUES,
      options?: OpenCallBookingOptions,
    ) => {
      if (isOutboundDemo) {
        window.open("https://www.polaranalytics.com/contact", "_blank");
        return;
      }

      const formData =
        options?.onboardingForm || tenant?.settings?.onboarding_form;
      setIsLoading(true);

      const hero = new RevenueHero({
        routerId,
        ...{ greetingText: options?.greetingText },
      });
      const data = await hero.submit({
        email: options?.overriddenEmail ?? user?.email,
        reported_gmv: formData?.responses.find(
          (response) => response.questionKey === onboardingQuestionKeys.gmv,
        )?.selection[0] as string,
        reported_region: formData?.responses.find(
          (response) => response.questionKey === onboardingQuestionKeys.region,
        )?.selection[0] as string,
      });
      if (options?.embed) {
        hero.dialog.setEmbedTarget("#revenue-hero-embed");
        document.querySelector("#revenue-hero-embed") && hero.dialog.open(data);
      } else {
        hero.dialog.open(data);
      }
      setIsLoading(false);
    },
    [tenant?.settings?.onboarding_form, user?.email, isOutboundDemo],
  );
  const openSetupCallBooking = async (options?: OpenCallBookingOptions) =>
    await openCallBooking(REVENUE_HERO_IDS.SETUP, options);
  const openAudienceSetupCallBooking = async (
    options?: OpenCallBookingOptions,
  ) => await openCallBooking(REVENUE_HERO_IDS.AUDIENCE_SETUP, options);
  const openCAPICallBooking = async (options?: OpenCallBookingOptions) =>
    await openCallBooking(REVENUE_HERO_IDS.CAPI, options);
  const openCustomConnectorCallBooking = async (
    options?: OpenCallBookingOptions,
  ) => await openCallBooking(REVENUE_HERO_IDS.CUSTOM_CONNECTORS, options);

  return {
    openAudienceSetupCallBooking,
    openSetupCallBooking,
    openCAPICallBooking,
    openCustomConnectorCallBooking,
    isLoading,
  };
};

export const getTenantStateMeetingKey = (id: RevenueHeroMeetingTypeID) => {
  return REVENUE_HERO_MEETING_TYPE_ID_STATE_KEY_MAP?.[id] ?? "hasBookedCall";
};

type CallEventData =
  | {
      type?: "UNKNOWN_TYPES"; // This enforces we check the type
    }
  | {
      type?: "CLOSE_DIALOG";
      // There might be more fields here, didn't investigate
    }
  | {
      type: "MEETING_BOOKED";
      meeting?: {
        relationships?: {
          meeting_type?: {
            data?: {
              id?: string;
            };
          };
        };
      };
    };

export const useCallEventListener = (
  asyncListener:
    | ((e: MessageEvent<CallEventData>) => Promise<void>)
    | ((e: MessageEvent<CallEventData>) => void),
  dependencyArray: unknown[],
) => {
  useEffect(() => {
    const listener = (e: MessageEvent<CallEventData>) => void asyncListener(e);
    window.addEventListener("message", listener);
    return () => window.removeEventListener("message", listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyArray);
};

export const useGlobalCallBookingListener = () => {
  const auth = useAuth();
  const { forceRefresh, tenant } = useBootstrap();
  const navigate = useNavigate();

  useCallEventListener(
    async ({ data }: MessageEvent<CallEventData>) => {
      if (data.type === "MEETING_BOOKED" && !auth.isOutboundDemo) {
        const meetingTypeId = data?.meeting?.relationships?.meeting_type?.data
          ?.id as RevenueHeroMeetingTypeID;
        await updateTenantState(
          await auth.getToken(),
          getTenantStateMeetingKey(meetingTypeId),
          true,
        );
        await forceRefresh();
        const gmv = tenant.settings?.onboarding_form?.responses?.find(
          (response) => response.questionKey === onboardingQuestionKeys.gmv,
        )?.selection[0];
        const region = tenant.settings?.onboarding_form?.responses?.find(
          (response) => response.questionKey === onboardingQuestionKeys.region,
        )?.selection[0];
        const goals = tenant.settings?.onboarding_form?.responses?.find(
          (response) => response.questionKey === onboardingQuestionKeys.goals,
        )?.selection[0];
        trackEvent(TRACKING_EVENTS.SETUP_CALL_BOOKED, {
          tenantId: auth.user?.tenantId,
          email: auth.user?.email,
          gmv,
          region,
          goals,
        });
        try {
          dataLayer?.push({
            event: "meeting_booked",
            gmv,
          });
        } catch (_e) {
          // Ignore
        }
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("call-booked", "");
        navigate({ search: queryParams.toString() }, { replace: true });
      }
    },
    [auth.isLoggedIn],
  );
};
