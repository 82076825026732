import React, { useCallback, useState } from "react";

import { useResizeObserver } from "../hooks/useResizeObserver";

import { FullWidthDiv } from "./FullWidthWrapper.styled";

interface FullWidthWrapperProps {
  height: number;
  children: React.ReactElement;
  className?: string;
  onStyle?: boolean;
}

export const FullWidthWrapper = ({
  height,
  children,
  className = "",
}: FullWidthWrapperProps) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const { width } = useResizeObserver(container);

  const containerRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setContainer(node);
    }
  }, []);

  return (
    <FullWidthDiv ref={containerRef} className={className}>
      {React.cloneElement(children, {
        width,
        height: height,
        style: { width: `${width}px`, height: `${height}px` },
      })}
    </FullWidthDiv>
  );
};
