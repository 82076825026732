import { useState } from "react";

import Icon, { IconNames } from "../../Icon/Icon";
import { ClassAndStyleProps, InteractionEvents } from "../../shared";
import { theme } from "../../theme";
import { Text } from "../../Typography/Typography";

import { StyledExampleLayout, StyledLeftIcon } from "./ExampleLayout.styled";

export type ExampleLayoutProps = InteractionEvents<HTMLDivElement> &
  ClassAndStyleProps & {
    question: string;
    leftIcon: IconNames;
    rightIcon: IconNames;
  };

const ExampleLayout = ({
  question,
  leftIcon,
  rightIcon,
  ...interactionEvents
}: ExampleLayoutProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <StyledExampleLayout
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={interactionEvents.onClick}
    >
      <StyledLeftIcon isHovered={isHovered}>
        <Icon name={leftIcon} color={theme.colors.primary100} size={20} />
      </StyledLeftIcon>
      <Text variant="body12Regular" color={theme.colors.text100}>
        {question}
      </Text>
      <Icon
        name={rightIcon}
        size={22}
        color={theme.colors.primary80}
        style={{ visibility: isHovered ? "visible" : "hidden" }}
      />
    </StyledExampleLayout>
  );
};

export default ExampleLayout;
