import { regionMappings } from "./regionMappings";

export const goalOptions = {
  activate: {
    key: "activate",
    label: "Increase Klaviyo abandonment flow revenue",
  },
  optimiseMarketing: {
    key: "optimise-marketing",
    label:
      "Attribute conversions and improve advertising efficiency|||infinitive",
  },
  bigPicture: {
    key: "big-picture",
    label: "Keep an eye on the big picture of the business|||infinitive",
  },
  ltvRetention: {
    key: "ltv-retention",
    label: "Calculate LTV and monitor retention|||infinitive",
  },
  optimiseInventory: {
    key: "optimise-inventory",
    label: "Optimise merchandising and inventory|||infinitive",
  },
  // Those 2 are kept for retrocompatibility, but not used anymore
  automateReporting: {
    key: "automate-reporting",
    label: "Speed up or automate my regular reporting|||infinitive",
  },
  quickAdHocQuestions: {
    key: "quick-ad-hoc-questions",
    label: "Answer ad-hoc data questions quickly|||infinitive",
  },
} as const;
export type GoalOption = (typeof goalOptions)[keyof typeof goalOptions]["key"];

export const onboardingQuestionKeys = {
  goals: "goals",
  gmv: "reported_gmv",
  region: "reported_region",
  callBooking: "call_booking",
};
type OnboardingQuestionBase = {
  key: string;
};

export type OnboardingFormQuestion = OnboardingQuestionBase & {
  slideType: "question";
  inputType: "checkbox" | "radio";
  title: string;
  question: string;
  options: {
    key: string;
    label: string;
  }[];
  tryGet?: () => string | undefined;
};

type OnboardingFormCallBooking = OnboardingQuestionBase & {
  slideType: "callBooking";
};

type OnboardingQuestion = OnboardingFormQuestion | OnboardingFormCallBooking;

export const onboardingQuestions: OnboardingQuestion[] = [
  {
    key: onboardingQuestionKeys.goals,
    slideType: "question",
    inputType: "radio",
    title: "Help us customize the app for you",
    question: "What would you like to achieve with Polar?",
    options: [
      goalOptions.activate,
      goalOptions.optimiseMarketing,
      goalOptions.bigPicture,
      goalOptions.ltvRetention,
      goalOptions.optimiseInventory,
    ],
  },
  {
    key: onboardingQuestionKeys.gmv,
    slideType: "question",
    inputType: "radio",
    title: "Help us customize the app for you",
    question: "What is your Annual Gross Merchandise Volume (GMV)?",
    options: [
      {
        key: "<1m",
        label: "Less than $1M",
      },
      {
        key: "1mto5m",
        label: "$1M to $5M",
      },
      {
        key: "5mto10m",
        label: "$5M to $10M",
      },
      {
        key: "10mto20m",
        label: "$10M to $20M",
      },
      {
        key: ">20m",
        label: "More than $20M",
      },
      {
        key: "agency",
        label: "Other (agency)",
      },
    ],
  },
  {
    key: onboardingQuestionKeys.region,
    slideType: "question",
    inputType: "radio",
    title: "Help us customize the app for you",
    question: "Which region are you in?",
    options: [
      {
        key: "north-america",
        label: "North America",
      },
      {
        key: "europe",
        label: "Europe",
      },
      {
        key: "asia-pacific",
        label: "Asia-Pacific",
      },
    ],
    tryGet: () => {
      const countryCode = (navigator.language || navigator.languages[0])
        ?.slice(-2)
        .toUpperCase();

      return regionMappings[countryCode];
    },
  },
  {
    key: onboardingQuestionKeys.callBooking,
    slideType: "callBooking",
  },
];
