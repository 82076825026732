import { axisBottom, range, scaleBand, select } from "d3";
import moment from "moment";
import { memo, useLayoutEffect, useRef } from "react";
import styled from "styled-components";

import { DateRange, noTz } from "../../dateUtils";
import { theme } from "../../theme";

import { usePosition } from "./PositionProvider";

interface XAxisProps {
  width: number;
  height: number;
  tickCount: number;
  forceTickCount?: number;
  margin: [number, number, number, number];
  dateRange?: DateRange;
  dates?: string[];
  fontSize?: number;
  shortDateFormat?: boolean;
  useNoTz?: boolean;
  tickSize?: number;
  xAxisLabels?: string[];
}
const ChartBottomAxis = styled.g`
  text {
    font-family: Poppins;
    font-weight: 300;
    line-height: 160%;
  }
  .domain {
    color: ${theme.colors.borderLight};
  }
  .tick {
    color: ${theme.colors.borderLight};
  }
`;

export const XAxis = memo(function XAxis({
  width,
  height,
  tickCount,
  forceTickCount,
  margin,
  fontSize = 11,
  dates,
  shortDateFormat,
  useNoTz = true,
  tickSize = 3,
  xAxisLabels,
}: XAxisProps) {
  const axisRef = useRef(null);
  const position = usePosition();

  useLayoutEffect(() => {
    const x = scaleBand<number>()
      .domain(range(tickCount + 1))
      .range([margin[3], width - margin[1]]);

    const maxtick = width / (fontSize * 12);
    const spread = forceTickCount
      ? Math.ceil((tickCount + 1) / forceTickCount)
      : tickCount + 1 < maxtick
      ? 1
      : Math.floor((tickCount + 1) / maxtick);

    const xAxis = axisBottom<number>(x)
      .tickValues(range(tickCount + 1).filter((d) => d % spread === 0))
      .tickSize(tickSize)
      .tickSizeOuter(0)
      .tickFormat((d, index) => {
        if (dates) {
          return useNoTz
            ? noTz(dates[d]).format(shortDateFormat ? "MMM D" : "MMM D, YY")
            : moment(dates[d])
                .utc()
                .format(shortDateFormat ? "MMM D" : "MMM D, YY");
        }

        const label: string | undefined = xAxisLabels?.[index];
        if (label && label.length < 12) {
          return label;
        }

        if (label) {
          const truncatedLabel = label
            .slice(0, 12)
            .replace(/[|] *$/, "")
            .trim();
          return label.length > 12 ? truncatedLabel + "..." : label;
        }

        return `${d}`;
      });

    select(axisRef.current)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
      .call(xAxis as any)
      .selectAll("text")
      .attr(
        "color",
        position.index !== undefined
          ? theme.colors.text90
          : theme.colors.text80,
      );
    select(axisRef.current).selectAll("line").attr("stroke-linecap", "round");
    select(axisRef.current).selectAll("path").attr("stroke-linecap", "round");
  }, [
    tickCount,
    tickSize,
    position.index,
    forceTickCount,
    width,
    margin,
    dates,
    useNoTz,
    fontSize,
    shortDateFormat,
    xAxisLabels,
  ]);

  return (
    <ChartBottomAxis
      style={{ fontSize }}
      transform={`translate(0, ${height - margin[2]})`}
      ref={axisRef}
    />
  );
});
