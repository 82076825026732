import classNames from "classnames";

import { ClassAndStyleProps, InteractionEvents } from "../shared";

import { Counters } from "./Counter.styled";

export type CounterProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    value: number | string;
    disabled?: boolean;
    variant: keyof typeof Counters;
    borderColor?: string;
  };

export default function Counter({
  className,
  style,
  value,
  disabled,
  variant,
  borderColor,
  ...interactionEvents
}: CounterProps) {
  const CounterVariant = Counters[variant];

  return (
    <CounterVariant
      {...interactionEvents}
      borderColor={borderColor}
      className={classNames(className, { disabled })}
      style={style}
    >
      {value}
    </CounterVariant>
  );
}
