import moment from "moment";
import objectHash from "object-hash";

export function storageMemoize<T extends Array<unknown>, R>(
  cacheKey: string,
  method: (...params: T) => Promise<R>,
): (...params: T) => Promise<R> {
  const memoized = async (...params: T) => {
    const args = [].filter.call(
      params,
      (a: unknown) => !(a instanceof AbortController),
    );

    const key = objectHash({
      time: moment().format("YYYY-MM-DD-HH"),
      ...args,
    });

    const latestCached = JSON.parse(localStorage.getItem(cacheKey) || "{}") as {
      key?: string;
      value?: R;
    };
    if (
      latestCached.key &&
      latestCached.key === key &&
      latestCached.value !== undefined &&
      localStorage.getItem("disable-query-cache") === null
    ) {
      return Promise.resolve(latestCached.value);
    }

    return method(...params).then((data) => {
      try {
        localStorage.setItem(cacheKey, JSON.stringify({ key, value: data }));
      } catch (e) {
        console.warn("Could not save data to cache:", e);
      }
      return data;
    });
  };

  return memoized;
}
