import { useState } from "react";

import { useCustomElementEditors } from "../../../../hooks/customElementEditors";
import { useModals } from "../../../../hooks/modals";
import {
  Box,
  Button,
  Flex,
  Grid,
  theme,
  useSortableGrid,
  VerticalActionButton,
} from "../../../../icecube-ux";
import { _ } from "../../../../languages/helper";
import {
  CustomDimension,
  CustomDimensionThenBlock,
  CustomDimensionWhenBlock,
} from "../../types";

import FormulaThenElseBlock from "./FormulaThenElseBlock";
import FormulaWhenBlock from "./FormulaWhenBlock";
import { DimensionAvailabilities } from "./utils";

interface WhenThenElseProps {
  dimension: CustomDimension;
  onChange: (newDimension: CustomDimension) => void;
  availableBreakdownsForThenElse?: DimensionAvailabilities;
}

export default function WhenThenElse({
  dimension,
  onChange,
  availableBreakdownsForThenElse,
}: WhenThenElseProps) {
  const { disableEdit } = useCustomElementEditors();
  const { confirm } = useModals();

  const [editingId, setEditingId] = useState(0);

  const handleReorder = (
    _: Array<string | number>,
    from: number,
    to: number,
  ) => {
    if (to === from) {
      return;
    }

    const temp = dimension.whenThen.splice(from, 1);
    dimension.whenThen.splice(to, 0, temp[0]);
    onChange({ ...dimension });
    setEditingId(to);
  };

  const handleAddWhenThenBlock = () => {
    dimension.whenThen.push({
      when: [{ lineOperator: "", dimensionKey: "", operator: "", value: "" }],
      then: [],
    });
    onChange({ ...dimension });
    setEditingId(dimension.whenThen.length - 1);
  };

  const handleRemoveWhenThenBlock = (id: number) => {
    confirm({
      title: _`Delete a block`,
      texts: [_`Are you sure you want to delete this block?`],
      danger: true,
      onConfirm: () => {
        dimension.whenThen.splice(id, 1);
        onChange({ ...dimension });
      },
    });
    setEditingId(id > 0 ? id - 1 : id);
  };

  const handleWhenBlockChange = (
    id: number,
    blocks: CustomDimensionWhenBlock[],
  ) => {
    dimension.whenThen[id].when = [...blocks];
    onChange({ ...dimension });
  };

  const handleThenBlockChange = (
    id: number,
    block: CustomDimensionThenBlock,
  ) => {
    dimension.whenThen[id].then = block;
    onChange({ ...dimension });
  };

  const handleElseBlockChange = (block: CustomDimensionThenBlock) => {
    dimension.else = block;
    onChange({ ...dimension });
  };

  const { x, y, ref, handleMouseDown, movingIndex, toIndex } = useSortableGrid({
    seriesLength: dimension.whenThen.length,
    options:
      dimension.whenThen.map((_, id) => ({ value: id, label: `${id}` })) || [],
    onSort: handleReorder,
    direction: "row",
  });

  return (
    <Grid
      ref={ref}
      gridTemplateRows="1fr"
      gap={16}
      style={{
        userSelect: movingIndex !== null ? "none" : undefined,
        opacity: disableEdit ? 0.7 : 1,
      }}
    >
      {dimension.whenThen.map((whenThen, id) => (
        <Grid
          key={`whenThen-${id}`}
          gridTemplateColumns={
            dimension.whenThen.length > 1 ? "auto 1fr auto" : "1fr"
          }
          gap={12}
          style={
            toIndex === id
              ? {
                  border: "2px solid " + theme.colors.borderLight + "90",
                  borderRadius: "6px",
                }
              : {
                  opacity: movingIndex === id ? "0.5" : 1,
                }
          }
        >
          {dimension.whenThen.length > 1 && (
            <VerticalActionButton
              icon="Dragger"
              onMouseDown={() => !disableEdit && handleMouseDown(id)}
            />
          )}
          <Flex
            flexDirection="column"
            gap={16}
            style={{
              cursor: editingId !== id ? "pointer" : undefined,
              opacity: editingId !== id ? 0.7 : undefined,
            }}
            onClick={() => setEditingId(id)}
          >
            <FormulaWhenBlock
              editing={editingId === id}
              blocks={whenThen.when}
              onChange={(newBlock) => handleWhenBlockChange(id, newBlock)}
            />
            <FormulaThenElseBlock
              editing={editingId === id}
              title={_`Then`}
              block={whenThen.then}
              onChange={(newBlock) => handleThenBlockChange(id, newBlock)}
              availableBreakdownsForThenElse={availableBreakdownsForThenElse}
            />
          </Flex>
          {dimension.whenThen.length > 1 && (
            <VerticalActionButton
              icon="Delete"
              danger
              onClick={() => !disableEdit && handleRemoveWhenThenBlock(id)}
            />
          )}
        </Grid>
      ))}
      {!disableEdit && (
        <Box marginLeft={dimension.whenThen.length > 1 ? "34px" : undefined}>
          <Button
            leftIcon="Add"
            color="secondary"
            onClick={handleAddWhenThenBlock}
          >
            {_`Add another When-Then block`}
          </Button>
        </Box>
      )}
      <Box marginLeft={dimension.whenThen.length > 1 ? "34px" : undefined}>
        <FormulaThenElseBlock
          editing
          title={_`Else`}
          block={dimension.else}
          onChange={handleElseBlockChange}
          availableBreakdownsForThenElse={availableBreakdownsForThenElse}
        />
      </Box>

      {movingIndex !== null && (
        <Box
          position="fixed"
          borderRadius="6px"
          height="130px"
          width="60vw"
          left={`${x}px`}
          top={`${y}px`}
          background={theme.colors.bgLight30 + "D0"}
        />
      )}
    </Grid>
  );
}
