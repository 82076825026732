import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import { Flex } from "../../Flex";
import useCombinedRefs from "../../hooks/useCombinedRefs";
import { legacyTheme } from "../../legacyTheme";
import { theme } from "../../theme";

interface AutoGrowInputProps {
  autoFocus?: boolean;
  value: string;
  style?: React.CSSProperties;
  onChange: (value: string) => void;
  onCursorMove?: (
    left: boolean,
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => void;
  onComplete?: (withTabKey: boolean) => void;
  onDelete?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onPressUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onPressDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onPaste?: React.ClipboardEventHandler<HTMLInputElement>;
}

const StyledAutoGrowInput = styled.input`
  font-family: ${legacyTheme.typography.default.fontFamily};
  line-height: 18px;
  border: 0;
  outline: 0;
  color: ${theme.colors.text100};
  font-weight: normal;
  font-size: 12px;
  width: auto;
  background: transparent;
  padding: 0;

  &:focus {
    outline: none !important;
  }
`;

const StyledAutoGrowSpan = styled.span`
  font-family: ${legacyTheme.typography.default.fontFamily};
  line-height: 18px;
  border: 0;
  outline: 0;
  color: ${theme.colors.text100};
  font-weight: normal;
  font-size: 12px;
  width: auto;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  pointer-events: none;
  white-space: pre;
`;

const AutoGrowInput = React.forwardRef<HTMLInputElement, AutoGrowInputProps>(
  (
    {
      autoFocus = false,
      value,
      onCursorMove,
      onChange,
      onBlur,
      onFocus,
      onComplete,
      onDelete,
      onPressUp,
      onPressDown,
      onPaste,
      style,
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const combinedRef = useCombinedRefs(ref, inputRef);
    const spanRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
      if (combinedRef.current && spanRef.current) {
        combinedRef.current.style.width = `${Math.max(
          2,
          spanRef.current.getBoundingClientRect().width,
        )}px`;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
      <Flex flexDirection="row" alignItems="center" style={style}>
        <StyledAutoGrowInput
          ref={combinedRef}
          autoFocus={autoFocus}
          value={value}
          onPaste={onPaste}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={(e) => onChange(e.target.value)}
          onKeyDown={(event) => {
            if (event.key === "ArrowDown") {
              event.preventDefault();
              event.stopPropagation();
              onPressDown && onPressDown(event);
              return false;
            }
            if (event.key === "ArrowUp") {
              event.preventDefault();
              event.stopPropagation();
              onPressUp && onPressUp(event);
              return false;
            }
            if (event.key === "Enter" || event.key === "Tab") {
              onComplete && onComplete(event.key === "Tab");
            }
            if (event.key === "Backspace" && value.length === 0) {
              onDelete && onDelete();
            }
            if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
              onCursorMove && onCursorMove(event.key === "ArrowLeft", event);
            }
          }}
        />
        <StyledAutoGrowSpan ref={spanRef}>{value}</StyledAutoGrowSpan>
      </Flex>
    );
  },
);

export default AutoGrowInput;
