import classNames from "classnames";

import { Icon } from "../Icon";
import { IconNames } from "../Icon/Icon";
import { ClassAndStyleProps, InteractionEvents } from "../shared";

import { StyledVerticalActionButton } from "./VerticalActionButton.styled";

export type VerticalActionButtonProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & { icon: IconNames; danger?: boolean };

export default function VerticalActionButton({
  icon,
  danger,
  className,
  style,
  ...interactionEvents
}: VerticalActionButtonProps) {
  return (
    <StyledVerticalActionButton
      danger={danger}
      {...interactionEvents}
      className={classNames(className)}
      style={style}
    >
      <Icon name={icon} size={18} />
    </StyledVerticalActionButton>
  );
}
