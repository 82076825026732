import * as d3 from "d3";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";

import { getTooltipDateFormatter } from "../../utils/dateUtils";
import { DebouncedFunction, EMPTY_ARRAY } from "../../utils/utils";
import { DateRange } from "../dateUtils";

import { BarLineChartStyled } from "./BarLineCharts.styled";
import { Annotations, AnnotationsData } from "./components/Annotations";
import BarCollection from "./components/BarCollection";
import { getDomainData } from "./components/chartUtils";
import EventReceiver from "./components/EventReceiver";
import Grid from "./components/Grid";
import Line from "./components/Line";
import {
  PositionContext,
  usePosition,
  withPosition,
} from "./components/PositionProvider";
import {
  AreaDataPoint,
  flattenSeries,
  hashSeries,
  Serie,
  SerieCollection,
  SerieFocusState,
} from "./components/Serie";
import SingleSerieAsBars from "./components/SingleSerieAsBars";
import StackedBars from "./components/StackedBars";
import StackedLines from "./components/StackedLines";
import Tooltip from "./components/Tooltip";
import TooltipContent, {
  DefaultTooltipContentProps,
} from "./components/TooltipContent";
import { VerticalLine } from "./components/VerticalLine";
import { XAxis } from "./components/XAxis";
import YAxis from "./components/YAxis";

interface Axis {
  tickCount?: number;
  fontSize?: number;
  label?: string;
  highlightValue?: number;
}

interface XAxisDefinition {
  withTicks: boolean;
  shortDateFormat?: boolean;
  fontSize?: number;
  tickCount?: number;
  tickSize?: number;
}

interface TooltipProps {
  tooltipTitle: string;
  data: Serie<number | string | AreaDataPoint>[];
  valueIndex: number;
}

export interface BarLineChartsProps {
  dateRange?: DateRange;
  width: number;
  height: number;
  series: (Serie | Serie<AreaDataPoint> | SerieCollection)[];
  onChangeFocusState?: (serieName: string, focusState: SerieFocusState) => void;
  xAxisValues?: string[];
  xAxisTooltipLegend?: string;
  leftAxis: Axis;
  leftAxisDomainIndex?: number;
  setYAxisLeftWidthForParent?: (value: number) => void;
  rightAxis?: Axis;
  extraYAxis?: Array<{
    values: number[];
    formatter?: (value: number, index: number) => string;
  }>;
  margin?: [number, number, number, number];
  tickSize?: number;
  leftAxisFormatter?: (value: number, index: number) => string;
  rightAxisFormatter?: (value: number, index: number) => string;
  dates?: string[];
  useNoTz?: boolean;
  forcedBarWidth?: number;
  customTooltipValues?: Serie<string>[];
  xAxis?: XAxisDefinition;
  xAxisLabels?: string[];
  onIndexHoverChange?: (index: number | undefined) => void;
  withTooltip?: boolean;
  shownInModal?: boolean;
  withVerticalLine?: boolean;
  tooltipDateFormatter?: (value: string) => string;
  tooltipComponent?: (props: TooltipProps) => React.ReactNode;
  isLoading?: boolean;
  tooltipHeightVariant?: "small" | "large";
  annotations?: AnnotationsData;
  onHoverAnnotation?: DebouncedFunction<(date: unknown) => void>;
}

export default withPosition(function BarLineCharts({
  useNoTz = true,
  width,
  height,
  series = EMPTY_ARRAY,
  xAxisValues = [],
  xAxisLabels,
  xAxisTooltipLegend,
  forcedBarWidth,
  margin = [24, 6, 24, 6],
  leftAxis,
  rightAxis,
  leftAxisDomainIndex,
  extraYAxis,
  leftAxisFormatter = (v) => `${v}`,
  rightAxisFormatter = (v) => `${v}`,
  setYAxisLeftWidthForParent,
  dateRange,
  dates,
  customTooltipValues,
  shownInModal,
  xAxis = {
    withTicks: true,
  },
  withTooltip = true,
  withVerticalLine = true,
  onIndexHoverChange,
  onChangeFocusState,
  tooltipHeightVariant = "small",
  tooltipDateFormatter = getTooltipDateFormatter("none", false),
  tooltipComponent = (props: DefaultTooltipContentProps) => (
    <TooltipContent
      key={props.valueIndex ?? "no-index"}
      {...props}
    ></TooltipContent>
  ),
  isLoading,
  annotations,
  onHoverAnnotation,
}: BarLineChartsProps) {
  const chartRef = useRef<HTMLDivElement>(null);
  const [chartDimensions, setChartDimensions] = useState<DOMRect>();
  const [yAxisLeftWidth, setYAxisLeftWidthRaw] = useState(0);
  const [yAxisRightWidth, setYAxisRightWidth] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const position = usePosition();
  const [showChartElements, setShowChartElements] = useState(false);

  const setYAxisLeftWidth = useCallback(
    (value: number | ((prev: number) => number)) => {
      setYAxisLeftWidthRaw((prev) => {
        const newValue = typeof value === "function" ? value(prev) : value;
        setYAxisLeftWidthForParent?.(newValue);
        return newValue;
      });
    },
    [setYAxisLeftWidthForParent],
  );

  const frozenDates = useMemo(() => {
    return dates;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates?.join(",")]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    if (!isLoading) {
      timeout = setTimeout(
        () => {
          setShowChartElements(true);
        },
        1000 + Math.random() * 2000,
      );
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isLoading]);

  useEffect(() => {
    onIndexHoverChange?.(position.index);
  }, [onIndexHoverChange, position.index]);

  useEffect(() => {
    const chart = chartRef.current; // Cache the value so we can unobserve

    if (!chart) {
      return;
    }
    const resizeHandler = () => {
      const rect = chart?.getBoundingClientRect();
      setChartDimensions(rect);
      if (
        Boolean(rect) &&
        rect.bottom >= 0 &&
        rect.right >= 0 &&
        rect.top <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.left <= (window.innerWidth || document.documentElement.clientWidth)
      ) {
        setShowChartElements(true);
      }
    };

    document.addEventListener("scroll", resizeHandler, true);

    const resizeObserver = new ResizeObserver(resizeHandler);
    resizeObserver.observe(chart);
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.unobserve(chart);
      resizeObserver.unobserve(document.body);
      document.removeEventListener("scroll", resizeHandler, true);
    };
  }, [chartRef]);

  const [margin0, margin1, margin2, margin3] = margin;
  const getYScale = useCallback(
    (
      series: (Serie<number | AreaDataPoint> | SerieCollection)[],
      yAxisKey: "left" | "right",
    ) => {
      return d3
        .scaleLinear()
        .domain(getDomainData(series, yAxisKey))
        .range([height - margin2, margin0]);
    },
    [height, margin0, margin2],
  );

  const hashedSeries = hashSeries(series, ["values", "yAxisKey"]);
  const yScales = useMemo<
    Record<string, d3.ScaleLinear<number, number, never>>
  >(() => {
    const extraSeries: Record<
      string,
      d3.ScaleLinear<number, number, never>
    > = {};
    if (extraYAxis) {
      extraYAxis.forEach((axis, index) => {
        extraSeries[`extra-${index}`] = d3
          .scaleLinear()
          .domain([Math.min(0, ...axis.values), Math.max(...axis.values)])
          .range([height - margin2, margin0]);
      });
    }
    return {
      left: getYScale(series, "left"),
      right: getYScale(series, "right"),
      ...extraSeries,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getYScale, extraYAxis, hashedSeries]);

  const m: [number, number, number, number] = [
    margin0,
    margin1 + yAxisRightWidth,
    margin2,
    margin3 + yAxisLeftWidth,
  ];
  const centralMargin = useMemo(
    () => {
      return m;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [m.join(";")],
  );

  const xAxisTooltipFormatter = (index: number) => {
    let formatter = (d: string) => d;
    if (frozenDates) {
      formatter = tooltipDateFormatter || formatter;
    }

    const xAxisValue = xAxisLabels?.[index] ?? xAxisValues?.[index];
    return (
      (xAxisTooltipLegend || "") +
      (xAxisValue ? ` ${formatter(xAxisValue)}` : "")
    );
  };

  const totalNumberOfLines = series.reduce((sum, serie) => {
    if (serie.displayType === "line") {
      return sum + 1;
    }
    if (serie.displayType === "stacked-lines") {
      return sum + serie.segments.length || 0;
    }
    return sum;
  }, 0);

  const totalNumberOfBarSeries = series.reduce((sum, serie) => {
    if (
      serie.displayType === "single-serie-as-bars" ||
      serie.displayType === "stacked-bars" ||
      serie.displayType === "bar-collection"
    ) {
      return sum + 1;
    }

    return sum;
  }, 0);

  const maxIndex = Math.max(
    ...series.map((serie) =>
      serie.displayType === "line" ||
      serie.displayType === "single-serie-as-bars"
        ? serie.values.length
        : 0,
    ),
    ...(series.flatMap((serie) =>
      serie.displayType === "stacked-bars" ||
      serie.displayType === "stacked-lines"
        ? serie.segments.map((s) => s.values.length)
        : 0,
    ) || 0),
    ...(series?.map((serie) =>
      serie.displayType === "bar-collection" ? serie.segments.length : 0,
    ) || 0),
  );

  const xBarScale = useMemo(() => {
    return d3
      .scaleBand<number>()
      .domain(d3.range(maxIndex))
      .range([centralMargin[3], width - centralMargin[1]]);
  }, [centralMargin, width, maxIndex]);
  const barWidth =
    xBarScale.bandwidth() / (totalNumberOfBarSeries || totalNumberOfLines);

  const xLineScale = useMemo(() => {
    return d3
      .scaleLinear()
      .domain([0, maxIndex])
      .range([centralMargin[3], width - centralMargin[1]]);
  }, [centralMargin, width, maxIndex]);

  const counters = {
    barSerieIndex: 0,
  };

  return (
    <BarLineChartStyled
      ref={chartRef}
      onMouseEnter={() => {
        setIsHovered(true);
        position.setEnabled(true);
      }}
      onMouseMove={() => {
        setIsHovered(true);
        position.setEnabled(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
        position.setEnabled(false);
      }}
    >
      <svg
        style={{ width: "100%", overflow: "overlay" }}
        viewBox={`0 0 ${width} ${height}`}
      >
        {xAxis.withTicks && (
          <XAxis
            width={width}
            margin={centralMargin}
            height={height}
            tickCount={frozenDates ? frozenDates.length - 1 : maxIndex - 1}
            tickSize={xAxis.tickSize}
            forceTickCount={xAxis.tickCount}
            fontSize={xAxis.fontSize}
            xAxisLabels={xAxisLabels}
            dateRange={dateRange}
            dates={frozenDates}
            shortDateFormat={xAxis.shortDateFormat}
            useNoTz={useNoTz}
          />
        )}

        {leftAxis.tickCount && (
          <Grid
            key="grid"
            yScale={yScales.left}
            margin={centralMargin}
            height={height}
            width={width}
            tickCount={leftAxis.tickCount}
          />
        )}

        {leftAxis.tickCount && !extraYAxis && (
          <YAxis
            width={width}
            margin={centralMargin}
            height={height}
            tickCount={Math.min(10, leftAxis.tickCount)}
            alignment="left"
            fontSize={leftAxis.fontSize}
            formatter={leftAxisFormatter}
            yScale={yScales.left}
            legend={leftAxis.label}
            onWidthChange={setYAxisLeftWidth}
            highlightValue={leftAxis.highlightValue}
          />
        )}

        {leftAxis.tickCount &&
          extraYAxis &&
          extraYAxis.map((axis, domainId) => (
            <YAxis
              key={`extra_yAxis_${domainId}`}
              width={width}
              margin={centralMargin}
              height={height}
              tickCount={Math.min(10, leftAxis.tickCount ?? 0)}
              alignment="left"
              fontSize={leftAxis.fontSize}
              formatter={axis.formatter}
              yScale={yScales[`extra-${domainId}`]}
              legend={leftAxis.label}
              onWidthChange={(value) =>
                setYAxisLeftWidth((p) => (p < value ? value : p))
              }
              highlightValue={leftAxis.highlightValue}
              hidden={domainId !== leftAxisDomainIndex}
            />
          ))}

        {rightAxis?.tickCount && (
          <YAxis
            width={width}
            margin={centralMargin}
            height={height}
            tickCount={Math.min(10, rightAxis.tickCount)}
            formatter={rightAxisFormatter}
            alignment="right"
            yScale={yScales.right}
            legend={rightAxis.label}
            onWidthChange={setYAxisRightWidth}
          />
        )}

        {showChartElements && width !== 0 && yAxisLeftWidth !== 0 ? (
          <>
            {series.map((serie, id) => {
              switch (serie.displayType) {
                case "line": {
                  return (
                    <Line
                      key={`${serie.name || serie.label}_${id}`}
                      width={width}
                      height={height}
                      margin={centralMargin}
                      totalNumberOfLines={totalNumberOfLines}
                      serie={serie}
                      yScale={yScales[serie.yAxisKey]}
                      barWidth={barWidth}
                      xLineScale={xLineScale}
                      onChangeFocusState={onChangeFocusState}
                    />
                  );
                }
                case "single-serie-as-bars": {
                  return (
                    <SingleSerieAsBars
                      key={`${serie.name || serie.label}_${id}`}
                      barSerieIndex={counters.barSerieIndex++}
                      width={width}
                      height={height}
                      margin={centralMargin}
                      totalNumberOfBarSeries={totalNumberOfBarSeries}
                      maxIndex={maxIndex}
                      serie={serie as Serie<number>}
                      yScale={yScales[serie.yAxisKey]}
                      onChangeFocusState={onChangeFocusState}
                    />
                  );
                }
                case "stacked-lines": {
                  return (
                    <StackedLines
                      key={`${serie.segments
                        .map((s) => s.name || s.label)
                        .join("|")}_${id}`}
                      width={width}
                      height={height}
                      series={serie.segments}
                      margin={centralMargin}
                      yScales={yScales}
                      onChangeFocusState={onChangeFocusState}
                    />
                  );
                }
                case "stacked-bars": {
                  return (
                    <StackedBars
                      key={`${serie.segments
                        .map((s) => s.name || s.label)
                        .join("|")}_${id}`}
                      width={width}
                      height={height}
                      series={serie.segments}
                      margin={centralMargin}
                      maxIndex={maxIndex}
                      totalNumberOfBarSeries={totalNumberOfBarSeries}
                      barSerieIndex={counters.barSerieIndex++}
                      yScales={yScales}
                      onChangeFocusState={onChangeFocusState}
                    />
                  );
                }
                case "bar-collection": {
                  return (
                    <BarCollection
                      key={`${serie.segments
                        .map((s) => s.name || s.label)
                        .join("|")}_${id}`}
                      forcedBarWidth={forcedBarWidth}
                      width={width}
                      height={height}
                      series={serie.segments}
                      margin={centralMargin}
                      maxIndex={maxIndex}
                      totalNumberOfBarSeries={totalNumberOfBarSeries}
                      barSerieIndex={counters.barSerieIndex++}
                      yScales={yScales}
                      onChangeFocusState={onChangeFocusState}
                    />
                  );
                }

                case "tooltip-only":
                default: {
                  return null;
                }
              }
            })}

            {withVerticalLine && (
              <VerticalLine
                margin={centralMargin}
                height={height}
                isVisible={isHovered}
                index={position.index}
                xPosition={position.x}
              ></VerticalLine>
            )}

            {annotations && (
              <Annotations
                annotations={annotations}
                margin={margin}
                xLineScale={xLineScale}
                height={height}
                barWidth={barWidth}
                totalNumberOfLines={totalNumberOfLines}
                chartDimensions={chartDimensions}
                isChartHovered={isHovered}
                onHoverAnnotation={onHoverAnnotation}
              />
            )}

            <EventReceiver
              width={width}
              height={height}
              margin={centralMargin}
              totalNumberOfSeries={totalNumberOfLines + totalNumberOfBarSeries}
              maxIndex={maxIndex}
            />
          </>
        ) : null}
      </svg>

      {withTooltip &&
        chartDimensions &&
        ReactDOM.createPortal(
          <Tooltip
            shownInModal={shownInModal}
            margin={centralMargin}
            parentDimensions={chartDimensions}
            isVisible={isHovered}
            tooltipHeightVariant={tooltipHeightVariant}
          >
            <PositionContext.Consumer>
              {(contextValues) =>
                contextValues !== null &&
                contextValues.index !== undefined &&
                tooltipComponent({
                  valueIndex: contextValues.index,
                  tooltipTitle: xAxisTooltipFormatter(contextValues.index),
                  data:
                    customTooltipValues ??
                    flattenSeries<AreaDataPoint | number>(series),
                })
              }
            </PositionContext.Consumer>
          </Tooltip>,
          document.body,
        )}
    </BarLineChartStyled>
  );
});
