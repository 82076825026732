import { Fragment, FunctionComponent, ReactElement, useState } from "react";

import {
  Box,
  Button,
  Flex,
  Grid,
  IconButton,
  Text,
  legacyTheme,
} from "../../icecube-ux";
import { _ } from "../../languages/helper";
import { DimensionFilter, Rule } from "../../utils/filterUtils";

import { OperatorSeparator } from "./OperatorSeparator";
import { RuleDetail } from "./RuleDetail";

interface GroupDetailProps {
  ruleGroup: Rule[];
  getDefaultRule: (dimensionFilter?: DimensionFilter) => Rule;
  index: number;
  dimensionSelector?: (
    ruleElement: Rule,
    index: number,
    updateRule: (i: number, rule: Rule) => void,
  ) => ReactElement;
  onGroupChange: (group: Rule[]) => void;
  onGroupDelete: () => void;
  isMetricRule?: boolean;
  children?: (
    dimensionKey: string,
    updateRule: (filterDimension: DimensionFilter) => void,
  ) => JSX.Element;
}

export const GroupDetail: FunctionComponent<GroupDetailProps> = ({
  ruleGroup,
  index,
  getDefaultRule,
  onGroupChange,
  onGroupDelete,
  isMetricRule,
  children,
}) => {
  const [newItemIndex, setNewItemIndex] = useState<number>();

  const updateRule = (i: number, rule: Rule) => {
    const newRules = ruleGroup.map((existingRule, index) =>
      index === i ? rule : existingRule,
    );
    onGroupChange(newRules);
  };

  const deleteRule = (i: number) => {
    const newRules = ruleGroup.filter((_, index) => index !== i);
    onGroupChange(newRules);
  };

  const addRule = () => {
    const newRules = [...ruleGroup, getDefaultRule()];
    onGroupChange(newRules);
    setNewItemIndex(newRules.length - 1);
  };

  return (
    <Box
      marginBottom="10px"
      background={legacyTheme.colors.bg.lightPlus}
      borderRadius="4px"
      padding="12px"
      fontSize="12px"
      lineHeight="18px"
      color={legacyTheme.colors.grey1}
      key={`group-${index}`}
    >
      <Grid gridTemplateColumns="1fr auto" className="Group-title">
        <Box marginBottom="6px">
          <Text variant="body12Regular">
            {_`Group of rules`} {index}
          </Text>
        </Box>
        <Flex gap={8}>
          <IconButton
            size="small"
            color="error"
            name="Delete"
            onClick={onGroupDelete}
          />
        </Flex>
      </Grid>
      <Grid gap={8}>
        {ruleGroup.map((rule, index) => (
          <Fragment key={index}>
            <Box
              background="white"
              border={`1px solid ${legacyTheme.colors.borders.default}`}
              borderRadius="4px"
              padding="12px"
              fontSize="12px"
              lineHeight="18px"
              color={legacyTheme.colors.grey1}
            >
              <RuleDetail
                rule={rule}
                index={index + 1}
                isLastCreated={index === newItemIndex}
                onRuleChange={(rule) => updateRule(index, rule)}
                onRuleDelete={() => deleteRule(index)}
                isMetricRule={isMetricRule}
              >
                {children?.(
                  rule.dimensionFilter?.name ?? "",
                  (dimensionFilter) => {
                    updateRule(index, {
                      ...getDefaultRule(dimensionFilter),
                      dimensionFilter: dimensionFilter ?? null,
                    });
                  },
                )}
              </RuleDetail>
            </Box>
            {index !== ruleGroup.length - 1 && (
              <OperatorSeparator content="And"></OperatorSeparator>
            )}
          </Fragment>
        ))}
        <Button color="tertiary" onClick={() => addRule()} leftIcon="Add" block>
          {_`Add rule`}
        </Button>
      </Grid>
    </Box>
  );
};
