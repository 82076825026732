import styled from "styled-components";

import { theme } from "../theme";

export const StyledEmbeddedVideoWrapper = styled.div`
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  border-radius: ${theme.borderRadius.r8px};
`;

export const StyledEmbeddedVideo = styled.iframe`
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: inherit;
`;
