import { createGlobalStyle, css } from "styled-components";

import { colors, legacyTheme } from "./legacyTheme";

const computedRules: string[] = [];

const prefixes = ["", "-left", "-right", "-top", "-bottom"];
prefixes.forEach((p) => {
  computedRules.push(
    ...Object.entries(legacyTheme.margin).map(
      ([key, value]) => `.margin${p}-${key} { margin${p}: ${value}; }`,
    ),
  );
  computedRules.push(
    ...Object.entries(legacyTheme.padding).map(
      ([key, value]) => `.padding${p}-${key} { padding${p}: ${value}; }`,
    ),
  );
});

computedRules.push(
  ...Object.entries(legacyTheme.zIndexes).map(
    ([key, value]) => `.z-${key} { z-index: ${value}; }`,
  ),
);

computedRules.push(
  ...Object.entries(legacyTheme.borderRadius).map(
    ([key, value]) => `.border-radius-${key} { border-radius: ${value}; }`,
  ),
);

computedRules.push(
  ...Object.entries(legacyTheme.shadows).map(
    ([key, value]) => `.shadow-${key} { box-shadow: ${value}; }`,
  ),
);

computedRules.push(
  ...prefixes.map(
    (key) =>
      `.border${key} { border${key}: 1px solid ${legacyTheme.colors.borders.default}; }`,
  ),
);

const addColorClasses = (object: Record<string, unknown>, prefix: string) => {
  Object.entries(object).forEach(([key, value]) => {
    if (typeof value === "string") {
      computedRules.push(`.color-${prefix}${key} { color: ${value}; }`);
      computedRules.push(
        `.background-${prefix}${key} { background: ${value}; }`,
      );
      return;
    }

    addColorClasses(value as Record<string, unknown>, `${prefix}${key}-`);
  });
};

addColorClasses(legacyTheme.colors, "");

const bodyStyles = css`
  font-family: Poppins;
`;

export const GlobalCss = `
@font-face {
  font-family: Poppins;
  font-weight: 300;
  src: url(/fonts/Poppins-Light.ttf);
}
@font-face {
  font-family: Poppins;
  font-weight: 400;
  src: url(/fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: Poppins;
  font-weight: 500;
  src: url(/fonts/Poppins-Medium.ttf);
}
@font-face {
  font-family: Poppins;
  font-weight: 600;
  src: url(/fonts/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: Poppins;
  font-weight: 700;
  src: url(/fonts/Poppins-Bold.ttf);
}
@font-face {
  font-family: Poppins;
  font-weight: 800;
  src: url(/fonts/Poppins-Bold.ttf);
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes animation-appear-grow {
  from {
    opacity: 0;
    transform: scale(0.85);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes wireframe-loading {
  100% {
    transform: translateX(100%);
  }
}

body {
  ${bodyStyles as unknown as string}
}

.cursor-pointer {
  cursor: pointer;
}

.display-none { display: none; }
.display-block { display: block; }
.display-inline-block { display: inline-block; }

.border-regular { border: 1px solid ${colors.borders.default}; }
.border-hover { border: 1px solid ${colors.borders.hover}; }

.position-absolute { position: absolute; }
.position-relative { position: relative; }
.position-fixed { position: fixed; }

.vertical-middle { vertical-align: middle; }

.flex-grow { flex-grow: 1; }
.flex-shrink { flex-shrink: 1; }

.appear-on-hover-inline-block {display: none !important; }
.appear-on-hover-block { display: none !important; }
.appear-on-hover-flex { display: none !important; }
.appear-on-hover-target-block { display: none !important; }
.appear-on-hover-target-inline-block { display: none !important; }

*:hover > .appear-on-hover-inline-block { display: inline-block !important; }
*:hover > .appear-on-hover-block { display: block !important; }
*:hover > .appear-on-hover-flex { display: flex !important; }
.appear-on-hover-root:hover .appear-on-hover-target-block { display: block !important; }
.appear-on-hover-root:hover .appear-on-hover-target-inline-block { display: inline-block !important; }

.visible-on-hover-inline-block { visibility: hidden !important; }
.visible-on-hover-block { visibility: hidden !important; }
.visible-on-hover-flex { visibility: hidden !important; }

*:hover > .visible-on-hover-inline-block { visibility: inherit !important; }
*:hover > .visible-on-hover-block { visibility: inherit !important; }
*:hover > .visible-on-hover-flex { visibility: inherit !important; }

${computedRules.join("")}
`;

export default createGlobalStyle`${GlobalCss}`;
