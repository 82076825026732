import styled from "styled-components";

import { legacyTheme } from "../legacyTheme";
import { theme } from "../theme";
import { typographyStylesMap } from "../Typography/typographyStylesMap";

const BaseCounter = styled.span<{ borderColor?: string }>`
  display: inline-block;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 2px;
  white-space: nowrap;
  box-sizing: border-box;

  font-family: ${legacyTheme.typography.default.fontFamily};
  font-style: ${legacyTheme.typography.default.fontStyle};
  font-weight: ${legacyTheme.typography.fontWeigth.w600};

  ${({ borderColor }) =>
    borderColor ? `border: 2px solid ${borderColor};` : ""}
`;

export const SmallCounter = styled(BaseCounter)`
  background: ${legacyTheme.colors.primary9};

  border-radius: 100px;
  line-height: 12px;

  min-width: 14px;
  height: 14px;
  padding: 2px 6px;

  font-size: ${legacyTheme.typography.fontSizes.tiny};
  color: ${legacyTheme.colors.primary4};

  ${({ borderColor }) =>
    borderColor ? `border: 1px solid ${borderColor};` : ""}

  &.disabled {
    color: ${legacyTheme.colors.text.placeholder};
    background: ${legacyTheme.colors.bg.inputs.disabled};
    padding: 1px;
    border: 1px dashed ${legacyTheme.colors.borders.hover};
  }
`;

const LargeCounter = styled(BaseCounter)`
  background: ${legacyTheme.colors.primary9};
  width: 19px;
  height: 19px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  ${typographyStylesMap.body10Medium}
  color: ${theme.colors.primary100};

  &.disabled {
    color: ${legacyTheme.colors.text.placeholder};
    background: ${legacyTheme.colors.bg.inputs.disabled};
    padding: 1px;
    border: 1px dashed ${legacyTheme.colors.borders.hover};
  }
`;

const OnboardingCounter = styled(BaseCounter)`
  color: ${theme.customColors.categoryAnalytics};
  background: ${theme.colors.warning20};

  min-width: 26px;
  height: 26px;
  display: flex;

  margin-top: -4px;

  font-size: ${theme.typography.fontSize.s12px};
  font-weight: ${theme.typography.fontWeight.semiBold};
  border-radius: ${theme.borderRadius.r4px};
`;

const OnboardingCounterCompleted = styled(OnboardingCounter)`
  color: ${theme.colors.success100};
  background: ${theme.colors.success20};
`;

const SetupExploreCounter = styled(BaseCounter)`
  color: ${theme.colors.text90};
  background: ${theme.colors.bgLight30};

  min-width: 33px;
  height: 33px;
  border-radius: ${theme.borderRadius.r4px};
  display: flex;

  ${typographyStylesMap.body16SemiBold}
`;

const ReferralCounter = styled(BaseCounter)`
  color: ${theme.colors.primary100};
  background: ${theme.colors.primary20};

  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;

  ${typographyStylesMap.body14SemiBold}
`;

const ButtonCounter = styled(BaseCounter)`
  border-radius: 8px;
  height: 16px;
  font-size: 10px;
  line-height: 17px;
  text-align: center;
  color: #4d42fe;
  background: #e9e7ff;
  display: inline-block;
  padding: 0 5px;
  min-width: 6px;
  vertical-align: middle;
  margin-left: 6px;
`;

const ButtonSquareCounter = styled(ButtonCounter)`
  border-radius: 3px;
  height: 18px;
  width: 18px;
`;

export const Counters = {
  small: SmallCounter,
  large: LargeCounter,
  button: ButtonCounter,
  onboarding: OnboardingCounter,
  onboardingCompleted: OnboardingCounterCompleted,
  setupExplore: SetupExploreCounter,
  referral: ReferralCounter,
  buttonSquare: ButtonSquareCounter,
};
