import styled, { StyledComponent } from "styled-components";

import { Flex } from "../Flex";
import { legacyTheme } from "../legacyTheme";
import { theme } from "../theme";

export const StyledDateAndMonth = styled.div`
  text-align: center;
`;

export const StyledDayFlex = styled(Flex).attrs({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
})`
  margin-top: ${legacyTheme.margin.regular};
`;

export const StyledDayLetter = styled.div`
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  color: ${legacyTheme.colors.text.placeholderDark};
  font-size: ${legacyTheme.typography.fontSizes.small};
`;

export const StyledDayNumber: StyledComponent<
  "div",
  object,
  { extraWidth: string; highlightToday?: boolean },
  never
> = styled.div<{ extraWidth: string; highlightToday: boolean }>`
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;

  &::before {
    z-index: 2;
    position: absolute;
    content: attr(data-before);
    width: 24px;
    height: 24px;
    border-radius: ${legacyTheme.borderRadius.all};
    text-align: center;
    line-height: 24px;
    color: ${legacyTheme.colors.text.regular};
    font-size: ${legacyTheme.typography.fontSizes.small};
  }

  &.selected::before {
    color: ${legacyTheme.colors.primary1};
    background: ${legacyTheme.colors.primary9};
  }

  &.range::before {
    color: ${legacyTheme.colors.white};
    background: ${legacyTheme.colors.primary1};
  }

  &.empty::before {
    background: ${legacyTheme.colors.transparent};
  }

  &.range:not(.last-of-line):not(.end)::after,
  &.selected:not(.last-of-line):not(.end)::after {
    z-index: 1;
    position: absolute;
    content: " ";
    background: ${legacyTheme.colors.primary9};
    width: ${(p: { extraWidth: string }) => p.extraWidth};
    height: 24px;
    left: 12px;
  }

  &.today::before {
    color: ${(p: { highlightToday?: boolean }) =>
      p.highlightToday ? theme.colors.warning110 : theme.colors.white100};
    background: ${(p: { highlightToday?: boolean }) =>
      p.highlightToday ? theme.colors.warning30 : legacyTheme.colors.primary5};
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const StyledCalendar = styled.div<{ width: string | number }>`
  user-select: none;
  max-width: ${({ width }: { width: string | number }) =>
    typeof width === "string" ? width : `${width}px`};
  padding: ${legacyTheme.padding.large} ${legacyTheme.padding.x5large};
  box-sizing: border-box;
  background: ${legacyTheme.colors.grey13};

  &.shadowed {
    box-shadow:
      0px 8px 24px rgba(47, 41, 156, 0.08),
      0px 2px 4px rgba(47, 41, 156, 0.12);
    border-radius: ${legacyTheme.borderRadius.default};
  }

  &.clear {
    background: ${legacyTheme.colors.white};
  }

  &.clear ${StyledDayFlex} {
    opacity: 0.7;
  }
`;
