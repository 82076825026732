import moment from "moment";

import {
  Subscription,
  SUBSCRIPTION_TYPES,
} from "../common/types/subscription-service";

const FULL_ACCESS_SUBSCRIPTION_TYPES = [
  SUBSCRIPTION_TYPES.PLUS,
  SUBSCRIPTION_TYPES.USAGE_BASED_PLAN,
  SUBSCRIPTION_TYPES.USAGE_BASED_PLUS_PLAN,
];

export const isFullAccessPlan = (sub?: Subscription | null) => {
  if (!sub) return false;
  return (
    sub.plan !== undefined && FULL_ACCESS_SUBSCRIPTION_TYPES.includes(sub.plan)
  );
};

export const isFreeOrTrialPlan = (sub?: Subscription | null) => {
  if (!sub) {
    return true;
  }
  if (sub.plan === undefined) return true;
  return [SUBSCRIPTION_TYPES.FREE_TRIAL, SUBSCRIPTION_TYPES.FREE_PLAN].includes(
    sub.plan,
  );
};

export const hasActivePaidPlan = (sub?: Subscription | null) => {
  return !isFreeOrTrialPlan(sub) && isSubscriptionActive(sub);
};

export const isSubscriptionActive = (sub?: Subscription | null) => {
  if (!sub) {
    return false;
  }
  return sub.valid_until === null || moment(sub.valid_until).isAfter(moment());
};

export const SubscriptionValidityDayCount = (sub: Subscription) => {
  return sub.valid_until === null
    ? 30
    : Math.max(0, moment(sub.valid_until).diff(moment(), "days"));
};

export const shouldShowReferral = (
  isDemoData: boolean | undefined,
  subscription?: Subscription | null,
) => {
  if (isDemoData) return false;
  const isFreeOrTrial = isFreeOrTrialPlan(subscription);
  const isActive = isSubscriptionActive(subscription);
  return !isFreeOrTrial && isActive;
};
