import { Fragment } from "react";
import styled from "styled-components";

import { Checkbox } from "../../Checkbox";
import { Flex } from "../../Flex";
import { StyledIcon } from "../../Icon/Icon.styled";
import { theme } from "../../theme";
import { WireframeLoader } from "../../WireframeLoader";

import { ReportTableBodyCellDisplay } from "./ReportTableBodyCell";
import { ReportTableFooterColumn } from "./ReportTableFooterColumns";
import {
  ReportTableBodyLine,
  ReportTableCheckboxConfig,
  ReportTableHeaderColumn,
} from "./types";

const StyledCheckbox = styled(Checkbox)`
  border: 1px solid ${theme.colors.primary40};
  display: flex;
  align-items: center;
  justify-content: center;

  ${StyledIcon} {
    position: relative;
    fill: ${theme.colors.primary100};
    color: ${theme.colors.primary100};
    top: 0;
    left: 0;
  }

  &.checked {
    background: ${theme.colors.bgLight10};
    border: 1px solid ${theme.colors.primary40};
  }
  &.checked:hover {
    background: ${theme.colors.primary40};
  }
  &:hover {
    border: 1px solid ${theme.colors.primary100};
  }
`;
type ReportTableBodyCellsProps = ReportTableCheckboxConfig & {
  isSub?: boolean;
  line: ReportTableBodyLine;
  headers: ReportTableHeaderColumn[][];
  lineIndex: number;
  leftPart?: boolean;
  loading?: boolean;
  loadingSecondary?: boolean;
  hoverAnchor: "top" | "bottom";
  readOnly?: boolean;
  footerColumns?: ReportTableFooterColumn[];
  cellSize?: "regular" | "big";
};
export default function ReportTableBodyCells({
  maxCheckedRows = 5,
  checkbox,
  checkedRows,
  onRowCheckboxChange,
  isSub = false,
  line: { cells, subLines },
  headers,
  lineIndex,
  loading,
  loadingSecondary,
  hoverAnchor,
  readOnly,
  leftPart = false,
  footerColumns,
  cellSize = "regular",
}: ReportTableBodyCellsProps) {
  const headerLine = headers.length > 0 ? headers[headers.length - 1] : [];

  if (!cells || cells?.length === 0) {
    return <></>;
  }
  const currentRowIndex = cells?.[0]?.rowIndices || [];
  const currentRowRules = cells?.[0]?.rowRules || {};
  const currentRowIndexKey = currentRowIndex.join("_") ?? "";
  return (
    <>
      {!loading &&
        cells.map((cell, i) => {
          const formatter = headerLine?.[i]?.formatter as Function | undefined;
          const checkedBoxBorderLeftColor =
            checkedRows?.[currentRowIndexKey]?.borderLeftColor;
          return headerLine?.[i]?.isSecondary && loadingSecondary ? (
            <WireframeLoader
              key={`column-${i}`}
              background={leftPart ? theme.colors.grey50 : theme.colors.grey40}
              height={"26px"}
              width={"100%"}
            />
          ) : (
            <Fragment key={`column-${i}`}>
              {checkbox && i === 0 ? (
                <Flex
                  alignItems={"center"}
                  justifyContent={"center"}
                  style={{
                    background: theme.colors.bgLight10,
                    borderRight: `1px solid ${theme.colors.borderLight}`,
                    ...(checkedBoxBorderLeftColor
                      ? {
                          borderLeft: `2px solid ${checkedBoxBorderLeftColor}`,
                        }
                      : {}),
                    ...(isSub
                      ? {
                          marginTop: -4,
                        }
                      : {}),
                  }}
                  onClick={() => {
                    if (
                      !checkedRows?.[currentRowIndexKey] &&
                      Object.keys(checkedRows || {}).length >= maxCheckedRows
                    ) {
                      return;
                    }
                    onRowCheckboxChange?.({
                      rowIndex: currentRowIndex,
                      rowRules: currentRowRules,
                    });
                  }}
                >
                  <StyledCheckbox
                    disabled={
                      Object.keys(checkedRows || {}).length >= maxCheckedRows
                    }
                    checked={!!checkedRows?.[currentRowIndexKey]}
                  />
                </Flex>
              ) : (
                <></>
              )}
              <ReportTableBodyCellDisplay
                isSub={isSub}
                cellIndex={i}
                lineIndex={lineIndex}
                leftPart={leftPart}
                readOnly={readOnly}
                hoverAnchor={hoverAnchor}
                cellSize={cellSize}
                cell={cell}
                formatter={formatter}
                footerColumnValue={footerColumns?.[i]?.value}
                secondary={headerLine?.[i]?.isSecondary}
              />
            </Fragment>
          );
        })}
      {subLines?.map((subLine, i) => (
        <ReportTableBodyCells
          key={`subline-${subLine.cells.map((c) => c.value).join("-")}-${i}`}
          checkbox={checkbox}
          checkedRows={checkedRows}
          onRowCheckboxChange={onRowCheckboxChange}
          isSub
          leftPart={leftPart}
          line={subLine}
          headers={headers}
          lineIndex={i}
          loading={loading}
          loadingSecondary={loadingSecondary}
          hoverAnchor={hoverAnchor}
          readOnly={readOnly}
        />
      ))}
      {!isSub &&
        loading &&
        new Array(
          (headers?.[headers.length - 1]?.length ?? 0) + (checkbox ? 1 : 0),
        )
          .fill(0)
          .map((_, i) => (
            <WireframeLoader
              background={leftPart ? theme.colors.grey50 : theme.colors.grey40}
              height={"26px"}
              width={"100%"}
              key={`column-${i}`}
            />
          ))}
    </>
  );
}
