const CANNY_APP_ID = "6560763e8f3a3773231345fa";

interface IdentifyCannyUser {
  user: {
    email: string;
    name: string;
    id: string;
    companies: { id: string; name: string }[];
  };
}

export const identifyUser = ({ user }: IdentifyCannyUser) => {
  (window as { Canny?: (...args: unknown[]) => void }).Canny?.("identify", {
    appID: CANNY_APP_ID,
    user: {
      email: user.email,
      name: user.name,
      id: user.id,
      companies: user.companies,
    },
  });
};
