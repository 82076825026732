import styled, { css } from "styled-components";

import { Flex } from "../../Flex";
import { legacyTheme } from "../../legacyTheme";
import { theme } from "../../theme";
import { typographyStylesMap } from "../../Typography/typographyStylesMap";

import { SerieFocusState } from "./Serie";

interface Entry {
  dashed?: boolean;
  line?: boolean;
  color: string;
  titleSegments: string[];
  value?: string;
  border?: string;
  focusState?: SerieFocusState;
}

interface TooltipLineWithCircleProps {
  entry: Entry;
}

const GraphTooltipTitleList = styled.ul<{ focusState?: SerieFocusState }>`
  all: unset;
  min-width: 0;
  white-space: nowrap;
  box-sizing: border-box;
  display: inline;
  max-width: 70%;

  text-overflow: ellipsis;
  overflow-x: hidden;

  text-align: center;

  ${typographyStylesMap.body11Regular}
  color: ${theme.colors.text90};

  ${({ focusState }) => {
    if (focusState === "highlighted") {
      return css`
        color: ${theme.colors.text100};
        ${typographyStylesMap.body11Medium}
      `;
    }
  }}
`;

const GraphTooltipTitleListItem = styled.li`
  all: unset;
  position: relative;
  display: inline;
  box-sizing: border-box;

  padding-left: 4px;
  padding-right: 4px;

  &:not(:first-child):after {
    content: "";
    height: 11px;
    width: 1px;

    position: absolute;
    left: 0;
    top: 1px;

    background-color: ${theme.colors.borderMedium};
  }

  &:first-child {
    border-left: none;
    padding-left: 0px;
  }

  &:last-child {
    padding-right: 0px;
  }
`;

const GraphTooltipValue = styled.div<{ focusState?: SerieFocusState }>`
  text-align: right;
  padding-left: 5px;
  margin-left: auto;

  ${typographyStylesMap.body11Regular}
  color: ${theme.colors.text100};

  ${({ focusState }) => {
    if (focusState === "highlighted") {
      return css`
        color: ${theme.colors.text110};
        ${typographyStylesMap.body11Medium}
      `;
    }
  }}
`;

const GraphTooltipColorSample = styled.div<{
  line?: boolean;
  dashed?: boolean;
  color: string;
}>`
  min-width: 11px;
  width: ${({ line }) => (line ? "26px" : "11px")};
  height: ${({ line }) => (line ? "0" : "11px")};
  border-radius: 3px;
  margin-right: 5px;
  box-sizing: border-box;
  border: 2px
    ${({ dashed, color }) => `${dashed ? "dashed" : "solid"} ${color}`};
`;

export default function TooltipLineWithCircle({
  entry,
}: TooltipLineWithCircleProps) {
  return (
    <Flex alignItems="center" fullWidth>
      <GraphTooltipColorSample
        line={entry.line}
        color={entry.color}
        dashed={entry.dashed}
        style={{
          border:
            entry.border ??
            (entry.color === "white"
              ? `1px solid ${legacyTheme.colors.borders.default}`
              : ""),
        }}
      ></GraphTooltipColorSample>
      <GraphTooltipTitleList focusState={entry.focusState}>
        {entry.titleSegments?.map((titleSegment) => {
          return (
            <GraphTooltipTitleListItem key={titleSegment}>
              {titleSegment}
            </GraphTooltipTitleListItem>
          );
        })}
      </GraphTooltipTitleList>
      <GraphTooltipValue focusState={entry.focusState}>{`${
        entry.value ?? "-"
      }`}</GraphTooltipValue>
    </Flex>
  );
}
