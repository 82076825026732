import styled from "styled-components";

import { Flex } from "../Flex";
import { legacyTheme } from "../legacyTheme";
import { theme } from "../theme";

export const StyledHorizontalList = styled(Flex).attrs({
  gap: 8,
  flexWrap: "wrap",
})``;

export const StyledHorizontalListLabelWrapper = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
  padding: 3px ${legacyTheme.padding.large};
  background: ${legacyTheme.colors.white};
  border: 1px solid ${legacyTheme.colors.borders.default};
  box-sizing: border-box;
  border-radius: ${legacyTheme.borderRadius.default};

  &:not(.disabled):hover {
    border: 1px solid ${legacyTheme.colors.borders.hover};
  }

  &.disabled {
    border: 1px dashed ${legacyTheme.colors.grey11} !important;
    cursor: auto;
  }

  &.selected {
    border: 1px solid ${theme.colors.primary20};
    background: ${theme.colors.primary10};
  }

  & .closer-icon {
    color: ${legacyTheme.colors.grey9};
  }

  & .closer-icon:hover {
    color: ${legacyTheme.colors.grey7};
  }

  &.small {
    padding: 0 ${legacyTheme.padding.regular};
  }
`;
