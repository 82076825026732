import styled, { css } from "styled-components";

import { Block } from "../Block";
import { theme } from "../theme";

export const StyledBanner = styled.div<{
  variant: "primary" | "notification";
  isMobile?: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 68px;
  box-sizing: border-box;

  display: flex;
  justify-content: stretch;

  box-shadow: ${theme.shadows.small};

  ${({ variant }) =>
    variant === "primary"
      ? css`
          background: ${theme.customColors.primaryBackgroundGradient};
          padding: 16px 24px;
        `
      : css`
          background-color: ${theme.colors.white100};
          padding: 8px;
        `}

  ${({ isMobile }) =>
    Boolean(isMobile) &&
    css`
      flex-direction: column;
      height: auto;
    `}
`;

export const StyledBannerWarningBlock = styled(Block).attrs({
  variant: "warning",
})`
  flex-grow: 1;
  display: flex;
  align-content: center;
`;

export const StyledBannerActions = styled.div`
  margin-left: auto;

  display: flex;
  align-items: center;
`;
