import styled from "styled-components";

import { legacyTheme } from "../legacyTheme";

export const StyledTextArea = styled.textarea`
  font-family: ${legacyTheme.typography.default.fontFamily};
  font-style: ${legacyTheme.typography.default.fontStyle};
  font-weight: ${legacyTheme.typography.fontWeigth.w400};
  font-size: ${legacyTheme.typography.fontSizes.large};

  padding: ${legacyTheme.padding.regular} ${legacyTheme.padding.medium};
  border-radius: ${legacyTheme.borderRadius.default};
  display: block;
  width: 100%;
  border: 1px solid ${legacyTheme.colors.bg.inputs.light};
  background: ${legacyTheme.colors.bg.inputs.light};
  color: ${legacyTheme.colors.text.regularHover};
  box-sizing: border-box;
  resize: vertical;

  &:hover {
    background: ${legacyTheme.colors.bg.inputs.lightHover};
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${legacyTheme.colors.text.placeholderDark};
  }

  &:disabled {
    border: 1px dotted ${legacyTheme.colors.borders.hover};
  }
`;
