import styled from "styled-components";

import { Grid } from "../Grid";
import { Icon } from "../Icon";
import { StyledIcon } from "../Icon/Icon.styled";
import { StyledLabel } from "../Label/Label.styled";
import { legacyTheme } from "../legacyTheme";
import { StyledMoreOptions } from "../MoreOptions/MoreOptions.styled";

export const StyledDraggerIcon = styled(Icon).attrs({
  name: "Dragger",
  size: 16,
})`
  cursor: pointer;
  opacity: 0;

  transition: opacity 0.2s ease-in-out;

  &:hover {
    color: ${legacyTheme.colors.primary4};
  }
`;

export const StyledSortableNavigationContainer = styled(Grid).attrs({
  gap: 8,
})`
  position: relative;
  margin-top: -1px;
  margin-bottom: 4px;
`;

export const StyledSortableNavigationLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1;

  & > div {
    height: 100%;
  }
`;

export const StyledSortableNavigation = styled(Grid).attrs({
  gridTemplateColumns: "22px 1fr auto",
  alignItems: "center",
})`
  position: relative;
  margin: 0 0px 0 ${legacyTheme.margin.large};
  color: ${legacyTheme.colors.primary7};
  user-select: none;

  &.moving {
    opacity: 0.5;
  }

  &.target::before {
    position: absolute;
    top: -6px;
    left: 40px;
    content: " ";
    height: 4px;
    width: 40px;
    background: ${legacyTheme.colors.borders.hover};
  }

  & ${StyledIcon} {
    vertical-align: middle;
  }

  & ${StyledLabel} {
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
  }

  &:hover ${StyledDraggerIcon} {
    opacity: 1;
  }

  & ${StyledMoreOptions} {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover ${StyledMoreOptions} {
    opacity: 1;
  }
`;

export const StyledSortableNavigationLabelDragged = styled(
  StyledSortableNavigation,
)`
  position: fixed;
  z-index: ${legacyTheme.zIndexes.layer6};
`;
