import { ConnectorIconNames } from "../../../../icecube-ux";
import {
  getIntegrationConnectorObject,
  getIntegrationIcon,
} from "../../../../integrations/integration";
import { _ } from "../../../../languages/helper";
import { IMetricsList, ITable } from "../../../../types/synthesizer";
import { keyToLabel } from "../../../../utils/utils";
import {
  CustomDimension,
  CustomDimensionThenBlock,
  CustomDimensionWhenBlock,
} from "../../types";

export interface DimensionAvailabilities {
  tableDimensionMap: { [key: string]: string[] };
  sharedDimensions: string[];
}

const isValidWhenBlock = (block: CustomDimensionWhenBlock) =>
  block.dimensionKey !== "" &&
  block.operator !== "" &&
  (block.value !== "" ||
    ["isNull", "isNotNull", "isEmpty", "isNotEmpty"].includes(block.operator));

const isValidWhenThenBlock = ({
  when,
  then,
}: {
  when: CustomDimensionWhenBlock[];
  then: CustomDimensionThenBlock;
}) =>
  then.length > 0 &&
  when.length > 0 &&
  when.find((block) => !isValidWhenBlock(block)) === undefined;

export const isValidCustomDimension = (dimension: CustomDimension) =>
  dimension.else.length > 0 &&
  dimension.whenThen.length > 0 &&
  dimension.whenThen.find((block) => !isValidWhenThenBlock(block)) ===
    undefined;

export const createNewCustomDimension = (): CustomDimension => ({
  whenThen: [
    {
      when: [{ lineOperator: "", dimensionKey: "", operator: "", value: "" }],
      then: [],
    },
  ],
  else: [],
});

export const getAvailableDimensionList = (
  metrics: IMetricsList,
  availableDimensions?: DimensionAvailabilities,
) => {
  const dimensionMap: { [key: string]: { label: string; tables: string[] } } =
    {};
  Object.values(metrics?.tables ?? {})
    .filter(
      (table) =>
        !availableDimensions ||
        Object.keys(availableDimensions.tableDimensionMap).includes(table.key),
    )
    .map((table) =>
      Object.values(table?.dimensions ?? {})
        .filter((d) => d.groupable)
        .forEach((dimension) => {
          if (!dimensionMap[dimension.key]) {
            dimensionMap[dimension.key] = {
              label: dimension.label,
              tables: [],
            };
          }
          dimensionMap[dimension.key].tables.push(table.key);
        }),
    );

  const ordered = Object.keys(dimensionMap)
    .sort((a, b) => {
      if (dimensionMap[a].tables.length > dimensionMap[b].tables.length) {
        return -1;
      } else if (
        dimensionMap[a].tables.length < dimensionMap[b].tables.length
      ) {
        return 1;
      }
      return dimensionMap[a].label.localeCompare(dimensionMap[b].label);
    })
    .reduce<{ [key: string]: { label: string; tables: string[] } }>(
      (obj, key) => {
        obj[key] = dimensionMap[key];
        return obj;
      },
      {},
    );

  return ordered;
};

const buildDimension = ([tableKey, table]: [string, ITable]) => ({
  label: table.label,
  key: tableKey,
  background:
    getIntegrationConnectorObject(table.connectorKeys?.[0] ?? "")
      ?.backgroundColor ?? "#fff",
  icon: getIntegrationIcon(table.connectorKeys?.[0] ?? ""),
  dimensions: Object.entries(table?.dimensions ?? {})
    .filter(([_, d]) => d.groupable)
    .sort(([_, a], [__, b]) => (a.key < b.key ? -1 : a.key > b.key ? 1 : 0))
    .map(([dimKey, dimension]) => ({
      label: keyToLabel(dimension.label),
      key: `${table.rootTableKey}.${dimKey}`,
      in: dimension.in,
    })),
});

export const getChannelGroupingDimensionList = (metrics: IMetricsList) => {
  // include only shopify sales main table
  //  and exclude order_tag dimension
  const limitedMetrics = {
    ...(metrics?.tables?.shopify_sales_main
      ? {
          shopify_sales_main: {
            ...metrics?.tables?.shopify_sales_main,
            dimensions: {
              ...Object.fromEntries(
                Object.entries(
                  metrics?.tables?.shopify_sales_main?.dimensions,
                ).filter(([key]) => key !== "order_tag"),
              ),
            },
          },
        }
      : {}),
  };
  return [
    {
      label: _`Shared`,
      key: "*",
      icon: "Polar" as ConnectorIconNames,
      background: "#f6f9fe",
      dimensions: [
        {
          label: _`Channel`,
          key: `*.channel`,
          in: [],
        },
        {
          label: _`Campaign`,
          key: `*.campaign`,
          in: [],
        },
        {
          label: _`Ad`,
          key: `*.ad_name`,
          in: [],
        },
        {
          label: _`Adsets`,
          key: `*.adset_name`,
          in: [],
        },
      ],
    },
    ...Object.entries(limitedMetrics)
      .sort(([_, a], [__, b]) => a.order - b.order)
      .map(buildDimension),
  ];
};

export const getAllTableDimensionList = (metrics: IMetricsList) => {
  return [
    {
      label: _`Shared`,
      key: "*",
      icon: "Polar" as ConnectorIconNames,
      background: "#f6f9fe",
      dimensions: [
        {
          label: _`Date`,
          key: `*.date`,
          in: [],
        },
        {
          label: _`Channel`,
          key: `*.channel`,
          in: [],
        },
        {
          label: _`Campaign`,
          key: `*.campaign`,
          in: [],
        },
        {
          label: _`Ad`,
          key: `*.ad_name`,
          in: [],
        },
        {
          label: _`Adsets`,
          key: `*.adset_name`,
          in: [],
        },
      ],
    },
    ...Object.entries(metrics?.tables ?? {})
      .sort(([_, a], [__, b]) => a.order - b.order)
      .map(buildDimension),
  ];
};
