import { ReactNode, useEffect, useState } from "react";

import { _ } from "../../languages/helper";

import { ReportTableFooterColumn } from "./components/ReportTableFooterColumns";
import { ReportTableSubHeaderTexts } from "./components/ReportTableSubHeaderLines";
import {
  ReportTableBodyCell,
  ReportTableHeaderColumn,
  FormatterType,
  ReportTableBodyLine,
} from "./components/types";

export const MAX_PIVOTED_COLUMNS = 50;

export interface ReportTableSideSplit<T> {
  left: T;
  right: T;
}
interface ReportTablePivoteProps {
  pivoted?: boolean;
  header: ReportTableSideSplit<ReportTableHeaderColumn[][]>;
  subHeaderTexts?: ReportTableSideSplit<ReportTableSubHeaderTexts>;
  body: ReportTableSideSplit<ReportTableBodyLine[]>;
  footer: ReportTableFooterColumn[];
}

export function useReportTablePivot({
  pivoted,
  subHeaderTexts,
  header,
  body,
  footer,
}: ReportTablePivoteProps) {
  const [pivotedHeader, setPivotedHeader] = useState<
    ReportTableSideSplit<ReportTableHeaderColumn[][]>
  >({ left: [], right: [] });
  const [pivotedSubHeaderTexts, setPivotedSubHeaderTexts] = useState<
    ReportTableSideSplit<ReportTableSubHeaderTexts> | undefined
  >();
  const [pivotedBody, setPivotedBody] = useState<
    ReportTableSideSplit<ReportTableBodyLine[]>
  >({ left: [], right: [] });
  const [pivotedFooter, setPivotedFooter] = useState<ReportTableFooterColumn[]>(
    [],
  );
  const [totalColumns, setTotalColumns] = useState<number | null>(null);
  const [shownColumns, setShownColumns] = useState<number | null>(null);

  useEffect(() => {
    if (!pivoted) {
      setPivotedHeader(header);
      setPivotedSubHeaderTexts(subHeaderTexts);
      setPivotedBody(body);
      setPivotedFooter(footer);
      return;
    }

    // Build dictionnarty of breakdown/value pairs
    const valueDict = new Map<string, ReportTableBodyCell[]>();
    body.left.forEach((breakdownValues, lId) => {
      const values = body.right[lId];
      const key = breakdownValues.cells.map((v) => v.value).join("/");
      valueDict.set(key, values.cells);
    });

    // Get the list of distinct values from left side
    const fullPivotedHeader: ReportTableSideSplit<ReportTableHeaderColumn[][]> =
      {
        left: [],
        right: [],
      };
    const breakdownValues: Array<{
      formatter?: FormatterType;
      key: string;
      values: Set<string | number | null>;
    }> = [];
    if (header.left.length > 0) {
      header.left[0].forEach((breakdown) => {
        breakdownValues.push({
          formatter: breakdown.formatter,
          key: breakdown.key,
          values: new Set<string | number>(),
        });
      });
    }
    body.left.forEach(({ cells }) => {
      cells.forEach((cell, cId) => {
        breakdownValues[cId].values.add(cell.value);
      });
    });

    // Build keys for all values in each line
    const keys: string[] = [];
    const buildKeyRecursively = (index: number, currentKeys: string[]) => {
      if (index > breakdownValues.length - 1) {
        keys.push(currentKeys.join("/"));
        return;
      }
      const breakdown = breakdownValues[index];
      Array.from(breakdown.values).forEach((bValue) => {
        buildKeyRecursively(index + 1, [...currentKeys, `${bValue}`]);
      });
    };
    buildKeyRecursively(0, []);

    // Build headers out of breakdown distinct values
    let totalColumns = 1;
    const columnToHeaderRatios = breakdownValues.map((breakdown) => {
      let headerLine: Array<string | number | null> = [];
      Array.from(breakdown.values).forEach((value) => headerLine.push(value));
      const columnToHeaderRatio =
        breakdownValues.length > 1 ? headerLine.length : 1;

      headerLine = new Array<(string | number | null)[]>(totalColumns)
        .fill(headerLine)
        .flat();

      fullPivotedHeader.right.push(
        headerLine.map(
          (v, id) =>
            ({
              key: `${v}-${id}`,
              label: `${v}`,
              nonDeletable: true,
              nonDraggable: true,
              nonOrderable: true,
              headerFormatter: breakdown.formatter,
              formatter: (v, x, y) => {
                const formatter = header?.right?.[header?.right?.length - 1]?.[
                  y
                ]?.formatter as (
                  value: number,
                  x: number,
                  y: number,
                ) => string | React.ReactNode;
                if (formatter) {
                  return formatter(v, x, y);
                }
                return v;
              },
            }) as ReportTableHeaderColumn,
        ),
      );
      totalColumns *=
        columnToHeaderRatio === 1 ? breakdown.values.size : columnToHeaderRatio;
      return columnToHeaderRatio;
    });

    setTotalColumns(totalColumns);

    for (let id = 0; id < Math.max(fullPivotedHeader.right.length, 1); id++) {
      const totalEntry: ReportTableHeaderColumn = {
        key: `total-${id}`,
        label: id === fullPivotedHeader.right.length - 1 ? _`Total` : "",
        nonDeletable: true,
        nonDraggable: true,
        nonOrderable: true,
        hidden: !(id === fullPivotedHeader.right.length - 1),
        formatter: (v, x, y) => {
          const formatter = header?.right?.[header?.right?.length - 1]?.[y]
            ?.formatter as (
            value: number,
            x: number,
            y: number,
          ) => string | ReactNode;
          if (formatter) {
            return formatter(v, x, y);
          }
          return v;
        },
      };
      fullPivotedHeader.left.push([
        {
          key: `metrics-${id}`,
          label: id === fullPivotedHeader.right.length - 1 ? _`Metrics` : "",
          nonDeletable: true,
          nonDraggable: true,
          nonOrderable: true,
          hidden: !(id === fullPivotedHeader.right.length - 1),
        },
        ...(subHeaderTexts?.right
          ? [
              {
                key: "builtin-comparison-dates",
                label: _`Period`,
                nonDeletable: true,
                nonDraggable: true,
                nonOrderable: true,
                hidden: !(id === fullPivotedHeader.right.length - 1),
                forceWidth: "90px",
              },
            ]
          : []),
        ...(footer.length > 0 ? [totalEntry] : []),
      ]);
    }

    // Build line from header right columns
    const fullPivotedBody: ReportTableSideSplit<ReportTableBodyLine[]> = {
      left: [],
      right: [],
    };
    if (header.right.length > 0) {
      header.right[0].forEach((column, cId) => {
        const leftCells: ReportTableBodyCell[] = [
          {
            value: column.label,
          },
          ...(subHeaderTexts?.right
            ? [
                {
                  value: "",
                  subText:
                    subHeaderTexts?.right?.[
                      column.isSecondary ? "secondary" : "primary"
                    ] || "primary",
                  forceWidth: "90px",
                },
              ]
            : []),
          ...(footer.length > 0
            ? [{ ...footer[cId], forceDisplayAsValue: true }]
            : []),
        ];
        const rightCells: ReportTableBodyCell[] = [];

        keys.forEach((key) => {
          const lineValue = valueDict.get(key);
          rightCells.push(lineValue?.[cId] ?? { value: "" });
        });

        fullPivotedBody.left.push({
          cells: leftCells,
        });
        fullPivotedBody.right.push({
          cells: rightCells,
        });
      });
    }

    const shownColumns = Math.min(MAX_PIVOTED_COLUMNS, totalColumns);
    setShownColumns(shownColumns);

    const columnSpans = columnToHeaderRatios.slice(1).map((_, i) => {
      return columnToHeaderRatios
        .slice(i + 1)
        .reduce((sum, val) => sum * val, 1);
    });

    const pivotedHeader: ReportTableSideSplit<ReportTableHeaderColumn[][]> = {
      left: fullPivotedHeader.left,
      right: fullPivotedHeader.right.map((rows, rowIndex) => {
        const columnSpan = columnSpans[rowIndex] || 1;

        return rows
          ?.slice(0, Math.ceil(MAX_PIVOTED_COLUMNS / columnSpan))
          .map((column, i, { length: arrayLength }) => {
            const isLastElement = i + 1 === arrayLength;
            const isTruncated =
              isLastElement && shownColumns % columnSpan !== 0;

            return {
              ...column,
              columnSpan: isTruncated
                ? shownColumns - i * columnSpan
                : columnSpan,
            };
          });
      }),
    };

    const pivotedBody: ReportTableSideSplit<ReportTableBodyLine[]> = {
      left: fullPivotedBody.left,
      right: fullPivotedBody.right.map((row) => ({
        ...row,
        cells: row.cells.slice(0, MAX_PIVOTED_COLUMNS),
      })),
    };

    setPivotedHeader(pivotedHeader);
    setPivotedSubHeaderTexts(undefined);
    setPivotedBody(pivotedBody);
    setPivotedFooter([]);
  }, [pivoted, subHeaderTexts, header, body, footer]);

  return {
    pivotedHeader,
    pivotedSubHeaderTexts,
    pivotedBody,
    pivotedFooter,
    totalColumns,
    shownColumns,
  };
}
