import styled, { css } from "styled-components";

import { theme } from "../theme";

export const StyledCustomerJourneyChip = styled.div`
  height: 28px;
  width: 200px;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
`;

export const StyledCustomerJourneyChipMain = styled.div<{
  isFirst?: boolean;
  isLast?: boolean;
  alignTouches: "left" | "right";
}>`
  flex-grow: 1;
  display: flex;
  align-items: center;
  background-color: ${theme.colors.bgLight30};
  height: 100%;
  padding: 0 2px 0 4px;
  overflow: hidden;
  ${({ isFirst, isLast, alignTouches }) =>
    isFirst &&
    css`
      border-left: 5.4px solid ${theme.colors.bgLight30};
      ${(alignTouches === "left" || !isLast) &&
      `border-left-color: ${theme.colors.grey70};`}
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    `}

  ${({ isLast, isFirst, alignTouches }) =>
    isLast &&
    css`
      ${(alignTouches === "right" || !isFirst) &&
      `border-right: 5.4px solid ${theme.colors.grey70};`}
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    `}
`;
