import { EMPTY_ARRAY } from "../../../utils/utils";

import {
  Serie,
  SerieCollection,
  isAreaDataPoint,
  isSerie,
  transposedValues,
} from "./Serie";

export const serieStack = (series: Serie<number>[]) => {
  if (!series.length) {
    return EMPTY_ARRAY;
  }

  const result: Serie<number>[] = [];
  series.forEach((serie, serieIndex) => {
    const previousSerie = result[Number(serieIndex) - 1];
    result.push({
      ...serie,
      values: serie.values.map((value, index) => {
        const previousValue = previousSerie?.values?.[index];
        return (previousValue ?? 0) + value;
      }),
    });
  });

  return result;
};

const arrayMergeBySum = (data: number[][]) => {
  if (data.length < 1) return [];

  const sumValues: number[] = [];
  data[0].forEach((_, i) => {
    let sum = 0;
    for (let y = 0; y < data.length; y++) {
      sum += data[y][i];
    }
    sumValues.push(sum);
  });
  return sumValues;
};

export const getDomainData = (
  series: (Serie | SerieCollection)[],
  yAxisKey: "left" | "right",
) => {
  const values = series.flatMap((serie) => {
    if (
      isSerie(serie) &&
      serie.displayType !== "tooltip-only" &&
      serie.yAxisKey === yAxisKey
    ) {
      return serie.values.map((value) => {
        if (isAreaDataPoint(value)) {
          return value.yUpper;
        }
        return value;
      });
    }

    if (isSerie(serie) || serie.segments.length === 0) {
      return [];
    }

    if (serie.displayType === "bar-collection") {
      return arrayMergeBySum(
        transposedValues({
          ...serie,
          segments: serie.segments.filter(
            (segment) =>
              segment.displayType !== "tooltip-only" &&
              segment.yAxisKey === yAxisKey,
          ),
        }),
      );
    }

    return arrayMergeBySum(serie.segments.map((segment) => segment.values));
  });

  return [Math.min(0, ...values), Math.max(...values)];
};
