import { SupportedRequestMethod } from "../common/connectors/requests";
import {
  GenericDataSourceConfiguration,
  withNewIntegrationService,
} from "../integrations/integration";
import { CustomDataset } from "../pages/connectors/popups/customDatasets/customDatasetTypes";

import { createClient } from "./apiClient";
import { getServiceHost } from "./services";

interface IntegrationError {
  error: { additional_data: string };
}

export const GlobalStatusTypes = {
  active: "active",
  broken: "broken",
  warning: "warning",
  paused: "paused",
  unknown: "unknown",
  syncing: "syncing",
  incomplete: "incomplete",
  building: "building",
} as const;
export type GlobalStatusType =
  (typeof GlobalStatusTypes)[keyof typeof GlobalStatusTypes];

interface OAuthAccounts {
  id: string;
  name: string;
  subaccounts: { id: string }[];
}

export interface RefreshStatusData {
  datasource_id: string;
  models?: string[];
  is_historical_sync?: boolean;
  is_enabled?: boolean;
  last_refresh_date?: string | null;
  next_refresh_date?: string | null;
  timezone?: string;
  setup_state?: GlobalStatusType;
  sync_state?: string;
  update_state?: string;
  updated_at: string | null;
  integration: string;
  errors?: string[];
  oauthAccounts?: OAuthAccounts[];
  polarConnectorAccounts?: PolarConnectorAccount[];
  polarConnectorSubAccounts?: PolarConnectorAccount[];
  polarConnectorTimezone?: string;
}

interface PolarConnectorAccount {
  id: string;
  status?: "granted" | "denied";
}

export type SummaryErrorType =
  | "empty_value"
  | "invalid_value"
  | "invalid_column_name"
  | "missing_column";

export interface SheetData {
  rows: Array<{
    [key: string]: { value: string; error: string };
  }>;
  errorsSummary: { type: string; columns: string[] }[];
  columnsInfo: { metrics: string[]; dimensions: string[] };
  sheetTitle: string;
}

export interface ConnectorStatusData {
  is_historical_sync?: boolean;
  last_refresh_date?: string | null;
  next_refresh_date?: string | null;
  setup_state: GlobalStatusType;
  sync_state?: string;
  update_state?: string;
  errors?: string[];
  datasource_id: string;
  platform: {
    is_historical_sync?: boolean;
    last_sync_ended_at?: string | null;
    next_sync_start_at?: string | null;
  };
  polar: {
    is_enabled: boolean;
    models?: string[];
    // TODO: wait for observability service to implement new warning system
    accounts?: PolarConnectorAccount[];
    subaccounts?: PolarConnectorAccount[];
    timezone?: string;
  };
  integration: string;
  updated_at: string | null;
  status: {
    global: GlobalStatusType;
    sync: string;
  };
  // TODO: wait for observability service to implement new warning system
  oauth: {
    accounts?: OAuthAccounts[];
  };
}

export interface ConnectorData {
  account_id?: string;
  id: string;
  profiles?: string[];
  accounts?: string[];
  shopify_url?: string;
  disabled: boolean;
  created_at: string;
  created_by: string | null;
  email: string | null;
}

export interface ConnectorList {
  [key: string]: ConnectorData[];
}

const getSafeUrlKeyForConnector = (connectorKey: string) =>
  connectorKey === "google-ads" ? "glg-sda" : connectorKey;

const client = createClient("integrations-service");
const clientV2 = createClient("datasource-service");

interface ConnectProps {
  connectorKey: string;
  redirectUrl?: string;
  token: string;
  params?: Record<string, string>;
  openInNewWindow?: boolean;
}

export const connect = async ({
  connectorKey,
  redirectUrl,
  token,
  params = {},
  openInNewWindow = false,
}: ConnectProps) => {
  const connector = getSafeUrlKeyForConnector(connectorKey);

  const urlParams = Object.entries(params).map(
    ([key, value]) => `${key}=${value}`,
  );
  if (redirectUrl) {
    urlParams.push(`redirect_url=${encodeURIComponent(redirectUrl)}`);
  }
  const url =
    (withNewIntegrationService(connector)
      ? getServiceHost("datasource-service")
      : getServiceHost("integrations-service")) +
    `/${connector}/connect?token=${encodeURIComponent(token)}&${urlParams.join(
      "&",
    )}`;

  if (redirectUrl) {
    if (openInNewWindow) {
      window.open(url);
    } else {
      window.document.location.href = url;
    }
  } else {
    const res = await connectWithParams(token, connectorKey, params);
    if (res.error) {
      return res;
    }
    return res;
  }
};

export const patchFacebookConnector = async (
  token: string,
  connectorId: string,
) => {
  await client
    .new()
    .patch(`/api/facebook-ads/${connectorId}`)
    .auth(token)
    .body({ is_connection_confirmed: true })
    .fetch();
  return true;
};

export const patchPinterestConnector = async (
  token: string,
  connectorId: string,
) => {
  await client
    .new()
    .patch(`/api/pinterest-ads/${connectorId}`)
    .auth(token)
    .body({ is_connection_confirmed: true })
    .fetch();
  return true;
};

export const getPinterestConnectCard = async (
  token: string,
  connectorId: string,
  redirect_url: string,
) => {
  const json = await client
    .new()
    .get(
      `/api/pinterest-ads/${connectorId}/connect-card?redirect_url=${encodeURIComponent(
        redirect_url,
      )}`,
    )
    .auth(token)
    .fetch<{ data: { connect_card_url?: string; should_retry: boolean } }>();
  return json.error ? null : json.data;
};

export const getPinterestConnectorId = async (token: string) => {
  const json = await client
    .new()
    .post("/api/pinterest-ads")
    .auth(token)
    .fetch<{ data: { id: string } }>();
  return json.error ? undefined : json.data;
};

export const getConnectorStatusV2 = async (
  token: string,
  datasourceId: string,
) => {
  const json = await clientV2
    .new()
    .get(`/api/datasources/${datasourceId}/status`)
    .auth(token)
    .fetch<{ data: ConnectorStatusData }>();
  return json.error ? null : json.data;
};

export const getFacebookCampaignData = async (
  token: string,
  inputs: { datasource_id: string; campaign_id: string }[],
) => {
  const json = await clientV2
    .new()
    .post(`/api/facebook-ads/getCampaignData`)
    .body({ inputs })
    .auth(token)
    .fetch<{ data: { status: string; canEdit: string }[] }>();
  return json.error ? null : json.data;
};

export const getFacebookAdsetData = async (
  token: string,
  inputs: { datasource_id: string; adset_id: string }[],
) => {
  const json = await clientV2
    .new()
    .post(`/api/facebook-ads/getAdsetData`)
    .body({ inputs })
    .auth(token)
    .fetch<{ data: { status: string; canEdit: string; currency: string }[] }>();
  return json.error ? null : json.data;
};

export const setFacebookCampaignData = async (
  token: string,
  {
    datasource_id,
    campaign_id,
    isActive,
  }: { datasource_id: string; campaign_id: string; isActive: boolean },
) => {
  const json = await clientV2
    .new()
    .post(`/api/facebook-ads/updateCampaignData`)
    .body({
      datasource_id,
      campaign_id,
      isActive,
    })
    .auth(token)
    .fetch();
  return json;
};

export const setFacebookAdsetData = async (
  token: string,
  {
    datasource_id,
    adset_id,
    isActive,
    dailyBudget,
  }: {
    datasource_id: string;
    adset_id: string;
    isActive: boolean;
    dailyBudget: number;
  },
) => {
  const json = await clientV2
    .new()
    .post(`/api/facebook-ads/updateAdsetData`)
    .body({
      datasource_id,
      adset_id,
      isActive,
      dailyBudget,
    })
    .auth(token)
    .fetch();
  return json;
};

export const getConnectorsStatuses = async (token: string) => {
  const json = await clientV2
    .new()
    .get(`/api/datasources/statuses`)
    .auth(token)
    .fetch<{ data: ConnectorStatusData[] }>();
  return json.error ? null : json.data;
};

export const refreshConnectorStatusV2 = async (
  token: string,
  datasourceId: string,
) => {
  const json = await clientV2
    .new()
    .get(`/api/datasources/${datasourceId}/refresh-status`)
    .auth(token)
    .fetch<{ data: ConnectorStatusData }>();
  return json.error ? null : json.data;
};

interface ConfigOptionBase {
  key: string;
  label: string;
  description?: string;
  type: string;
  isRequired?: boolean; // default false
  isPrivate?: boolean; // default false
  isReadOnly?: boolean; // default false
}

interface MultiSelectConfigOption extends ConfigOptionBase {
  type: "multi-select";
  options: { label: string; value: string }[];
}
interface TextConfigOption extends ConfigOptionBase {
  type: "text";
}
interface SelectConfigOption extends ConfigOptionBase {
  type: "select";
  options: { label: string; value: string }[];
}

export type ConfigOption =
  | SelectConfigOption
  | TextConfigOption
  | MultiSelectConfigOption;

export const getConnectorConnectParams = async (
  token: string,
  connectorId: string,
) => {
  const json = await clientV2
    .new()
    .get(`/api/${connectorId}/connector-connect-params`)
    .auth(token)
    .fetch<{ data: ConfigOption[] }>();
  return json;
};

export const getDatasourceEditOptions = async (
  token: string,
  datasourceId: string,
) => {
  const json = await clientV2
    .new()
    .get(`/api/${datasourceId}/datasource-edit-options`)
    .auth(token)
    .fetch<{ data: ConfigOption[] }>();
  return json.error ? [] : json.data ?? [];
};

export const getConnectorEditOptions = async (
  token: string,
  connectorId: string,
) => {
  const json = await clientV2
    .new()
    .get(`/api/${connectorId}/connector-edit-options`)
    .auth(token)
    .fetch<{ data: ConfigOption[] }>();
  return json.error ? [] : json.data ?? [];
};

const connectWithParams = async (
  token: string,
  connectorId: string,
  params: Record<string, string>,
): Promise<{ error: boolean; datasourceId?: string }> => {
  const json = await clientV2
    .new()
    .post(`/api/${connectorId}/connectToken`)
    .body(params)
    .auth(token)
    .fetch<{ data: { datasourceId?: string } }>();
  return json;
};

export const registerConnectorRoleDatasource = async (
  token: string,
  datasourceId: string,
  metadata: {
    connectorKey: string;
  },
) => {
  return await clientV2
    .new()
    .post("/api/register/connector-role-datasource")
    .auth(token)
    .body({ metadata, datasourceId })
    .fetch();
};

export const getDatasourcesV2 = async (
  token: string,
  connectoroKey: string,
  abortSignal?: AbortSignal,
) => {
  const json = await clientV2
    .new(abortSignal)
    .get(`/api/${connectoroKey}/datasources`)
    .auth(token)
    .fetch<{ data: GenericDataSourceConfiguration[] }>();
  return json.error ? [] : json.data ?? [];
};

export interface BingDataSourceData {
  id: string;
  disabled: boolean;
  accounts: string[];
}
export interface ShopifyDataSourceConfiguration {
  id: string;
  disabled: boolean;
  shopify_url: string;
  revenue_computation_include_discounts: boolean;
  revenue_computation_include_refunds: boolean;
  revenue_computation_include_shipping: boolean;
  revenue_computation_include_tax: boolean;
}
export interface AmazonDataSourceConfiguration {
  id: string;
  disabled: boolean;
  region: string;
  attribution_window: string;
  profiles: string[];
}

export interface GADataSourceData {
  id: string;
  disabled: boolean;
  endDate: Date;
  endDateStatus: boolean;
  accounts: string[];
  profiles: string[];
}
export interface SnapChatDataSourceData {
  id: string;
  disabled: boolean;
  swipe_attribution_window: string;
  view_attribution_window: string;
  organizations: string[];
}
export interface TikTokDataSourceData {
  id: string;
  disabled: boolean;
  accounts: string[];
}
export interface CriteoDataSourceConfiguration {
  id: string;
  disabled: boolean;
  client_id: string;
  access_token: string;
  currency: string;
  report_timezone: string;
}
export interface GAFourDataSourceData {
  id: string;
  disabled: boolean;
  startDate: Date;
  startDateStatus: boolean;
  accounts: string[];
  properties: string[];
}

export interface GAdsDataSourceData {
  id: string;
  disabled: boolean;
  conversion_window_size: number;
  customer_id: string;
  accounts: string[];
}
export interface KlaviyoDataSourceConfiguration {
  id: string;
  disabled: boolean;
  shopify_url: string;
  access_token: string;
  site_id: string;
}

export const PINTEREST_WINDOW_SIZE_MAP = {
  ZERO: 0,
  DAY_1: 1,
  DAY_7: 7,
  DAY_30: 30,
  DAY_60: 60,
};

export type PinterestWindowSize = keyof typeof PINTEREST_WINDOW_SIZE_MAP;

export interface PinterestDataSourceData {
  id: string;
  disabled: boolean;
  click_attribution_window: PinterestWindowSize;
  engagement_attribution_window: PinterestWindowSize;
  view_attribution_window: PinterestWindowSize;
  conversion_report_time: string;
}
export interface GoogleSheetsDataSourceData {
  id: string;
  disabled: boolean;
  sheet_url: string;
  named_range: string;
  report_type: string;
}
export interface FbDataSourceData {
  id: string;
  disabled: boolean;
  click_attribution_window: string;
  view_attribution_window: string;
  action_report_time: string;
  accounts: string[];
}
export interface RechargeDataSourceConfiguration {
  id: string;
  disabled: boolean;
  shopify_url: string;
  access_token: string;
}
export type ConnectorsOfDatasource =
  | GenericDataSourceConfiguration
  | AmazonDataSourceConfiguration
  | BingDataSourceData
  | KlaviyoDataSourceConfiguration
  | GAdsDataSourceData
  | GoogleSheetsDataSourceData
  | TikTokDataSourceData
  | FbDataSourceData
  | SnapChatDataSourceData
  | CriteoDataSourceConfiguration
  | GAFourDataSourceData
  | GADataSourceData
  | PinterestDataSourceData
  | RechargeDataSourceConfiguration
  | ShopifyDataSourceConfiguration;

export const getConnectorsOfDataSource = async <
  T extends ConnectorsOfDatasource[],
>(
  token: string,
  dataSource: string,
  abortSignal?: AbortSignal,
) => {
  const connectorKeySafe = getSafeUrlKeyForConnector(dataSource);
  const json = await clientV2
    .new(abortSignal)
    .get(`/api/proxy/integrations-service/${connectorKeySafe}`)
    .auth(token)
    .fetch<{
      data: T;
    }>();
  const defaultData = [] as unknown as T;
  return json.error ? defaultData : json.data ?? defaultData;
};

export const getConnectors = async (token: string) => {
  const result = await clientV2
    .new()
    .get("/api/datasources/with-v1-connectors")
    .auth(token)
    .fetch<{
      data: ConnectorList;
    }>();

  return result?.error ? {} : result.data;
};

export const updateConnector = async (
  token: string,
  connectorKey: string,
  connectorId: string,
  data: Record<string, unknown>,
) => {
  const connectorKeySafe = getSafeUrlKeyForConnector(connectorKey);
  const json = await client
    .new()
    .patch(`/api/${connectorKeySafe}/${connectorId}`)
    .auth(token)
    .body(data)
    .fetch();
  return json;
};

export interface UpdateDatasourceData {
  connectorEditData: Record<string, unknown>;
  connectorConnectData?: Record<string, unknown>;
}

export const updateConnectorV2 = async (
  token: string,
  connectorKey: string,
  connectorId: string,
  data: UpdateDatasourceData,
) => {
  await clientV2
    .new()
    .patch(`/api/${connectorKey}/datasources/${connectorId}`)
    .auth(token)
    .body(data)
    .fetch();
};

export const getShopifyPixelStatus = async (
  abortController: AbortController | undefined,
  token: string,
) => {
  const result = await clientV2
    .new(abortController?.signal)
    .post(`/api/shopify-pixel/status`)
    .auth(token)
    .fetch<{
      data: {
        storeUrl: string;
        id: string;
        isInstalled: boolean;
        hasPixelPermissions: boolean;
      }[];
    }>();
  return result.error ? null : result.data;
};

export interface UtmCoverageItem {
  channel: string;
  campaign: string;
  campaign_id: string;
  split_account_id: string;
  spend: number;
  matched: "True" | "False";
}

export const getShopifyUtmCoverage = async (
  abortController: AbortController | undefined,
  token: string,
) => {
  const result = await clientV2
    .new(abortController?.signal)
    .get(`/api/shopify-pixel/utmCoverage`)
    .auth(token)
    .fetch<{ data: UtmCoverageItem[] }>();
  return result.error ? null : result.data;
};

export interface CampaignsByAccount {
  [key: string]: {
    campaign_id: string;
    campaign_name: string;
  }[];
}

export const getGAdsCampaigns = async (
  abortController: AbortController | undefined,
  token: string,
  accounts: string[],
) => {
  const result = await clientV2
    .new(abortController?.signal)
    .post(`/api/shopify-pixel/getGAdsCampaigns`)
    .auth(token)
    .body({ accounts })
    .fetch<{ data: CampaignsByAccount }>();
  return result.error ? null : result.data;
};

export interface CampaignsUpdated {
  campaignsUpdated: number;
}

export const setGAdsUtms = async (
  abortController: AbortController | undefined,
  token: string,
  campaignsToUpdate: CampaignsByAccount,
) => {
  const result = await clientV2
    .new(abortController?.signal)
    .post(`/api/shopify-pixel/setGAdsUtms`)
    .auth(token)
    .body({ campaignsToUpdate })
    .fetch<{ data: CampaignsUpdated }>();
  return result.error ? null : result.data;
};

export interface AttributionRateData {
  store: string;
  date: string;
  fully_attributed: number;
  not_attributed: number;
  total: number;
}

export const getShopifyPixelAttributionRates = async (
  abortController: AbortController | undefined,
  token: string,
) => {
  const result = await clientV2
    .new(abortController?.signal)
    .get(`/api/shopify-pixel/attribution-rates`)
    .auth(token)
    .fetch<{
      data: AttributionRateData[];
    }>();
  return result.error ? null : result.data;
};

export interface AttributionForFullWeek {
  store: string;
  sales_channel: string;
  fully_attributed: number;
  not_attributed: number;
}
export const getPixelAttributionForFullWeek = async (
  abortController: AbortController | undefined,
  token: string,
  hasHourlyRefresh: boolean,
) => {
  const result = await clientV2
    .new(abortController?.signal)
    .get(
      `/api/shopify-pixel/attribution-for-full-week?hasHourlyRefresh=${hasHourlyRefresh}`,
    )
    .auth(token)
    .fetch<{
      data: AttributionForFullWeek[];
    }>();
  return result.error ? null : result.data;
};

export const installPixel = async (
  token: string,
  shopId: string,
  abortController?: AbortController | undefined,
) => {
  const result = await clientV2
    .new(abortController?.signal)
    .post(`/api/shopify-pixel/install`)
    .body({ shopId })
    .auth(token)
    .fetch<{ data: boolean }>();
  return result.error ? null : result.data;
};

export const getPixelExtensionStatuses = async (
  token: string,
  abortController?: AbortController | undefined,
) => {
  const result = await clientV2
    .new(abortController?.signal)
    .post(`/api/shopify-pixel/extension-statuses`)
    .auth(token)
    .fetch<{
      data: {
        shops: {
          id: string;
          shopify_url: string;
          hasPixelPermissions: boolean;
          pixelConfig:
            | undefined
            | {
                id: string;
                settings: string;
              };
        }[];
      };
    }>();
  return result.error ? null : result.data.shops;
};

export const getShopifyPixelDataFlow = async (
  abortController: AbortController | undefined,
  token: string,
  storeUrl: string,
) => {
  const result = await clientV2
    .new(abortController?.signal)
    .get(`/api/shopify-pixel/flow?store=${storeUrl}`)
    .auth(token)
    .fetch<{ data: { date: string; value: number }[] }>();
  return result.error ? null : result.data;
};

export const deleteConnector = async (
  token: string,
  connectorKey: string,
  connectorId: string,
) => {
  const connectorKeySafe = getSafeUrlKeyForConnector(connectorKey);
  const json = await clientV2
    .new()
    .delete(
      `/api/proxy/integrations-service/${connectorKeySafe}/${connectorId}`,
    )
    .auth(token)
    .fetch();
  return json.error;
};

export const deleteIntegrationAccountV2 = async (
  token: string,
  connectorKey: string,
  integrationAccountId: string,
) => {
  const json = await clientV2
    .new()
    .delete(`/api/${connectorKey}/integration-accounts/${integrationAccountId}`)
    .auth(token)
    .fetch();
  return json;
};

export interface FbAccountData {
  id: string;
  name: string;
}

export interface FbDataSourceConfiguration {
  available_accounts: FbAccountData[];
}
export interface TikTokAccountData {
  id: string;
  name: string;
}
export interface TikTokDataSourceConfiguration {
  available_accounts: TikTokAccountData[];
}

export interface AmazonAccount {
  available_profiles: {
    account_id: string;
    profile_id: string;
    account_name?: string;
  }[];
}

export interface GAProfile {
  account_id: string;
  account_name: string;
  profile_id: string;
  profile_name: string;
  web_property_id: string;
  web_property_name: string;
}

export interface GADataSourceConfiguration {
  available_profiles: GAProfile[];
}

export interface GAFourProperty {
  account_id: string;
  account_name: string;
  property_id: string;
  property_name: string;
}

export interface GAFourDataSourceConfiguration {
  available_properties: GAFourProperty[];
}

export interface BingAccountData {
  name: string;
  customer_id: string;
  accounts: Array<{
    account_id: string;
    name: string;
  }>;
}
export interface BingDataSourceConfiguration {
  available_customers: BingAccountData[];
}
export interface GAdsCustomer {
  account_id: string;
  accounts: Array<{
    account_id: string;
    currency_code: string;
    name: string;
    timezone: string;
  }>;
  currency_code: string;
  name: string;
  timezone: string;
}

export interface GAdsDataSourceConfiguration {
  available_customers: GAdsCustomer[];
}

export interface SnapChatAccountData {
  id: string;
  name: string;
}

export interface SnapChatDataSourceConfiguration {
  available_organizations: SnapChatAccountData[];
}

export type AccountDetails =
  | GAdsDataSourceConfiguration
  | GAFourDataSourceConfiguration
  | GADataSourceConfiguration
  | AmazonAccount
  | BingDataSourceConfiguration
  | FbDataSourceConfiguration
  | TikTokDataSourceConfiguration
  | SnapChatDataSourceConfiguration;

export const getConnectorDetails = async <T extends AccountDetails>(
  token: string,
  dataSource: string,
  connectorId: string,
  abortSignal?: AbortSignal,
) => {
  const connectorKeySafe = getSafeUrlKeyForConnector(dataSource);
  const json = await client
    .new(abortSignal)
    .get(`/api/${connectorKeySafe}/${connectorId}/accounts-details`)
    .auth(token)
    .fetch<
      {
        data: T;
      },
      IntegrationError
    >();
  if (json.error) {
    throw new Error(json.error.additional_data || "Unknown error");
  }
  return json.data || {};
};

export const setBingSelectedAccount = async (
  token: string,
  connectorId: string,
  accounts: string[],
) => {
  const json = await client
    .new()
    .patch(`/api/bing-ads/${connectorId}`)
    .auth(token)
    .body({ accounts })
    .fetch();
  return json;
};

export const setTikTokSelectedAccount = async (
  token: string,
  connectorId: string,
  accounts: string[],
) => {
  const json = await client
    .new()
    .patch(`/api/tiktok-ads/${connectorId}`)
    .auth(token)
    .body({ accounts })
    .fetch();
  return json;
};

export const setPinterestConfiguration = async (
  token: string,
  connectorId: string,
  click_attribution_window: string,
  engagement_attribution_window: string,
  view_attribution_window: string,
  conversion_report_time: string,
) => {
  const json = await client
    .new()
    .patch(`/api/pinterest-ads/${connectorId}`)
    .auth(token)
    .body({
      click_attribution_window,
      engagement_attribution_window,
      view_attribution_window,
      conversion_report_time,
    })
    .fetch();
  return json;
};

export const setGoogleSheetsConfiguration = async (
  token: string,
  connectorId: string,
  sheet_url: string,
  named_range: string,
  report_type: string,
) => {
  const json = await client
    .new()
    .patch(`/api/google-sheets/${connectorId}`)
    .auth(token)
    .body({
      sheet_url,
      named_range,
      report_type,
    })
    .fetch();
  return json;
};

export const setSnapChatSelectedProfiles = async (
  token: string,
  connectorId: string,
  organizations: string[],
  swipe_attribution_window: string,
  view_attribution_window: string,
) => {
  const json = await client
    .new()
    .patch(`/api/snapchat-ads/${connectorId}`)
    .auth(token)
    .body({
      organizations,
      swipe_attribution_window,
      view_attribution_window,
    })
    .fetch();
  return json;
};

export const setFbSelectedProfiles = async (
  token: string,
  connectorId: string,
  accounts: string[],
  click_attribution_window: string,
  view_attribution_window: string,
  action_report_time: string,
) => {
  const json = await client
    .new()
    .patch(`/api/facebook-ads/${connectorId}`)
    .auth(token)
    .body({
      accounts,
      click_attribution_window,
      view_attribution_window,
      action_report_time,
    })
    .fetch();
  return json;
};

export const setGAdsSelectedProfiles = async (
  token: string,
  connectorId: string,
  customer_id: string,
  accounts: string[],
  conversion_window_size: number,
) => {
  const json = await client
    .new()
    .patch(`/api/glg-sda/${connectorId}`)
    .auth(token)
    .body({ customer_id, accounts, conversion_window_size })
    .fetch();
  return json;
};

export const setGASelectedProfiles = async (
  token: string,
  connectorId: string,
  accounts: string[],
  profiles: string[],
) => {
  const json = await client
    .new()
    .patch(`/api/google-analytics/${connectorId}`)
    .auth(token)
    .body({ accounts, profiles })
    .fetch();
  return json;
};

export const setGAFourSelectedProperties = async (
  token: string,
  connectorId: string,
  accounts: string[],
  properties: string[],
) => {
  const json = await client
    .new()
    .patch(`/api/google-analytics-four/${connectorId}`)
    .auth(token)
    .body({ accounts, properties })
    .fetch();
  return json;
};

export const setKlaviyoConfiguration = async (
  token: string,
  connectorId: string,
  shopify_url: string,
  access_token: string,
  site_id: string,
) => {
  const json = await client
    .new()
    .patch(`/api/klaviyo/${connectorId}`)
    .auth(token)
    .body({
      shopify_url,
      access_token,
      site_id,
    })
    .fetch();
  return json;
};

export const setRechargeConfiguration = async (
  token: string,
  connectorId: string,
  shopify_url: string,
  access_token: string,
) => {
  const json = await client
    .new()
    .patch(`/api/recharge/${connectorId}`)
    .auth(token)
    .body({
      shopify_url,
      access_token,
    })
    .fetch();
  return json;
};

export const setAmazonConfiguration = async (
  token: string,
  connectorId: string,
  region: string,
  attribution_window: string,
  profiles: string[],
) => {
  const json = await client
    .new()
    .patch(`/api/amazon-ads/${connectorId}`)
    .auth(token)
    .body({
      region,
      attribution_window,
      profiles,
    })
    .fetch();
  return json;
};

export const setCriteoConfiguration = async (
  token: string,
  connectorId: string,
  client_id: string,
  access_token: string,
  currency: string,
  report_timezone: string,
) => {
  const json = await client
    .new()
    .patch(`/api/criteo/${connectorId}`)
    .auth(token)
    .body({
      client_id,
      access_token,
      currency,
      report_timezone,
    })
    .fetch();
  return json;
};

export const connectKlaviyoAccount = async (
  token: string,
  site_id: string,
  access_token: string,
  shopify_url: string,
) => {
  const json = await client
    .new()
    .post(`/api/klaviyo`)
    .auth(token)
    .body({
      site_id,
      access_token,
      shopify_url,
    })
    .fetch<
      {
        data: {
          id: string;
        };
      },
      IntegrationError
    >();
  return json;
};

export const connectRechargeAccount = async (
  token: string,
  access_token: string,
  shopify_url: string,
) => {
  const json = await client
    .new()
    .post(`/api/recharge`)
    .auth(token)
    .body({
      access_token,
      shopify_url,
    })
    .fetch<
      {
        data: {
          id: string;
        };
      },
      IntegrationError
    >();
  return json;
};

export const connectCriteoAccount = async (
  token: string,
  client_id: string,
  access_token: string,
  currency: string,
  report_timezone: string,
) => {
  const json = await client
    .new()
    .post(`/api/criteo`)
    .auth(token)
    .body({
      client_id,
      access_token,
      currency,
      report_timezone,
    })
    .fetch<
      {
        data: {
          id: string;
        };
      },
      IntegrationError
    >();
  return json;
};

export const reconnectAccount = (
  connector: string,
  redirect_url: string,
  token: string,
  connectorId: string,
  shop?: string,
) => {
  const connectorKeySafe = getSafeUrlKeyForConnector(connector);
  window.document.location.href =
    (withNewIntegrationService(connector)
      ? getServiceHost("datasource-service")
      : getServiceHost("integrations-service")) +
    `/${connectorKeySafe}/connect?data_source_id=${connectorId}&token=${encodeURIComponent(
      token,
    )}&redirect_url=${encodeURIComponent(redirect_url)}${
      shop ? `&shop=${shop}` : ""
    }`;
};

export const getCustomDatasets = async (
  token: string,
  connectorKey: string,
) => {
  const connectorKeySafe = getSafeUrlKeyForConnector(connectorKey);
  const json = await clientV2
    .new()
    .get(`/api/${connectorKeySafe}/custom-datasets`)
    .auth(token)
    .fetch<{ data: { json_value: CustomDataset[] } | undefined }>();
  return json;
};

export const updateCustomDatasets = async (
  token: string,
  connectorKey: string,
  jsonValue: CustomDataset[],
) => {
  const connectorKeySafe = getSafeUrlKeyForConnector(connectorKey);
  const json = await clientV2
    .new()
    .patch(`/api/${connectorKeySafe}/custom-datasets`)
    .body({ jsonValue })
    .auth(token)
    .fetch<{ data: { json_value: CustomDataset[] } | undefined }>();
  return json;
};

export const deleteCustomDataset = async (
  token: string,
  connectorKey: string,
  tableId: string,
) => {
  const connectorKeySafe = getSafeUrlKeyForConnector(connectorKey);
  const json = await clientV2
    .new()
    .delete(`/api/${connectorKeySafe}/custom-datasets/${tableId}`)
    .auth(token)
    .fetch<{ data: { json_value: CustomDataset[] } }>();
  return json;
};

export const postRequestedConnectors = async (
  token: string,
  body: {
    requestMethod: SupportedRequestMethod;
    requestedConnectors: string[];
    description?: string;
  },
) => {
  const json = await clientV2
    .new()
    .post("/api/requested-connectors")
    .body(body)
    .auth(token)
    .fetch();
  return json;
};

export const getVideoThumbnailUrl = (
  statuses: RefreshStatusData[],
  token: string,
  datasource_id: string,
  identifier: string,
) => {
  const connectorKey =
    statuses.find(
      (s) =>
        s.datasource_id.replaceAll("-", "").toUpperCase() === datasource_id,
    )?.integration ?? "";

  if (connectorKey === "facebook-ads") {
    return `${getServiceHost(
      "datasource-service",
    )}/media/video/connector/facebook-ads/datasource_id/${datasource_id}/identifier/${identifier}?token=${token}`;
  }

  return `${identifier}`;
};

export const validateGoogleSheet = async ({
  token,
  sheetUrl,
  namedRange,
  datasourceId,
}: {
  token: string;
  sheetUrl: string;
  namedRange: string;
  datasourceId: string;
}) => {
  const json = await clientV2
    .new()
    .post(`/api/google-sheets/validate/${datasourceId}`)
    .body({
      sheet_url: sheetUrl,
      named_range: namedRange,
    })
    .auth(token)
    .fetch<{ data: SheetData }>();
  return json;
};

export const previewGoogleSheet = async ({
  token,
  sheetUrl,
  namedRange,
  datasourceId,
  reportType,
}: {
  token: string;
  sheetUrl: string;
  namedRange: string;
  datasourceId: string;
  reportType: string;
}) => {
  const json = await clientV2
    .new()
    .post(`/api/google-sheets/preview/${datasourceId}`)
    .body({
      sheet_url: sheetUrl,
      named_range: namedRange,
      report_type: reportType,
    })
    .auth(token)
    .fetch<{ data: SheetData }>();
  return json;
};

export const triggerDataRefresh = async ({
  token,
  datasourceId,
}: {
  token: string;
  datasourceId: string;
}) => {
  const json = await clientV2
    .new()
    .post(`/api/trigger-data-refresh/${datasourceId}`)
    .body({})
    .auth(token)
    .fetch();
  return json;
};
