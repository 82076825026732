import styled, { css } from "styled-components";

import {
  baseInputFieldColorMap,
  OnBackground,
} from "../shared/baseInputFieldColorMap";
import {
  baseInputFieldSizeStylesMap,
  TextFieldSize,
} from "../shared/baseInputFieldSizeStylesMap";
import { theme } from "../theme";

export const StyledTextField = styled.div``;

interface StyledTextFieldInputProps {
  size: TextFieldSize;
  error?: string | React.ReactNode;
  withBackground: OnBackground;
  hasLeftElement: boolean;
  hasRightIcon: boolean;
  block?: boolean;
  noBorder?: boolean;
  transparent?: boolean;
}

export const StyledTextFieldInputWrapper = styled.div<StyledTextFieldInputProps>`
  position: relative;

  & > input {
    ${({ size, transparent, withBackground }) => {
      const colors =
        baseInputFieldColorMap[transparent ? "transparent" : withBackground];
      return css`
        ${baseInputFieldSizeStylesMap[size]}
        ${colors.root}

        ${transparent ? "padding: 0px !important;" : ""}

    div:hover > div > &:not(:disabled):not(:focus) {
          ${colors.hover}
        }

        &:focus {
          ${colors.focus}
        }
      `;
    }}

    ${({ error }) =>
      error ? `border-color: ${theme.colors.error100} !important;` : ""}

    ${({ noBorder }) =>
      noBorder ? `border: none !important; outline: none !important;` : ""}

  ${({ block }) => (block ? `display: block;` : "")}

  ${({ hasLeftElement }) =>
      hasLeftElement ? `padding-left: ${ICON_WRAPPER_WIDTH};` : ""}
  ${({ hasRightIcon }) =>
      hasRightIcon ? `padding-right: ${ICON_WRAPPER_WIDTH};` : ""}
  }
`;

const ICON_WRAPPER_WIDTH = "30px";

export const StyledTextFieldInputColorSquare = styled.div`
  width: 11px;
  height: 11px;
  border-radius: 2px;
`;

export const StyledTextFieldInputIconWrapper = styled.div<{
  pointer?: boolean;
  right?: boolean;
}>`
  position: absolute;
  top: 0;
  height: 100%;
  width: ${ICON_WRAPPER_WIDTH};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ pointer }) => (pointer ? "cursor: pointer;" : "")}
  ${({ right }) => (right ? "right: 0;" : "")}
`;

export const StyledTextFieldInput = styled.input`
  border: 1px solid transparent;
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  outline: none;

  &::placeholder {
    color: ${theme.colors.text80};
  }

  &:disabled {
    border: 1px dashed ${theme.colors.text80};
  }
`;

export const StyledLeftComponent = styled.div`
  font-family: ${theme.typography.fontFamily};
  font-style: ${theme.typography.fontStyle};
  font-weight: ${theme.typography.fontWeight.regular};
  font-size: ${theme.typography.fontSize.s11px};
  line-height: 34px;
  position: absolute;
  left: 12px;
  top: 0;
  color: ${theme.colors.text95};
`;

export const StyledRightComponent = styled.div`
  font-family: ${theme.typography.fontFamily};
  font-style: ${theme.typography.fontStyle};
  font-weight: ${theme.typography.fontWeight.regular};
  font-size: ${theme.typography.fontSize.s11px};
  line-height: 34px;
  position: absolute;
  right: 12px;
  top: 0;
  color: ${theme.colors.text95};
`;

export const StyledLoadingComponent = styled.div<{ size: TextFieldSize }>`
  position: absolute;
  right: 12px;
  top: 0;
  margin-top: ${({ size }) => (size === "large" ? "6px" : "4px")};
`;
