import styled from "styled-components";

export const SectionWrapperStyled = styled.div`
  padding: 34px;
  background: #ffffff;
  box-shadow:
    0px 2px 4px rgba(43, 150, 210, 0.1),
    0px 12px 32px rgba(43, 150, 210, 0.05);
  border-radius: 8px;
`;
