import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { useAuth } from "../hooks/auth/auth";
import { useBootstrap } from "../hooks/bootstrap";
import { trackEvent, TRACKING_EVENTS } from "../utils/trackingUtils";

interface RoutingAnalyticsProps {
  children?: React.ReactNode;
}

export default function RoutingAnalytics({ children }: RoutingAnalyticsProps) {
  const auth = useAuth();
  const { tenant } = useBootstrap();
  const location = useLocation();

  const [previousPathname, setPreviousPathname] = useState("-1");

  const pathname = location.pathname;
  const search = location.search;
  React.useEffect(() => {
    const fetch = async () => {
      const token = await auth.getToken();
      if (token && pathname !== previousPathname) {
        const params = new URLSearchParams(search);
        const newPlan = params.get("new_plan");
        if (newPlan) {
          trackEvent(TRACKING_EVENTS.USER_UPGRADE, {
            tenant_id: tenant?.id || "N/A",
            plan: newPlan,
          });
        }
        analytics.page(pathname, {
          tenant_id: tenant?.id || "N/A",
          outboundDemoData: JSON.stringify(auth.outboundDemoData),
        });
        setPreviousPathname(pathname);
      }
    };
    void fetch();
  }, [pathname, previousPathname, search, auth, tenant]);

  return <>{children}</>;
}
