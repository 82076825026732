import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";

import useOutsideClickHandler from "../hooks/useOutsideClickHandler";

const MIN_MARGIN = 300;
const MAX_MARGIN = 300;

interface UseDropdownProps {
  onClick?: MouseEventHandler;
  disabled?: boolean;
  withAddButton?: boolean;
  onClickAddButton?: MouseEventHandler<HTMLDivElement>;
  notOpenable?: boolean;
  allowFlexibleHeight?: boolean;
  allowFlexibleWidth?: boolean;
  forceWidthMatch?: boolean;
  allowToggle?: boolean;
  openOnInit?: boolean;
}

const useDropdown = ({
  onClick,
  disabled,
  withAddButton,
  onClickAddButton,
  notOpenable,
  allowFlexibleHeight,
  allowFlexibleWidth,
  forceWidthMatch,
  allowToggle,
  openOnInit = false,
}: UseDropdownProps) => {
  const [open, setOpen] = useState(openOnInit);

  const [node, setNode] = useState<HTMLDivElement | null>(null);
  const [scroll, setScroll] = useState(1);
  const [listPosition, setListPosition] = useState<React.CSSProperties>({});
  const [windowDimension, setWindowDimension] = useState({
    height: 0,
    width: 0,
  });

  const refCallback = useCallback((element: HTMLDivElement) => {
    setNode(element);
  }, []);

  const openDropdown = () => setOpen(true);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(e);
    }

    if (withAddButton && onClickAddButton && notOpenable) {
      onClickAddButton(e);
    }

    if ((allowToggle || !open) && disabled !== true) {
      setOpen((o) => !o);
    }

    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const nodeRectX = node?.getBoundingClientRect().x;
  const nodeRectY = node?.getBoundingClientRect().y;
  useEffect(() => {
    if (!node) {
      return;
    }

    const rect = node.getBoundingClientRect();
    const listPosition: React.CSSProperties = {
      left: Math.max(0, rect.x),
      top: rect.y + rect.height + 2,
      width: forceWidthMatch ? rect.width : undefined,
      minWidth: allowFlexibleWidth ? undefined : rect.width,
      maxHeight: allowFlexibleHeight
        ? Math.max(50, windowDimension.height - 50 - (rect.y + rect.height))
        : Math.min(MAX_MARGIN, windowDimension.height - (rect.y + rect.height)),
    };
    if (windowDimension.width - rect.x < MIN_MARGIN) {
      delete listPosition.left;
      listPosition.right = windowDimension.width - rect.x - rect.width;
    }
    if (!allowFlexibleHeight && windowDimension.height - rect.y < MIN_MARGIN) {
      delete listPosition.top;
      delete listPosition.maxHeight;
      listPosition.bottom = windowDimension.height - rect.y + 2;
    }
    setListPosition(listPosition);
  }, [
    node,
    open,
    nodeRectX,
    nodeRectY,
    windowDimension.height,
    windowDimension.width,
    allowFlexibleWidth,
    allowFlexibleHeight,
    scroll,
    forceWidthMatch,
  ]);

  useEffect(() => {
    const handleScroll = () => {
      setScroll((s) => s + 1);
    };
    const handleResize = () => {
      setWindowDimension({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll, true);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const scrollListener = (e: Event) => {
      if (node && node !== e.target && !node.contains(e.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener("scroll", scrollListener, true);
    return () => {
      document.removeEventListener("scroll", scrollListener);
    };
  }, [node, setOpen]);

  useOutsideClickHandler(close, node);

  return {
    refCallback,
    listPosition,
    open,
    setOpen,
    handleClick,
    close,
    openDropdown,
  };
};

export default useDropdown;
