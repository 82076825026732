import { theme } from "../theme";

export const chipColorStylesMap = {
  warning: {
    default: {
      background: theme.colors.warning30,
      borderColor: theme.colors.warning30,
      color: theme.colors.warning100,
    },
    hover: {
      background: theme.colors.warning40,
      borderColor: theme.colors.warning40,
      color: theme.colors.warning110,
    },
  },
  default: {
    default: {
      background: theme.colors.white100,
      borderColor: theme.colors.borderLight,
      color: theme.colors.text90,
    },
    hover: {
      background: theme.colors.white100,
      borderColor: theme.colors.borderMedium,
      color: theme.colors.text100,
    },
  },
  onBlackBackground: {
    default: {
      background: theme.colors.white12,
      borderColor: theme.colors.white12,
      color: theme.colors.white100,
    },
    hover: {
      background: theme.colors.white100,
      borderColor: theme.colors.white100,
      color: theme.colors.text100,
    },
  },
  selectedOnWhiteBackground: {
    default: {
      background: theme.colors.primary10,
      borderColor: theme.colors.primary20,
      color: theme.colors.primary100,
    },
    hover: {
      background: theme.colors.primary20,
      borderColor: theme.colors.primary30,
      color: theme.colors.primary110,
    },
  },
  selectedOnLightBackground: {
    default: {
      background: theme.colors.bgLight30,
      borderColor: theme.colors.borderMedium,
      color: theme.colors.text100,
    },
    hover: {
      background: theme.colors.grey50,
      borderColor: theme.colors.borderDark,
      color: theme.colors.text100,
    },
  },
  xSmallSelected: {
    default: {
      background: theme.colors.bgLight30,
      borderColor: theme.colors.borderMedium,
      color: theme.colors.text100,
    },
    hover: {
      background: theme.colors.grey50,
      borderColor: theme.colors.borderDark,
      color: theme.colors.text110,
    },
  },
  xxSmall: {
    default: {
      background: theme.colors.white12,
      borderColor: theme.colors.white12,
      color: theme.colors.white100,
    },
    hover: {
      background: theme.colors.white100,
      borderColor: theme.colors.white100,
      color: theme.colors.text110,
    },
  },
  disabled: {
    background: theme.colors.bgLight10,
    border: `dashed 1px ${theme.colors.text80}`,
    color: theme.colors.text80,
  },
};
