import { OnboardingFormData } from "../../../common/types/users-service";
import { Flex, Logo, Space, Stack, Text, theme } from "../../../icecube-ux";
import { SelectableCard } from "../../../icecube-ux/SelectableCard";
import { _ } from "../../../languages/helper";
import TrustedBy from "../../login/components/TrustedBy";
import {
  OnboardingFormQuestion,
  onboardingQuestions,
} from "../onboardingQuestions";

import {
  StyledOnboardingFormQuestionSlide,
  StyledOnboardingFormQuestionSlideBody,
  StyledOnboardingFormQuestionSlideBodyContents,
  StyledOnboardingFormQuestionSlideFooter,
  StyledOnboardingFormQuestionSlideFooterButton,
  StyledOnboardingFormQuestionSlideFormPanel,
  StyledOnboardingFormQuestionSlideHeader,
  StyledOnboardingFormQuestionSlideHeaderWrapper,
  StyledOnboardingFormQuestionSlideIllustrationPanel,
  StyledOnboardingFormQuestionSlideIllustrationPanelBody,
  StyledOnboardingFormQuestionSlideIllustrationPanelBodyImage,
  StyledOnboardingFormQuestionSlideIllustrationPanelFooter,
  StyledOnboardingFormQuestionSlideIllustrationPanelHeader,
  StyledOnboardingFormQuestionSlideOptions,
} from "./OnboardingFormQuestionSlide.styles";
import OnboardingProgressBar from "./OnboardingProgressBar/OnboardingProgressBar";

interface OnboardingFormProps {
  onSubmit: (
    latestResponses?: OnboardingFormData["responses"],
  ) => Promise<void>;
  currentQuestion: OnboardingFormQuestion;
  currentResponse: OnboardingFormData["responses"][0];
  setOnboardingForm: React.Dispatch<React.SetStateAction<OnboardingFormData>>;
  previousQuestion?: () => void;
  currentQuestionIndex: number;
}

function OnboardingFormQuestionSlide({
  onSubmit,
  currentQuestion,
  currentQuestionIndex,
  currentResponse,
  setOnboardingForm,
  previousQuestion,
}: OnboardingFormProps) {
  const TEXT_LINES = [
    _`Unlock personalized insights`,
    _`Track accurate attribution`,
    _`Master omnichannel presence`,
    _`Data-driven decisions in real-time`,
    _`Flexible scalability for limitless evolution`,
  ];

  return (
    <StyledOnboardingFormQuestionSlide>
      <StyledOnboardingFormQuestionSlideFormPanel>
        <StyledOnboardingFormQuestionSlideHeaderWrapper>
          <StyledOnboardingFormQuestionSlideHeader>
            <Logo width={140} variant="colored" />
            <Text
              variant="body20Bold"
              color={theme.colors.text110}
              textAlign="center"
            >
              {_(currentQuestion.title)}
            </Text>
            <Space size={40} />
            <Text
              variant="body16SemiBold"
              color={theme.colors.text110}
              style={{ alignSelf: "flex-start" }}
            >
              {_(currentQuestion.question)}
            </Text>
            <Space />
          </StyledOnboardingFormQuestionSlideHeader>
        </StyledOnboardingFormQuestionSlideHeaderWrapper>
        <StyledOnboardingFormQuestionSlideBody>
          <StyledOnboardingFormQuestionSlideBodyContents>
            <Space />
            <StyledOnboardingFormQuestionSlideOptions>
              {currentQuestion.options.map((option) => (
                <SelectableCard
                  key={option.key}
                  id={option.key}
                  label={_(option.label)}
                  checked={currentResponse.selection.includes(option.key)}
                  type={currentQuestion.inputType}
                  onChange={() => {
                    setOnboardingForm((prev) => ({
                      ...prev,
                      responses: prev.responses.map((response) => {
                        if (response.questionKey === currentQuestion.key) {
                          if (currentQuestion.inputType === "radio") {
                            return {
                              ...response,
                              selection: [option.key],
                            };
                          } else {
                            return {
                              ...response,
                              selection: response.selection.includes(option.key)
                                ? response.selection.filter(
                                    (selection) => selection !== option.key,
                                  )
                                : [...response.selection, option.key],
                            };
                          }
                        } else {
                          return response;
                        }
                      }),
                    }));
                  }}
                />
              ))}
            </StyledOnboardingFormQuestionSlideOptions>
            <Space size={100} />
          </StyledOnboardingFormQuestionSlideBodyContents>
        </StyledOnboardingFormQuestionSlideBody>
        <OnboardingProgressBar
          progress={(currentQuestionIndex + 1) / onboardingQuestions.length}
        />
        <StyledOnboardingFormQuestionSlideFooter>
          {previousQuestion && (
            <StyledOnboardingFormQuestionSlideFooterButton
              color="tertiary"
              size="xxlarge"
              onClick={previousQuestion}
            >
              {_`Previous|||page`}
            </StyledOnboardingFormQuestionSlideFooterButton>
          )}
          <StyledOnboardingFormQuestionSlideFooterButton
            disabled={currentResponse.selection.length === 0}
            onClick={() => void onSubmit()}
            size="xxlarge"
          >
            {_`Continue`}
          </StyledOnboardingFormQuestionSlideFooterButton>
        </StyledOnboardingFormQuestionSlideFooter>
      </StyledOnboardingFormQuestionSlideFormPanel>
      <StyledOnboardingFormQuestionSlideIllustrationPanel>
        <StyledOnboardingFormQuestionSlideIllustrationPanelBody>
          <StyledOnboardingFormQuestionSlideIllustrationPanelBodyImage src="/img/onboarding-screens-illustration.png" />
        </StyledOnboardingFormQuestionSlideIllustrationPanelBody>
        <StyledOnboardingFormQuestionSlideIllustrationPanelHeader>
          <Text
            color={theme.colors.white100}
            variant="body24Regular"
            textAlign="center"
          >
            {_`Data to decisions,`}{" "}
            <Text
              color={theme.colors.secondary100}
              variant="body24Regular"
              style={{ fontStyle: "italic" }}
              inline
            >
              {_`All in one place`}
            </Text>
          </Text>
          <Space size={24} />
          <Stack gap={4}>
            {TEXT_LINES.map((line, index) => (
              <Flex key={`line-${index}`} alignItems="center" gap={12}>
                <img src="/img/Check.svg" alt="" />
                <Text color="#C3BFFF" variant="body16Regular">
                  {line}
                </Text>
              </Flex>
            ))}
          </Stack>
        </StyledOnboardingFormQuestionSlideIllustrationPanelHeader>
        <StyledOnboardingFormQuestionSlideIllustrationPanelFooter>
          <TrustedBy variant="dark-background" style={{ padding: "0 16px" }} />
        </StyledOnboardingFormQuestionSlideIllustrationPanelFooter>
      </StyledOnboardingFormQuestionSlideIllustrationPanel>
    </StyledOnboardingFormQuestionSlide>
  );
}

export default OnboardingFormQuestionSlide;
