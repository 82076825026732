import React from "react";

import { pluralize } from "../../../utils/languageUtils";
import { Counter } from "../../Counter";
import { theme } from "../../theme";
import { Text } from "../../Typography";

import {
  StyledFunctionHelper,
  StyledFunctionHelperTitle,
  StyledFunctionHelperContent,
  StyledFunctionCurrentParameter,
} from "./FunctionHelper.styled";

interface FunctionHelperParameters {
  name: string;
  description: string;
}

export interface FunctionHelperProps {
  name: string;
  highlighted: number;
  parameters: FunctionHelperParameters[];
}

export default function FunctionHelper({
  name,
  highlighted,
  parameters,
}: FunctionHelperProps) {
  return (
    <StyledFunctionHelper>
      <StyledFunctionHelperTitle>
        <Text variant="body11Medium">{name}()</Text>
        <Counter
          value={`${parameters.length} ${pluralize(
            parameters.length,
            "argument",
          )}`}
          variant="small"
        />
      </StyledFunctionHelperTitle>
      <StyledFunctionHelperContent>
        <Text
          variant="body10Regular"
          color={theme.colors.text90}
          style={{ whiteSpace: "nowrap" }}
        >
          {name}({" "}
          {parameters.map((p, id) => (
            <React.Fragment key={`p-${p.name}-${id}`}>
              {highlighted === id ? (
                <StyledFunctionCurrentParameter>
                  {p.name}
                </StyledFunctionCurrentParameter>
              ) : (
                p.name
              )}
              {id < parameters.length - 1 && ", "}
            </React.Fragment>
          ))}{" "}
          )
        </Text>
        <Text
          variant="body10Regular"
          color={theme.colors.text80}
          fontStyle="italic"
        >
          {parameters[highlighted]?.description}
        </Text>
      </StyledFunctionHelperContent>
    </StyledFunctionHelper>
  );
}
