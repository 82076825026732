import { useEffect, useState } from "react";

import { useAuth } from "../../hooks/auth/auth";
import { useBootstrap } from "../../hooks/bootstrap";
import { useCustomElementEditors } from "../../hooks/customElementEditors";
import {
  Box,
  Button,
  Grid,
  Icon,
  IconButton,
  List,
  Loader,
  Popup,
  Text,
  theme,
} from "../../icecube-ux";
import { _ } from "../../languages/helper";
import { getCustomDimensions } from "../../lib/customDimensionService";
import { ListOptionActionsHoverWrapper } from "../../pages/custom/ListOptionActionsHoverWrapper";
import { trackEvent, TRACKING_EVENTS } from "../../utils/trackingUtils";

import { CustomDimensionRecord } from "./types";

interface CustomBreakdownsListPopupProps {
  onClose: () => void;
}

export default function CustomBreakdownsListPopup({
  onClose,
}: CustomBreakdownsListPopupProps) {
  const auth = useAuth();
  const { tenant } = useBootstrap();
  const editor = useCustomElementEditors();

  const [loading, setLoading] = useState(false);
  const [dimensions, setDimensions] = useState<CustomDimensionRecord[]>([]);

  const handleNewDimension = () => {
    onClose();
    editor.createDimension();
    trackEvent(TRACKING_EVENTS.CUSTOM_DIMENSION_SAVE, {
      tenant_id: tenant?.id || "N/A",
    });
  };

  const handleEditDimension = (id: string) => {
    onClose();
    editor.editDimension(id);
    trackEvent(TRACKING_EVENTS.CUSTOM_DIMENSION_EDIT, {
      tenant_id: tenant?.id || "N/A",
    });
  };

  const handleDeleteDimension = (e: React.MouseEvent, id: string) => {
    e.stopPropagation();
    e.preventDefault();
    editor.deleteDimension(id, () => {
      setDimensions((dimensions) => dimensions.filter((d) => d.id !== id));
    });
  };

  useEffect(() => {
    if (!auth.processing) {
      void (async () => {
        setLoading(true);
        const dimensions = await getCustomDimensions(await auth.getToken());
        setDimensions(dimensions);
        setLoading(false);
      })();
    }
  }, [auth]);

  return (
    <Popup
      headerComponent={
        <Popup.BaseHeader>
          <Text variant="body16SemiBold">{_`Custom dimensions`}</Text>
        </Popup.BaseHeader>
      }
      footerComponent={
        <Grid gridTemplateColumns="1fr 1fr" gap={12}>
          <Button leftIcon="ArrowLeft" color="tertiary" onClick={onClose}>
            {_`Back to custom report`}
          </Button>
          <Button leftIcon="Add" color="secondary" onClick={handleNewDimension}>
            {_`New custom dimension`}
          </Button>
        </Grid>
      }
      onClose={onClose}
      withPaddingTop
    >
      <Box marginTop="18px">
        {loading && (
          <Box margin="20px" textAlign="center">
            <Loader />
          </Box>
        )}
        {!loading && dimensions.length === 0 && (
          <Box
            border={`1px solid ${theme.colors.borderLight}`}
            background={theme.colors.bgLight10}
            borderRadius={theme.borderRadius.r4px}
            width={500}
            height={390}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap="21px"
          >
            <Box
              borderRadius="50%"
              background={theme.colors.bgLight30}
              color={theme.colors.primary40}
              width="130px"
              height="130px"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Icon name="Search" size={70} />
            </Box>
            <Box textAlign="center">
              <Text variant="body15SemiBold">{_`No custom dimensions`}</Text>
              <Text variant="body12Regular" color={theme.colors.primary60}>
                {_`Use the button below to create your first custom dimension.`}
              </Text>
            </Box>
          </Box>
        )}
        {!loading && dimensions.length > 0 && (
          <List
            onClickOnElement={(e) => handleEditDimension(`${e}`)}
            options={dimensions.map((dim) => ({
              label: dim.title,
              value: dim.id,
              rightComponents: [
                <ListOptionActionsHoverWrapper>
                  <Button
                    key="edit"
                    color="link-secondary"
                    leftIcon="Edit"
                    size="tiny"
                    onClick={() => handleEditDimension(dim.id)}
                  >
                    {_`Edit`}
                  </Button>
                  <IconButton
                    key="delete"
                    name="Delete"
                    size="tiny"
                    color="error"
                    onClick={(e) => handleDeleteDimension(e, dim.id)}
                  />
                </ListOptionActionsHoverWrapper>,
              ],
            }))}
            optionHoverStyle={{
              background: theme.colors.bgLight10,
            }}
            selected={[]}
          />
        )}
      </Box>
    </Popup>
  );
}
