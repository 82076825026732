import { createContext, useContext, useState } from "react";

import { Button } from "../icecube-ux";
import { _ } from "../languages/helper";
import {
  deleteCustomDimension,
  duplicateCustomDimension,
} from "../lib/customDimensionService";
import {
  createCustomMetric,
  deleteCustomMetric,
  duplicateCustomMetric,
  patchCustomMetric,
} from "../lib/customMetricService";
import {
  CustomMetricDefinition,
  DEFAULT_CUSTOM_METRIC_FORMATTING,
} from "../types/synthesizer";

import { useAuth } from "./auth/auth";
import {
  CustomDimensionCategory,
  CustomElementEditorsContextProps,
} from "./customElementEditorsTypes";
import { useMetricList } from "./metricList";
import { useModals } from "./modals";
import {
  LOCAL_STORAGE_KEYS,
  removeMatchingLocalStorageKeys,
} from "./useLocalStorage";

const CustomElementEditorsContext =
  createContext<CustomElementEditorsContextProps | null>(null);

export function ProvideCustomElementEditors({
  children,
}: {
  children: React.ReactNode;
}) {
  const provider = useProvideCustomElementEditors();
  return (
    <CustomElementEditorsContext.Provider value={provider}>
      {children}
    </CustomElementEditorsContext.Provider>
  );
}

const DEFAULT_EMPTY_METRIC: CustomMetricDefinition = {
  id: 0,
  title: "",
  elements: [],
  description: "",
  formatting: DEFAULT_CUSTOM_METRIC_FORMATTING,
};

export const useCustomElementEditors = () => {
  const context = useContext(CustomElementEditorsContext);
  if (context === null) {
    throw Error("CustomElementEditors context not provided");
  }
  return context;
};

export const CREATION_CUSTOM_METRIC_ID = "0";
export const EMPTY_STATE_CUSTOM_METRIC_ID = "";

export const isCustomMetricDefinitionDifferent = (
  a?: CustomMetricDefinition,
  b?: CustomMetricDefinition,
) => {
  return (
    JSON.stringify(
      Object.fromEntries(
        Object.entries(a || DEFAULT_EMPTY_METRIC).sort(([key1], [key2]) =>
          key1.localeCompare(key2),
        ),
      ),
    ) !==
    JSON.stringify(
      Object.fromEntries(
        Object.entries(b || DEFAULT_EMPTY_METRIC).sort(([key1], [key2]) =>
          key1.localeCompare(key2),
        ),
      ),
    )
  );
};

const deepCloneJsonObject = <T,>(customDefinition: T) => {
  return JSON.parse(JSON.stringify(customDefinition)) as T;
};

const useProvideCustomElementEditors = () => {
  const auth = useAuth();
  const { refresh, customMetricsDefinitions } = useMetricList();
  const [editingMetrics, setEditingMetrics] = useState<
    CustomMetricDefinition[]
  >([]);
  const { showLoader, hideLoader, confirm, alert } = useModals();
  const [disableEdit, setDisableEdit] = useState(false);
  const [dimensionCategory, setDimensionCategory] =
    useState<CustomDimensionCategory>("any");
  const [openedMetricEditor, setOpenedMetricEditor] = useState<string | null>(
    null,
  );
  const [openedDimensionEditor, setOpenedDimensionEditor] = useState<
    string | null
  >(null);
  const [openedDimensionList, setOpenedDimensionList] = useState(false);

  const createMetric = () => {
    setOpenedMetricEditor(CREATION_CUSTOM_METRIC_ID);
    if (!editingMetrics.find((m) => m.id === 0)) {
      setEditingMetrics((prev) => {
        return [...prev, DEFAULT_EMPTY_METRIC];
      });
    }
  };

  const handleOpenReport = (id: string) => {
    window.open(`/custom/create/${id}`, "_blank");
  };

  const saveCustomMetric = async (
    onError: () => void,
    forceMetric?: CustomMetricDefinition,
  ) => {
    const id = openedMetricEditor;
    if (!id) {
      return;
    }
    const metric =
      forceMetric ||
      editingMetrics.find((m) => m.id.toString() === openedMetricEditor) ||
      DEFAULT_EMPTY_METRIC;
    if (id && id !== CREATION_CUSTOM_METRIC_ID) {
      const result = await patchCustomMetric(
        await auth.getToken(),
        metric.title,
        metric,
        id,
      );
      if (result.error) {
        onError();
        hideLoader();
        if (result.conflictingReports) {
          alert({
            danger: true,
            title: _`Impossible to save your changes`,
            texts: [
              _`The updated version of this metric is not comptaible with the following reports:`,
              ...result.conflictingReports.map(([id, title]) => (
                <Button
                  color="link-tertiary"
                  rightIcon="ExternalLink"
                  onClick={() => handleOpenReport(id)}
                >
                  {title}
                </Button>
              )),
              _`You can either update the reports or create a new metric.`,
            ],
          });
        } else {
          alert({
            danger: true,
            title: _`Impossible to save your changes`,
            texts: [result.message || _`Unknown error, please try again.`],
          });
        }
        return;
      } else {
        refresh();
      }
    } else {
      await saveNewCustomMetricInDb(metric);
    }
  };
  const saveNewCustomMetricInDb = async ({
    id, // remove the id from the metric object
    ...metric
  }: CustomMetricDefinition) => {
    const createdId = await createCustomMetric(
      await auth.getToken(),
      metric.title,
      metric,
    );
    if (createdId) {
      setEditingMetrics((prev) => {
        return prev.map((m) => {
          if (m.id === 0) {
            return {
              id: parseInt(createdId),
              ...metric,
            };
          }
          return m;
        });
      });
      setOpenedMetricEditor(createdId.toString());
      refresh();
    }
  };

  const createDimension = (category: CustomDimensionCategory = "any") => {
    setDimensionCategory(category);
    setOpenedDimensionEditor("");
    setDisableEdit(false);
  };

  const editMetric = (id: string) => {
    // IMPORTANT: id CREATION_CUSTOM_METRIC_ID is for creation
    setOpenedMetricEditor(id);
    setEditingMetrics((prev) => {
      const targetCustomMetric = customMetricsDefinitions.find(
        (m) => m.id.toString() === id,
      );
      if (
        targetCustomMetric &&
        !prev.find((m) => m.id === targetCustomMetric.id)
      ) {
        return [
          ...prev,
          deepCloneJsonObject<CustomMetricDefinition>(targetCustomMetric),
        ];
      } else {
        return prev;
      }
    });
  };

  const closeMetric = (id: string) => {
    const actions = () => {
      const list = editingMetrics.filter((m) => m.id.toString() !== id);
      if (list.length === 0) {
        setEditingMetrics([]);
        if (openedMetricEditor !== null) {
          setOpenedMetricEditor(EMPTY_STATE_CUSTOM_METRIC_ID);
        }
      } else {
        setEditingMetrics(list);
        setOpenedMetricEditor(list[list.length - 1].id.toString());
      }
    };
    const targetCustomMetric = editingMetrics.find(
      (m) => m.id.toString() === id,
    );
    if (
      targetCustomMetric &&
      isCustomMetricDefinitionDifferent(
        targetCustomMetric,
        customMetricsDefinitions.find((m) => m.id.toString() === id),
      )
    ) {
      confirm({
        danger: true,
        title: _`Abandon changes`,
        texts: [_`Are you sure you want to leave the custom metric builder?`],
        onConfirm: () => {
          actions();
        },
      });
    } else {
      actions();
    }
  };
  const recordMetricChanges = (
    id: string,
    newMetric: CustomMetricDefinition,
  ) => {
    setEditingMetrics((prev) => {
      const targetIndex = prev.findIndex((m) => m.id.toString() === id);
      if (targetIndex === -1) {
        return prev;
      }
      const newMetrics = [...prev];
      newMetrics[targetIndex] = {
        ...newMetric,
      };
      return newMetrics;
    });
  };

  const discardMetricChanges = (id: string) => {
    setEditingMetrics((prev) => {
      const targetIndex = prev.findIndex((m) => m.id.toString() === id);
      if (targetIndex === -1) {
        return prev;
      }
      const newMetrics = [...prev];
      const newMetric =
        customMetricsDefinitions.find((m) => m.id.toString() === id) ||
        DEFAULT_EMPTY_METRIC;
      newMetrics[targetIndex] =
        deepCloneJsonObject<CustomMetricDefinition>(newMetric);
      return newMetrics;
    });
  };

  const editDimension = (
    id: string,
    category: CustomDimensionCategory = "any",
  ) => {
    setDimensionCategory(category);
    // IMPORTANT: CREATION_CUSTOM_METRIC_ID is for creation
    setOpenedDimensionEditor(id === CREATION_CUSTOM_METRIC_ID ? "" : id);
  };

  const duplicateMetric = async (id: string) => {
    showLoader();
    await duplicateCustomMetric(await auth.getToken(), id);
    refresh();
    hideLoader();
  };

  const clearDimensionLocalStorageCache = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.CUSTOM_METRIC_DIMENSIONS);
    removeMatchingLocalStorageKeys((key) =>
      key.startsWith(LOCAL_STORAGE_KEYS.DASH_VIEWS_DIMENSIONS_PROVIDER_PREFIX),
    );
  };

  const duplicateDimension = async (
    id: string,
    duplicationCallback?: (id: string) => void,
  ) => {
    showLoader();
    const newId = await duplicateCustomDimension(await auth.getToken(), id);
    clearDimensionLocalStorageCache();
    refresh();
    hideLoader();
    newId && duplicationCallback?.(newId);
  };

  const deleteMetric = (id: string, deletionCallback?: () => void) => {
    confirm({
      danger: true,
      title: _`Deleting metric`,
      texts: [_`Are you sure you want to delete this metric?`],
      onConfirm: async () => {
        showLoader();
        const deleted = await deleteCustomMetric(await auth.getToken(), id);
        hideLoader();
        if (!deleted) {
          alert({
            danger: true,
            title: _`Warning`,
            texts: [
              _`This metric cannot be deleted at the moment, because it is currently in use.`,
            ],
          });
          return;
        }
        deletionCallback && deletionCallback();
        closeMetric(id);
        refresh();
      },
    });
  };
  const deleteDimension = (id: string, deletionCallback?: () => void) => {
    confirm({
      danger: true,
      title: _`Deleting dimension`,
      texts: [_`Are you sure you want to delete this dimension?`],
      onConfirm: async () => {
        showLoader();
        const deleted = await deleteCustomDimension(await auth.getToken(), id);
        hideLoader();
        if (!deleted) {
          alert({
            danger: true,
            title: _`Warning`,
            texts: [
              _`This dimension cannot be deleted at the moment, because it is currently in use.`,
            ],
          });
          return;
        }
        clearDimensionLocalStorageCache();
        deletionCallback && deletionCallback();
        setOpenedDimensionEditor(null);
        refresh();
      },
    });
  };

  const hideMetric = () => {
    const cleanup = () => {
      setOpenedMetricEditor(null);
      setEditingMetrics([]);
      refresh();
    };
    // check if having unsaved changes
    if (
      editingMetrics.some((m) => {
        const targetCustomMetric = customMetricsDefinitions.find(
          (cm) => cm.id === m.id,
        );
        return isCustomMetricDefinitionDifferent(targetCustomMetric, m);
      })
    ) {
      confirm({
        danger: true,
        title: _`Abandon changes`,
        texts: [_`Are you sure you want to leave the custom metric builder?`],
        onConfirm: () => {
          cleanup();
        },
      });
    } else {
      cleanup();
    }
  };
  const hideDimension = () => {
    setOpenedDimensionEditor(null);
    refresh();
  };

  const showDimensionList = () => setOpenedDimensionList(true);
  const hideDimensionList = () => setOpenedDimensionList(false);

  const openMetricEditor = () =>
    setOpenedMetricEditor(EMPTY_STATE_CUSTOM_METRIC_ID);
  const hideMetricEditor = () => setOpenedMetricEditor(null);

  return {
    editingMetrics,

    openedMetricEditor,
    openedDimensionEditor,
    dimensionCategory,
    setDimensionCategory,

    createMetric,
    editMetric,
    closeMetric,
    hideMetric,
    recordMetricChanges,
    discardMetricChanges,
    saveCustomMetric,

    openMetricEditor,
    hideMetricEditor,

    createDimension,
    editDimension,
    hideDimension,
    duplicateMetric,
    duplicateDimension,
    deleteMetric,
    deleteDimension,
    showDimensionList,
    hideDimensionList,
    openedDimensionList,
    disableEdit,
    setDisableEdit,
  };
};
