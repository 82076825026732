import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import PolarLogo from "../assets/logo.svg";

const StyledLogoWrapper = styled(Link)<{ $isNavOpen: boolean }>`
  display: block;
  position: relative;
  overflow: hidden;
  margin: 18px 0 0 18px;
  flex-shrink: 0;

  ${(p) =>
    !p.$isNavOpen &&
    css`
      margin: 0;
      display: flex;
      justify-content: center;
    `}
}
`;

const StyledSmallLogoIcon = styled.img<{ isShown: boolean }>`
  cursor: pointer;
  margin: 16px 0;
  height: 32px;
  width: 32px;
  display: block;

  ${(p) =>
    !p.isShown &&
    css`
      display: none;
    `}
`;

const StyledFullLogo = styled.img<{ isShown: boolean }>`
  ${(p) =>
    !p.isShown &&
    css`
      display: none;
    `}
`;

type NavigationHeaderProps = {
  isNavOpen: boolean;
};

export const NavigationHeader = ({ isNavOpen }: NavigationHeaderProps) => {
  return (
    <StyledLogoWrapper $isNavOpen={isNavOpen} to="/">
      <StyledFullLogo isShown={isNavOpen} src={PolarLogo} alt="" />

      <StyledSmallLogoIcon
        isShown={!isNavOpen}
        src="/img/polar-logo.png"
        alt=""
      />
    </StyledLogoWrapper>
  );
};
