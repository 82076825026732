import React from "react";

import "../global.css";
import { WarningIcon } from "../Icons/Icons";
import "./error-block.css";

interface ErrorBlockProps {
  line1: string | React.ReactElement;
  line2: string | React.ReactElement;
  mobile?: boolean;
}

export function ErrorBlock({
  line1,
  line2,
  mobile = false,
  className,
}: ErrorBlockProps &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >) {
  return (
    <div className={"error-block " + (mobile ? "mobile " : "") + className}>
      <WarningIcon size={mobile ? 20 : 26} className="warning-block" />
      <div>
        <div className="line1">{line1}</div>
        <div className="line2">{line2}</div>
      </div>
    </div>
  );
}
