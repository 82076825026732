import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  key: "snapchat-ads",
  category: INTEGRATION_CATEGORIES.advertising,
  image: "Snapchat",
  dependsOnAccounts: true,
  backgroundColor: theme.customColors.snapchatBg,
  borderColor: theme.customColors.snapchatBorder,
  label: "Snap Ads",
  adPlatformChannelName: "Snapchat Ads",
  utmGuide: "6778982",
  get description() {
    return _`Online advertising platform from ${["provider", "SnapChat"]}`;
  },
  canReconnectOAuth: true,
});
