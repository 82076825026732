import styled, { css } from "styled-components";

import { ButtonColor } from "../Button";
import { colorStylesMap } from "../Button/colorStylesMap";
import { StyledIcon } from "../Icon/Icon.styled";
import { StyledLabel } from "../Label/Label.styled";
import { legacyTheme } from "../legacyTheme";
import { OnBackground } from "../shared/baseInputFieldColorMap";
import {
  baseInputFieldSizeStylesMap,
  TextFieldSize,
} from "../shared/baseInputFieldSizeStylesMap";
import { theme } from "../theme";

export const StyledDropdown = styled.div<{
  block: boolean;
  size: TextFieldSize;
  withBackground: OnBackground;
  open: boolean;
  disabled: boolean;
  color: ButtonColor;
}>`
  display: inline-flex;
  border: 1px solid transparent;
  justify-content: space-between;
  align-items: stretch;
  cursor: pointer;

  &.block {
    display: flex;
  }

  ${StyledLabel} {
    flex-grow: 1;
  }
  ${({ color, size, open, disabled }) => {
    const colorStyle = colorStylesMap[color];
    return css`
      ${baseInputFieldSizeStylesMap[size]}
      border-radius: ${baseInputFieldSizeStylesMap[size].borderRadius};
      ${open ? { ...colorStyle.root, ...colorStyle.hover } : colorStyle.root}

      ${disabled &&
      css`
        border: 1px dashed ${theme.colors.text80};
        cursor: default;
      `}
    `;
  }}
  padding: 0;
`;

export const StyledDropdownMainContent = styled.div<{
  size: TextFieldSize;
  template: string;
  open: boolean;
  color: ButtonColor;
}>`
  display: grid;
  grid-template-columns: ${({ template }) => template};
  align-items: center;
  flex-grow: 1;
  height: 100%;
  gap: 4px;
  border: 1px solid transparent;

  ${({ size }) => {
    return css`
      ${baseInputFieldSizeStylesMap[size]}
    `;
  }};
`;

export const StyledDropdownValueDisplay = styled.div<{
  disabled: boolean;
  open: boolean;
  color: ButtonColor;
}>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ color, open }) => {
    const colorStyle = colorStylesMap[color];

    return css`
      ${open ? { ...colorStyle.root, ...colorStyle.hover } : colorStyle.root}
    `;
  }};
`;

export const StyledDropdownIconRight = styled.div`
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  & .droper {
    margin-right: -4px;
  }
`;

export const StyledDropdownAddButton = styled.div<{
  withBackground: OnBackground;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  border-left: 1px solid
    ${({ withBackground }) =>
      withBackground === "whiteBackground"
        ? theme.colors.borderLight
        : theme.colors.borderMedium};
  width: 34px;
  text-align: center;

  ${StyledIcon} {
    vertical-align: middle;
  }
`;

export const StyleDropdownChildren = styled.div<{ maxHeight?: string }>`
  position: fixed;
  z-index: ${legacyTheme.zIndexes.layer4};
  border-radius: ${theme.borderRadius.r4px};
  background: ${theme.colors.white100};
  box-sizing: border-box;
  padding: 4px 8px;
  box-shadow: ${theme.shadows.regular};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "300px")};
  overflow: auto;
`;

export const SyledFixedBottomShadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: ${theme.zIndexes.layer5};
`;

export const StyledConnectorIconWrapper = styled.div`
  background: ${theme.customColors.googleFbInstaBg};
  border-radius: ${theme.borderRadius.r2px};
  border: 1px solid ${theme.customColors.googleFbInstaBorder};
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
