import { Button } from "../Button";
import { ConnectorIconNames } from "../ConnectorIcon";
import { Flex } from "../Flex";
import { IconNames } from "../Icon/Icon";

interface QuickFilterListProps {
  elements: {
    label: string;
    value: string;
    icon?: IconNames | ConnectorIconNames;
    onClick: () => void;
  }[];
  selectedValue: string;
}

export const QuickFilterList = ({
  elements,
  selectedValue,
}: QuickFilterListProps) => {
  return (
    <Flex gap={8}>
      {elements.map((item) => (
        <Button
          key={item.value}
          size="small"
          color={item.value === selectedValue ? "secondary" : "tertiary"}
          leftIcon={item.icon}
          onClick={() => item.onClick()}
        >
          {item.label}
        </Button>
      ))}
    </Flex>
  );
};
