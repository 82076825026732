import React from "react";

import { Button } from "../../icecube-ux";
import { _ } from "../../languages/helper";
import "../global.css";
import { CheckIcon } from "../Icons/Icons";
import "./plan-card.css";

export function PlanCardContainer(
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >,
) {
  return (
    <div {...props} className={"plan-card-container " + props.className}>
      {props.children}
    </div>
  );
}

interface PlanCardProps {
  image: string;
  title: string;
  price: string;
  granularity: string;
  line1: string;
  line2: string;
  item1: string;
  item2: string;
  item3: string;
  item4: string;
  item5?: string;
  loading?: boolean;
  onClick?: () => void;
  selected?: boolean;
  disabled?: boolean;
}

export function PlanCard({
  image,
  title,
  price,
  granularity,
  line1,
  line2,
  item1,
  item2,
  item3,
  item4,
  item5 = "",
  loading = false,
  selected = false,
  disabled = false,
  onClick,
}: PlanCardProps) {
  return (
    <div className={"plan-card " + (selected || disabled ? " selected" : "")}>
      <div>
        <div className="title-block">
          <img src={image} alt="" />
          <div className="title">{title}</div>
          <div className="price">
            {price}
            <span> / {granularity}</span>
          </div>
        </div>
        <div className="line-block">
          <div className="line1">{line1}</div>
          <div className="line2">{line2}</div>
        </div>
        <div className="item-block">
          <div className="item">
            <CheckIcon /> {item1}
          </div>
          <div className="item">
            <CheckIcon /> {item2}
          </div>
          <div className="item">
            <CheckIcon /> {item3}
          </div>
          <div className="item">
            <CheckIcon /> {item4}
          </div>
          {item5 && (
            <div className="item">
              <CheckIcon /> {item5}
            </div>
          )}
        </div>
      </div>
      {selected && !disabled && (
        <Button block={true} disabled={true}>
          {_`Selected plan`}
        </Button>
      )}
      {!selected && !disabled && (
        <Button block={true} onClick={onClick} loading={loading}>
          {_`Choose plan`}
        </Button>
      )}
    </div>
  );
}
