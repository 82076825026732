import styled from "styled-components";

import { Flex } from "../Flex";

export const StyledSetupBookingBlockMain = styled(Flex).attrs({
  direction: "row",
})`
  flex-grow: 1;
  padding: 20px 60px;
  align-items: center;
  box-sizing: border-box;
  height: 90px;

  @media (max-width: 1300px) {
    padding: 20px 30px;
  }
`;

export const StyledSetupBookingBlockMainContent = styled.div`
  margin-right: 30px;
  flex-grow: 0;
`;

export const StyledSetupBookingBlockMainAction = styled.div`
  margin-left: auto;
`;

export const StyledSetupBookingBlockContent = styled.div``;
