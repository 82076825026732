import { theme } from "../../theme";

interface VerticalLineProps {
  height: number;
  margin: [number, number, number, number];
  isVisible: boolean;
  index?: number;
  xPosition?: number;
}

export const VerticalLine = ({
  height,
  margin,
  isVisible,
  index,
  xPosition,
}: VerticalLineProps) => {
  return index !== undefined && isVisible && xPosition ? (
    <line
      key={index}
      x1={xPosition}
      x2={xPosition}
      y1={height - margin[2]}
      y2={margin[0]}
      stroke={theme.colors.borderLight}
    />
  ) : (
    <line></line>
  );
};
