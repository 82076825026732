import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

const openTab = (e: React.MouseEvent<HTMLAnchorElement>) => {
  e.preventDefault();
  e.stopPropagation();
  window
    .open(
      "https://app.omnisend.com/o/my-account/integrations/api-keys",
      "_blank",
    )
    ?.focus();
};

registerFrontendIntegration({
  key: "omnisend",

  category: INTEGRATION_CATEGORIES.marketing,
  image: "Omnisend",
  label: "Omnisend",
  isNewIntegrationService: true,

  dependsOnAccounts: false,
  backgroundColor: theme.customColors.fairingBg,
  borderColor: theme.customColors.fairingBorder,
  get description() {
    return _`E-commerce e-mail marketing and SMS platform`;
  },
  get userInstructions() {
    return (
      <>
        {_`To learn how to find your API Key, please go to`}{" "}
        <a href="/#" onClick={openTab}>
          {_`your account page`}
        </a>
        .
      </>
    );
  },
});
