import "react-notifications/lib/notifications.css";
import { Outlet } from "react-router-dom";

import { ProvideAuth } from "../../hooks/auth/ProvideAuth";
import { ProvideBanners } from "../../hooks/banners";
import { ProvideMenu } from "../../hooks/menu";
import { ProvideModals } from "../../hooks/modals";
import { ProvideScroll } from "../../hooks/scroll";
import { ProvideSnacks } from "../../icecube-ux";
import { ProvideDeviceType } from "../../icecube-ux/hooks/provideDeviceType";
import Maintenance from "../../Maintenance";

export default function SharedProviders() {
  return (
    <Maintenance>
      <ProvideDeviceType>
        <ProvideSnacks>
          <ProvideMenu>
            <ProvideBanners>
              <ProvideScroll>
                <ProvideModals>
                  <ProvideAuth>
                    <Outlet />
                  </ProvideAuth>
                </ProvideModals>
              </ProvideScroll>
            </ProvideBanners>
          </ProvideMenu>
        </ProvideSnacks>
      </ProvideDeviceType>
    </Maintenance>
  );
}
