import styled from "styled-components";

import { theme } from "../theme";

export const StyledSubscriptionPlanListDivider = styled.div<{
  isVertical?: boolean;
}>`
  display: flex;
  height: ${({ isVertical }) => (isVertical ? "auto" : "1px")};
  width: ${({ isVertical }) => (isVertical ? "1px" : "auto")};
  margin: ${({ isVertical }) => (isVertical ? "16px 0 24px" : "0")};
  background-color: ${theme.colors.borderLight};
  align-self: stretch;
`;

export const StyledSubscriptionPlanList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
`;
