import styled from "styled-components";

import { theme } from "../theme";

export type NotificationMessageVariant = "primary" | "warning" | "long";
export type NotificationMessageSize = "small" | "medium" | "large";

type StyledNotificationMessageProps = {
  variant: NotificationMessageVariant;
  size: NotificationMessageSize;
};

const BACKGROUND_COLOR = {
  primary: theme.colors.primary10,
  warning: theme.colors.warning10,
  long: theme.colors.bgLight10,
};

const BORDER_COLOR = {
  primary: theme.colors.primary30,
  warning: theme.colors.warning40,
  long: theme.colors.borderLight,
};

export const StyledNotificationMessage = styled.div<StyledNotificationMessageProps>`
  display: flex;
  padding: 4px 4px 4px 8px;
  align-items: center;
  gap: 8px;

  border-radius: 4px;
  border: 1px solid ${(p) => BORDER_COLOR[p.variant]};
  background: ${(p) => BACKGROUND_COLOR[p.variant]};
`;
