import classNames from "classnames";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import type { TenantFormatters } from "../../hooks/useFormatters";
import { _ } from "../../languages/helper";
import { Chip } from "../Chip";
import { CustomerJourneyChip } from "../CustomerJourneyChip";
import { Flex } from "../Flex";
import { Grid } from "../Grid";
import { usePrevious } from "../hooks/usePrevious";
import { Loader } from "../Loader";
import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { theme } from "../theme";
import { Text } from "../Typography";

import { CHANNEL_NAME_TO_ICON_MAPPING_ICE_CUBE } from "./consts";
import {
  StyledCustomerJourneyTable,
  StyledCustomerJourneyTableCell,
  StyledCustomerJourneyTableFooterCell,
  StyledCustomerJourneyTableJourneySection,
  StyledCustomerJourneyTableJourneySectionScrollContainer,
  StyledCustomerJourneyTableScrollShadow,
} from "./CustomerJourneyTable.styled";
import {
  CustomerJourneyTableHeaderCell,
  CustomerJourneyTableCell,
  CustomerJourneyTableFooterCell,
} from "./CustomerJourneyTableCells";
import { CustomerJourneyTableOrdering } from "./CustomerJourneyTableOrdering";

export type CustomerJourneyTableProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    data: {
      journey: { campaign?: string; channel: string }[];
      conversionValue: number;
      conversionRate: number;
      conversions: number;
    }[];
    totals: {
      conversionValue: number;
      conversionRate: number;
      conversions: number;
    };
    maxValues: {
      conversionValue?: number;
      conversionRate?: number;
      conversions?: number;
    };
    formatters: TenantFormatters;
    totalCount: number;
    ordering: CustomerJourneyTableOrdering;
    onChangeOrdering: (ordering: CustomerJourneyTableOrdering) => void;
    isLoading: boolean;
    itemsPerPage: number;
    alignTouches: "left" | "right";
    setAlignTouches: (value: "left" | "right") => void;
  };

export default function CustomerJourneyTable({
  data,
  totals,
  maxValues,
  formatters,
  totalCount,
  onChangeOrdering,
  ordering,
  isLoading,
  itemsPerPage,
  alignTouches,
  setAlignTouches,
  className,
  style,
  ...interactionEvents
}: CustomerJourneyTableProps) {
  const [
    isAtEndOfJourneySectionHorizontalScroll,
    setIsAtEndOfJourneySectionHorizontalScroll,
  ] = useState(true);
  const [
    isAtStartOfJourneySectionHorizontalScroll,
    setIsAtStartOfJourneySectionHorizontalScroll,
  ] = useState(true);
  const previousData = usePrevious(data, {
    ignoreWhen: (data) => !data || data.length === 0,
  });
  const previousTotals = usePrevious(totals, {
    ignoreWhen: (data) => !data,
  });
  const previousTotalCount = usePrevious(totalCount, {
    ignoreWhen: (data) => !data,
  });
  const journeySectionHorizontalScrollElementRef = useRef<HTMLDivElement>(null);

  const updateJourneySectionScrollPosition = () => {
    const scrollElement = journeySectionHorizontalScrollElementRef.current;
    if (alignTouches === "left") {
      setIsAtEndOfJourneySectionHorizontalScroll(
        Boolean(
          scrollElement &&
            scrollElement.scrollLeft + scrollElement.clientWidth ===
              scrollElement.scrollWidth,
        ),
      );
      setIsAtStartOfJourneySectionHorizontalScroll(
        Boolean(scrollElement && scrollElement.scrollLeft === 0),
      );
    } else {
      setIsAtEndOfJourneySectionHorizontalScroll(
        Boolean(scrollElement && scrollElement.scrollLeft === 0),
      );
      setIsAtStartOfJourneySectionHorizontalScroll(
        Boolean(
          scrollElement &&
            scrollElement.clientWidth - scrollElement.scrollLeft ===
              scrollElement.scrollWidth,
        ),
      );
    }
  };

  useEffect(() => {
    updateJourneySectionScrollPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journeySectionHorizontalScrollElementRef, alignTouches]);
  useLayoutEffect(() => {
    updateJourneySectionScrollPosition();
    journeySectionHorizontalScrollElementRef?.current?.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useLayoutEffect(() => {
    window.addEventListener("resize", updateJourneySectionScrollPosition);
    return () =>
      window.removeEventListener("resize", updateJourneySectionScrollPosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emptyData = new Array(itemsPerPage).fill({ journey: [] });
  const dataToDisplay = (isLoading ? previousData : data) ?? emptyData;
  const totalsToDisplay = isLoading ? previousTotals : totals;
  const totalCountToDisplay = isLoading ? previousTotalCount : totalCount;

  return (
    <StyledCustomerJourneyTable
      {...interactionEvents}
      className={classNames(className)}
      style={style}
      areAllJourneysSingleTouch={dataToDisplay.every(
        (row) => row.journey.length === 1,
      )}
    >
      <StyledCustomerJourneyTableCell>
        <Flex justifyContent="space-between">
          <Chip
            selected={alignTouches === "left"}
            leftIcon="ArrowLeftAlign"
            onClick={alignTouches !== "left" && (() => setAlignTouches("left"))}
            size="small"
          >
            {_`Align first`}
          </Chip>
          <Chip
            selected={alignTouches === "right"}
            rightIcon="ArrowRightAlign"
            onClick={
              alignTouches !== "right" && (() => setAlignTouches("right"))
            }
            size="small"
          >
            {_`Align last`}
          </Chip>
        </Flex>
      </StyledCustomerJourneyTableCell>
      <Grid gridTemplateColumns="repeat(3, 1fr)" gap={8}>
        <CustomerJourneyTableHeaderCell
          columnKey="conversions"
          ordering={ordering}
          onChangeOrdering={onChangeOrdering}
        >
          {_`Conversions`}
        </CustomerJourneyTableHeaderCell>
        <CustomerJourneyTableHeaderCell
          columnKey="conversionValue"
          ordering={ordering}
          onChangeOrdering={onChangeOrdering}
        >
          {_`Conversion Value`}
        </CustomerJourneyTableHeaderCell>
        <CustomerJourneyTableHeaderCell
          columnKey="conversionRate"
          ordering={ordering}
          onChangeOrdering={onChangeOrdering}
          tooltipContent={
            <>
              <Text
                variant="body12Medium"
                color={theme.colors.text100}
                style={{ marginBottom: 6 }}
              >
                {_`Polar Pixel Conversion Rate`}
              </Text>
              <Text
                variant="body12Regular"
                color={theme.colors.text90}
                style={{ marginBottom: 6 }}
              >
                {_`Conversions divided by sessions. Each touch in a journey counts as a session.`}
              </Text>
              <Text variant="body12Regular" color={theme.colors.text90}>
                {_`If you apply a view or filter that depends on a purchase having been made, conversion rate will increase because all the non-purchasing journeys will not be included.`}
              </Text>
            </>
          }
        >
          {_`Conversion Rate`}
        </CustomerJourneyTableHeaderCell>
      </Grid>
      <StyledCustomerJourneyTableJourneySection isLoading={isLoading}>
        <StyledCustomerJourneyTableJourneySectionScrollContainer
          alignTouches={alignTouches}
          ref={journeySectionHorizontalScrollElementRef}
          onScroll={updateJourneySectionScrollPosition}
        >
          <Grid gridAutoRows="44px" gap={8} style={{ flexGrow: 1 }}>
            {dataToDisplay.map((row, index) => (
              <StyledCustomerJourneyTableCell key={index}>
                <Flex
                  justifyContent={
                    alignTouches === "left" ? "flex-start" : "flex-end"
                  }
                >
                  {row.journey.map(
                    (
                      touch: { campaign?: string; channel: string },
                      index: number,
                    ) => (
                      <CustomerJourneyChip
                        key={index}
                        name={touch.campaign || touch.channel}
                        icon={
                          CHANNEL_NAME_TO_ICON_MAPPING_ICE_CUBE[touch.channel]
                        }
                        tooltipContent={`${touch.channel}${
                          touch.campaign ? ` – ${touch.campaign}` : ""
                        }`}
                        isFirst={index === 0}
                        isLast={index === row.journey.length - 1}
                        alignTouches={alignTouches}
                      />
                    ),
                  )}
                </Flex>
              </StyledCustomerJourneyTableCell>
            ))}
          </Grid>
        </StyledCustomerJourneyTableJourneySectionScrollContainer>
        {!isAtStartOfJourneySectionHorizontalScroll && (
          <StyledCustomerJourneyTableScrollShadow side="left" />
        )}
        {!isAtEndOfJourneySectionHorizontalScroll && (
          <StyledCustomerJourneyTableScrollShadow side="right" />
        )}
      </StyledCustomerJourneyTableJourneySection>
      <Grid
        gridTemplateColumns="repeat(3, 1fr)"
        gridAutoRows="44px"
        gap={8}
        style={{ opacity: isLoading ? "50%" : undefined }}
      >
        {dataToDisplay.map((row, id) => (
          <React.Fragment key={`row-${id}`}>
            <CustomerJourneyTableCell
              value={row.conversions}
              formatter={formatters.integerFormatter}
              relativeBarMax={
                ordering.columnKey === "conversions"
                  ? maxValues.conversions
                  : undefined
              }
            />
            <CustomerJourneyTableCell
              value={row.conversionValue}
              formatter={formatters.currencyFormatter}
              relativeBarMax={
                ordering.columnKey === "conversionValue"
                  ? maxValues.conversionValue
                  : undefined
              }
            />
            <CustomerJourneyTableCell
              value={row.conversionRate}
              formatter={formatters.percentageFormatter}
              relativeBarMax={
                ordering.columnKey === "conversionRate"
                  ? maxValues.conversionRate
                  : undefined
              }
            />
          </React.Fragment>
        ))}
      </Grid>
      <StyledCustomerJourneyTableFooterCell
        style={{
          paddingLeft: 16,
          opacity: isLoading ? "50%" : undefined,
        }}
      >
        <Flex alignItems="center">
          <Text variant="body12Medium" inline style={{ marginRight: 12 }}>
            {_`Total`}
          </Text>
          <Text variant="body10Medium" inline color={theme.colors.primary100}>
            {isLoading && !totalCountToDisplay ? "-" : totalCountToDisplay}{" "}
            {_`results`}
          </Text>
        </Flex>
      </StyledCustomerJourneyTableFooterCell>
      <Grid
        gridTemplateColumns="repeat(3, 1fr)"
        gap={8}
        style={{ opacity: isLoading ? "50%" : undefined }}
      >
        <CustomerJourneyTableFooterCell
          value={totalsToDisplay?.conversions}
          formatter={formatters.integerFormatter}
        />
        <CustomerJourneyTableFooterCell
          value={totalsToDisplay?.conversionValue}
          formatter={formatters.currencyFormatter}
        />
        <CustomerJourneyTableFooterCell
          value={totalsToDisplay?.conversionRate}
          formatter={formatters.percentageFormatter}
        />
      </Grid>
      {isLoading && (
        <Flex
          justifyContent="center"
          alignItems="center"
          style={{ height: "100%", position: "absolute", width: "100%" }}
        >
          <Loader data-cy="loader" />
        </Flex>
      )}
    </StyledCustomerJourneyTable>
  );
}
