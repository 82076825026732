import styled from "styled-components";

import { Icon } from "../Icon";
import { theme } from "../theme";
import { typographyStylesMap } from "../Typography/typographyStylesMap";

export const StyledSelectableCard = styled.span``;

export const StyledSelectableCardLabel = styled.label`
  border: 1px solid ${theme.colors.borderLight};
  border-radius: ${theme.borderRadius.r8px};
  background-color: ${theme.colors.white100};
  ${typographyStylesMap.body13SemiBold}
  color: ${theme.colors.text110};
  padding: 16px;
  display: flex;
  gap: 16px;
  align-items: center;
  cursor: pointer;
  input:checked + & {
    background-color: ${theme.colors.primary10};
    border-color: ${theme.colors.borderMedium};
    color: ${theme.colors.primary100};
  }
  &&:hover {
    background-color: ${theme.colors.primary20};
    border-color: ${theme.colors.borderDark};
    color: ${theme.colors.primary110};
  }
`;

export const StyledSelectableCardBox = styled.div`
  width: 18px;
  height: 18px;
  border-radius: ${theme.borderRadius.r4px};
  border: 1px solid ${theme.colors.borderMedium};
  background-color: ${theme.colors.white100};
  flex-shrink: 0;
`;

export const StyledSelectableCardCheckIcon = styled(Icon)`
  display: none;
  input:checked + ${StyledSelectableCardLabel} & {
    display: block;
  }
`;

export const StyledSelectableCardRadio = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid ${theme.colors.borderMedium};
  background-color: ${theme.colors.white100};
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  input:checked + ${StyledSelectableCardLabel} & {
    border-color: ${theme.colors.primary70};
  }
  input:checked + ${StyledSelectableCardLabel}:hover & {
    border-color: ${theme.colors.primary80};
  }
`;

export const StyledSelectableCardRadioBullet = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: ${theme.colors.primary60};
  flex-shrink: 0;

  display: none;
  input:checked + ${StyledSelectableCardLabel} & {
    display: block;
  }
  input:checked + ${StyledSelectableCardLabel}:hover & {
    background-color: ${theme.colors.primary80};
  }
`;
