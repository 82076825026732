import styled from "styled-components";

import { legacyTheme } from "../legacyTheme";

export const StyledRadio = styled.span`
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 15px;
  height: 15px;
  border-radius: ${legacyTheme.borderRadius.all};
  border: 1px solid ${legacyTheme.colors.borders.default};
  cursor: pointer;
  transition: border 0.2s ease-in-out;
  background-color: ${legacyTheme.colors.white};

  &:hover {
    border: 1px solid ${legacyTheme.colors.borders.hover};
  }

  &.checked,
  input:checked + label & {
    border: 5px solid ${legacyTheme.colors.success1};
  }

  &.checked:hover,
  input:checked + label &:hover {
    border: 5px solid ${legacyTheme.colors.success1darken};
  }

  &.disabled,
  input:disabled + label & {
    background: ${legacyTheme.colors.bg.inputs.disabled};
    border: 1px dashed ${legacyTheme.colors.borders.hover};
  }
`;
