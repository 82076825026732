import classNames from "classnames";

import { Grid } from "../../icecube-ux";
import "./tab-buttons.css";

interface TabButtonsProps {
  onChange: (value: string | number) => void;
  value: string | number;
  tabs: Array<{ label: string; value: string | number }>;
  withoutMargin?: boolean;
  className?: string;
}

export default function TabButtons({
  onChange,
  value,
  tabs,
  className,
  withoutMargin = false,
}: TabButtonsProps) {
  return (
    <Grid
      gridTemplateColumns={`repeat(${tabs.length}, 1fr)`}
      className={classNames("tab-buttons", className, {
        "mlx-8 mrx-8": !withoutMargin,
      })}
      gap={8}
    >
      {tabs.map((t) => (
        <span
          key={`tab-${t.value}`}
          onClick={() => onChange(t.value)}
          className={value === t.value ? "selected" : ""}
        >
          {t.label}
        </span>
      ))}
    </Grid>
  );
}
