import { ReactNode, createContext, useContext, useState } from "react";

import { EmojiIconNames } from "../EmojiIcon/EmojiIcon";

import Snack from "./Snack";
import SnackContainer from "./SnackContainer";

const DEFAULT_SNACK_LIFETIME = 3000;

interface SnackSettings {
  text: string;
  icon?: EmojiIconNames;
  time?: number;
}

interface SnacksContextProps {
  snacks: { [key: string]: SnackSettings | undefined };
  create: (settings: SnackSettings) => void;
  clear: () => void;
}

const SnacksContext = createContext<SnacksContextProps | null>(null);

export function ProvideSnacks({ children }: { children: ReactNode }) {
  const snacks = useProvideSnacks();
  return (
    <SnacksContext.Provider value={snacks}>
      <SnackContainer>
        {Object.entries(snacks.snacks).map(
          ([key, element]) =>
            element && (
              <Snack key={key} text={element.text} icon={element.icon} />
            ),
        )}
      </SnackContainer>
      {children}
    </SnacksContext.Provider>
  );
}

let snackId = 0;
const getSnackId = () => {
  snackId++;
  return `s${snackId}`;
};

export const useSnacks = () => {
  const context = useContext(SnacksContext);
  if (context === null) {
    throw Error("Snacks context not provided");
  }
  return context;
};

const useProvideSnacks = () => {
  const [snacks, setSnacks] = useState<{
    [key: string]: SnackSettings | undefined;
  }>({});

  const create = (settings: SnackSettings) => {
    const id = getSnackId();
    setSnacks((s) => ({ ...s, [id]: settings }));
    setTimeout(() => {
      removeSnack(id);
    }, settings.time || DEFAULT_SNACK_LIFETIME);
  };

  const removeSnack = (id: string) =>
    setSnacks((s) => ({ ...s, [id]: undefined }));

  const clear = () => {
    setSnacks({});
  };

  return {
    snacks,
    create,
    clear,
  };
};
