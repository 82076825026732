import React, { useEffect } from "react";
import styled from "styled-components";

import {
  CloseCircleIcon,
  DraggerIcon,
} from "../../../../components/Icons/Icons";
import {
  ConnectorIconNames,
  Flex,
  Grid,
  Icon,
  theme,
} from "../../../../icecube-ux";
import { IconNames } from "../../../../icecube-ux/Icon/Icon";

import TableLogo from "./TableLogo";

interface FormulaBlockProps {
  onDelete: () => void;
  onClick?: () => void;
  onStartDragging?: () => void;
  onStopDragging?: () => void;
  iconName: IconNames;
  label: React.ReactElement | string;
  icon?: ConnectorIconNames;
  dragging?: boolean;
  isDroppingTarget?: boolean;
  droppingTargetPosition?: string;
  badgeIndex?: number;
  canDrag?: boolean;
  highlighted?: boolean;
  filterCount: number;
}

const FormularBlockDiv = styled.div<{}>`
  position: relative;
  background: white;
  padding: 8px;
  margin: 4px;
  line-height: 20px;
  color: ${theme.colors.text90};
  font-weight: normal;
  font-size: 12px;
  height: 36px;
  box-sizing: border-box;
  border: 1px solid ${theme.colors.borderLight};
  border-radius: 4px;

  &:hover,
  &.highlighted {
    background: var(--primary9);
    color: #665dfe;
  }
  &.dropping-target-start::before {
    position: absolute;
    height: 36px;
    width: 4px;
    left: -6px;
    content: "";
    background: gray;
    z-index: 1000;
    top: 0px;
    opacity: 0.7;
    border-radius: 10px;
  }
  &.dropping-target-end::before {
    position: absolute;
    height: 36px;
    width: 4px;
    right: -6px;
    content: "";
    background: gray;
    z-index: 1000;
    top: 0px;
    opacity: 0.7;
    border-radius: 10px;
  }
  &.dragging svg.icon-dragger {
    cursor: grabbing;
  }
  svg.icon-delete {
    fill: #d9dced;
    margin-top: 2px;
    cursor: pointer;
  }
  svg.icon-dragger {
    fill: #b9b5ff;
    margin-top: 2px;
    cursor: grab;
  }
  svg.icon-filter {
    fill: #b9b5ff;
    margin-top: 2px;
    cursor: pointer;
  }
  svg.operator {
    height: 18px;
    margin: 0 2px;
  }
  svg.icon-dragger:hover,
  svg.icon-delete:hover,
  svg.icon-filter:hover {
    fill: #7766fe;
  }

  .counter {
    vertical-align: middle;
    border-radius: 8px;
    height: 16px;
    font-size: 10px;
    line-height: 17px;
    text-align: center;
    color: ${theme.colors.primary100};
    background: ${theme.colors.primary40};
    display: inline-block;
    padding: 0 5px;
    min-width: 6px;
    margin-top: 2px;
  }
`;

export default function FormulaBlock({
  onDelete,
  onClick,
  icon,
  iconName,
  label,
  dragging,
  isDroppingTarget,
  onStartDragging,
  onStopDragging = () => {},
  droppingTargetPosition,
  badgeIndex,
  filterCount,
  canDrag = true,
  highlighted = false,
}: FormulaBlockProps) {
  useEffect(() => {
    document.addEventListener("mouseup", onStopDragging);
    return () => {
      document.removeEventListener("mouseup", onStopDragging);
    };
  }, [onStopDragging]);

  return (
    <FormularBlockDiv
      className={
        "formula-block" +
        (highlighted ? " highlighted" : "") +
        (dragging ? " dragging" : "") +
        (isDroppingTarget && droppingTargetPosition === "start"
          ? " dropping-target-start"
          : "") +
        (isDroppingTarget && droppingTargetPosition === "end"
          ? " dropping-target-end"
          : "")
      }
    >
      <Grid
        gridTemplateColumns={
          onClick ? "auto auto 1fr auto auto auto" : "auto 1fr auto"
        }
        gap={4}
      >
        {canDrag && (
          <DraggerIcon
            onMouseDown={onStartDragging}
            size={14}
            className="icon-dragger"
          />
        )}
        {icon && (
          <TableLogo
            icon={icon}
            badgeIndex={badgeIndex}
            forceBackgroundColor={highlighted ? "white" : undefined}
          />
        )}
        {label}
        {onClick && (
          <Flex alignItems={"center"}>
            <Icon
              name={iconName}
              onClick={onClick}
              size={14}
              color={theme.colors.primary50}
            />
            {filterCount > 0 && <div className="counter">{filterCount}</div>}
          </Flex>
        )}
        <CloseCircleIcon onClick={onDelete} className="icon-delete" size={14} />
      </Grid>
    </FormularBlockDiv>
  );
}
