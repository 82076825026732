import { useEffect, useState } from "react";
import { useResizeDetector } from "react-resize-detector";

export enum ReportTableHeightManagementType {
  MAX_HEIGHT = "maxHeight",
  MAX_ROW = "maxRow",
  AUTO = "auto",
}

interface UseReportTableSizeManagerProps {
  heightManagementType: ReportTableHeightManagementType;
  heightValue: number;
  pivoted?: boolean;
}

const ABSOLUTE_MAX_ROW = 1000;
const AUTO_HEIGHT_MARGIN_DELTA = 8;

export function useReportTableSizeManager({
  heightManagementType,
  heightValue,
  pivoted,
}: UseReportTableSizeManagerProps) {
  const [containerHeight, setContainerHeight] = useState(0);
  const [leftPartRightMargin, setLeftPartRightMargin] = useState(0);
  const [rightPartRightMargin, setRightPartRightMargin] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [maximumBodyHeight, setMaximumBodyHeight] = useState<string | null>();
  const [maximumRowPerPage, setMaximumRowPerPage] = useState(ABSOLUTE_MAX_ROW);
  const [bodyNeedsScroll, setBodyNeedsScroll] = useState(false);
  const [bodyScrollHeight, setBodyScrollHeight] = useState(0);

  const leftPartSizeDetector = useResizeDetector<HTMLDivElement>({
    onResize: () => {
      if (leftPartSizeDetector.ref.current) {
        const node = leftPartSizeDetector.ref.current
          .parentElement as HTMLDivElement;
        if (!node) {
          return;
        }
        setLeftPartRightMargin(node.offsetWidth - node.clientWidth);
        setBodyScrollHeight(node.scrollHeight);
        setBodyNeedsScroll(node.scrollHeight > node.clientHeight);
      }
    },
  });

  const rightPartSizeDetector = useResizeDetector<HTMLDivElement>({
    onResize: () => {
      if (rightPartSizeDetector.ref.current) {
        const node = rightPartSizeDetector.ref.current
          .parentElement as HTMLDivElement;
        if (!node) {
          return;
        }
        const containerNode = (node.parentElement as HTMLDivElement)
          .parentElement as HTMLDivElement;
        if (!containerNode) {
          return;
        }
        setRightPartRightMargin(node.offsetWidth - node.clientWidth);
        setContainerHeight(containerNode.clientHeight);
      }
    },
  });

  const headerSizeDetector = useResizeDetector<HTMLDivElement>({
    onResize: () => {
      if (headerSizeDetector.ref.current) {
        const node = headerSizeDetector.ref.current
          ?.parentElement as HTMLDivElement;
        setHeaderHeight(node?.offsetHeight);
      }
    },
  });
  const footerSizeDetector = useResizeDetector<HTMLDivElement>({
    onResize: () => {
      if (footerSizeDetector.ref.current) {
        const node = footerSizeDetector.ref.current;
        setFooterHeight(node?.offsetHeight);
      }
    },
  });

  useEffect(() => {
    setMaximumBodyHeight(
      heightManagementType === ReportTableHeightManagementType.AUTO
        ? `${
            containerHeight -
            footerHeight -
            headerHeight -
            AUTO_HEIGHT_MARGIN_DELTA
          }`
        : heightManagementType === ReportTableHeightManagementType.MAX_ROW
        ? null
        : `${heightValue - footerHeight - headerHeight}`,
    );
    setMaximumRowPerPage(
      heightManagementType === ReportTableHeightManagementType.MAX_ROW
        ? pivoted === true
          ? ABSOLUTE_MAX_ROW
          : parseInt(`${heightValue}`)
        : ABSOLUTE_MAX_ROW,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerHeight, footerHeight, heightManagementType, heightValue, pivoted]);

  return {
    leftPartSizeDetector,
    rightPartSizeDetector,
    headerSizeDetector,
    footerSizeDetector,
    leftPartRightMargin,
    rightPartRightMargin,
    maximumBodyHeight,
    maximumRowPerPage,
    bodyNeedsScroll,
    bodyScrollHeight,
  };
}
