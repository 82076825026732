import React, { useCallback, useState } from "react";

import { useResizeObserver } from "../hooks/useResizeObserver";

interface RatioWrapperProps {
  ratio?: number;
  maxHeight?: number;
  children: React.ReactElement;
  className?: string;
  onStyle?: boolean;
}

export const RatioWrapper = ({
  ratio = 1 / 2.75,
  maxHeight = Infinity,
  children,
  className = "",
  onStyle = false,
}: RatioWrapperProps) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const { width } = useResizeObserver(container);

  const containerRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setContainer(node);
    }
  }, []);

  return (
    <div ref={containerRef} className={className}>
      {React.cloneElement(children, {
        width,
        height: Math.min(width * ratio, maxHeight),
        style: onStyle
          ? {
              width: `${width}px`,
              height: `${Math.min(width * ratio, maxHeight)}px`,
            }
          : undefined,
      })}
    </div>
  );
};
