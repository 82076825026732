import styled, { css } from "styled-components";

import { StyledExploreMetricInfo } from "../KeyIndicatorCard/ExploreMetricLink.styled";
import { theme } from "../theme";

export const ChartContainer = styled.div`
  display: flex;
  align-items: end;
  width: 100%;
  height: 100px;
  overflow: hidden;
`;
export const StyledKeyIndicatorCardWithChart = styled.div`
  display: flex;
  gap: 8px;
  padding: 8px;
  border: 1px solid ${theme.colors.borderLight};
  border-radius: ${theme.borderRadius.r4px};
  box-sizing: border-box;
  max-height: 234px;
`;

export const StyledKeyIndicatorWithChartValues = styled.div<{
  showExploreLink: boolean;
}>`
  margin: 8px 0 8px;
  padding: 6px;
  border-radius: 4px;
  background: ${theme.colors.bgLight10};
  height: 54px;

  & ${StyledExploreMetricInfo} {
    ${({ showExploreLink }) =>
      showExploreLink &&
      css`
        display: flex;
        position: absolute;
        right: 8px;
        bottom: 24px;
      `}
  }

  &:hover ${StyledExploreMetricInfo} {
    display: flex;
    position: absolute;
    right: 8px;
    bottom: 24px;
  }
`;

export const StyledFocusedDate = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 8px;
`;
