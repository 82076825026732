import { ConnectorIcon, Icon } from "../../../icecube-ux";

export type AddonIconNames = "PolarPixel" | "Capi" | "IntradayRefresh";

interface AddonIconProps {
  name: AddonIconNames | "Addons";
  size: number;
}

const IconComponent = {
  PolarPixel: ({ size }: { size: number }) => (
    <ConnectorIcon name="PolarPixel" size={size} />
  ),
  IntradayRefresh: ({ size }: { size: number }) => (
    <Icon name="Refresh" size={size} />
  ),
  Capi: ({ size }: AddonIconProps) => <Icon name="Capi" size={size} />,
  Addons: ({ size }: AddonIconProps) => <Icon name="Addons" size={size} />,
};

export function AddonIcon({
  name,
  size,
}: {
  name: AddonIconNames | "Addons";
  size: number;
}) {
  if (!name) {
    return null;
  }

  const Icon = IconComponent[name];

  if (Icon === undefined) {
    console.warn("Addon icon not found", name);
    return null;
  }

  return <Icon {...{ size, name }} />;
}
