import { useCallback, useEffect, useRef, useState } from "react";

import useMouse from "../hooks/useMouse";

interface SortableGridOption {
  value: string | number;
  label: string;
}
export interface SortableGridHookProps {
  seriesLength: number;
  options: SortableGridOption[];
  disableSort?: boolean;
  onSort: (values: Array<string | number>, from: number, to: number) => void;
  onRemove?: (value: number | string) => void;
  direction?: "column" | "row";
  ref?: React.RefObject<HTMLDivElement>;
}

export function useSortableGrid({
  seriesLength,
  options,
  onSort,
  disableSort,
  direction = "column",
  ref: propsRef,
}: SortableGridHookProps) {
  const { mousePosition, pause, restart } = useMouse(true);

  const ownRef = useRef<HTMLDivElement>(null);
  const ref = propsRef ?? ownRef;

  const [movingIndex, setMovingIndex] = useState<number | null>(null);
  const [toIndex, setToIndex] = useState<number | null>(null);

  const swapPositions = useCallback(
    (from: number, to: number) => {
      if (from === to) {
        return;
      }
      const elems = [...options];
      const temp = elems.splice(from, 1)[0];
      elems.splice(from > to ? to : to - 1, 0, temp);

      onSort([...elems.map((e) => e.value)], from, to);
    },
    [onSort, options],
  );

  const handleMouseDown = useCallback(
    (index: number) => {
      if (!disableSort) {
        restart();
        setMovingIndex(index);
      }
    },
    [disableSort, restart, setMovingIndex],
  );

  const handleMouseUp = useCallback(() => {
    if (movingIndex !== null && toIndex !== null) {
      swapPositions(movingIndex, toIndex);
    }
    pause();
    setMovingIndex(null);
    setToIndex(null);
  }, [movingIndex, swapPositions, toIndex, pause]);

  const handleMouseMove = useCallback(() => {
    if (ref.current && movingIndex !== null) {
      const rect = ref.current.getBoundingClientRect();

      const cellCount =
        direction === "column"
          ? {
              x: seriesLength,
              y: Math.ceil(options.length / seriesLength),
            }
          : {
              x: Math.ceil(options.length / seriesLength),
              y: seriesLength,
            };

      const currentPosition =
        direction === "column"
          ? {
              x: Math.floor(mousePosition.x - rect.x),
              y: Math.floor(mousePosition.y - rect.y),
            }
          : {
              x: Math.floor(mousePosition.y - rect.y),
              y: Math.floor(mousePosition.x - rect.x),
            };

      const cellSize =
        direction === "column"
          ? {
              x: ref.current.scrollWidth / seriesLength,
              y: ref.current.scrollHeight / cellCount.y,
            }
          : {
              x: ref.current.scrollWidth / cellCount.x,
              y: ref.current.scrollHeight / seriesLength,
            };

      const currentLine =
        mousePosition.y < rect.y
          ? 0
          : Math.min(cellCount.y - 1, currentPosition.y / cellSize.y);

      const currentRow =
        mousePosition.x < rect.x
          ? 0
          : Math.min(cellCount.x - 1, currentPosition.x / cellSize.x);

      const to = Math.min(
        options.length,
        direction === "column"
          ? Math.floor(currentLine) * seriesLength + Math.floor(currentRow)
          : Math.floor(currentRow) * seriesLength + Math.floor(currentLine),
      );

      setToIndex(to);
    }
  }, [mousePosition, direction, ref, seriesLength, options, movingIndex]);

  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [handleMouseMove, handleMouseUp]);

  return {
    x: mousePosition.x,
    y: mousePosition.y,
    ref,
    handleMouseDown,
    movingIndex,
    toIndex,
  };
}
