import React, { forwardRef } from "react";
import styled from "styled-components";

import { Block } from "../Block";
import { Counter } from "../Counter";
import { Flex } from "../Flex";
import { theme } from "../theme";
import { Text } from "../Typography";

type ReactChildren<
  T,
  TWrapped = T extends string ? T : React.ReactElement<T>,
> = TWrapped | TWrapped[];

const Body = styled.div`
  margin-top: 16px;
`;

const Heading = Object.assign(
  ({
    ordinal,
    isCompleted,
    children,
    style,
  }: {
    ordinal?: number;
    isCompleted?: boolean;
    children: React.ReactNode;
    style?: React.CSSProperties;
  }) => {
    return (
      <Flex alignItems="center" columnGap={12} style={style}>
        {ordinal && (
          <Counter
            value={ordinal}
            variant={isCompleted ? "onboardingCompleted" : "onboarding"}
          />
        )}
        <Text
          color={theme.colors.text110}
          variant="body16SemiBold"
          style={{ marginBottom: 2 }}
        >
          {children}
        </Text>
      </Flex>
    );
  },
  { Body },
);

const SubHeading = ({
  children,
  style,
}: {
  children: ReactChildren<string>;
  style?: React.CSSProperties;
}) => {
  return (
    <Text
      color={theme.colors.text80}
      variant="body12Regular"
      style={{ marginLeft: 38, ...style }}
    >
      {children}
    </Text>
  );
};

const StyledSetupSection = styled(Block)`
  && {
    padding: 24px;
  }
  & + & {
    margin-top: 12px;
  }
`;

const SetupSection = forwardRef<
  HTMLDivElement,
  { children: React.ReactNode; style?: React.CSSProperties }
>(
  (
    {
      children,
      style,
    }: {
      children: React.ReactNode;
      style?: React.CSSProperties;
    },
    ref,
  ) => {
    return (
      <StyledSetupSection style={style} ref={ref}>
        <Flex style={{ gridColumn: 3 }} flexDirection="column" fullWidth>
          {children}
        </Flex>
      </StyledSetupSection>
    );
  },
);

export default Object.assign(SetupSection, { Heading, SubHeading, Body });
