import styled from "styled-components";

import { Icon } from "../Icon";
import { theme } from "../theme";

export const StyledEvolutionInfo = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: start;
  align-items: center;
  margin-top: auto;
  gap: 6px;
`;

export const StyledKeyIndicatorCard = styled.div<{
  showExploreMetric?: boolean;
}>`
  display: grid;
  gap: 8px;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-template-rows: 1fr;
  max-height: 175px;
  border: 1px solid ${theme.colors.borderLight};
  border-radius: ${theme.borderRadius.r4px};
  box-sizing: border-box;
  padding: 8px;
`;

export const StyledKeyIndicatorValues = styled.div`
  margin: 14px 0 16px;
`;

export const StyledKeyIndicatorGoalOverlay = styled.div`
  position: absolute;
  display: none;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: ${theme.borderRadius.r2px};
  background: rgba(90, 80, 254, 0.85);
  backdrop-filter: blur(1.5px);
  z-index: 10;
  align-items: center;
  justify-items: center;
  justify-content: center;
`;

export const StyledKeyIndicatorGoal = styled.div`
  position: relative;
  flex-shrink: 0;
  display: grid;
  grid-template-rows: auto 1fr;
  width: 90px;
  border-radius: ${theme.borderRadius.r2px};
  background: ${theme.colors.bgLight10};

  &:hover ${StyledKeyIndicatorGoalOverlay} {
    display: flex;
  }
`;

export const StyledKeyIndicatorGoalTitle = styled.div`
  text-align: center;
  border-bottom: 1px solid ${theme.colors.borderLight};
`;

export const StyledKeyIndicatorGoalSVG = styled.svg`
  height: 24px;
  width: 78px;
`;

export const StyledKeyIndicatorGoalArrow = styled(Icon).attrs({
  name: "Indicator",
})`
  position: absolute;
  bottom: 0;
  left: calc(50% - 8px);
  color: ${theme.colors.grey70};
`;
