import { useEffect } from "react";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Outlet, useSearchParams } from "react-router-dom";

import { ProvideViews } from "../../components/CountryFilters/views/ViewProvider";
import { ProvideBootstrap } from "../../hooks/bootstrap";
import { ProvideConnectorObservability } from "../../hooks/connectorObservability";
import { ProvideCustomElementEditors } from "../../hooks/customElementEditors";
import { ProvideDimensions } from "../../hooks/dimensions";
import { ProvideMetricList } from "../../hooks/metricList";
import { ProvidePixelConfiguration } from "../../hooks/pixelConfiguration";
import { ProvideAddonPopups } from "../../hooks/useAddonPopup";
import { useGlobalCallBookingListener } from "../../hooks/useCallBooking";
import { ProvideChannelGroupingCustomDimension } from "../../hooks/useChannelGroupingCustomDimensions";
import { ProvideFormatters } from "../../hooks/useFormatters";
import { ProvideSignupFromOutboundPopup } from "../../hooks/useSignupFromOutboundPopup";
import { ProvideSmartFilter } from "../../hooks/useSmartFilter";
import { CustomElementEditors } from "../../pages/custom/CustomElementEditors";
import RoutingAnalytics from "../../shared/RoutingAnalytics";
import SlackConnector from "../../shared/SlackConnector";

const WithGlobalCallListener = () => {
  useGlobalCallBookingListener();

  return null;
};

export const LoggedInProviders = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has("can_leave")) {
      searchParams.delete("can_leave");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  return (
    <ProvideBootstrap>
      <ProvideConnectorObservability>
        <ProvideDimensions>
          <ProvideSmartFilter>
            <ProvideViews>
              <ProvideFormatters>
                <ProvideMetricList>
                  <ProvideChannelGroupingCustomDimension>
                    <ProvideCustomElementEditors>
                      <ProvidePixelConfiguration>
                        <ProvideSignupFromOutboundPopup>
                          <ProvideAddonPopups>
                            <>
                              <CustomElementEditors />
                              <RoutingAnalytics>
                                <NotificationContainer />
                                <SlackConnector />
                                <WithGlobalCallListener />
                                <Outlet />
                              </RoutingAnalytics>
                            </>
                          </ProvideAddonPopups>
                        </ProvideSignupFromOutboundPopup>
                      </ProvidePixelConfiguration>
                    </ProvideCustomElementEditors>
                  </ProvideChannelGroupingCustomDimension>
                </ProvideMetricList>
              </ProvideFormatters>
            </ProvideViews>
          </ProvideSmartFilter>
        </ProvideDimensions>
      </ProvideConnectorObservability>
    </ProvideBootstrap>
  );
};
