import classNames from "classnames";
import React from "react";

import { Checkbox } from "../Checkbox";
import { Flex } from "../Flex";
import Icon from "../Icon/Icon";
import { Label } from "../Label";
import { Loader } from "../Loader";
import { Radio } from "../Radio";
import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { theme } from "../theme";

import {
  StyledHorizontalList,
  StyledHorizontalListLabelWrapper,
} from "./HorizontalList.styled";
import { ListOnlyProps } from "./List";

type HorizontalListProps = InteractionEvents<HTMLDivElement> &
  ClassAndStyleProps &
  ListOnlyProps & {
    small?: boolean;
    withCheckmarks?: boolean;
    withCross?: boolean;
    disabled?: boolean;
    withNoneButton?: boolean;
    withSelectAllButton?: boolean;
    onRemoveElement?: (value: string | number) => void;
    onClear?: () => void;
    onSelectAll?: () => void;
  };

export default function HorizontalList({
  loading,
  options,
  selected,
  withCheckboxes,
  withCheckmarks,
  withRadio,
  withCross,
  onClickOnElement,
  onRemoveElement,
  multiple,
  disabled,
  highlighted,
  small,
  withNoneButton,
  withSelectAllButton,
  onClear,
  onSelectAll,
  className,
  style,
  ...interactionEvents
}: HorizontalListProps) {
  const handleRemoveElement = (
    e: React.MouseEvent<HTMLOrSVGElement>,
    value: number | string,
  ) => {
    if (onRemoveElement) {
      onRemoveElement(value);
    }
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <StyledHorizontalList
      {...interactionEvents}
      style={style}
      className={classNames(className)}
    >
      {loading && (
        <Flex justifyContent="center">
          <Loader size={18} />
        </Flex>
      )}
      {withNoneButton && (
        <StyledHorizontalListLabelWrapper
          className={classNames({
            selected: selected.length === 0,
            small,
          })}
        >
          <Label
            block={true}
            highlighted={highlighted}
            onClick={() => onClear && onClear()}
            small={small}
            rightIcon={
              withCheckmarks && selected.length === 0 ? "Check" : undefined
            }
            style={{
              color:
                selected.length === 0 ? theme.colors.primary100 : undefined,
            }}
          >
            None
          </Label>
        </StyledHorizontalListLabelWrapper>
      )}
      {withSelectAllButton && (
        <StyledHorizontalListLabelWrapper
          className={classNames({
            selected: selected.length === options.length,
            small,
          })}
        >
          <Label
            style={{
              color:
                selected.length === options.length
                  ? theme.colors.primary100
                  : undefined,
            }}
            block={true}
            highlighted={highlighted}
            onClick={() => onSelectAll && onSelectAll()}
            small={small}
            rightIcon={
              withCheckmarks && selected.length === options.length
                ? "Check"
                : undefined
            }
          >
            All
          </Label>
        </StyledHorizontalListLabelWrapper>
      )}
      {options.map((option) => {
        if (option.isSubtitle) {
          return null;
        }

        const isSelected = selected.includes(option.value);
        return (
          <StyledHorizontalListLabelWrapper
            className={classNames({
              selected: isSelected,
              disabled: !isSelected && disabled,
              small,
            })}
            key={`option-${option.value}`}
          >
            <Label
              style={{
                color:
                  isSelected && !disabled ? theme.colors.primary100 : undefined,
              }}
              block={true}
              disabled={disabled}
              highlighted={highlighted}
              leftIcon={option.leftIcon}
              rightIcon={
                withCheckmarks === isSelected ? "Check" : option.rightIcon
              }
              leftComponents={[
                ...(option.leftComponents || []),
                ...(withCheckboxes
                  ? [
                      <Checkbox
                        disabled={!isSelected && disabled}
                        checked={isSelected}
                      />,
                    ]
                  : []),
                ...(withRadio
                  ? [
                      <Radio
                        disabled={!isSelected && disabled}
                        checked={isSelected}
                      />,
                    ]
                  : []),
              ]}
              rightComponents={[
                ...(option.rightComponents || []),
                ...(withCross && isSelected
                  ? [
                      <Icon
                        name="CloseCircle"
                        className="closer-icon"
                        onClick={(e) => handleRemoveElement(e, option.value)}
                      />,
                    ]
                  : []),
              ]}
              onClick={() => {
                if (onClickOnElement && !disabled) {
                  onClickOnElement(option.value);
                }
              }}
              small={small}
            >
              {option.label}
            </Label>
          </StyledHorizontalListLabelWrapper>
        );
      })}
    </StyledHorizontalList>
  );
}
