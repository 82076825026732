import { randomString } from "../utils/utils";

import { MemoryCache } from "./memoryCache";
import { getServiceHost } from "./services";

export interface CURRENCIES {
  [key: string]: number;
}

export const getCurrencies = async (token: string) => {
  if (MemoryCache.has("currencies")) {
    return MemoryCache.get("currencies") as CURRENCIES;
  }

  const response = await fetch(
    getServiceHost("currencies-service") + "/api/currencies",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        LogContext: randomString(12),
        token,
      },
    },
  );
  const json = (await response.json()) as { error?: boolean; data: CURRENCIES }; //To fix request might fail
  if (json.error === true) {
    throw new Error("Cannot get currencies list");
  }
  MemoryCache.set("currencies", json.data);
  return json.data;
};
