import { theme } from "../theme";

export const colorStylesMap = {
  default: {
    root: {
      background: theme.colors.bgLight20,
      color: theme.colors.text90,
      border: `1px solid ${theme.colors.borderLight}`,
    },
    hover: {
      background: theme.colors.bgLight30,
      color: theme.colors.text100,
      border: `1px solid ${theme.colors.borderMedium}`,
    },
  },
  error: {
    root: {
      color: theme.colors.text90,
      background: theme.colors.bgLight20,
      border: `1px solid ${theme.colors.borderLight}`,
    },
    hover: {
      color: theme.colors.error100,
      background: theme.colors.bgLight20,
      border: `1px solid ${theme.colors.borderMedium}`,
    },
  },
  warning: {
    root: {
      color: theme.colors.warning80,
      background: "transparent",
    },
    hover: {
      color: theme.colors.warning80,
      background: "transparent",
    },
  },
  light: {
    root: {
      background: theme.colors.bgLight30,
      color: theme.colors.text90,
      border: `1px solid ${theme.colors.borderLight}`,
    },
    hover: {
      background: theme.colors.grey50,
      color: theme.colors.text90,
      border: `1px solid ${theme.colors.borderMedium}`,
    },
  },
  dark: {
    root: {
      background: theme.colors.grey50,
      color: theme.colors.text100,
      border: `1px solid ${theme.colors.borderMedium}`,
    },
    hover: {
      background: theme.colors.grey60,
      color: theme.colors.text110,
      border: `1px solid ${theme.colors.borderDark}`,
    },
  },
  lightBlue: {
    root: {
      background: theme.colors.primary20,
      color: theme.colors.primary70,
    },
    hover: {
      background: theme.colors.primary30,
      color: theme.colors.primary80,
    },
  },
  blue: {
    root: {
      background: theme.colors.primary30,
      color: theme.colors.primary100,
    },
    hover: {
      background: theme.colors.primary40,
      color: theme.colors.primary100,
    },
  },
  transparent: {
    root: {
      background: "transparent",
      color: theme.colors.text80,
    },
    hover: {
      background: theme.colors.bgLight20,
      color: theme.colors.text90,
    },
  },
  transparentOnBlue: {
    root: {
      background: "transparent",
      color: "rgba(255, 255, 255, 0.8)",
    },
    hover: {
      background: "rgba(255, 255, 255, 0.08)",
      color: theme.colors.white100,
    },
  },
  transparentOnDark: {
    root: {
      background: "transparent",
      color: theme.colors.white100,
    },
    hover: {
      background: theme.colors.white12,
      color: theme.colors.white100,
    },
  },
  onError100: {
    root: {
      color: theme.colors.white100,
      background: theme.colors.error80,
    },
    hover: {
      color: theme.colors.white100,
      background: theme.colors.error70,
    },
  },
  onError80: {
    root: {
      color: theme.colors.white100,
      background: theme.colors.error60,
    },
    hover: {
      color: theme.colors.white100,
      background: theme.colors.error60,
    },
  },
  onError60: {
    root: {
      color: theme.colors.white100,
      background: theme.colors.error40,
    },
    hover: {
      color: theme.colors.white100,
      background: theme.colors.error30,
    },
  },
  onError40: {
    root: {
      color: theme.colors.error110,
      background: theme.colors.error50,
    },
    hover: {
      color: theme.colors.error110,
      background: theme.colors.error70,
    },
  },
  onError20: {
    root: {
      color: theme.colors.error110,
      background: theme.colors.error30,
    },
    hover: {
      color: theme.colors.error110,
      background: theme.colors.error50,
    },
  },
  onError10: {
    root: {
      color: theme.colors.error110,
      background: theme.colors.error20,
    },
    hover: {
      color: theme.colors.error110,
      background: theme.colors.error40,
    },
  },

  onSuccess100: {
    root: {
      color: theme.colors.white100,
      background: theme.colors.success80,
    },
    hover: {
      color: theme.colors.white100,
      background: theme.colors.success70,
    },
  },
  onSuccess80: {
    root: {
      color: theme.colors.white100,
      background: theme.colors.success60,
    },
    hover: {
      color: theme.colors.white100,
      background: theme.colors.success50,
    },
  },
  onSuccess60: {
    root: {
      color: theme.colors.white100,
      background: theme.colors.success40,
    },
    hover: {
      color: theme.colors.white100,
      background: theme.colors.success30,
    },
  },
  onSuccess40: {
    root: {
      color: theme.colors.success110,
      background: theme.colors.success50,
    },
    hover: {
      color: theme.colors.success110,
      background: theme.colors.success70,
    },
  },
  onSuccess20: {
    root: {
      color: theme.colors.success110,
      background: theme.colors.success30,
    },
    hover: {
      color: theme.colors.success110,
      background: theme.colors.success50,
    },
  },
  onSuccess10: {
    root: {
      color: theme.colors.success110,
      background: theme.colors.success20,
    },
    hover: {
      color: theme.colors.success110,
      background: theme.colors.success40,
    },
  },
  onPrimary100: {
    root: {
      color: theme.colors.white100,
      background: theme.colors.primary80,
    },
    hover: {
      color: theme.colors.white100,
      background: theme.colors.primary70,
    },
  },
  onPrimary80: {
    root: {
      color: theme.colors.white100,
      background: theme.colors.primary60,
    },
    hover: {
      color: theme.colors.white100,
      background: theme.colors.primary50,
    },
  },
  onPrimary60: {
    root: {
      color: theme.colors.white100,
      background: theme.colors.primary40,
    },
    hover: {
      color: theme.colors.white100,
      background: theme.colors.primary30,
    },
  },
  onPrimary40: {
    root: {
      color: theme.colors.primary110,
      background: theme.colors.primary50,
    },
    hover: {
      color: theme.colors.primary110,
      background: theme.colors.primary70,
    },
  },
  onPrimary20: {
    root: {
      color: theme.colors.primary110,
      background: theme.colors.primary30,
    },
    hover: {
      color: theme.colors.primary110,
      background: theme.colors.primary60,
    },
  },
  onPrimary10: {
    root: {
      color: theme.colors.primary110,
      background: theme.colors.primary20,
    },
    hover: {
      color: theme.colors.primary110,
      background: theme.colors.primary40,
    },
  },
} as const;
