import { Frequency } from "../utils/dateUtils";

import { createClient } from "./apiClient";
import { Element } from "./dashboardElement";

export interface KIGoalSettings {
  value: number;
  frequency: Frequency;
  success: "higher" | "lower";
}

interface RawKISection {
  id: number;
  title: string;
  metrics: string;
  goals?: { [key: string]: KIGoalSettings };
  template_id?: string;
  position: number;
}

export interface KISection extends Element {
  metrics: string[];
  goals?: { [key: string]: KIGoalSettings };
  templateId?: string;
  position: number;
}

export interface TargetSection {
  hasMultipleTargets: boolean;
  section: {
    sectionData: KISection;
    sectionId: number;
  };
}

const client = createClient("views-service");

export const getKISections = async (token: string) => {
  const result = await client.new().get("/api/sections").auth(token).fetch<{
    data: RawKISection[];
  }>();
  if (result.error) {
    return [];
  }
  const { data } = result;

  return data.map((d) => ({
    ...d,
    metrics: d.metrics.split("|"),
    id: d.id,
  }));
};

export const createKISection = async ({
  token,
  title,
  metrics,
  templateId,
}: {
  token: string;
  title: string;
  templateId?: string;
  metrics: string[];
}) => {
  const result = await client
    .new()
    .post("/api/sections")
    .body({ title, metrics: metrics.join("|"), templateId })
    .auth(token)
    .fetch<{ data: number | null }>();
  return result.error ? null : result.data;
};

export const patchKISection = async ({
  token,
  id,
  title,
  metrics,
  templateId,
  goals,
}: {
  token: string;
  id: number;
  title: string;
  metrics: string[];
  templateId?: string;
  goals: { [key: string]: KIGoalSettings };
}) => {
  const result = await client
    .new()
    .patch("/api/sections/" + id)
    .body({ title, metrics: metrics.join("|"), goals, templateId })
    .auth(token)
    .fetch();
  return result.error ? null : true;
};

export const deleteKISection = async (
  token: string,
  id: number,
  force?: true,
) => {
  const result = await client
    .new()
    .delete("/api/sections/" + id + (force ? "?force=true" : ""))
    .body({})
    .auth(token)
    .fetch();
  return result;
};

export const orderKISection = async (token: string, indexes: string[]) => {
  const result = await client
    .new()
    .patch("/api/sections/order")
    .body({ indexes })
    .auth(token)
    .fetch();
  return result.error ? null : result.data;
};

export const getKISectionForTarget = async (
  token: string,
  metricKey: string,
) => {
  const result = await client
    .new()
    .get(`/api/metrics/${metricKey}/targetSection`)
    .auth(token)
    .fetch<{
      data: TargetSection;
    }>();

  return result.error ? null : result.data;
};

export const patchKISectionTarget = async ({
  token,
  metricKey,
  sectionId,
  target,
}: {
  token: string;
  metricKey: string;
  sectionId: string;
  target: KIGoalSettings;
}) => {
  const result = await client
    .new()
    .patch(`/api/metrics/${metricKey}/targetSection/${sectionId}`)
    .auth(token)
    .body({ target })
    .fetch();

  return result.error ? null : result.data;
};
