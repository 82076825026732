import { Fragment } from "react";
import styled from "styled-components";

import { theme } from "../../theme";

export const Decorator = styled.div<{
  last: boolean;
  depth: number;
  curDepth: number;
}>`
  &::before {
    position: absolute;
    top: 12px;
    left: ${({ curDepth }) => curDepth * 16 + (curDepth - 1) * 4}px;
    width: ${({ last, depth, curDepth }) =>
      last || depth === curDepth ? "9px" : "0"};
    height: 1px;
    margin: auto;
    content: "";
    background-color: ${theme.colors.borderDark};
  }

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${({ curDepth }) => curDepth * 16 + (curDepth - 1) * 4}px;
    width: 1px;
    height: ${({ last }) => (last ? "13px" : "100%")};
    content: "";
    background-color: ${theme.colors.borderDark};
  }
`;

export const FolderStructureDecorator = ({
  depth,
  lastPerDepth,
}: {
  lastPerDepth: boolean[];
  depth: number;
}) => {
  return (
    <>
      {Array(depth)
        .fill(1)
        .map((_, i) =>
          lastPerDepth[i + 1] && i + 1 !== depth ? (
            <Fragment key={crypto.randomUUID()}></Fragment>
          ) : (
            <Decorator
              key={crypto.randomUUID()}
              last={lastPerDepth[i + 1]}
              curDepth={i + 1}
              depth={depth}
            ></Decorator>
          ),
        )}
    </>
  );
};
