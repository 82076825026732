import {
  DEFAULT_PRICING_MODEL,
  PricingModels,
} from "../../common/types/users-service";
import { useBootstrap } from "../../hooks/bootstrap";
import { Popup } from "../../icecube-ux";
import GMVTierPricingPlanContainer from "../Plans/GMVTierBasedPricingPlanContainer";
import PackagePlansContainer from "../Plans/PackagePlansContainer";
import UsageBasedPricingPlanContainer from "../Plans/UsageBasedPricingPlanContainer";

interface PlansPopupProps {
  onClose?: () => void;
  asPopup?: boolean;
  zIndex?: number;
}
const PopupContent = ({ asPopup }: Pick<PlansPopupProps, "asPopup">) => {
  const { getUserTenantSetting } = useBootstrap();
  const pricingModel =
    getUserTenantSetting("pricing_model", undefined) || DEFAULT_PRICING_MODEL;

  switch (pricingModel) {
    case PricingModels.PACKAGE:
      return <PackagePlansContainer asPopup={asPopup} />;

    case PricingModels.USAGE_BASED:
      return (
        <UsageBasedPricingPlanContainer
          style={asPopup ? { maxWidth: "94%", marginLeft: "25px" } : undefined}
        />
      );

    case PricingModels.GMV_TIER_BASED:
    default: {
      return (
        <GMVTierPricingPlanContainer
          style={asPopup ? { maxWidth: "94%", marginLeft: "25px" } : undefined}
        />
      );
    }
  }
};

export default function PlansPopup({
  onClose = () => {},
  asPopup = true,
  zIndex = 4000,
}: PlansPopupProps) {
  if (!asPopup) {
    return <PopupContent />;
  }

  return (
    <Popup onClose={onClose} zIndex={zIndex}>
      <PopupContent asPopup />
    </Popup>
  );
}
