import { ConnectorAccounts } from "../components/ConnectorIcon/ConnectorIcon";
import { VIEW_KEY_TO_CONNECTOR_MAPPING } from "../components/CountryFilters/views/viewMapping";
import {
  ConnectorSetting,
  getIntegrationHandler,
  withNewIntegrationService,
} from "../integrations/integration";
import { _ } from "../languages/helper";
import {
  AccountDetails,
  AmazonAccount,
  BingDataSourceConfiguration,
  ConnectorList,
  FbDataSourceConfiguration,
  GADataSourceConfiguration,
  GAdsDataSourceConfiguration,
  GAFourDataSourceConfiguration,
  getConnectorDetails,
  getConnectorsOfDataSource,
  getDatasourcesV2,
  RefreshStatusData,
  SnapChatDataSourceConfiguration,
  TikTokDataSourceConfiguration,
} from "../lib/integrationsService";

export const AD_ACCOUNT_TYPES = [
  "google-ads",
  "facebook-ads",
  "snapchat-ads",
  "bing-ads",
  "tiktok-ads",
  "pinterest-ads",
  "google-sheets",
  "criteo",
  "amazon-ads",
];

const SALES_CHANNEL_TYPES = ["shopify", "amazon-selling-partner", "klaviyo"];

export const getDatasourceStatusesByConnector = (
  statuses: RefreshStatusData[],
) => {
  return statuses.reduce((acc, status) => {
    const integration = status.integration;
    const statusesForIntegration = acc.get(integration);
    acc.set(integration, [...(statusesForIntegration ?? []), status]);
    return acc;
  }, new Map<string, RefreshStatusData[]>());
};

export const connectorStatus = (
  connectorKey: string,
  statuses: RefreshStatusData[],
) => {
  if (connectorKey === "polar") {
    return "ready";
  }
  const connectorGroups = getDatasourceStatusesByConnector(statuses);
  const connectorList = connectorGroups.get(connectorKey) ?? [];

  if (
    connectorList.length > 0 &&
    connectorList.filter((c) => c.setup_state === "incomplete").length ===
      connectorList.length
  ) {
    return "not_setup";
  }

  if (
    connectorList.length > 0 &&
    connectorList.filter(
      (c) =>
        (c.setup_state === "syncing" || c.setup_state === "building") &&
        c.is_historical_sync === true,
    ).length === connectorList.length
  ) {
    return "loading";
  }

  return connectorList.length === 0 ? "none" : "ready";
};

export const hasAtLeastOneConnector = (
  tableDependencies: string[],
  statuses: RefreshStatusData[],
  tableKeyToConnectorKey: { [key: string]: string[] },
) => {
  if (tableDependencies.length === 0) {
    return true;
  }
  const connectorGroups = getDatasourceStatusesByConnector(statuses);

  for (let i = 0; i < tableDependencies.length; i++) {
    if (
      tableKeyToConnectorKey[tableDependencies[i]] &&
      tableKeyToConnectorKey[tableDependencies[i]].find((c) => {
        const datasourcesforConnector = connectorGroups.get(c);
        return datasourcesforConnector && datasourcesforConnector.length > 0;
      }) !== undefined
    ) {
      return true;
    }
  }
  return false;
};

export const getConnectorBlockAccounts = (
  connectorData: ConnectorList,
  connector: ConnectorSetting,
) => {
  if (!connectorData[connector.key]) {
    return [];
  }

  const data = connectorData[connector.key];

  return data.map((c, i) => ({
    line1: c.shopify_url || connector.label,
    line2: _`Account` + ` ${i + 1}/${data.length}`,
    id: c.id,
    status: c.disabled ? "disabled" : "ready",
  })) as ConnectorAccounts[];
};

interface GetDatasourceAccounts {
  connectorKey: string;
  token: string;
  abortSignal: AbortSignal;
}

export const getDatasourceAccounts = async ({
  connectorKey,
  token,
  abortSignal,
}: GetDatasourceAccounts): Promise<
  { title: string; subtitle?: string; identifier: string }[]
> => {
  let dataSourceConnectors: {
    id: string;
    profiles?: string[];
    accounts?: string[];
    properties?: string[];
    organizations?: string[];
    customer_id?: string;
    connectorKey: string;
    shopify_url?: string;
  }[] = [];

  if (withNewIntegrationService(connectorKey)) {
    dataSourceConnectors = (
      await getDatasourcesV2(token, connectorKey, abortSignal)
    ).map((d) => ({ ...d, connectorKey }));
  } else {
    dataSourceConnectors = (
      await getConnectorsOfDataSource(token, connectorKey, abortSignal)
    ).map((d) => ({ ...d, connectorKey }));
  }
  const promises = dataSourceConnectors.flatMap(
    async (datasource, datasourceIndex) => {
      if (connectorKey === "pinterest-ads") {
        return [
          {
            title: _`Account` + ` ${datasourceIndex + 1}`,
            subtitle: "Pinterest " + _`Account`,
            identifier: datasource.id.split("-").join("").toUpperCase(),
          },
        ];
      } else if (connectorKey === "criteo") {
        return [
          {
            title: _`Account` + ` ${datasourceIndex + 1}`,
            subtitle: "Criteo",
            identifier: datasource.id.split("-").join("").toUpperCase(),
          },
        ];
      } else if (connectorKey === "klaviyo") {
        return [
          {
            title: _`Account` + ` ${datasourceIndex + 1}`,
            subtitle: datasource.shopify_url,
            identifier: datasource.id.split("-").join("").toUpperCase(),
          },
        ];
      } else {
        let connectorDetails: AccountDetails | undefined;
        try {
          connectorDetails = await getConnectorDetails(
            token,
            VIEW_KEY_TO_CONNECTOR_MAPPING[connectorKey] || connectorKey,
            datasource.id,
            abortSignal,
          );
        } catch {
          return [];
        }

        if (connectorKey === "google-analytics") {
          return (
            (connectorDetails as GADataSourceConfiguration)
              ?.available_profiles || []
          )
            .filter(
              (profile) =>
                datasource.accounts?.includes(profile.account_id) &&
                datasource.profiles?.includes(profile.profile_id),
            )
            .map((profile) => {
              return {
                title: `${profile.account_name} (${profile.account_id})`,
                subtitle: `${profile.profile_name} (${profile.profile_id})`,
                identifier: profile.profile_id,
              };
            });
        } else if (connectorKey === "google-analytics-four") {
          return (
            (connectorDetails as GAFourDataSourceConfiguration)
              ?.available_properties || []
          )
            .filter(
              (profile) =>
                datasource.accounts?.includes(profile.account_id) &&
                datasource.properties?.includes(profile.property_id),
            )
            .map((profile) => {
              return {
                title: `(GA4) ${profile.account_name} (${profile.account_id})`,
                subtitle: `${profile.property_name} (${profile.property_id})`,
                identifier: profile.property_id,
              };
            });
        } else if (connectorKey === "snapchat-ads") {
          return (
            (connectorDetails as SnapChatDataSourceConfiguration)
              ?.available_organizations || []
          )
            .filter((profile) => datasource.organizations?.includes(profile.id))
            .map((profile) => {
              return {
                title: `${profile.name}`,
                subtitle: _`Account` + ` ${profile.id}`,
                identifier: profile.id,
              };
            });
        } else if (connectorKey === "facebook-ads") {
          return (
            (connectorDetails as FbDataSourceConfiguration)
              ?.available_accounts || []
          )
            .filter((profile) => datasource.accounts?.includes(profile.id))
            .map((profile) => {
              return {
                title: `${profile.name}`,
                subtitle: _`Account` + ` ${profile.id}`,
                identifier: profile.id.startsWith("act_")
                  ? profile.id.substring(4)
                  : profile.id,
              };
            });
        } else if (connectorKey === "amazon-ads") {
          return ((connectorDetails as AmazonAccount)?.available_profiles || [])
            .filter(
              (profile) => datasource.profiles?.includes(profile.profile_id),
            )
            .map((profile) => {
              return {
                title: `${profile.account_name}`,
                subtitle: _`Account` + ` ${profile.profile_id}`,
                identifier: profile.profile_id,
              };
            });
        } else if (connectorKey === "tiktok-ads") {
          return (
            (connectorDetails as TikTokDataSourceConfiguration)
              ?.available_accounts || []
          )
            .filter((profile) => datasource.accounts?.includes(profile.id))
            .map((profile) => {
              return {
                title: `${profile.name}`,
                subtitle: _`Account` + ` ${profile.id}`,
                identifier: profile.id,
              };
            });
        } else if (connectorKey === "bing-ads") {
          return (
            (connectorDetails as BingDataSourceConfiguration)
              ?.available_customers || []
          )
            .flatMap((profile) => profile.accounts)
            .filter(
              (account) => datasource.accounts?.includes(account.account_id),
            )
            .map((account) => {
              return {
                title: `${account.name}`,
                subtitle: _`Account` + ` ${account.account_id}`,
                identifier: account.account_id,
              };
            });
        } else if (connectorKey === "google-ads") {
          return (
            (connectorDetails as GAdsDataSourceConfiguration)
              ?.available_customers || []
          )
            .filter(
              (customer) => datasource.customer_id === customer.account_id,
            )
            .flatMap((customer) =>
              customer.accounts
                .filter(
                  (account) =>
                    datasource.accounts?.includes(account.account_id),
                )
                .map((account) => {
                  return {
                    title: `${account.name} (${account.account_id})`,
                    subtitle: `${customer.name} (${customer.account_id})`,
                    identifier: account.account_id,
                  };
                }),
            );
        }
      }
      return [];
    },
  );

  const resolved = [];
  // We want to call account details sequentially to avoid failed account-details calls
  for (let i = 0; i < promises.length; i++) {
    resolved.push(await promises[i]);
  }
  return resolved.flat();
};

export const getBusinessUsageOfConnector = (connector: string) => {
  const isAdPlatform = AD_ACCOUNT_TYPES.includes(connector);
  const isSalesChannel = SALES_CHANNEL_TYPES.includes(connector);
  return {
    isAdPlatform,
    isSalesChannel: isSalesChannel,
    isUnknownSource: !(isAdPlatform || isSalesChannel),
  };
};

export const getMissingLabel = ({
  connectorList,
  unknownSource,
  adPlatform,
  salesChannel,
}: {
  connectorList: string[];
  unknownSource: string[];
  adPlatform: string[];
  salesChannel: string[];
}) => {
  let list = [];
  if (connectorList.length === 0) {
    return "";
  }
  if (connectorList.length === 1) {
    list.push(getIntegrationHandler(connectorList[0])?.label);
  } else {
    // currently treat unknown source as ad platform
    const combinedAdPlatform = [...adPlatform, ...unknownSource];
    if (combinedAdPlatform.length > 1) {
      list.push(_`an ad platform`);
    } else if (combinedAdPlatform.length === 1) {
      const label = getIntegrationHandler(combinedAdPlatform[0])?.label;
      label && list.push(label);
    }
    if (salesChannel.length > 1) {
      list.push(_`a sales channel`);
    } else if (salesChannel.length === 1) {
      const label = getIntegrationHandler(salesChannel[0])?.label;
      label && list.push(label);
    }
  }
  list = list.filter(Boolean);
  if (list.length === 0) {
    return "";
  }
  if (list.length === 1) {
    return _`${["item", list[0]]} is required`;
  }
  return _`${[
    "items",
    `${list.slice(0, -1).join(", ")} ${_`and`} ${list.at(-1)}`,
  ]} are required`;
};
