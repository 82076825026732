import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";

import useOutsideClickHandler from "../hooks/useOutsideClickHandler";
import { ListOptions } from "../List";
import { ClassAndStyleProps, InteractionEvents } from "../shared";

import { FunctionHelperProps } from "./components/FunctionHelper";
import Step from "./components/Step";
import { StyledMultiStepFormulaInput } from "./MultiStepFormulaInput.styled";

export interface StepProps {
  type: "dropdown" | "select";
  options: ListOptions[];
  placeholder: React.ReactNode;
  dropdownComponent?: React.ReactElement;
  valueRenderer?: (
    value: string,
    label: string,
    options: ListOptions[],
  ) => React.ReactNode;
  allowCustomValue?: boolean;
  loading?: boolean;
  argumentCount?: number;
  onSearchChange?: (value: string) => void;
  functionHelperInfo?: FunctionHelperProps;
  initialSearch?: string;
}

export type MultiStepFormulaInputProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    disableEdit: boolean;
    steps: StepProps[];
    values: Array<string | null>;
    onChangeStepValue: (stepId: number, value: string | null) => void;
    onPaste?: (stepId: number, value: string) => void;
  };

export default function MultiStepFormulaInput({
  disableEdit,
  steps,
  values,
  onChangeStepValue,
  onPaste,
  className,
  style,
  ...interactionEvents
}: MultiStepFormulaInputProps) {
  const stepsRef = useRef<{
    [key: number]: HTMLInputElement | HTMLDivElement | null;
  }>({});
  const [openedStep, setOpenedStep] = useState(-1);

  const handleValueChange = (id: number, value: string | null) => {
    if (value !== null) {
      onChangeStepValue(id, value);
      handleStepClose();
    } else {
      onChangeStepValue(id, null);
    }
  };

  const handleStepOpen = (id: number) => {
    setOpenedStep(id);
  };

  const handleStepClose = useCallback(() => {
    setOpenedStep(-1);
  }, [setOpenedStep]);

  const handleMoveToStep = (id: number) => {
    if (stepsRef.current[id]) {
      stepsRef.current[id]?.click();
    }
  };

  const renderStep = (
    parenthesisArgumentCount: number[],
    id: number,
    step: StepProps,
  ) => {
    if (id > 0 && values.length < id) {
      // eslint-disable-next-line no-param-reassign
      parenthesisArgumentCount.forEach((p) => (p = p - 1)); // Do not change this line, we need to change the argument
      return null;
    }

    if (step.argumentCount !== undefined && step.argumentCount > 0) {
      parenthesisArgumentCount.push(1);
      parenthesisArgumentCount.forEach((_, id) => {
        if (parenthesisArgumentCount[id] > 0) {
          parenthesisArgumentCount[id] += step.argumentCount ?? 0;
        }
      });
    }

    parenthesisArgumentCount.forEach((_, id) => parenthesisArgumentCount[id]--);
    return (
      <React.Fragment key={`step-${id}`}>
        <Step
          ref={(el) => (stepsRef.current[id] = el)}
          autoFocus={id === values.length && id > 0}
          content={step.dropdownComponent}
          onOpen={() => !disableEdit && handleStepOpen(id)}
          onMoveLeft={() => !disableEdit && handleMoveToStep(id - 1)}
          onMoveRight={() => !disableEdit && handleMoveToStep(id + 1)}
          onPaste={(value) => onPaste && onPaste(id, value)}
          isOpened={openedStep === id}
          value={values?.[id] ?? null}
          onValueChange={(value) =>
            !disableEdit && handleValueChange(id, value)
          }
          isFirst={id === 0}
          isLast={id === steps.length - 1}
          isCurrentLast={id === values.length}
          openParenthesis={
            step.argumentCount !== undefined && step.argumentCount > 0
          }
          closeParenthesis={
            parenthesisArgumentCount.filter((p) => p === 0).length
          }
          isFunctionArgument={
            parenthesisArgumentCount.find((p) => p > 0) !== undefined
          }
          functionHelperInfo={step.functionHelperInfo}
          {...step}
        />
      </React.Fragment>
    );
  };
  // to avoid the always close effect because the array ref is always different
  const valuesString = values.join("");

  useEffect(() => {
    if (values.length === steps.length && values[values.length - 1]) {
      handleStepClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleStepClose, steps.length, valuesString]);

  const containerRef = useRef<HTMLDivElement>(null);
  useOutsideClickHandler(handleStepClose, containerRef.current);

  const parenthesisArgumentCount: number[] = [];
  return (
    <StyledMultiStepFormulaInput
      {...interactionEvents}
      className={classNames(className)}
      style={style}
      ref={containerRef}
    >
      {steps.map((step, id) => renderStep(parenthesisArgumentCount, id, step))}
    </StyledMultiStepFormulaInput>
  );
}
