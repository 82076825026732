import styled, { css } from "styled-components";

import { StyledIcon } from "../Icon/Icon.styled";
import { legacyTheme } from "../legacyTheme";
import {
  baseInputFieldColorMap,
  OnBackground,
} from "../shared/baseInputFieldColorMap";
import {
  baseInputFieldSizeStylesMap,
  TextFieldSize,
} from "../shared/baseInputFieldSizeStylesMap";
import { theme } from "../theme";

export const StyledSelect = styled.div<{
  block: boolean;
  size: TextFieldSize;
  withBackground: OnBackground;
  error?: string;
  open: boolean;
  disabled: boolean;
  secondary?: boolean;
}>`
  display: inline-flex;
  justify-content: space-between;
  align-items: stretch;
  cursor: pointer;
  position: relative;

  ${(block) => (block ? "display: flex;" : "")}

  border: 1px solid transparent;

  ${({ size, secondary, withBackground, open, disabled }) => {
    const colors = secondary
      ? {
          root: {
            color: theme.colors.primary100,
            backgroundColor: theme.colors.primary10,
            borderColor: theme.colors.primary10,
          },
          hover: {
            backgroundColor: theme.colors.bgLight30,
            borderColor: theme.colors.bgLight30,
          },
          focus: {
            backgroundColor: theme.colors.bgLight10,
            borderColor: theme.colors.bgLight10,
            outline: `solid 3px ${theme.colors.primary10}`,
          },
        }
      : baseInputFieldColorMap[withBackground];
    return css`
      ${baseInputFieldSizeStylesMap[size]}
      padding: 0;
      ${colors.root}

      div:hover > &:not(.disabled):not(.open) {
        ${colors.hover}
      }

      ${open ? colors.focus : ""}

      ${disabled &&
      css`
        border: 1px dashed ${theme.colors.text80};
        cursor: default;
      `}
    `;
  }}

  ${({ error }) =>
    error ? `border-color: ${theme.colors.error100} !important;` : ""}
`;

export const StyledSelectMainContent = styled.div<{
  size: TextFieldSize;
  template: string;
}>`
  display: grid;
  grid-template-columns: ${({ template }) => template};
  align-items: center;
  align-content: center;
  flex-grow: 1;
  gap: 8px;

  ${({ size }) => {
    return css`
      ${baseInputFieldSizeStylesMap[size]}
    `;
  }};
`;
export const StyledSelectValueDisplay = styled.div<{
  primary: boolean;
  secondary: boolean;
  disabled: boolean;
}>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({ disabled, secondary, primary }) =>
    primary
      ? theme.colors.text100
      : disabled
      ? theme.colors.text80
      : secondary
      ? theme.colors.primary100
      : theme.colors.text90};
`;
export const StyledSelectPlaceholder = styled.div<{ disabled: boolean }>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({ disabled }) =>
    disabled ? theme.colors.text70 : theme.colors.text80};
`;

export const StyledSelectIconRight = styled.div`
  margin-left: 4px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  & .droper {
    margin-right: -4px;
  }
`;

export const StyledSelectAddButton = styled.div<{
  withBackground: OnBackground;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  border-left: 1px solid
    ${({ withBackground }) =>
      withBackground === "whiteBackground"
        ? theme.colors.borderLight
        : theme.colors.borderMedium};
  width: 34px;
  text-align: center;

  ${StyledIcon} {
    vertical-align: middle;
  }
`;

export const StyleSelectList = styled.div`
  position: fixed;
  z-index: ${legacyTheme.zIndexes.layer4};
  border-radius: ${theme.borderRadius.r4px};
  background: ${theme.colors.white100};
  box-sizing: border-box;
  padding: 0;
  box-shadow: ${theme.shadows.regular};
`;

export const StyledSelectListScroller = styled.div`
  box-sizing: border-box;
  padding: 12px 12px 12px 12px;
  height: 100%;
  overflow: auto;
`;

export const StyledSelectListFooter = styled.div`
  padding: 8px;
  border-top: 1px solid ${theme.colors.borderLight};
`;

export const SyledFixedBottomShadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: ${theme.zIndexes.layer5};
`;
