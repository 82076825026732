import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  key: "shiphero",
  image: "Shiphero",
  label: "ShipHero",
  get description() {
    return _``;
  },
  category: INTEGRATION_CATEGORIES.other_datasources,
  isCustomConnector: true,
  backgroundColor: theme.customColors.fallbackConnectorBg,
  borderColor: theme.customColors.fallbackConnectorBorder,
});
