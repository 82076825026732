import styled, { css } from "styled-components";

import { legacyTheme } from "../legacyTheme";

import { typographyStylesMap } from "./typographyStylesMap";

export const StyledTypography = styled.div<{ variant?: string }>`
  font-family: ${legacyTheme.typography.default.fontFamily};
  font-style: ${legacyTheme.typography.default.fontStyle};
  font-size: ${legacyTheme.typography.fontSizes.regular};
  font-weight: ${legacyTheme.typography.default.fontWeight};
  color: ${legacyTheme.colors.text.regular};

  ${({ variant }) =>
    css`
      ${typographyStylesMap[variant || ""]}
    ` ?? ""}

  &.SectionTitle {
    font-size: ${legacyTheme.typography.fontSizes.xxlarge};
    font-weight: ${legacyTheme.typography.fontWeigth.w600};
    line-height: 24px;
  }

  &.Subtitle {
    font-size: ${legacyTheme.typography.fontSizes.xlarge};
    font-weight: ${legacyTheme.typography.fontWeigth.w600};
    line-height: 21px;
  }

  &.BodyRegular {
    font-size: ${legacyTheme.typography.fontSizes.large};
    font-weight: ${legacyTheme.typography.fontWeigth.w400};
    line-height: 18px;
  }

  &.BodyRegularBold {
    font-size: ${legacyTheme.typography.fontSizes.large};
    font-weight: ${legacyTheme.typography.fontWeigth.w600};
    line-height: 18px;
  }

  &.MediumRegular {
    font-size: ${legacyTheme.typography.fontSizes.regular};
    font-weight: ${legacyTheme.typography.fontWeigth.w400};
    line-height: 16px;
  }

  &.MediumRegularBold {
    font-size: ${legacyTheme.typography.fontSizes.regular};
    font-weight: ${legacyTheme.typography.fontWeigth.w600};
    line-height: 16px;
  }

  &.Tiny {
    font-size: ${legacyTheme.typography.fontSizes.small};
    font-weight: ${legacyTheme.typography.fontWeigth.w400};
    line-height: 15px;
  }

  &.TinyMedium {
    font-size: ${legacyTheme.typography.fontSizes.small};
    font-weight: ${legacyTheme.typography.fontWeigth.w500};
    line-height: 15px;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.noWrap {
    white-space: nowrap;
  }

  &.pointer {
    cursor: pointer;
  }

  &.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.underline {
    text-decoration: underline;
  }

  &.disableSelection {
    user-select: none;
  }
`;
