import { createClient } from "./apiClient";

const client = createClient("views-service");

export interface Annotation {
  views_ids: string[];
  datetime: string;
  description: string;
  id?: number;
}

const cleanViewsIds = (viewsIds: string[]) =>
  viewsIds.map((viewId) => {
    const [firstPart, secondPart] = viewId.split("-");
    return `${firstPart}-${secondPart}`;
  });

export const getAnnotations = async (token: string, viewsIds: string[]) => {
  const result = await client
    .new()
    .get(`/api/annotations?view_ids=${cleanViewsIds(viewsIds).join(",")}`)
    .auth(token)
    .fetch<{
      data: Annotation[];
    }>();
  if (result.error) {
    return [];
  }
  const { data } = result;
  return data.map((annotation) => ({
    ...annotation,
  })) as Annotation[];
};

export const createAnnotation = async (
  token: string,
  annotation: {
    views_ids: string[];
    datetime: string;
    description: string;
  },
) => {
  const { views_ids, datetime, description } = annotation;
  const result = await client
    .new()
    .post("/api/annotations")
    .body({ views_ids: cleanViewsIds(views_ids), datetime, description })
    .auth(token)
    .fetch();
  return result.error ? null : result.data;
};

export const editAnnotation = async (
  token: string,
  annotation: {
    views_ids: string[];
    datetime: string;
    description: string;
    id?: number;
  },
) => {
  const { views_ids, datetime, description, id } = annotation;
  const result = await client
    .new()
    .patch(`/api/annotations/${id}`)
    .body({ id, views_ids: cleanViewsIds(views_ids), datetime, description })
    .auth(token)
    .fetch();
  return result.error ? null : result.data;
};

export const deleteAnnotation = async (token: string, id: number) => {
  const result = await client
    .new()
    .delete(`/api/annotations/${id}`)
    .auth(token)
    .fetch();
  return result.error ? null : result.data;
};
