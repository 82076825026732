import * as d3 from "d3";
import { useMemo } from "react";

import { serieStack } from "./chartUtils";
import Line from "./Line";
import { Serie, SerieFocusState } from "./Serie";

interface StackedLinesProps {
  width: number;
  height: number;
  margin: [number, number, number, number];
  series: Serie<number>[];
  yScales: Record<string, d3.ScaleLinear<number, number, never>>;
  onChangeFocusState?: (serieName: string, focusState: SerieFocusState) => void;
}

export default function StackedLines({
  width,
  height,
  margin,
  series,
  yScales,
  onChangeFocusState,
}: StackedLinesProps) {
  const largestSetValueCount = Math.max(...series.map((d) => d.values.length));

  const xBarScale = useMemo(() => {
    return d3
      .scaleBand<number>()
      .domain(d3.range(series[0].values.length))
      .range([margin[3], width - margin[1]]);
  }, [series, margin, width]);

  const barWidth = xBarScale.bandwidth() / series.length;

  const xLineScale = useMemo(() => {
    return d3
      .scaleLinear()
      .domain([0, largestSetValueCount])
      .range([margin[3], width - margin[1]]);
  }, [largestSetValueCount, margin, width]);

  const stackedSeries = useMemo(() => {
    return serieStack(series);
  }, [series]);

  return (
    <>
      {[...stackedSeries].reverse().map((serie) => {
        if (serie.displayType === "tooltip-only") {
          return null;
        }

        return (
          <Line
            withArea
            barWidth={barWidth}
            xLineScale={xLineScale}
            key={serie.name}
            serie={serie}
            height={height}
            width={width}
            margin={margin}
            totalNumberOfLines={stackedSeries.length}
            yScale={yScales[serie.yAxisKey]}
            onChangeFocusState={onChangeFocusState}
          />
        );
      })}

      {stackedSeries.map((serie) => {
        if (serie.displayType === "tooltip-only") {
          return null;
        }

        const color = serie?.color || "red";
        const opacity = serie?.focusState === "dimmed" ? 0.15 : 1;

        return (
          <>
            {serie.values.map((value, index) => {
              return (
                <circle
                  stroke="white"
                  r={4}
                  fill={color}
                  cy={yScales[serie.yAxisKey](value)}
                  cx={xLineScale(index) + (barWidth * series.length) / 2}
                  strokeOpacity={opacity}
                  fillOpacity={opacity}
                ></circle>
              );
            })}
          </>
        );
      })}
    </>
  );
}
