import { useMemo } from "react";
import styled, { css } from "styled-components";

import { theme } from "../../theme";
import { Text } from "../../Typography";

import { AreaDataPoint, Serie } from "./Serie";
import TooltipLineWithCircle from "./TooltipLineWithCircle";

export const GraphTooltipTitle = styled.div`
  margin-bottom: 2px;
`;

export const GraphTooltipContent = styled.div<{ hasTitle: boolean }>`
  ${({ hasTitle }) => {
    if (hasTitle) {
      return css`
        margin-top: 12px;
      `;
    }
  }}

  width: 100%;
  white-space: nowrap;
`;

export interface DefaultTooltipContentProps {
  tooltipTitle?: string;
  data: Serie<number | string | AreaDataPoint>[];
  valueIndex: number;
}

export default function TooltipContent({
  data,
  valueIndex,
  tooltipTitle,
}: DefaultTooltipContentProps) {
  const entry = useMemo(() => {
    return data.map((serie) => ({
      line: serie.displayType === "line",
      dashed: serie.dashed,
      color:
        serie?.legendStyle?.backgroundColor ??
        serie?.colors?.[valueIndex ?? 0] ??
        serie.color,
      border: serie?.legendStyle?.border,
      titleSegments: [
        serie.label == null
          ? serie.name == null
            ? "..."
            : serie.name
          : serie.label,
      ],
      value: String(
        serie.valueFormatter
          ? serie.valueFormatter(serie.values[valueIndex ?? 0] as number)
          : serie.values[valueIndex ?? 0],
      ),
    }));
  }, [data, valueIndex]);

  return (
    <>
      <GraphTooltipTitle>
        {tooltipTitle && (
          <Text variant="body10Medium" color={theme.colors.text110}>
            {tooltipTitle}
          </Text>
        )}
      </GraphTooltipTitle>
      <GraphTooltipContent hasTitle={Boolean(tooltipTitle)}>
        {entry.map((entry, i) => (
          <TooltipLineWithCircle key={`entry-${i}`} entry={entry} />
        ))}
      </GraphTooltipContent>
    </>
  );
}
