import classNames from "classnames";
import React from "react";

import { ClassAndStyleProps, InteractionEvents } from "../shared";

import { StyledScroller } from "./Scroller.styled";

type ScrollerProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    maxHeight?: string;
    maxWidth?: string;
    children?: React.ReactNode;
  };

export default function Scroller({
  maxHeight,
  maxWidth,
  children,
  className,
  style,
  ...interactionEvents
}: ScrollerProps) {
  return (
    <StyledScroller
      {...interactionEvents}
      className={classNames(className)}
      style={{ ...style, ...{ maxWidth, maxHeight } }}
    >
      {children}
    </StyledScroller>
  );
}
