import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

import { _ } from "../../../languages/helper";
import { Flex } from "../../Flex";
import { Icon } from "../../Icon";
import { StyledDragDropIcon } from "../../Icon/CustomIcons";
import { IconNames } from "../../Icon/Icon";
import { MoreOptions, OptionItem } from "../../MoreOptions";
import { Tag } from "../../Tag";
import { Tooltip } from "../../Tooltip";
import { useReportTable } from "../ReportTable.context";

import ReportTableHeaderColumnCellRightButton from "./ReportTableHeaderColumnCellRightButton";
import {
  StyledReportTableHeaderActionsGroup,
  StyledReportTableHeaderActionsRow,
  StyledReportTableHeaderCell,
  StyledReportTableHeaderCellLabel,
  StyledReportTableHeaderCellRightIconWrapper,
} from "./ReportTableHeaderColumns.styled";
import { ReportTableHeaderColumn } from "./types";

interface ReportTableHeaderColumnCellProps {
  left: boolean;
  column: ReportTableHeaderColumn;
  columnStart: number;
  columnEnd: number;
  position: { row: number; column: number };
  startDragEvent: (index: number) => void;
  dragStatus: { movingIndex: number | null; toIndex: number | null };
  readOnly?: boolean;
  minimalColumnHeader?: boolean;
}

export default function ReportTableHeaderColumnCell({
  left,
  column,
  columnStart,
  columnEnd,
  position,
  startDragEvent,
  dragStatus,
  readOnly,
  minimalColumnHeader,
}: ReportTableHeaderColumnCellProps) {
  const { changeOrdering, deleteColumn, ordering } = useReportTable();
  const labelRef = useRef(null);
  const [isLabelTruncated, setIsLabelTruncated] = useState(false);
  const actions: OptionItem[] = minimalColumnHeader
    ? []
    : [
        ...(column.nonOrderable
          ? []
          : [
              {
                label: _`Sort descending`,
                icon: "ArrowDownWithTail" as IconNames,
                onClick: () => changeOrdering(column.key, "DESC"),
              },
              {
                label: _`Sort ascending`,
                icon: "ArrowUpWithTail" as IconNames,
                onClick: () => changeOrdering(column.key, "ASC"),
              },
            ]),
        ...(column.actions || []),
        ...(readOnly || column.nonDeletable
          ? []
          : [
              {
                label: _`Delete`,
                icon: "Delete" as IconNames,
                onClick: () => deleteColumn(left, column.key),
              },
            ]),
      ];

  useEffect(() => {
    const labelNode = labelRef.current;
    if (!labelNode) return;

    const resizeObserver = new ResizeObserver(([entry]) => {
      if (!entry?.target) {
        return;
      }

      setIsLabelTruncated(
        (entry.target.parentElement?.scrollHeight || 0) * 2 <
          entry.target.scrollHeight,
      );
    });

    resizeObserver.observe(labelNode);

    return () => resizeObserver.unobserve(labelNode);
  }, [labelRef, column.label]);

  const showDragIcon =
    position.row === 0 &&
    !column.nonDraggable &&
    !readOnly &&
    !column.isSecondary;
  const showSortIcon = ordering.columnKey === column.key;
  return (
    <StyledReportTableHeaderCell
      className={classNames({
        dragTarget:
          position.row === 0 && dragStatus.toIndex === position.column,
        dragMoving:
          position.row === 0 && dragStatus.movingIndex === position.column,
        secondary: column.isSecondary,
      })}
      style={{
        gridColumnStart: columnStart,
        gridColumnEnd: columnEnd,
        gridRow: `${position.row + 1} / ${position.row + 2}`,
      }}
      background={column?.colors?.background}
      color={column?.colors?.color}
      borderColor={column?.colors?.borderColor}
    >
      {minimalColumnHeader ? (
        <></>
      ) : (
        <StyledReportTableHeaderActionsRow>
          <StyledReportTableHeaderActionsGroup side="left">
            {(showDragIcon || showSortIcon) && (
              <Flex alignItems="center">
                {showDragIcon && (
                  <StyledDragDropIcon
                    background="light"
                    size={14}
                    onMouseDown={() => startDragEvent(position.column)}
                  />
                )}
                {showSortIcon && (
                  <Icon
                    style={{ flexShrink: 0 }}
                    size={14}
                    onClick={() => changeOrdering(column.key)}
                    name={
                      ordering.direction === "ASC"
                        ? "ArrowUpWithTail"
                        : "ArrowDownWithTail"
                    }
                  />
                )}
              </Flex>
            )}
            <Flex alignItems="center" style={{ marginLeft: 4 }}>
              {column.icon ? column.icon : null}
            </Flex>
          </StyledReportTableHeaderActionsGroup>
          <StyledReportTableHeaderActionsGroup side="right">
            {column.rightButton && (
              <ReportTableHeaderColumnCellRightButton
                button={column.rightButton}
              />
            )}
            {column.rightIcon && column.rightIconTooltipContent && (
              <Tooltip
                contentStyle={{ padding: 0 }}
                persistOnClick
                white
                direction="top"
                content={column.rightIconTooltipContent}
              >
                <StyledReportTableHeaderCellRightIconWrapper>
                  {column.rightIcon}
                </StyledReportTableHeaderCellRightIconWrapper>
              </Tooltip>
            )}
            {column.rightIcon && !column.rightIconTooltipContent && (
              <StyledReportTableHeaderCellRightIconWrapper>
                {column.rightIcon}
              </StyledReportTableHeaderCellRightIconWrapper>
            )}
            {column.secondaryLabel && (
              <Tag color="primaryDarker" size="small">
                {column.secondaryLabel}
              </Tag>
            )}
            {column.rightComponent}
            {actions.length > 0 && !column.isSecondary && (
              <MoreOptions
                iconButtonColor="dark"
                dataKey={column.key}
                options={actions}
                size="tiny"
              />
            )}
            {column.withCrossIcon && (
              <Icon
                name="CloseCircle"
                onMouseDown={() =>
                  column.onClickCrossIcon && column.onClickCrossIcon()
                }
              />
            )}
          </StyledReportTableHeaderActionsGroup>
        </StyledReportTableHeaderActionsRow>
      )}
      <Tooltip
        style={{ flexGrow: 1 }}
        delay={500}
        parentDisplay="flex"
        contentStyle={{ display: isLabelTruncated ? undefined : "none" }}
        wrapperStyle={{ flexGrow: 1, display: "flex", alignItems: "flex-end" }}
        content={column.label}
      >
        <StyledReportTableHeaderCellLabel
          ref={labelRef}
          onClick={() =>
            column.nonOrderable !== true && changeOrdering(column.key)
          }
          className={classNames({ orderable: column.nonOrderable !== true })}
        >
          {column.headerComponent
            ? column.headerComponent
            : column.headerFormatter
            ? column.headerFormatter(column.label)
            : column.label}
        </StyledReportTableHeaderCellLabel>
      </Tooltip>
    </StyledReportTableHeaderCell>
  );
}
