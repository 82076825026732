import { useEffect, useMemo, useState } from "react";

export type ScreenType = "xlarge" | "large" | "medium" | "small";

const useWindowSize = () => {
  const [state, setState] = useState<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const screenType: ScreenType = useMemo(() => {
    if (state.width > 1500) {
      return "xlarge";
    }
    if (state.width > 1200) {
      return "large";
    }
    return state.width < 1000 ? "small" : "medium";
  }, [state]);

  useEffect((): (() => void) | void => {
    const handler = () => {
      setState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handler);

    return () => {
      window.removeEventListener("resize", handler);
    };
  }, []);

  return { ...state, screenType };
};

export default useWindowSize;
