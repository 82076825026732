import { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useBootstrap } from "../../hooks/bootstrap";
import { useSmartFilter } from "../../hooks/useSmartFilter";
import {
  Tooltip,
  IconButton,
  Text,
  Flex,
  Space,
  theme,
  Icon,
  Button,
  Tag,
} from "../../icecube-ux";
import { useDeviceType } from "../../icecube-ux/hooks/provideDeviceType";
import { _ } from "../../languages/helper";
import {
  isAffectedByDataSettingsPage,
  supportsUnderlyingData,
} from "../../pages/custom/metric-components/utils";
import { getComposeRules } from "../../pages/key-indicators/utils";
import { MetricDescription } from "../../types/synthesizer";
import { openAsCustomReport } from "../../utils/customReport";
import { trackEvent, TRACKING_EVENTS } from "../../utils/trackingUtils";

import MetricDescriptionText from "./MetricDescriptionText";

interface MetricInfoIconProps {
  metricKey?: string;
  description?: MetricDescription[] | string;
  label?: string;
  size?: "large" | "small";
  rightComponent?: React.ReactNode;
  iconContext: string;
}

const StyledDataSettingsLink = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  color: ${theme.colors.text80};

  &:hover {
    color: ${theme.colors.text90};
  }
`;

type DataSettingsLinkProps = {
  onClick?: () => void;
};

const DataSettingsLink = ({ onClick }: DataSettingsLinkProps) => {
  return (
    <Link to="/settings/data" onClick={onClick}>
      <StyledDataSettingsLink>
        <Icon name="Edit" size={13} color="inherit" />

        <Text color="inherit" underline>{_`Edit data settings`}</Text>
      </StyledDataSettingsLink>
    </Link>
  );
};

const MetricInfoIcon = ({
  metricKey,
  description,
  label,
  size = "small",
  rightComponent,
  iconContext,
}: MetricInfoIconProps) => {
  const navigate = useNavigate();
  const { isMobile } = useDeviceType();
  const { tenant } = useBootstrap();
  const { range, selectedViewIds } = useSmartFilter();

  const infoTitle = description ? label : undefined;
  const info = useMemo(() => {
    if (!description) return null;
    if (typeof description === "string") return description;

    return <MetricDescriptionText description={description} />;
  }, [description]);

  const showDataSettingsLink = isAffectedByDataSettingsPage({
    metricKey: metricKey || "",
    isMobile,
  });

  return (
    <div>
      {info || infoTitle ? (
        <Tooltip
          mouseLeaveDelay={200}
          direction="top"
          content={
            <>
              {infoTitle || showDataSettingsLink ? (
                <>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text variant="body13Medium">{infoTitle || ""}</Text>
                    {showDataSettingsLink ? (
                      <DataSettingsLink
                        onClick={() => {
                          trackEvent(
                            TRACKING_EVENTS.DATA_SETTINGS_LINK_CLICKED,
                            {
                              tenantId: tenant.id,
                              metricKey,
                              linkContext: iconContext,
                              description: JSON.stringify(description),
                            },
                          );
                        }}
                      />
                    ) : null}
                  </Flex>
                  <Space size={12} />
                </>
              ) : null}
              {info}
              {rightComponent ? rightComponent : <></>}
              {!rightComponent &&
              supportsUnderlyingData({ isMobile, metricKey }) ? (
                <Flex fullWidth>
                  <Button
                    size="small"
                    color="primary"
                    style={{ width: "100%", marginTop: 12 }}
                    onClick={() => {
                      trackEvent(TRACKING_EVENTS.SHOW_UNDERLYING_DATA_CLICKED, {
                        tenantId: tenant.id,
                        context: `${iconContext}-info-tooltip`,
                        metric: metricKey,
                        granularity: "none",
                        dateRange: range,
                        selectedViewIds: JSON.stringify(selectedViewIds),
                      });
                      openAsCustomReport(navigate, {
                        template: "shopify-sales-report",
                        report: {
                          title: label
                            ? _`${[
                                "label",
                                label || "Metric",
                              ]} - Underlying Data`
                            : _`Underlying Data`,
                          rules: getComposeRules(tenant),
                        },
                        granularity: "day",
                        dateRange: range,
                        views: selectedViewIds,
                      });
                    }}
                  >
                    <Icon
                      name="Level3"
                      size={13}
                      style={{ stroke: theme.colors.primary100 }}
                    />
                    <Flex gap={8}>
                      <Text
                        variant="body12Regular"
                        color={theme.colors.white100}
                      >
                        {_`View underlying data`}
                      </Text>
                      <Tag color="blue">New</Tag>
                    </Flex>
                  </Button>
                </Flex>
              ) : null}
            </>
          }
          white={true}
        >
          <IconButton color="transparent" name="InfoCircle" size={size} />
        </Tooltip>
      ) : (
        <div />
      )}
    </div>
  );
};

export default MetricInfoIcon;
