import moment, { Moment } from "moment";

export interface DateRange {
  start: moment.Moment;
  end: moment.Moment;
}

export const noTz = (m: string | Moment | Date) => {
  const m1 = moment(m);
  const offsetInMinutes = m1.parseZone().utcOffset();
  return m1.add(offsetInMinutes, "m");
};

export const startOfDayInTimezone = (
  date: Date,
  timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone,
) => {
  const localeString = date.toLocaleString("en-US", { timeZone });
  const [month, day, year] = localeString
    .split(/\D/)
    .map((num) => parseInt(num, 10));

  const startOfDay = new Date(Date.UTC(year, month - 1, day));
  return startOfDay.toISOString().slice(0, 19) + "Z";
};
