import styled, { css } from "styled-components";

import { baseInputFieldColorMap } from "../shared/baseInputFieldColorMap";
import { theme } from "../theme";
import { typographyStylesMap } from "../Typography/typographyStylesMap";

const invisibleInputSizeMap = {
  root: {
    large: {
      ...typographyStylesMap.body18Regular,
      padding: "4px 4px",
    },
    small: {
      ...typographyStylesMap.body15Regular,
      padding: "2px 4px",
    },
  },
  input: {
    large: {
      padding: "2.5px 8px",
      margin: "1px 3px 2px",
    },
    small: {
      padding: "1.5px 8px",
      margin: "0.5px 3px",
    },
  },
};

export const StyledInvisibleInputWrapper = styled.div``;

interface SharedInvisibleInputProps {
  $size: "large" | "small";
  allowEditing?: boolean;
}

const sharedStyles = css<SharedInvisibleInputProps>`
  ${(props) =>
    props.allowEditing
      ? css`
          div > &:hover:not(:focus) {
            background: ${theme.colors.primary10};
            color: ${theme.colors.primary100};
          }
        `
      : ""}

  border-radius: ${theme.borderRadius.r4px};
  box-sizing: border-box;
  border: 1px solid transparent;

  ${(props) => invisibleInputSizeMap.root[props.$size]}
  color: ${theme.colors.text80};
`;

export const StyledInvisiblePlaceholder = styled.div<SharedInvisibleInputProps>`
  ${sharedStyles}
`;

export const StyledInvisibleValueDisplay = styled.div<SharedInvisibleInputProps>`
  ${sharedStyles}
  font-weight: ${theme.typography.fontWeight.medium};
  color: ${theme.colors.text100};
`;

export const StyledInvisibleInput = styled.input<SharedInvisibleInputProps>`
  ${sharedStyles}

  ${(props) => invisibleInputSizeMap.input[props.$size]}

  display: block;
  width: 100%;

  &:focus {
    ${baseInputFieldColorMap.whiteBackground.focus}
  }

  &::placeholder {
    color: ${theme.colors.text80};
  }
`;
