import { FunctionComponent } from "react";

import { Box } from "../../icecube-ux";

interface OperatorSeparatorProps {
  content: string;
  withMargin?: boolean;
}

export const OperatorSeparator: FunctionComponent<OperatorSeparatorProps> = ({
  content,
  withMargin,
}) => {
  return (
    <Box
      padding="2px"
      background="#f6fbff"
      borderRadius="4px"
      lineHeight="19px"
      letterSpacing="0.03em"
      textTransform="uppercase"
      textAlign="center"
      color="#898eb8"
      fontFamily="Poppins"
      fontStyle="normal"
      fontWeight="bold"
      fontSize="10px"
      margin={withMargin ? "12px 0" : undefined}
    >
      {content}
    </Box>
  );
};
