import { createContext, ReactNode, useContext } from "react";

import { RefreshStatusData } from "../lib/integrationsService";

import { useConnectorStatuses } from "./useConnectorsStatuses";

export interface ConnectorObservabilityContextProps {
  statuses: RefreshStatusData[];
  setIsWaitingForStatus: (datasourceId: string) => void;
  setStatusForDatasource: (
    datasourceId: string,
    status: RefreshStatusData,
  ) => void;
  connectorsWithErrors: RefreshStatusData[];
  hasShopifyHistoricalSync: boolean;
  hasLongShopifyHistoricalSync: boolean;
  loaded: boolean;
}

export const ConnectorObservabilityContext =
  createContext<ConnectorObservabilityContextProps | null>(null);

export const getModelLastRefreshDate = (
  model: string,
  statuses: RefreshStatusData[],
) => {
  let lastRefreshDate: string | null | undefined = null;
  statuses.forEach((status) => {
    if (status.models?.includes(model)) {
      if (!lastRefreshDate) {
        lastRefreshDate = status.last_refresh_date;
      } else if (
        status.last_refresh_date &&
        status.last_refresh_date > lastRefreshDate
      ) {
        lastRefreshDate = status.last_refresh_date;
      }
    }
  });
  return lastRefreshDate;
};

interface ProvideConnectorObservabilityProps {
  children: ReactNode;
}

export const ProvideConnectorObservability = ({
  children,
}: ProvideConnectorObservabilityProps) => {
  const connectorStatuses = useConnectorStatuses();

  return (
    <ConnectorObservabilityContext.Provider value={connectorStatuses}>
      {children}
    </ConnectorObservabilityContext.Provider>
  );
};

export const useConnectorObservability = () => {
  const context = useContext(ConnectorObservabilityContext);
  if (context === null) {
    throw Error("Connector observability context not provided");
  }
  return context;
};
