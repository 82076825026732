import classNames from "classnames";

import { Icon } from "../Icon";
import { IconNames } from "../Icon/Icon";
import { ClassAndStyleProps, InteractionEvents } from "../shared";

import {
  SegmentedButtonSizeProp,
  StyledSegmentedButton,
  StyledSegmentedWrapper,
} from "./SegmentedButton.styled";

type SegmentedButtonOptionProps = InteractionEvents<HTMLButtonElement> &
  ClassAndStyleProps & {
    title: string;
    icon?: IconNames;
    selected: boolean;
    disabled?: boolean;
  };

function Option({
  title,
  icon,
  disabled,
  selected,
  className,
  onClick,
  ...interactionEvents
}: SegmentedButtonOptionProps) {
  return (
    <StyledSegmentedButton
      {...interactionEvents}
      onClick={(e) => {
        if (disabled || selected) {
          return;
        }
        onClick?.(e);
      }}
      className={classNames(className)}
      selected={selected}
      disabled={disabled}
      type="button"
      role="checkbox"
      aria-checked={selected}
    >
      {icon ? <Icon size={10} name={icon} /> : null}
      {title}
    </StyledSegmentedButton>
  );
}

export type SegmentedButtonProps = InteractionEvents<HTMLFieldSetElement> &
  ClassAndStyleProps & {
    children: React.ReactElement<typeof Option>[];
  } & SegmentedButtonSizeProp;

function SegmentedButton({
  className,
  style,
  children,
  size = "small",
  ...interactionEvents
}: SegmentedButtonProps) {
  return (
    <StyledSegmentedWrapper
      {...interactionEvents}
      size={size}
      style={style}
      className={classNames(className)}
    >
      {children}
    </StyledSegmentedWrapper>
  );
}

export default Object.assign(SegmentedButton, {
  Option,
});
