import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  key: "impact",
  image: "Impact",
  label: "Impact",
  category: INTEGRATION_CATEGORIES.advertising,
  backgroundColor: theme.customColors.fallbackConnectorBg,
  borderColor: theme.customColors.fallbackConnectorBorder,
  isCustomConnector: true,
  get description() {
    return _`Partnership management platform`;
  },
});
