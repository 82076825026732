type ChipArrowHeadProps = { color: string };

export const ChipArrowHead = ({ color }: ChipArrowHeadProps) => (
  <svg
    viewBox="0.65 0.5 14 27.95"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    style={{
      height: "100%",
      flexShrink: 0,
    }}
  >
    <path
      d="M0.632812 0.4646C1.50463 0.4646 2.33708 0.827605 2.9303 1.46647L13.1045 12.4232C14.1733 13.5743 14.1733 15.3549 13.1045 16.506L2.9303 27.4627C2.33708 28.1016 1.50463 28.4646 0.632812 28.4646V14.4646V0.4646Z"
      fill={color}
    />
  </svg>
);
