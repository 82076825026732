import { FunctionComponent } from "react";

import { Input, Select } from "../../icecube-ux";
import {
  DimensionTypes,
  INTEGER_RULE_OPERATORS,
  RuleOperators,
  RuleWithFilter,
  STRING_RULE_OPERATORS,
} from "../../utils/filterUtils";
import { filterIsNotNullOrUndefined } from "../../utils/utils";

interface DynamicRuleProps {
  rule: RuleWithFilter;
  onRuleChange: (rule: RuleWithFilter) => void;
}

export const DynamicRule: FunctionComponent<DynamicRuleProps> = ({
  rule,
  onRuleChange,
}) => {
  const dimensionFilter = rule.dimensionFilter;
  const type = dimensionFilter.type;

  const updateValueAtIndex = (value: string, valueIndex: number) => {
    const ruleValues: string[] = (rule.dimensionFilter?.valueLabels || []).map(
      (_, i) => {
        if (i === valueIndex) {
          return value;
        }
        return (rule.value[i] as string) ?? "";
      },
    );

    onRuleChange({
      dimensionFilter,
      operator: rule.operator,
      value: ruleValues,
    });
  };

  const allRulesOperators = [
    ...INTEGER_RULE_OPERATORS,
    ...STRING_RULE_OPERATORS,
  ];

  const operatorOptions =
    dimensionFilter?.operators
      ?.map((operatorKey) =>
        allRulesOperators.find((operator) => operator.value === operatorKey),
      )
      .filter(filterIsNotNullOrUndefined) ?? [];

  return (
    <>
      <Select
        forcedDisplayedValue={
          operatorOptions.find((r) => r.value === rule.operator)?.label ?? "..."
        }
        options={operatorOptions}
        selected={[rule.operator]}
        onChange={(v) =>
          onRuleChange({
            dimensionFilter,
            operator: v[0] as RuleOperators,
            value: [],
          })
        }
        block
      />
      {(dimensionFilter?.valueLabels || []).map((label, valueLabelIndex) => (
        <Input
          type={type === DimensionTypes.integer ? "number" : "text"}
          key={`valueLabel-${valueLabelIndex}`}
          value={(rule.value?.[valueLabelIndex] as string) || ""}
          onChange={(v) => updateValueAtIndex(v as string, valueLabelIndex)}
          placeholder={label}
          block
        />
      ))}
    </>
  );
};
