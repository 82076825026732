import { ComposeRules } from "../utils/composeUtils";
import { randomString } from "../utils/utils";

import { getServiceHost } from "./services";

export interface CustomViewRule {
  filterKey: string;
  operator: string;
  value: string;
  connectorKey: string;
}

export interface CustomViewStore {
  title: string;
  subtitle: string;
  identifier: string;
  checked: boolean;
  opened: boolean;
  rules: CustomViewRule[];
  connectorKey: string;
}

export interface CustomViewConnector {
  connectorKey: string;
  name: string;
  icon: string;
  opened: boolean;
  connectorAccounts?: unknown[];
  stores: CustomViewStore[];
}

export interface CustomView {
  id: string;
  title: string;
  rules?: ComposeRules;
  currency?: string;
  connectors: CustomViewConnector[];
}
export interface CustomViewGroup {
  id: string;
  title: string;
  json_value: CustomView[];
}

let customViewToken = "";
let customViews: CustomViewGroup[] | null = null;

export const getCustomViews = async (token: string) => {
  if (customViews !== null && customViewToken === token) {
    return customViews;
  }

  const response = await fetch(getServiceHost("views-service") + "/api/views", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      LogContext: randomString(12),
      token,
    },
  });
  const json = (await response.json()) as
    | { data: CustomViewGroup[] }
    | undefined; //To fix, request can fail
  customViews = json?.data ?? null;
  customViewToken = token;
  return json?.data;
};

export const createCustomView = async (
  token: string,
  title: string,
  json: CustomView[],
) => {
  const response = await fetch(getServiceHost("views-service") + "/api/views", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      LogContext: randomString(12),
      token,
    },
    body: JSON.stringify({ title, json: JSON.stringify(json) }),
  });
  const result = (await response.json()) as { data: CustomView } | undefined; //To fix, request can fail
  customViews = null;
  return result?.data;
};

export const patchCustomView = async (
  token: string,
  id: string,
  title: string,
  json: CustomView[],
) => {
  const response = await fetch(
    getServiceHost("views-service") + "/api/views/" + id,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        LogContext: randomString(12),
        token,
      },
      body: JSON.stringify({ title, json: JSON.stringify(json) }),
    },
  );
  const result = (await response.json()) as { error: boolean }; //Tofix request might fail;
  customViews = null;
  return result.error === false;
};

export const deleteCustomView = async (token: string, id: string) => {
  const response = await fetch(
    getServiceHost("views-service") + "/api/views/" + id,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        LogContext: randomString(12),
        token,
      },
      body: JSON.stringify({}),
    },
  );
  const result = (await response.json()) as { error: boolean }; //Tofix request might fail;
  return result.error === false;
};
