import {
  GetApplicableGMVTierData,
  PlanName,
  SUBSCRIPTION_TYPE_NAMES,
} from "../../../common/types/subscription-service";
import { PricingModels } from "../../../common/types/users-service";
import {
  useRedirectToStripeCheckout,
  useTenantPlan,
  useSubscriptionEnd,
} from "../../../hooks/useAddonUtils";
import {
  Box,
  SubscriptionPlanCard,
  SubscriptionPlanFooter,
  SubscriptionPlanHeader,
  SubscriptionPlanListDivider,
} from "../../../icecube-ux";
import { _ } from "../../../languages/helper";
import { trackEvent, TRACKING_EVENTS } from "../../../utils/trackingUtils";
import {
  ActivateAccountSupport,
  ActivatePlatformAccess,
} from "../components/basicPlanContent";
import { ConfirmationProperties } from "../components/ConfirmationPopup";
import SubscriptionPlanButton from "../components/SubscriptionPlanButton";

type UsageBasedPlusCardProps = {
  price?: number;
  tier: GetApplicableGMVTierData["plans"][number]["tiers"][number] & {
    wrapperId: string;
  };
  isShowingPreviousPlan: boolean;
  recommendedThreshold: number | null;
  currentTier: {
    threshold: number;
    wrapperId: string;
    tierIndex: number;
    validFrom?: string | undefined;
  } | null;
  highlightedPlan: PlanName | null;
  setConfirmationProperties: (args: ConfirmationProperties | null) => void;
  blockSelfUpgrade: boolean;
  gmvTierBasedPricing: GetApplicableGMVTierData;
};

const GMVActivatePlanCard = ({
  price,
  tier,
  isShowingPreviousPlan,
  currentTier,
  recommendedThreshold,
  highlightedPlan,
  setConfirmationProperties,
  blockSelfUpgrade,
  gmvTierBasedPricing,
}: UsageBasedPlusCardProps) => {
  const { isOnUsageBasedPlusActivatePlan } = useTenantPlan();
  const { isSubscriptionEnding, subscriptionEndDate } = useSubscriptionEnd();
  const {
    isRedirecting,
    redirectStripeUsageBasedCheckout,
    redirectToStripeCustomerPortal,
  } = useRedirectToStripeCheckout({ setConfirmationProperties });

  const isTheActiveTier =
    tier.wrapperId === currentTier?.wrapperId &&
    tier.tierIndex === currentTier?.tierIndex;
  const isShowingPrice =
    tier.mode !== "flat_price_hidden" && tier.mode !== "custom";

  return (
    <SubscriptionPlanCard
      current={isOnUsageBasedPlusActivatePlan}
      selected={
        highlightedPlan ===
        SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLUS_ACTIVATE_PLAN
      }
    >
      <SubscriptionPlanHeader
        planInfo={null}
        planName={_`Analyze, Enrich & Activate Plan`}
        price={isShowingPrice ? price : undefined}
        recommended={
          highlightedPlan ===
          SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLUS_ACTIVATE_PLAN
        }
      ></SubscriptionPlanHeader>

      <SubscriptionPlanListDivider />

      <ActivatePlatformAccess showDifference={isShowingPreviousPlan} />

      <SubscriptionPlanListDivider />

      <ActivateAccountSupport showDifference={isShowingPreviousPlan} />

      <Box marginTop="auto" padding="0px 16px 16px 16px">
        <SubscriptionPlanButton
          isOnPlan={isTheActiveTier && isOnUsageBasedPlusActivatePlan}
          redirectStripeUsageBasedCheckout={
            isShowingPrice && !blockSelfUpgrade
              ? () => {
                  trackEvent(TRACKING_EVENTS.GMV_PLAN_CHANGE_CLICKED, {
                    plan: SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLUS_ACTIVATE_PLAN,
                    tier,
                    currentTier,
                    recommendedThreshold,
                  });
                  redirectStripeUsageBasedCheckout({
                    pricing_model: PricingModels.GMV_TIER_BASED,
                    plan: SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLUS_ACTIVATE_PLAN,
                    wrapperId: tier.wrapperId,
                    tierIndex: tier.tierIndex,
                  });
                }
              : undefined
          }
          isRedirecting={isRedirecting}
          subscriptionEndDate={subscriptionEndDate}
          isSubscriptionEnding={isSubscriptionEnding}
          redirectToStripeCustomerPortal={
            gmvTierBasedPricing.isSetUpOnStripe
              ? async () => {
                  trackEvent(TRACKING_EVENTS.GMV_CURRENT_PLAN_CLICKED, {
                    plan: SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLUS_ACTIVATE_PLAN,
                    currentTier,
                    recommendedThreshold,
                  });

                  await redirectToStripeCustomerPortal();
                }
              : undefined
          }
        />

        <SubscriptionPlanFooter>
          {_`Month to month. No commitment`}
        </SubscriptionPlanFooter>
      </Box>
    </SubscriptionPlanCard>
  );
};

export default GMVActivatePlanCard;
