import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  isKISectionElement,
  isReportElement,
  kiElementController,
  reportElementController,
} from "../lib/dashboardController";
import {
  Dashboard,
  DashboardElement,
  DashboardElementType,
  DashboardPage,
  Element,
} from "../lib/dashboardElement";
import * as dashboardService from "../lib/dashboardsService";
import { DashboardReorderPayload } from "../lib/dashboardsService";
import { Schedule } from "../lib/schedulesService";

import { useAuth } from "./auth/auth";
import {
  PerformanceEvent,
  usePerformanceMeasurement,
} from "./usePerformanceMeasurement";

type ElementsArray = Array<Element>;

export interface DashboardsElementsAssociationType
  extends Omit<Dashboard, "elements"> {
  elements: ElementsArray;
}
type ElementStore = Partial<Record<DashboardElementType, ElementsArray>>;
export interface DashboardsContextProps {
  loading: boolean;
  elementStore: ElementStore;
  dashboards: Dashboard[];
  getElementDashboard: (
    id: number,
    elementType: DashboardElementType,
  ) => number;
  createDashboard: (
    title: string,
    elements: DashboardElement[],
    page: DashboardPage,
  ) => Promise<number | null>;
  duplicateDashboard: (id: number) => Promise<number | null>;
  updateDashboard: (
    id: number,
    title: string,
    elements: DashboardElement[],
  ) => Promise<void>;
  deleteDashboard: (id: number) => Promise<void>;
  reorderDashboard: (data: DashboardReorderPayload) => Promise<void>;
  renameDashboard: (id: number, title: string) => Promise<void>;
  assignElementToDashboard: (
    elementId: number,
    dashboardId: number,
    dashboardElementType: DashboardElementType,
  ) => Promise<void>;
  dashboardsElementsAssociation: Array<DashboardsElementsAssociationType>;
  createElement: (element: ElementControlType) => Promise<number | null>;
  editElement: (
    element: {
      id: number;
    } & ElementControlType,
  ) => Promise<boolean>;
  deleteElement: (data: {
    id: number;
    elementType: DashboardElementType;
    force?: true;
  }) => Promise<void | Schedule[]>;
  duplicateElement: (data: {
    id: number;
    elementType: DashboardElementType;
  }) => Promise<number | null>;
  getDashboardElements: (id: number) => ElementsArray;
  elementNotInDashboard: ElementsArray;
}

const DashboardsContext = createContext<DashboardsContextProps | null>(null);

export function ProvideDashboards({ children }: { children: ReactNode }) {
  const provider = useProvideDashboards(["report", "kiSection"]);
  return (
    <DashboardsContext.Provider value={provider}>
      {children}
    </DashboardsContext.Provider>
  );
}

export const useDashboards = () => {
  const context = useContext(DashboardsContext);
  if (context === null) {
    throw Error("Dashboard context not provided");
  }
  return context;
};

interface ElementController<T> {
  getAll: (token: string) => Promise<T[]>;
  create: (token: string, element: T) => Promise<number | null>;
  edit: (token: string, id: number, element: T) => Promise<boolean>;
  delete: (
    token: string,
    id: number,
    force?: true,
  ) => Promise<{ error: boolean; data?: Schedule[] }>;
}

const getController = (elementTypes: DashboardElementType[]) => {
  const operators: {
    report?: ElementController<Element>;
    kiSection?: ElementController<Element>;
  } = {};
  for (const elementType of elementTypes) {
    switch (elementType) {
      case "report":
        operators[elementType] =
          reportElementController as ElementController<Element>;
        break;
      case "kiSection":
        operators[elementType] =
          kiElementController as ElementController<Element>;
        break;
      default:
    }
  }

  return operators;
};

const buildElementStore = (elementProcessors: DashboardElementType[]) => {
  const stores: ElementStore = {};
  for (const page of elementProcessors) {
    stores[page] = [] as Element[];
  }
  return stores;
};

interface ElementControlType {
  element: Element;
  elementType: DashboardElementType;
}

const getElementStringId = (element: Element) => {
  if (isReportElement(element)) {
    return `report-${element.id}`;
  }
  if (isKISectionElement(element)) {
    return `kiSection-${element.id}`;
  }
  return "";
};

const getElementType = (element: Element) => {
  if (isReportElement(element)) {
    return "report";
  }
  if (isKISectionElement(element)) {
    return "kiSection";
  }
  return null;
};
const useProvideDashboards = (elementTypes: DashboardElementType[]) => {
  const auth = useAuth();
  const measurement = usePerformanceMeasurement({
    eventRecord: {
      performanceEvent: PerformanceEvent.KI_SECTIONS_REQUEST_STARTED,
    },
  });
  const [loading, setLoading] = useState(true);
  const [dashboards, setDashboards] = useState<Dashboard[]>([]);
  const [elementStore, setElementStore] = useState(
    buildElementStore(elementTypes),
  );
  const elementController = useMemo(() => {
    return getController(elementTypes);
  }, [elementTypes]);
  const createDashboard = async (
    title: string,
    elements: DashboardElement[],
    page: DashboardPage,
  ) => {
    const dashboardIndex = await dashboardService.createDashboard(
      await auth.getToken(),
      title,
      elements,
      page,
    );
    if (dashboardIndex) {
      setDashboards((s) => [
        ...s,
        {
          id: dashboardIndex,
          title,
          elements,
          page,
          position: Math.max(0, ...dashboards.map((d) => d.position)) + 1,
        },
      ]);
    }
    return dashboardIndex;
  };

  const duplicateDashboard = async (id: number) => {
    const source = dashboardsElementsAssociation.find((d) => d.id === id);
    if (!source) {
      return null;
    }
    const newId = await dashboardService.createDashboard(
      await auth.getToken(),
      `${source.title} Copy`,
      [],
      source.page,
    );
    const newElements = await Promise.all(
      source.elements.map(async (element) => {
        const elementType = getElementType(element);
        if (elementType) {
          return {
            id: await createElement({
              element: { ...element, title: `${element.title} Copy` },
              elementType,
            }),
            type: elementType,
          };
        }
      }),
    );
    if (newId) {
      await updateDashboard(
        newId,
        `${source.title} Copy`,
        newElements.filter(
          (element): element is DashboardElement => !!element?.id,
        ),
      );
    }
    await loadDashboardsAndElements();
    return newId;
  };

  const renameDashboard = async (id: number, title: string) => {
    const sectionIndex = dashboards.findIndex((s) => s.id === id);
    if (sectionIndex < 0) {
      return;
    }

    await dashboardService.patchDashboard(
      await auth.getToken(),
      title,
      dashboards[sectionIndex].elements,
      id,
    );
    dashboards[sectionIndex].title = title;
    setDashboards([...dashboards]);
  };

  const updateDashboard = async (
    id: number,
    title: string,
    elements: DashboardElement[],
  ) => {
    await dashboardService.patchDashboard(
      await auth.getToken(),
      title,
      elements,
      id,
    );
    const sectionIndex = dashboards.findIndex((s) => s.id === id);
    if (sectionIndex > -1) {
      dashboards[sectionIndex].title = title;
      dashboards[sectionIndex].elements = elements;
      setDashboards(dashboards);
    }
  };

  const deleteDashboard = async (id: number) => {
    await dashboardService.deleteDashboard(await auth.getToken(), id);
    setDashboards((dashboards) =>
      dashboards.filter((dashboard) => dashboard.id !== id),
    );
  };

  const createElement = async ({
    elementType,
    element,
  }: ElementControlType) => {
    const id =
      (await elementController[elementType]?.create(
        await auth.getToken(),
        element,
      )) ?? 0;
    if (id) {
      setElementStore((s) => {
        return {
          ...s,
          [elementType]: [...(s?.[elementType] ?? []), { ...element, id }],
        };
      });
    }
    return id;
  };

  const duplicateElement = async ({
    id,
    elementType,
  }: {
    id: number;
    elementType: DashboardElementType;
  }) => {
    const element = elementStore?.[elementType]?.find((r) => r.id === id);
    const newElementId = element
      ? await createElement({
          element: { ...element, title: `${element.title} Copy` },
          elementType,
        })
      : 0;
    const dashboardId = getElementDashboard(id, elementType);
    if (newElementId && dashboardId > 0) {
      await assignElementToDashboard(newElementId, dashboardId, elementType);
    }
    return newElementId;
  };

  const editElement = async ({
    id,
    element,
    elementType,
  }: {
    id: number;
  } & ElementControlType) => {
    if (!id) return false;
    const succeed =
      (await elementController[elementType]?.edit(
        await auth.getToken(),
        id,
        element,
      )) ?? false;

    if (succeed) {
      const index =
        elementStore?.[elementType]?.findIndex((r) => r.id === id) ?? -1;
      if (index > -1) {
        const target = elementStore[elementType]![index];
        elementStore[elementType]![index] = {
          ...target,
          ...element,
          id,
        };
        setElementStore({
          ...elementStore,
          [elementType]: [...elementStore[elementType]!],
        });
      }
      return true;
    }
    return false;
  };

  const deleteElement = async ({
    id,
    elementType,
    force,
  }: {
    id: number;
    elementType: DashboardElementType;
    force?: true;
  }) => {
    if (!id) return;
    const dashboardId = getElementDashboard(id, elementType);
    const deletionResult = await elementController[elementType]?.delete(
      await auth.getToken(),
      id,
      force,
    );
    if (deletionResult?.error) {
      return deletionResult?.data || [];
    }
    if (dashboardId > 0) {
      const dashboard = dashboards.find((d) => d.id === dashboardId);
      if (dashboard) {
        await updateDashboard(
          dashboard.id,
          dashboard.title,
          dashboard.elements.filter((element) => element.id !== id),
        );
      }
    }
    setElementStore((s) => {
      return {
        ...s,
        [elementType]: [...(s?.[elementType] ?? [])].filter(
          (element) => element.id !== id,
        ),
      };
    });
    await loadDashboardsAndElements();
  };
  const reorderDashboard = async (data: DashboardReorderPayload) => {
    setLoading(true);
    await dashboardService.orderDashboardsV2(await auth.getToken(), data);
    await loadDashboardsAndElements();
  };

  const assignElementToDashboard = async (
    elementId: number,
    dashboardId: number,
    dashboardElementType: DashboardElementType,
  ) => {
    const newDashboard = dashboards.find((d) => d.id === dashboardId);
    const currentDashboard = dashboards.find((d) =>
      d.elements.some((element) => element.id === elementId),
    );
    if (currentDashboard) {
      await updateDashboard(
        currentDashboard.id,
        currentDashboard.title,
        currentDashboard.elements.filter((element) => element.id !== elementId),
      );
    }
    if (newDashboard) {
      await updateDashboard(newDashboard.id, newDashboard.title, [
        ...newDashboard.elements,
        { type: dashboardElementType, id: elementId },
      ]);
    }
    void loadDashboardsAndElements();
  };

  const getDashboard = useCallback(
    (id: number) => dashboards.find((d) => d.id === id),
    [dashboards],
  );

  const getDashboardElements = useCallback(
    (id: number) => {
      const dash = getDashboard(id);
      const res: ElementsArray = [];
      for (const element of dash?.elements ?? []) {
        const report = elementStore?.[element.type]?.find(
          (r) => r.id === element.id,
        );
        if (report) {
          res.push(report);
        }
      }
      return res;
    },
    [getDashboard, elementStore],
  );

  const getElementDashboard = (id: number, elementType: DashboardElementType) =>
    dashboards.find((d) =>
      d.elements.some(
        (element) => element.id === id && element.type === elementType,
      ),
    )?.id ?? 0;
  const elementNotInDashboard = useMemo(() => {
    const inDashboardIds = dashboards
      .map((d) => d.elements)
      .reduce((p, dashboardElements) => {
        dashboardElements.forEach((dashboardElement) =>
          p.add(`${dashboardElement.type}-${dashboardElement.id}`),
        );
        return p;
      }, new Set<string>());
    const notInDashboard = [];
    for (const elementType of elementTypes) {
      for (const element of elementStore?.[elementType] ?? []) {
        const elementStringId = getElementStringId(element);
        if (isReportElement(element)) {
          // section_key is a field for the report element that is used in acquisition and other pages
          if (!inDashboardIds.has(elementStringId) && !element.section_key) {
            notInDashboard.push(element);
          }
        }
        if (isKISectionElement(element)) {
          if (!inDashboardIds.has(elementStringId)) {
            notInDashboard.push(element);
          }
        }
      }
    }
    return notInDashboard;
  }, [elementStore, dashboards, elementTypes]);

  const loadDashboardsAndElements = useCallback(async () => {
    setLoading(true);
    const data = await dashboardService.getDashboards(await auth.getToken());

    setDashboards(data || []);

    const elements = await Promise.all(
      elementTypes.map(async (page) => {
        const elements = await elementController[page]?.getAll(
          await auth.getToken(),
        );
        if (page === "kiSection") {
          measurement?.reportTimeElapsed({
            performanceEvent: PerformanceEvent.KI_SECTIONS_REQUEST_FINISHED,
            nMetricsBySection:
              elements
                ?.filter(isKISectionElement)
                ?.map((section) => section?.metrics?.length || 0) ?? [],
          });
        }

        return { page, elements };
      }),
    );
    setElementStore(
      elements.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.page]: curr.elements,
        }),
        buildElementStore(elementTypes),
      ),
    );
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.getToken]);

  useEffect(() => {
    if (auth.processing || !auth.isLoggedIn) {
      return;
    }
    void loadDashboardsAndElements();
  }, [loadDashboardsAndElements, auth.processing, auth.isLoggedIn]);

  const dashboardsElementsAssociation: Array<DashboardsElementsAssociationType> =
    useMemo(() => {
      const dashboardsElementsAssociation: Array<DashboardsElementsAssociationType> =
        [];
      const usedElements: string[] = [];
      dashboards.forEach((d) => {
        const elements = getDashboardElements(d.id).filter((r) => r);
        dashboardsElementsAssociation.push({
          ...d,
          elements,
        });
        usedElements.push(
          ...elements.map((element) => getElementStringId(element)),
        );
      });
      for (const elementSource of elementTypes) {
        const elements = elementStore?.[elementSource]?.filter(
          (r) => !usedElements.includes(`${elementSource}-${r.id}`),
        );
        if (elements?.length) {
          dashboardsElementsAssociation.push({
            id: 0,
            title: "", // name will be determined later in the component
            page: elementSource === "report" ? "report" : "ki", // FIXME: clean this up when we finally migrate to mix the report and kiSection together
            position: Infinity,
            elements: elements.filter((r) => {
              if (isReportElement(r)) {
                return !r.section_key;
              }
              return true;
            }),
          });
        }
      }
      return dashboardsElementsAssociation;
    }, [dashboards, elementTypes, elementStore, getDashboardElements]);

  return {
    loading,
    elementStore,
    dashboards,
    getDashboardElements,
    getElementDashboard,
    createDashboard,
    duplicateDashboard,
    updateDashboard,
    deleteDashboard,
    reorderDashboard,
    renameDashboard,
    assignElementToDashboard,
    dashboardsElementsAssociation,
    createElement,
    editElement,
    deleteElement,
    duplicateElement,
    elementNotInDashboard,
  };
};
