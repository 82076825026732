import { useEffect, useState } from "react";

import { useAuth } from "../../../hooks/auth/auth";
import { Button, Flex, Popup, Text, theme } from "../../../icecube-ux";
import { _ } from "../../../languages/helper";
import { checkEmailValidity } from "../../../lib/usersService";
import { TRACKING_EVENTS, trackEvent } from "../../../utils/trackingUtils";

import ExploreOnMyOwn from "./ExploreOnMyOwn";
import Step1 from "./Step1";
import Step2 from "./Step2";

interface BookACallProps {
  onClose: () => void;
}

export default function BookACall({ onClose }: BookACallProps) {
  const { outboundDemoData } = useAuth();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [stepId, setStepId] = useState(1);
  const [email, setEmail] = useState("");

  const handleGoStep2 = async () => {
    if (email === "") {
      setError(_`Please enter your email address.`);
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      const emailValid = await checkEmailValidity(email);
      if (emailValid.free) {
        setError(
          _`Sorry, personal email domains are not allowed. Please provide a valid business email address.`,
        );
        setLoading(false);
        return;
      }
      if (emailValid.disposable) {
        setError(
          _`Sorry, disposable email domains are not allowed. Please provide a valid business email address.`,
        );
        setLoading(false);
        return;
      }
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
    setStepId(2);
  };

  useEffect(() => {
    trackEvent(TRACKING_EVENTS.OUTBOUND_TEST_B_CALL_POPUP_STEP_VIEWED, {
      step: stepId,
      ...outboundDemoData,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepId]);

  return (
    <Popup
      style={{ width: "650px", maxWidth: "90vw" }}
      onClose={onClose}
      footerComponent={
        stepId === 1 ? null : (
          <Flex flexDirection="column" gap={8}>
            <div
              style={{
                marginTop: "-25px",
              }}
            >
              <Text
                textAlign="center"
                variant="body12Regular"
                color={theme.colors.text80}
                style={{
                  background: "white",
                  display: "block",
                  width: "30px",
                  margin: "0 auto",
                }}
              >{_`Or`}</Text>
            </div>
            {stepId === 3 ? (
              <Button
                color="tertiary"
                block
                onClick={() => {
                  onClose();

                  trackEvent(
                    TRACKING_EVENTS.OUTBOUND_TEST_B_EXPLORE_ON_MY_OWN_CLICKED,
                    {
                      ...outboundDemoData,
                    },
                  );
                }}
              >
                {_`I'll explore on my own!`}
              </Button>
            ) : (
              <Button
                color="tertiary"
                block
                onClick={() => {
                  setStepId(3);

                  trackEvent(
                    TRACKING_EVENTS.OUTBOUND_TEST_B_CONTINUE_TO_EXPLORE_CLICKED,
                    {
                      ...outboundDemoData,
                    },
                  );
                }}
              >
                {_`Continue to explore the app`}
              </Button>
            )}
          </Flex>
        )
      }
    >
      {stepId === 1 && (
        <Step1
          loading={loading}
          error={error !== "" ? error : undefined}
          goToNextStep={() => handleGoStep2()}
          email={email}
          onEmailChange={setEmail}
        />
      )}
      {stepId === 2 && (
        <Step2
          email={email}
          goToNextStep={() => setStepId(3)}
          onCancel={() => setStepId(1)}
        />
      )}
      {stepId === 3 && <ExploreOnMyOwn />}
    </Popup>
  );
}
