import React from "react";

interface CenteringProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export default function Centering({
  className = "",
  style,
  children,
}: CenteringProps) {
  return (
    <div className={"centering " + (className || "")} style={style}>
      <div className="m-auto">{children}</div>
    </div>
  );
}
