import styled from "styled-components";

import Icon from "../Icon/Icon";
import { legacyTheme } from "../legacyTheme";
import { theme } from "../theme";

export const StyledCallout = styled.div`
  background: ${theme.colors.primary10};
  border: solid 1px ${theme.colors.primary30};
  padding: ${legacyTheme.padding.regular} ${legacyTheme.padding.xlarge};
  border-radius: ${theme.borderRadius.r8px};
  animation: animation-appear-grow 0.5s ease-in-out;
`;

export const StyledCalloutIcon = styled(Icon)`
  color: ${theme.colors.primary40};
`;
