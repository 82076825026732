import classNames from "classnames";
import React, { ReactChild } from "react";

import Counter from "../Counter/Counter";
import Icon, { IconNames } from "../Icon/Icon";
import { ClassAndStyleProps, InteractionEvents } from "../shared";

import useLabel from "./Label.hooks";
import {
  StyledLabel,
  StyledLeftComponent,
  StyledRightComponent,
} from "./Label.styled";

export type LabelProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    children?: React.ReactNode;
    highlighted?: string;
    block?: boolean;
    filled?: boolean;
    disabled?: boolean;
    counter?: number;
    leftIcon?: IconNames;
    rightIcon?: IconNames;
    leftComponents?: React.ReactElement[];
    rightComponents?: React.ReactElement[];
    pointerCursor?: boolean;
    toggleRightComponentsOnHover?: boolean;
    autoHeight?: boolean;
    small?: boolean;
    error?: boolean;
    contentStyle?: React.CSSProperties;
  };

export default function Label({
  className,
  style,
  contentStyle,
  children,
  highlighted,
  block,
  filled,
  disabled,
  counter,
  rightIcon,
  leftIcon,
  leftComponents,
  rightComponents,
  pointerCursor,
  toggleRightComponentsOnHover,
  autoHeight,
  small,
  error,
  ...interactionEvents
}: LabelProps) {
  const { highlightedChildren } = useLabel(
    Array.isArray(children) ? (children[0] as ReactChild) : children,
    highlighted,
  );

  return (
    <StyledLabel
      {...interactionEvents}
      className={classNames(className, {
        filled,
        disabled,
        block,
        pointerCursor,
        autoHeight,
        small,
        error,
      })}
      style={style}
    >
      {leftComponents &&
        leftComponents.map((leftComponent, i) => (
          <StyledLeftComponent
            key={`lc-${i}`}
            className={classNames({ small })}
          >
            {leftComponent}
          </StyledLeftComponent>
        ))}
      {leftIcon && <Icon name={leftIcon} size={14} className="left-icon" />}
      {counter !== undefined && (
        <Counter disabled={disabled} value={counter} variant="small" />
      )}
      <span
        className="label-content"
        style={contentStyle}
        title={
          typeof (highlightedChildren || children) === "string"
            ? ((highlightedChildren || children) as string)
            : undefined
        }
      >
        {typeof (Array.isArray(children) ? children[0] : children) === "string"
          ? highlightedChildren
          : children}
      </span>
      {rightIcon && <Icon name={rightIcon} size={14} className="right-icon" />}
      {rightComponents &&
        rightComponents.map((rightComponent, i) => (
          <StyledRightComponent
            key={`rc-${i}`}
            className={classNames({
              hoverToggler: toggleRightComponentsOnHover,
              alignTop: autoHeight,
              small,
            })}
          >
            {rightComponent}
          </StyledRightComponent>
        ))}
    </StyledLabel>
  );
}
