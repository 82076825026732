import { _ } from "../../../../languages/helper";
import { Button } from "../../../Button";
import { ConnectorIconNames } from "../../../ConnectorIcon";
import ConnectorIconWrapper from "../../../ConnectorIcon/ConnectorIconWrapper";
import ConnectorIconWrapperList from "../../../ConnectorIcon/ConnectorIconWrapperList";
import { Flex } from "../../../Flex";
import { List } from "../../../List";
import { Text } from "../../../Typography";
import { stopPropagation } from "../../../utils";

import { StyledTableBlock } from "./TableBlock.styled";

export interface DimensionOrMetricDefinition {
  label: string;
  key: string;
  connectors?: ConnectorIconNames[];
  leftComponents?: React.ReactElement[];
}

export interface OperationDefinition {
  label: string;
  key: string;
  params: Array<{ name: string; description: string }>;
}

interface TableBlockProps {
  label: string;
  icon?: ConnectorIconNames;
  background?: string;
  metrics?: DimensionOrMetricDefinition[];
  dimensions?: DimensionOrMetricDefinition[];
  operations?: OperationDefinition[];
  onSelectElement?: (key: string) => void;
  highlight?: string;
  preSelectionHint?: string;
  withValueExplorer?: boolean;
  onToggleToValueExplorer?: (dimension: DimensionOrMetricDefinition) => void;
  withSearch?: boolean;
}

export default function TableBlock({
  label,
  icon,
  background,
  metrics,
  dimensions,
  operations,
  highlight,
  onSelectElement,
  preSelectionHint,
  withValueExplorer,
  onToggleToValueExplorer,
  withSearch,
}: TableBlockProps) {
  const buildConnectorImages = (dimension: DimensionOrMetricDefinition) => {
    if (
      dimension.connectors === undefined ||
      dimension.connectors.length === 0
    ) {
      return [];
    }
    return [
      <ConnectorIconWrapperList
        key={`dim-connectors-${dimension.key}`}
        names={dimension.connectors}
        max={4}
      />,
    ];
  };

  const filteredDimensionsOrMetrics = (dimensions ?? metrics ?? []).filter(
    (dim) =>
      !highlight ||
      dim.key.toLowerCase().includes(highlight.toLowerCase()) ||
      dim.label.toLowerCase().includes(highlight.toLowerCase()),
  );

  if (filteredDimensionsOrMetrics.length === 0 && operations?.length === 0) {
    return null;
  }

  return (
    <StyledTableBlock>
      <Flex flexDirection="row" alignItems="center" gap={8}>
        {icon && background && (
          <ConnectorIconWrapper
            name={icon}
            background={background}
            size="small"
          />
        )}
        <Text variant="body12Medium">{label}</Text>
      </Flex>
      <List
        options={(operations ?? filteredDimensionsOrMetrics).map((d, i) => ({
          label: d.label,
          value: d.key,
          className: "appear-on-hover-root",
          leftComponents:
            (d as DimensionOrMetricDefinition).leftComponents ?? [],
          rightComponents: withValueExplorer
            ? [
                <Button
                  key={`toggle-value-for-${d.key}-${i}`}
                  color="link-secondary"
                  size="small"
                  className="appear-on-hover-target-block"
                  onClick={(e) => {
                    onToggleToValueExplorer && onToggleToValueExplorer(d);
                    return stopPropagation(e);
                  }}
                >
                  {_`Select dimension value`}
                </Button>,
              ]
            : buildConnectorImages(d),
        }))}
        selected={[]}
        onClickOnElement={(value) =>
          onSelectElement && onSelectElement(`${value}`)
        }
        preSelectionHint={preSelectionHint}
        highlighted={highlight}
        withSearch={withSearch}
        searchNoAutoFocus
      />
    </StyledTableBlock>
  );
}
