import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  key: "google-search-console",
  image: "GoogleSearchConsole",
  label: "Google Search Console",
  category: INTEGRATION_CATEGORIES.analytics_and_attribution,
  isNewIntegrationService: true,
  get description() {
    return _``;
  },
  get customConnectorInfo() {
    return [_`Due to API limitations, data is in California timezone.`];
  },
  dependsOnAccounts: true,
  canReconnectOAuth: true,
  backgroundColor: theme.customColors.googleFbInstaBg,
  borderColor: theme.customColors.googleFbInstaBorder,
});
