import { _ } from "../../languages/helper";
import { Button, ButtonColor } from "../Button";
import { ConnectorIcon } from "../ConnectorIcon";
import { EmojiIconBackground } from "../EmojiIcon";
import { Flex } from "../Flex";
import { Space } from "../Space";
import { Stack } from "../Stack";
import { theme } from "../theme";
import { Text } from "../Typography";

import Popup from "./Popup";

type ConfirmPopupProps = {
  onConfirm: () => void;
  onDecline: () => void;
  isLoading?: boolean;
  children: React.ReactNode;
  confirmLabel?: string;
  confirmColor?: ButtonColor;
  declineLabel?: string;
  zIndex?: number;
};

export const ConfirmPopup = ({
  onConfirm,
  onDecline,
  isLoading,
  confirmLabel,
  confirmColor,
  declineLabel,
  children,
  zIndex,
}: ConfirmPopupProps) => {
  return (
    <Popup
      onClose={onDecline}
      style={{ width: 360 }}
      zIndex={zIndex}
      headerComponent={
        <Popup.BaseHeader style={{ borderBottom: "none", paddingBottom: 0 }}>
          {" "}
        </Popup.BaseHeader>
      }
      footerComponent={
        <Flex flexDirection="column" gap={12}>
          <Button
            type="submit"
            color={confirmColor || "primary"}
            loading={isLoading}
            onClick={onConfirm}
            block={true}
            size="small"
          >
            {confirmLabel || _`Confirm`}
          </Button>

          <Button
            type="button"
            color="ghost"
            onClick={onDecline}
            block={true}
            size="small"
          >
            {declineLabel || _`No, not now`}
          </Button>
        </Flex>
      }
    >
      <Stack alignItems="center">
        {" "}
        <Flex gap={8} alignItems="center">
          <EmojiIconBackground
            background={theme.colors.white100}
            style={{ border: `1px solid ${theme.colors.borderLight}` }}
            size="large"
          >
            <ConnectorIcon size={30} name="Polar" />
          </EmojiIconBackground>
        </Flex>
      </Stack>

      <Space />

      {typeof children === "string" ? (
        <Text
          variant="body12Regular"
          color={theme.colors.text90}
          textAlign="center"
          style={{ paddingLeft: 4, paddingRight: 4 }}
        >
          {_`Are you sure you want to leave the custom report builder?`}
        </Text>
      ) : (
        children
      )}
    </Popup>
  );
};
