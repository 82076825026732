import { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router";

import { useAuth, withAuthentication } from "../../hooks/auth/auth";
import {
  PerformanceEvent,
  trackPageLoadCheckpoint,
} from "../../hooks/usePerformanceMeasurement";
import {
  Button,
  HeroFormLayout,
  Text,
  Flex,
  TextField,
  theme,
} from "../../icecube-ux";
import { _ } from "../../languages/helper";
import Form from "../../shared/Forms/Form";
import EmptyLayout from "../../shared/Layouts/EmptyLayout";

import { isPasswordStrongEnough } from "./utils";

trackPageLoadCheckpoint(PerformanceEvent.PAGE_LOADED);

function PasswordChange() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleReset = async () => {
    setError("");

    const passwordValidOrError = isPasswordStrongEnough(password);
    if (passwordValidOrError !== true) {
      setError(passwordValidOrError);
      return;
    }

    setLoading(true);
    try {
      await auth.completeNewPassord(password);
      navigate("/");
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  return (
    <EmptyLayout>
      <Helmet>
        <title>{_`Change your password`} | Polar Analytics</title>
      </Helmet>
      <HeroFormLayout hideLogo={true}>
        <HeroFormLayout.Main padding="50px 60px">
          <Form
            onSubmit={() => {
              void handleReset();
              return false;
            }}
          >
            <Flex
              flexDirection="column"
              fullWidth
              alignItems="stretch"
              gap={30}
            >
              <Flex flexDirection="column" alignItems="center">
                <HeroFormLayout.Main.Title>
                  {_`Choose a password`}
                </HeroFormLayout.Main.Title>
                <HeroFormLayout.Main.Description>
                  {_`Use the form below to choose your password`}
                </HeroFormLayout.Main.Description>
                {error !== "" && (
                  <Text
                    variant="body14Regular"
                    color={theme.colors.error100}
                    textAlign="center"
                  >
                    {error}
                  </Text>
                )}
              </Flex>

              <Flex gap={8} flexDirection="column">
                <Text fontWeight={500} fontSize="13px" color="#4B4B7E">
                  {_`Password`}
                </Text>
                <TextField
                  value={password}
                  onChange={(v) => setPassword(v as string)}
                  type="password"
                  placeholder={_`Choose wisely`}
                />
              </Flex>

              <Button
                loading={loading}
                onClick={() => void handleReset()}
                block={true}
                className="mtx-18"
                size="xxlarge"
              >
                {_`Set new password`}
              </Button>
            </Flex>
          </Form>
        </HeroFormLayout.Main>
      </HeroFormLayout>
    </EmptyLayout>
  );
}

export default withAuthentication(PasswordChange);
