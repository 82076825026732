import styled from "styled-components";

import { theme } from "../../../icecube-ux";

export const SEGMENTS: { [key: string]: string } = {
  product_viewed: "Product Viewed",
  product_added_to_cart: "Added to Cart",
  checkout_started: "Checkout Started",
};

export const StyledAudienceBlock = styled.section<{
  padding?: number;
}>`
  background: white;
  box-shadow: ${theme.shadows.small};
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  padding: ${({ padding }) => padding ?? 24}px;
`;

export const StyledAudienceConnectorIconWrapper = styled.div`
  background: ${theme.customColors.menuAudienceBg};
  height: 48px;
  min-width: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
