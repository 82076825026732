import React, { useMemo } from "react";

import { StyledHighlightedSpan } from "./Label.styled";

export default function useLabel(
  children?: React.ReactNode,
  highlightedRaw?: string,
) {
  const highlighted = highlightedRaw?.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const highlightText = useMemo(() => {
    if (typeof children !== "string") {
      return children;
    }
    const text = children;
    if (highlighted && text.toLowerCase().includes(highlighted.toLowerCase())) {
      const highlightRegexp = new RegExp(highlighted, "gi");
      const indices: number[] = [];
      let result: RegExpExecArray | null;
      while ((result = highlightRegexp.exec(text))) {
        indices.push(result.index);
      }
      if (indices.length > 0) {
        const elements: Array<{ highlight: boolean; node: React.ReactNode }> = [
          { highlight: false, node: text.substring(0, indices[0]) },
        ];
        for (let i = 0; i < indices.length; i++) {
          elements.push({
            highlight: true,
            node: text.substring(indices[i], indices[i] + highlighted.length),
          });
          if (i < indices.length - 1) {
            elements.push({
              highlight: false,
              node: text.substring(
                indices[i] + highlighted.length,
                indices[i + 1],
              ),
            });
          }
        }
        elements.push({
          highlight: false,
          node: text.substring(
            indices[indices.length - 1] + highlighted.length,
          ),
        });
        return (
          <>
            {elements.map((e, i) =>
              e.highlight ? (
                <StyledHighlightedSpan key={`${highlighted}-${i}`}>
                  {e.node}
                </StyledHighlightedSpan>
              ) : (
                <span key={`${highlighted}-${i}`}>{e.node}</span>
              ),
            )}
          </>
        );
      }
      return text;
    }
    return text;
  }, [children, highlighted]);

  return {
    highlightedChildren: highlightText,
  };
}
