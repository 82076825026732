import styled from "styled-components";

import { _ } from "../../../languages/helper";
import { Box } from "../../Box";
import { Button } from "../../Button";
import { theme } from "../../theme";
import { Text } from "../../Typography";

const StyledInstantDemoFooter = styled.div`
  background: ${theme.customColors.primaryBackground};
`;

export const InstantDemoFooter = () => {
  return (
    <StyledInstantDemoFooter>
      <Box padding="10px 16px 8px 16px">
        <Text
          variant="body10Regular"
          color={theme.colors.white100}
          textAlign="center"
        >
          {_`You're currently viewing sample data`}
        </Text>
      </Box>
      <Box padding="0 16px 16px 16px">
        <Button
          color="secondary-on-blue-background"
          block
          onClick={() => {
            window.open("https://www.polaranalytics.com/contact", "_blank");
          }}
        >
          {_`Schedule a discovery call`}
        </Button>
      </Box>
    </StyledInstantDemoFooter>
  );
};
