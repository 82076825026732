import moment from "moment";

import { Block } from "../../../icecube-ux";
import { _ } from "../../../languages/helper";

export default function EndPlanBlock({
  subscriptionEndDate,
}: {
  subscriptionEndDate: string | Date;
}) {
  return (
    <Block
      variant="warning"
      padding="regular"
      className="margin-bottom-large"
      style={{ display: "flex", alignItems: "center" }}
    >
      {_`Your plan will end on`}{" "}
      {moment(subscriptionEndDate).utcOffset(0).format("Do MMMM")}{" "}
      {_`and will revert to the Free Plan`}
    </Block>
  );
}
