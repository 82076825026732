import classNames from "classnames";
import { useState } from "react";

import { _ } from "../../languages/helper";
import { Button } from "../Button";
import { legacyTheme } from "../legacyTheme";
import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { Text } from "../Typography";

import {
  StyledFreeTrialMarketingPopup,
  StyledFreeTrialMarketingPopupBackButton,
  StyledFreeTrialMarketingPopupCenterAlign,
  StyledFreeTrialMarketingPopupContent,
  StyledFreeTrialMarketingPopupHeader,
  StyledFreeTrialMarketingPopupHeaderImage,
  StyledFreeTrialMarketingPopupHeaderImageWrapper,
  StyledFreeTrialMarketingPopupNextButton,
} from "./FreeTrialMarketingPopup.styled";
import { freeTrialMarketingPopupSlideId, slides } from "./utils";

export type FreeTrialMarketingPopupProps = InteractionEvents<HTMLSpanElement> &
  ClassAndStyleProps & {
    onClose: () => void;
    initialSlideId?: freeTrialMarketingPopupSlideId;
    onViewPlans: () => void;
    onRequestFreeTrial: () => void;
  };

export default function FreeTrialMarketingPopup({
  onClose,
  initialSlideId = "unlimited-key-indicators",
  onViewPlans,
  onRequestFreeTrial,
  className,
  style,
  ...interactionEvents
}: FreeTrialMarketingPopupProps) {
  const initialSlideIndex = slides.findIndex(
    (slide) => slide.id === initialSlideId,
  );
  const [currentSlideIndex, setCurrentSlideIndex] = useState(
    initialSlideIndex === -1 ? 0 : initialSlideIndex,
  );
  const currentSlide = slides[currentSlideIndex];
  return (
    <StyledFreeTrialMarketingPopup
      {...interactionEvents}
      className={classNames(className)}
      style={style}
      onClose={onClose}
      footerComponent={
        <StyledFreeTrialMarketingPopupCenterAlign>
          <Button color="ghost" size="large" onClick={onClose}>
            Maybe later
          </Button>
        </StyledFreeTrialMarketingPopupCenterAlign>
      }
    >
      <StyledFreeTrialMarketingPopupHeader>
        <StyledFreeTrialMarketingPopupBackButton
          onClick={() =>
            setCurrentSlideIndex((currentSlideindex) =>
              currentSlideIndex === 0
                ? slides.length - 1
                : currentSlideindex - 1,
            )
          }
        />
        <StyledFreeTrialMarketingPopupHeaderImageWrapper>
          <StyledFreeTrialMarketingPopupHeaderImage
            src={`/img/marketing/${currentSlide.headerImage}.svg`}
            style={currentSlide.imageStyle}
          />
        </StyledFreeTrialMarketingPopupHeaderImageWrapper>
        <Text
          color={legacyTheme.colors.primary8}
          fontSize="34px"
          lineHeight="45px"
          fontWeight={legacyTheme.typography.fontWeigth.w600}
        >
          {currentSlide.headerText}
        </Text>
        <StyledFreeTrialMarketingPopupNextButton
          onClick={() =>
            setCurrentSlideIndex((currentSlideindex) =>
              currentSlideIndex === slides.length - 1
                ? 0
                : currentSlideindex + 1,
            )
          }
        />
      </StyledFreeTrialMarketingPopupHeader>
      <StyledFreeTrialMarketingPopupContent>
        <Text
          color={legacyTheme.colors.grey5}
          fontSize="17px"
          lineHeight="28px"
          fontWeight={legacyTheme.typography.fontWeigth.w400}
          textAlign="center"
          className="margin-bottom-x5large"
        >
          {currentSlide.contentText}
        </Text>
        <StyledFreeTrialMarketingPopupCenterAlign>
          <Button
            size="large"
            color="secondary"
            className="margin-right-large"
            style={{ width: 200 }}
            onClick={onRequestFreeTrial}
          >
            {_`Request a free trial`}
          </Button>
          <Button size="large" onClick={onViewPlans} style={{ width: 200 }}>
            {_`View plans`}
          </Button>
        </StyledFreeTrialMarketingPopupCenterAlign>
      </StyledFreeTrialMarketingPopupContent>
    </StyledFreeTrialMarketingPopup>
  );
}
