import styled from "styled-components";

import { Icon } from "../Icon";
import { legacyTheme } from "../legacyTheme";
import { Popup } from "../Popup";

export const StyledFreeTrialMarketingPopup = styled(Popup).attrs({
  withPaddingTop: false,
})`
  width: 566px;
`;

export const StyledFreeTrialMarketingPopupHeader = styled.div`
  background-image: url("/img/header-bg-gradient.svg");
  background-size: cover;

  background-repeat: no-repeat;
  margin: -${legacyTheme.padding.x5large};
  margin-bottom: 0;
  padding: 30px 50px;

  height: 212px;
  box-sizing: border-box;
  background-position: top center;

  display: flex;
  flex-direction: row-reverse;
`;

export const StyledFreeTrialMarketingPopupHeaderHighlight = styled.span`
  color: ${legacyTheme.colors.white};
`;

export const StyledFreeTrialMarketingPopupHeaderImageWrapper = styled.div`
  position: relative;
  min-width: 180px;
  height: 100%;
`;

export const StyledFreeTrialMarketingPopupHeaderImage = styled.img`
  position: absolute;
`;

export const StyledFreeTrialMarketingPopupCenterAlign = styled.div`
  text-align: center;
`;

export const StyledFreeTrialMarketingPopupContent = styled.div`
  padding: 40px;
  padding-bottom: 20px;
`;

const StyledFreeTrialMarketingPopupChangeSlideButton = styled(Icon).attrs({
  size: 20,
})`
  cursor: pointer;
  position: absolute;
  top: 92px;
  color: ${legacyTheme.colors.primary7};
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  padding: 4px;

  &:hover {
    color: ${legacyTheme.colors.primary5};
  }
`;

export const StyledFreeTrialMarketingPopupNextButton = styled(
  StyledFreeTrialMarketingPopupChangeSlideButton,
).attrs({ name: "CaretNext" })`
  right: 8px;
`;

export const StyledFreeTrialMarketingPopupBackButton = styled(
  StyledFreeTrialMarketingPopupChangeSlideButton,
).attrs({ name: "CaretBack" })`
  left: 8px;
`;
