import React from "react";

import { Logo } from "../Logo";
import { InteractionEvents } from "../shared";
import { theme } from "../theme";
import { Text } from "../Typography";
import { TextVariant } from "../Typography/Typography";

import {
  StyledHeroFormLayout,
  StyledHeroFormLayoutMain,
} from "./HeroFormLayout.styled";

const Main = ({
  children,
  padding,
}: {
  children: React.ReactNode;
  padding: string;
}) => {
  return (
    <StyledHeroFormLayoutMain style={{ padding }}>
      {children}
    </StyledHeroFormLayoutMain>
  );
};

const Footer = ({ children }: { children: React.ReactNode }) => {
  return <footer>{children}</footer>;
};

type MainType = React.ReactElement<
  React.ComponentProps<typeof Main>,
  typeof Main
>;
type FooterType = React.ReactElement<
  React.ComponentProps<typeof Footer>,
  typeof Footer
>;

export type HeroFormLayoutProps = InteractionEvents<HTMLDivElement> & {
  children: MainType | [MainType, FooterType];
  style?: React.CSSProperties;
  hideLogo?: boolean;
};

function HeroFormLayout({
  children,
  hideLogo = false,
  style,
}: HeroFormLayoutProps) {
  let main = null;
  let footer = null;

  if (children instanceof Array) {
    [main, footer] = children;
  } else {
    main = children;
  }

  return (
    <StyledHeroFormLayout style={style} hideLogo={hideLogo}>
      {!hideLogo && <Logo variant="light" />}

      {main}

      {footer}
    </StyledHeroFormLayout>
  );
}

export default Object.assign(HeroFormLayout, {
  Main: Object.assign(Main, {
    Title: ({
      children,
      textAlign = "center",
      variant = "body40SemiBold",
    }: {
      children: React.ReactNode;
      textAlign?: "left" | "center" | "right";
      variant?: TextVariant;
    }) => (
      <Text
        variant={variant}
        color={theme.colors.text110}
        textAlign={textAlign}
      >
        {children}
      </Text>
    ),
    Subtitle: ({ children }: { children: React.ReactNode }) => (
      <Text
        variant="body20Regular"
        color={theme.colors.text90}
        textAlign="center"
      >
        {children}
      </Text>
    ),
    Description: ({ children }: { children: React.ReactNode }) => (
      <Text
        variant="body14Regular"
        color={theme.colors.text90}
        textAlign="center"
      >
        {children}
      </Text>
    ),
  }),
  Footer,
});
