import React from "react";

import { AddonType } from "../../../common/types/subscription-service";
import { Grid, theme } from "../../../icecube-ux";
import { _ } from "../../../languages/helper";
import AddonPopup from "../../Popups/AddonPopup";

import { AddonDetailsCard } from "./AddonDetailsCard";

interface PolarPixelPopupProps {
  onClickAddon: (selectedAddon: AddonType) => void;
  onClose: (e: React.MouseEvent) => void;
  disableAnimation?: boolean;
}

export const PolarPixelPopup = ({
  onClickAddon,
  onClose,
  disableAnimation = false,
}: PolarPixelPopupProps) => {
  return (
    <AddonPopup
      disableAnimation={disableAnimation}
      onClickAddon={onClickAddon}
      headerTitle={_`Polar Pixel`}
      headerSubtitle={_`Maximize your marketing ROI with clear, actionable data`}
      headerIconColor={theme.customColors.pixelBg}
      bodyTitle={_`Discover the Power of the Polar Pixel First-party Attribution`}
      addonName="PolarPixel"
      onClose={onClose}
      iconName="PolarPixel"
      loading={false}
      addonDetailsContent={
        <Grid gridTemplateColumns="repeat(2, 1fr)" gap={12}>
          <AddonDetailsCard
            title={_`Effortless Setup & High Accuracy`}
            paragraph={_`Integrate Polar Pixel with a single JavaScript code in your Shopify theme for deeper customer insights.`}
          />
          <AddonDetailsCard
            title={_`Conversion Deduplication & ROAS Calculation`}
            paragraph={_`By deduplicating conversions from multiple ad platforms, Polar Pixel helps you understand the real cost per acquisition and calculate Return on Ad Spend (ROAS) by channel and campaign.`}
          />
          <AddonDetailsCard
            title={_`Customizable Reporting & Adjustable Attribution Models`}
            paragraph={_`Access and leverage accurate attribution data in Polar via the Key Indicators, Acquisition tab, and Custom Reports. Tailor your attribution models and attribution windows to suit your needs.`}
          />
          <AddonDetailsCard
            title={_`See Tangible Results in a Matter of Weeks`}
            paragraph={_`Yellowpop saw significant improvements within two weeks. They experienced a 36% decrease in their cost per purchase, a 28% increase in ROAS, a 4% decrease in CPM, and a 3% decrease in CPC.`}
          />
        </Grid>
      }
    />
  );
};
