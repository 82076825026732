import { FunctionComponent } from "react";

import { useMetricList } from "../../hooks/metricList";
import { Input, Select } from "../../icecube-ux";
import { _ } from "../../languages/helper";
import {
  METRIC_RULE_OPERATORS,
  Rule,
  RuleOperators,
} from "../../utils/filterUtils";

interface RuleProps {
  rule: Rule;
  onRuleChange: (rule: Rule) => void;
}

const MetricRule: FunctionComponent<RuleProps> = ({
  rule,
  onRuleChange,
}: RuleProps) => {
  const { getMetricDetailsFromId } = useMetricList();
  const metricDetails = getMetricDetailsFromId(
    rule.dimensionFilter?.name ?? "",
  );
  const isPercentage = metricDetails?.metric.percentage ?? false;

  return (
    <>
      <Select
        forcedDisplayedValue={
          METRIC_RULE_OPERATORS.find((r) => r.value === rule.operator)?.label ??
          "..."
        }
        options={METRIC_RULE_OPERATORS}
        selected={[rule.operator]}
        onChange={(v) =>
          onRuleChange({
            dimensionFilter: rule.dimensionFilter,
            operator: v[0] as RuleOperators,
            value: [],
          })
        }
        block
      />
      <Input
        type={"number"}
        value={
          rule.value?.[0] === ""
            ? ""
            : Number(rule.value?.[0]) * (isPercentage ? 100 : 1)
        }
        onChange={(v) => {
          onRuleChange({
            dimensionFilter: rule.dimensionFilter,
            operator: rule.operator,
            value: [v === "" ? "" : `${Number(v) / (isPercentage ? 100 : 1)}`],
          });
        }}
        placeholder={_`Write a value...`}
        block
      />
    </>
  );
};

export default MetricRule;
