import styled from "styled-components";

import { theme } from "../theme";

export const StyledHeroFormLayout = styled.div<{ hideLogo: boolean }>`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: ${({ hideLogo }) => (hideLogo ? 600 : 783)}px;
  overflow-y: auto;
  padding: 32px 0;

  background: url(/img/grainy-background.png),
    ${theme.customColors.darkerLandingPageBackground};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px 0px;
`;

export const StyledHeroFormLayoutMain = styled.main`
  box-sizing: border-box;
  width: 750px;
  max-width: 100%;
  z-index: 1;
  background: ${theme.colors.white100};
  border-radius: ${theme.borderRadius.r10px};
`;
